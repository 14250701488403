import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import useFeedbackService from "./useFeedbackService";

/**
 * 
 * hook is dedicated to make checks before confirming an action and show errors, preventing the user from having to retype the data or handling errors when not necessary
 */
function useActionPreConfirm(){
    const feedbackService = useFeedbackService();
    const { appState } = useGlobalState();
    
    function check(){
        if(!appState.isConnectionAvailable()){
            feedbackService.notifyToast("No internet connection.", "error");
            return false;
        }

        return true;
    }

    return {check};
}

export default useActionPreConfirm;