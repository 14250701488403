import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useLocation, withRouter } from "react-router";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import { useCustomPopupsStore } from "../CustomPopupsStore";
import styles from "./MultiPopProductionsStarterPopup.module.scss";
import ProductionService from "../../../../../barrel/services/productionService";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { IonLoading } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { dispatchCustomPopupsStore } from "../CustomPopupsStore";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
import productionController from "../../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import { useIonAlert } from "@ionic/react";
import { useIonToast } from "@ionic/react";
import MultiPopPopupOperationSelectionSubscreen from "./SubSelectionScreens/MultiPopPopupOperationSelectionSubscreen/MultiPopPopupOperationSelectionSubscreen";
import MultiPopProductionsStartedConfirmationButtons from "./MultiPopProductionsStarterPopupConfirmationButtons";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import ProductionOrdersDao from "../../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import MultiPopPopupProductionOrderSelectionSubscreen from "./SubSelectionScreens/MultiPopPopupProductionOrderSelectionSubscreen/MultiPopPopupProductionOrderSelectionSubscreenMultiPopPopupProductionOrderSelectionSubscreen";
import MultiPopPopupProductionOrderProductSelectionSubscreen from "./SubSelectionScreens/MultiPopPopupProductionOrderProductSelectionSubscreen/MultiPopPopupProductionOrderProductSelectionSubscreen";
import productionOrdersController from "../../../../../barrel/controllers/productionOrdersController";
import PopsWithUnavailablePreviousProductionRecord from "./resultExecutionScreens/popsWithUnavailablePreviousRecord/popsWithUnavailablePreviousRecord";
import productionOrderProductsController from "../../../../../barrel/controllers/productionOrderProductsController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import { dispatchPreActionsStore, usePreActionsStore } from "../../../../FeaturePages/Productions/Standard/ProductionInitiation/preActionsStore";
import PreActionSelectMachine from "../../../../FeaturePages/Productions/Standard/ProductionInitiation/ProductionStage/PreActionViews/SelectMachine/PreActionSelectMachine";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
export  const ASK_SELECT_MACHINE_PRODUCT_BY_PRODUCT = true; //if changing this make a new flag somewhere and make it configurable, and set default behaviour to do product by product

function MultiPopProductionsStarterPopup(props: any) {
  const {t} = useHxfTranslation();
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "MultiPopProductionsStarterPopup",
  });

 
  const feedbackService = useFeedbackService();
  const preActionsData = useRef<any>(null);
  const [didMount ,setDidMount] = useState(false);
  const popupsStore = useCustomPopupsStore();
  const previousPopupsStore = usePrevious(popupsStore);
  const myLocalStorage = useLocalStorageHandler();
  const [selectedProduction, setSelectedProduction] = useState(-1);
  const [enabledCancelAllButton, setEnabledCancelAllButton] = useState(true);
  const preActionsStore = usePreActionsStore();
  const [rerenderPreActionSelectMachine, setRerenderPreActionSelectMachine] = useState(0);
  const [startMultiProductionButtonEnabled, setStartMultiProductionEnabled] =
    useState(true);
  const productionOrdersDao = ProductionOrdersDao();

  const [currentStep, setCurrentStep] = useState(""); //SELECT_OPERATION, SELECT_PRODUCTION_ORDER,SELECT_PRODUCTION_ORDER_PRODUCT

  const [currentSelectionData, setCurrentSelectionData] = useState<any>({});
  const [selectedProductionOrderId, setSelectedProductionOrderId] =
    useState(-1);
  const [askPreviousRelations, setAskPreviousRelations] = useState<any>([]);

  const [popsRequiringAskPreviousRecord, setPopsRequiringAskPreviousRecord] =
    useState([]);
  const [
    indexAnsweringAskPreviousRecordPop,
    setIndexAnsweringAskPreviousRecordPop,
  ] = useState(-1);

  const [
    arrayPopsWithUnavailablePreviousProductionRecord,
    setArrayPopsWithUnavailablePreviousProductionRecord,
  ] = useState<any>([]);
  const [
    showResultScreenPopsWithUnavailablePreviousRecord,
    setShowResultScreenWithUnavailablePreviousRecord,
  ] = useState(false);

  const [isLoadingFromCallback, setIsLoadingFromCallback] = useState(false); //force loading gif to show
  const resetPreActionsData = () => {
    preActionsData.current = null;
    dispatchPreActionsStore({
      type: "RESET_STORE",
    });
  }
  const isPopSelected = (idProductionOrderProduct: any) => {
    for (let i = 0; i < addedPopsArray.length; i++) {
      let idPop = addedPopsArray[i].Id_ProductionOrder_Product;
      if (idPop === idProductionOrderProduct) {
        return true;
      }
    }

    return false;
  };

  const isArrayPopsSelected = (arrayPops: any) => {
    if (addedPopsArray.length === 0) {
      return false;
    }
    for (let i = 0; i < arrayPops.length; i++) {
      let idPop = arrayPops[i].Id_ProductionOrder_Product;
      let exists = false;
      for (let j = 0; j < addedPopsArray.length; j++) {
        if (idPop === addedPopsArray[j].Id_ProductionOrder_Product) {
          exists = true;
        }
      }

      if (!exists) {
        return false;
      }
    }

    return true;
  };

  const [addedPopsArray, setAddedPopsArray] = useState<any>([]);
  const previousAddedPopsArray = usePrevious(addedPopsArray);
  const onResetInternalState = () => {
    setCurrentSelectionData({});
    setCurrentStep("SELECT_OPERATION");
    setSelectedProductionOrderId(-1);
    setAddedPopsArray([]);
    setIndexAnsweringAskPreviousRecordPop(-1);
    console.log("RESETTING INTERNAL STATE MULTI");
  };

  const onPopupClose = useCallback((options = {}) => {
    let redirectTo = null;
    if (popupsStore.multiPopProductionsStarterPopupSettings?.onCloseGoToPath) {
      redirectTo =
        popupsStore.multiPopProductionsStarterPopupSettings?.onCloseGoToPath;
    }
    setShowResultScreenWithUnavailablePreviousRecord(false);
    setArrayPopsWithUnavailablePreviousProductionRecord([]);
    setSelectedProduction(-1);
    dispatchCustomPopupsStore({
      type: "CLOSE_MULTI_POP_PRODUCTIONS_STARTER_POPUP",
    });

    let stateObj:any = {redirectTo: redirectTo};
    if(options?.openMinimizedMultiPrs){

      stateObj.withHistoryState = {onLoadOpenMultis:true};
    }
    if (redirectTo !== null) {
      props.history.push({
        pathname: "/page-redirector",
        state: stateObj,
      });
    }
  }, [props.history, popupsStore.multiPopProductionsStarterPopupSettings]);

  const onOpenPopup = useCallback(() => {
    onResetInternalState();
    productionOrdersDao
      .getActiveProductionOrders(0, 1, true, true, true, true,true, cancelToken)
      .then((resp: any) => {
        let posData = resp.data.response.data.return;
        let posExtra = resp.data.response.data?.extra;
        let posTotalSize = resp.data.response.data.totalSize;
        let productionOrderProductsSimultaneousOperations = posExtra?.getProductionOrderProductsSimultaneousOperations;
         
        if (
          posExtra &&
          productionOrderProductsSimultaneousOperations.length > 0
        ) {
          let dispatchData = {
            type: "SET_MULTI_POP_PRODUCTIONS_STARTER_POPUP_DATA",
            payload: [...productionOrderProductsSimultaneousOperations],
          };

          dispatchCustomPopupsStore(dispatchData);
        } else {
          //error , no simultaneous operations currently available
        }
      })
      .catch((resp) => {
        if (isCancel(resp)) {
          return;
        }
        console.log("failed error: ", resp);
        //an error occurred try again later
      });
  }, [productionOrdersDao, cancelToken, isCancel]);

  const unselectPops = (popsArrayToRemove: any) => {
    let newPopsArray = [];
    for (let i = 0; i < addedPopsArray.length; i++) {
      let exists = false;
      let idPop = addedPopsArray[i].Id_ProductionOrder_Product;
      for (let j = 0; j < popsArrayToRemove.length; j++) {
        if (idPop === popsArrayToRemove[j].Id_ProductionOrder_Product) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        newPopsArray.push(addedPopsArray[i]);
      }
    }

    setAddedPopsArray(newPopsArray);
  };

  const getCurrentlySelectedPoData = () => {
    let productionOrderId = selectedProductionOrderId;
    let productionOrderCode = "";
    let productionOrderName = "";
    for (
      let i = 0;
      i < currentSelectionData.productionOrdersData.length;
      i++
    ) {
      if (
        parseInt(
          currentSelectionData.productionOrdersData[i]
            .Id_ProductionOrder
        ) === productionOrderId
      ) {
        productionOrderCode =
          currentSelectionData.productionOrdersData[i].code;
        productionOrderName =
          currentSelectionData.productionOrdersData[i].name;
        break;
      }
    }

    return {productionOrderCode:productionOrderCode,productionOrderName:productionOrderName}
  }

  const addPoDataToObj = (obj:any) => {

    let selectedPoData = getCurrentlySelectedPoData();

    return {...obj,productionOrderCode:selectedPoData.productionOrderCode,productionOrderName: selectedPoData.productionOrderName}
  }

  const updatePopsArray = (popsArrayToUpdate:any) => {
    let newPopsArray = [];
    
    for (let i = 0; i < addedPopsArray.length; i++) {
      let idPop = addedPopsArray[i].Id_ProductionOrder_Product;
      for (let j = 0; j < popsArrayToUpdate.length; j++) {
        if (idPop === popsArrayToUpdate[j].Id_ProductionOrder_Product) {
          newPopsArray.push(addPoDataToObj(popsArrayToUpdate[j]));
        }else{
          newPopsArray.push(addedPopsArray[i]);
        }
      }

    }

    setAddedPopsArray(newPopsArray);
  }

  const onAskPreviousPopupCloseCallback = useCallback(
    (closeReason: any) => {
      onPopupClose();
    },
    [onPopupClose]
  );
  const openAskPreviousPopup = useCallback(
    (popObj: any) => {
      //show but loading
      let dispatchData = {
        type: "SHOW_ASK_PREV_PRODUCTIONRECORD_POPUP",
        payload: {},
      };
      dispatchCustomPopupsStore(dispatchData);

      let idPop = popObj.Id;
      let workerCode = myLocalStorage.getWorkerIdentificationCode();
      productionOrdersController()
        .getCandidatesToPop(workerCode, idPop)
        .then((res) => {
          console.log("[XYZ]done ,", res);

          let updatedOperations = res?.data?.response?.data;
          let found = false;
          let isFirstOperation = false;

          let selectedPopSelectedOperationId = popObj.temp_selectedOperation.Id;
          let popProductFirstOperationId =
            popObj.temp_product.Id_OperationProduct_Base;

          if (!selectedPopSelectedOperationId || !popProductFirstOperationId) {
            //throw error
            throw new Error(
              "unexpected error ,failed to find selectedpop data"
            );
          }
          isFirstOperation =
            selectedPopSelectedOperationId === popProductFirstOperationId;

          let operationAskPreviousProductionSettings =
            ProductionService().loadOperationAskPreviousProductionSettings(
              popObj.temp_selectedOperation,
              isFirstOperation
            );

          let infoBarSettings = {
            productionOrderCode: popObj.temp_productionOrder.code,
            productionOrderName: popObj.temp_productionOrder.name,
            operationCode: popObj.temp_selectedOperation.code,
            operationName: popObj.temp_selectedOperation.name,
            productCode: popObj.temp_product.code,
            productName: popObj.temp_product.name,
          };
          if (!updatedOperations) {
            present("Failed to load previous operations (error2)");
          } else {
            let found = false;
            let candidates = [];
            for (let i = 0; i < updatedOperations.length; i++) {
              if (
                updatedOperations[i].Id === popObj.temp_selectedOperation.Id
              ) {
                candidates =
                  updatedOperations[i].candidatePreviousProductionRecords;
                found = true;
                break;
              }
            }
            if (!found) {
              present("Failed to load previous operations (error3)");
            } else {
              let candidatePreviousProductionRecordsToAssociate = candidates;
              let dispatchData = {
                type: "SHOW_ASK_PREV_PRODUCTIONRECORD_POPUP",
                payload: {
                  associatePreviousProductionRecordPopupSettings: {
                    ...operationAskPreviousProductionSettings,
                  },
                  associatePreviousProductionRecordPopupData: {
                    candidatePreviousProductionRecordsToAssociate:
                      candidatePreviousProductionRecordsToAssociate,
                    popId: popObj.Id,
                    idOperation: popObj.temp_selectedOperation.Id,
                    productionOrderId: popObj.Id_ProductionOrder,
                    preActionsData: preActionsData.current
                  },
                  associatePreviousProductionRecordInfoBarSettings: {
                    ...infoBarSettings,
                  },
                  associatePreviousProductionRecordOnConfirmMultiPop: {
                    customConfirm: true,
                    popupCloseRedirectTo: "feature/production-orders",
                  },
                  associatePreviousProductionRecordsCustomCallbacks: {
                    onPopupClose: onAskPreviousPopupCloseCallback,
                  },
                },
              };

              dispatchCustomPopupsStore(dispatchData);
            }
          }
        })
        .catch((ex) => {
          console.log("exception: ", ex);
          dispatchCustomPopupsStore({
            type: "CLOSE_ASK_PREV_PRODUCTIONRECORD_POPUP",
          });
          present(
            "Failed to connect. Try again and contact us if the error persists. (l09j2trf)"
          );
          onPopupClose();
        });
    },
    [myLocalStorage, onPopupClose, onAskPreviousPopupCloseCallback, present]
  );

  const startMultiProduction = useCallback(
    (passedNonPersistedAskPreviousRelations = null) => {

      let asdasd = currentSelectionData;

      if(!ASK_SELECT_MACHINE_PRODUCT_BY_PRODUCT && currentSelectionData?.shopfloorSetting_selectMachine === 1 && !preActionsData?.current?.selectedMachine){
        dispatchPreActionsStore({
          type: "SET_PENDING_PREACT",
          payload: {
             pendingPreActionsOperation:{
              obj:{
                Id:currentSelectionData.oneOperationProductIdWithCode
              }, index:0
             },
             pendingAction:"PREACT_SELECT_MACHINE"
          },
        });
      }else{
        let arrayWithPopsIds = [];
        let mapPopsSelectedMachine:any = {};

        for (let i = 0; i < addedPopsArray.length; i++) {
          let idPop = addedPopsArray[i].Id_ProductionOrder_Product;
          if(addedPopsArray[i]?.selectedMachine && ASK_SELECT_MACHINE_PRODUCT_BY_PRODUCT){
            mapPopsSelectedMachine[idPop] = addedPopsArray[i].selectedMachine;
          }
          arrayWithPopsIds.push(idPop);
        }
        let workerCode = myLocalStorage.getWorkerIdentificationCode();
        let operationCode = currentSelectionData.operationCode;
  
        let objAskPreviousRelations:any =
          passedNonPersistedAskPreviousRelations !== null
            ? passedNonPersistedAskPreviousRelations
            : askPreviousRelations;

        let extraData:any = {};

        if(mapPopsSelectedMachine){
          extraData = {...extraData,mapPopsSelectedMachine:mapPopsSelectedMachine};
        }

        productionController()
          .multiProductionExecution(
            workerCode,
            operationCode,
            arrayWithPopsIds,
            objAskPreviousRelations,
            preActionsData.current,
            extraData,
            cancelToken
          )
          .then((res: any) => {
            console.log(res);
            setIsLoadingFromCallback(false);
            let response = res?.data?.response;
  
            if (response?.popsRequireAskPreviousProductionRecord) {
              console.log("Has pops requiring to ask previous.");
  
              setPopsRequiringAskPreviousRecord(
                response.popsRequireAskPreviousProductionRecord
              );
              setIndexAnsweringAskPreviousRecordPop(0);
              openAskPreviousPopup(
                response.popsRequireAskPreviousProductionRecord[0]
              );
            } else if (response?.popsWithUnavailablePreviousProductionRecord) {
              //remove current relations of pops with unavailable previous production to force ask to associate again on next try.
  
              let newAskRelations = [];
              for (let i = 0; i < objAskPreviousRelations.length; i++) {
                let relationIdPop =
                  objAskPreviousRelations[i].Id_ProductionOrder_Product;
                let found = false;
  
                for (
                  let j = 0;
                  j < response.popsWithUnavailablePreviousProductionRecord.length;
                  j++
                ) {
                  let unavPopId =
                    response.popsWithUnavailablePreviousProductionRecord[j];
                  if (unavPopId === relationIdPop) {
                    found = true;
                    break;
                  }
                }
  
                if (!found) {
                  newAskRelations.push(objAskPreviousRelations[i]);
                }
              }
  
              setAskPreviousRelations(newAskRelations);
              //-----
              setArrayPopsWithUnavailablePreviousProductionRecord(
                response.popsWithUnavailablePreviousProductionRecord
              );
              setShowResultScreenWithUnavailablePreviousRecord(true);
            } else if (res.data.code === 200) {
              //all good
              presentToast({
                color: "success",
                message: "Multi Production Started Successfully!",
                duration: 2000,
              });
              onPopupClose({openMinimizedMultiPrs:true});
              return;
            } else {
              //unexpected result
              throw new Error("unexpected result #28934897 ");
            }
  
            if (!startMultiProductionButtonEnabled) {
              setStartMultiProductionEnabled(true);
            }
          })
          .catch((res: any) => {
            if (isCancel(res)) {
              return;
            }
            setIsLoadingFromCallback(false);
            let errorsMap = res?.response?.data?.response?.issues?.errorsMap;

            if(errorsMap?.MACHINE_IS_BUSY){

                feedbackService.notifyToast(t('selected.machine.is.busy'),"error");
                setRerenderPreActionSelectMachine(rerenderPreActionSelectMachine + 1);
                return;
            }
            
            if (!startMultiProductionButtonEnabled) {
              setStartMultiProductionEnabled(true);
            }
  
            present(
              "Failed to connect. Try again and contact us if the error persists. (h36fgf6)"
            );
            onPopupClose();
          });
      }

 
    },
    [
      myLocalStorage,
      cancelToken,
      isCancel,
      presentToast,
      onPopupClose,
      present,
      askPreviousRelations,
      openAskPreviousPopup,
      startMultiProductionButtonEnabled,
      addedPopsArray,
      currentSelectionData,
      feedbackService,
      rerenderPreActionSelectMachine,
      t
    ]
  );
  const onConfirmSelectPreviousProductionRecordCallback = useCallback(
    (
      workerCode: any,
      idOperation: any,
      previousProductionRecordId: any,
      popId: any
    ) => {
      let newObj = {
        Id_Operation: idOperation,
        Id_ProductionOrder_Product: popId,
        Id_Previous_ProductionRecord: previousProductionRecordId,
      };

      let currentRelations = [...askPreviousRelations];
      currentRelations.push(newObj);
      setAskPreviousRelations(currentRelations);

      let finishedConfirmingAskPrevious =
        indexAnsweringAskPreviousRecordPop ===
        popsRequiringAskPreviousRecord.length - 1;

      if (finishedConfirmingAskPrevious) {
        console.log("Finished confirming now attempt execute multi");
        setIsLoadingFromCallback(true);
        startMultiProduction(currentRelations);
        return;
      }

      let newIndex = indexAnsweringAskPreviousRecordPop;
      newIndex = newIndex + 1;
      setIndexAnsweringAskPreviousRecordPop(newIndex);

      let isFirstOperation = false;

      openAskPreviousPopup(popsRequiringAskPreviousRecord[newIndex]);
    },
    [
      askPreviousRelations,
      startMultiProduction,
      indexAnsweringAskPreviousRecordPop,
      openAskPreviousPopup,
      popsRequiringAskPreviousRecord,
    ]
  );

  const getScreenToRender = () => {
    if (showResultScreenPopsWithUnavailablePreviousRecord) {
      return (
        <>
          <PopsWithUnavailablePreviousProductionRecord
            onClickTryAgain={() => {
              setShowResultScreenWithUnavailablePreviousRecord(false);
              setIndexAnsweringAskPreviousRecordPop(-1);
              setStartMultiProductionEnabled(false);
              setIsLoadingFromCallback(true);
              startMultiProduction();
            }}
            onClickEditCallback={() => {
              setCurrentStep("SELECT_PRODUCTION_ORDER");
              setShowResultScreenWithUnavailablePreviousRecord(false);
              setIndexAnsweringAskPreviousRecordPop(-1);
              setStartMultiProductionEnabled(true);
              setIsLoadingFromCallback(false);
              //dispatchCustomPopupsStore({type:"SHOW_MULTI_POP_PRODUCTIONS_STARTER_POPUP"});
            }}
            onRemovePopByIdCallback={(idpop: any) => {
              let newAddedPopsArray = [];
              for (let i = 0; i < addedPopsArray.length; i++) {
                if (addedPopsArray[i].Id_ProductionOrder_Product !== idpop) {
                  newAddedPopsArray.push(addedPopsArray[i]);
                }
              }
              setAddedPopsArray(newAddedPopsArray);
              let newarrayPopsWithUnavailablePreviousProductionRecord = [];
              for (
                let i = 0;
                i < arrayPopsWithUnavailablePreviousProductionRecord.length;
                i++
              ) {
                if (
                  arrayPopsWithUnavailablePreviousProductionRecord[i] !== idpop
                ) {
                  newarrayPopsWithUnavailablePreviousProductionRecord.push(
                    arrayPopsWithUnavailablePreviousProductionRecord[i]
                  );
                }
              }

              setArrayPopsWithUnavailablePreviousProductionRecord(
                newarrayPopsWithUnavailablePreviousProductionRecord
              );
            }}
            arrayPopsWithUnavailablePreviousProductionRecord={
              arrayPopsWithUnavailablePreviousProductionRecord
            }
            currentSelectionData={currentSelectionData}
            addedPopsArray={addedPopsArray}
          />
        </>
      );
    }

    return (
      <>
        {popupsStore.multiPopProductionsStarterPopupData.length === 0 ? (
          <div className={styles.loadingContainer}>
            <LoadingSpinnerImg />
          </div>
        ) : (
          <>

          {preActionsStore?.pendingAction == "PREACT_SELECT_MACHINE" && (
              <div key={rerenderPreActionSelectMachine}>
                <PreActionSelectMachine 

                  onClose={() => {
                    resetPreActionsData();
                    setStartMultiProductionEnabled(true);
                  }}
                  
                  onFinish={(selectedMachine:any) => {
              
                    preActionsData.current = {
                      ...preActionsData.current,
                      selectedMachine:selectedMachine
                    }
                    dispatchPreActionsStore({
                      type: "SET_PREACTIONS_DATA",
                      payload: {
                        ...preActionsStore.preActionsData, ...preActionsData.current
                      },
                    });
                    //operationSelect(preActionsStore.pendingPreActionsOperation.obj, preActionsStore.pendingPreActionsOperation.index);
                    startMultiProduction();
                  }}

                />
              </div>
              )}

            {currentStep === "SELECT_OPERATION" ? (
              <MultiPopPopupOperationSelectionSubscreen
                multiPopProductionsStarterPopupData={
                  popupsStore.multiPopProductionsStarterPopupData
                }
                onOperationSelect={(
                  operationSelectedData: any,
                  indexOperation: number
                ) => {
                  setIsLoadingFromCallback(true);

                  productionOrdersController()
                    .getActiveProductionOrders(
                      {
                        page: 0,
                        size: "unlimited",
                        openOnly: true,
                        getAllWorkerOnGoingProductions: false,
                        includePopsOperationsProgressData:true,
                        operationCodeFilter:
                          operationSelectedData.operationCode,
                      },
                      cancelToken
                    )
                    .then((res) => {
                      let resultingProductionOrders =
                        res?.data?.response?.data?.return;

                      if (!resultingProductionOrders) {
                        presentToast(
                          "Failed to fetch data, please try again or contact us if the error persists",
                          3000
                        );
                      } else {
                        let productionOrdersData = [];
                        for (
                          let i = 0;
                          i < resultingProductionOrders.length;
                          i++
                        ) {
                          resultingProductionOrders[i]["Id_ProductionOrder"] =
                            resultingProductionOrders[i]["Id"];
                          delete resultingProductionOrders[i]["Id"];
                          productionOrdersData.push(
                            resultingProductionOrders[i]
                          );
                        }

                        operationSelectedData = { ...operationSelectedData }; //reinitialize, to avoid issues
                        operationSelectedData.productionOrdersData =
                          productionOrdersData;

                        setCurrentSelectionData(operationSelectedData);
                        setCurrentStep("SELECT_PRODUCTION_ORDER");
                      }

                      setIsLoadingFromCallback(false);
                    })
                    .catch((res) => {
                      if (isCancel(res)) {
                        return;
                      }
                      setIsLoadingFromCallback(false);
                      presentToast(
                        "An error occurred, please try again or contact us if the error persists",
                        3000
                      );
                    });
                }}
              />
            ) : (
              <></>
            )}
            {currentStep === "SELECT_PRODUCTION_ORDER" ? (
              <MultiPopPopupProductionOrderSelectionSubscreen
                selectionData={currentSelectionData}
                onGoBack={() => {
                  //button not visible
                  setCurrentSelectionData({});
                  setAddedPopsArray([]);
                  setCurrentStep("SELECT_OPERATION");
                }}
                onProductionOrderSelect={(
                  resSelectedProductionOrderId: any
                ) => {
                  setIsLoadingFromCallback(true);
                  productionOrderProductsController()
                    .getPopsDataByProductionOrder(
                      resSelectedProductionOrderId,
                      currentSelectionData.operationCode,
                      true,
                      true,
                      cancelToken
                    )
                    .then((res) => {
                      setIsLoadingFromCallback(false);
                    
                      let resultingProductionOrderProducts =
                        res?.data?.response?.popsData;
                      if (!resultingProductionOrderProducts) {
                        //error occurred no productionOrderProducts found
                        return;
                      }

                      let operationsAccessData = res?.data?.response?.extra?.operationsAccessData;
                      let selectedData = { ...currentSelectionData, operationsAccessData:operationsAccessData };
                     
                      for (
                        let i = 0;
                        i < resultingProductionOrderProducts.length;
                        i++
                      ) {
                        resultingProductionOrderProducts[i].productData =
                          JSON.parse(
                            resultingProductionOrderProducts[i].productData
                          );
                      }
                      selectedData.productionOrderProductsData =
                        resultingProductionOrderProducts;

                      setCurrentSelectionData(selectedData);

                      setCurrentStep("SELECT_PRODUCTION_ORDER_PRODUCT");
                      setSelectedProductionOrderId(
                        parseInt(resSelectedProductionOrderId)
                      );
                    })
                    .catch((res: any) => {
                      if (isCancel(res)) {
                        return;
                      }
                      setIsLoadingFromCallback(false);
                      presentToast(
                        "An error occurred, please try again or contact us if the error persists",
                        3000
                      );
                    });
                }}
              />
            ) : (
              <></>
            )}

            {currentStep === "SELECT_PRODUCTION_ORDER_PRODUCT" ? (
              <MultiPopPopupProductionOrderProductSelectionSubscreen
                selectedProductionOrderId={selectedProductionOrderId}
                selectionData={currentSelectionData}
                isArrayPopsSelected={(arrayPops: any) => {
                  return isArrayPopsSelected(arrayPops);
                }}
                isPopSelected={(idPop: any) => {
                  return isPopSelected(idPop);
                }}
                onGoBack={() => {
                  setCurrentStep("SELECT_PRODUCTION_ORDER");
                  setSelectedProductionOrderId(-1);
                }}
                onSelectPops={(popsArrayToAdd: any) => {
                  const selectedPoData = getCurrentlySelectedPoData();

                  let newPopsArray = [...addedPopsArray];
                  for (let i = 0; i < popsArrayToAdd.length; i++) {
                    let exists = false;
                    let idPop = popsArrayToAdd[i].Id_ProductionOrder_Product;
                    for (let j = 0; j < addedPopsArray.length; j++) {
                      if (
                        idPop === addedPopsArray[j].Id_ProductionOrder_Product
                      ) {
                        exists = true;
                        break;
                      }
                    }
                    if (!exists) {
                      newPopsArray.push(addPoDataToObj(popsArrayToAdd[i]));
                      /*newPopsArray.push({
                        ...popsArrayToAdd[i],
                        productionOrderCode: selectedPoData.productionOrderCode,
                        productionOrderName: selectedPoData.productionOrderName,
                      });*/
                    }
                  }

                  setAddedPopsArray(newPopsArray);
                }}
                onUnselectPops={(popsArrayToRemove: any) => {
                  unselectPops(popsArrayToRemove);
                }}
                onUpdatePops={(popsArrayToUpdate: any) => {
                  updatePopsArray(popsArrayToUpdate);
                }}
              />
            ) : (
              <></>
            )}
          </>
        )}

        {addedPopsArray.length > 0 && (
          <div className={styles.addedPopsSection}>
            <div className={styles.lineBreaker}></div>
            <div className={styles.addedPops} id="divAddedPopsContainer">
              {addedPopsArray.map((row: any, index: number) => (
                <div className={styles.addedPop} key={"addedPop_ele_" + index}>
                  <div className={styles.popName}>
                    <b>{t('order')}:</b> {row.productionOrderCode} <b>{t('product')}:</b>{" "}
                    {row.code} {row?.selectedMachine && (<><b>{t('machine')}:</b> {row?.selectedMachine?.machineData?.code}</>)}
                  </div>
                  <div className={styles.popRemoveBtnContainer}>
                    <IonButton
                      color="danger"
                      className={styles.popRemoveBtn}
                      onClick={() => {
                        unselectPops([row]);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        size="large"
                        icon={closeOutline}
                      />
                    </IonButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <MultiPopProductionsStartedConfirmationButtons
          enabled={
            addedPopsArray.length > 0 && startMultiProductionButtonEnabled
          }
          onClickConfirm={() => {
            setStartMultiProductionEnabled(false);

            startMultiProduction();
         
          }}
          enabledCancelAll={enabledCancelAllButton && selectedProduction === -1}
          onCancelAllClick={() => {}}
        />
      </>
    );
  };

  useEffect(() => {
    if(!didMount){
      resetPreActionsData();
      setDidMount(true);
    }
  },[didMount]);
  useEffect(() => {
    console.log("pops req ask prev: ", popsRequiringAskPreviousRecord);
  }, [popsRequiringAskPreviousRecord]);

  useEffect(() => {
    if (popupsStore.multiPopProductions_askPreviousConfirm?.workerCode) {
      //triggered by TRIGGER_MULTI_POP_CONFIRM_ASK_PREVIOUS_PRODUCTION_RECORD

      console.log("TRIGGERED, do something here");
      let receivedData = {
        ...popupsStore.multiPopProductions_askPreviousConfirm,
      };
      dispatchCustomPopupsStore({
        type: "CLEAR_TRIGGER_MULTI_POP_CONFIRM_ASK_PREVIOUS_PRODUCTION_RECORD",
      });
  
      onConfirmSelectPreviousProductionRecordCallback(
        receivedData.workerCode,
        receivedData.idOperation,
        receivedData.idSelectedPreviousProductionId,
        receivedData.popId
      );
    }
  }, [
    popupsStore.multiPopProductions_askPreviousConfirm,
    onConfirmSelectPreviousProductionRecordCallback,
  ]);
  useEffect(() => {
    if (
      previousPopupsStore &&
      popupsStore &&
      previousPopupsStore?.multiPopProductionsStarterPopup !==
        popupsStore?.multiPopProductionsStarterPopup
    ) {
      if (
        popupsStore.multiPopProductionsStarterPopup &&
        !previousPopupsStore.multiPopProductionsStarterPopup
      ) {
        onOpenPopup();
      }
    }
  }, [popupsStore, previousPopupsStore, onOpenPopup]);

  useEffect(() => {
    if (
      previousAddedPopsArray &&
      previousAddedPopsArray !== addedPopsArray &&
      addedPopsArray.length > previousAddedPopsArray.length
    ) {
      let elem: any = document.getElementById("divAddedPopsContainer");
      if (elem) {
        elem.scrollTo({
          left: elem.offsetWidth,
          behavior: "smooth",
        });
      }
    }
  }, [addedPopsArray, previousAddedPopsArray]);

  if (isLoadingFromCallback) {
    return (
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}>
                {t('simultaneous.productions.initializer')}
              </div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  onPopupClose();
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
          </div>
          <div className={styles.loadingDivContent}>
            <LoadingSpinnerImg />
          </div>
        </div>
      </SimpleContentPopup>
    );
  }

  if (
    !popupsStore.multiPopProductionsStarterPopup ||
    (indexAnsweringAskPreviousRecordPop !== -1 &&
      showResultScreenPopsWithUnavailablePreviousRecord === false)
  ) {
    return <></>;
  }

  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>
            {t('simultaneous.productions.initializer')}
            </div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                onPopupClose();
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>

          {getScreenToRender()}
        </div>
      </div>
    </SimpleContentPopup>
  );
}

export default withRouter(MultiPopProductionsStarterPopup);
