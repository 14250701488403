import { useHistory } from "react-router";
import { dispatchProductionOperationStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ProductionOperationStore";
import { useProductionOrderSelectionStore } from "../../pages/FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import useFeedbackService from "./useFeedbackService";
import useHxfTranslation from "./useHxfTranslation";

const usePrTempStore = () => {

    const feedbackService = useFeedbackService();
    const productionOrderSelectionStore = useProductionOrderSelectionStore();
    const {t} = useHxfTranslation();
    const history = useHistory();

    const handleSaveErrors = (res:any) => {

        if(res?.response?.data?.response?.issues?.errors.includes("error.productionRecord.isCanceled")){


            dispatchProductionOperationStore({type:"TRIGGER_SHOW_REVOKE_CANCELLATION"});
            return;
        }
        if(res?.response?.data?.response?.issues?.errors.includes("error.hashEditDoesNotMatch")){
            feedbackService.notifyToast(t('rejected.someone.edited.same.time'),"error",{duration:6000});
            let idPo = productionOrderSelectionStore.productionOrder.Id;
            let idPop = productionOrderSelectionStore.productionRecord.Id_ProductionOrder_Product;
            let idPr = productionOrderSelectionStore.productionRecord.Id;
   
            history.push({
                pathname: "/page-redirector",
                state: { redirectTo:         "/feature/production-order/" +
                idPo +
                "/po-product/" +
                idPop +
                "/production/" +
                idPr, },
              });
            
            
            return;
        }
     
        feedbackService.notifyToast(t('failed.to.save'),"error");

    
    }
    
    return {handleSaveErrors};

}

export default usePrTempStore;