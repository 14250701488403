interface IStockMovementProductParameterizedVariableData {
  Value: string;
  Id_Product_ParameterizedVariable: number | string;
}

interface IProductParameterizedVariableData {
  Id_ParameterizedVariable: number | string;
  Id_Product_ParameterizedVariable: number | string;
}

interface IParameterizedVariableData {
  Id_ParameterizedVariable: number;
  name?: string;
  code?: string;
  searchable?: string;
  description?: string;
  varType?: string;
  varTypeOptionSelect_Opts?: string;
}

//all the existing manually mapped parameterized variables should be migrated to this methods gradually. Note: it might be needed to change Id from Id_Product_ParameterizedVariable or Id to Id_ParameterizedVariable on the backend before passing data to this service
function ParameterizedVariablesMapperService() {
  /**
   *
   * @param stockMovementProductParameterizedVariableValuesDataArray
   * @param productParameterizedVariablesDataArray
   * @param parameterizedVariablesDataArray
   *
   * @return an array stockMovementProductParameterizedVariableValuesDataArray with all the information relative to the paramterized variables
   */
  const arrFillStockMovementProductParameterizedVariables = (
    stockMovementProductParameterizedVariableValuesDataArray: IStockMovementProductParameterizedVariableData[],
    productParameterizedVariablesDataArray: IProductParameterizedVariableData[],
    parameterizedVariablesDataArray: IParameterizedVariableData[]
  ) => {
    productParameterizedVariablesDataArray =
      ParameterizedVariablesMapperService().arrFillProductParameterizedVariables(
        productParameterizedVariablesDataArray,
        parameterizedVariablesDataArray
      );
    for (
      let i = 0;
      i < stockMovementProductParameterizedVariableValuesDataArray.length;
      i++
    ) {
      let found = false;
      for (let j = 0; j < productParameterizedVariablesDataArray.length; j++) {
        if (
          Number(
            stockMovementProductParameterizedVariableValuesDataArray[i][
              "Id_Product_ParameterizedVariable"
            ]
          ) ===
          Number(
            productParameterizedVariablesDataArray[j][
              "Id_Product_ParameterizedVariable"
            ]
          )
        ) {
          stockMovementProductParameterizedVariableValuesDataArray[i] = {
            ...stockMovementProductParameterizedVariableValuesDataArray[i],
            ...productParameterizedVariablesDataArray[j],
          };
          found = true;
          break;
        }
      }

      if (!found) {
        throw "stockMovementProductParameterizedVariable didnt find a productParameterizedVariable match";
      }
    }

    return stockMovementProductParameterizedVariableValuesDataArray;
  };

  /**
   *
   * @param productParameterizedVariablesDataArray
   * @param parameterizedVariablesDataArray
   *
   * @return returns an array with productParameterizedVariablesDataArray with all the information relative to the paramterized variables
   */
  const arrFillProductParameterizedVariables = (
    productParameterizedVariablesDataArray: IProductParameterizedVariableData[],
    parameterizedVariablesDataArray: IParameterizedVariableData[]
  ) => {
    for (let i = 0; i < productParameterizedVariablesDataArray.length; i++) {
      let found = false;
      for (let j = 0; j < parameterizedVariablesDataArray.length; j++) {
        if (
          Number(
            productParameterizedVariablesDataArray[i][
              "Id_ParameterizedVariable"
            ]
          ) ===
          Number(parameterizedVariablesDataArray[j]["Id_ParameterizedVariable"])
        ) {
          productParameterizedVariablesDataArray[i] = {
            ...productParameterizedVariablesDataArray[i],
            ...parameterizedVariablesDataArray[j],
          };
          found = true;
          break;
        }
      }

      if (!found) {
        throw "productParameterizedVariable didnt find a parameterizedVariable match";
      }
    }

    return productParameterizedVariablesDataArray;
  };

  return {
    arrFillStockMovementProductParameterizedVariables,
    arrFillProductParameterizedVariables,
  };
}

export default ParameterizedVariablesMapperService;
