import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { ellipsisVerticalCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import productionOrdersController from "../../../barrel/controllers/productionOrdersController";
import ProductionOrdersDao from "../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import MultiProductionRecordDto from "../../../barrel/dataTransferObjects/multiProductionRecordDto";
import ProductionRecordDto from "../../../barrel/dataTransferObjects/productionRecordDto";
import usePunchclock from "../../../barrel/hooks/usePunchclock";
import useStateCallback from "../../../barrel/hooks/useStateCallback";
import useSyncSettings from "../../../barrel/hooks/useSyncSettings";
import HxfBarcodeScanner, {
  IHxfBarcodeScanFormat,
  IHxfBarcodeSettings,
} from "../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import CustomUnavailableErrorNotice from "../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import { dispatchCustomPopupsStore } from "../../CustomElements/ProductionUI/CustomPopups/CustomPopupsStore";
import SimpleContentPopup from "../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";

import ProgressBar from "../../CustomElements/ProgressBar/ProgressBar";

import AppOfflineInaccessible from "../../ErrorPages/AppOfflineInaccessible/AppOfflineInaccessible";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import styles from "./MaterialStaging.module.scss";
import ScannablesService from "../../../barrel/services/ScannablesService";
import { useCancelToken } from "../../../barrel/hooks/useCancelToken";
import MobileWrapperCommunicationService from "../../../barrel/services/mobileWrapperCommunicationService";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import ScanBarcodePopup from "../ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import DatesConversionService from "../../../barrel/services/datesConversionService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";

function MaterialStaging(props: any) {
  const { cancelToken, isCancel } = useCancelToken({
    alias: "ProductionOrdersStandard",
  });
  const feedbackService = useFeedbackService();
  const punchclock = usePunchclock();
  const { sessionState } = useGlobalState();
  const {t} = useHxfTranslation();
  const syncSettings = useSyncSettings();
  const [didMount, setDidMount] = useState(false);

  const sizePage = 25;
  const [loadedPOS, setLoadedPOS] = useStateCallback(null);
  const loadedPOSRef = useRef<any>(null);
  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [activeProductionsButtonData, setActiveProductionsButtonData] =
    useState<any>(null);
  const [simultaneousOperationPopsData, setSimultaneousOperationPopsData] =
    useState<any>(null);
  const [
    activeMultiProductionsButtonData,
    setActiveMultiProductionsButtonData,
  ] = useState<any>(null);
  const posContainerRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);

  const autoSelScannerSettings = useRef<IHxfBarcodeSettings>({
    useCamera: false,
    useExternalDevice: false,
    allowedScanFormats: [],
  });
  function isHidden(el: any) {
    return el.offsetParent === null;
  }

  const loadMoreProductionOrders = useCallback(() => {
    let currentPage = allowedInfiniteScrollLoadPageRef.current;
    //redirPunchclockMode
    


    productionOrdersController()
      .getActiveProductionOrders(
        {
          page: currentPage,
          size: sizePage,
          openOnly: false,
          getAllWorkerOnGoingProductions: false,
          getProductionOrdersSimultaneousOperations: false,
          getAllWorkerOnGoingMultiProductions: false,
          getViewRelevantFlags: false, //set to true to enable scanners etc..
          calculateMaterialStaging:true
        },
        cancelToken
      )
      .then((resp) => {
        syncSettings.check(resp);

        let posData = resp?.data?.response?.data?.return;
        if (!posData) {
          throw "unexpected data posData";
        }
        let requiresPunchclockIn =
          resp?.data?.response?.data?.extra?.requirePunchclockIn;
        if (requiresPunchclockIn) {
          punchclock.redirRequirePunchclockIn();
          return;
        }
        let posTotalSize = resp.data.response.data.totalSize;
        let posExtraData = resp.data.response.data.extra;

        if (currentPage === 0) {
          //first load only
          //loads the extraData that loads in the backend only when the page = 0
          //this is done to avoid double requests just to gather extra unrelated data



          let allowedScans: IHxfBarcodeScanFormat[] = [];
          autoSelScannerSettings.current = {
            useExternalDevice:
              posExtraData?.flags
                ?.flag_shopfloor_productionOrders_autoSel_useExternal === 1
                ? true
                : false,
            useCamera:
              posExtraData?.flags
                ?.flag_shopfloor_productionOrders_autoSel_useCamera === 1
                ? true
                : false,
            allowedScanFormats: [],
            initCameraOnStartUp: false,
          };

          if (
            autoSelScannerSettings.current.useCamera &&
            !autoSelScannerSettings.current.useExternalDevice
          ) {
            autoSelScannerSettings.current.initCameraOnStartUp = true;
          }
          //checking for auto selection scan
          if (
            posExtraData?.flags
              ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode39
          ) {
            let scanType: IHxfBarcodeScanFormat = {
              type: "barcode39",
              pattern:
                posExtraData?.flags
                  ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode39,
            };
            allowedScans.push(scanType);
            //allow fallback method to production order Id only, ex: if it accepts [poid,popid] and doesnt find popid only poid
            if (
              posExtraData?.flags
                ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode39 !==
              "[productionorder.id]"
            ) {
              let scanType2: IHxfBarcodeScanFormat = {
                type: "barcode39",
                pattern: "[productionorder.id]",
              };
              allowedScans.push(scanType2);
            }
          }


          if (
            posExtraData?.flags
              ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode128
          ) {
            let scanType: IHxfBarcodeScanFormat = {
              type: "barcode128",
              pattern:
                posExtraData?.flags
                  ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode128,
            };
            allowedScans.push(scanType);
            //allow fallback method to production order Id only, ex: if it accepts [poid,popid] and doesnt find popid only poid
            if (
              posExtraData?.flags
                ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode128 !==
              "[productionorder.id]"
            ) {
              let scanType2: IHxfBarcodeScanFormat = {
                type: "barcode128",
                pattern: "[productionorder.id]",
              };
              allowedScans.push(scanType2);
            }
          }


          if (
            posExtraData?.flags
              ?.setting_shopfloor_productionOrders_autoSel_labelScan_qr
          ) {
            let scanType: IHxfBarcodeScanFormat = {
              type: "qrcode",
              pattern:
                posExtraData?.flags
                  ?.setting_shopfloor_productionOrders_autoSel_labelScan_qr,
            };
            allowedScans.push(scanType);

            //allow fallback method to production order Id only, ex: if it accepts [poid,popid] and doesnt find popid only poid
            if (
              posExtraData?.flags
                ?.setting_shopfloor_productionOrders_autoSel_labelScan_qr !==
              "[productionorder.id]"
            ) {
              let scanType2: IHxfBarcodeScanFormat = {
                type: "qrcode",
                pattern: "[productionorder.id]",
              };
              allowedScans.push(scanType2);
            }
          }
          autoSelScannerSettings.current.allowedScanFormats = allowedScans;
          //-----------------

         
        }

        let newArrayLoadedPOS: any = [];

        if (loadedPOSRef.current !== null) {
          newArrayLoadedPOS = [...loadedPOSRef.current, ...posData];
        } else {
          newArrayLoadedPOS = posData;
        }

        loadedPOSRef.current = newArrayLoadedPOS;

        let hasMoreElements =
          newArrayLoadedPOS.length < posTotalSize && posData.length !== 0;
        if (hasMoreElements) {
          allowedInfiniteScrollLoadPageRef.current =
            allowedInfiniteScrollLoadPageRef.current + 1;
          infiniteScrollEnabledRef.current = true;
        } else {
          infiniteScrollEnabledRef.current = false;
        }
        if (currentPage === 0) {
          setLoadingPage(false);
        }
        setLoadedPOS(loadedPOSRef.current);
        setIsLoadingMore(false);
        setIndexReRender(indexReRender + 1);
      })
      .catch((resp) => {
        console.log("THE ERROR: ", resp);
        if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
          if (failedToLoadPosError) {
            setFailedToLoadPosError(false);
          }
          setRequiresNetworkLoadError(true);
        } else {
          if (requiresNetworkLoadError) {
            setRequiresNetworkLoadError(false);
          }
          setFailedToLoadPosError(true);
        }
        console.log("FAILED::", resp);

        setLoadingPage(false);
      });
  }, [
    cancelToken,
    syncSettings,
    punchclock,
    failedToLoadPosError,
    indexReRender,
    setLoadedPOS,
    requiresNetworkLoadError,
  ]);

  useEffect(() => {
    if (!didMount) {
      loadMoreProductionOrders();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,

    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadMoreProductionOrders,
  ]);

  const formattedStagedVal = (val:any) => {
    
    return val.toFixed(2);
  }

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/home" />
      <div className={styles.pageTitle}>{t('materialstaging')}</div>

      {showBarcodeScannerPopup && (
        <ScanBarcodePopup
        freeScannerMode={true}
          title="Scanner"
          visible={true}
          barcodeScannerSettings={autoSelScannerSettings.current}
          onClosedCamera={() => {
            if (
              MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
              autoSelScannerSettings.current.useCamera &&
              !autoSelScannerSettings.current.useExternalDevice
            ) {
              setShowBarcodeScannerPopup(false);
            }
          }}
          onInvalidScan={() => {
            feedbackService.notifyToast("Invalid code scanned","warning");
          }}
          onFinishedScan={(result: any) => {
            console.log("RESULT: ", result);
         
            let format = result.resultFormat;
            let resultScan = result.resultScan;
            let delimiter = null;

            if (format.type === "barcode39") {
              delimiter = ScannablesService().hxfBarcode39Delimiter;
            }
            if (format.type === "barcode128") {
              delimiter = ScannablesService().hxfBarcode128Delimiter;
            }

            if (format.type === "qrcode") {
              delimiter = ScannablesService().hxfQrcodeDelimiter;
            }

            let pattern = format.pattern;
            let splittedPattern = pattern.split(delimiter);

            let indexThatIdentifiesTheProductionOrder = -1;
            for (let i = 0; i < splittedPattern.length; i++) {
              if (splittedPattern[i] === "[productionorder.id]") {
                indexThatIdentifiesTheProductionOrder = i;
                break;
              }
            }

            let indexThatIdentifiesTheProductionOrderProductId = -1;
            for (let i = 0; i < splittedPattern.length; i++) {
              if (splittedPattern[i] === "[productionorder.poproduct.id]") {
                indexThatIdentifiesTheProductionOrderProductId = i;
                break;
              }
            }

            if (indexThatIdentifiesTheProductionOrder !== -1) {
            
              let splittedResult = resultScan.split(delimiter);
              if (splittedResult.length !== splittedPattern.length) {
             
                console.log("Scan does not match the pattern");
                feedbackService.notifyToast("Invalid code scanned","warning");
              } else {
             
                let productionOrderIdentifiableValue =
                  splittedResult[indexThatIdentifiesTheProductionOrder];
                console.log(
                  "Now redir to po with id val",
                  productionOrderIdentifiableValue
                );
                //todo check if it is po ID then simply redirect to the proper url, if its a code then check for production orders by code etc
                let redirLinkBuilder =
                  "/feature/production-order/" +
                  productionOrderIdentifiableValue;

                if (indexThatIdentifiesTheProductionOrderProductId !== -1) {
                  if (
                    splittedResult[
                      indexThatIdentifiesTheProductionOrderProductId
                    ]
                  ) {
                    redirLinkBuilder =
                      redirLinkBuilder +
                      "/po-product/" +
                      splittedResult[
                        indexThatIdentifiesTheProductionOrderProductId
                      ];
                  }
                }

                props.history.push(redirLinkBuilder);
              }
            }else{
              feedbackService.notifyToast("Invalid code scanned","warning");
            }
          }}
          onClosePopup={() => {
            setShowBarcodeScannerPopup(false);
          }}
        />
      )}

      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.productionOrdersContainer}>
        <div
          id="productionOrdersSelectionDiv"
          key={"rerender_ref_selection_" + indexReRender}
          className={styles.productionOrdersSelection}
          ref={posContainerRef}
        >
          {!failedToLoadPosError && loadedPOS !== null ? (
            <>

              {autoSelScannerSettings?.current?.allowedScanFormats && autoSelScannerSettings?.current?.allowedScanFormats?.length >
                0 &&
                (MobileWrapperCommunicationService().isDeviceMobileWrapper() ||
                  (!MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
                    autoSelScannerSettings.current.useExternalDevice)) && (
                  <IonCardHeader
                    className={`${styles.elemElement}`}
                    onClick={() => {
                      setShowBarcodeScannerPopup(true);
                    }}
                  >
                    <div className={styles.elemBackgroundContainer}>
                    <QrCodeScannerIcon />
                    </div>
                    <IonCardSubtitle>
                      <div className={styles.scannerDeviceElemTitle}><b>Scanner</b></div>
                    </IonCardSubtitle>
                    <IonCardTitle
                      className={styles.elemElementDescription}
                    ></IonCardTitle>
                  </IonCardHeader>
                )}
 
              {loadedPOSRef.current.map((obj: any, index: number) => (
                <IonCardHeader
                  key={index}
                  className={styles.elemElement}
                  onClick={() => {
                    
                    props.history.push("/feature/material-staging/production-order/" + obj.Id);
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <LocalShippingIcon />
                  </div>
                  <div>
                    <div className={styles.label}>{t("productionorder")}</div>
                    <div className={`${styles.labelContent} ${styles.poCodeName}`}>({obj.code}) {obj.name}</div>
                  </div>

                  <div className={styles.stagedProgressContainer}>

                    <div>
                      <small><b>{t("staged.material")}</b></small>
                      <ProgressBar pgValue={formattedStagedVal(obj.stagedMaterial_pct)} />
                    </div>
    
                  </div>
                </IonCardHeader>
              ))}
            </>
          ) : (
            <></>
          )}

          {isLoadingMore && (
            <IonCardHeader className={styles.elemElement} onClick={() => {}}>
              <div className={styles.elemBackgroundContainer}>
                <LocalShippingIcon />
              </div>
              <IonCardSubtitle>
                <div className={styles.elemElementTitle}>Loading...</div>
              </IonCardSubtitle>
              <IonCardTitle className={styles.elemElementDescription}>
                <IonSpinner />
              </IonCardTitle>
            </IonCardHeader>
          )}

          <HxfInfiniteScroll
            containerElementRef={posContainerRef}
            requiresContainerHavingScrollbar={true}
            onLoadMore={() => {
              /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
            
              if(allowingVal > 7){
                return;
              }
              console.log("allowing more :", allowingVal);
               allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
              */

              if (infiniteScrollEnabledRef.current) {
                setIsLoadingMore(true);
                loadMoreProductionOrders();
              }
            }}
            allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
          />
        </div>
      </div>
      {failedToLoadPosError ? (
        <div className={styles.errorArea}>
          <CustomUnavailableErrorNotice />
        </div>
      ) : (
        <></>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}
    </InAppTemplate>
  );
}

export default MaterialStaging;
