import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";


import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import useProductionProcess from "../../../../../../barrel/hooks/useProductionProcess";
import SimpleContentPopup from "../../../SimpleContentPopup/SimpleContentPopup";
import styles from "./PopupRemoveCancellation.module.scss";
import HomeIcon from '@mui/icons-material/Home';
import RestoreIcon from '@mui/icons-material/Restore';
import WarningIcon from '@mui/icons-material/Warning';
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { useHistory } from "react-router";
import productionController from "../../../../../../barrel/controllers/productionController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
interface IProps{

    onSuccessRevokedCancellation?:any;
}

function PopupRemoveCancellation(props: IProps){
  
    const {t} = useHxfTranslation();
    const currentProductionOrderSelectionData = useProductionOrderSelectionStore();
    const history = useHistory();
    const feedbackService = useFeedbackService();
    const myLocalStorage = useLocalStorageHandler();

    return (
    <>
        <SimpleContentPopup>
        <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t("restore.record")}</div>

            {/*
            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                props.onClose();
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>*/}
          </div>
          <div className={styles.content}>
            <div style={{display:'flex', justifyContent:'center',marginTop:30,marginBottom:30}}>
              <div>
                <div style={{display:'flex', justifyContent:'center', marginBottom:20}}>
                    <WarningIcon fontSize="large" color="warning"/>
                </div>
                <div style={{display:'flex', justifyContent:'center',marginBottom:30,marginLeft:20,marginRight:20, fontSize:20}}>
                    {t('someone.cancelled.ask.revoke')}
                </div>

                <div className={styles.btnCancel}>
                      <IonButton
                       style={{minWidth:350}}
                        color="success"
                        size="large"
                        onClick={() => {
                            let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
                            let idPr = currentProductionOrderSelectionData?.productionRecord?.Id;
                            productionController().undoCancel({workerCode:currentWorkerCode, Id_ProductionRecord:idPr}).then((res) => {
                                if(res?.data?.undone !== "ok"){
                                    throw "unexpected response";
                                }
                                feedbackService.notifyToast(t('record.cancel.undone.success'), "info");

                                props.onSuccessRevokedCancellation();
                                
                            }).catch((res) => {
                                feedbackService.notifyToast(t('failed.error.generic'), "error");
                            });
                        }}
                    >
                       <span style={{marginRight:5}}><RestoreIcon fontSize="large"/></span>
                        
                        {t('restore.record')}
                
                    </IonButton>
                </div>
                <div className={styles.orSeparate}>
                  {t('or')}
                </div>
                <div className={styles.btnCancel}>

                    <IonButton
                        style={{minWidth:350}}
                        size="large"
                        onClick={() => {
                            let idPo = currentProductionOrderSelectionData.productionOrder.Id;
                            let idPop = currentProductionOrderSelectionData?.productionRecord?.Id_ProductionOrder_Product;
                            
                            history.push("/feature/production-order/" + idPo + "/po-product/" + idPop);
                        }}
                    >
                        <span style={{marginRight:5}}><HomeIcon fontSize="large"/></span>
                        {t('exit')}
                
                    </IonButton>
                </div>
              </div>


            </div>

          </div>
          
        </div>


  
      </div>
        </SimpleContentPopup>
    </>)
}

export default PopupRemoveCancellation;