import { IonSpinner } from "@ionic/react";
import { styled } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useOngoingUnavailabilityOccurrenceHook from "../../../../FeaturePages/OnGoingUnavailabilityOccurrence/store/onGoingUnavailabilityOccurrenceStore";
import HxfIonLoading from "../../../HxfIonLoading/HxfIonLoading";
import UnavailabilityActionAskCommentConfirmationButtons from "./ConfirmationButtons/UnavailabilityActionAskCommentConfirmationButtons";
import styles from "./UnavailabilityActionAskComment.module.scss";
import { Checkbox, TextField } from "@mui/material";
interface IProps{
    onConfirm?:any;
    onCancel?:any;
}

export const getUnavailabilityActionAskCommentCode = () => {
    return "UNAVACT_ASK_COMMENT";
}

const StyledRadio = styled(Radio)(({ theme }) => ({

    "& .MuiSvgIcon-root": {
      width:45,
      height:45
    },
  
  }));

function UnavailabilityActionAskComment(props:IProps){

    const [adjustingToProducts, setAdjustingToProducts] = useState<any>([null]);
    const [didMount, setDidMount] = useState(false);
    const prevDidMount = usePrevious(didMount);
    const {t} = useHxfTranslation();
    const occurrenceState = useOngoingUnavailabilityOccurrenceHook();
    const {sessionState} = useGlobalState();
    const [returningObj, setReturningObj] = useState<any>({comment:null});
    
    const [isLoading, setIsLoading] = useState(true);


    const getAskWorkerElement = () => {

        let currentActionData = occurrenceState.getCurrentUnavailabilityAction();

 

        return (
            <div>


                <div style={{textAlign:'center'}}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
           
                    </FormLabel>
                    <div style={{fontSize:20}}>
                    <TextField
                        disabled={false}
                        error={false}
                        onChange={(evt) => {
                            
                            setReturningObj({...returningObj, comment:evt.target.value});
                        }}
                        type={"text"}
                        value={returningObj.comment !== null ? returningObj.comment : ""}
                        fullWidth={true}
                        label={t('comment.obs')}
                        variant="outlined"

                        style={{textAlign: 'left'}}
                        
                        
                        multiline
                        rows={7}
                    />
                    </div>

                </FormControl>
                </div>
                
            </div>
        )
    }
   


    useEffect(() => {
        if(!didMount){

            setDidMount(true);
        }
    },[didMount,occurrenceState,props,returningObj,sessionState]);

    useEffect(() => {
        //after mounting
        if(!prevDidMount && didMount){

        

            setIsLoading(false);
        }
    }, [didMount,occurrenceState,props,returningObj,sessionState, prevDidMount]);


    if(isLoading){
        return (
            <>
             
               <div className={styles.content}>
                <div style={{marginBottom:50, marginTop:50}}>
                   
                    <IonSpinner/>
                    
                </div>
            </div>
            </>
        )
    }


    return (
        <>
            <div className={styles.content}>
                <div style={{marginBottom:50, marginTop:50}}>
                   
                  {getAskWorkerElement()}
                    
                </div>
            </div>
            <div>
               <UnavailabilityActionAskCommentConfirmationButtons
                disabled={false}
                onConfirm={() => {
                    //validate etc..
                   //yes or no
                    props.onConfirm({
                        ...returningObj
                    });
             
                }}
                />
            </div>
        </>
    )
}

export default UnavailabilityActionAskComment;