import { getCurrentTimestampSeconds } from "../utils/TimeUtils";
function useLocalStorageHandler() {

  const isWaitingToReloadWhenPossible = () => {
    return localStorage.getItem("reload_when_possible") === "true";
  }
  /**
   * when the user is not doing anything relevant, ex: finishes a production record, the paeg reloads
   */
  const setReloadWhenPossible = () => {
    localStorage.setItem("reload_when_possible","true");
  }

  const clearWaitingToReloadWhenPossible = () => {
    localStorage.removeItem("reload_when_possible");
  }

  /**
   *
   * @param mode single multi worker mode, SMW_MODE_MULTI means it will ask for user ID every time a feature is clicked, SMW_MODE_SINGLE means it will not ask every time, worker stays identified the same
   */
  const setSMWMode = (mode: "SMW_MODE_MULTI" | "SMW_MODE_SINGLE") => {
    localStorage.setItem("SMW_MODE", mode);
  };
  const getSMWMode = () => {
    let res = localStorage.getItem("SMW_MODE");

    if (!res) {
      return "SMW_MODE_SINGLE";
    }
    return res;
  };
  const clearRefreshToken = () => {
    console.log("deleting rt");
    localStorage.removeItem("refresh_token");
  };
  const saveRefreshToken = (refreshToken: any) => {
    localStorage.setItem("refresh_token", refreshToken);
  };

  const getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };

  const updateLatestOnlineActivityTs = () => {
    let lastOnlineTime: any = getCurrentTimestampSeconds();
    localStorage.setItem("lastTimeOnline", lastOnlineTime);
  };

  const getLatestOnlineActivityTs = () => {
    return localStorage.getItem("lastTimeOnline");
  };

  const getWorkerIdentificationCode = () => {
    return localStorage.getItem("workerIdentificationCode");
  };

  const setWorkerIdentificationCode = (code: any) => {
    localStorage.setItem("workerIdentificationCode", code);
  };
  const clearWorkerIdentificationCode = () => {
    localStorage.removeItem("workerIdentificationCode");
  };

  return {
    clearWorkerIdentificationCode,
    setWorkerIdentificationCode,
    getWorkerIdentificationCode,
    updateLatestOnlineActivityTs,
    getLatestOnlineActivityTs,
    clearRefreshToken,
    saveRefreshToken,
    getRefreshToken,
    setSMWMode,
    getSMWMode,
    setReloadWhenPossible,
    isWaitingToReloadWhenPossible,
    clearWaitingToReloadWhenPossible
  };
}

export default useLocalStorageHandler;
