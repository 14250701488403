import axios from "axios";
import { getCookie } from "../utils/CookieUtils";
import entityUserController from "./entityUserController";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
function BarrelWrapper() {
  const { getSessionCheckRoute, getLogoutSessionRoute } =
    entityUserController();

  const getReqOptionsCancelToken = (reqOptions, cancelToken) => {
    if (cancelToken) {
      reqOptions["cancelToken"] = cancelToken;
    }

    return reqOptions;
  };

  const appPostRequest = (url, postData = {}, cancelToken = null) => {
    let reqOptions = { withCredentials: true };
    reqOptions = getReqOptionsCancelToken(reqOptions, cancelToken);

    return ssServePostRequest(url, postData, reqOptions);
  };

  const ssServePostRequest = (url, postData, extraOptions) => {
    let options = {};

    let headers = {};


    let includeXsrf = false;

    let allowedSfsBackend = false;
    if (process.env.REACT_APP_MODE === "dev") {
      if (url.startsWith(process.env.REACT_APP_API_SERVE_DEV)) {
        includeXsrf = true;
        allowedSfsBackend = true;
      }
    }else{
      let allowedBackends = process.env.REACT_APP_ALLOWED_LIVE_API_SERVES;
      let arrayAllowedBackends = allowedBackends.split(",");
      for(let i = 0; i<arrayAllowedBackends.length; i++){
        if(url.startsWith(arrayAllowedBackends[i])){
          includeXsrf = true;
          allowedSfsBackend = true;
        }
      }
    }

    if (includeXsrf) {
      //include header if the request goes to the API serve
      headers = {
        "xsrf-token": getCookie(process.env.REACT_APP_APPNAME_PREFIX + "_WS_csrf"),
      };
    }

    if (
      allowedSfsBackend && (
      url.endsWith(getSessionCheckRoute()) ||
      url.endsWith(getLogoutSessionRoute()))
    ) {
      headers["refresh-token"] = localStorage.getItem("refresh_token");
      if (url.endsWith(getLogoutSessionRoute())) {
        localStorage.removeItem("refresh_token");
      }
    }

    options["headers"] = headers;

    options = { ...options, ...extraOptions };

    return axios.post(url, postData, options);
  };

  const simplePostRequest = (url, postData, extraOptions) => {
    let options = {};
    let headers = {};
    options["headers"] = headers;
    options = { ...options, ...extraOptions };
    return axios.post(url, postData, options);
  };
  const simpleGetRequest = (url) => {

    return axios.get(url);
  };
  return { appPostRequest, simplePostRequest,simpleGetRequest };
}

export default BarrelWrapper;
