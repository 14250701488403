import { IonCardTitle } from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";



import styles from "./StartWorkerDowntime.module.scss";

import PanToolIcon from '@mui/icons-material/PanTool';
import machinesController from "../../../barrel/controllers/machinesController";
import { useCancelToken } from "../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import unavailabilitiesController from "../../../barrel/controllers/unavailabilitiesController";
import workerController from "../../../barrel/controllers/workerController";
import useSyncSettings from "../../../barrel/hooks/useSyncSettings";

function StartWorkerDowntime(props:any) {
    
  const { cancelToken, isCancel } = useCancelToken();
  const paramsData = props?.location?.state; 
  const myLocalStorage = useLocalStorageHandler();
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const timerRecalc = useRef<any>(null);
  const syncSettings = useSyncSettings();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedMachine,setSelectedMachine] = useState({})
  const [loadedUnavailabilities,setLoadedUnavailabilities] = useState(null);
  const {t} = useHxfTranslation();
  const feedbackService = useFeedbackService();


  const getWorkerDowntimes = useCallback(() => {

    let unavs:any = loadedUnavailabilities;
    if(!unavs){
        return [];
    }
    //order desc
    unavs.sort((a:any, b:any) => {
        if (a.code < b.code) {
          return 1; // swap the elements
        } else if (a.code > b.code) {
          return -1; // maintain the order
        }
        return 0; // leave the elements unchanged with respect to each other
    });
    return unavs;
  },[loadedUnavailabilities]);

  useEffect(() => {
   
    if (!didMount) {
   
      let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
      unavailabilitiesController().getListJson({onlyWorkerUnavs:true,filterByAllowedEntityUser:true},{workerCode:currentWorkerCode}).then((res) => {
        syncSettings.check(res);
        let unavs = res?.data?.response?.data?.return;
        if(!unavs){
          throw "unexpected error load unavs";
        }
       
        setLoadedUnavailabilities(unavs);
        setLoadingPage(false);
      }).catch((res) => {
          feedbackService.notifyToast(t('generic.critical.error'), "error");
          props.history.push({
              pathname:"/",
              state: {},
            });
            return;
      });

      setDidMount(true);
    }
  }, [
    didMount,
    feedbackService,
    t,
    props.history,
    syncSettings,
    myLocalStorage
  ]);





  const getInitiateUnavailabilityButton = (obj:any) => {

    return (
      <div
      className={`${styles.elemElement} ${styles.unavElem}`}
      onClick={() => {
            let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
            setLoadingPage(true);
            workerController().startDowntime({workerCode:currentWorkerCode}, obj.Id, cancelToken).then((res:any) => {
                let createdIdUnavOccurrence = res?.data?.response?.Id_UnavailabilityOccurrence;
                if(!createdIdUnavOccurrence){
                    throw "error invalid created unav occur";
                }
            
                //todo redir to occurrence
                props.history.push({
                    pathname:"/feature/downtime/occurrence/" + createdIdUnavOccurrence,
                  });
          
             
                setLoadingPage(false);
            }).catch((res:any) => {
                if (isCancel(res)) {
                    return;
                }
                setLoadingPage(false);
                let result = res?.response?.data?.response;
                if(result?.issues?.errors.includes("error.thisUnavailabilityAlreadyRunning")){
                    feedbackService.notifyToast(t('unav.already.running.worker'), "error");
                    return;
                }
                if(result?.issues?.errors.includes("error.activeProductions")){
                  feedbackService.notifyToast(t('must.finish.active.productions'), "error");
                  return;
              }
              
             
                feedbackService.notifyToast(t('generic.critical.error'), "error");
            });
        
      }}
    >
        
      <div className={styles.actionBtnContainer}>
      <div className={styles.elemBackgroundContainer}>
        <PanToolIcon />
      </div>
        <div className={`${styles.elemElementTitle}`}>
          {obj.name}
        </div>

      </div>
      <IonCardTitle className={styles.elemElementDescription}>
      
      </IonCardTitle>

    </div>
    )
  }

  if(loadingPage || !loadedUnavailabilities){
    return (

        <InAppTemplate>
          <CustomArrowBack pushUrl="/" />
          <div className={styles.pageTitle}>{!loadingPage && (t('downtime.reason'))}</div>
          {loadingPage && <LoadingSpinnerImg />}
    
        </InAppTemplate>
      );
  }

  return (

    <InAppTemplate>
      <CustomArrowBack pushUrl="/" />
      <div className={styles.pageTitle}>{!loadingPage && (t('downtime.reason'))}</div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
            {getWorkerDowntimes().length > 0 && (
                <>
                {getWorkerDowntimes().map((obj:any,index:any) => (
                    <div key={"dt_reason_" + index}>
                        {getInitiateUnavailabilityButton(obj)}
                    </div>
                ))}
                </>
            )}
            {getWorkerDowntimes().length === 0 && (
                <>
                <div>{t('no.possible.downtimes')}</div>
                </>
            )}
        </div>
      </div>
    </InAppTemplate>
  );
}

export default StartWorkerDowntime;
