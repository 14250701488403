import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
interface IProps{
    onClick?:any;
}
function HxfOverlayLoading(props:IProps){

    return (<>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {
                if(props?.onClick){
                    props.onClick();
                }
               
            }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>)
}

export default HxfOverlayLoading;