import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";
import productsController from "../../../../../barrel/controllers/productsController";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useOngoingUnavailabilityOccurrenceHook from "../../../../FeaturePages/OnGoingUnavailabilityOccurrence/store/onGoingUnavailabilityOccurrenceStore";
import HxfSelectorField from "../../../HxfSelectorField/HxfSelectorField";
import SimpleContentPopup from "../../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import UnavailabilityActionAdjustMachineToProductConfirmationButtons from "./ConfirmationButtons/UnavailabilityActionAdjustMachineToProductConfirmationButtons";
import DefineProductParameterizedVariableValuesPopup from "../../../statePopups/defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import styles from "./UnavailabilityActionAdjustMachineToProduct.module.scss";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import PopupAdjustMachineProductTransformation from "./PopupAdjustMachineProductTransformation/PopupAdjustMachineProductTransformation";
import unavailabilitiesController from "../../../../../barrel/controllers/unavailabilitiesController";
import HxfIonLoading from "../../../HxfIonLoading/HxfIonLoading";
import useSyncSettings from "../../../../../barrel/hooks/useSyncSettings";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useMachineManagementShortcutReceiverStore } from "../../../../../barrel/hooks/useMachineManagementShortcutReceiver/machineManagementShortcutReceiverStore";
import ProductService from "../../../../../barrel/services/productService";
interface IProps{
    onConfirm?:any;
    onCancel?:any;
}

interface IBaseAdjustToProduct{
    Id_Product:any,
    name:any,
    code:any,
    arrayProductParameterizedVariables:any,
    transformations:any,
    _indexSelected:any
}

export const getUnavailabilityActionAdjustMachineToProductCode = () => {
    return "UNAVACT_ADJUST_MACHINE_TO_PRODUCT";
}

export const isTheSameProduct = (product1:any, product2:any) => {

    
    if(!product1 || !product2){
        return false;
    }
    if(product1.Id_Product !== product2.Id_Product){
        return false;
    }

    let productPVVs = product1?.arrayProductParameterizedVariables ? product1?.arrayProductParameterizedVariables : [];
    let productPVVs2 = product2?.arrayProductParameterizedVariables ? product2?.arrayProductParameterizedVariables : [];
    
    if(productPVVs.length !== productPVVs2.length){
        return false;
    }

    for(let i = 0; i<productPVVs.length; i++){
        let idPPv =  productPVVs[i].Id_Product_ParameterizedVariable;
        let pvValue = productPVVs[i].Value
        let found = false;
        for(let j = 0; j<productPVVs2.length; j++){
            let idPPv2 =  productPVVs2[j].Id_Product_ParameterizedVariable;
            let pvValue2 = productPVVs2[j].Value;
            if(idPPv === idPPv2 && pvValue === pvValue2){
                found = true;
                break;
            }
        }

        if(!found){
            return false;
        }
    }
 
    return true;
}

function UnavailabilityActionAdjustMachineToProduct(props:IProps){
    //const [adjustingToProduct, setAdjustingToProduct] = useState<any>(null);

    const initialAdjustingToProducts = [null];
    const [adjustingToProducts, setAdjustingToProducts] = useState<any>(initialAdjustingToProducts);
    
    const {t} = useHxfTranslation();
    const occurrenceState = useOngoingUnavailabilityOccurrenceHook();
    const syncSettings = useSyncSettings();
    const feedbackService = useFeedbackService();
    const [popupDefinedPVSData, setPopupDefinedPVSData] = useState<any>(null);
    const [popupDefinedProductTransformationData, setPopupDefinedProductTransformationData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const machineManagementShortcutReceiver = useMachineManagementShortcutReceiverStore();
    const getFilters = (index:any) => {
 
       let filters:any = {excludingProductIds:"",includeParameterizedVariables:true,includePVAdvancedSettings:true};
       let curActUnav = occurrenceState.getCurrentUnavailabilityAction();
       let filteredSearch = curActUnav?._actionData?.filteredSearch;
       if(filteredSearch === "RAW"){
        //only products that are raw materials
        filters.rawOnly = true;
       }else if(filteredSearch === "FINAL"){
        filters.productsWithOperationsOnly = true;
       }
   
        let idProductsToFilter = [];
        for(let i = 0; i<adjustingToProducts.length; i++){
            if(i !== index && adjustingToProducts[i]){
                let shouldFilter = false;
                //only add to filter if: has no ppvvs
                if( !adjustingToProducts[i]?.arrayProductParameterizedVariables || adjustingToProducts[i]?.arrayProductParameterizedVariables.length === 0){
                    shouldFilter = true;
                }
                if(shouldFilter){
                    idProductsToFilter.push(adjustingToProducts[i].Id_Product);
                }
                
            }
        }

       
        let stringExcludeProductIds = "";
        for(let i = 0; i<idProductsToFilter.length; i++){
            if(i !== 0){
                stringExcludeProductIds += ",";
            }
            stringExcludeProductIds += idProductsToFilter[i];
        }
        filters.excludingProductIds = stringExcludeProductIds;
    
        return filters;
    }


    const checkIfAddedProductWithId = (idProduct:any) => {
        for(let i = 0; i<adjustingToProducts.length; i++){
            if(adjustingToProducts[i]){
                //skip nulls

                if(adjustingToProducts[i].Id_Product === idProduct){
                    return true;
                }
            }
        }

        return false;
    }



    const getAdjustedProduct = ( index:any) => {
       
        let adjustingToProduct = adjustingToProducts[index];
        if(adjustingToProduct){
            return {value:adjustingToProduct.Id_Product, label:"(" + adjustingToProduct.code + ") " + adjustingToProduct.name};
        }

        return null;
    }

    const hasAtleastOneProduct = () => {
 
        for(let i = 0; i< adjustingToProducts.length; i++){
            if(adjustingToProducts[i] !== null){
                return true;
            }
        }
        return false;
    }

    const cancelAddedProduct = (index:any) => {
      
        let adjustingToProductsArray = [...adjustingToProducts];
        adjustingToProductsArray.splice(index, 1);
        setAdjustingToProducts(adjustingToProductsArray);
    }


    useEffect(() => {
        if(!didMount){
          
            let curActUnav = occurrenceState.getCurrentUnavailabilityAction();
            let preloadedAdjustedProducts = [];
            let SHOULD_PRELOAD_LATEST_ADJUSTMENT = curActUnav?._actionData?.preloadLatestAdjustment === 1;
            if(SHOULD_PRELOAD_LATEST_ADJUSTMENT){

                

                let unavailabilityOccurrenceData = occurrenceState?.occurrenceStore?.unavailabilityOccurrenceData;
                let machinesData = unavailabilityOccurrenceData?.machinesData;
                if(machinesData && machinesData.length > 0){
                    let loadAdjustmentsData = machinesData[0]?.adjustedToProducts ? machinesData[0]?.adjustedToProducts : [];
                    if(loadAdjustmentsData && loadAdjustmentsData.length > 0){
                        for(let i = 0; i<loadAdjustmentsData.length; i++){
                       
                            preloadedAdjustedProducts.push({
                                Id_Product:loadAdjustmentsData[i].Id_Product,
                                name:loadAdjustmentsData[i].productName,
                                code:loadAdjustmentsData[i].productCode,
                                arrayProductParameterizedVariables:loadAdjustmentsData[i]?.arrayProductParameterizedVariables ? loadAdjustmentsData[i]?.arrayProductParameterizedVariables : [],
                                transformations:loadAdjustmentsData[i]?.transformations ? loadAdjustmentsData[i]?.transformations : [],
                                _indexSelected:i
                                
                            });
                        }
                    }
                 
                }

            }
            //machineManagementShortcutReceiver
            let routeState = machineManagementShortcutReceiver.routeState;
            if(routeState?.isLoadingFromQuickShortcut)
            {
                let productSelected = routeState?.productSelected;
                let arrayProductParameterizedVariables = productSelected?.arrayProductParameterizedVariableValues ? productSelected.arrayProductParameterizedVariableValues : [];
                let shortcutAddSelectedProductAsAdjustment = curActUnav?._actionData?.shortcutAddSelectedProductAsAdjustment === 1;
                if(shortcutAddSelectedProductAsAdjustment){

                    let productToAdjustTo:IBaseAdjustToProduct = {
                        Id_Product:productSelected.Id,
                        name:productSelected.name,
                        code:productSelected.code,
                        arrayProductParameterizedVariables:arrayProductParameterizedVariables,
                        transformations:[],
                        _indexSelected:preloadedAdjustedProducts.length
                    };

                    preloadedAdjustedProducts.push(productToAdjustTo);
                    //todo handle ppvvs ??
                }
                let shortcutLoadedAdjustToBomProducts = curActUnav?._actionData?.shortcutAddBomFromSelectedProductAsAdjustment === 1;
                let unavActAdjustMTPCShortcutParameterizedVariables =  curActUnav?._actionData?.unavActAdjustMTPCShortcutParameterizedVariables;
                if(!unavActAdjustMTPCShortcutParameterizedVariables){
                    unavActAdjustMTPCShortcutParameterizedVariables = [];
                }
                let unavActAdjMTPCTransformedShortcutParameterizedVariables =  curActUnav?._actionData?.unavActAdjMTPCTransformedShortcutParameterizedVariables;
                if(!unavActAdjMTPCTransformedShortcutParameterizedVariables){
                    unavActAdjMTPCTransformedShortcutParameterizedVariables = [];
                }

                let shortcutLoadedAddBomAsTransformations = curActUnav?._actionData?.shortcutAdjustedProductAddBomAsTransformations === 1;;
              
              
                let bomProductsFromSelectedProduct = productSelected?.bomProducts;
                if(bomProductsFromSelectedProduct && shortcutLoadedAdjustToBomProducts){
                    let shortcutLoadedOnlyAdjustToProductsContainingSpecificPvIds:any = [];
                    for(let i = 0; i<unavActAdjustMTPCShortcutParameterizedVariables.length; i++){
                        shortcutLoadedOnlyAdjustToProductsContainingSpecificPvIds.push(unavActAdjustMTPCShortcutParameterizedVariables[i].Id_ParameterizedVariable);
                    }

                    let shortcutLoadedAskFillAdjustedProductPVVs = curActUnav?._actionData?.shortcutAdjustedProductsRequireRedefinePVVS === 1;
                    
                    /**
                     * from this product that was selected on the shortcut,
                     * add the product itself, undefined PV
                     * fill "transf"
                     */
                    for(let i = 0;i<bomProductsFromSelectedProduct.length; i++){
                        let bpArrayPPVVS = bomProductsFromSelectedProduct[i]?.arrayProductParameterizedVariables;
                        if(shortcutLoadedOnlyAdjustToProductsContainingSpecificPvIds && shortcutLoadedOnlyAdjustToProductsContainingSpecificPvIds.length > 0 && bpArrayPPVVS){
                            let foundAll = true;
                            for(let k = 0; k<shortcutLoadedOnlyAdjustToProductsContainingSpecificPvIds.length; k++){
                                let idPvCheck:any = shortcutLoadedOnlyAdjustToProductsContainingSpecificPvIds[k];
                                let found = false;
                                for(let j = 0;j<bpArrayPPVVS.length; j++){
                                    let idPvVerify:any = bpArrayPPVVS[j].Id_ParameterizedVariable;
                                    if(parseInt(idPvCheck) === parseInt(idPvVerify)){
                                        found = true;
                                    }
                                }
                                if(!found){
                                    foundAll = false;
                                    break;
                                }
                            }

                            if(!foundAll){
                                continue;
                            }

                            let newAdjProductArrayPPVVS = JSON.parse(JSON.stringify(bpArrayPPVVS));
                            if(shortcutLoadedAskFillAdjustedProductPVVs){
                                for(let j = 0; j<newAdjProductArrayPPVVS.length; j++)
                                {
                                    newAdjProductArrayPPVVS[j].Value = null;
                                }
                            }

                            let transformations = [];
                           
                            if(shortcutLoadedAddBomAsTransformations){

                                let shortcutLoadedOnlyTransformToProductsContainingSpecificPvIds:any = [];
                                for(let i = 0; i<unavActAdjMTPCTransformedShortcutParameterizedVariables.length; i++){
                                    shortcutLoadedOnlyTransformToProductsContainingSpecificPvIds.push(unavActAdjustMTPCShortcutParameterizedVariables[i].Id_ParameterizedVariable);
                                }

                                for(let j = 0;j<bomProductsFromSelectedProduct.length; j++){

                                    let pvs = bomProductsFromSelectedProduct[j].arrayProductParameterizedVariables;
                                    if(unavActAdjMTPCTransformedShortcutParameterizedVariables.length > 0){
                                        //should only allow transforming if contains the parameterized variables
                                        if(pvs.length !== shortcutLoadedOnlyTransformToProductsContainingSpecificPvIds.length){
                                            continue; //doesnt match doesnt have all for sure
                                        }
                                        let atLeastOneNotFound = false;
                                        for(let idxShortcutPVV = 0; idxShortcutPVV < shortcutLoadedOnlyTransformToProductsContainingSpecificPvIds.length; idxShortcutPVV++){
                                            let idPvRequired = shortcutLoadedOnlyTransformToProductsContainingSpecificPvIds[idxShortcutPVV];
                                            let foundPV = false;
                                            for(let idxPVBom = 0; idxPVBom < pvs.length; idxPVBom++){
                                                if(parseInt(pvs[idxPVBom].Id_ParameterizedVariable) === parseInt(idPvRequired)){
                                                    foundPV = true;
                                                }
                                            }
                                            if(!foundPV){
                                                atLeastOneNotFound = true;
                                            }
                                        }

                                        if(atLeastOneNotFound){
                                            continue;//doesnt match, ignore this product for transformations
                                        }
                                    }
                                    transformations.push({
                                        Id_Product:bomProductsFromSelectedProduct[j].Id_ProductChild,
                                        name:bomProductsFromSelectedProduct[j].childProduct_name,
                                        code:bomProductsFromSelectedProduct[j].childProduct_code,
                                        arrayProductParameterizedVariables:bomProductsFromSelectedProduct[j].arrayProductParameterizedVariables,
                                        Quantity:0
                                    });
                                }
                            }
                            
                            let productToAdjustTo:IBaseAdjustToProduct = {
                                Id_Product:bomProductsFromSelectedProduct[i].Id_ProductChild,
                                name:bomProductsFromSelectedProduct[i].childProduct_name,
                                code:bomProductsFromSelectedProduct[i].childProduct_code,
                                arrayProductParameterizedVariables:newAdjProductArrayPPVVS,
                                transformations:transformations,
                                _indexSelected:preloadedAdjustedProducts.length+i
                            };

                            preloadedAdjustedProducts.push(productToAdjustTo);
                        }

                    }
                }
            }
            

            

            if(preloadedAdjustedProducts.length > 0){
                preloadedAdjustedProducts.push(null); //add empty field
                setAdjustingToProducts(preloadedAdjustedProducts);
            }

            setDidMount(true);
        }
    },[didMount]);

    return (
        <>
        {isLoading && (
                <HxfIonLoading message={t('loading')}/>
        )}
        {popupDefinedProductTransformationData && (
            <PopupAdjustMachineProductTransformation
                onClosePopup={() => {
                    setPopupDefinedProductTransformationData(null);
                    if(popupDefinedPVSData?._isAddingNew){
                        cancelAddedProduct(popupDefinedProductTransformationData._indexSelected);
                    }
                   
                }}
                onSuccessConfirm={(res:any) => {
               
                    let adjustingToProductsArray = [...adjustingToProducts];
                       
                    let addingProduct = {
                        ...popupDefinedProductTransformationData, 
                        _indexSelected:popupDefinedProductTransformationData._indexSelected,
                        transformations:res
                    };
                 

                    adjustingToProductsArray[popupDefinedProductTransformationData._indexSelected] = addingProduct;
                    
                    setAdjustingToProducts(adjustingToProductsArray);
                    setPopupDefinedProductTransformationData(null);
     
            
                }}
                title={t('title.transformation.product')}
                currentData={popupDefinedProductTransformationData}
            />
        )}
        {popupDefinedPVSData && (
                <DefineProductParameterizedVariableValuesPopup
                        isFromUnavailability={true}
                        isLoading={isLoading}
                        currentData={popupDefinedPVSData}
                        allowCurrentDataValuesLoad={true}
                        includeFullParameterizedVariablesData={true}
                        title={t('product.parameters')}
                        onClosePopup={() => {
                            setPopupDefinedPVSData(null);
                            if(popupDefinedPVSData?._isAddingNew){
                                cancelAddedProduct(popupDefinedPVSData._indexSelected);
                            }
                            
                        }}
                        onSuccessConfirm={(res:any) => {
                            let adjustingToProductsArray = [...adjustingToProducts];
                       
                            let addingProduct = {
                                ...popupDefinedPVSData, 
                                arrayProductParameterizedVariables:res,
                                _indexSelected:popupDefinedPVSData._indexSelected
                            };
                         
                            if(popupDefinedPVSData?._isAddingNew){
                                for(let i = 0; i<adjustingToProducts.length; i++){
                                    if(isTheSameProduct(addingProduct, adjustingToProducts[i])){
                                        feedbackService.notifyToast(t('product.already.added.same.settings'), "error");
                                        return; //already exists
                                    }
                                }
                            }

                            adjustingToProductsArray[popupDefinedPVSData._indexSelected] = addingProduct;
             
                            let curActUnav = occurrenceState.getCurrentUnavailabilityAction();

                            let showPopupTransformation = curActUnav?._actionData?.stepPrepareForTransformation === 1;
                            if(showPopupTransformation){
                                let curActUnav = occurrenceState.getCurrentUnavailabilityAction();

                                //currently assumes the first, assuming this occurrence only happens at 1 machine at a time. to rework in the future
                                let idMachine = occurrenceState.occurrenceStore?.unavailabilityOccurrenceData?.machines?.[0].Id_Machine;
                                if(!idMachine){
                                    feedbackService.notifyToast(t('unexpected.machine'), "error");
                                    throw "unexpected machine";
                                }

                                


                                    setIsLoading(true);
                                    unavailabilitiesController().getUnavailabilityAdjustmentLatestTransformationsConfig(idMachine,addingProduct.Id_Product,addingProduct?.arrayProductParameterizedVariables).then((res) => {
                                   
                                        setIsLoading(false);
                                       
                                        let prevtransformations = res?.data?.response?.transformations;
                                        if(!prevtransformations){
                                            throw "unexpected";
                                        }

                                        let alreadyHadDefinedTransformationsCurrentState = addingProduct?.transformations && addingProduct?.transformations.length > 0;
                                        if(alreadyHadDefinedTransformationsCurrentState){
                        
                                            let currentDefinedTransformations = addingProduct.transformations;
                                            if(prevtransformations && prevtransformations.length > 0){
                                                for(let i = 0; i<currentDefinedTransformations.length; i++){
                                                    
                                                    let currentTransformationKey = ProductService().buildProductKey(currentDefinedTransformations[i].Id_Product,currentDefinedTransformations[i].arrayProductParameterizedVariables );

                                                    for(let j=0; j<prevtransformations.length; j++){
                                                        let prevTransformationKey = ProductService().buildProductKey(prevtransformations[j].Id_Product,prevtransformations[j].arrayProductParameterizedVariables );
                                                        if(currentTransformationKey === prevTransformationKey){
                                                            currentDefinedTransformations[i].Quantity = prevtransformations[i]?.Quantity;
                                                        }
                                                    }
                                                }
                                            }

                                            addingProduct.transformations = currentDefinedTransformations;
                                            //simply override the quantities if any transformation matches, do not add new
                                            setPopupDefinedProductTransformationData(addingProduct);
                                     
                                        }else{
                                            //write current transformations from the remotely stored history
                                            addingProduct.transformations = prevtransformations;
                                            //
                                            setPopupDefinedProductTransformationData(addingProduct);
                                        }

    
                                        setAdjustingToProducts(adjustingToProductsArray);
                                        setPopupDefinedPVSData(null);
                                    }).catch((res) => {
                                        setIsLoading(false);
                                        setPopupDefinedPVSData(null);
                                        feedbackService.notifyToast(t('generic.critical.error'), "error");
                                    });
                                

                                
                            }else{
                                setAdjustingToProducts(adjustingToProductsArray);
                                setPopupDefinedPVSData(null);
                            }
                 
                        }}
                />
        )}
            <div className={styles.content}>
                <div style={{marginBottom:50}}>
                    {adjustingToProducts.map((objAdjustingToProduct:any, index:any) => (
                    <div className={`${styles.adjProductContainer} ${adjustingToProducts[index]?._hasError ? styles.hasErrorAdjProductContainer : ""}`} key={"adjustingToProduct_"+index}>
                        <div className={styles.adjProductInputContainer}>
                            <HxfSelectorField
                            style={{width:'100%'}}
                            dataInputFieldAttributeValue="adjust-product-selector"
                            searchingFilterKey={"searchBarFilter"}
                                disabled={false}
                                error={
                                false
                                }
                                onChange={(evt, val) => {

                                    //check if product with same id already exists and reject ONLY if has no PVVs
                                    if(!val?.fetchedData?.arrayProductParameterizedVariables){
                                        if(checkIfAddedProductWithId(val?.fetchedData?.Id)){
                                            return;
                                        }
                                    }
                                
                                    let adjustingToProductsArray = [...adjustingToProducts];
                                    let adjProduct = null;
                                    if(val){
                                        let productData = val?.fetchedData;

                                        let wasProductAlreadySet = adjustingToProducts[index] !== null;
                                        if(wasProductAlreadySet && isTheSameProduct(adjustingToProducts[index], val?.fetchedData)){

                                            return;
                                        }
                                        adjProduct = {Id_Product:productData.Id, name:productData.name, code:productData.code};
                                    
                                        adjustingToProductsArray[index] = adjProduct;
                                        if(adjProduct && adjustingToProductsArray[adjustingToProductsArray.length-1] !== null){
                                            adjustingToProductsArray.push(null);
                                        }
                                    }else{
                                        adjustingToProductsArray.splice(index, 1);
                                    }
                                
                            
                                    setAdjustingToProducts(adjustingToProductsArray);

                                
                                    
                                    if(val.fetchedData?.arrayProductParameterizedVariables?.length > 0){
                                        setPopupDefinedPVSData({
                                            _indexSelected:index,
                                            Id_Product:val.fetchedData.Id,
                                            name:val.fetchedData.name,
                                            code:val.fetchedData.code,
                                            arrayProductParameterizedVariables: val.fetchedData.arrayProductParameterizedVariables,
                                            _isAddingNew:true
                                        });
                                
                                    }
                                }}
                                value={getAdjustedProduct(index)}
                                listJsonFunction={
                                productsController().getProductsListJson
                                }
                                listJsonOptions={getFilters(index)}
                                uniqueId={"unav_product_sel"}
                                labelPlaceholder={t("product")}
                            />
                            {(adjustingToProducts[index]?.Id_Product && adjustingToProducts[index]?.arrayProductParameterizedVariables?.length > 0) && (
                                <div className={styles.editAdjustmentBtn} onClick={() => {
                                    if(adjustingToProducts[index].arrayProductParameterizedVariables.length > 0){
                                        setPopupDefinedPVSData({
                                            _indexSelected:index,
                                            Id_Product:adjustingToProducts[index].Id_Product,
                                            name:adjustingToProducts[index].name,
                                            code:adjustingToProducts[index].code,
                                            arrayProductParameterizedVariables: adjustingToProducts[index].arrayProductParameterizedVariables,
                                            transformations:adjustingToProducts[index]?.transformations ? adjustingToProducts[index]?.transformations : []
                                        });
                                    }
                                }}>
                                    <EditNoteIcon fontSize="large"/>
                                </div>
                            )}

                        </div>
                        {objAdjustingToProduct?.arrayProductParameterizedVariables && objAdjustingToProduct.arrayProductParameterizedVariables.length > 0 && (
                            <>
                                <div className={styles.pvsContainer}>
                                    {objAdjustingToProduct?.arrayProductParameterizedVariables.map((objPv:any, pvIndex:any) => (
                                        <>
                                        <b>{objPv.name}:</b>{objPv?.Value ? objPv?.Value : t('not.defined')}{pvIndex < objAdjustingToProduct?.arrayProductParameterizedVariables.length -1 && (<>, </>)}
                                        </>
                                    ))}
                                </div>
                            </>
                        )}
                        <div></div>
                        {objAdjustingToProduct?.transformations && objAdjustingToProduct.transformations.length > 0 && (
                            <>
                                <div className={styles.transformationsContainer}>
                                    <div style={{margin:5}}>
                                        <div style={{textDecoration:'underline',marginBottom:5}}><b>{t('transformations')}:</b></div>
                                        {objAdjustingToProduct?.transformations.map((objTransformation:any, transformationIdx:any) => (
                                            <>
                                            <div style={{borderWidth:1, borderRadius:10, borderStyle:"solid"}}>
                                                <div style={{margin:5}}>
                                                <b>{t('quantity')}:</b> {objTransformation.Quantity} <b>{t('product')}:</b> {objTransformation.name} 
                                                    {objTransformation?.arrayProductParameterizedVariables && objTransformation?.arrayProductParameterizedVariables.length > 0 && (
                                                        <>
                                                            {objTransformation.arrayProductParameterizedVariables.map((objTransPPVV:any, idxTransPPVV:any) => (
                                                                <span key={"product_" + index + "_transf_" + transformationIdx + "_pv" + idxTransPPVV} style={{marginLeft:5}}>
                                                                    <b>{objTransPPVV.name}:</b> {objTransPPVV.Value}
                                                                </span>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>

                                            </div>

                                            </>
                                        ))}
                                    </div>

                                </div>
                            </>
                        )}
                    </div>
                    ))}

                    
                </div>
            </div>

            <div>
               <UnavailabilityActionAdjustMachineToProductConfirmationButtons
                disabledConfirm={isLoading} //!hasAtleastOneProduct() ||
                disabledReset={false}
                onResetCurrentAdjustments={() => {
                    let unavailabilityOccurrenceData = occurrenceState?.occurrenceStore?.unavailabilityOccurrenceData;
                    let machinesAdjusted = [];
                    if(unavailabilityOccurrenceData){
                        let machines = unavailabilityOccurrenceData.machines;
                        for(let i = 0; i<machines.length;i++){
                            machinesAdjusted.push({Id_Machine:machines[i].Id_Machine});
                        }
                    }
                    let newAdjustingToProductsArr = initialAdjustingToProducts;
                    setAdjustingToProducts(newAdjustingToProductsArr);
                    /*
                    props.onConfirm({
                        adjustingToProducts:[],
                        machinesAdjusted:machinesAdjusted
                    });*/
                }}
                onConfirm={() => {
                    //validate etc..
                    
                    let curActUnav = occurrenceState.getCurrentUnavailabilityAction();

                    let requiresProductTransformation = curActUnav?._actionData?.stepPrepareForTransformation === 1;
                    let requiresFillPPVS = requiresProductTransformation; //more conditions -> add here

                    let unavailabilityOccurrenceData = occurrenceState?.occurrenceStore?.unavailabilityOccurrenceData;
                    let machinesAdjusted = [];
                    if(unavailabilityOccurrenceData){
                        let machines = unavailabilityOccurrenceData.machines;
                        for(let i = 0; i<machines.length;i++){
                            machinesAdjusted.push({Id_Machine:machines[i].Id_Machine});
                        }
                    }

                    
                    let adjustingToProductsArr = JSON.parse(JSON.stringify(adjustingToProducts));
                    let hasErrors = false;
                    let hasWarnedAdjustedProductUndefVariable = false;
                    for(let i = 0; i<adjustingToProductsArr.length; i++){
                        if(adjustingToProductsArr[i] === null){
                            continue; //skip the empty product input
                        }
                        let arrppvvs = adjustingToProductsArr[i].arrayProductParameterizedVariables;
                        if(requiresFillPPVS && arrppvvs && arrppvvs.length > 0){
                            let foundError = false;
                            for(let k = 0; k<arrppvvs.length; k++){
                                if(arrppvvs[k]?.Value === null || arrppvvs[k]?.Value === undefined){
                                    foundError = true;
                                    break;
                                }
                            }
    
                            if(foundError){
                                adjustingToProductsArr[i]["_hasError"] = true;
                                if(!hasWarnedAdjustedProductUndefVariable){
                                    feedbackService.notifyToast(t('some.adjusted.products.variable.not.defined'),"error",{duration:5000});
                                    hasWarnedAdjustedProductUndefVariable = true;
                                }
                                
                                hasErrors= true;
                            }
                        }

                    }

                    if(hasErrors){
                        setAdjustingToProducts(adjustingToProductsArr);
                        return;
                    }

                    let cloneAdjustingToProductsArr = [...adjustingToProducts];

                    let splicedElements = 0;
                    //clean nulls
                    for(let i = 0; i<cloneAdjustingToProductsArr.length; i++){
                        if(!cloneAdjustingToProductsArr[i]){
                            adjustingToProductsArr.splice(i+splicedElements, 1);
                            splicedElements++;
                        }
                    }
                  
                    props.onConfirm({
                        adjustingToProducts:adjustingToProductsArr,
                        machinesAdjusted:machinesAdjusted
                    });
                    /*props.onConfirm({
                        adjustingToProduct:adjustingToProduct,
                        machinesAdjusted:machinesAdjusted
                    });*/
                }}
                />
            </div>
        </>
    )
}

export default UnavailabilityActionAdjustMachineToProduct;