import { useCallback, useEffect, useState } from "react";

import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../barrel/hooks/usePrevious";
import AppSessionService from "../../barrel/services/appSessionService";
import { useAppOfflineContext } from "../../contexts/AppOfflineProvider";
import { deleteCookie } from "../../barrel/utils/CookieUtils";
import entityUserController from "../../barrel/controllers/entityUserController";
import { useGlobalState } from "../GlobalStateProvider";
import HxfAppServersService from "../../barrel/services/hxfAppServersService";
function SessionState(logoutCallback: any, callbackShowOfflineMode: any) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialState:any = {
    Id_Entity: 0,
    Id_EntityUser: 0,
    Id_Bizz: 0,
    customAccountId: "",
    entityName: "",
    EntityUser_Timezone: null,
    Entity_Timezone: null,
    userPermissions: [],
    flags: {}, //db persistent shopfloor flags
    shopfloorBtnFeatures: {},
    loggedIn: false,
    flag_NOTFIRSTLOADED: true, //temp state flag
    hasMultipleEntities: false,
    flag_hasSelectedAnEntity: false, //temp state flag
    Id_Worker: 0,
    workerName: "",
    punchclockMode: "MANUAL_DETECT",
    allowMinimizeMultipleOperations: false,
    entityCacheCurrentVersion: null,
    hideSignOffWorkstation:0,
    sfDevices_enableZebraConfigure:0,
    machinesCamScanner:0,
    operationsDistribution:"FULL_DISTRIBUTION"
  };
  const { appOffline } = useAppOfflineContext();

  const [userSessionData, setUserSessionData] = useState(initialState);
  const myLocalStorage = useLocalStorageHandler();

  const previousLogginState = usePrevious(userSessionData.loggedIn);
  const [didMount, setDidMount] = useState(false);
  const { clearWorkerIdentificationCode } = useLocalStorageHandler();
  const { tryRefreshSession } = AppSessionService();

  const [firstSessionLoad, setFirstSessionLoad] = useState(false);

  const [underMaintenance, setUnderMaintenance] = useState(false); //todo move to the proper state with custom store event dispatcher
  const [unavailable, setUnavailable] = useState(false); //todo move to a proepr state

  const [membershipExpired, setMembershipExpired] = useState(false);

  const appOfflineModuleReady = appOffline().isModuleReady();


  const hasMachineAdjustedStatusEnabled = () => {

    let adjustedMachineStatus = userSessionData?.globalSettings?.featureExpander?.statusMachineAdjustedToProducts;
    return adjustedMachineStatus === 1;
  }


  const isUnavailable = () => {
    return unavailable;
  };
  const isUnderMaintenance = () => {
    return underMaintenance;
  };


  const hasAppAdminLevel = (adminlvl:any) => {
    let userAdminLevel = userSessionData?.appAdminLevel ? userSessionData.appAdminLevel : 0;
    if(userAdminLevel >= adminlvl){
        return true;
    }
    return false;
}


  const isSessionStateLoaded = () => {
    return (
      userSessionData.flag_NOTFIRSTLOADED === undefined ||
      userSessionData.flag_NOTFIRSTLOADED === null ||
      userSessionData.flag_NOTFIRSTLOADED === false
    );
  };

  function hasMultipleEntities() {
    return userSessionData.hasMultipleEntities;
  }

  const isMembershipExpired = () => {
    return membershipExpired;
  }

  function isUserLoggedIn() {
    return userSessionData.loggedIn;
  }

  function isUserWorkerIdentified() {
    return (
      userSessionData["Id_Worker"] !== undefined &&
      userSessionData["Id_Worker"] !== null &&
      userSessionData["Id_Worker"] !== 0
    );
  }

  function setWorker(id: number, name: string, code: string) {
    myLocalStorage.setWorkerIdentificationCode(code);
    setUserSessionData({
      ...userSessionData,
      workerName: name,
      Id_Worker: id,
    });
  }

  const getInitialState = useCallback(() => {
    return initialState;
  }, [initialState]);

  const deauthWorker = () => {
    clearWorkerIdentificationCode();
    setUserSessionData({ ...userSessionData, Id_Worker: 0 });
  };

  const logout = useCallback(() => {
    console.log("call logoutx");
    myLocalStorage.clearWorkerIdentificationCode();
    //todo mark token invalid
    setUserSessionData({ ...getInitialState(), flag_NOTFIRSTLOADED: false });
    deleteCookie(process.env.REACT_APP_APPNAME_PREFIX + "_csrf");
    clearWorkerIdentificationCode();

    logoutCallback();
  }, [
    logoutCallback,
    getInitialState,
    clearWorkerIdentificationCode,
    myLocalStorage,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startAutoRefresher = () => {
    

    let refreshInSeconds =
      process.env.REACT_APP_REFRESH_TOKENS_EVERY_SECONDS !== undefined
        ? process.env.REACT_APP_REFRESH_TOKENS_EVERY_SECONDS
        : 0;
    refreshInSeconds = +refreshInSeconds;
    refreshInSeconds = refreshInSeconds * 1000;
    if (process.env.REACT_APP_REFRESH_TOKENS_EVERY_SECONDS === undefined) {
      throw new Error("env var must be defined or there will be issues");
    } else {
      setTimeout(refreshSession, refreshInSeconds);
    }
  };

  const handleSetSessionDataBySessionData = useCallback(
    async (responseSessionData) => {

      const newUserSessionData = { ...userSessionData, ...responseSessionData }; //merge usersession data options with response. to keep fields from both sides
      setUserSessionData({
        ...newUserSessionData,
        flag_NOTFIRSTLOADED: false,
      });
      if (responseSessionData?.Id_Worker !== 0) {
        myLocalStorage.setWorkerIdentificationCode(
          responseSessionData.workerIdentificationCode
        );
      }
    },
    [userSessionData, myLocalStorage]
  );

  const attentionRequired = () => {
    setMembershipExpired(true);
  }

  const checkMembershipData = (membershipData:any) => {
    if(membershipData?._expired && (membershipData?.onMembershipExpiration === "DISABLE_WORKSTATION" || membershipData?.onMembershipExpiration === "DISABLE_WORKSTATION_AND_BACKOFFICE")){
      setMembershipExpired(true);
    }
  }

  const refreshSession = useCallback(async () => {
 
    if (!HxfAppServersService().hasServerBeenSet()) {
      logout();
    } else {
      tryRefreshSession(
        (responseObject: any) => {
          // setUserSessionData(responseObject.response);
       
          const response = responseObject.data.response;
          
          let membershipData = response?.membershipData;
        
          checkMembershipData(membershipData);
          handleSetSessionDataBySessionData(response);
          startAutoRefresher();
        },
        (resp: any) => {
          let respCode = resp?.response?.data?.code;


         
          if (!respCode) {
            setUnavailable(true);
          } else if (respCode === 503) {
            setUnderMaintenance(true);
          } else {
            logout();
          }
        }
      );
    }
    /*if (!HxfAppServersService().hasServerBeenSet()) {
      logout();
    } else {
      tryRefreshSession(
        (responseObject: any) => {
          // setUserSessionData(responseObject.response);
       
          const response = responseObject.data.response;
          
          let membershipData = response?.membershipData;
        
          checkMembershipData(membershipData);
          handleSetSessionDataBySessionData(response);
          startAutoRefresher();
        },
        (resp: any) => {
          let respCode = resp?.response?.data?.code;


         
          if (!respCode) {
            setUnavailable(true);
          } else if (respCode === 503) {
            setUnderMaintenance(true);
          } else {
            logout();
          }
        }
      );
    }*/

  }, [
    logout,
    startAutoRefresher,
    tryRefreshSession,
    handleSetSessionDataBySessionData,
  ]);

  useEffect(() => {
    //guarantee first call is called when the indexDb is ready
    if (!firstSessionLoad && appOffline().isModuleReady()) {
      const curReadyState = appOffline().isModuleReady();
      refreshSession();
      setFirstSessionLoad(true);
    }
  }, [appOfflineModuleReady, appOffline, firstSessionLoad, refreshSession]);

  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
    }
  }, [didMount, setDidMount]);

  return {
    hasMultipleEntities,
    logout,
    isUserLoggedIn,
    isSessionStateLoaded,
    userSessionData,
    setUserSessionData,
    startAutoRefresher,
    handleSetSessionDataBySessionData,
    hasMachineAdjustedStatusEnabled,
    isUserWorkerIdentified,
    setWorker,
    isUnderMaintenance,
    isUnavailable,
    deauthWorker,
    isMembershipExpired,
    checkMembershipData,attentionRequired,
    hasAppAdminLevel
  };
}

export default SessionState;
