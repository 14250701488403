import { IonContent, IonPage } from "@ionic/react";
import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import entityUserController from "../../barrel/controllers/entityUserController";
import EntityDao from "../../barrel/dataAccessObjects/ModelObjects/Objects/EntityDao";
import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import { useAppOfflineContext } from "../../contexts/AppOfflineProvider";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import LoadingApp from "../Loading/LoadingApp/LoadingApp";
import ReconnectApp from "../MiscPage/ReconnectApp/ReconnectApp";
import styles from "./EntitySwitch.module.scss";


//deprecated
function EntitySwitch(props) {
  const { saveRefreshToken } = useLocalStorageHandler();
  const { appOffline } = useAppOfflineContext();
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showRetry, setShowRetry] = useState(false);


  const entityDao = EntityDao();

  const [selectableEntities, setSelectableEntities] = useState([
    { Id_Entity: 0, entityName: "" },
  ]);

  const showUserEntitiesSelection = useCallback(async () => {
    entityDao
      .getAccessibleEntities()
      .then((resp) => {
        console.log("ACCESSIBLE DATA RECEIVED", resp);
        let responseData = resp.data.response;

        let currentEntityId = sessionState.userSessionData.Id_Entity;
        console.log("CUR ENT: ", currentEntityId);

        let entitiesAvailable = responseData;

        if (entitiesAvailable.length > 1) {
          let positionTargetEntity = currentEntityId;
          for (var i = 0; i < entitiesAvailable.length; i++) {
            if (
              parseInt(entitiesAvailable[i].Id_Entity) ==
              parseInt(positionTargetEntity)
            ) {
              positionTargetEntity = i;
              break;
            }
          }
          //swap element with the first
          if (positionTargetEntity != 0) {
            let tempElement = entitiesAvailable[0];
            entitiesAvailable[0] = entitiesAvailable[positionTargetEntity];
            entitiesAvailable[positionTargetEntity] = tempElement;
          }

          setSelectableEntities(entitiesAvailable);
          setIsLoading(false);
        } else {
          console.log("Single entity");
        }
      })
      .catch(() => {
        setShowRetry(true);
      });
  }, [entityDao, sessionState.userSessionData.Id_Entity]);

  useEffect(() => {
    if (!didMount) {
      showUserEntitiesSelection();
      setDidMount(true);
    }
  }, [didMount, showUserEntitiesSelection]);

  if (!sessionState.hasMultipleEntities()) {
    props.history.push("/home");
    return (
      <IonPage>
        <IonContent fullscreen>Redirecting to home..</IonContent>
      </IonPage>
    );
  }

  if (showRetry) {
    return <ReconnectApp />;
  }

  if (isLoading) {
    return (
      <>
        <LoadingApp />
      </>
    );
  }

  return <></>;
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={styles.pageContainer}>
          <div className={styles.entitySelectionDiv}>
            <div
              className={styles.entityGoBack}
              onClick={() => {
                props.history.push("/");
              }}
            >
              <div>Go back</div>
            </div>
            <div className={styles.entitySelectionContainer}>
              <div className={styles.entitySelectionContainerHeader}>
                Select An Entity
              </div>
              <div className={styles.entitySelHeaderSeparator}></div>
              <div className={styles.entitiesAreaContainer}>
                {selectableEntities.map((entity, index) => (
                  <div key={index}>
                    <div
                      className={`${styles.entityButtonArea} ${
                        index < selectableEntities.length - 1 &&
                        styles.buttonBorderBottom
                      } ${index == 0 && styles.buttonSelected}`}
                      onClick={() => {
                        if (index !== 0) {
                          entityUserController()
                            .switchEntityUser(entity.Id_Entity)
                            .then((resp) => {
                              console.log("The resp", resp);
                              sessionState.handleSetSessionDataBySessionData(
                                resp.data.response
                              );
                              let refreshToken =
                                resp.data.response.refresh_token;
                              saveRefreshToken(refreshToken);
                              props.history.push("/");
                            });
                        }
                      }}
                    >
                      <p>{entity.entityName}</p>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                onClick={() => {
                  entityDao
                    .getAccessibleEntities()
                    .then((resp) => {
                      console.log("xxxxx", resp);
                    })
                    .catch(() => {});
                }}
              >
                testing data access
              </Button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
export default EntitySwitch;
