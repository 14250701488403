
import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";

function irregularityRecordsController() {


  const executeIrregularityOccurrence = (
    options:any,
    cancelToken: any
  ) => {
    if(!options){
      options = {};
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "irregularity-record/execute-irregularity-occurrence",
      { ...options },
      cancelToken
    );
  };

  return {
    executeIrregularityOccurrence
  };
}

export default irregularityRecordsController;
