import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import BarrelWrapper from "./barrelWrapper";
import HxfAppServersService from "../services/hxfAppServersService";

interface IMoveStockProductPvsProps {
  Id_Product_ParameterizedVariable: number;
  value: string | number;
  label: string | number;
}

interface IMoveStockProps {
  Id_Product: number;
  Id_Lot: number | null;
  Id_Warehouse: number | null;
  Id_WarehouseLocation: number | null;
  productParameterizedVariables: IMoveStockProductPvsProps[] | null;
  newLotNameCode?: string;
}

export interface ICStockManagementProductsListProduct{
 
  Id?:any;
  productParameterizedVariableValues?:any;
  quantityToFill?:any;

}

interface IStagingProductParameterizedVariableValue{
  Id_Product_ParameterizedVariable?:any;
  Value?:any;
}
interface IStagingProductStockLocation{
  Id_WarehouseLocation?:any;
  Id_Warehouse?:any;
  Id_Lot?:any;
  Quantity?:any;
}
interface IStagingProductStock{
  locationsQuantity:IStagingProductStockLocation[];
}
interface IStagingProduct{
  Id?:any;
  productParameterizedVariableValues?:IStagingProductParameterizedVariableValue[];
  stagedStock?:IStagingProductStock;

}

function stockManagementController() {
  const myLocalStorage = useLocalStorageHandler();

  const moveStockPick = (movementObj: IMoveStockProps | null) => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    let url =
      HxfAppServersService().getBackend() + "stock-management/move-stock/pick";

    let postingObj: any = movementObj ? movementObj : {};
    postingObj["workerCode"] = currentWorkerCode;
    return BarrelWrapper().appPostRequest(url, postingObj);
  };

  const moveStockDrop = (movementObjsArr: IMoveStockProps[]) => {
    let url =
      HxfAppServersService().getBackend() + "stock-management/move-stock/drop";
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    let postingObj: any = {
      workerCode: currentWorkerCode,
      dropsArray: [...movementObjsArr],
    };

    return BarrelWrapper().appPostRequest(url, postingObj);
  };

  const getProductsStockAtLocation = (
    productsFilterList: any,
    extraOptions: any
  ) => {
    let url =
      HxfAppServersService().getBackend() +
      "stock-management/check-products-stock-at-location";

    if(!productsFilterList){
      productsFilterList = [];
    }
    let postingData = { productsFilterList:productsFilterList };
    if (extraOptions !== null) {
      postingData = { ...postingData, ...extraOptions };
    }
    return BarrelWrapper().appPostRequest(url, postingData);
  };

  const updateUniqueProductLocation = (productObj:any) => {

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "stock-management/unique-product-update-location",
      productObj
    );
}

  const getMultiSuggestedLots = (productsList:ICStockManagementProductsListProduct[],cancelToken?:any) => {
    let requestUrl = "stock-management/multi-suggest-lots-staging";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      { productsList: productsList },
      cancelToken
    );
  }

  const getWorkerProductsInTransit = (extra = {}, cancelToken: any) => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    let requestUrl = "stock-management/worker/in-transit-products";

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      { workerCode: currentWorkerCode, ...extra },
      cancelToken
    );
  };

  const launchStagingMaterial = (idProductionOrder:any, productComponentsStockLocations:any[], shouldOpenProductionOrder:any) => {
    
    let stagingProducts:IStagingProduct[] = [];
    //convert clear all unneeded keys
   
    for(let i = 0; i<productComponentsStockLocations.length; i++){
        let ppvvs:IStagingProductParameterizedVariableValue[] = [];
        for(let j = 0; j < productComponentsStockLocations[i].productParameterizedVariableValues.length; j++){
            ppvvs.push({
                Id_Product_ParameterizedVariable:productComponentsStockLocations[i].productParameterizedVariableValues[j].Id_Product_ParameterizedVariable,
                Value:productComponentsStockLocations[i].productParameterizedVariableValues[j].Value
            });
        }

        let stagedStock:IStagingProductStock = {locationsQuantity:[]};
        for(let j = 0; j < productComponentsStockLocations[i].stagedStock.locationsQuantity.length; j++){
            
   
            let currentLocations = stagedStock.locationsQuantity;
            let cycleLocation = productComponentsStockLocations[i].stagedStock.locationsQuantity[j];
            if(cycleLocation?.Quantity > 0){
                currentLocations.push({
                    Id_WarehouseLocation:cycleLocation?.Id_WarehouseLocation ? cycleLocation.Id_WarehouseLocation : null,
                    Id_Warehouse:cycleLocation?.Id_Warehouse ? cycleLocation.Id_Warehouse : null,
                    Id_Lot:cycleLocation?.Id_Lot ? cycleLocation.Id_Lot : null,
                    Quantity:cycleLocation?.Quantity ? cycleLocation.Quantity : 0
                });
            }

        }
        
        if(stagedStock.locationsQuantity.length > 0){
            stagingProducts.push({
                Id:productComponentsStockLocations[i].Id,
                productParameterizedVariableValues:ppvvs,
                stagedStock:stagedStock
            });
        }

    }
    let data = {"Id_ProductionOrder":idProductionOrder, productComponentsStockLocations:stagingProducts, shouldOpenProductionOrder:shouldOpenProductionOrder ? shouldOpenProductionOrder : false};
  
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "stock-management/staging-launch",
      data
    );
}

  return {
    updateUniqueProductLocation,
    moveStockDrop,
    moveStockPick,
    getWorkerProductsInTransit,
    getProductsStockAtLocation,
    getMultiSuggestedLots,
    launchStagingMaterial
  };
}

export default stockManagementController;
