import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import HxfKeyRippleEffectCustom from "../../../../HxfDigitKeyboard/HxfKeyRippleEffectCustom/HxfKeyRippleEffectCustom";
import styles from "./ActionScreenIrregularityElement.module.scss";
import Ripples from "react-ripples";
function ActionScreenIrregularityElement(props: any) {
  return (
    <div className={`${styles.outerContainer} ${!props.isEnabled ? styles.disabledIrr: ""}`}>
      <Ripples
        onClick={() => {
          if (props?.onClick) {
            if(props.isEnabled){
              props.onClick();
            }
            
          }
        }}
      >
        <div className={styles.IrregularityDescIconContainer}>
          <div className={styles.IrregularityAddIconContainer}>
            <IonIcon icon={addOutline} size="large" />
          </div>
          <div className={styles.codeNameContainer}>
            <div className={styles.code}>({props.code})</div>
            <div>{props.name}</div>
          </div>
        </div>
      </Ripples>
    </div>
  );
}

export default ActionScreenIrregularityElement;
