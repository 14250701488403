import { useEffect, useRef, useState } from "react";

interface IProps{
    onReceivedImageFromCamera:any;
}
//listens for wrapcom_returned_camera_picture, to get a picture taken from the mobile native app
function useHxfNativeAppCameraPictureListener(props:IProps){

    const timerCheckPictureMobile = useRef<any>(null);

    const [initiatedListener, setInitiatedListener] = useState(false);


    const initiateCustomListenerForMobile = () => {
        timerCheckPictureMobile.current = setTimeout(() => {
          let valFound = localStorage.getItem("webcom_rn");
          
          if (!valFound || valFound === "") {
            initiateCustomListenerForMobile();
          } else {
            try {
              let receivedMessage = JSON.parse(valFound);
              let action = receivedMessage.action;
              let payload = receivedMessage.payload;
    
              if (action === "wrapcom_returned_camera_picture") {
                localStorage.setItem("webcom_rn", "");
                clearTimeout(timerCheckPictureMobile.current);
                props.onReceivedImageFromCamera(payload);
              }
            } catch (exception) {

            }
    
            
          }
        }, 250);
    };

    useEffect(() => {

      return () => {
        console.log("[HxfCameraPicture] cleanup clearing events");
  
  
        if (initiatedListener) {
          //clear listener
          
          clearTimeout(timerCheckPictureMobile.current);
        }
      };
      // eslint-disable-next-line
    }, []);



    return {initiateCustomListenerForMobile};
}

export default useHxfNativeAppCameraPictureListener;