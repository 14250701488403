import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IAction {
  addedIrregularitys: any;
}

interface IActionScreen {
  type: any;
  payload?: any;
}

const initialState = {
  addedIrregularitys: [],
};

const reducer = (store: IAction, action: IActionScreen) => {
  switch (action.type) {
    case "RESET_STORE":
      console.log("RESETTING STORE ASK PRODUCED Irregularity");

      store = { ...initialState, addedIrregularitys: [] };
      return store;
    case "SET_ADDED_IRREGULARITIES":
      store = { ...store, addedIrregularitys: [...action.payload] };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    default:
      return store;
  }
};

export const [
  useActionScreenAskProducedProductIrregularityStore,
  dispatchActionScreenAskProducedProductIrregularityStore,
] = createStore(initialState, reducer);
