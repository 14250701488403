import HxfSelectorField from "../../../../../HxfSelectorField/HxfSelectorField";
import { TextField } from "@mui/material";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import styles from "./ASMFVChecklistPVInput.module.scss";
import { Backdrop, Checkbox, CircularProgress, InputAdornment } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
interface IProps{
    objPv?:any;
    error?:any;
    indexPv?:any;
    indexMatchedFixedVariable?:any;
    defaultValue?:any;
    mapMatchFixedVarChecklistPvError?:any;
    onChange?:any;

}
function ASMFVChecklistPVInput(props:IProps){
    let varName = props.objPv.name;
    const {t} = useHxfTranslation();

    const [pendingValueChange, setPendingValueChange] = useState<any>(undefined);

    const calcInput = () => {
        if (props.objPv.varType === "text") {
            return (
              <div>
                <TextField
                  disabled={false}
                  error={props.error}
                  onChange={(evt) => {
                    /*setChecklistPvValue(
                      indexChecklist,
                      indexPv,
                      evt.target.value,
                      objPv.varType
                    );*/
                  }}
                  type={"text"}
                  value={props?.defaultValue}
                  fullWidth={true}
                  label={varName}
                  variant="outlined"
                />
              </div>
            );
          }
      
          if (props.objPv.varType === "numeric") {
            return (
              <div>
      
                <TextField
                  disabled={false}
                  error={props.error}
                  onChange={(evt) => {
                    /*
                    setChecklistPvValue(
                      indexChecklist,
                      indexPv,
                      evt.target.value,
                      objPv.varType
                    );*/
      
                  }}
                  type={"number"}
                  value={props?.defaultValue}
                  fullWidth={true}
                  label={varName}
                  variant="outlined"
                />
              </div>
            );
          }
      
          if (props.objPv.varType === "checkbox") {
            return (
              <div className={styles.checkboxCustom}>
                <div className={styles.checkboxCustomInner}>
                  <Checkbox
                    color={"primary"}
                    onChange={(evt) => {
                      /*let newVal = evt.target.checked;
      
                      console.log("ONCHG: ", evt.target.checked);
                      setChecklistPvValue(
                        indexChecklist,
                        indexPv,
                        evt.target.checked,
                        objPv.varType
                      );*/
                    }}
                    checked={props?.defaultValue}
                  />
                </div>
              </div>
            );
          }
      
          if (props.objPv.varType === "optionselect") {
            let optionsSelectableArr = props.objPv.varTypeOptionSelect_Opts.split(",");
      
            let defaultOptionsObj = [];
            for (let i = 0; i < optionsSelectableArr.length; i++) {
              defaultOptionsObj.push({
                label: optionsSelectableArr[i],
                value: optionsSelectableArr[i],
              });
            }
      
            return (
              <div className={styles.customChecklistComboSelectOptions}>
                <HxfSelectorField
                  dataInputFieldAttributeValue={"checklistvar_mfvidx_" + props.indexMatchedFixedVariable + "_pvidx_" + props.indexPv}
                  error={props.error}
                  uniqueId={"hxfsel_" + props.indexMatchedFixedVariable + "_" + props.indexPv}
                  disabled={false}
                  onChange={(evt, val) => {
                  
                    
                  
                    if (val === null) {
                      val = null;
                    }
      
                    let updatedVal = val?.value ? val.value : null;
                    setPendingValueChange(updatedVal);
                    //not changing directly here because when we setPendingValueChange it forced the current memoized component to rerender (having access to the updated store states)
                    //and only then deploy the value to the onChange (that dispatches it to the store)
                    
                  }}
                  value={props?.defaultValue}
                  defaultItems={defaultOptionsObj}
                  labelPlaceholder={t('value')}
                />
              </div>
            );
          }
      
          return <>not implemented</>;
    }

    const memoizedInput = useMemo(() => {
        console.log("recalculating CLPV ", props.defaultValue);
        return calcInput();
    },[JSON.stringify(props.defaultValue),props.error]); //stringify otherwise even object changes to the same object triggers rerender

    useEffect(() => {
        if(pendingValueChange !== undefined){
            console.log("deploying value change");
            setPendingValueChange(undefined);
            props.onChange(pendingValueChange);
           
     
        }
    },[props.defaultValue,pendingValueChange,props.error]);

    return (<>{memoizedInput}</>)
}

export default ASMFVChecklistPVInput;