import { IHxfBarcodeScanFormat } from "../../components/HxfBarcodeScanner/HxfBarcodeScanner";

export interface ICustomIntegrationNotificationDataConfig{
  ip?:any;
  port?:any;
  content?:any;//other extra content that might be relevant
}

export interface ICustomIntegrationNotificationData{
  identifier:string;
  data?:ICustomIntegrationNotificationDataConfig;
}
/**
 * service that dispatches events that are captured by the react native app
 */

//deprecated in favor of mobilePWAInstallCommunicationService
function MobileWrapperCommunicationService() {
  const notifyChangedAccessTokenBackoffice = () => {
    const evt = new CustomEvent("wrapcom_changed_at_bo");
    window.dispatchEvent(evt);
  };
  const notifyChangedAccessTokenFrontoffice = () => {
    const evt = new CustomEvent("wrapcom_changed_at_fo");
    window.dispatchEvent(evt);
  };

  const notifyExitShopfloor = () => {
    const evt = new CustomEvent("wrapcom_exit_so");
    window.dispatchEvent(evt);
  };

  const notifyOpenPdf = (pdfUrl:any) => {
    
    const evt = new CustomEvent("wrapcom_open_pdf", {
      detail: pdfUrl,
    });
    window.dispatchEvent(evt);
  }


  const notifyOpenPrinterZpl = () => {
    const evt = new CustomEvent("wrapcom_open_printer_zpl");
    window.dispatchEvent(evt);
  }

  const notifyPrintLabel = (labelString:string,count:number) => {
    const evt = new CustomEvent("wrapcom_network_print_zpl", {
      detail: {"zplContent":labelString,"count":count},
    });
    window.dispatchEvent(evt);
  }

  const notifyStopCustomIntegration = (identifier:any) => {
    const evt = new CustomEvent("wrapcom_stop_custom_integration", {
      detail:{
        identifier:identifier
      }
    });
    window.dispatchEvent(evt);
  }

  const notifyStartCustomIntegration = ( integrationNotificationData:ICustomIntegrationNotificationData ) => {
  
    const evt = new CustomEvent("wrapcom_start_custom_integration", {
      detail: integrationNotificationData,
    });
    window.dispatchEvent(evt);
  
  }

  /**
   *
   * @param formatsToScan empty array = all formats
   */
  const notifyWaitingForCameraScan = (
    formatsToScan: IHxfBarcodeScanFormat[] = []
  ) => {
    const evt = new CustomEvent("wrapcom_waiting_for_scan", {
      detail: formatsToScan,
    });
    window.dispatchEvent(evt);
  };


  const notifyWaitingForCameraPicture = () => {
    const evt = new CustomEvent("wrapcom_waiting_for_camera_picture", {
      detail: {
        cameraType:'back'
      },
    });
    window.dispatchEvent(evt);
  }

  const isDeviceMobileWrapper = () => {
    return localStorage.getItem("mobileWrap") === "true";
  };
  return {
    notifyExitShopfloor,
    notifyWaitingForCameraScan,
    isDeviceMobileWrapper,
    notifyChangedAccessTokenBackoffice,
    notifyChangedAccessTokenFrontoffice,
    notifyOpenPdf,
    notifyOpenPrinterZpl,
    notifyPrintLabel,
    notifyStartCustomIntegration,
    notifyStopCustomIntegration,
    notifyWaitingForCameraPicture
    
  };
}

export default MobileWrapperCommunicationService;
