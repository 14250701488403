import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  body {

  }

  .test{
    background-color: ${({ theme }) => theme.bgcol};
    color: ${({ theme }) => theme.txtcol};
  }

  .hxf-small-btn{

  }

  .hxf-worker-screen-btn{

  }

  

  `;
