import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useState } from "react";
import UomService from "../../../../barrel/services/uomService";
import HxfDigitKeyboard from "../../HxfDigitKeyboard/HxfDigitKeyboard";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./DefineProductQuantityPopup.module.scss";

interface IProduct {
  code?: any;
  name?: any;
  uomLabel?: any;
  qtyValue?: any;
  maxQuantityBaseUom?: any;
  uomConversionsData?: any;
  Id_Selected_UoM?: number;
  perUnitValue?: number;
}
interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  title: string;
  productData: IProduct | null;
  qtyInputPlaceholder?: string | null;
  requirePositiveQuantity?: boolean | null;
}
function DefineProductQuantityPopup(props: IPopup) {
  const getProperMaxLimitPlaceholder = () => {
    if (!props?.productData?.maxQuantityBaseUom) {
      return "";
    }
    let qty = parseFloat(props.productData.maxQuantityBaseUom);
    if (qty > 0) {
      return "(max: " + getProperMaxValue() + ")";
    }
  };

  const [qtyError, setQtyError] = useState(false);

  const getProperDefaultValue = () => {
    let qtyValue = "";
    let currentQtyValue = props?.productData?.qtyValue;
    if (currentQtyValue && currentQtyValue !== 0) {
      if (props?.productData?.Id_Selected_UoM) {
        if (parseFloat(currentQtyValue) > 0) {
          let newCurrentQtyValue = UomService().convertQuantity(
            currentQtyValue,
            -1,
            props.productData.Id_Selected_UoM,
            props.productData.uomConversionsData
          );
          return newCurrentQtyValue;
        }
      }

      return currentQtyValue;
    }

    return qtyValue;
  };

  const getProperMaxValue = () => {
    if (!props?.productData?.maxQuantityBaseUom) {
      return null;
    }
    //if no uom defined and no uom data
    if (
      !props?.productData?.Id_Selected_UoM ||
      props?.productData?.Id_Selected_UoM === -1
    ) {
      return props?.productData?.maxQuantityBaseUom;
    }

    let realMaxQty = UomService().convertQuantity(
      props.productData.maxQuantityBaseUom,
      -1,
      props.productData.Id_Selected_UoM,
      props.productData.uomConversionsData
    );

    return realMaxQty;
  };

  if (!props?.productData) {
    return <></>;
  }

  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.selectedProductInfo}>
              <div className={styles.lineProductInfoLabels}>
                ( {props.productData.code} ) {props.productData.name}
              </div>
            </div>
            <div className={`popup-content-border-sides-only-styles ${styles.content}`}>
              <div className={styles.areaKeybdContainer}>
                <div className={styles.keybdContainer}>
                  <HxfDigitKeyboard
                    Id_Selected_UoM={props?.productData?.Id_Selected_UoM}
                    uomConversionsData={props?.productData?.uomConversionsData}
                    maxValue={getProperMaxValue()}
                    subPlaceholder={getProperMaxLimitPlaceholder()}
                    placeholder={props?.qtyInputPlaceholder}
                    uomLabel={props?.productData?.uomLabel}
                    showErrorInput={qtyError}
                    defaultValue={getProperDefaultValue()}
                    onChange={(res) => {
                      setQtyError(false);
                    }}
                    onConfirmation={(res) => {
           
                      if (isNaN(res) || res === "") {
                        setQtyError(true);
                        return;
                      }
                      let numberQty = parseFloat(res);
                      if (props?.requirePositiveQuantity && numberQty <= 0) {
                        setQtyError(true);
                        return;
                      }
                      if (numberQty < 0) {
                        setQtyError(true);
                        return;
                      }

                      let newProductData = { ...props.productData }; //if in the future we need to add uom selection on this menu, we modify this object
                     
                      let result:any = {
                        newProductData: newProductData,
                        quantityResult: numberQty,
                      };
                    
                      if(newProductData?.perUnitValue){
                        result.perUnitValue = newProductData.perUnitValue;
                      }

                      props.onSuccessConfirm(result);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default DefineProductQuantityPopup;
