import { IonButton, IonIcon } from "@ionic/react";
import { addCircleOutline, closeOutline, createOutline, trashOutline } from "ionicons/icons";
import SimpleContentPopup from "../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import { IMaterialStaggingRequiredProduct, IMaterialStagingStagedStockLocationQuantity } from "../MaterialStagingProductionOrderMaterials";
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSpinner,
} from "@ionic/react";
import RemoveIcon from '@mui/icons-material/Remove';
import styles from "./StageProductComponentPopup.module.scss";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HxfTextfieldNumber from "../../../../../CustomElements/HxfTextfieldNumber/HxfTextfieldNumber";
import StageProductComponentPopupConfirmationButtons from "./confirmationButtons/StageProductComponentPopupConfirmationButtons";
import HxfSelectorField from "../../../../../CustomElements/HxfSelectorField/HxfSelectorField";
import warehouseLocationsController from "../../../../../../barrel/controllers/warehouseLocationsController";
import { useEffect, useRef, useState } from "react";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import warehousesController from "../../../../../../barrel/controllers/warehousesController";
import lotsController from "../../../../../../barrel/controllers/lotsController";
import stockManagementController from "../../../../../../barrel/controllers/stockManagementController";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

export interface IPropsMaterialStagingStageFrom{
  materialStagingProduct:IMaterialStaggingRequiredProduct;

  onClosePopup?:any;
  onUpdateMaterialProduct?:any;
}


export interface IMaterialStagingMovementLocationData{
  name?:any;
  code?:any;
}

export interface IMaterialStagingFromMovement{
  Id_WarehouseLocation?:any;
  warehouseLocationData?:IMaterialStagingMovementLocationData;
  Id_Warehouse?:any;
  warehouseData?:IMaterialStagingMovementLocationData;
  Id_Lot?:any;
  lotData?:IMaterialStagingMovementLocationData;
  Quantity?:any;
  availableQuantity?:any;
}


function StageProductComponentPopup(props: IPropsMaterialStagingStageFrom) {
  
  const [addingStagingMovements, setAddingStagingMovements] = useState<IMaterialStagingFromMovement[]>([]);
  const [addingMovement, setAddingMovement] = useState<IMaterialStagingFromMovement>({});
  const [editWarehouseLocation, setEditWarehouseLocation] = useState(false);
  const previousEditWarehouseLocation = usePrevious(editWarehouseLocation);
  const [editWarehouse, setEditWarehouse] = useState(false);
  const [editLot, setEditLot] = useState(false);
  const feedbackService = useFeedbackService();

  const refAddingInputWarehouseLocation = useRef<any>(null);
  const {t} = useHxfTranslation();
  const [forceOpenWarehouseLocationMenu, setForceOpenWarehouseLocationMenu] = useState(true);
  const hasWhLoc = useRef(false);
  const [didMount, setDidMount] = useState(false);

  const getAddingWarehouseLocation = () => {

    if(addingMovement.Id_WarehouseLocation && addingMovement.warehouseLocationData){
        return {label:addingMovement.warehouseLocationData.name,value:addingMovement.Id_WarehouseLocation};
    }
    return null;
}

const getTotalAdded = () => {
    let total = 0;
    for(let i = 0; i<addingStagingMovements.length; i++){
        total += addingStagingMovements[i].Quantity;
    }

    return total;
}
const getTotalAddedUI = () => {
  let total = getTotalAdded();
 

  let totalReqred = props.materialStagingProduct.totalQtyRequired;

  let color = "#7b5000";
  if(total >= totalReqred){
      color = "#0b7b00";
  }

  if(total === 0){
      color = "black";
  }
  return (<span style={{color:color}}>{total}</span>);
}

const getAddingWarehouse = () => {

    if(addingMovement.Id_Warehouse && addingMovement.warehouseData){
        return {label:addingMovement.warehouseData.name,value:addingMovement.Id_Warehouse};
    }
    return null;
}

const getAlreadyStagedQuantity = (idWhLocation:any, idWh:any, idLot:any) => {

  let alreadyStaged = 0;
  for(let i = 0; i<addingStagingMovements.length; i++){
      if(addingStagingMovements[i].Id_WarehouseLocation == idWhLocation && addingStagingMovements[i].Id_Warehouse == idWh && addingStagingMovements[i].Id_Lot == idLot){
          alreadyStaged += addingStagingMovements[i].Quantity;
      }else if(addingStagingMovements[i].Id_WarehouseLocation == idWhLocation && idWh === null){
          //when selecting just whlocation
          alreadyStaged += addingStagingMovements[i].Quantity;
      }else if(addingStagingMovements[i].Id_WarehouseLocation == idWhLocation && addingStagingMovements[i].Id_Warehouse == idWh && idLot === null){
          //when selecting just whlocation and wharehouse
          alreadyStaged += addingStagingMovements[i].Quantity;
      }
  }
  
  return alreadyStaged;
}

const getAvailableQtyField = (obj:IMaterialStagingFromMovement) => {
  if(obj?.availableQuantity){

      let calculatedAvailableQty = addingMovement.availableQuantity - addingMovement.Quantity - getAlreadyStagedQuantity(addingMovement.Id_WarehouseLocation,addingMovement.Id_Warehouse,addingMovement.Id_Lot);

      return calculatedAvailableQty;

  }

  return "";

}

const isQtyError = (obj:IMaterialStagingFromMovement, excludeStaged?:boolean) => {
  let addingQty = parseFloat(obj.Quantity);
  let alreadyStagedQty:any = excludeStaged ? 0 : getAlreadyStagedQuantity(obj.Id_WarehouseLocation,obj.Id_Warehouse,obj.Id_Lot);
  let availableQty = parseFloat(obj.availableQuantity);


  if((addingQty + alreadyStagedQty) > availableQty){
      return true;
  }

  return false;
}
const isButtonDisabledAdding = () => {
  let isEnabled = addingMovement.Quantity > 0 && addingMovement.Quantity <= (addingMovement.availableQuantity - getAlreadyStagedQuantity(addingMovement.Id_WarehouseLocation,addingMovement.Id_Warehouse,addingMovement.Id_Lot));
  return !isEnabled;
}
const getAddingMovementQty = () => {
  return addingMovement.Quantity;
}


const getMaxFillQtyAddingMovement = (obj:IMaterialStagingFromMovement) => {
  let realAvailableQty = getAvailableQtyField(obj);
  if(realAvailableQty === ""){
      realAvailableQty = 0;
  }

  let remaining = props.materialStagingProduct.totalQtyRequired - getTotalAdded();

  let maxAv = obj.Quantity + realAvailableQty;
  /*if(maxAv > props.materialStagingProduct.totalQtyRequired){
      maxAv = props.materialStagingProduct.totalQtyRequired;
  }*/

  if(maxAv > remaining){
      maxAv = remaining;
  }

  if(remaining < 0){
      maxAv = 0;
  }

  return maxAv;
}

const getAddingLot = () => {

    if(addingMovement.Id_Lot && addingMovement.lotData){
        return {label:addingMovement.lotData.name,value:addingMovement.Id_Lot};
    }
    return null;
}
    /*
  if (!props?.productData) {
    return <></>;
  }*/

    const isShowingShopfloorBaseOnStaged = (obj:IMaterialStagingFromMovement) => {
      let hasShopfloorLocationSpecified = (!obj?.Id_WarehouseLocation&& !obj?.Id_Warehouse && !obj?.Id_Lot);
    
      return hasShopfloorLocationSpecified;
    }

    const isShowingShopfloorBaseOnAdding = () => {
      
        let hasShopfloorLocationSpecified = (!addingMovement?.Id_WarehouseLocation && !addingMovement?.Id_Warehouse && !addingMovement?.Id_Lot);
        return hasShopfloorLocationSpecified && !editWarehouseLocation && !hasWhLoc.current;
    }

    useEffect(() => {
      if(!didMount){
          let setupMovements:IMaterialStagingFromMovement[] = [];

          let stockLocations = props.materialStagingProduct.stagedStock?.locationsQuantity;
          if(stockLocations){
              for(let i = 0; i<stockLocations?.length; i++){
                  setupMovements.push({
                      Id_WarehouseLocation:stockLocations[i].Id_WarehouseLocation,
                      Id_Warehouse:stockLocations[i].Id_Warehouse,
                      Id_Lot:stockLocations[i].Id_Lot,
                      warehouseLocationData:stockLocations[i].warehouseLocationData,
                      warehouseData:stockLocations[i].warehouseData,
                      lotData:stockLocations[i].lotData,
                      Quantity:stockLocations[i].Quantity,
                      availableQuantity:stockLocations[i].availableQuantity,
                  });
              }
          }


          let settingAvQty = props.materialStagingProduct.shopfloorUnstagedQtyAvailable;
          setAddingMovement({Id_WarehouseLocation:null, Id_Warehouse:null, Id_Lot:null,Quantity:0,availableQuantity:settingAvQty});
          setAddingStagingMovements(setupMovements);
          setDidMount(true);
      }
  },[didMount,props.materialStagingProduct.stagedStock?.locationsQuantity,props.materialStagingProduct.shopfloorUnstagedQtyAvailable]);
  
  useEffect(() => {
    console.log("PRV: ",previousEditWarehouseLocation);
    console.log("PRV: ",editWarehouseLocation);
    if(previousEditWarehouseLocation !== undefined && previousEditWarehouseLocation !== editWarehouseLocation){
        if(editWarehouseLocation){
            if(refAddingInputWarehouseLocation){
                refAddingInputWarehouseLocation.current.focus();
  
            }
        }
    }
},[editWarehouseLocation, previousEditWarehouseLocation]);

  return (
    <div className={styles.intPopup}>
        
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{t('materialstaging')}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.selectedProductInfo}>
              <div className={styles.seletedProductInfoLabels}>
                ({props.materialStagingProduct.code}) {props.materialStagingProduct.name}
              </div>
            </div>

            <div className={styles.remainingInfo}>
              <div className={styles.remainingInfoLabels}>
              {getTotalAddedUI()}/{props.materialStagingProduct.totalQtyRequired}
              </div>
            </div>
            <div className={`popup-content-border-sides-only-styles ${styles.content}`}>
                
                <div className={styles.movementsContainer}>
       
                <div className={styles.addedMovementContainer}>
                    <IonCardHeader
                      key={0}
                      className={styles.addingElement}
                      onClick={() => {
                        

                      }}
                    >
                      <div className={styles.addingElemBackgroundContainer}>
                        <LocalShippingIcon />
                      </div>
                      {isShowingShopfloorBaseOnAdding() ? (
                        <>
                          <div className={styles.shopfloorBaseLocation} onClick={() => {
                              setEditWarehouseLocation(true);
                          }}>
                            <div className={styles.centeredShopfloorElement}>
                              <div className={styles.iconShopfloorBaseEdit}>
                                <IonIcon size={"large"} slot="start" icon={createOutline}></IonIcon>
                              </div>
                              <div className={styles.shopfloorBase}>
                                Shopfloor 
                              </div>
                            
                            </div>
                          </div>
                        
                        </>
                      ) : (<>
                      <div className={styles.stockLocs}>
                        <div>
                          <HxfSelectorField
                          searchingFilterKey={"searchBarFilter"}
                          refTextfield={refAddingInputWarehouseLocation}
                          listJsonFunction={warehouseLocationsController().getWarehouseLocationsListJson} 
                          error={false}
                          onBlur={() => {
                            
                              setTimeout(() => {
                                  //prevent flicker
                                  if(editWarehouseLocation && !addingMovement?.Id_WarehouseLocation && !hasWhLoc.current){
                                      setEditWarehouseLocation(false);
                                  }
                              },100);

                          }}
                          callbackCacheSearchResults={() => {
                              
                          }}
                          disableInternalFiltering={true} 
                          onChange={(evt,val) => {
                          
                            setForceOpenWarehouseLocationMenu(false);
                              let idWarehouseLocation:any = null;
                              if(val !== null){
                                  idWarehouseLocation = val.value;
                              }
                              
                              if(!val && addingMovement.Id_WarehouseLocation === null || (val && val.value === addingMovement.Id_WarehouseLocation)){
                                  if(val){
                                      setEditWarehouseLocation(false);
                                  }
                                  return;
                              }

                              if(val){
                                  setEditWarehouseLocation(false);
                                  hasWhLoc.current = true;
                              }else{
                                  hasWhLoc.current = false;
                              }
                              let newData:any = null;
                              if(val){
                                  newData = {
                                      name:val.fetchedData.name,code:val.fetchedData.code
                                  };
                              }

                              let stockTraceProductParameterizedVariableValues:any = false;
                              
                              if(props.materialStagingProduct.productParameterizedVariableValues){
                                  stockTraceProductParameterizedVariableValues = props.materialStagingProduct.productParameterizedVariableValues;
                              }

                              let exactStockLocation = !idWarehouseLocation; //if id warehouse location is null, get stock from shopfloor production stock only, otherwise we get all warehouses from the same warehouse location and sum

                              let productFilters = [{
                                  Id_Product:props.materialStagingProduct.Id,
                                  exactProductStockLocation:exactStockLocation,
                                  exactProductParameterizedVariables:stockTraceProductParameterizedVariableValues ? true : false,
                                  stockMovement_productParameterizedVariables: stockTraceProductParameterizedVariableValues ? stockTraceProductParameterizedVariableValues : null,
                                  stockLocation:{
                                      Id_WarehouseLocation:idWarehouseLocation,
                                  }

                              }];
                            
                              //calculate available stock at whlocation
                              stockManagementController().getProductsStockAtLocation(productFilters,{lotTraceability:false,notInStaging:true}).then((res) => {
                                
                                  let result = res?.data?.response?.data;

                                  if(!result || result.length === 0){
                                      throw "unexpected stock not found";
                                  }
                                  let availableQuantity = 0;
                                  let stockResult = result[0].stockResult;

                                  
                                  
                                  if(stockResult){
                                      for(let i = 0; i<stockResult.length; i++){
                                          availableQuantity += stockResult[i].Quantity;
                                      }
                                  }
                                  
                                  
                                  let addingObj = {Quantity:0,availableQuantity:availableQuantity};
                                  if(!idWarehouseLocation){
                                      for(let i = 0; i<addingStagingMovements.length; i++){
                                          //sum with existing match
                                          if(addingStagingMovements[i].Id_WarehouseLocation === null){ //&& addingStagingMovements[i].Id_Warehouse === null && addingStagingMovements[i].Id_Lot === null
                                              addingObj.availableQuantity += addingStagingMovements[i].Quantity;
                                          }
                                      }
                                      setAddingMovement({...addingMovement,Id_WarehouseLocation:null,Id_Warehouse:null, Id_Lot:null,...addingObj});
                                  }else{
                                      for(let i = 0; i<addingStagingMovements.length; i++){
                                          //sum with existing match
                                          if(addingStagingMovements[i].Id_WarehouseLocation === idWarehouseLocation){
                                              addingObj.availableQuantity += addingStagingMovements[i].Quantity;
                                          }
                                      }
                                      setAddingMovement({...addingMovement,Id_WarehouseLocation:idWarehouseLocation,Id_Warehouse:null, Id_Lot:null,warehouseLocationData:newData,...addingObj});
                                  }
                                  
                              }).catch((res:any) => {
                                  feedbackService.notifyToast(t("generic.critical.error"), "error");
                              });
                              //
                            
                          }}
                          value={getAddingWarehouseLocation()}
                          uniqueId={"whloc_stagefrom"}
                          labelPlaceholder={t("warehouselocation")}
                     
                      />
                        </div>
                        <div>
                        <HxfSelectorField
                                        searchingFilterKey={"searchBarFilter"}
                                        listJsonFunction={warehousesController().getWarehousesListJson} 
                                        listJsonOptions={{Id_WarehouseLocation:addingMovement.Id_WarehouseLocation}}
                                        error={false}
                                        callbackCacheSearchResults={() => {
                                            
                                        }}
                                        disabled={!addingMovement?.Id_WarehouseLocation}
                                        disableInternalFiltering={true} 
                                        onChange={(evt:any,val:any) => {
                                          
                                            
                                            let idWarehouse:any = null;
                                            if(val !== null){
                                                idWarehouse = val.value;
                                            }
                                            
                                            if(!val && addingMovement.Id_Warehouse === null || (val && val.value === addingMovement.Id_Warehouse)){
                                                if(val){
                                                    setEditWarehouse(false);
                                                }
                                                return;
                                            }
                                            if(val){
                                                setEditWarehouse(false);
                                            }
                                            let newData:any = null;
                                            if(val){
                                                newData = {
                                                    name:val.fetchedData.name,code:val.fetchedData.code
                                                };
                                            }

                                            let stockTraceProductParameterizedVariableValues:any = false;
                                            
                                            if(props.materialStagingProduct.productParameterizedVariableValues){
                                                stockTraceProductParameterizedVariableValues = props.materialStagingProduct.productParameterizedVariableValues;
                                            }

                                            let exactStockLocation = false; 

                                            let productFilters = [{
                                                Id_Product:props.materialStagingProduct.Id,
                                                exactProductStockLocation:exactStockLocation,
                                                exactProductParameterizedVariables:stockTraceProductParameterizedVariableValues ? true : false,
                                                stockMovement_productParameterizedVariables: stockTraceProductParameterizedVariableValues ? stockTraceProductParameterizedVariableValues : null,
                                                stockLocation:{
                                                    Id_WarehouseLocation:addingMovement.Id_WarehouseLocation,
                                                    Id_Warehouse:idWarehouse
                                                }

                                            }];
                                     
                                            //calculate available stock at whlocation
                                            stockManagementController().getProductsStockAtLocation(productFilters,{lotTraceability:false,notInStaging:true}).then((res) => {
                                                
                                                let result = res?.data?.response?.data;

                                                if(!result || result.length === 0){
                                                    throw "unexpected stock not found";
                                                }
                                                let availableQuantity = 0;
                                                let stockResult = result[0].stockResult;

                                            
                                                if(stockResult){
                                                    for(let i = 0; i<stockResult.length; i++){
                                                        availableQuantity += stockResult[i].Quantity;
                                                    }
                                                }
                                
                                                let addingObj = {Quantity:0,availableQuantity:availableQuantity};
                                                if(!idWarehouse){
                                                    for(let i = 0; i<addingStagingMovements.length; i++){
                                                        //sum with existing match, it was set to null so we set the availableqty according to the previous selector associated
                                                       if(addingStagingMovements[i].Id_WarehouseLocation === addingMovement.Id_WarehouseLocation){
                                                           addingObj.availableQuantity += addingStagingMovements[i].Quantity;
                                                       }
                                                    }
                                                    setAddingMovement({...addingMovement,Id_Warehouse:null, Id_Lot:null,...addingObj});
                                                }else{

                                                    for(let i = 0; i<addingStagingMovements.length; i++){
                                                        //sum with existing match
                                                       if(addingStagingMovements[i].Id_Warehouse === idWarehouse){
                                                           addingObj.availableQuantity += addingStagingMovements[i].Quantity;
                                                       }
                                                    }
                                                    setAddingMovement({...addingMovement,Id_Warehouse:idWarehouse,Id_Lot:null,warehouseData:newData,...addingObj});
                                                }
                                                
                                            }).catch((res:any) => {
                                                feedbackService.notifyToast(t('generic.critical.error'), "error");
                                            });
                                        
                                        }}
                                        value={getAddingWarehouse()}
                                        uniqueId={"wh_stagefrom"}
                                        labelPlaceholder={t('warehouse')}
                                    />
                        </div>
                        <div>
                        <HxfSelectorField
                                searchingFilterKey={"searchBarFilter"}
                                listJsonFunction={lotsController().getLotsListJson} 
                                listJsonOptions={{Id_WarehouseLocation:addingMovement.Id_WarehouseLocation,Id_Warehouse:addingMovement.Id_Warehouse}}
                                error={false}
                                callbackCacheSearchResults={() => {
                                    
                                }}
                                disabled={!addingMovement?.Id_Warehouse}
                                disableInternalFiltering={true} 
                                onChange={(evt:any,val:any) => {
                                  
                                    let idLot:any = null;
                                    if(val !== null){
                                        idLot = val.value;
                                    }
                                    
                                    if(!val && addingMovement.Id_Lot === null || (val && val.value === addingMovement.Id_Lot)){
                                        if(val){
                                            setEditLot(false);
                                        }
                                        return;
                                    }
                                    if(val){
                                        setEditLot(false);
                                    }
                                    let newData:any = null;
                                    if(val){
                                        newData = {
                                            name:val.fetchedData.name,code:val.fetchedData.code
                                        };
                                    }

                                    let stockTraceProductParameterizedVariableValues:any = false;
                                    
                                    if(props.materialStagingProduct.productParameterizedVariableValues){
                                        stockTraceProductParameterizedVariableValues = props.materialStagingProduct.productParameterizedVariableValues;
                                    }

                                    let exactStockLocation = false; 

                                    let productFilters = [{
                                        Id_Product:props.materialStagingProduct.Id,
                                        exactProductStockLocation:exactStockLocation,
                                        exactProductParameterizedVariables:stockTraceProductParameterizedVariableValues ? true : false,
                                        stockMovement_productParameterizedVariables: stockTraceProductParameterizedVariableValues ? stockTraceProductParameterizedVariableValues : null,
                                        stockLocation:{
                                            Id_WarehouseLocation:addingMovement.Id_WarehouseLocation,
                                            Id_Warehouse:addingMovement.Id_Warehouse,
                                            Id_Lot:idLot
                                        }

                                    }];
                               
                                    //calculate available stock at whlocation
                                    stockManagementController().getProductsStockAtLocation(productFilters,{lotTraceability:false,notInStaging:true}).then((res) => {
                                        
                                        let result = res?.data?.response?.data;

                                        if(!result || result.length === 0){
                                            throw "unexpected stock not found";
                                        }
                                        let availableQuantity = 0;
                                        let stockResult = result[0].stockResult;

                                    
                                        if(stockResult){
                                            for(let i = 0; i<stockResult.length; i++){
                                                availableQuantity += stockResult[i].Quantity;
                                            }
                                        }
                        
                                        let addingObj = {Quantity:0,availableQuantity:availableQuantity};
                                        if(!idLot){
                                        
                                            for(let i = 0; i<addingStagingMovements.length; i++){
                                                //sum with existing match, it was set to null so we set the availableqty according to the previous selector associated
                                                if(addingStagingMovements[i].Id_Warehouse === addingMovement.Id_Warehouse){
                                                    addingObj.availableQuantity += addingStagingMovements[i].Quantity;
                                                }
                                            }
                                            setAddingMovement({...addingMovement, Id_Lot:null,...addingObj});
                                        }else{

                                            for(let i = 0; i<addingStagingMovements.length; i++){
                                                //sum with existing match
                                                if(addingStagingMovements[i].Id_Lot === idLot){
                                                    addingObj.availableQuantity += addingStagingMovements[i].Quantity;
                                                }
                                            }
                                            setAddingMovement({...addingMovement,Id_Lot:idLot,lotData:newData,...addingObj});
                                        }
                                        
                                    }).catch((res) => {
                                        feedbackService.notifyToast(t('generic.critical.error'), "error");
                                    });
                                    
                                }}
                                value={getAddingLot()}
                                uniqueId={"lot_stagefrom"}
                                labelPlaceholder={t('lot')}
                            />
                        </div>
                      </div>
                      </>)}
               
                      <div className={styles.availableQtyContainer} style={{color:'black'}}>
                        {getAvailableQtyField(addingMovement) === "" ? (
                          <>{t('no.stock.loc.available')}</>
                        ) :(
                          <div className={styles.availableQtyContainer}><div className={styles.avQtyLabel}><b>{t('available.qty')}:</b></div><div>{getAvailableQtyField(addingMovement)}</div></div>
                        )}
            
                      </div>
                      <div className={styles.pcqt}>
                        
                      <HxfTextfieldNumber fullWidth={true} error={isQtyError(addingMovement,false)} label={t('quantity')} onChange={(evt:any) => {
                                            let defVal:any = 0;
                                            if(evt === ""){
                                                defVal = 0;
                                            }else{
                                                defVal = parseFloat(evt);
                                            }

                                            if(defVal < 0){
                                                defVal = 0;
                                            }
                                        
                                            setAddingMovement({...addingMovement, Quantity:parseFloat(defVal)});
                                            //update available qties from  movements

                                            let currentMvs = [...addingStagingMovements];
                                            for(let i = 0; i<currentMvs.length; i++){
                                                if(currentMvs[i].Id_WarehouseLocation == addingMovement.Id_WarehouseLocation  && currentMvs[i].Id_Warehouse == addingMovement.Id_Warehouse && currentMvs[i].Id_Lot == addingMovement.Id_Lot){

                                                    currentMvs[i].availableQuantity = addingMovement.availableQuantity - parseFloat(defVal);
                                                }
                                            }

                                            setAddingStagingMovements(currentMvs);
                                        }} value={getAddingMovementQty()}/>
                                        {(getAvailableQtyField(addingMovement) !== "" && getAvailableQtyField(addingMovement) > 0 && getMaxFillQtyAddingMovement(addingMovement) > 0) && (
                                                          <div className={styles.maxBtnContainer} onClick={() => {
                                                              let maxQty = getMaxFillQtyAddingMovement(addingMovement);
                                              
                                                              let currentMvs = [...addingStagingMovements];
                                                              for(let i = 0; i<currentMvs.length; i++){
                                                                  if(currentMvs[i].Id_WarehouseLocation == addingMovement.Id_WarehouseLocation  && currentMvs[i].Id_Warehouse == addingMovement.Id_Warehouse && currentMvs[i].Id_Lot == addingMovement.Id_Lot){
                  
                                                                      currentMvs[i].availableQuantity = addingMovement.availableQuantity - parseFloat(maxQty);
                                                                  }
                                                              }

                                                              setAddingStagingMovements(currentMvs);
                                                              setAddingMovement({...addingMovement, Quantity: maxQty});
                                                          }}>
                                                              <div className={styles.maxBtn}>(fill:{getMaxFillQtyAddingMovement(addingMovement)})</div>
                                                          </div>
                                          )}
                      </div>
                      <div style={{position:'relative',left:'-2px'}}>
                        <IonButton
                            style={{width:'100%',height:'50px',fontSize:'18px'}}
                            className={styles.confirmButton}
                            disabled={isButtonDisabledAdding()}
                            onClick={() => {
                              //Todo, if equal exists, remove and merge with adding 
                              let iPos = -1;
                              let  curMvs = [...addingStagingMovements];
                              
                              for(let i = 0; i<curMvs.length; i++){
                                  if(curMvs[i].Id_WarehouseLocation === addingMovement.Id_WarehouseLocation && curMvs[i].Id_Warehouse === addingMovement.Id_Warehouse && curMvs[i].Id_Lot === addingMovement.Id_Lot){

                                      curMvs[i].Quantity = curMvs[i].Quantity + addingMovement.Quantity;
                                      curMvs[i].availableQuantity = addingMovement.availableQuantity;
                                      iPos = i;
                                      break;
                                  }
                              }
                          
                              if(iPos != -1){
                                  if(curMvs.length >= 2 && iPos != 0){
                                      let tempElem = curMvs[iPos];
                                      curMvs[iPos] = curMvs[0];
                                      curMvs[0] = tempElem;
                                  }
                                  setAddingStagingMovements([...curMvs]);
                              }else{
                                  setAddingStagingMovements([addingMovement,...addingStagingMovements]);
                              }
                              
                              //since default selection whloc, wh, lot is the shopfloor, get its quantity, and subtract the staged qty and set as available qty
                              let availableQty = props.materialStagingProduct.shopfloorUnstagedQtyAvailable - getAlreadyStagedQuantity(null,null,null);

                              for(let i = 0; i<addingStagingMovements.length; i++){
                                  //sum with existing match
                                  if(addingStagingMovements[i].Id_WarehouseLocation === null){ //&& addingStagingMovements[i].Id_Warehouse === null && addingStagingMovements[i].Id_Lot === null
                                    availableQty += addingStagingMovements[i].Quantity;
                                  }
                              }
                              hasWhLoc.current = false;
                              setAddingMovement({Id_WarehouseLocation:null,Id_Warehouse:null,Id_Lot:null,Quantity:0,availableQuantity:availableQty});
                            }}
                          >
                           <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
                            {t('add')}
                        </IonButton>
                      </div>
                    </IonCardHeader>    
                  
                  </div>
                  {addingStagingMovements.map((obj:IMaterialStagingFromMovement,index) => (
                    <div key={"keymsfm_" + index} className={styles.addedMovementContainer}>
                      <IonCardHeader
                        key={0}
                        className={styles.elemElement}
                        onClick={() => {
                          

                        }}
                      >
                        <div className={styles.elemBackgroundContainer}>
                          <LocalShippingIcon />
                        </div>
                        <div className={styles.btnRemoveMovementContainer} onClick={() => {
                              
                              let curMovements:IMaterialStagingFromMovement[] = [];
                              for(let i = 0; i<addingStagingMovements.length; i++){
                                  if(i !== index){
                                      curMovements.push(addingStagingMovements[i]);
                                  }
                              }
                              setAddingStagingMovements(curMovements);
                        }}>
                          <div className={styles.removeIcon}>  <RemoveIcon/></div>
                        </div>

                        {isShowingShopfloorBaseOnStaged(obj) ? (
                          <>
                          <div className={styles.locDiv}><b>{t('location')}:</b>{t('shopfloor')}</div>
                          </>
                        ) : (
                          <div className={styles.stockLocs}>
                            <div>
                              <div><b>{t('warehouselocation')}:</b></div>
                              <div>{obj.warehouseLocationData?.name}</div>
                            </div>
                            <div>
                              <div><b>{t('warehouse')}:</b></div>
                              <div>{obj.warehouseData?.name}</div>
                            </div>
                            <div>
                              <div><b>{t('lot')}:</b></div>
                              <div>{obj.lotData?.name}</div>
                            </div>
                          </div>
                        )}


                        <div className={styles.pcqtAdded}>
                          <div>
                          <div className={styles.availableQtyContainer}><div className={styles.avQtyLabel}><b>{t('available.qty')}:</b></div><div>{obj.availableQuantity - getAlreadyStagedQuantity(obj.Id_WarehouseLocation, obj.Id_Warehouse, obj.Id_Lot)}</div></div>
                         
                              <div style={{marginTop:'10px'}}>
                              <HxfTextfieldNumber  fullWidth={true} error={isQtyError(obj, true)} label={t('quantity')}  onChange={(evt:any) => {
                                                  let defVal:any = 0;
                                                  if(evt === ""){
                                                      defVal = 0;
                                                  }else{
                                                      defVal = parseFloat(evt);
                                                  }
                                                  if(defVal < 0){
                                                      defVal = 0;
                                                  }
                                                  let curMovements:IMaterialStagingFromMovement[] = [...addingStagingMovements];
                                                  curMovements[index].Quantity = defVal;
                                                  setAddingStagingMovements(curMovements);
                                }} value={obj.Quantity}/>
                              </div>

                          </div>
                        </div>
                      </IonCardHeader>    
                    
                    </div>
                  ))}



                 
                </div>
                <div className={styles.spaceFiller}></div>
            </div>
          </div>
         
          <StageProductComponentPopupConfirmationButtons onConfirm={() => {

                let stageStockLocations:IMaterialStagingStagedStockLocationQuantity[] = [];
                let totalStock = 0;
                for(let i = 0; i<addingStagingMovements.length; i++){
                    stageStockLocations.push({
                        Id_WarehouseLocation:addingStagingMovements[i].Id_WarehouseLocation,
                        Id_Warehouse:addingStagingMovements[i].Id_Warehouse,
                        Id_Lot:addingStagingMovements[i].Id_Lot,
                        Quantity:addingStagingMovements[i].Quantity,
                        warehouseLocationData:addingStagingMovements[i].warehouseLocationData,
                        warehouseData:addingStagingMovements[i].warehouseData,
                        lotData:addingStagingMovements[i].lotData,
                        availableQuantity:addingStagingMovements[i].availableQuantity
                    });
                    totalStock += addingStagingMovements[i].Quantity;
                }
                let newMaterialProduct = props.materialStagingProduct;
                newMaterialProduct.stagedStock = {
                    locationsQuantity:stageStockLocations,
                    totalStock:totalStock
                };

                props.onUpdateMaterialProduct(newMaterialProduct);
          }}/>
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default StageProductComponentPopup;
