import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import { InputAdornment } from "@mui/material";
import { closeOutline, hammerOutline } from "ionicons/icons";
import { useState } from "react";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import { dispatchCustomPopupsStore, useCustomPopupsStore } from "../CustomPopupsStore";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from "./CollabCancelPopup.module.scss";
import useProductionProcess from "../../../../../barrel/hooks/useProductionProcess";


function CollabCancelPopup(){
  
    const {t} = useHxfTranslation();

    const popupsStore = useCustomPopupsStore();
    const productionProcess = useProductionProcess();
    if (!popupsStore.collabCancelPopup) {
        return <></>;
    }
    return (
    <>
        <SimpleContentPopup>
        <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t("production.record.cancelation")}</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                dispatchCustomPopupsStore({
                  type: "CLOSE_COLLAB_CANCEL_POPUP",
                });
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          <div className={styles.content}>
            <div style={{display:'flex', justifyContent:'center',marginTop:30,marginBottom:30}}>
              <div>
                <div className={styles.btnCancel}>
                      <IonButton
                        color="danger"
                        size="large"
                        onClick={() => {
                          dispatchCustomPopupsStore({
                            type: "CLOSE_COLLAB_CANCEL_POPUP",
                          });
                          productionProcess.cancelRecord({collabCancelParentRecord:true});
                        }}
                    >
                       <span style={{margin:5}}><DeleteForeverIcon fontSize="large"/></span>
                        
                        {t('cancel.full.record')}
                
                    </IonButton>
                </div>
                <div className={styles.orSeparate}>
                  {t('or')}
                </div>
                <div className={styles.btnCancel}>

                    <IonButton
                        size="large"
                        onClick={() => {
                          dispatchCustomPopupsStore({
                            type: "CLOSE_COLLAB_CANCEL_POPUP",
                          });
                          productionProcess.cancelRecord();
                        }}
                    >
                        <span style={{margin:5}}><HighlightOffIcon fontSize="large"/></span>
                        {t('cancel.participation')}
                
                    </IonButton>
                </div>
              </div>


            </div>

          </div>
          
        </div>


  
      </div>
        </SimpleContentPopup>
    </>)
}

export default CollabCancelPopup;