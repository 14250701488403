import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import styles from "./HxfImageUploaderPreviewPopup.module.scss";
interface IProps{
    onClose?:any;
    imgSrc?:any;
}
function HxfImageUploaderPreviewPopup(props:IProps){

    let stylex:any = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%',
        maxHeight: '90vh', //hxf vh
        overflow:'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display:'flex',
        justifyContent:'center'
    };

    return (
    
        <Modal
        open={true}
        onClose={() => {
            props.onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <>
            <div>
            <div className={styles.topBar} onClick={() => {
                props.onClose();
            }}><div className={styles.closeBtn}><CancelIcon/></div></div>
                </div>
            <Box sx={stylex}>

                <img style={{objectFit:'contain'}} src={props.imgSrc}/>
            </Box>
            </>
        </Modal>
 
    )
}

export default HxfImageUploaderPreviewPopup;