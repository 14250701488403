import { useCallback, useEffect, useRef, useState } from "react";
import { useManageProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenAskUseMachineCurrent.module.scss";
import ActionScreenAskUseMachineCurrentConfirmationButtons from "./ActionScreenAskUseMachineCurrentConfirmationButtons";
import ActionScreenMachineElement from "./ActionScreenMachineCurrentElement";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import CustomElementGoBack from "../../../../../../pages/CustomElements/CustomElementGoback/CustomElementGoBack";
import SegmentGoBack from "./SegmentGoBack/SegmentGoBack";
import HxfBarcodeScanner from "../../../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import ScanBarcodePopup from "../../../../../FeaturePages/ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import MachineIdentifierScanner from "./MachineIdentifierScanner/MachineIdentifierScanner";
import operationProductsController from "../../../../../../barrel/controllers/operationProductsController";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
import productionRecordOperationActionTempStoresController from "../../../../../../barrel/controllers/productionRecordOperationActionTempStoresController";
import { Backdrop, CircularProgress } from "@mui/material";
import { dispatchActionScreenAskUseMachineCurrentElementStore, useActionScreenAskUseMachineCurrentElementStore } from "./ActionScreenAskUseMachineCurrentElementStore";
import usePrTempStore from "../../../../../../barrel/hooks/usePrTempStore";
interface IResult {
  irregularitysList: any;
}

function ActionScreenAskUseMachineCurrent(props: any) {
  const DISABLE_BUSY_RESTRICTION = true; //eventually there will be a setting in the action to define if we should ignore "business", because during actions we tell the system which machine we used unlike the "preaction" which we tell the system which machine we are going to use (present =/= future)
  const RESYNC_FREQ_SECONDS = 5;
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const {t} = useHxfTranslation();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const { sessionState } = useGlobalState();
  const preConfirm = useActionPreConfirm();
  const feedbackService = useFeedbackService();
  const currentActionScreenManager = useActionScreenManagerStore();
  const poSelectionStoreManager = useManageProductionOrderSelectionStore();
  const prTempStoreHandler = usePrTempStore();
  const currentStore = useActionScreenAskUseMachineCurrentElementStore();
  const [machinesData, setMachinesData] = useState<any>(null);
  const previousMachineData = usePrevious(machinesData);
  const myLocalStorage = useLocalStorageHandler();
  const [optionsMachineSegmentsData, setOptionsMachineSegmentsData] = useState([]);
  const [isSelectingSegment, setIsSelectingSegment] = useState(false);

  const [extraOperationProductData, setExtraOperationProductData] = useState<any>(null);
  const timerScroll = useRef<any>(null);
  const timerResync = useRef<any>(null);
  const isFirstResync = useRef<any>(true);


  let initialMachineSelState = {
    
      Id_Machine: 0,
      Id_MachineSegment:null,
      segmentData:null,
      machineData:null
    
  };

  
  const [selectedMachine, setSelectedMachine] = useState<any>(initialMachineSelState);
  const [changedSelectedMachine, setChangedSelectedMachine] = useState<any>(false);
  const [result, setResult] = useState<IResult>({ irregularitysList: [] });

  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  // states to allow clearing when loading a default stock location config

  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);

  function forceRerender() {
    setIndexkey(indexkey + 1);
  }

  function confirmMachineSelection() {
    return { Id_Machine: selectedMachine.Id_Machine, Id_MachineSegment:selectedMachine.Id_MachineSegment };
  }
  function confirmQuantity(res: any) {
    if (isNaN(res) || res == "") {
      setShowErrorInput(true);
      forceRerender();
      return false;
    }
    let valueNumber = parseFloat(res) + 0;

    let allowZeroQuantity = true;
    if (allowZeroQuantity && valueNumber === 0) {
      let newResult = { ...result, quantity: 0 };
      setResult(newResult);
      return newResult;
    } else if (valueNumber === 0 && !allowZeroQuantity) {
      setShowErrorInput(true);
      forceRerender();
      return false;
    } else {
      //let returnObj = {quantity:valueNumber};
      let newResult = { ...result, quantity: valueNumber };
      setResult(newResult);
      return newResult;
      //props.callbackSuccessConfirm(returnObj);
    }

    return false;
  }

  const confirmClick = () => {
    if(!preConfirm.check()){
      return;
    }
    let storeResult = confirmMachineSelection();

    dispatchActionScreenAskUseMachineCurrentElementStore({type:"SET_SELECTED_MACHSEG",payload:{...selectedMachine}});
    
    let shouldStoreTempData = productionOrderSelectionStore?.currentOperation?.shopfloorSetting_allowStoreTempData === 1;
   
    if(props.skipStoreTempDataIfSelectionUnchanged && !changedSelectedMachine){
      shouldStoreTempData = false;
    }


    //in case its minimized
    if(shouldStoreTempData){
      let currActConfig = currentActionScreenManager?.currentActionConfig;
      let idOperationProductAction = currActConfig?.Id_OperationProduct_Action;
      let operationActionData = currentActionScreenManager?.operationActionData;
      let idOperationProduct = operationActionData?.Id_Operation;
      let idPr = productionOrderSelectionStore?.productionRecord?.Id;
      let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
      let relevantInfo = storeResult;

      let hashEditPr = poSelectionStoreManager.getHashEditByCurrentSelectionOperation();
      clearTimerResync(); //we need to clear timer resync or it might clear setIsLoading when it shouldnt
      setIsLoading(true);
      productionRecordOperationActionTempStoresController().save({
        workerCode:currentWorkerCode,
        Id_OperationProduct:idOperationProduct,
        Id_OperationProduct_Action:idOperationProductAction,
        Id_ProductionRecord:idPr,
        data:relevantInfo,
        hashEdit:hashEditPr
      }).then((res) => {

        setIsLoading(false);

        //update hashedit

        if(!res?.data?.prStore){
          throw "unexpected response";
        }

        let idPrTempStore = res?.data?.prStore?.Id;
        let newHash = res?.data?.prStore?.hashEdit;
        poSelectionStoreManager.updateHashEditPrTempStoreById(idPrTempStore, newHash);
        props.callbackSuccessConfirm(storeResult);
      }).catch((res) => {
        initiateTimerResync();
        setIsLoading(false);
        prTempStoreHandler.handleSaveErrors(res);
        //err failed
      });
    }else{
      props.callbackSuccessConfirm(storeResult);
    }
 
  };

  const clickedMachineSegmentIndex = (optionSegment:any) => {

    

    setSelectedMachine({...selectedMachine,
      Id_MachineSegment:optionSegment.Id_MachineSegment,
      segmentData:optionSegment
    });
  }
  // eslint-disable-next-line
  const resyncMachinesData = () => {
    let idOperationProduct = productionOrderSelectionStore?.currentOperation.Id;
    let idOperationAction = currentActionScreenManager?.operationActionData?.Id;

    let idPr = productionOrderSelectionStore.productionRecord.Id;
    let idPop = productionOrderSelectionStore.productionRecord.Id_ProductionOrder_Product;
    operationProductsController().getMachines(idOperationProduct,{
      ignoreMachineryFilters:true,
      Id_ProductionOrder_Product:idPop,
      fetchPrStore:{
        Id_OperationProduct_Action:idOperationAction,
        Id_OperationProduct:idOperationProduct,
        Id_ProductionRecord:idPr
      }
    }).then((res:any) => {


      let responseObj = res?.data?.response;
      let machinesDataRes = responseObj?.data;
      let extraOperationDAta = responseObj?.operationProduct;
      if(!machinesDataRes){
        throw "invalid return machines data";
      }
      setMachinesData(machinesDataRes);
      setExtraOperationProductData(extraOperationDAta);

      /*
      let prStore = responseObj?.prStore;
      if(prStore){
        let prStoreData = prStore?.data;
        let prStoreMachineSelectedData = null;
        try {
          prStoreMachineSelectedData = JSON.parse(prStoreData);
     
        } catch (objError) {
          console.log("Failed to parse prStore at askusemach");
        }
     
        let machineToSelect = null;
         //currently does not support segment auto select, todo later
         let selMachineData = null;
        if(prStoreMachineSelectedData?.Id_Machine){
         
          let idMachineSel = prStoreMachineSelectedData?.Id_Machine;

          for(let i = 0; i<machinesDataRes.length; i++){
            //find machine
            if(machinesDataRes[i].Id_Machine === idMachineSel){
              selMachineData = machinesDataRes[i];
              break;
            }
          }
        
          if(selMachineData){
            machineToSelect = {Id_Machine: idMachineSel, machineData: selMachineData};
          }
        }


        let selectingMachineObj:any = null;
        if(machineToSelect){
          selectingMachineObj = {...initialMachineSelState, Id_Machine:machineToSelect.Id_Machine, machineData:machineToSelect.machineData};
        
          //todo handle segment here inside this condi
        }

        if(selectingMachineObj){

          //to trigger after setmachines data
          setTimeout( () => {
            setSelectedMachine(selectingMachineObj);
          },200);
         
        }
       
         
      }*/

 
    
      
      setIsLoading(false);
    }).catch((res) => {
      feedbackService.notifyToast("error","error");
      setIsLoading(false);
      
    });
  }
  // eslint-disable-next-line
  const scrollToMachine = (idMachine = null, idMachineSegment = null) => {

    if(idMachineSegment){
      //todo
      //

      //data-machine-segment-id-selection

      let content = document.querySelector("[data-content='true']");
      let elem:any = document.querySelector("[data-machine-segment-id-selection='" + idMachine  +"']");
      if(elem && content){
        content.scrollTo({
          top: elem.offsetTop,
          behavior: "smooth"
        });
      }
    }else{
  

      let content = document.querySelector("[data-content='true']");
      let elem:any = document.querySelector("[data-machine-id-selection='" + idMachine  +"']");
      let container = elem.closest("[data-machines-container='true']");
      /*if(elem){
        elem.scrollIntoView({
          behavior: "smooth",
          block: "center", inline: "nearest",
          container
        });
      }*/
      if(elem && content){
        content.scrollTo({
          top: elem.offsetTop,
          behavior: "smooth"
        });
      }
    }

  }


  const getIsBusyMachine = useCallback((obj:any) => {
  
    if(!DISABLE_BUSY_RESTRICTION){
      return false;
    }
    if(obj?.allowParallelization === 0 && obj?.runningProcesses > 0){
      return true;
    }
    return false;
  },[DISABLE_BUSY_RESTRICTION]);

  const getIsEnabledMachine = useCallback((obj: any) => {
    if(extraOperationProductData?.allowMachineSelectAnyCircunstance === 1){
      return true;
    }
    if(obj.allowWorkerActivityOverride === 1){
      return true;
    }
    if (getIsBusyMachine(obj)) {
      return false;
    }

    return true;
  },[getIsBusyMachine,extraOperationProductData]);


  const clickedMachineIndex = useCallback(
    (index: number, options = {}) => {
  
      
      let obj = machinesData[index];
   
      let selectingMachineObj = {
        Id_Machine: obj.Id_Machine,
        Id_MachineSegment:null,
        segmentData:null,
        machineData:machinesData[index]
      };
      if(!getIsEnabledMachine(machinesData[index])){
        return;
      }


      let machineSegmentsData = machinesData[index]?.machineSegmentsData;

      if(options?.attachMachineSegment){
        selectingMachineObj.Id_MachineSegment = options.attachMachineSegment.Id_MachineSegment;
        selectingMachineObj.segmentData = options.attachMachineSegment.segmentData;
        if(selectingMachineObj.Id_MachineSegment){
          setOptionsMachineSegmentsData(machinesData[index].machineSegmentsData);
          setIsSelectingSegment(true);
        }
      }else{
        if(machineSegmentsData?.length > 0){
          if(machineSegmentsData.length === 1){
            //select the only one available as selected in the background
  
            selectingMachineObj.Id_MachineSegment = machineSegmentsData[0].Id_MachineSegment;
            selectingMachineObj.segmentData = machineSegmentsData[0].optionSegment;
            setIsSelectingSegment(false);
          }else{
            setOptionsMachineSegmentsData(machinesData[index].machineSegmentsData);
            setIsSelectingSegment(true);
          }
  
        }
      }

  
      setSelectedMachine({...selectingMachineObj
      });
     
      if(options?.smoothScrollToSelection){

        timerScroll.current = setTimeout(() => {

          scrollToMachine(selectingMachineObj.Id_Machine,selectingMachineObj.Id_MachineSegment);
        },250);
      }
      
      setRemountKeypadIndex(remountKeypadIndex + 1);
    },
    [
      getIsEnabledMachine,
      remountKeypadIndex,
      scrollToMachine,
      machinesData
    ]
  );

  const closeCallback = () => {
    props.callbackOnClose(
      currentActionScreenManager?.currentActionConfig?.machinesList
    );
  };
  function stepConfirm(res: any) {
    /*let storeResult = confirmQuantity(res);
            if(storeResult !== false){
                    //
                    if(storeResult.quantity > 0){
                        let addingIrregularityObj = {...addingIrregularityData, filledIrregularityData:{...addingIrregularityData.filledIrregularityData, quantity: storeResult.quantity}};
                    
                        
                        
                    }
                    
                    setCurrentStep("SELECT_IRREGULARITY");
            } */
  }
  const goBackFromSegment = () => {
    setSelectedMachine({...selectedMachine, Id_Machine:0,Id_MachineSegment:null,segmentData:null});
    setIsSelectingSegment(false);
  }


  useEffect(() => {
    return () => {
      // clear the timer reference when the component unmounts
      console.log("Clear timer scroll");
      if(timerScroll.current){
        clearTimeout(timerScroll.current);
      }

      clearTimerResync();
      
    };
  }, []);


  const clearTimerResync = () => {
    if(timerResync.current){
      clearInterval(timerResync.current);
    }
  }

  const initiateTimerResync = () => {
     // if(!DISABLE_BUSY_RESTRICTION){
      timerResync.current = setInterval(() => {
        resyncMachinesData();
      },RESYNC_FREQ_SECONDS*1000);
    //}
  }
  
  useEffect(() => {
    if (!didMount) {
      //mounting

      /*if (
        currentActionScreenManager?.currentActionConfig?.machinesList
          ?.length === 1 &&
        currentActionScreenManager?.currentActionConfig?.machinesList[0]
          ?.temp_isBusyFull === false
      ) {
        clickedMachineIndex(0);
      }*/

      resyncMachinesData();


      initiateTimerResync();

      setDidMount(true);
    }
  }, [
    didMount,currentActionScreenManager?.currentActionConfig,productionOrderSelectionStore.prTempStore,currentActionScreenManager?.operationActionData
    ,currentActionScreenManager?.currentActionConfig?.irregularitysList,
    clickedMachineIndex,
    resyncMachinesData,
    DISABLE_BUSY_RESTRICTION
  ]);

  useEffect(() => {
    return () => {
      //unmounting
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(isFirstResync.current){
 
      if(machinesData && JSON.stringify(machinesData) !== JSON.stringify(previousMachineData)){
        isFirstResync.current = false;

        let currActConfig = currentActionScreenManager?.currentActionConfig;
        let prTempStore = productionOrderSelectionStore?.prTempStore ?  productionOrderSelectionStore.prTempStore : [];
        let idOperationProductAction = currActConfig?.Id_OperationProduct_Action;
        let operationActionData = currentActionScreenManager?.operationActionData;

        
        let idOperationProduct = operationActionData?.Id_Operation;
        let actionTempStoredData = null;
        for(let i = 0;i<prTempStore.length; i++){
            let ptsId_OperationProduct = prTempStore[i]?.Id_OperationProduct;
            let ptsId_OperationProduct_Action = prTempStore[i]?.Id_OperationProduct_Action;
            if(ptsId_OperationProduct === idOperationProduct && ptsId_OperationProduct_Action === idOperationProductAction){
              actionTempStoredData = prTempStore[i];
              break;
            }
              
        }
  
        let hasBrowserStoredMachineSelectionData = currentStore?.selectedMachineAndSegmentData ? true : false;

        if(hasBrowserStoredMachineSelectionData){
          setSelectedMachine(currentStore?.selectedMachineAndSegmentData);
        }else{
          if(actionTempStoredData){
            let selMachineData = actionTempStoredData?.data;
            let machsData = machinesData;
            if(machsData){
              for(let i = 0; i<machsData.length; i++){
                if(machsData[i].Id_Machine === selMachineData.Id_Machine){
                  clickedMachineIndex(i);
                }
              }
            }
          }else{
  
            if(machinesData.length === 1){
     
              clickedMachineIndex(0);
            }
          }
        }
     

    

      }
    }
  },[machinesData, previousMachineData, clickedMachineIndex,currentActionScreenManager?.currentActionConfig,currentActionScreenManager?.operationActionData,productionOrderSelectionStore.prTempStore]);

  if (isLoading) {
    return (<>
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={() => {
  
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      </>);
  }


  if(isSelectingSegment){
    return (
      <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar callbackOnClose={closeCallback} title="Machine Segments" />
        <div className={styles.machineTip}><div className={styles.machineContainer}>{selectedMachine?.machineData?.code}</div></div>
        <div className={styles.content} data-content="true">

          {currentActionScreenManager?.currentActionConfig?.machinesList?.length === 1 ? 
          (
          <>{t("usethis.segment.confirm")}</>
          ) : (
            <>{t("select.a.machinesegment")}</>
          )}
          
          <div className={`${styles.selectionOuter}`}>
            <div className={styles.selectionContainer}>
              <SegmentGoBack onClick={() => {
                goBackFromSegment();
              }}/>
              {optionsMachineSegmentsData.map(
                (obj: any, index: number) => (
                  <div key={"machine_" + index} data-machine-segment-id-selection={obj.Id_MachineSegment}>
                    <ActionScreenMachineElement
                      isSegment={true}
                      enabled={getIsEnabledMachine(obj)}
                      
                      selected={obj.Id_MachineSegment === selectedMachine.Id_MachineSegment}
                      name={obj.name}
                      code={obj.code}
                      onClick={() => {
                        setChangedSelectedMachine(true);
                        clickedMachineSegmentIndex(obj);
                      }}
                    />
                  </div>
                )
              )}
        
            </div>
          </div>
        </div>
      </div>

      <ActionScreenAskUseMachineCurrentConfirmationButtons
        enabled={selectedMachine.Id_MachineSegment !== null}
        typeShow={"CONFIRM"}
        onClickConfirm={() => {


            //set machine segment and confirm
            confirmClick();
          
         
         
        }}
      />
    </>
    )
  }
 




  return (
    <>

      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar callbackOnClose={closeCallback} title={t('machines')} />

        <div className={styles.content} data-content={true}>
          {machinesData && machinesData?.length === 1 ? 
          (
          <>{t("usethis.machine.confirm")}</>
          ) : (
            <>{t("select.a.machine.used")}</>
          )}
          
          <div className={`${styles.selectionOuter}`}>
            <div className={styles.selectionContainer} data-machines-container="true">
              {sessionState.userSessionData?.machinesCamScanner === 1 && (
              <MachineIdentifierScanner onReturnSelectedMachine={(idMachine:any, idMachineSegment:any) => {

           

                 
                let machineName = "";
                let machineCode = "";
                let machineData = null;
                let segmentData = null;
                let machines = machinesData;
                
                let machineIndex = 0;
                for(let i = 0; i<machines.length; i++){
                  let machineSegments = null;
                  if(parseInt(machines[i].Id_Machine) === parseInt(idMachine)){
                    machineData = machines[i];
                    machineIndex = i;
                    if(idMachineSegment){
                      machineSegments = machines[i]?.machineSegmentsData ? machines[i]?.machineSegmentsData : [];
                      for(let j = 0; j<machineSegments.length; j++){
                        if(parseInt(machineSegments[j].Id_MachineSegment) === parseInt(idMachineSegment)){
                          segmentData = machineSegments[j];
                          break;
                        }
                      }

                    }

                    if(getIsBusyMachine(machines[i])){
                      feedbackService.notifyToast("This machine is currently busy!", "error");
                      return;
                    }

                    
                    break;
                  }
                }

                if(machineData){
                
                  clickedMachineIndex(machineIndex, {
                    attachMachineSegment:{
                      Id_MachineSegment:idMachineSegment,
                      segmentData:segmentData
                    },
                    smoothScrollToSelection: true
                  });
                }
                
       
                
          }}/>
              )}

              {machinesData?.length > 0 && machinesData.map(
                (obj: any, index: number) => (
                  <div key={"machine_" + index} data-machine-id-selection={obj.Id_Machine}>
                    <ActionScreenMachineElement
                      enabled={getIsEnabledMachine(obj)}
                      isBusy={getIsBusyMachine(obj)}
                      selected={obj.Id_Machine === selectedMachine.Id_Machine}
                      name={obj.name}
                      code={obj.code}
                      onClick={() => {
                          setChangedSelectedMachine(true);
                          clickedMachineIndex(index);
                        
                       
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <ActionScreenAskUseMachineCurrentConfirmationButtons
        enabled={selectedMachine.Id_Machine !== 0}
        typeShow={"CONFIRM"}
        onClickConfirm={() => {

          let alwaysAllow = extraOperationProductData?.allowMachineSelectAnyCircunstance === 1;
          let allowsOverride = selectedMachine?.machineData?.allowWorkerActivityOverride === 1;

          if(alwaysAllow || allowsOverride || !getIsBusyMachine({
            Id_Machine:selectedMachine.Id_Machine,
            allowParallelization:selectedMachine.machineData.allowParallelization,
            runningProcesses:selectedMachine.machineData.runningProcesses
          })){
            confirmClick();
          }
          
        }}
      />
    </>
  );
}
export default ActionScreenAskUseMachineCurrent;
