import createStore from "../../../AppCustomStateManagement/createStore";
// Library interfaces
interface IDowntimesFooterUIStore {
  showFooter:boolean;
}

interface IDowntimesUIStoreAction {
  type: any;
  payload?: any;
}

const initialState = {
  showFooter: false
};

const reducer = (
  store: IDowntimesFooterUIStore,
  action: IDowntimesUIStoreAction
) => {
  switch (action.type) {
    case "SET_FINALIZER_DATA":
        store = {...action.payload};
        return store;
    case "SET_SHOW_STATE":
      store.showFooter = action.payload;
      return store;
    case "RESET_STORE":
      store = initialState;
      return store;
    default:
      return store;
  }
};

export const [useDowntimesFooterUIStore, dispatchDowntimesFooterUIStore] = createStore(
  initialState,
  reducer
);
