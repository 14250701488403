import { IonButton, IonIcon, IonLoading } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import serializedProductsController from "../../../../../../../../barrel/controllers/serializedProductsController";
import useFeedbackService from "../../../../../../../../barrel/hooks/useFeedbackService";
import useWindowSize from "../../../../../../../../barrel/hooks/useWindowSize";
import MobileWrapperCommunicationService from "../../../../../../../../barrel/services/mobileWrapperCommunicationService";
import { IHxfBarcodeSettings } from "../../../../../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import ScanBarcodePopup from "../../../../../../../FeaturePages/ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import { useProductionOrderSelectionStore } from "../../../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { useActionScreenManagerStore } from "../../../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../../../Components/ActionScreenTopBar";
import useActionScreenTopBar from "../../../Components/hooks/useActionScreenTopBar";
import styles from "./ActionScreenIdentifyProductSerialIdentify.module.scss";




interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}

function ActionScreenIdentifyProductSerialIdentify(props: IProps) {
  const confirmationSeconds = 1;
  const [width, height] = useWindowSize();
  const actTopBar = useActionScreenTopBar();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);
  const [lastScannedPossibleSerial, setLastScannedPossibleSerial] = useState<any>(null);
  const currentActionScreenManager = useActionScreenManagerStore();
  
  const [currentSerialIndex, setCurrentSerialIndex] = useState(0);
  const [enabledConfirmBtn, setEnabledConfirmBtn] = useState(false);
  const feedbackService = useFeedbackService();
  const [serials, setSerials] = useState<any>([]);

  const [numberProductsToIdentify, setNumberProductsToIdentify] = useState(0);

  const countdown = useRef(0);
  const countdownTimer = useRef<any>(null);
  const [countdownVal, setCountdownVal] = useState<any>(null);
  const [didMount, setDidMount] = useState(false);

  const [showAddMoreConfirm, setShowAddMoreConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const barcodeScannerSettings:IHxfBarcodeSettings = {
    useCamera:true,
    useExternalDevice:true,
    allowedScanFormats:[],
    allowAllFormats:true
  };


  const tickCountdown = () => {
      if(countdown.current > 0){
          countdownTimer.current = setTimeout(() => {
            countdown.current = countdown.current - 1;
            if(countdown.current === 0){
                setEnabledConfirmBtn(true);
            }
            setCountdownVal(countdown.current);
            tickCountdown();
          }, 1000);
      }
  }
  const initiateCountdown = () => {
      countdown.current = confirmationSeconds;
      setCountdownVal(confirmationSeconds);
      tickCountdown();
  }

  const submitSuccess = () =>{

          props.callbackSuccessConfirm(
             {serials:serials}
          );
  }
  const resetLastScanned = () => {

    setEnabledConfirmBtn(false);
    setLastScannedPossibleSerial(null);
  }

  const isFreelyMode = useCallback(() => {
      return currentActionScreenManager.currentActionConfig.identifyMode === "FREELY";
  },[currentActionScreenManager.currentActionConfig.identifyMode]);

  useEffect(() => {
    if(!didMount){
        if(!isFreelyMode()){

            let settings = currentActionScreenManager.currentActionConfig;
            if(settings.identifyMode === "BY_SCALE_PRODUCTIVITY"){
                setNumberProductsToIdentify(productionOrderSelectionStore.productionRecord.scaleProductivity);
            }else if(settings.identifyMode === "BY_PRODUCED_QUANTITY"){
                let persistedStateResult = productionOrderSelectionStore?.production?.persistedStateResult;
                if(persistedStateResult){
                    persistedStateResult = JSON.parse(persistedStateResult);
                    let producedQt = persistedStateResult["PRODUCE_PRODUCT_QUANTITY"].quantity;
                    setNumberProductsToIdentify(producedQt);
                }else{
                    throw "to implement...";
                }
               
            }
        
        }
        setDidMount(true);
    }
  },[didMount,isFreelyMode,productionOrderSelectionStore.production,currentActionScreenManager.currentActionConfig,productionOrderSelectionStore.productionRecord.scaleProductivity]);


  if(isLoading){
      return (<IonLoading
        isOpen={true}
        message={"Loading..."}
      />)
  }
  if(!didMount){
      return (<></>)
  }
  return (
    <>

    <div style={{position:'absolute'}}>
        <div style={{width:width + 'px'}}>
            {showBarcodeScannerPopup && (
                <ScanBarcodePopup
                freeScannerMode={true}
                title="Scanner"
                visible={true}
                barcodeScannerSettings={barcodeScannerSettings}
                onClosedCamera={() => {
                
                if (
                    MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
                    barcodeScannerSettings.useCamera &&
                    !barcodeScannerSettings.useExternalDevice
                ) {
                    setShowBarcodeScannerPopup(false);
                }
                }}
                
                onFinishedScan={(result: any) => {
                console.log("RESULT: ", result);
        
                
                if(result?.resultScan){
                    if(serials.includes(result.resultScan)){
                        feedbackService.notifyToast("This serial was already added", "error");
                        return;
                    }
                    
                }
               
                setShowBarcodeScannerPopup(false);
                let scannedPossibleSerial = result?.resultScan;
                if(scannedPossibleSerial){
                    setLastScannedPossibleSerial(scannedPossibleSerial);
                }

            
                initiateCountdown();

            
                }}
                onClosePopup={() => {
                setShowBarcodeScannerPopup(false);
                }}
            />
            )}
        </div>
    </div>
 
     <div>
     <div className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title="Serial Number Identification"
        />

        <div className={styles.content}>
            {!isFreelyMode() && (
                <>
                {numberProductsToIdentify === 1 ? (
                    <div className={styles.popupInfoQuestion}>
                        Identify the product
                    </div>
                ) : (
                    <div className={styles.popupInfoQuestion}>

                    Identify <b>({currentSerialIndex+1}/{numberProductsToIdentify})</b>
                    </div>
                )}
                </>

            )}

            {isFreelyMode() && (
                 <div className={styles.popupInfoQuestion}>

                 Total Identified: <b>{serials.length}</b>
                 </div>
            )}


            {lastScannedPossibleSerial && (
                    <div className={styles.lastPossSerialContainer}>
                        <div className={styles.centerAskConfirm}>
                            Please confirm the serial number is correct.
                        </div>
                        <div className={styles.centerAskConfirm}>
                            <b>Serial:</b> {lastScannedPossibleSerial}
                        </div>
                       
                    </div>
            )}


          <div className={`${styles.selectionOuter}`}></div>

        </div>
      </div>





      {!showAddMoreConfirm ? (
            <div className={styles.confirmationButtons}>
                    <div className={styles.confirmBtnContainer}>
                        <IonButton
                                disabled={showBarcodeScannerPopup}
                                className={`${styles.confBtn} ${styles.cancelBtn} `}
                                color={lastScannedPossibleSerial === null ? "primary" : "warning"}
                                onClick={() => {
                                    console.log("SCANNING:");
                                    setShowBarcodeScannerPopup(true);
                                    resetLastScanned();
                                }}
                            >
                            
                                {lastScannedPossibleSerial === null ? (<>Scan</>) : (<>Scan Again</>)}
                        </IonButton>

                    </div>
                    <div className={styles.confirmBtnContainer}>
                                <IonButton
                                        className={styles.confBtn}
                                        disabled={lastScannedPossibleSerial === null || !enabledConfirmBtn}
                                        onClick={() => {

                                        setIsLoading(true);

                                        let idOperationProduct = productionOrderSelectionStore.currentOperation.Id;
                                        let idProductionRecord = productionOrderSelectionStore.productionRecord.Id;
                                    
                                        let idProduct = productionOrderSelectionStore.product.Id;
                                        let productionOrder_productPPVVs = productionOrderSelectionStore.productionOrderProduct_ParameterizedVariableValues;
                                        let productPPVVs = [];
                                        if(productionOrder_productPPVVs && productionOrder_productPPVVs.length > 0){
                                            for(let i = 0; i < productionOrder_productPPVVs.length; i++){
                                                let idProductPv = productionOrder_productPPVVs[i].Id_Product_ParameterizedVariable;
                                                let value = productionOrder_productPPVVs[i].Value;
                                                productPPVVs.push({Id_Product_ParameterizedVariable:idProductPv,Value:value});
                                            }
                                        }



                                        serializedProductsController().validateSerial(lastScannedPossibleSerial,{
                                            "Id_OperationProduct":idOperationProduct,
                                            "Id_Product":idProduct,
                                            "productParameterizedVariableValues":productPPVVs,
                                            "Id_ProductionRecord":idProductionRecord
                                        }).then((any) => {
                                            setIsLoading(false);
                                            if(productionOrderSelectionStore.productionRecord.scaleProductivity <= 0){
                                                throw "unexpected scale productivity value";
                                            }
                                            
                                            if(lastScannedPossibleSerial){
                                                let curSerials:any = serials;
                                                curSerials.push(lastScannedPossibleSerial);
                                             
                                                if(isFreelyMode()){
                                                    setShowAddMoreConfirm(true);
                                                    //add
                                                    resetLastScanned();
                                                    setSerials(curSerials);
                                                    setCurrentSerialIndex(currentSerialIndex + 1);
                                                   
                                                }else{
                                                    //productionOrderSelectionStore.productionRecord.scaleProductivity
                                                    if(currentSerialIndex+1 >= numberProductsToIdentify){
                                                        
                                                        submitSuccess();

                                                    }else{
                                                        resetLastScanned();
                                                        setSerials(curSerials);
                                                        setCurrentSerialIndex(currentSerialIndex + 1);
                                                    }
                                                }
                                            }
                                        }).catch((res) => {
                                            setIsLoading(false);
                                            let issues = res?.response?.data?.response?.issues;
                                            if(issues){
                                                let errors = issues?.errors;
                                                if(errors){
                                                    if(errors.includes("error.serialNotFound")){
                                                        feedbackService.notifyToast("The serial number is not registered in our system", "error");
                                                        return;
                                                    }

                                                    if(errors.includes("error.productDoesntMatch")){
                                                        feedbackService.notifyToast("The scanned item is not the expected product", "error");
                                                        return;
                                                    }
                                                }
                                            }
                                            
                                            feedbackService.notifyToast("an unexpected error occurred, try again and contact us if the error persits", "error");
                                        });


                                }}>
                                <div className={styles.btnIcon}>
                                
                                </div>
                                {!countdownVal ? (<>Confirm</>) : (
                                    <>
                                        {countdownVal > 0 ? (<>Confirm ({countdownVal})</>) : (<>Confirm</>)}
                                    </>
                                )}
                        </IonButton>
                    </div>
            </div>
      ) : (
        <div className={styles.confirmationButtons}>
                <div className={styles.confirmBtnContainer}>
                    <IonButton

                            disabled={showBarcodeScannerPopup}
                            className={`${styles.confBtn} ${styles.cancelBtn} `}
                            color={"success"}
                            onClick={() => {
                                setShowAddMoreConfirm(false);
                                console.log("SCANNING:");
                                setShowBarcodeScannerPopup(true);
                                resetLastScanned();
                            }}
                        >

                            <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
                           Add More
                    </IonButton>

                </div>
                <div className={styles.confirmBtnContainer}>
                            <IonButton
                                    className={styles.confBtn}
                                    disabled={false}
                                    onClick={() => {
                                        submitSuccess();
                                    }}
                                >
                            <div className={styles.btnIcon}>
                            
                            </div>
                            Finished
                    </IonButton>
                </div>
        </div>
      )}


     </div>
  
    </>
  );
}
export default ActionScreenIdentifyProductSerialIdentify;
