import { useCallback, useEffect, useRef, useState } from "react";
import { numberSecondsToHHMMSS } from "../../../barrel/utils/TimeUtils";
import usePrevious from "../../../barrel/hooks/usePrevious";
import useInterval from "../../../barrel/hooks/useInterval";
import DatesConversionService from "../../../barrel/services/datesConversionService";

interface IProps{
    startFromSeconds:any;
    timerEnabled:any;
}
function TimeElapsedLabelV2(props: any) {
    const [startDateTime, setStartDateTime] = useState(new Date());
  
  const calculateElapsedTime = () => {
  let currentDate = new Date();

  let startDT = startDateTime ? startDateTime : new Date();
  let timeDiffMs: any = currentDate.getTime() - startDT.getTime();//startDateTime.getTime();
  let currentTimeElapsed = timeDiffMs / 1000;

  let baseSeconds = parseInt(props.startFromSeconds);
  return currentTimeElapsed+baseSeconds;
  }
  const [timeElapsed, setTimeElapsed] = useState(0);


  const previousStartDateTime = usePrevious(startDateTime);
  const [didMount, setDidMount] = useState(false);

  const timerCountRef = useRef(setTimeout(() => {}, 0));
  const [timerCount, setTimerCount] = useState(setTimeout(() => {}, 0));

  const previousTimerEnabled = usePrevious(props.timerEnabled);
  const [enabled, setEnabled] = useState(false);

  if (!props.timerEnabled) {
    clearTimeout(timerCountRef.current);
  }

  useInterval(
    () => {
    
      let currentTimeElapsed = calculateElapsedTime();
      console.log(" ELAPS: ", currentTimeElapsed);
      setTimeElapsed(currentTimeElapsed);
    }, // Delay in milliseconds or null to stop it
    enabled ? 1000 : null
  );

  useEffect(() => {
    if (
      previousStartDateTime !== undefined &&
      previousStartDateTime !== startDateTime
    ) {
      clearTimeout(timerCountRef.current);
      if (props.timerEnabled) {
        setEnabled(true);
      }
    }
  }, [previousStartDateTime, enabled, startDateTime, props.timerEnabled]);
  useEffect(() => {
    let timerCreate: any = null;
    if (!didMount) {
      if (props.timerEnabled) {
        setEnabled(true);
      }

      setDidMount(true);
    }

    () => {
      //unmount
      console.log("unmounting time elapsed");
      clearTimeout(timerCountRef.current);
    };
  }, [didMount, timeElapsed, timerCount, startDateTime, props.timerEnabled]);

  
  return <>{numberSecondsToHHMMSS(timeElapsed)}</>;
}
export default TimeElapsedLabelV2;
