import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";


interface IProductionHistoryControllerGetProduction{
    workerCode:any,
    includeMatchingFixedVarsRecords?:any,
    page?:number,
    size?:number
}

function productionHistoryController() {




  const getProductionHistory = (
    options:IProductionHistoryControllerGetProduction,
    cancelToken: any
  )  => {
    
    if(!options?.page){
        options.page = 0;
    }

    if(!options?.size){
        options.size = 50;
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production-history/fetch",
      options,cancelToken
    );
  };

  return {
    getProductionHistory,
  };
}

export default productionHistoryController;
