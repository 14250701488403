import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import styles from "./AppMembershipExpired.module.scss";
import { Button } from "@mui/material";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";

function AppMembershipExpired() {
  const {t} = useHxfTranslation();
    //the membership needs to be renewed
  return (
    <div className={styles.pageContainer}>
      <div className={styles.messageContainer}>
        <div className={styles.messageTitle}>{t('attention.required')}</div>
        <div className={styles.messageText}>
          {t('signin.or.contact.supervisor')}
        </div>
        <div className={styles.buttonRetry}>
          <Button
            onClick={() => {
              location.reload();
            }}
          >
            {t('reload')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AppMembershipExpired;
