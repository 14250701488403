function HxfArrayUtils(){

    function sortByKey(array:any, key:any, order:any = 'ASC') {
        return array.sort((a:any, b:any) => {
            if (!a.hasOwnProperty(key) || a[key] === null) {
                return 1; // Move a to the end
            }
            if (!b.hasOwnProperty(key) || b[key] === null) {
                return -1; // Move b to the end
            }
    
            if (order === 'ASC') {
                if (a[key] < b[key]) return -1;
                if (a[key] > b[key]) return 1;
                return 0;
            } else {
                if (a[key] < b[key]) return 1;
                if (a[key] > b[key]) return -1;
                return 0;
            }
        });
    }

    return {sortByKey};
}

export default HxfArrayUtils;