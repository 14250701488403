import { useEffect } from "react";
import createStore from "../../../../AppCustomStateManagement/createStore";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";

// Library interfaces
interface IAction {
    mapWorkerOperationCount: any;
    triggeredShowRevokeCancellation?:boolean;
    hasModifiedScaleProductivity?:boolean;
}

interface IActionScreen {
  type: any;
  payload?: any;
}

const initialState = {
  mapWorkerOperationCount: {},
  triggeredShowRevokeCancellation:false,
  hasModifiedScaleProductivity:false //if it is a new PR, the first time the types a quantity it is set to true or if it already had a prefilled qty and the user modified it, if left untouched it is set as 0
};

const reducer = (store: IAction, action: IActionScreen) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState, mapWorkerOperationCount: {} };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    case "TRIGGER_SHOW_REVOKE_CANCELLATION":
      store = { ...store,triggeredShowRevokeCancellation:true };
      return store;
    case "CLEAR_SHOW_REVOKE_CANCELLATION":
      store = { ...store,triggeredShowRevokeCancellation:false };
      return store;
    case "MODIFIED_SCALE_PRODUCTIVITY":
      store = { ...store, hasModifiedScaleProductivity:true };
      return store;
    case "RESET_MODIFIED_SCALE_PRODUCTIVITY":
      store = { ...store, hasModifiedScaleProductivity:false };
      return store;
    default:
      return store;
  }
};

export const [
  useProductionOperationStore,
  dispatchProductionOperationStore,
] = createStore(initialState, reducer);

export const productionOperationSessionStats = () => {
  const screenState = useProductionOperationStore();

  const myLocalStorage = useLocalStorageHandler();

  const increaseOperationCount = (operationId:any) =>{
    let idWorker:any = myLocalStorage.getWorkerIdentificationCode();
    //todo, set in map [worker][idoperation] = count++
    let newMap = {...screenState.mapWorkerOperationCount};
    if(newMap?.[idWorker]?.[operationId]){
        newMap[idWorker][operationId]++;
    }else{
        newMap[idWorker] = {};
        newMap[idWorker][operationId] = 1;
    }

    dispatchProductionOperationStore({ type: "SET_STORE" , payload:{...screenState, mapWorkerOperationCount: newMap}});
    
  }

  const getOperationCount = (operationId:any) => {
    let idWorker:any = myLocalStorage.getWorkerIdentificationCode();

    let newMap = {...screenState.mapWorkerOperationCount};
    if(newMap?.[idWorker]?.[operationId]){
       return newMap[idWorker][operationId];
    }

    return 0;
  }

  const modifiedScaleProductivity = () => {
    dispatchProductionOperationStore({ type: "MODIFIED_SCALE_PRODUCTIVITY" , payload:{}});
  }
  const resetModifiedScaleProductivity = () => {
    dispatchProductionOperationStore({ type: "RESET_MODIFIED_SCALE_PRODUCTIVITY" , payload:{}});
  }
  return { modifiedScaleProductivity,resetModifiedScaleProductivity,increaseOperationCount,getOperationCount };
};
