interface IProps{
  url:any;
}

const ViewFileVideo = (props:IProps) => {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <video
          style={{ maxWidth: '100%', maxHeight: '770px' }}
          controls
        >
          <source src={props.url} type="video/mp4" />
          <source src={props.url} type="video/webm" />
          <source src={props.url} type="video/ogg" />
          <source src={props.url} type="video/x-matroska" />
          <source src={props.url} type="video/x-msvideo" />
          <source src={props.url} type="video/3gpp" />
          <source src={props.url} type="video/quicktime" />
          <source src={props.url} type="video/x-flv" />
          This device does not support video. Contact us so we can address it.
        </video>
      </div>
    );
  };
  
  export default ViewFileVideo;