import createStore from "../../../../AppCustomStateManagement/createStore";

// Library interfaces

interface ICustomPopupsAction {
  type: any;
  payload?: any;
}

export interface IZebraPrintersPopupPayload {
  zebraPrintersPopup: boolean;
  zebraPopupStep:
    | "SETUP"
    | "SCAN"
    | "MANAGE"
    | "ADD_DEVICE"
    | "USE_SELECT"
    | "PRINT_CONFIRM";
}

const initialZebraPrintersPopupState: IZebraPrintersPopupPayload = {
  zebraPrintersPopup: false,
  zebraPopupStep: "SETUP",
};

const initialCustomIntegrationsPopupState = {
  customIntegrationsPopup: false,
};

const initialOnGoingProductionsPopupState = {
  onGoingProductionsPopup: false,
  onGoingProductionsPopupData: [],
  onGoingProductionsPopupSettings: {
     onCloseGoToPath: null,
     filters:{
      Id_ProductionOrder_Product:null,
      Id_OperationProduct:null,
     },
  },
};

const initialOnGoingMultiProductionsPopupState = {
  onGoingMultiProductionsPopup: false,
  onGoingMultiProductionsPopupData: [],
  onGoingMultiProductionsPopupSettings: { onCloseGoToPath: null },
};

const initialCollabCancelPopup = {
  collabCancelPopup: false
};

const initialAssociatePreviousProductionRecordPopupState = {
  associatePreviousProductionRecordPopup: false,
  associatePreviousProductionRecordPopupSettings: {
    allowNonePreviousProductionRecord: false,
    autoSelectLatestOperation: false,
    showButtonAutoCalculateLatestOperation: false,
    autoCalculatePreviousProductionRecord: false,
  },
  associatePreviousProductionRecordPopupData: [],
  associatePreviousProductionRecordInfoBarSettings: {},
  associatePreviousProductionRecordOnConfirmMultiPop: {},
  associatePreviousProductionRecordsCustomCallbacks: {},
};

const initialMultiPopProductionsStarterPopup = {
  multiPopProductionsStarterPopup: false,
  multiPopProductionsStarterPopupSettings: {},
  multiPopProductionsStarterPopupData: [],
  multiPopProductions_askPreviousConfirm: {},
};

const initialGenericOverlayPopup = {
  genericOverlayPopup:false

};
const initialStatesPopups = {
  ...initialGenericOverlayPopup,
  ...initialOnGoingProductionsPopupState,
  ...initialAssociatePreviousProductionRecordPopupState,
  ...initialMultiPopProductionsStarterPopup,
  ...initialZebraPrintersPopupState,
  ...initialCustomIntegrationsPopupState,
  ...initialCollabCancelPopup
};

const initialState = {
  ...initialStatesPopups,
};

const reducer = (store: any, action: ICustomPopupsAction) => {
  switch (action.type) {
    case "RESET_STORE":
      store = initialState;
      return store;
    case "SET_STORE":
      store = action.payload;
      return store;
    case "SHOW_COLLAB_CANCEL_POPUP":
      store = { ...store, collabCancelPopup: true, ...action.payload };
      return store;
    case "CLOSE_COLLAB_CANCEL_POPUP":
      store = { ...store, collabCancelPopup: false, ...action.payload };
      return store;
    case "SHOW_GENERIC_OVERLAY_POPUP":
      store = { ...store, genericOverlayPopup: true, ...action.payload };
      return store;
    case "CLOSE_GENERIC_OVERLAY_POPUP":
      store = { ...store, genericOverlayPopup: false, ...action.payload };
      return store;
    case "SHOW_ZEBRA_PRINTERS_POPUP":
      store = { ...store, zebraPrintersPopup: true, ...action.payload };
      return store;
    case "CLOSE_ZEBRA_PRINTERS_POPUP":
      store = { ...store, ...initialZebraPrintersPopupState };
      return store;
    case "SHOW_CUSTOM_INTEGRATIONS_POPUP":
        store = { ...store, customIntegrationsPopup: true, ...action.payload };
        return store;
    case "CLOSE_CUSTOM_INTEGRATIONS_POPUP":
      store = {...store, ...initialCustomIntegrationsPopupState };
      return store;
    case "SHOW_ON_GOING_PRODUCTIONS_POPUP":
      store = { ...store, onGoingProductionsPopup: true, ...action.payload };
      return store;
    case "CLOSE_ON_GOING_PRODUCTIONS_POPUP":
      store = { ...store, ...initialOnGoingProductionsPopupState };
      return store;
    case "SHOW_ON_GOING_MULTI_PRODUCTIONS_POPUP":
      store = {
        ...store,
        onGoingMultiProductionsPopup: true,
        ...action.payload,
      };
      return store;
    case "CLOSE_ON_GOING_MULTI_PRODUCTIONS_POPUP":
      store = { ...store, ...initialOnGoingMultiProductionsPopupState };
      return store;
    case "SHOW_ASK_PREV_PRODUCTIONRECORD_POPUP":
      store = {
        ...store,
        associatePreviousProductionRecordPopup: true,
        ...action.payload,
      };
      return store;
    case "CLOSE_ASK_PREV_PRODUCTIONRECORD_POPUP":
      store = {
        ...store,
        ...initialAssociatePreviousProductionRecordPopupState,
      };
      return store;
    case "SHOW_MULTI_POP_PRODUCTIONS_STARTER_POPUP":
      store = {
        ...store,
        multiPopProductionsStarterPopup: true,
        ...action.payload,
      };
      return store;
    case "CLOSE_MULTI_POP_PRODUCTIONS_STARTER_POPUP":
      store = { ...store, ...initialMultiPopProductionsStarterPopup };
      return store;
    case "SET_MULTI_POP_PRODUCTIONS_STARTER_POPUP_DATA":
      store = {
        ...store,
        multiPopProductionsStarterPopupData: [...action.payload],
      };
      return store;
    case "TRIGGER_MULTI_POP_CONFIRM_ASK_PREVIOUS_PRODUCTION_RECORD":
      store = {
        ...store,
        multiPopProductions_askPreviousConfirm: { ...action.payload },
      };
      return store;
    case "CLEAR_TRIGGER_MULTI_POP_CONFIRM_ASK_PREVIOUS_PRODUCTION_RECORD":
      store = { ...store, multiPopProductions_askPreviousConfirm: {} };
      return store;
    default:
      return store;
  }
};

export const [useCustomPopupsStore, dispatchCustomPopupsStore] = createStore(
  initialState,
  reducer
);
