import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import PrintSerialLabelProductPopup from "../../../../statePopups/printSerialLabelPopup/printSerialLabelProductPopup";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import useActionScreenTopBar from "../Components/hooks/useActionScreenTopBar";
import styles from "./ActionScreenPrintSerializationLabels.module.scss";
import { dispatchActionScreenPrintSerializationLabelsStore, useActionScreenPrintSerializationLabelsStore } from "./ActionScreenPrintSerializationLabelsStore";



interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}


export interface IPopupPrintSPSerializedProduct{
  Id:any;
  serial:any;
}
interface IPopupPrintSP {
  Id_Label:any,
  serializedProducts:IPopupPrintSPSerializedProduct[],
  labelCode:string,
  labelName:string
}
function ActionScreenPrintSerializationLabels(props: IProps) {
  const [indexkey, setIndexkey] = useState(0);
  const actTopBar = useActionScreenTopBar();

  const {t} = useHxfTranslation();

  const currentActionScreenManager = useActionScreenManagerStore();
  const feedbackService = useFeedbackService();
  
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  const actionScreenState = useActionScreenPrintSerializationLabelsStore();

  const [popupPrintSerialLabel, setPopupPrintSerialLabel] = useState<IPopupPrintSP>({Id_Label:null,serializedProducts:[], labelCode:"",labelName:""});

  // states to allow clearing when loading a default stock location config

  const closeCallback = () => {
    props.callbackOnClose(
    );
  };


  useEffect(() => {
    if (!didMount) {
      //mounting

      let idLabel = currentActionScreenManager.currentActionConfig?.Id_Label;
      let labelCode = currentActionScreenManager.currentActionConfig?.labelCode;
      let labelName = currentActionScreenManager.currentActionConfig?.labelName;

      let serializedProducts:IPopupPrintSPSerializedProduct[] = []; 
      //if not in store load from action config
      //if in store load from store
      if(actionScreenState?.serializedProducts !== null){
        serializedProducts = actionScreenState?.serializedProducts;
      }else{
        let curActSps = currentActionScreenManager.currentActionConfig?.serializedProducts;
        if(curActSps && curActSps.length > 0){
          for(let i = 0; i<curActSps.length; i++){
            serializedProducts.push({
              Id:curActSps[i].Id,
              serial:curActSps[i].serial
            })
          }
        }

        dispatchActionScreenPrintSerializationLabelsStore({
          type: "SET_SERIALIZED_PRODUCTS",
          payload: serializedProducts,
        });
      }

      
      if(idLabel && labelCode && labelName && serializedProducts && serializedProducts.length > 0){
        setPopupPrintSerialLabel({Id_Label:idLabel, labelCode:labelCode, labelName:labelName,serializedProducts:serializedProducts});
      }else{
        //error notify
        feedbackService.notifyToast(t("production.flow.error"),"error");
        //confirm success ?
      }
      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager,
    feedbackService,
    t
  ]);

  if (isLoading) {
    return <>

      <IonLoading
     
      isOpen={true}
      onDidDismiss={() => {

      }}
      message={"Please wait..."}
    /></>;
  }


 
  return (
    <>
    
      <div key={indexkey + "_forceRerender"} className={styles.container}>
    
      <PrintSerialLabelProductPopup title={t("serial.label.printing")}
          terminateButtonVisible={true}
         onClosePopup={() => {
           closeCallback();
           actTopBar.closeActionWindows();
         }}
         onSuccessConfirm={() => {}}
         Id_Label={popupPrintSerialLabel.Id_Label}
         serializedProducts={popupPrintSerialLabel.serializedProducts}
         labelCode={popupPrintSerialLabel.labelCode}
         labelName={popupPrintSerialLabel.labelName}
         onTerminateButton={() => {
           props.callbackSuccessConfirm();
         }}
        />
      
      </div>


    </>
  );
}
export default ActionScreenPrintSerializationLabels;
