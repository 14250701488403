import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";
import useFeedbackService from "../../../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import PrintLabelPopup from "../../../../../../statePopups/printLabelPopup/printLabelPopup";
import PrintSerialLabelProductPopup from "../../../../../../statePopups/printSerialLabelPopup/printSerialLabelProductPopup";
import { useActionScreenManagerStore } from "../../../../ActionScreensManager/ActionScreenManagerStore";

import useActionScreenTopBar from "../../../Components/hooks/useActionScreenTopBar";

import styles from "./ActionScreenAskPrintLabelsPrintOnMobile.module.scss";


export interface IActionScreenPrintLabelsPrintableLabel{
    Id_Label?:any;
    code?:any;
    name?:any;
  }
  
interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
    printableLabels:IActionScreenPrintLabelsPrintableLabel[];
    genericLabelData:any; //params to attach to render label
    currentActionConfig:any;
    countToPrint?:any;
}

function ActionScreenAskPrintLabelsPrintOnMobile(props: IProps) {

  const [indexkey, setIndexkey] = useState(0);
  const actTopBar = useActionScreenTopBar();
  const {t} = useHxfTranslation();
  const currentActionScreenManager = useActionScreenManagerStore();
  const feedbackService = useFeedbackService();
  
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);


  // states to allow clearing when loading a default stock location config

  const closeCallback = () => {
    props.callbackOnClose(
    );
  };


  useEffect(() => {
    if (!didMount) {
      //mounting
      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager,
    feedbackService
  ]);

  if (isLoading) {
    return <>

      <IonLoading
     
      isOpen={true}
      onDidDismiss={() => {

      }}
      message={"Please wait..."}
    /></>;
  }



 
  return (
    <>
    
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        
      <PrintLabelPopup title={t('label.printing')}
          terminateButtonVisible={true}
          countToPrint={props.countToPrint}
         onClosePopup={() => {
           closeCallback();
         }}
         onSuccessConfirm={() => {}}
         genericLabelData={props.genericLabelData}
         printableLabels={props.printableLabels}
         onTerminateButton={() => {
            closeCallback();
         }}
         isZebra={props?.currentActionConfig.zebraLabel === 1}
         zebraLabelMode={props.currentActionConfig.zebraLabelMode}
        />
      
      </div>


    </>
  );
}
export default ActionScreenAskPrintLabelsPrintOnMobile;
