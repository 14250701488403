import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import WarningIcon from '@mui/icons-material/Warning';
import { Backdrop, Checkbox, CircularProgress, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { shieldCheckmarkOutline, trashBinOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router";
import irregularityRecordsController from "../../../../../../barrel/controllers/irregularityRecordsController";
import productionRecordOperationActionTempStoresController from "../../../../../../barrel/controllers/productionRecordOperationActionTempStoresController";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import { useCancelToken } from "../../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
import usePrTempStore from "../../../../../../barrel/hooks/usePrTempStore";
import useWindowSize from "../../../../../../barrel/hooks/useWindowSize";
import RandomizationService from "../../../../../../barrel/services/randomizationService";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import { useManageProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import SimpleContentPopup from "../../../SimpleContentPopup/SimpleContentPopup";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import { dispatchProductionOperationStore } from "../../ProductionOperationStore";
import { useActionScreenAskUseMachineCurrentElementStore } from "../ActionScreenAskUseMachineCurrent/ActionScreenAskUseMachineCurrentElementStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenMatchFixedVariables.module.scss";
import { manageActionScreenMatchFixedVariables, useActionScreenMatchFixedVariablesStore } from "./ActionScreenMatchFixedVariablesStore";
import ActionScreenMatchFixedVariablesConfirmationButtons from "./ConfirmationButtons/ActionScreenMatchFixedVariablesConfirmationButtons";
import PopupAutoCalculateDistance from "./PopupAutoCalculateMFVFill/PopupAutoCalculateMFVFill";
import ASMFVChecklistPVInput from "./SubElements/ASMFVChecklistPVInput";
import ASMFVFixedVariableNumberInput from "./SubElements/ASMFVFixedVariableNumberInput";
interface IResult {
    resultMatchingFixedVariables: any;
}

const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
 const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:4
 }));
 const CustomTableCellVar = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:7
 }));

 

function ActionScreenMatchFixedVariables(props: any) {
 
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const preConfirm = useActionPreConfirm();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const poSelectionStoreManager = useManageProductionOrderSelectionStore();
  const prTempStoreHandler = usePrTempStore();
  const screenState = useActionScreenMatchFixedVariablesStore();
  const {t} = useHxfTranslation();
  const screenStateManage = manageActionScreenMatchFixedVariables();
  const [present] = useIonAlert();
  const currentActionScreenManager = useActionScreenManagerStore();
  const myLocalStorage = useLocalStorageHandler();
  const [result, setResult] = useState<IResult>({ resultMatchingFixedVariables: [] });
  const currentStoreActionAskUseMachineCurrent = useActionScreenAskUseMachineCurrentElementStore();
  const [askConfirmationPopup, setAskConfirmationPopup] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);
  const appState = useGlobalState();
  const [width, height] = useWindowSize();
  const [mapMatchFixedVarChecklistPvError, setMapMatchFixedVarChecklistPvError] = useState<any>({});
  const feedbackService = useFeedbackService();
  const [showPopupAutoCalculate,setShowPopupAutoCalculate] = useState<any>(null);
  const [tempPerfMFVSDataArray,setTempPerfMFVSDataArray] = useState<any>([]);
  const [mapRerenderMfvRowIndex, setMapRerenderMfvRowIndex] = useState<any>({});
  const [mapVisibleInputsRowIndex, setMapVisibleInputsRowIndex] = useState<any>({});
  const { cancelToken, isCancel } = useCancelToken({
    alias: "asmfv",
  });
  const history = useHistory();
  console.log("SELSTORE:", currentActionScreenManager);

  const [ranFirstVisibleInputs, setRanFirstVisibleInputs] = useState(false);
  // states to allow clearing when loading a default stock location config

  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);

  const [showEmptyListWarning, setShowEmptyListWarning] = useState(false);

  function forceRerender() {
    setIndexkey(indexkey + 1);
  }

  const getMachineIdToAssociateToMfvValue = () => {
    if(currentStoreActionAskUseMachineCurrent?.selectedMachineAndSegmentData?.Id_Machine){
      return currentStoreActionAskUseMachineCurrent?.selectedMachineAndSegmentData?.Id_Machine;
    }
    return null;
  }

  const getWorkerIdtoAssociateToMfvValue = () => {

    if(appState?.sessionState?.userSessionData?.Id_Worker){
      let idWorker = appState?.sessionState?.userSessionData?.Id_Worker;
      return idWorker;
    }
    return null;
  }

  // eslint-disable-next-line
  const getCurrentFixedVariablesToFill = () => {

    let matchingFixedVariables = currentActionScreenManager?.currentActionConfig?.matchingFixedVariables ? currentActionScreenManager?.currentActionConfig?.matchingFixedVariables : [];
    return matchingFixedVariables;
  
  };

  const getChecklistData = (idChecklist:any) => {
    if(currentActionScreenManager?.currentActionConfig?.mapChecklist_data?.[idChecklist]){
      let data = currentActionScreenManager?.currentActionConfig?.mapChecklist_data?.[idChecklist];
      return JSON.parse(JSON.stringify(data));
    }

    return null;
  }

  const finishMatchFixedVariables = (submissionData:any, ignoreConfirmation = false) => {
    if(ignoreConfirmation){
      props.callbackSuccessConfirm(submissionData);
      return;
    }
    present({
      cssClass: "asmfvConfirmationPopup",
      header: t("confirm"),
      message: t("confirm.correct.values"),
      buttons: [
        t("cancel"),
        {
          cssClass:"confirmBtn",
          text: t('yes'),
          handler: (d:any) => {
            props.callbackSuccessConfirm(submissionData);
          },
        },
      ],
      onDidDismiss: (e:any) => {},
    });
             
  }

  const confirmSaveForLater = async (options:any = {}) => {
    return new Promise((resolve:any, reject:any) => {
      let currentlyDefined = screenStateManage.getResultMatchingFixedVariables();

      let relevantToStoreInfo = [];
      if(currentlyDefined){
        for(let i = 0; i<currentlyDefined.length; i++){
          //only care to store data if the value is filled
          if(currentlyDefined[i]?.Value !== null && currentlyDefined[i]?.Value !== undefined){
        
            let idFixedVariable = currentlyDefined[i].Id_FixedVariable;
            let theValue = currentlyDefined[i]?.Value;
            let filledChecklistData = currentlyDefined[i]?.filledChecklistData;
  
            let relevantFilledChecklistData = [];
            if(filledChecklistData && filledChecklistData?.length > 0){
              for(let j = 0; j<filledChecklistData.length; j++){
  
                let idPv = filledChecklistData[j].Id_ParameterizedVariable;
                let value = filledChecklistData[j].Value;
                relevantFilledChecklistData.push({Id_ParameterizedVariable: idPv, Value: value});
              }
            }
  
            let tempStoreObj:any = {
              Id_FixedVariable:idFixedVariable,
              Value:theValue,
            };

            if(currentlyDefined[i]?.Id_Machine){
              tempStoreObj["Id_Machine"] = currentlyDefined[i]?.Id_Machine;
            }
            if(currentlyDefined[i]?.Id_Worker ){
              tempStoreObj["Id_Worker"] = currentlyDefined[i]?.Id_Worker;
            }

            if(relevantFilledChecklistData.length > 0){
              tempStoreObj["filledChecklistData"] = relevantFilledChecklistData;
            }
            relevantToStoreInfo.push(tempStoreObj);
          }
        }
      }
  
      let currActConfig = currentActionScreenManager?.currentActionConfig;
      let idOperationProductAction = currActConfig?.Id_OperationProduct_Action;
      let operationActionData = currentActionScreenManager?.operationActionData;
      let idOperationProduct = operationActionData?.Id_Operation;
      let idPr = productionOrderSelectionStore?.productionRecord?.Id;
      let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
   
      let hashEditPr = poSelectionStoreManager.getHashEditByCurrentSelectionOperation();
  
  
      setIsLoading(true);
      productionRecordOperationActionTempStoresController().save({
        workerCode:currentWorkerCode,
        Id_OperationProduct:idOperationProduct,
        Id_OperationProduct_Action:idOperationProductAction,
        Id_ProductionRecord:idPr,
        data:relevantToStoreInfo,
        hashEdit:hashEditPr
      }).then((res) => {
  
        setIsLoading(false);
  
        if(!res?.data?.prStore){
          throw "unexpected response";
        }
  
      
        let idPo = productionOrderSelectionStore.productionOrder.Id;
        let idPop = productionOrderSelectionStore?.productionRecord?.Id_ProductionOrder_Product;
       
        let idPrTempStore = res?.data?.prStore?.Id;
        let newHash = res?.data?.prStore?.hashEdit;
        poSelectionStoreManager.updateHashEditPrTempStoreById(idPrTempStore, newHash);
        
        if(!options?.noMinimize){
          feedbackService.notifyToast(t('operation.been.minimized'),"info");
          if(myLocalStorage.getSMWMode() === "SMW_MODE_MULTI"){
            history.push("/");
          }else{
            history.push("/feature/production-order/" + idPo + "/po-product/" + idPop);
          }
          
        }
        resolve();
  
      }).catch((res) => {
        setIsLoading(false);

        prTempStoreHandler.handleSaveErrors(res);
        reject();
        //err failed
      });
    });

  }

  const rerenderRowIndex = (index:any) => {
    let newmap = {...mapRerenderMfvRowIndex};
    newmap[index] = RandomizationService().generateRandomString(15);
    setMapRerenderMfvRowIndex(newmap);
  }
  const getRerenderKey = ( index:any) => {

    let keyVal = mapRerenderMfvRowIndex?.[index];
    if(!keyVal){
      return "rerender_mfvrow_" + index;
    }

    return "rerender_mfvrowkey_" + keyVal;
  }

  const confirmMFVS = () => {
    if(!preConfirm.check()){
        return;
    }
    let ALLOW_EMPTY_FILL = true; //if changing here, make a flag instead and set to true on all current using entities
    
    
    let onlyConsiderFilledVariables =  currentActionScreenManager?.currentActionConfig?.mismatchIrregularity?.ignoreEmptyVars === 1;

    let hasErrors = false;
    let isNotEntirelyFilled = false;
    let newMapMAtchFixedVarChecklistPvError = {...mapMatchFixedVarChecklistPvError};
    let resultMatchingFixedVars = screenStateManage.getResultMatchingFixedVariables();

    let irregularRowIndexDetected = null;
    let unfilledChecklistRowIndexDetected = null;

    for(let i = 0; i<resultMatchingFixedVars.length; i++){

      let shouldValidateThisVariableChecklist = onlyConsiderFilledVariables ? false : true;

      let dVal = resultMatchingFixedVars[i]?.Value;
      if(onlyConsiderFilledVariables && dVal !== null && dVal !== undefined){
        shouldValidateThisVariableChecklist = true;
      }
      if(resultMatchingFixedVars[i]?.varType === "numeric"){
        if(!onlyConsiderFilledVariables){
        
          //if variables not filled mark as irregular
          if(!resultMatchingFixedVars[i]?.Value || resultMatchingFixedVars[i]?.Value === 0){
            resultMatchingFixedVars[i]._error = true;
            hasErrors = true;
            isNotEntirelyFilled = true;
            if(!irregularRowIndexDetected){
              irregularRowIndexDetected = i;
            }
            
          }
        }

      }

      if(resultMatchingFixedVars[i]?.varType === "checkbox"){
        /*
        let varValue = resultMatchingFixedVars[i]?.Value;
        let expectedVal = resultMatchingFixedVars[i]?.expectedVal ? resultMatchingFixedVars[i]?.expectedVal : 0;
        */
      }


      if(resultMatchingFixedVars[i]?.Id_Checklist){
        let filledDataPvs = resultMatchingFixedVars[i]?.filledChecklistData;
                          
      
        if(!filledDataPvs){
          let checklistData = getChecklistData(resultMatchingFixedVars[i].Id_Checklist);
          let checklistDataPvsDefault:any = checklistData?.parameterizedVariables ? checklistData?.parameterizedVariables : [];
          filledDataPvs = checklistDataPvsDefault;

        }

        if(filledDataPvs && shouldValidateThisVariableChecklist){
          for(let j = 0; j<filledDataPvs.length; j++){

        
              if(!filledDataPvs[j]?.Value || filledDataPvs[j]?.Value === null){
                let updatedMap:any = {...newMapMAtchFixedVarChecklistPvError};
                if(!updatedMap?.[i]){
                  updatedMap[i] = {};
                }
                updatedMap[i][j] = true;
              
                newMapMAtchFixedVarChecklistPvError = updatedMap;
                hasErrors = true;
                if(!unfilledChecklistRowIndexDetected){
                  unfilledChecklistRowIndexDetected = i;
                }
               
              }
            

          }
        }

      }

    }


    setMapMatchFixedVarChecklistPvError(JSON.parse(JSON.stringify(newMapMAtchFixedVarChecklistPvError)));

    if(irregularRowIndexDetected !== null){
      console.log("Scroll to row: " + irregularRowIndexDetected);
      scrollToTheTableRowIndex(irregularRowIndexDetected);
    }else if(unfilledChecklistRowIndexDetected !== null){
      console.log("Scroll to row: " + unfilledChecklistRowIndexDetected);
      scrollToTheTableRowIndex(unfilledChecklistRowIndexDetected);
    }

    if(isNotEntirelyFilled && ALLOW_EMPTY_FILL){
      //allow when not entirely filled to mark as irregular
    }else{
      if(hasErrors){
        screenStateManage.setMachingFixedVariablesArr(JSON.parse(JSON.stringify(resultMatchingFixedVars)));
        return;
      } 
    }
  
    let willCreateIrregularity = false;

    if(isNotEntirelyFilled && ALLOW_EMPTY_FILL){
      setShowEmptyListWarning(true);
      willCreateIrregularity = true;
    }else{
      setShowEmptyListWarning(false);
    }
    let matchingFixedVariables = screenStateManage.getResultMatchingFixedVariables();


    for(let i = 0; i<matchingFixedVariables.length; i++){

      let _centerValue = matchingFixedVariables[i]._centerValue;
      let minValue:any = (parseFloat(_centerValue) - parseFloat(matchingFixedVariables[i].negativeOffset)).toFixed(2);
      let maxValue:any = (parseFloat(_centerValue) + parseFloat(matchingFixedVariables[i].positiveOffset)).toFixed(2);

      let filledValue = matchingFixedVariables[i]?.Value;
      if(matchingFixedVariables[i].varType === "numeric"){
        if(parseFloat(filledValue) < parseFloat(minValue) || parseFloat(filledValue) > parseFloat(maxValue)){
          willCreateIrregularity = true;
          if(!irregularRowIndexDetected){
            irregularRowIndexDetected = i;
          }
        }
      }


      if(matchingFixedVariables[i].varType === "checkbox"){
        let expectedVal = matchingFixedVariables[i]?.expectedVal;
        if(onlyConsiderFilledVariables){
          if(filledValue !== null && filledValue !== undefined && expectedVal !== filledValue){
            willCreateIrregularity = true;
            if(!irregularRowIndexDetected){
              irregularRowIndexDetected = i;
            }
          }
        }else{
          if(expectedVal !== filledValue){
            willCreateIrregularity = true;
            if(!irregularRowIndexDetected){
              irregularRowIndexDetected = i;
            }
          }
        }

      }

    }

    let mismatchIrregularity = currentActionScreenManager?.currentActionConfig?.mismatchIrregularity;

    let Id_OperationProduct_Action = currentActionScreenManager?.currentActionConfig?.Id_OperationProduct_Action;
    let res = screenStateManage.getResultMatchingFixedVariables();
  



    //override select this machine
    let hasBrowserStoredMachineSelectionData = currentStoreActionAskUseMachineCurrent?.selectedMachineAndSegmentData ? true : false;


    let createIrregularityObj = {
      create:willCreateIrregularity,
      Id_Irregularity:mismatchIrregularity ? mismatchIrregularity.Id_Irregularity : null,
      //override_startUnavOnSelMachines:[{Id_Machine:currentStoreActionAskUseMachineCurrent?.selectedMachineAndSegmentData?.Id_Machine}], //todo, currently not beeing considered when set, uses pr associated machine when this argument is not passed
    };
    let submissionData = {
      Id_OperationProduct_Action:Id_OperationProduct_Action,
      matchingFixedVariables:res,
      createIrregularity:{...createIrregularityObj}
    };

    if(irregularRowIndexDetected !== null){
      console.log("[2] Scroll to row: " + irregularRowIndexDetected);
      scrollToTheTableRowIndex(irregularRowIndexDetected);
    }else if(unfilledChecklistRowIndexDetected !== null){
      console.log("[2] Scroll to row: " + unfilledChecklistRowIndexDetected);
      scrollToTheTableRowIndex(unfilledChecklistRowIndexDetected);
    }

    if(willCreateIrregularity){
      setAskConfirmationPopup(submissionData);
    }else{
      if(productionOrderSelectionStore?.currentOperation?.shopfloorSetting_allowStoreTempData === 1){
        confirmSaveForLater({noMinimize:true}).then((res) => {
          finishMatchFixedVariables(submissionData);
        }).catch((res) => {

        });
      }else{

        finishMatchFixedVariables(submissionData);
      }
     


    }
  }

  const hasMFValueErrors = (options?:any) => {

    let doNotValidateEmptyValues = options?.doNotValidateEmptyValues;

    let checklistMfvs = getListMfvsRecheckedForExceedingErrors(screenStateManage.getResultMatchingFixedVariables());
    for(let index = 0; index<checklistMfvs.length; index++){
      if(doNotValidateEmptyValues){
        if(checklistMfvs[index]?.Value === undefined || checklistMfvs[index]?.Value === null){
          continue;
        }
      }
      if(checklistMfvs[index]?._outofrange){
        return checklistMfvs[index]?._outofrange;
      }
    }
    return false;
  }
  const isConfirmDisabled = () => {
    let resultMatchingFixedVars = screenStateManage.getResultMatchingFixedVariables();

    let hasAtLeastOneFilledVariable = false;
    if(resultMatchingFixedVars){
      for(let i = 0;i<resultMatchingFixedVars.length;i++){
        if(resultMatchingFixedVars[i]?.Value !== undefined && resultMatchingFixedVars[i]?.Value !== null){
          hasAtLeastOneFilledVariable = true;
          break;
        }
      }
    }

    return !hasAtLeastOneFilledVariable;
  }

  function getConfirmationButtons() {
    //

    return (
      <div className={`${styles.checklistsConfirmationContainer}`}>
        <div className={styles.lineBreaker}></div>
        <div>
            
            <ActionScreenMatchFixedVariablesConfirmationButtons
              showSaveForLater={productionOrderSelectionStore?.currentOperation?.shopfloorSetting_allowStoreTempData === 1}
              disabledConfirm={isConfirmDisabled() || askConfirmationPopup !== null}
              disabledShowSaveForLater={hasMFValueErrors({doNotValidateEmptyValues:true})} //allow continue later, even if some values are empty
              onClickSaveForLater={() => {
                  confirmSaveForLater();
              }}
              onClickConfirm={() => {
                  confirmMFVS();
              }}
            />

        </div>
      </div>
    );

    return <>not implemented</>;
  }


const getListMfvsRecheckedForExceedingErrors = useCallback( (listToCheck:any) => {
  let onlyConsiderFilledVariables =  currentActionScreenManager?.currentActionConfig?.mismatchIrregularity?.ignoreEmptyVars === 1;
  let currentList = listToCheck;

  for(let index = 0; index<currentList.length; index++){
    let value = currentList[index].Value;
    let varType = currentList[index].varType;

    if(varType === "checkbox"){

      let expectedVal = currentList[index].expectedVal ? currentList[index]?.expectedVal : 0 ;
  
      if(value !== expectedVal){
        currentList[index]._outofrange = true;
      }else{
        currentList[index]._outofrange = false;
      }
      
    }

    if(varType === "numeric"){
          let isOutOfRange = false;
          let exceedsNegativeRange = false;
          let exceedsPositiveRange = false;
          let shouldCheckIfExceed = !onlyConsiderFilledVariables || (onlyConsiderFilledVariables && value !== null);
          if(shouldCheckIfExceed){
            let centerVal:any = currentList[index]._centerValue;
            let negativeOffset:any = parseFloat(currentList[index].negativeOffset).toFixed(2);
            let positiveOffset = parseFloat(currentList[index].positiveOffset).toFixed(2);
           
            let centerLessOffset:any = parseFloat(centerVal) - parseFloat(negativeOffset);
            centerLessOffset = parseFloat(centerLessOffset).toFixed(2);

            let centerPlusOffset:any = parseFloat(centerVal) + parseFloat(positiveOffset);
            centerPlusOffset = parseFloat(centerPlusOffset).toFixed(2);
            exceedsNegativeRange = (parseFloat(value) < centerLessOffset);
            exceedsPositiveRange = (parseFloat(value) > centerPlusOffset);
          }
      
          isOutOfRange = (exceedsNegativeRange) || (exceedsPositiveRange);
          if(isOutOfRange){
              //todo show error
              if(exceedsNegativeRange){
                currentList[index]._outofrange_negative = true;
              }else{
                currentList[index]._outofrange_negative = false;
              }
              if(exceedsPositiveRange){
                currentList[index]._outofrange_positive = true;
              }else{
                currentList[index]._outofrange_positive = false;
              }
              currentList[index]._outofrange = true;
          
          }else{
            currentList[index]._outofrange_negative = false;
            currentList[index]._outofrange_positive = false;
            currentList[index]._outofrange = false;
          }
    }

      
  }
  return currentList;
},[currentActionScreenManager?.currentActionConfig?.mismatchIrregularity?.ignoreEmptyVars]);
const checkIfShow = (index:any) => {
  if(mapVisibleInputsRowIndex?.[index]){
    console.log("keeping visible");
    return true; //if already rendered keep visile
  }
    // Get the container and target row
    let container:any = document.querySelector('div[data-element-content-container="true"]');
    let targetRow:any = document.querySelector('tr[data-mfv-row-index="' + index + '"]');
    if(!container || !targetRow){
      return false;
    }
    let scrollPosition = container.scrollTop;
    let targetRowOffset = targetRow.offsetTop;
    let distance = Math.abs(scrollPosition - targetRowOffset);
    
    console.log("Distance:", distance);

   return distance < 500;
}

const updateVisibleInputs = (options?:any) => {
  let newMap:any = {};
  
  let firstRunRenderIdxMax = 7;
  
  for(let i = 0; i<screenStateManage.getResultMatchingFixedVariables().length; i++){
    let shouldShow = checkIfShow(i);
    if((shouldShow && !options?.firstRun) || ( options?.firstRun && i< firstRunRenderIdxMax)){
  
      newMap[i] = true;
    }
  }
  setMapVisibleInputsRowIndex(newMap);
}

  useEffect(() => {

    console.log("STS_:",screenState);
  },[screenState]);

  useEffect( () => {
    if(!ranFirstVisibleInputs && screenState.resultMatchingFixedVariables.length > 0){
      updateVisibleInputs({firstRun:true});
      setRanFirstVisibleInputs(true);
    }
  },[screenState.resultMatchingFixedVariables]);

  useEffect(() => {
    if (!didMount) {
      //mounting

      let matchingFixedVariables = getCurrentFixedVariablesToFill();
      let currentlyDefined = screenStateManage.getResultMatchingFixedVariables();

      let currActConfig = currentActionScreenManager?.currentActionConfig;
      let prTempStore = productionOrderSelectionStore?.prTempStore ?  productionOrderSelectionStore.prTempStore : [];
      let idOperationProductAction = currActConfig?.Id_OperationProduct_Action;
      let operationActionData = currentActionScreenManager?.operationActionData;
      let idOperationProduct = operationActionData?.Id_Operation;
      let actionTempStoredData = null;
      for(let i = 0;i<prTempStore.length; i++){
          let ptsId_OperationProduct = prTempStore[i]?.Id_OperationProduct;
          let ptsId_OperationProduct_Action = prTempStore[i]?.Id_OperationProduct_Action;
          if(ptsId_OperationProduct === idOperationProduct && ptsId_OperationProduct_Action === idOperationProductAction){
            actionTempStoredData = prTempStore[i];
            break;
          }
            
      }
      let newToAddToDefineResultingMatchingArr = [];
      
      for(let i = 0; i<matchingFixedVariables.length; i++){
          let found = false;
       
          for(let j = 0; j<currentlyDefined.length; j++){
              if(currentlyDefined[j]["Id_FixedVariable"] === matchingFixedVariables[i]["Id_FixedVariable"]){
                  found = true;
              }
          }

          if(!found){
        

              let fvsData = actionTempStoredData?.data ? actionTempStoredData.data : [];
              //load previously saved data (minimized on previous operations)
              for(let j = 0; j<fvsData.length; j++){
                if(fvsData[j].Id_FixedVariable === matchingFixedVariables[i]["Id_FixedVariable"]){
                 
                  matchingFixedVariables[i]["Value"] = fvsData[j]?.Value;
                  if(fvsData[j]?.filledChecklistData){
                    matchingFixedVariables[i].filledChecklistData = fvsData[j]?.filledChecklistData;
                  }
                  if(fvsData[j]?.Id_Worker){
                    matchingFixedVariables[i].Id_Worker = fvsData[j]?.Id_Worker;
                  }
                  if(fvsData[j]?.Id_Machine){
                    matchingFixedVariables[i].Id_Machine = fvsData[j]?.Id_Machine;
                  }
                  
                }

              }

              newToAddToDefineResultingMatchingArr.push(matchingFixedVariables[i]);
          }
      }

      for(let i = 0; i<newToAddToDefineResultingMatchingArr.length; i++){
        currentlyDefined.push(newToAddToDefineResultingMatchingArr[i]);
      }
   
      currentlyDefined =  getListMfvsRecheckedForExceedingErrors(currentlyDefined);

      let newArrDefined = currentlyDefined;//JSON.parse(JSON.stringify(currentlyDefined));
      screenStateManage.setMachingFixedVariablesArr(newArrDefined);
      
      //screenStateManage.getResultMatchingFixedVariables()
      setIsLoading(false);
      setDidMount(true);
    }
  }, [didMount,getListMfvsRecheckedForExceedingErrors,getCurrentFixedVariablesToFill,screenStateManage,productionOrderSelectionStore, currentActionScreenManager?.operationActionData,currentActionScreenManager?.currentActionConfig]);

  const setChecklistPvValue = (indexMatchedFixedVariable:any, idChecklist:any, indexPv:any, value:any) => {
    let currentList = screenStateManage.getResultMatchingFixedVariables();

    let checklistData = getChecklistData(idChecklist);
    let currentFilledChecklistData = currentList[indexMatchedFixedVariable]?.["filledChecklistData"] ? currentList[indexMatchedFixedVariable]?.["filledChecklistData"] : {
      filledChecklistData: {
      Id_Checklist:idChecklist,
      filledDataPvs:null
      }
    };

    let filledDataPvs = currentFilledChecklistData?.filledDataPvs;

    if(!filledDataPvs){
      let checklistDataPvsDefault:any = checklistData?.parameterizedVariables ? checklistData?.parameterizedVariables : [];

      filledDataPvs = checklistDataPvsDefault;
    }

    filledDataPvs[indexPv].Value = value;
    currentFilledChecklistData = filledDataPvs;

    currentList[indexMatchedFixedVariable].filledChecklistData = currentFilledChecklistData;

    screenStateManage.setMachingFixedVariablesArr(JSON.parse(JSON.stringify(currentList)));
  }

  const getChecklistPvValue:any = (
    indexMatchedFixedVariable: number,
    indexPv: number,
    varType: string,
    options:any = {}
  ) => {
    let currentList = screenStateManage.getResultMatchingFixedVariables();

    let filledChecklistData = currentList?.[indexMatchedFixedVariable]?.filledChecklistData;

    let rawValue = filledChecklistData?.[indexPv]?.Value ? filledChecklistData?.[indexPv]?.Value : null;
   
    if (rawValue === null || rawValue === undefined) {
      if (varType === "text") {
        return "";
      }
      if (varType === "numeric") {
        return "";
      }
      if (varType === "checkbox") {
        return false;
      }
      if (varType === "optionselect") {
        return null;
      }
      return null;
    } else {
      if (varType === "optionselect") {

        if(options?.returnValueOnly){
          return rawValue;
        }
        return { label: rawValue, value: rawValue };
      }
    }

    return rawValue;

  };

  const getFieldVarType = (
    objPv: any,
    indexMatchedFixedVariable: number,
    indexPv: number,
    idChecklist: any
  ) => {

    return (
      <ASMFVChecklistPVInput
       objPv={objPv} 
       error={mapMatchFixedVarChecklistPvError?.[indexMatchedFixedVariable]?.[indexPv] === true}
       indexPv={indexPv}
       indexMatchedFixedVariable={indexMatchedFixedVariable}
       mapMatchFixedVarChecklistPvError={mapMatchFixedVarChecklistPvError}
       defaultValue={getChecklistPvValue(indexMatchedFixedVariable, indexPv, objPv.varType)}
       onChange={(val:any) => {
       
          if(mapMatchFixedVarChecklistPvError?.[indexMatchedFixedVariable]?.[indexPv]){
            let updatedMap:any = {...mapMatchFixedVarChecklistPvError};
            updatedMap[indexMatchedFixedVariable][indexPv] = false;
            setMapMatchFixedVarChecklistPvError({...updatedMap});
          }
          if (val === null) {
            val = null;
          }
          setChecklistPvValue(indexMatchedFixedVariable, idChecklist, indexPv, val);
       }}
       />
    )

    /*
    let varName = objPv.name;
  
    if (objPv.varType === "text") {
      return (
        <div>
          <TextField
            disabled={false}
            error={mapMatchFixedVarChecklistPvError?.[indexMatchedFixedVariable]?.[indexPv] === true}
            onChange={(evt) => {

            }}
            type={"text"}
            value={getChecklistPvValue(indexMatchedFixedVariable, indexPv, objPv.varType)}
            fullWidth={true}
            label={varName}
            variant="outlined"
          />
        </div>
      );
    }

    if (objPv.varType === "numeric") {
      return (
        <div>

          <TextField
            disabled={false}
            error={mapMatchFixedVarChecklistPvError?.[indexMatchedFixedVariable]?.[indexPv] === true}
            onChange={(evt) => {
 

            }}
            type={"number"}
            value={getChecklistPvValue(indexMatchedFixedVariable, indexPv, objPv.varType)}
            fullWidth={true}
            label={varName}
            variant="outlined"
          />
        </div>
      );
    }

    if (objPv.varType === "checkbox") {
      return (
        <div className={styles.checkboxCustom}>
          <div className={styles.checkboxCustomInner}>
            <Checkbox
              color={"primary"}
              onChange={(evt) => {
  
              }}
              checked={getChecklistPvValue(indexMatchedFixedVariable, indexPv, objPv.varType)}
            />
          </div>
        </div>
      );
    }

    if (objPv.varType === "optionselect") {
      let optionsSelectableArr = objPv.varTypeOptionSelect_Opts.split(",");

      let defaultOptionsObj = [];
      for (let i = 0; i < optionsSelectableArr.length; i++) {
        defaultOptionsObj.push({
          label: optionsSelectableArr[i],
          value: optionsSelectableArr[i],
        });
      }

      return (
        <div className={styles.customChecklistComboSelectOptions}>
          <HxfSelectorField
            dataInputFieldAttributeValue={"checklistvar_mfvidx_" + indexMatchedFixedVariable + "_pvidx_" + indexPv}
            error={mapMatchFixedVarChecklistPvError?.[indexMatchedFixedVariable]?.[indexPv] === true}
            uniqueId={"hxfsel_" + indexMatchedFixedVariable + "_" + indexPv}
            disabled={false}
            onChange={(evt, val) => {
            
              
              if(mapMatchFixedVarChecklistPvError?.[indexMatchedFixedVariable]?.[indexPv]){
                let updatedMap:any = {...mapMatchFixedVarChecklistPvError};
                updatedMap[indexMatchedFixedVariable][indexPv] = false;
                setMapMatchFixedVarChecklistPvError({...updatedMap});
              }
              if (val === null) {
                val = null;
              }
              setChecklistPvValue(indexMatchedFixedVariable, idChecklist, indexPv, val?.value ? val.value : null);

            }}
            value={getChecklistPvValue(indexMatchedFixedVariable, indexPv, objPv.varType)}
            defaultItems={defaultOptionsObj}
            labelPlaceholder={t('value')}
          />
        </div>
      );
    }

    return <>not implemented</>;*/
  };

  const getPreviousRowValue = (previousRow:any, objPv:any) => {

    if(!previousRow){
      return null;
    }

    let filledChecklistData = previousRow?.filledChecklistData;
    if(filledChecklistData){
      for(let i = 0; i<filledChecklistData.length; i++){
        if(filledChecklistData[i].Id_ParameterizedVariable === objPv.Id_ParameterizedVariable){
          return filledChecklistData[i].Value;
        }
      }
    }

   
    return null;
  }

  const getChecklistInputs = (obj:any, index:any) => {

    if(!obj.Id_Checklist){
      return (<></>)
    }

    let checklistData = getChecklistData(obj.Id_Checklist);

    let checklistPvs = checklistData?.parameterizedVariables ? checklistData?.parameterizedVariables : [];
    if(!checklistPvs){
      return (<></>)
    }

    let previousRowInfo:any = null;
    if(index > 0){
      let currentList = screenStateManage.getResultMatchingFixedVariables();
                                                      
      previousRowInfo = currentList[index - 1];
    }

    return (<div className={styles.checklistsFieldsContainer}>
              <div>
                {checklistPvs?.map(
                    (objPv: any, indexPv: number) => (
                 
                       <div
                        key={"chklist_" + index + "_pv_" + indexPv}
                        className={styles.checklistField}
                      >
              
                            {getFieldVarType(objPv, index, indexPv, obj.Id_Checklist)}
                            {getPreviousRowValue(previousRowInfo, objPv) && (
                              <div style={{marginLeft:'5px'}}>
                                  <IonButton style={{height:50}}
                                  data-btn={"checklist-value-paster_mfvidx_" + index + "_pvidx_" + indexPv}
                                  disabled={getPreviousRowValue(previousRowInfo, objPv) === getChecklistPvValue(index, indexPv, objPv.varType, {returnValueOnly: true})}
                                  onClick={() => {
                                    let previousRowValue = getPreviousRowValue(previousRowInfo, objPv);
                                    if(mapMatchFixedVarChecklistPvError?.[index]?.[indexPv]){
                                      let updatedMap:any = {...mapMatchFixedVarChecklistPvError};
                                      updatedMap[index][indexPv] = false;
                                      setMapMatchFixedVarChecklistPvError({...updatedMap});
                                    }

                                    //copy value from previous row if exists
                                    setChecklistPvValue(index, obj.Id_Checklist, indexPv, previousRowValue);
                                  }}>
                                      <ContentCopyIcon/>
                                  </IonButton>
                              </div>
                            )}

   

                      </div>

                    )
                  )}
              </div>

              </div>
            )
  }

  const getRightMfvCheckColor = (index:any) => {
    let valor = screenStateManage.getResultMatchingFixedVariableValue(index);
    if(valor === null){
      return "black";
    }
    if(valor === 0){
      return "red";
    }

    return "green";
  }

  const getMFVValue = (index:any) => {
   let val = screenStateManage.getResultMatchingFixedVariableValue(index);
   if(val === undefined){
    return "";
   }

   return val;
  }

  const getMFVValueInput = (obj:any, index:any) => {

 
    if(obj.varType === "checkbox"){

      return (<>
      <div style={{color:'gray', marginBottom:40}} data-container="asmfvContainer">
        <div style={{display:'flex'}}>
          <div><span>{obj.name}:</span></div>
          <div>
          {isObjOutOfRange(index) ? (<ReportProblemIcon color="error" data-icon={"outOfRange_" + obj.code}/>) : (<><CheckIcon color="success" data-icon={"inRange_" + obj.code}/></>)} {/*<CheckIcon color="success"/>*/}
          </div>
        </div>

        <div style={{display:'flex',alignItems:'center',marginTop:10}}>
          <div style={{marginLeft:10,marginRight:10}}>
            <span>NOK</span>
            <Checkbox
                data-input-checkbox={"mfv_NOK_" + obj.code}
                color={"primary"}
                style={{
                  scale:'2',
              
                }}
                onChange={(evt:any) => {


                  if(screenStateManage.getResultMatchingFixedVariableValue(index) === 0){
                    updateResultMFVValue(index, null);
                    return;
                  }
                  updateResultMFVValue(index, 0);
                }}
                checked={screenStateManage.getResultMatchingFixedVariableValue(index) === 0 && screenStateManage.getResultMatchingFixedVariableValue(index) !== null}
              />
          </div>
          <div style={{marginLeft:10,marginRight:10}}>
          <span>OK</span>
            <Checkbox
                data-input-checkbox={"mfv_OK_" + obj.code}
                color={"primary"}
                style={{
                  scale:'2',
              
                }}
                onChange={(evt:any) => {
                  let newVal = evt.target.checked;
                  if(screenStateManage.getResultMatchingFixedVariableValue(index) === 1){
                    updateResultMFVValue(index, null);
                    return;
                  }
                  updateResultMFVValue(index, 1);
                }}
                checked={screenStateManage.getResultMatchingFixedVariableValue(index) === 1}
              />
          </div>

        </div>

      </div>
      </>)
    }

    if(obj.varType === "numeric"){
      return (
        <div style={{maxWidth:240}} data-container="asmfvContainer">
          <ASMFVFixedVariableNumberInput index={index} obj={obj}  startAdornment={getStartAdornmentIfDefined(obj)}
          endAdornment={(<InputAdornment position="end">{isObjOutOfRange(index) ? (<ReportProblemIcon color="error" data-icon={"outOfRange_" + obj.code}/>) : (<CheckIcon color="success" data-icon={"inRange_" + obj.code}/>)}</InputAdornment>)}
          customClickOverride={obj?.fillAutoCalc && obj?.fillAutoCalc !== null }
          onCustomClick={() => {
            if(obj?.fillAutoCalc && obj?.fillAutoCalc !== null ){
              setShowPopupAutoCalculate({index: index,value:obj.Value, fillAutoCalc:obj?.fillAutoCalc});
            }
          }}
          onFocus={() => {
         

          }}
          onBlur={(newVal:any) => {
            //updateResultMFVValue(index, newVal);
            //call rerender current row, next row and previous row
          }}
          error={obj?._error === true} 
          onChange={(evt:any) => {
            updateResultMFVValue(index, evt);
              
          }} defaultValue={getMFVValue(index)}/>

        {/*<HxfTextfieldNumber
          dataInputFieldAttributeValue={obj.code}
          fullWidth={true}
          startAdornment={getStartAdornmentIfDefined(obj)}
          endAdornment={(<InputAdornment position="end">{isObjOutOfRange(index) ? (<ReportProblemIcon color="error" data-icon={"outOfRange_" + obj.code}/>) : (<CheckIcon color="success" data-icon={"inRange_" + obj.code}/>)}</InputAdornment>)}
          customClickOverride={obj?.fillAutoCalc && obj?.fillAutoCalc !== null }
          onCustomClick={() => {
            if(obj?.fillAutoCalc && obj?.fillAutoCalc !== null ){
              setShowPopupAutoCalculate({index: index,value:obj.Value, fillAutoCalc:obj?.fillAutoCalc});
            }
          }}
          onFocus={() => {
         

          }}
          onBlur={() => {
  
          }}
          centerInputText={true} error={obj?._error === true} label={obj.name} placeholder={t('value')}
          onChange={(evt:any) => {
            
              updateResultMFVValue(index, evt);
          }} defaultValue={getMFVValue(index)}/> */}
            <div className={styles.rangeContainer}>
              <div className={`${obj?._outofrange_negative ? styles.exceedErrorLim : ""}`}>
                {getMinVal(obj)}
              </div>
              <div>{obj?._centerValue}</div>
              <div className={`${obj?._outofrange_positive ? styles.exceedErrorLim : ""}`}>
                {getMaxVal(obj)}
              </div>
            </div>
            {obj?._outofrange && (
              <div>
  
              </div>
            )}                       
      </div>
      )
    }
    
    return (
      <>undefined</>
    )
  }

  const getMinVal = (obj:any) => {
      let centerVal = obj._centerValue;
      let negativeOffset = obj.negativeOffset;
    
      return (parseFloat(centerVal) - parseFloat(negativeOffset)).toFixed(2);
  }

  const getMaxVal = (obj:any) => {
    let centerVal = obj._centerValue;
    let positiveOffset = obj.positiveOffset;
  
    return (parseFloat(centerVal) + parseFloat(positiveOffset)).toFixed(2);
}

const getConfirmPopup = () => {

  let title = t('irregularity.confirmation');
  let currentActionConfig = currentActionScreenManager?.currentActionConfig;
  if(!askConfirmationPopup){
    return (<></>)
  }

  return (
    (
      <>
        <SimpleContentPopup dataPopupContainerValue={"asmfv_popup"}>
          <div>
            <div  className={styles.container}>
              <ActionScreenTopBar
                closeOverride={() => {
              
                  setAskConfirmationPopup(null);
                }}
                title={t('irregularity.confirmation')}
              />

              <div className={styles.contentPopupConfirm}>
                <div>
                        <div className={styles.askConfirmIrregularityLabel}>
                          {currentActionConfig?.mismatchIrregularity?.name}
                        </div>
                        <div style={{textAlign:'center'}}>
                          {showEmptyListWarning  ? (<>
                          <div>
                            <div>
                              <Trans i18nKey="some.fields.empty">
                                    Some fields are <b>empty</b>!
                              </Trans>
                            </div>
                            <div>
                              <Trans i18nKey="irregular.consider">
                                The product will be considered <b>irregular</b>!
                              </Trans>
                            </div>
                          </div>
                          </>
                          ) : (
                            <>
                              <Trans i18nKey="product.seems.irregular">
                                  The product seems to be <b>irregular</b>.
                              </Trans>
                            </>
                          )}

             

                        </div>
                        
                        <div className={styles.irregularityConfirmIcon}>
                          <WarningIcon fontSize="large"/>
                        </div>
                        <div style={{textAlign:'center'}}>
                          {t('confirm.product.irregularity')}
                        </div>
                        {currentActionConfig?.mismatchIrregularity?.allowActionProductAcceptance === 1 && (
                          <div className={styles.confirmBtnIrr}>
                          <IonButton
                              style={{width:'100%'}}
                              color="success"
                              onClick={() => {
                                let currentSubmissionData = {...askConfirmationPopup};
                                currentSubmissionData.createIrregularity = {create:false};
                                finishMatchFixedVariables(currentSubmissionData, true);
                              }}
                            >
                              {t('acceptable.product')}
                                <div className={styles.iconContainer}>
                                  <IonIcon icon={shieldCheckmarkOutline} />
                                </div>
                            </IonButton>
                        </div> 
                        )}

                         <div>
                          <IonButton
                            data-btn="confirmIrregularity"
                            style={{width:'100%'}}
                              color="primary"
                              onClick={() => {
                                let currentSubmissionData = {...askConfirmationPopup};

                                // if onTriggerMinimize, execute irr and minimize

                                let shouldExecAndMinimizeInstead = currentActionScreenManager?.currentActionConfig?.mismatchIrregularity?.onTriggerMinimize;
                                let idIrregularity = currentActionScreenManager?.currentActionConfig?.mismatchIrregularity?.Id_Irregularity;
                                  
                                if(shouldExecAndMinimizeInstead){
                                  if(!idIrregularity){
                                    throw "unexpected, no valid id irregularity";
                                  }
                                  

                                  let idProductionRecord = productionOrderSelectionStore.productionRecord.Id;
                                
                                  let idPop = productionOrderSelectionStore.productionRecord.Id_ProductionOrder_Product;
                            
                                  let irregularity = {
                                    Id_Irregularity: idIrregularity,
                                    filledIrregularityData: {
                                      quantity: 1 //currently only triggers quantity of 1 irregularity to count on
                                    }
                                  }

                                  let hasBrowserStoredMachineSelectionData = currentStoreActionAskUseMachineCurrent?.selectedMachineAndSegmentData ? true : false;
                                  setIsLoading(true);
                                  irregularityRecordsController().executeIrregularityOccurrence({
                                    irregularity:irregularity,
                                    Id_ProductionRecord:idProductionRecord,
                                    Id_ProductionOrder_Product:idPop,
                                    override_startUnavOnSelMachines:[
                                      {
                                        Id_Machine:currentStoreActionAskUseMachineCurrent?.selectedMachineAndSegmentData?.Id_Machine
                                      }
                                    ]
                                  },cancelToken).then((res) => {
                                    if(!res?.data?.Id_IrregularityRecord){
                                      throw "unexpected id irr";
                                    }
                                   
                                    setIsLoading(false);
                                    confirmSaveForLater(); //pormecalu behaviour
                                    //if it should close the record simply remove confirmSaveForLater, and run finishMatchFixedVariables
                                    //finishMatchFixedVariables(currentSubmissionData, true);
                                  }).catch((res) => {
                                
                                    setIsLoading(false);
                                    if (isCancel(res)) {
                                      return;
                                    }

                                    //if canceled and is child show restore
                                    // dispatchProductionOperationStore({type:"TRIGGER_SHOW_REVOKE_CANCELLATION"});
                                    if(res?.response?.data?.response?.issues?.errors.includes("error.productionRecord.isCanceled")){
                                      setAskConfirmationPopup(null);
                                      dispatchProductionOperationStore({type:"TRIGGER_SHOW_REVOKE_CANCELLATION"});
                                      return;
                                    }
                                    if(res?.response?.data?.response?.issues?.errors.includes("error.alreadyClosed")){
                                   
                                      feedbackService.notifyToast(t('pr.already.closed'),"error");
                                      let idPo = productionOrderSelectionStore.productionOrder.Id;
                                      let idPop = productionOrderSelectionStore?.productionRecord?.Id_ProductionOrder_Product;
                                      history.push({
                                        pathname: "/page-redirector",
                                        state: { redirectTo: "/feature/production-order/" + idPo + "/po-product/" + idPop },
                                      });
                                      return;
                                    }
                                
                                    feedbackService.notifyToast(t('generic.critical.error'),"error");
                                  });
                                }else{
                                  finishMatchFixedVariables(currentSubmissionData, true);
                                }
                                
                              }}
                            >
                              {t('confirm.irregularity')}
                                <div className={styles.iconContainer}>
                                  <IonIcon icon={trashBinOutline} />
                                </div>
                            </IonButton>
                         </div> 
                        
                </div>

              </div>
            </div>
          
          </div>
        </SimpleContentPopup>
      </>
    )
  )
}

const getStartAdornmentIfDefined = (obj:any) => {
  
  if(obj.wsOnMatchFixedVarsActionShowSymbol === 1){

    if(obj.symbol === "DIAMETER"){

    return (<InputAdornment position="start"><div style={{fontWeight:600, fontSize:20}}>∅</div></InputAdornment>)
    }

    if(obj.symbol === "COUNTERSUNK"){
      return (<InputAdornment position="start"><div style={{fontWeight:600, fontSize:20}}>⌵</div></InputAdornment>)
    }

    if(obj.symbol === "ARROW_DOWN"){
      return (<InputAdornment position="start"><div style={{fontWeight:600, fontSize:20}}>↧</div></InputAdornment>)
    }

    if(obj.symbol === "SQUARE_CUP"){
      return (<InputAdornment position="start"><div style={{fontWeight:600, fontSize:20}}>⊔</div></InputAdornment>)
    }
    
  }

  return (<></>)
}


const updateResultMFVValue = (index:any, value:any) => {
  //let onlyConsiderFilledVariables =  currentActionScreenManager?.currentActionConfig?.mismatchIrregularity?.ignoreEmptyVars === 1;
  let defVal:any = value;
  let currentList = screenStateManage.getResultMatchingFixedVariables();
  let varType = currentList[index].varType;

  let idWorker = getWorkerIdtoAssociateToMfvValue();
  let idMachine = getMachineIdToAssociateToMfvValue();
  currentList[index].Id_Machine = idMachine;
  currentList[index].Id_Worker = idWorker;
  
  if(varType === "checkbox"){
    currentList[index].Value = value;
  }


  if(varType === "numeric"){


    /*
    //BAD IMPLEMENTATION "" !== 0, "" is "unfilled"
    if(value === ""){
      if(onlyConsiderFilledVariables){
        defVal = null;
      }else{
        defVal = 0;
      }
        
    }*/

    if(defVal !== null && defVal !== ""){
      if(parseFloat(defVal) < 0){
        defVal = 0;
      }
    }

    if(defVal === ""){
      delete currentList[index].Value
      currentList[index]._error = false;
      currentList[index]._outofrange_negative = false;
      currentList[index]._outofrange_positive = false;
     
    }else{
      currentList[index].Value = defVal;
      currentList[index]._error = false;
    }


  }


  currentList = getListMfvsRecheckedForExceedingErrors(currentList);

  screenStateManage.setMachingFixedVariablesArr(JSON.parse(JSON.stringify(currentList)));
}

  const isObjOutOfRange = (index:any) => {
    let currentList = screenStateManage.getResultMatchingFixedVariables();
    let varType = currentList[index].varType;

    if(varType === "checkbox"){
      let expectedVal = currentList[index].expectedVal ? currentList[index]?.expectedVal : 0 ;
      if(varType === "checkbox" && (expectedVal === 0 && (!currentList[index].Value || currentList[index].Value === 0))){
        return false;
      }
    }
 
    if(!currentList[index].Value){
      return true;
    }
    return currentList[index]?._outofrange;
  }
  
  const scrollToTheTableRowIndex = (idxRow:any) => {
    try{
      let targetRowIndex = idxRow;

      // Get the container and target row
      let container:any = document.querySelector('div[data-element-content-container="true"]');
      let targetRow:any = document.querySelector('tr[data-mfv-row-index="' + targetRowIndex + '"]');
  
      // Scroll the container to make the target row visible
      if (container && targetRow) {
        container.scrollTop = targetRow.offsetTop - container.offsetTop;
      }
    }catch(exception){
      console.log("Failed to auto scroll");
    }

  }

  const hasAtLeastOneChecklist = () => {

    let currentmatchFixedVars = screenStateManage.getResultMatchingFixedVariables();
    for(let i = 0; i<currentmatchFixedVars.length; i++){

      let obj = currentmatchFixedVars[i];
      if(obj.Id_Checklist){
        return true;
      }
    }
    //
    return false;
  }
  useEffect(() => {
    return () => {
      //unmounting
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (<>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
    onClick={() => {

    }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
    </>);
  }






  if(isLoading){
    return (<>loading...</>)
  }

  return (
    <>
      {getConfirmPopup()}
      {showPopupAutoCalculate && (
        <PopupAutoCalculateDistance autoCalculationMode={showPopupAutoCalculate.fillAutoCalc} defaultValue={showPopupAutoCalculate.value} onClose={() => {
          setShowPopupAutoCalculate(null);
        }} onConfirm={(autofillnumber:any) => {
          let indexToChange = showPopupAutoCalculate.index;
          updateResultMFVValue(indexToChange, autofillnumber);
          rerenderRowIndex(indexToChange);
          setShowPopupAutoCalculate(null);
        }}
        />
      )}
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('fixed.variables')}
        />

        <div className={`${styles.contentWidthSizes} ${styles.content}`} data-element-content-container={true} onScroll={() => {
           
            updateVisibleInputs();
        }}>
            <div style={{width:'100%'}}>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <CustomTableHead>
                            <TableRow>
                                <CustomTableCell><b>{t('variable')}</b></CustomTableCell>
                                {width >= 540 && hasAtLeastOneChecklist() && (
                                  <CustomTableCell align="left" className={styles.biggerScreensShow}><b>{t('information')}</b></CustomTableCell>
                                )}
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
                          {screenStateManage.getResultMatchingFixedVariables().length === 0 && (
                            <div className={styles.noVarConf}>{t('no.variable.has.been.configured')}</div>
                          )}
                          {screenStateManage.getResultMatchingFixedVariables().map((obj:any,index:any) => (
                              
                              <TableRow key={getRerenderKey(index)}  data-mfv-row-index={index} style={!mapVisibleInputsRowIndex?.[index] ? {height:100} : {}}>
                                {mapVisibleInputsRowIndex?.[index] && (
                                  <>
                                  <CustomTableCellVar align="left"> 
                                    <div className={styles.matchFixVarContainer} key={"mfv_fill_" + index}>
                                      {getMFVValueInput(obj,index)}
                                    </div>
                                    {width < 540 && hasAtLeastOneChecklist() && (
                                      <div>
                                          {getChecklistInputs(obj,index)}
                                      </div>
                                    )}         
                                  </CustomTableCellVar>

                                  {width >= 540 && hasAtLeastOneChecklist() && (
                                      <CustomTableCellVar>
                                        <div>
                                            {getChecklistInputs(obj,index)}
                                        </div>
                                      </CustomTableCellVar>
                                  )}   </>
                                )}

                                
                              </TableRow>
                              
                          ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
   

            
        </div>
      </div>

      {getConfirmationButtons()}
    </>
  );
}
export default ActionScreenMatchFixedVariables;
