import { useGlobalState } from "../GlobalCustomStateManagement/GlobalStateProvider";
import AppUnderMaintenance from "../pages/ErrorPages/AppUnderMaintenance/AppUnderMaintenance";
import CustomErrorNotice from "../pages/CustomElements/CustomErrorNotice/CustomErrorNotice";
import useHxfTranslation from "../barrel/hooks/useHxfTranslation";
function HxfContentRenderer(props: any) {
  const { appState } = useGlobalState();

  const {t} = useHxfTranslation();

  if (appState.appStateData.maintenance) {
    return <AppUnderMaintenance />;
  }

  if (!appState.isConnectionAvailable()) {
    return (
      <CustomErrorNotice
        title={t('network.access.required')}
        description={t('no.internet')}
        secondDescription={t('restore.internet.connection')}
      />
    );
  }

  return <>{props.children}</>;
}

export default HxfContentRenderer;
