import CustomErrorNotice from "../CustomErrorNotice/CustomErrorNotice";
function ErrorNoticeRequiresNetworkLoadObject() {
  return (
    <CustomErrorNotice
      title="Connection Required"
      description="This feature is requiring an internet connection to continue."
      secondDescription="You may have been in offline mode for too long or the app needs to fetch an important update from our servers to guarantee the data integrity."
    />
  );
}

export default ErrorNoticeRequiresNetworkLoadObject;
