import styles from "./DowntimesFooterUI.module.scss";
import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import { useDowntimesFooterUIStore } from "./downtimesFooterUIStore";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import unavailabilityOccurrencesController from "../../../barrel/controllers/unavailabilityOccurrencesController";
import { useEffect, useRef, useState } from "react";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import { useHistory } from "react-router";
import useOngoingUnavailabilityOccurrenceHook from "../../FeaturePages/OnGoingUnavailabilityOccurrence/store/onGoingUnavailabilityOccurrenceStore";
import UnavailabilityActionConsumeMaterial, { getUnavailabilityActionConsumeMaterialCode } from "./UnavailabilityActions/UnavailabilityActionConsumeMaterial/UnavailabilityActionConsumeMaterial";
import UnavailabilityActionWindow from "./UnavailabilityActionWindow/UnavailabilityActionWindow";
import UnavailabilityActionAdjustMachineToProduct, { getUnavailabilityActionAdjustMachineToProductCode } from "./UnavailabilityActions/UnavailabilityActionAdjustMachineToProduct/UnavailabilityActionAdjustMachineToProduct";
import UnavailabilityActionAskIfProductiveStarterWorker, { getUnavailabilityActionAskIfProductiveStarterWorker } from "./UnavailabilityActions/UnavailabilityActionAskIfProductiveStarterWorker/UnavailabilityActionAskIfProductiveStarterWorker";
import HxfOverlayLoading from "../HxfOverlayLoading/HxfOverlayLoading";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import usePrevious from "../../../barrel/hooks/usePrevious";
import UnavailabilityActionAskComment, { getUnavailabilityActionAskCommentCode } from "./UnavailabilityActions/UnavailabilityActionAskComment/UnavailabilityActionAskComment";
import UnavailabilityActionFillChecklist, { getUnavailabilityActionFillChecklist } from "./UnavailabilityActions/UnavailabilityActionAskFillCheckbox/UnavailabilityActionFillChecklist";
import useMachineManagementShortcutReceiver from "../../../barrel/hooks/useMachineManagementShortcutReceiver/useMachineManagementShortcutReceiver";
function DowntimesFooterUI(){
    const downtimesFooterUiStore = useDowntimesFooterUIStore();
    const {occurrenceStore,getCurrentUnavailabilityAction,endUnavailabilityAction,restartActionIndex} = useOngoingUnavailabilityOccurrenceHook();
    const {t} = useHxfTranslation();
    const myLocalStorage = useLocalStorageHandler();
    const [btnEnabled, setBtnEnabled] = useState(true);
    const feedbackService = useFeedbackService();
    const history = useHistory();
    const [currentAction, setCurrentAction] = useState<any>(null);

    const [isHandlingResult, setIsHandlingResult] = useState(false);
    const previousIsHandling = usePrevious(isHandlingResult);
    const [showHandlingOverlayloading, setShowHandlingOverlayloading] = useState(false);
    const machineManagementShortcutReceiver = useMachineManagementShortcutReceiver();
    const handleResult = (result:any) => {
        setBtnEnabled(false);

        endUnavailabilityAction(result).then((objectReturn:any) => {
         

            if(objectReturn?.nextUnavailabilityAction === "FINISHED"){
                finishUnavailabilityOccurrence(objectReturn.newOccurrenceStore);
                return;
            }else{
                setCurrentAction(objectReturn.nextUnavailabilityAction);
            }
            setBtnEnabled(true);
        }).catch((res:any) => {
       
            setBtnEnabled(true);
            errorReset();
        });
    }
    const finishUnavailabilityOccurrence = (newOccurrenceStore:any) => {
            let isAWorkerDowntime = newOccurrenceStore?.unavailabilityOccurrenceData?.machines.length === 0;
            let unavailabilityOccurrenceData = newOccurrenceStore?.unavailabilityOccurrenceData;
            let idUnavailabilityOccurrence = unavailabilityOccurrenceData?.Id;//downtimesFooterUiStore?.Id_UnavailabilityOccurrence;
            let workerCode = myLocalStorage.getWorkerIdentificationCode();
            setIsHandlingResult(true);
            unavailabilityOccurrencesController().finishUnavailabilityOccurrence({workerCode:workerCode,submittingDataList:newOccurrenceStore.submittingDataList},idUnavailabilityOccurrence).then((res) => {
                setIsHandlingResult(false);
                if(res?.data?.code === 200){
                    feedbackService.notifyToast(t('finished.downtime.successfully'),"success");
                    let shortcutRecData = machineManagementShortcutReceiver.shortcutReceivedData;
                    if(shortcutRecData?.unavailabilityReturnTo){
                        history.push(shortcutRecData?.unavailabilityReturnTo);
                       
                    }else if(isAWorkerDowntime){
                        history.push("/");
                    }else{
                        history.push("/feature/machines");
                    }
                    return;
                }else{
                    throw "unexpected result";
                }
                //setBtnEnabled(true);
            }).catch((res) => {
                setIsHandlingResult(false);
                if(res?.response?.data?.response?.issues?.errors.includes("error.alreadyFinished")){
                    feedbackService.notifyToast(t('unav.already.finished'),"error");
                    if(isAWorkerDowntime){
                        history.push("/");
                    }else{
                        history.push("/feature/machines");
                    }
                
                    return;
                }
                feedbackService.notifyToast(t('failed.error.generic'),"error",{duration:2500});
                setBtnEnabled(true);
            });
    }
    const cancelAction = () => {
        setBtnEnabled(true);
        setCurrentAction(null);
        restartActionIndex();
    }

      const errorReset = () => {
        feedbackService.notifyToast(t('generic.critical.error'),"error");
        cancelAction();
      }


      useEffect(() => {
        if(!previousIsHandling && isHandlingResult && !showHandlingOverlayloading){
            setShowHandlingOverlayloading(true);
        }
      },[isHandlingResult,showHandlingOverlayloading,previousIsHandling]);

      useEffect(() => {
        console.log("current action:",currentAction);
      },[currentAction]);

      if(!downtimesFooterUiStore.showFooter){
        return (<></>)
      }
    

    
      return (<>

            {isHandlingResult && (
                <>
                    <>{showHandlingOverlayloading && (<HxfOverlayLoading onClick={() => {
                        setShowHandlingOverlayloading(false);
                    }}/>)}
                    </>
                </>
            )}

            {currentAction?.code === getUnavailabilityActionFillChecklist() && (
                    <UnavailabilityActionWindow title={t('checklist.fill')}  onCancel={() => {
                        cancelAction();
                    }}>
                        <UnavailabilityActionFillChecklist onConfirm={(result:any)  => {
                            handleResult(result);
                        }}
                        />
                    </UnavailabilityActionWindow>
            )}
            {currentAction?.code === getUnavailabilityActionAskCommentCode() && (
                    <UnavailabilityActionWindow title={t('comment.obs')}  onCancel={() => {
                        cancelAction();
                    }}>
                        <UnavailabilityActionAskComment onConfirm={(result:any)  => {
                            handleResult(result);
                        }}
                        />
                    </UnavailabilityActionWindow>
            )}
            {currentAction?.code === getUnavailabilityActionAdjustMachineToProductCode() && (
                <UnavailabilityActionWindow title={t('adjust.machine.to.product')}  onCancel={() => {
                    cancelAction();
                }}>
                    <UnavailabilityActionAdjustMachineToProduct onConfirm={(result:any) => {
                        

                        handleResult(result);
                    }} />
                </UnavailabilityActionWindow>
            )}
            {currentAction?.code === getUnavailabilityActionConsumeMaterialCode() && (
                <UnavailabilityActionWindow title={t('consume.material')}  onCancel={() => {
                    cancelAction();
                }}>
                    <UnavailabilityActionConsumeMaterial onConfirm={(result:any) => {
                        handleResult(result);
                    }}/>
                </UnavailabilityActionWindow>
            )}
            {currentAction?.code === getUnavailabilityActionAskIfProductiveStarterWorker() && (
                <UnavailabilityActionWindow title={t('confirm.if.starter.worker.productive.work')}  onCancel={() => {
                    cancelAction();
                }}>
                    <UnavailabilityActionAskIfProductiveStarterWorker onConfirm={(result:any) => {
                        handleResult(result);
                    }}/>
                </UnavailabilityActionWindow>
            )}


            {isHandlingResult ? (
                                <div style={{position:'relative',top:-20}}>
                                <LoadingSpinnerImg/>
                            </div>
            ) : (
                <div className={styles.confirmationContainer}>
                    <div className={styles.confirmButtonContainer}>
                        <IonButton
                        id="hxfProductionOperationConfirmationBtn"
                        data-btn="finish-unavailability"
                        className={styles.confirmButton}
                        color="danger"
                        disabled={!btnEnabled}
                        onClick={() => {


                        
                            setBtnEnabled(false);

                            let currentUnavAction = getCurrentUnavailabilityAction();
                            if(currentUnavAction === "NO_ACTIONS"){
                                handleResult("NO_ACTIONS");
                            }else{
                                setCurrentAction(currentUnavAction);
                            }
                    
            
                
                        }}
                        >
                            {t('downtime.end')}
                        </IonButton>
                    </div>
                </div>
            )}
        </>
      );
    

    
}

export default DowntimesFooterUI;