
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function useHxfTranslation(){
    const { t, i18n }:any = useTranslation();


    return {t};    
}

export  default useHxfTranslation;