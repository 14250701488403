import styles from "./WorkerIdentifyPage.module.scss";
import HxfDigitKeyboard from "../CustomElements/HxfDigitKeyboard/HxfDigitKeyboard";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import { IonButton } from "@ionic/react";
import { useIonAlert } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { logOutOutline } from "ionicons/icons";
import WorkerDao from "../../barrel/dataAccessObjects/ModelObjects/Objects/WorkerDao";
import { useState } from "react";
import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import HxfContentRenderer from "../../components/HxfContentRenderer";
import SystemSoundService from "../../barrel/services/systemSoundService";
import useFeedbackService from "../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../barrel/hooks/useHxfTranslation";
import HxfIonLoading from "../CustomElements/HxfIonLoading/HxfIonLoading";
import workerController from "../../barrel/controllers/workerController";
const WorkerIdentifyPage = (props: any) => {
  const localStorage = useLocalStorageHandler();
  const { sessionState } = useGlobalState();
  const [present] = useIonAlert();
  const workerDao = WorkerDao();
  const feedbackService = useFeedbackService();
  const {t} = useHxfTranslation();
  const [errorIdentifyCode, setErrorIdentifyCode] = useState(false);
  const [okButtonEnabled, setOkButtonEnabled] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  console.log(props);
  const confirmWorkerIdentification = (code: any) => {
   
    
    SystemSoundService().identifyOkButton();
    console.log("code:: ", code);
    setOkButtonEnabled(false);
    if (code == "") {
      return;
    }
 
    setIsLoadingAction(true);
    workerController()
      .getByCode(code)
      .then((res) => {
   
        setIsLoadingAction(false);
        console.log("RESULTADO: ", res);
        //Todo, save res.response.data.Id (workerid) , res.response.data.name (worker name), verificar primeiro se res.response.data.enabled é true
        let responseObj = res.data.response.data;
        let idWorker = responseObj.Id;
        let workerName = responseObj.name;
        let isEnabled = responseObj.enabled;
        if (!isEnabled) {
          //shows outline error because worker is not enabled
          setErrorIdentifyCode(true);
          setOkButtonEnabled(true);
        } else {
          sessionState.setWorker(idWorker, workerName, code);
          console.log("done worker set succesfully.");

          let targetRedirect =
            props?.location?.state?.pageToAccessAfterIdentification;
          let currentPathname = props.location.pathname;

          if (
            targetRedirect === currentPathname ||
            targetRedirect === undefined
          ) {
            targetRedirect = "/home";
          }

          props.history.push({ pathname: targetRedirect });
        }
      })
      .catch((ex) => {
        setIsLoadingAction(false);
        console.log("ex:", ex);
        setOkButtonEnabled(true);
        if(ex?.response?.status === 403){
          feedbackService.notifyToast(t('insufficient.perms'),"danger");
        }else if (ex?.error?.exceptionObj?.request?.status === 404) {
          //shows outline error because worker wasnt found
          console.log("Error here");
          setErrorIdentifyCode(true);
        } else {
          feedbackService.notifyToast(
            t("generic.critical.error"),
            "danger"
          );

        }
      });
    return;
  };
  return (
    <HxfContentRenderer>
      <div>
        {props?.location?.state?.options?.hideToolbar === true ? (
          <>
            <div style={{ marginTop: "7vh" }}></div>
          </>
        ) : (
          <div className={styles.toolBar}>
            <div></div>
            <div className={styles.accountDivContainer}>
              <div className={styles.entityDiv}>
                {sessionState.userSessionData.entityName}
              </div>
              <div>
                <b>Account ID:</b>
                {sessionState.userSessionData.customAccountId}
              </div>
            </div>
            <div>
              <div style={{ marginRight: "20px" }}>
                <IonButton
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  {t('home')}
                </IonButton>
              </div>


            </div>
          </div>
        )}
        {isLoadingAction && (
          <HxfIonLoading isOpen={true} message={t('loading') + "..."} renderDelay={1000}/>
        )}
        <div className={styles.identifyInfo}>
          {t("worker.identify.card")}
        </div>
        <HxfDigitKeyboard
          enableScanners={true}
          showErrorInput={errorIdentifyCode}
          confirmationButtonEnabled={okButtonEnabled}
          uomLabel="ID"
          onConfirmation={(val: any) => {
            confirmWorkerIdentification(val);
          }}
        />
      </div>
    </HxfContentRenderer>
  );
};

export default WorkerIdentifyPage;
