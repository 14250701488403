import productionController from "../controllers/productionController";
function useProductionHook() {

  const selectOperationStart = (
    options = {},
    workerCode,
    popId,
    idOperation,
    idPreviousProduction,
    preActionsData,
    cancelToken
  ) => {
    return productionController().startProduction(options,workerCode,
      popId,
      idOperation,
      idPreviousProduction,
      preActionsData,
      cancelToken);
    
  };

  return { selectOperationStart };
}

export default useProductionHook;
