import { IonIcon } from "@ionic/react";
import { hardwareChipOutline, qrCodeOutline } from "ionicons/icons";
import styles from "./SARScanner.module.scss";
import Ripples from "react-ripples";
import { fileTrayOutline } from "ionicons/icons";

import { useState } from "react";

import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import ScanBarcodePopup from "../../../ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import MachineIdentifierService from "../../../../../barrel/services/machineIdentifierService";
import SarScannerService from "../../../../../barrel/services/sarScannerService";

interface IProps{
    onReturnSelectedShippingOrderCode:any;
}

//shippings and receivings scanner
function SARScanner(props: IProps) {
  const {t} = useHxfTranslation();
  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);
  const feedbackService = useFeedbackService(); 

  const isCompatible = true;//MobileWrapperCommunicationService().isDeviceMobileWrapper();


  const getElement = () => {
    let innerClickableElement = (
      <div
        className={` ${styles.descIconContainer}`}
      >
        <div className={styles.addIconContainer}>
            <IonIcon icon={qrCodeOutline} size="large" />
        </div>
      </div>
    );


    return (
        <>
          {showBarcodeScannerPopup && (
                <ScanBarcodePopup
                freeScannerMode={true}
                title="Scanner"
                visible={true}
                barcodeScannerSettings={{
                    useCamera:true,
                    useExternalDevice:true,
                    allowedScanFormats:[{type:'qrcode',pattern:'[]'}], //SarScannerService().getShippingOrderDefaultFormatQrcode()}
                    allowAllFormats:false,
                    initCameraOnStartUp:true
                }}
                onClosedCamera={() => {
                    setShowBarcodeScannerPopup(false);
                }}
                onInvalidScan={() => {
                    feedbackService.notifyToast("Invalid code scanned","warning");
                }}
                onFinishedScan={(result: any) => {
               

                    let scannedIdentifier = result?.resultScan;
               
                    if(!scannedIdentifier){
                        feedbackService.notifyToast("Invalid code scanned","warning");
                        setShowBarcodeScannerPopup(false);
                    }else{
                       

                        let shippingOrderCode = null;
                  
                        let splittedString = scannedIdentifier.split("-");

            
                        //returns ex:  SFSSHPO-code
                        if(scannedIdentifier.includes("SFSSHPO-")){
                            shippingOrderCode = splittedString[1];
                        }

                        if(!shippingOrderCode){
                            throw "invalid shpcode selected selected";
                        }
                      
                        props.onReturnSelectedShippingOrderCode(shippingOrderCode);
                        setShowBarcodeScannerPopup(false);
                    }
                }}
                onClosePopup={() => {
                    setShowBarcodeScannerPopup(false);
                }}
                />
            )}
        <Ripples
      
      onClick={() => {
  
          setShowBarcodeScannerPopup(true);
      }}
    >
      {innerClickableElement}
    </Ripples>
        </>

    );
  };

  return (
    <>
        {isCompatible && (
            <div
            className={`${styles.outerContainer} ${
                showBarcodeScannerPopup === true
                ? styles.outerContainerSelected
                : styles.outerContainerUnselected
            }`}
            >
            {getElement()}
            </div>
        )}

    </>

  );
}

export default SARScanner;
