import { styled } from "@mui/material";

const StyledHxfDividerWithContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  "& .StyledHxfDividerWithContent-border": {
    borderBottom: "2px solid lightgray",
    width: "100%",
  },

  "& .StyledHxfDividerWithContent-content": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 22,
    color: "lightgray",
  },
}));
function HxfDividerWithContent({ children }: any) {
  return (
    <StyledHxfDividerWithContent>
      <div className="StyledHxfDividerWithContent-border" />
      <span className="StyledHxfDividerWithContent-content">{children}</span>
      <div className="StyledHxfDividerWithContent-border" />
    </StyledHxfDividerWithContent>
  );
}

export default HxfDividerWithContent;
