import {
  IonButton,
  IonCardHeader, IonIcon, useIonAlert
} from "@ionic/react";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { cloudDownloadOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import productComponentsController from "../../../../../barrel/controllers/productComponentsController";
import stockManagementController, { ICStockManagementProductsListProduct } from "../../../../../barrel/controllers/stockManagementController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import HxfAppServersService from "../../../../../barrel/services/hxfAppServersService";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomUnavailableErrorNotice from "../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import ProgressBar from "../../../../CustomElements/ProgressBar/ProgressBar";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import MaterialStagingConfirmationPopup from "./confirmLaunchPopup/MaterialStagingConfirmationPopup";
import styles from "./MaterialStagingProductionOrderMaterials.module.scss";
import StageProductComponentPopup from "./stageProductComponentPopup/StageProductComponentPopup";
export interface IMaterialStagingMovementLocationData{
    name?:any;
    code?:any;
}

export interface IMaterialStagingFromMovement{
    Id_WarehouseLocation?:any;
    warehouseLocationData?:IMaterialStagingMovementLocationData;
    Id_Warehouse?:any;
    warehouseData?:IMaterialStagingMovementLocationData;
    Id_Lot?:any;
    lotData?:IMaterialStagingMovementLocationData;
    Quantity?:any;
    availableQuantity?:any;
}

export interface IMaterialStaggingRequiredProductParameterizedVariableValue{
    Id_Product_ParameterizedVariable?:any;
    name?:any;
    code?:any;
    Value?:any;
}


export interface IMaterialStagingStagedStockLocationQuantity{
    Id_WarehouseLocation?:any;
    Id_Warehouse?:any;
    Id_Lot?:any;
    Quantity?:any;
    warehouseLocationData?:IMaterialStagingMovementLocationData;
    warehouseData?:IMaterialStagingMovementLocationData;
    lotData?:IMaterialStagingMovementLocationData;
    availableQuantity?:any;
}

export interface IMaterialStagingStagedStock{
    totalStock?:any;
    locationsQuantity:IMaterialStagingStagedStockLocationQuantity[];
}
export interface IMaterialStaggingRequiredProduct{
    Id?:any;
    name?:any;
    code?:any;
    totalQtyRequired?:any;
    availableUnstagedStock?:any;
    productParameterizedVariableValues?:IMaterialStaggingRequiredProductParameterizedVariableValue[];
    stagedStock?:IMaterialStagingStagedStock;
    shopfloorUnstagedQtyAvailable?:any;
}

  
function MaterialStagingProductionOrderMaterials(props: any) {
    const { cancelToken, isCancel } = useCancelToken({
      alias: "ProductionOrdersStandard",
    });
    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();

    const { sessionState } = useGlobalState();

    const [didMount, setDidMount] = useState(false);
    const [present] = useIonAlert();
    const [modifiedStaging, setModifiedStaging] = useState(false);


    const [loadedPCS,setLoadedPCS] = useState<IMaterialStaggingRequiredProduct[]>([]);

    const [loadedProductionOrderData, setLoadedProductionOrderData] = useState<any>(null);
    const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
    const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
      useState(false);
    const [loadingPage, setLoadingPage] = useState(true);

    const poId = props?.match?.params?.poId;


    const [indexReRender, setIndexReRender] = useState(0);
  
    const [showStageProductComponentPopupData, setShowStageProductComponentPopupData] = useState<any>(false);

    const [showMaterialStagingConfirmation,setShowMaterialStagingConfirmation] = useState<any>(false);
    const [rerenderStagingDocKey, setRerenderStagingDocKey] = useState(0);

    const buildIframeUrlStagingDocument = () => {
      
      return HxfAppServersService().getBackend() +  "stock-management/staging-document/?Id_ProductionOrder=" + poId;
   }


    const getCompletedStagingValue = (obj:IMaterialStaggingRequiredProduct) => {

        let totalRequired = obj?.totalQtyRequired ? obj.totalQtyRequired : 0;
        let totalStagedAlready = obj.stagedStock?.totalStock ? obj.stagedStock.totalStock : 0;
    
        if(totalRequired === 0){
            return 100;
        }
        if(totalStagedAlready === 0){
            return 0;
        }

        let pct = totalStagedAlready/totalRequired*100;
        return parseFloat(pct.toFixed(2));
    }

    const loadMoreProductComponents = useCallback(() => {
   
        
        productComponentsController().getProductionOrderBoM(poId,{
            includeAvailableUnstagedStock: true,
            calculatedStagedStockByComponent: true,
            showStagedStock: true,
            includeProductionOrderData:true
        }).then((res:any) => {
           

            let mapPopsBomRequirements = res?.data?.response?.data?.bySubProduct;
            if(!mapPopsBomRequirements){
                throw "failed to fetch pops bom requirements";
            }

            let productionOrderData = res?.data?.response?.data?.productionOrderData;
           
            if(productionOrderData){
                setLoadedProductionOrderData(productionOrderData);
            }
            let materialStaggingProducts:IMaterialStaggingRequiredProduct[] = [];
														
            let bomProductKeys = Object.keys(mapPopsBomRequirements);
            for(let i = 0; i<bomProductKeys.length; i++){
                let bomProduct = mapPopsBomRequirements[bomProductKeys[i]];
                    
                let materialStaggingProduct:IMaterialStaggingRequiredProduct = {
                    Id:bomProduct.Id_Product,
                    totalQtyRequired:bomProduct.totalQtyRequired,
                    name:bomProduct.name,
                    code:bomProduct.code,
                    availableUnstagedStock:bomProduct.availableUnstagedStock,
                    stagedStock:bomProduct.stagedStock,
                    shopfloorUnstagedQtyAvailable:bomProduct.shopfloorUnstagedQtyAvailable
                };

                let recProductParameterizedVariableValues = bomProduct.productParameterizedVariables;
                let productParameterizedVariableValues:IMaterialStaggingRequiredProductParameterizedVariableValue[] = [];
                if(recProductParameterizedVariableValues.length > 0){
                    for(let j = 0; j<recProductParameterizedVariableValues.length; j++){

                        let productPVV:IMaterialStaggingRequiredProductParameterizedVariableValue = {
                            Id_Product_ParameterizedVariable:recProductParameterizedVariableValues[j].Id_Product_ParameterizedVariable,
                            Value:recProductParameterizedVariableValues[j].Value,
                            name:recProductParameterizedVariableValues[j].name,
                            code:recProductParameterizedVariableValues[j].code
                        };
                        productParameterizedVariableValues.push(productPVV);
                    }


                }
                materialStaggingProduct.productParameterizedVariableValues = productParameterizedVariableValues;

                materialStaggingProducts.push(materialStaggingProduct);
            }

            setLoadedPCS(materialStaggingProducts);
       
            setLoadingPage(false);
          
        }).catch((res:any) => {

        });
        //todo, load product components etc..
    }, [poId]);
  
    useEffect(() => {
      if (!didMount) {
        loadMoreProductComponents();
        setDidMount(true);
      }
    }, [
      didMount,
      failedToLoadPosError,
      loadMoreProductComponents,
      requiresNetworkLoadError,
      sessionState,
    ]);
  
    const confirmSetSuggestedLots = () => {

                      
                        
                        
                        //obj.Id,obj.productParameterizedVariableValues,{quantityToFill:obj.totalQtyRequired}
                        
                        let curLoadedPcs = [...loadedPCS];
                        let productsList:ICStockManagementProductsListProduct[] = [];
                        for(let i = 0; i<curLoadedPcs.length; i++){
                            
                            productsList.push({
                                Id:curLoadedPcs[i].Id,
                                productParameterizedVariableValues:curLoadedPcs[i].productParameterizedVariableValues,
                                quantityToFill:curLoadedPcs[i].totalQtyRequired
                            });
                        }
                        stockManagementController().getMultiSuggestedLots(productsList).then((res) => {
                          setModifiedStaging(true);  
                          let mapSuggestedLots = res?.data?.response?.mapSuggestedLots;
                            if(!mapSuggestedLots){
                                feedbackService.notifyToast(t('no.lots.to.suggest'), "warning");
                                return;
                            }
                          
                            let mapKeys = Object.keys(mapSuggestedLots);
                            //match with current pcs
                            for(let i = 0; i<curLoadedPcs.length; i++){
                                let idProduct = curLoadedPcs[i].Id;
                                let ppvvs = curLoadedPcs[i].productParameterizedVariableValues;

                                let matchedProduct = false;
                                for(let j = 0; j<mapKeys.length; j++){
                                    let rcProduct = mapSuggestedLots[mapKeys[j]];
                                    if(rcProduct.Id_Product === idProduct){
                                        if(!ppvvs){
                                            throw "failed to get ppvvs";
                                        }
                                        //check ppvvs
                                        if(ppvvs && ppvvs.length === rcProduct.productParameterizedVariableValues.length){
                                            matchedProduct = true;
                                        }

                                        if(!matchedProduct){
                                            break;
                                        }

                                        let matchedAllPvvs = true;
                                       
                                        for(let l = 0; l<ppvvs?.length; l++){
                                            let pvId = ppvvs[l].Id_Product_ParameterizedVariable;
                                            let pvValue = ppvvs[l].Value;
                                            let foundMatchPv = false;
                                            for(let k = 0; k<rcProduct.productParameterizedVariableValues.length; k++){
                                                let rcPvId = rcProduct.productParameterizedVariableValues[k].Id_Product_ParameterizedVariable;
                                                let rcPvValue =  rcProduct.productParameterizedVariableValues[k].Value;
                                                if(rcPvId === pvId && pvValue === rcPvValue ){
                                                    foundMatchPv = true;
                                                    break;
                                                }
                                             }

                                             if(!foundMatchPv){
                                                matchedAllPvvs = false;
                                                break;
                                             }

                                        }

                                        if(matchedAllPvvs){
                                            //matched product and pvvs

                                           let suggestedLots = rcProduct.suggestedLots;

                                           let newStockLocations:IMaterialStagingStagedStockLocationQuantity[] = [];
                                           let newTotalQty = 0;
                                           for(let m = 0; m<suggestedLots.length; m++){
                                               newTotalQty += suggestedLots[m].Quantity;
                                               newStockLocations.push({
                                                   Id_WarehouseLocation:suggestedLots[m].Id_WarehouseLocation,
                                                   Id_Warehouse:suggestedLots[m].Id_Warehouse,
                                                   Id_Lot:suggestedLots[m].Id_Lot,
                                                   Quantity:suggestedLots[m].Quantity,
                                                   availableQuantity:suggestedLots[m].availableQuantity,
                                                   warehouseLocationData:suggestedLots[m]?.Id_WarehouseLocation ? suggestedLots[m].warehouseLocationData : null,
                                                   warehouseData:suggestedLots[m]?.Id_Warehouse ? suggestedLots[m].warehouseData : null,
                                                   lotData:suggestedLots[m]?.Id_Lot ? suggestedLots[m].lotData : null
                                               })
                                           }
                                           let updatedObj:IMaterialStaggingRequiredProduct = {...curLoadedPcs[i], stagedStock:{
                                                locationsQuantity:newStockLocations,
                                                totalStock:newTotalQty
                                            }};
                                        
                                      
                                            curLoadedPcs[i] = updatedObj;
                                           break;
                                        }
                              
                                    }
                                }
                            }

                           
                            setLoadedPCS(curLoadedPcs);
                        }).catch((res) => {
                            feedbackService.notifyToast(t("generic.critical.error"),"error");
                        });
    }
    return (
      <InAppTemplate>
        <div className={styles.productionOrderInfo}>
          ({loadedProductionOrderData?.code}) {loadedProductionOrderData?.name}
        </div>
        <CustomArrowBack pushUrl="/feature/material-staging" />
        <div className={styles.pageTitle}> <div><ViewInArIcon /></div> <div>{t('materialstaging')}</div></div>
        <div className={styles.topTools}>                 
          {rerenderStagingDocKey > 0 && (
            <div style={{display:'none'}} key={rerenderStagingDocKey}>
                <iframe title="stagingDoc" src={buildIframeUrlStagingDocument()} width="0" height="0"></iframe>
            </div>
          )}
          <IonButton
            disabled={modifiedStaging}
            
            onClick={() => {
              setRerenderStagingDocKey(rerenderStagingDocKey + 1);
            }}
          >
            <IonIcon slot="start" size="medium" icon={cloudDownloadOutline} />
            {t("document")}
          </IonButton>
        </div>
        {
          showMaterialStagingConfirmation && (
            <MaterialStagingConfirmationPopup isOrderOpen={loadedProductionOrderData.open} isStagedComplete={showMaterialStagingConfirmation?.isStagedComplete} onClose={() => {
              setShowMaterialStagingConfirmation(false);
            }} onConfirm={(openOrder:boolean) => {
              setShowMaterialStagingConfirmation(false);

              let shouldOpenProductionOrder = openOrder;
              stockManagementController().launchStagingMaterial(poId,loadedPCS,shouldOpenProductionOrder).then((res) => {
             
            
                  let successCheck = res?.data?.response?.launched;
                  if(successCheck){
                      setModifiedStaging(false);
                      feedbackService.notifyToast(t("staged.success"), "success");
                  }else{
                      throw "failed to launch materials";
                  }
                 
              }).catch((res) => {
                  feedbackService.notifyToast(t("failed.error.generic"),"error");
              });
            }}/>
          )
        }
        {showStageProductComponentPopupData && (
            <StageProductComponentPopup materialStagingProduct={showStageProductComponentPopupData.obj} onClosePopup={() => {
                setShowStageProductComponentPopupData(false);
            }} onUpdateMaterialProduct={(updateObj:IMaterialStaggingRequiredProduct) => {
              setModifiedStaging(true);    
              let idx = showStageProductComponentPopupData.indexMaterial;
                            
                let productionOrderStaging = [...loadedPCS];
                let popsStaging = [...productionOrderStaging];
                popsStaging[idx] = updateObj;

                setLoadedPCS(popsStaging);
                setShowStageProductComponentPopupData(false);
            }}/>
        )}

        {loadingPage && <LoadingSpinnerImg />}
  
        <div className={styles.productionOrdersContainer}>
          <div
            id="productionOrdersSelectionDiv"
            key={"rerender_ref_selection_" + indexReRender}
            className={styles.productionOrdersSelection}
          >
            {!failedToLoadPosError ? (
              <>
                
                <IonCardHeader
                    
                    className={` ${styles.elemBackgroundBulb}`}
                    onClick={() => {
                     
                         let hasAtLeastOneMovement = false;

                         let curLoadedPcs = [...loadedPCS];
                        
                         for(let i = 0; i<curLoadedPcs.length; i++){
                             if(curLoadedPcs[i].stagedStock?.totalStock > 0){
                                 hasAtLeastOneMovement = true;
                                 break;
                             }
                         
                         }

                   
                        present({
                            header: t("overwrite.material.staging"),
                            message: t("are.you.sure.calculated.material.staging"),
                            buttons: [
                                t("cancel"),
                                {
                                text: t("yes"),
                                handler: (d) => {
                                    confirmSetSuggestedLots();
                                },
                                },
                            ],
                            onDidDismiss: (e) => {},
                            });
                         
            
                    }}
                  >
                    <div className={`${styles.elemBackgroundContainer}`}>
                      <TipsAndUpdatesIcon />
                    </div>
                    <div className={styles.autoLabel}>
                      {t("auto")}
                    </div>
         



                    <div className={styles.stagedProgressContainer}>
  
                      <div>
                        <small><b>{t("automatically.fill.all")}</b></small>
                      
                      </div>
      
                    </div>
                  </IonCardHeader>

                {loadedPCS.map((obj: any, index: number) => (
                  <IonCardHeader
                    key={index}
                    className={styles.elemElement}
                    onClick={() => {
                          setShowStageProductComponentPopupData({obj:obj,indexMaterial:index});
                         
                    }}
                  >
                    <div className={styles.elemBackgroundContainer}>
                      <ViewInArIcon />
                    </div>
                    <div>
                      <div className={`${styles.labelContent} ${styles.pvContainer}`}>({obj.code}) {obj.name}</div>
                    </div>
                    {obj.productParameterizedVariableValues.length > 0 && (
                        <div>
                             <div  className={`${styles.labelContent} ${styles.pvContainer}`}>
                                {obj.productParameterizedVariableValues.map((objPv:IMaterialStaggingRequiredProductParameterizedVariableValue,indPv:any) => (
                                    <>
                                        <span key={"ms_" + index + "_pv_" + indPv} style={{marginRight:'5px'}}>
                                            <b>{objPv.name}:</b> {objPv.Value}
                                        </span>
                                
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
      



                    <div className={styles.stagedProgressContainer}>
  
                      <div>
                        <small><b>{t("staged.material")}</b></small>
                        <ProgressBar pgValue={getCompletedStagingValue(obj)} />
                      </div>
      
                    </div>
                  </IonCardHeader>
                ))}{" "}

          <div style={{marginBottom:'300px'}}></div>
              </>
            ) : (
              <></>
            )}
  


          </div>
          
        </div>
        {failedToLoadPosError ? (
          <div className={styles.errorArea}>
            <CustomUnavailableErrorNotice />
          </div>
        ) : (
          <></>
        )}
  
        {requiresNetworkLoadError ? (
          <div className={styles.errorArea}>
            <ErrorNoticeRequiresNetworkLoadObject />
          </div>
        ) : (
          <></>
        )}

        <div className={styles.confirmationBottomContainer}>
                <div className={styles.confirmationBottomContent}>
                  <div className={styles.lineBreaker}></div>

                  <div className={styles.centeredElem}>
                    <IonButton
                      disabled={!modifiedStaging}
                      className={styles.confirmButton}
                      onClick={() => {

                        let isAllCompleted = true;
                        for(let i = 0; i<loadedPCS.length; i++){
                          let completedVal = getCompletedStagingValue(loadedPCS[i]);

                          if(completedVal < 100){
                            isAllCompleted = false;
                            break;
                          }
                        }

                        //
                        setShowMaterialStagingConfirmation({isStagedComplete:isAllCompleted});
                      }}
                    >
                      {t("launch.materials")}
                    </IonButton>
                  </div>

                  
                </div>
              </div>
      </InAppTemplate>
    );
  }
  
  export default MaterialStagingProductionOrderMaterials;
  