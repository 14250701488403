import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";


function machinesController() {

      
      const getMachineStatus = (
        options: any = {
        },
        cancelToken?: any
      ) => {


        let requestUrl = "machines/status";


        return BarrelWrapper().appPostRequest(
          HxfAppServersService().getBackend() + requestUrl,
          {...options},
          cancelToken
        );
    }

    const startDowntime = (options = {},Id_Unavailability:any, Id_Machine:any, Id_MachineSegment:any, cancelToken:any) => {

      

      let requestUrl =
      "machine/initiate-downtime";


      return BarrelWrapper().appPostRequest(
        HxfAppServersService().getBackend() + requestUrl,
        {Id_Machine:Id_Machine,Id_Unavailability:Id_Unavailability, Id_MachineSegment:null, ...options},
        cancelToken
      );
    }

    const getMachine = (
      options = {},
      Id_Machine:any,
      cancelToken: any
    ) => {


      let requestUrl =
        "machine/get/" + Id_Machine;
  

      return BarrelWrapper().appPostRequest(
        HxfAppServersService().getBackend() + requestUrl,
        {...options},
        cancelToken
      );
  }
    const getAllMachines = (
        options: any = {
          page: 0,
          size: 10,
        },
        cancelToken: any
      ) => {


        let requestUrl =
          "machines/listJson/?page=" +
          options["page"] +
          "&size=" +
          options["size"];
    

        return BarrelWrapper().appPostRequest(
          HxfAppServersService().getBackend() + requestUrl,
          {},
          cancelToken
        );
    }
    

    return {startDowntime,getMachine,getAllMachines,getMachineStatus};
}

export default machinesController;
