import { IonContent, IonPage } from "@ionic/react";

import styles from "./ReconnectApp.module.scss";
function ReconnectApp() {
  return (
    <IonPage>
      <IonContent fullscreen>
        <>
          <div className={styles.testStyle}>todo reconnectapp page</div>
        </>
      </IonContent>
    </IonPage>
  );
}

export default ReconnectApp;
