import { IonCardTitle } from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";

import PanToolIcon from '@mui/icons-material/PanTool';
import machinesController from "../../../../barrel/controllers/machinesController";
import LoadingSpinnerImg from "../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../CustomElements/CustomArrowBack/CustomArrowBack";
import styles from "./SelectedMachineManagement.module.scss";

import { useCancelToken } from "../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import InAppTemplate from "../../../InAppTemplate/InAppTemplate";
import { useHistory } from "react-router";
import useMachineManagementShortcutReceiver from "../../../../barrel/hooks/useMachineManagementShortcutReceiver/useMachineManagementShortcutReceiver";
function SelectedMachineManagement(props:any) {
  const reloadChecktime = 10000;
  const { cancelToken, isCancel } = useCancelToken({alias:'selectedMachineManagement'});
  const machineId = props?.match?.params?.machineId;
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const feedbackService = useFeedbackService();
  const timerRecalc = useRef<any>(null);
  const [loadedMachine, setLoadedMachine] = useState<any>({});
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const machineManagementShortcutReceiver = useMachineManagementShortcutReceiver();
  const {t} = useHxfTranslation();


  const loadPageChecks = useCallback(() => {
   

    machinesController().getMachine({checkIfAvailable:true},machineId,cancelToken).then((res:any) => {
    
        if(!res?.data?.response?.data.Id){
            throw "unexpected response";
          }
     
          let machineData = res?.data?.response?.data;
          timerRecalc.current = setTimeout( () => {
            loadPageChecks();
          },reloadChecktime);
          setLoadedMachine(machineData);
          setLoadingPage(false);
    }).catch((res) => {
        if (isCancel(res)) {
            return;
        }
      
        timerRecalc.current = setTimeout( () => {
          loadPageChecks();
        },reloadChecktime);
        setLoadingPage(false);
        feedbackService.notifyToast(t('generic.critical.error'), "error");
    });

  }, [setLoadingPage, feedbackService, t, cancelToken,isCancel,machineId]);

  useEffect(() => {
   
    if (!didMount) {

      loadPageChecks();
      timerRecalc.current = setTimeout( () => {
        loadPageChecks();
      },reloadChecktime);
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  useEffect(() => {
    return () => {
        if(timerRecalc.current){
            console.log("clearing interval");
            clearTimeout(timerRecalc.current);
        }
    }
  /* eslint-disable-line */
  },[]);



  const getInitiateUnavailabilityButton = () => {

    return (
      <div
      className={`${styles.elemElement} ${styles.unavElem}`}
      data-btn={"start-unavailability"}
      onClick={() => {
        //
        let statePreload:any = {
          Id_Machine:machineId,
          machineData:loadedMachine
        };

        props.history.push({
          pathname: "/feature/machine-downtime/start",
          state: statePreload,
        });
      }}
    >
        
      <div className={styles.actionBtnContainer}>
        <div className={`${styles.elemElementTitle}`}>
         {t('machine.unavailability.start.btn')}
        </div>
        <div className={styles.actionIcon}>
          <PanToolIcon />
        </div>
      </div>
      <IonCardTitle className={styles.elemElementDescription}>
      
      </IonCardTitle>

    </div>
    )
  }

  const getResumeDowntimeJobButton = () => {

    return (
      <div
      className={`${styles.elemElement} ${styles.unavElem}`}
      onClick={() => {

        if(loadedMachine?._firstOngoingUnavOccurrence?.Id){

          let statePreload:any = {
          };
  
          props.history.push({
            pathname: "/feature/downtime/occurrence/" + loadedMachine?._firstOngoingUnavOccurrence?.Id,
            state: statePreload,
          });
        }

      }}
    >
        
      <div className={styles.actionBtnContainer}>
        <div className={`${styles.elemElementTitle}`}>
         {t('ongoing.downtime')}
        </div>
        <div className={styles.actionIcon}>
          <PanToolIcon />
        </div>
      </div>
      <IonCardTitle className={styles.elemElementDescription}>
      
      </IonCardTitle>

    </div>
    )
  }
  return (
    
    <InAppTemplate>
      <CustomArrowBack pushUrl="/feature/machines" additionalRouteState={{preserveShortcutData:true}} />
      <div className={styles.pageTitle}>{!loadingPage && (loadedMachine?.code)}</div>
      <div className={styles.topInfoContainer}>
        {machineManagementShortcutReceiver.shortcutReceivedData?.operationProductSelected && (
          <>{machineManagementShortcutReceiver.elementPreloadedShortcutData}</>
        )}
      </div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>

        {!loadingPage && (<>
          {loadedMachine?._unavailable && (
            <div key={"lmach_idx"}>
              {getResumeDowntimeJobButton()}
            </div>
          )}
          {!loadedMachine?._unavailable && (
            <div key={"lmach_idx"}>
              {getInitiateUnavailabilityButton()}
            </div>
          )}
        </>
        ) }

        </div>
      </div>
    </InAppTemplate>
  );
}

export default SelectedMachineManagement;
