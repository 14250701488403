import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

import { useAppOfflineContext } from "../contexts/AppOfflineProvider";
import SessionState from "./sessionState/SessionState";
import AppState from "./sessionState/AppState";
import { isEmptyObject } from "../barrel/utils/ObjectUtils";
import { AxiosIntercepterProvider } from "../contexts/AxiosIntercepterContext";
import entityUserController from "../barrel/controllers/entityUserController";
import HxfAppServersService from "../barrel/services/hxfAppServersService";
import AppUnderMaintenance from "../pages/ErrorPages/AppUnderMaintenance/AppUnderMaintenance";
import AppOfflineInaccessible from "../pages/ErrorPages/AppOfflineInaccessible/AppOfflineInaccessible";
import AppMembershipExpired from "../pages/ErrorPages/AppMembershipExpired/AppMembershipExpired";
import LoadingApp from "../pages/Loading/LoadingApp/LoadingApp";
export interface GlobalStateInterfaceProps {
  firstname: string;
  lastname: string;
  age: string;
}

const GlobalStateContext = createContext({
  state: {} as Partial<GlobalStateInterfaceProps>,
  setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterfaceProps>>>,
  stuff: {} as Partial<GlobalStateInterfaceProps>,
  setStuff: {} as Dispatch<SetStateAction<Partial<GlobalStateInterfaceProps>>>,
  dothis: {} as any,
  sessionState: {} as any,
  appState: {} as any,
});

const GlobalStateProvider = ({
  children,
  value = {} as GlobalStateInterfaceProps,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterfaceProps>;
}) => {
  const [state, setState] = useState(value);
  const [stuff, setStuff] = useState(value);
  const [didMount, setDidMount] = useState(false);
  const appState = AppState();
  const logoutCallback = () => {
    if (
      appState.isConnectionAvailable() &&
      HxfAppServersService().hasServerBeenSet()
    ) {
      console.log("Calling logoff");
      entityUserController()
        .logoutSession()
        .then(() => {})
        .catch(() => {});
    }
  };

  const callbackShowOfflineMode = () => {
    console.log("Setting show offline mode from appload");
    appState.setMaintenance(true);
  };
  const sessionState = SessionState(logoutCallback, callbackShowOfflineMode);

  const { appOffline } = useAppOfflineContext();

  const dothis = () => {
    //console.log("done");
  };

  useEffect(() => {
    if (!didMount) {
      let tsNow: any = Math.floor(Date.now() / 1000);
      localStorage.setItem("appInitiatedTs", tsNow.toString());
      setDidMount(true);
    }
  }, [didMount]);
  //guarantees first session check has been made before continuing
  //todo custom loading

  if (sessionState.isUnavailable()) {
    //todo change this logic and use a proper store

    return (
      <>
        <AppOfflineInaccessible />
      </>
    );
  }
  if (sessionState.isUnderMaintenance()) {
    //todo change this logic and use a proper store

    return (
      <>
        <AppUnderMaintenance />
      </>
    );
  }

  if(sessionState.isMembershipExpired()){
    return (
      <>
        <AppMembershipExpired />
      </>
    );
  }

  if (
    !appOffline().isModuleReady() ||
    !sessionState.isSessionStateLoaded() ||
    isEmptyObject(sessionState)
  ) {
    console.log("PASSING on loading GlobalStateProv");
    return <>
     <LoadingApp/>
    </>;
  }

  console.log("Current Session State:", sessionState.userSessionData);
  return (
    <GlobalStateContext.Provider
      value={{
        state,
        setState,
        stuff,
        setStuff,
        dothis,
        sessionState,
        appState,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
