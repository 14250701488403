import { IonButton, IonIcon } from "@ionic/react";
import { TextField } from "@mui/material";
import { addOutline, removeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import {
  IProduct,
  IProductFixedVariable, IProductParameterizedVariable
} from "../../../../../../../barrel/modelInterfaces/iProduct";
import { useProductionOrderSelectionStore } from "../../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import {
  buildWasteKey, IMapWastes
} from "../ActionScreenAskProducedProductIrregularity";
import styles from "./SubScreenDefineWastes.module.scss";
import HardcodedBehaviourService from "../../../../../../../barrel/services/hardcodedBehaviourService";
import { useGlobalState } from "../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";

interface IProps {
  fullIrregularityObject:any;
  arrayProductsData: IProduct[]; //Array of products to ask for wastes
  attachBomProducts: boolean;
  mapWastes: IMapWastes; //map with wastes filled
  onModifyWastes: any;
}
const focusedColor = "#503528";

export const subscreenDefineWastesBuildArrayProductsFromBom = (
  fullIrregularityObject:any,productBoM: any, sessionState:any
) => {
  let builtArray: IProduct[] = [];

  let ignoreProductsWithOperations = fullIrregularityObject?.resultingWaste_ignoreProductsWithOperations === 1;
  let ignoreProductsWithoutPVS = fullIrregularityObject?.resultingWaste_ignoreProductsWithoutPVS === 1;

  if (productBoM && Object.keys(productBoM).length > 0) {
    let allbomkeys = Object.keys(productBoM);
    for (let i = 0; i < allbomkeys.length; i++) {
      let idProduct = productBoM[allbomkeys[i]].Id_ProductChild;
      let productCode = productBoM[allbomkeys[i]].temp_ProductChild_code;
      let productName = productBoM[allbomkeys[i]].temp_ProductChild_name;
      let Id_OperationProduct_Base = productBoM[allbomkeys[i]]?.childProduct_Id_OperationProduct_Base;
      let qty = productBoM[allbomkeys[i]]?.Quantity
        ? productBoM[allbomkeys[i]].Quantity
        : 0;
      let ppvvs: IProductParameterizedVariable[] = [];
      let ppfvs: IProductFixedVariable[] = [];

      let productBomPvs: any = productBoM[allbomkeys[i]].parameterizedVariables;
      if (productBomPvs && typeof productBomPvs === "string") {
        productBomPvs = JSON.parse(productBomPvs);
      }
      let productBomPPVs: any =
        productBoM[allbomkeys[i]].productParameterizedVariables;
      if (productBomPPVs && typeof productBomPPVs === "string") {
        productBomPPVs = JSON.parse(productBomPPVs);
      }

      let productBomPCPVS: any =
        productBoM[allbomkeys[i]].productComponent_ParameterizedVariables;
      if (productBomPCPVS && typeof productBomPCPVS === "string") {
        productBomPCPVS = JSON.parse(productBomPCPVS);
      }

      let productHasPvs = productBomPPVs && productBomPPVs.length > 0;
      if (productBomPPVs) {
        for (let j = 0; j < productBomPPVs.length; j++) {
          let idParameterizedVariable =
            productBomPPVs[j]["Id_ParameterizedVariable"];
          let idProductParameterizedVariable = productBomPPVs[j]["Id"];

          let pvName = "";
          let pvCode = "";

          for (let k = 0; k < productBomPvs.length; k++) {
            if (productBomPvs[k]["Id"] === idParameterizedVariable) {
              pvName = productBomPvs[k]["name"];
              pvCode = productBomPvs[k]["code"];
              break;
            }
          }

          let pvValue = "";
          if(productBomPCPVS){
            for (let k = 0; k < productBomPCPVS.length; k++) {
              if (
                productBomPCPVS[k]["Id_Product_ParameterizedVariable"] ===
                idProductParameterizedVariable
              ) {
                pvValue = productBomPCPVS[k]["Value"];
                break;
              }
            }
  
          }

          let productComponentParameterizedVariable: IProductParameterizedVariable =
            {
              Id_Product_ParameterizedVariable: idProductParameterizedVariable,
              name: pvName,
              code: pvName,
              Value: pvValue,
            };

          ppvvs.push(productComponentParameterizedVariable);
        }
      }


      let checkShouldIgnoreThisProductWithOperations =  ignoreProductsWithOperations && Id_OperationProduct_Base !== 0 && Id_OperationProduct_Base !== undefined;

      let checkShouldIgnoreThisProductWithoutPVS = ignoreProductsWithoutPVS && !productHasPvs;
  
      let shouldIgnoreProduct = checkShouldIgnoreThisProductWithOperations || checkShouldIgnoreThisProductWithoutPVS;
      if(shouldIgnoreProduct){
        continue;
      }

      let hardcodedCheckShouldHideVisually = HardcodedBehaviourService().checkIfHideButStillWaste_actionScreenAskProducedProductBuildWastesProduct(fullIrregularityObject,productBoM[allbomkeys[i]],sessionState);
  
      let product: IProduct = {
        Id_Product: idProduct,
        code: productCode,
        name: productName,
        productParameterizedVariablesData: ppvvs,
        productFixedVariablesData: ppfvs,
        Quantity: qty
      };
      if(hardcodedCheckShouldHideVisually){
        product._visuallyHideFromWastes = true;
      }
      builtArray.push(product);
    }
  }

  return builtArray;
};

function ActionScreenIrregularityDefineWastes(props: IProps) {
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const { sessionState } = useGlobalState();

  const {t} = useHxfTranslation();
  const getWasteQtyValue = (product: IProduct) => {
    let key = buildWasteKey(product);

    if (props.mapWastes[key]) {
      return props.mapWastes[key].Quantity;
    }
    return 0;
  };
  const buildArrayProductsData = () => {
    let builtArray: any = subscreenDefineWastesBuildArrayProductsFromBom(
      props.fullIrregularityObject,
      productionOrderSelectionStore?.product?.product_BoM,
      sessionState
    ); //productionOrderSelectionStore?.product?.product_BoM;
    if (!props.attachBomProducts) {
      builtArray = [];
    }

    return builtArray;
  };

  const arrayProductsData = buildArrayProductsData();

  if(arrayProductsData && arrayProductsData.length === 0){
    return (
      <div>
        <b>{t('nothing.to.register')}</b>
      </div>
    )
  }
  return (
    <>
      <div className={styles.tableArea}>
        <div className={styles.consumptionsTableContainer}>
          <div className={styles.consumptionsTableBody}>
            <div className={styles.consumptionsRow}>
              <div
                className={`${styles.consumptionsBodyCellHeader} ${styles.consumptionsLeftHeader}`}
              >
                {t('product')}
              </div>
              <div className={`${styles.consumptionsBodyCellHeader}`}>
              {t('wasted.qty')}
              </div>
            </div>

            {arrayProductsData.map((obj: IProduct, index: number) => (
              <div key={"bom_p_" + index} className={styles.consumptionsRow}>
                {!obj?._visuallyHideFromWastes && (
                 <>
                <div
                  className={`${styles.consumptionsBodyCell} ${styles.consumptionsProductCol}`}
                >
                  <div className={styles.consumptionProductName}>
                    <div> ({obj.code}) </div>
                    <div> {obj.name} </div>
                  </div>

                  {obj.productParameterizedVariablesData.length > 0 && (
                    <div>
                      <div>
                        <div
                          className={
                            styles.consumptionsParameterizedVariablesLabel
                          }
                        >
                          <b>{t('parameterizedvariables')}:</b>
                        </div>
                        {obj.productParameterizedVariablesData.map(
                          (
                            objPPv: IProductParameterizedVariable,
                            indexPv: number
                          ) => (
                            <div
                              className={
                                styles.consumptionsParameterizedVariablesAndValues
                              }
                              key={"bom_p_pv_" + indexPv + "_pindex_" + index}
                            >
                              {objPPv.name}:{objPPv.Value}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.consumptionsBodyCell}>
                  <div className={styles.consumptionsQtyContainer}>
                    <div className={styles.consumptionsQty}>
                      <TextField
                        inputProps={{
                          min: 0,
                          style: { width: "85px", textAlign: "center" },
                        }}
                        disabled={false}
                        error={false}
                        onChange={(evt) => {
                          let newQty = evt.target.value;
                          props.onModifyWastes(obj, newQty);
                        }}
                        value={getWasteQtyValue(obj)}
                        label={"Qty"}
                        variant="outlined"
                      />
                    </div>

                  </div>
                  <div style={{display:'flex',justifyContent:'center'}}>
                        <IonButton
                          data-btn={"irr-btn-subtract-idx-" + index}
                          disabled={false}
                          className={styles.addrembtn}
                          onClick={() => {
                             
                              let qt = getWasteQtyValue(obj);
                              if(!qt){
                                  qt = 0;
                              }

                              let newQt = parseInt(qt) - 1;
                              if(newQt < 0){
                                  newQt = 0;
                              }
                    
                    
                              props.onModifyWastes(obj, newQt);
                          }}
                      >
                          <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                        </IonButton>

                        <IonButton
                          data-btn={"irr-btn-subtract-idx-" + index}
                          disabled={false}
                          className={styles.addrembtn}
                          onClick={() => {
                            let qt = getWasteQtyValue(obj);
                            if(!qt){
                                qt = 0;
                            }

                            let newQt = parseInt(qt) +1;
                            props.onModifyWastes(obj, newQt);
                          }}
                      >
                          <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                        </IonButton>
                    </div>
                </div>
                </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ActionScreenIrregularityDefineWastes;
