import HxfSelectorField from "../../CustomElements/HxfSelectorField/HxfSelectorField";
import warehousesController from "../../../barrel/controllers/warehousesController";
import { useEffect, useState } from "react";
function GenericTestPage() {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    if (!didMount) {
      //page=" + offset + "&size=" + rowsSize + "&searchingCodeName=" + searchBarFilter + orderByQuery
      /*let abc = {
                "page":0,
                "size":10,
                "searchingCodeName":""
                 };
            warehousesController().getWarehousesListJson(abc);*/
      setDidMount(true);
    }
  }, [didMount]);

  return (
    <>
      example async selector field{" "}
      <HxfSelectorField
        uniqueId="ex_selector_00"
        labelBuilder={(dataObj) => {
          return "custom Label Builder -> " + dataObj.name;
        }}
        listJsonFunction={warehousesController().getWarehousesListJson}
        onChange={(event, newValue) => {
          console.log("onChange evt: ", event);
          console.log("onChange val: ", newValue);
        }}
      />
      example normal offline selector field{" "}
      <HxfSelectorField
        uniqueId="ex_selector_01"
        defaultItems={[{ label: "something", value: 1 }]}
        onChange={(event, newValue) => {
          console.log("onChange evt: ", event);
          console.log("onChange val: ", newValue);
        }}
      />
    </>
  );
}

export default GenericTestPage;
