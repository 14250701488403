import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import HxfDigitKeyboard from "../../HxfDigitKeyboard/HxfDigitKeyboard";
import HxfStockLocationForm from "../../HxfStockLocationForm/HxfStockLocationForm";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import { IonCardTitle, IonCardHeader, IonCardSubtitle } from "@ionic/react";
import styles from "./DefineUomPopup.module.scss";
import DefineUomPopupConfirmationButtons from "./defineUomPopupConfirmationButtons/DefineUomPopupConfirmationButtons";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  title: string;
  productData: any;
}
function DefineUomPopup(props: IPopup) {
  const [idSelectedUom, setIdSelectedUom] = useState(-1);

  if (!props?.productData) {
    return <></>;
  }

  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.selectedProductInfo}>
              <div className={styles.seletedProductInfoLabels}>
                ( {props.productData.code}) {props.productData.name}
              </div>
            </div>
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
              <div className={styles.selectUomInfo}>
                Select an unit of measurement
              </div>
              <div className={styles.selectorAreaContainer}>
                <div className={styles.selectorArea}>
                  <div key={"prod_uom_def"}>
                    <IonCardHeader
                      className={`${
                        idSelectedUom === -1
                          ? styles.featureElementSelected
                          : ""
                      } ${styles.featureElement}`}
                      onClick={() => {
                        setIdSelectedUom(-1);
                      }}
                    >
                      <div className={styles.featureBackgroundContainer}>
                        <LocalShippingIcon />
                      </div>
                      <IonCardTitle className={styles.featureElementTitle}>
                        pcs
                      </IonCardTitle>
                      <IonCardSubtitle>
                        <div className={styles.featureElementDescription}>
                          base unit
                        </div>
                      </IonCardSubtitle>
                    </IonCardHeader>
                  </div>

                  {props?.productData?.uomConversionsData.map(
                    (obj: any, index: number) => (
                      <div key={"prod_uom_" + index}>
                        <IonCardHeader
                          className={`${
                            idSelectedUom === obj.Id_UoM_Target
                              ? styles.featureElementSelected
                              : ""
                          } ${styles.featureElement}`}
                          onClick={() => {
                            setIdSelectedUom(obj.Id_UoM_Target);
                          }}
                        >
                          <div className={styles.featureBackgroundContainer}>
                            <LocalShippingIcon />
                          </div>
                          <IonCardTitle className={styles.featureElementTitle}>
                            {obj.UoM_Target_Code}
                          </IonCardTitle>
                          <IonCardSubtitle>
                            <div className={styles.featureElementDescription}>
                              {obj.UoM_Target_Name}
                            </div>
                          </IonCardSubtitle>
                        </IonCardHeader>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <DefineUomPopupConfirmationButtons
            onClickConfirm={(result: any) => {
              props.onSuccessConfirm(idSelectedUom);
            }}
          />
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default DefineUomPopup;
