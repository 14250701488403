import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./AddingProductQtyConfirmationPopup.module.scss";

interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  productData?:any;
  qtyResult?:any;
}
function AddingProductQtyConfirmationPopup(props: IPopup) {
  const {t} = useHxfTranslation();

  if (!props?.productData) {
    return <></>;
  }

  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{t('confirmation')}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.selectedProductInfo}>
              <div className={styles.seletedProductInfoLabels}>
                ( {props?.productData?.code} ) {props?.productData?.name}
              </div>
            </div>
            <div className={`popup-content-border-sides-only-styles ${styles.content}`}>
                <div className={styles.confirmationContent}>
                  {props?.productData?.productParameterizedVariables && (
                    <div className={styles.pvvContainer}>
                      {props?.productData?.productParameterizedVariables.map((objPv:any,idx:any) => (
                        <div key={"apPvIdx_" + idx}>
                          <div><b>{objPv.name}</b>: {objPv.Value}</div>
                  
                        </div>
                      ))}
                    </div>
                  )}
                  
                  <div>
                    <div style={{display:'flex',justifyContent:'center'}}><b>{t('quantity')}</b></div>
                    <div style={{fontSize:30,display:'flex',justifyContent:'center'}}>
                      {props?.qtyResult?.quantityResult}
                    </div>
                  </div>
                </div>
            </div>
          </div>

              
        <div
          className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
        >
            <div>
              <div className={styles.container}>
                <div className={styles.buttonsContainer}>
                  <div className={`${styles.customConfirmationContainer} `}>
                    <IonButton
                    data-btn={"confirm-qty-add-stock-value"}
                    disabled={false}
                      className={styles.confirmButton}
                      onClick={() => {
                        props.onSuccessConfirm();
                      }}
                    >
                      {t('confirm')}
                    </IonButton>
                  </div>
                </div>
              </div>
            </div>
        </div>
  
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default AddingProductQtyConfirmationPopup;
