
import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { t } from "i18next";
import { closeOutline } from "ionicons/icons";
import { useState } from "react";
import SimpleContentPopup from "../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./MaterialStagingConfirmationPopup.module.scss";

interface IProps{
    onClose?:any;
    onConfirm?:any;
    isStagedComplete?:boolean;
    isOrderOpen?:boolean;
}
function MaterialStagingConfirmationPopup(props:IProps){
   
    const [openOrder, setOpenOrder] = useState((props?.isStagedComplete) ? true : false);
  

    return (
      <div className={styles.intPopup}>
        <SimpleContentPopup>
          <div>
            <div className={styles.container}>
              <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
              >
                <div></div>
  
                <div className={styles.title}>{t('material.staging.confirmation')}</div>
  
                <IonButton
                  color="danger"
                  className={styles.closeWindowButton}
                  onClick={() => {
                      if(props.onClose){
                        props.onClose();
                      }
                   
                  }}
                >
                  <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
              </div>
              <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >

                <div className={styles.confirmationContent}>
                  <div>
                        {!props.isOrderOpen && (
                            <div style={{display:'flex',justifyContent:'center'}}>
                                
                              <FormGroup>
                                  <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }} checked={openOrder}  onChange={(evt) => {
                                      let newVal = evt.target.checked;
                                      setOpenOrder(newVal);
                                  }} />} label={t('open.productionorder')} />
                              
                              </FormGroup>
                            </div>
                        )}

                        <div style={{display:'flex',justifyContent:'center'}}>
                            <IonButton
                                disabled={false}
                                className={styles.confirmButton}
                                onClick={() => {
                                    props.onConfirm(openOrder);
                                }}
                                >
                                    <div style={{margin:'30px',fontSize:'30px'}}>
                                        {t('confirm')}
                                    </div>
                                
                                </IonButton>
                        </div>
                  </div>

 
                </div>

             </div>
            </div>
          </div>
        </SimpleContentPopup>
      </div>
    );
}
  

export default MaterialStagingConfirmationPopup;
