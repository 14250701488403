import createStore from "../../../AppCustomStateManagement/createStore";

const reducer = (store, action) => {
  switch (action.type) {
    case "INCREASE":
      return store + 1;
    default:
      return store;
  }
};

export const [useCount, dispatchCount] = createStore(0, reducer);
