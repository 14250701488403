import { getCurrentTimestampSeconds } from "../../barrel/utils/TimeUtils";
function GenericOfflineObjectStorage(db) {
  /**
   *
   * @param {*} key
   * @returns content that didnt expire yet.
   */
  const getVal = (key) => {
    //return db.ObjectStorage.get(key);
    return db.ObjectStorage.where(["key+tsExpiration"])
      .between([key, getCurrentTimestampSeconds()], [key, Infinity])
      .toArray();
  };

  const setVal = (
    key,
    value,
    options = { tsExpiration: 0, returnTypeArray: false }
  ) => {
    return db.ObjectStorage.put({
      key: key,
      returnTypeArray:
        options["returnTypeArray"] && options["returnTypeArray"] == true
          ? options["returnTypeArray"]
          : false,
      obj: value,
      tsExpiration: options["tsExpiration"],
    });
  };

  const deleteExpiredData = () => {
    console.log("deleteExpireData callback");
    return db.ObjectStorage.where("tsExpiration")
      .between(1, getCurrentTimestampSeconds())
      .delete();
  };

  return { getVal, setVal, deleteExpiredData };
}

export default GenericOfflineObjectStorage;
