import { useNavigatorOnline } from "@oieduardorabelo/use-navigator-online";
import Dexie from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Offline, Online } from "react-detect-offline";

import useLocalStorageHandler from "../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../barrel/hooks/usePrevious";
import { getCurrentTimestampSeconds } from "../barrel/utils/TimeUtils";
import { useGlobalState } from "../GlobalCustomStateManagement/GlobalStateProvider";
import LoadingApp from "../pages/Loading/LoadingApp/LoadingApp";
import GenericOfflineObjectStorage from "./OfflineObjects/GenericOfflineObjectStorage";
import OfflineSessionState from "./OfflineObjects/OfflineSessionState";
const AppOfflineContext = React.createContext();

export function useAppOfflineContext() {
  return useContext(AppOfflineContext);
}
//ex usage:    const {appOffline} = useAppOfflineContext();

function AppOfflineProvider(props) {
  const { isOnline } = useNavigatorOnline({ startOnline: true });
  const { getLatestOnlineActivityTs } = useLocalStorageHandler();
  const [db, setDb] = useState(null);
  const previousDb = usePrevious(db);
  const [didMount, setDidMount] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const offlineSessionState = OfflineSessionState(db);
  const genericOfflineObjectStorage = GenericOfflineObjectStorage(db);
  /**
   * create db if it doesnt exist, and stores db object in the state
   * @returns
   */
  const initiateOfflineDb = () => {
    //CHANGING baseDbVersion wipes the offline db from clients next start
    let baseDbVersion = 4; //increase on important db changes such as primary keys or stuff that breaks on upgrade
    let tablesVersion = 4; //increase when adding new fields or stuff that isnt deal breaking

    let currentLocalVersion = localStorage.getItem("baseDbVersion");

    let db = new Dexie("AppOfflineDb");
    if (currentLocalVersion != baseDbVersion) {
      console.log("Wiping database.");
      db.delete();
      localStorage.setItem("baseDbVersion", baseDbVersion);
    }
    db.version(tablesVersion).stores({
      ObjectStorage: "key,obj,tsExpiration,[key+tsExpiration],returnTypeArray",
    });
    db.version(tablesVersion).stores({ Product: "++Id,name,code" });

    db.open();

    let tempGenStorage = GenericOfflineObjectStorage(db);
    let datares = tempGenStorage
      .deleteExpiredData()
      .then((res) => {})
      .catch(() => {
        console.log("failed to clean expired data");
      });
    setDb(db);
  };

  const isOfflineForTooLong = () => {
    let lastTimeOnlineTs = getLatestOnlineActivityTs();
    if (lastTimeOnlineTs == null || lastTimeOnlineTs == undefined) {
      return true;
    }

    let currentTs = getCurrentTimestampSeconds();

    //offline for a week
    if (currentTs - lastTimeOnlineTs > 3600 * 24 * 7) {
      return true;
    }

    return false;
  };

  /**
   * checks if the user is online
   * @returns
   */
  const isConnectionAvailable = () => {
    return isOnline;
    //return navigator.onLine;
  };

  const testingContext = () => {
    console.log("test context");
  };

  const isModuleReady = () => {
    return isReady;
  };

  function getDb() {
    return db;
  }
  /*
    usable appOffline methods,
    function created for better syntax appOffline().something instead of importing "something" directly
    */
  const appOffline = () => {
    return {
      isOfflineForTooLong,
      isModuleReady,
      testingContext,
      isConnectionAvailable,
      offlineSessionState,
      genericOfflineObjectStorage,
    };
  };

  useEffect(() => {
    if (!didMount) {
      initiateOfflineDb();
      setDidMount(true);
    }
  }, [didMount]);

  //readystate
  useEffect(() => {
    if (db !== previousDb && !isReady && db !== null) {
      setIsReady(true);
    }
  }, [db, isReady, previousDb]);

  useEffect(() => {}, [isReady]);
  //on state data change

  const value = {
    testingContext,
    appOffline,
  };
  if (!isReady) {
    //todo custom loading
    console.log("Passing on loading #AppoOff");
    return <><LoadingApp/></>;
  }

  //offlineStoreSessionState();
  //offlineSessionState.syncState();

  return (
    <AppOfflineContext.Provider value={value}>
      {props.children}
    </AppOfflineContext.Provider>
  );
}

export default AppOfflineProvider;
