import {
    IonButton,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
  } from "@ionic/react";
  import LocalShippingIcon from "@mui/icons-material/LocalShipping";
  import { closeOutline } from "ionicons/icons";
  import { useEffect, useRef, useState } from "react";


  import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
  import styles from "./TraceStockPopup.module.scss";

  import { t } from "i18next";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import SimpleContentPopup from "../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import stockManagementController from "../../../barrel/controllers/stockManagementController";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import { CircularProgress } from "@mui/material";

export interface ITraceStockProductData{
    Id_Product:any;
    name:any;
    code:any;
    arrayProductParameterizedVariables:any;
}
  
  interface IPopup {
    onClose:any;
    onSelectStockLocation:any;
    Id_Product:any;
    productName:any;
    productCode:any;
    arrayProductParameterizedVariables:any;
    notDefinedAllParameterizedVariableValues?:boolean;
  }
  function TraceStockPopup(props: IPopup) {
  
    const {t} = useHxfTranslation();
    const [didMount, setDidMount] = useState(false);
    const feedbackService = useFeedbackService();

    const [stockRes, setStockRes] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    const canShowPVS = (obj:any) => {
      return (obj?.arrayProductParameterizedVariables); //props?.notDefinedAllParameterizedVariableValues === true && 
    }

    const getStockLocationElement = (obj:any, index:any) => {

        let isBaseShopfloorLocation = obj.Id_WarehouseLocation === null && obj.Id_Warehouse === null;

        let warehouseLocationCode =  obj?.warehouseLocationCode;
        let warehouseLocationName =  obj?.warehouseLocationName;

        let warehouseCode = obj?.warehouseCode;
        let warehouseName = obj?.warehouseName;

        let totalStockAvailable = obj?.Quantity;


        return (
            <>
            <div className={styles.stockLocationContainer}>
                <div>
                    <div>
                        {canShowPVS(obj) ? (
                          <>
                            <div><b>{t('parameterizedvariables')}</b></div>
                            {obj?.arrayProductParameterizedVariables?.map((objPv:any, objIndex:any) => (
                              <>
                                <div>
                                  {objPv.name}: {objPv.Value}
                                </div>
                              </>
                            ))}
                          </>
                        ) : (<></>)}
                        <div><b>{t('location')}</b></div>
                        {isBaseShopfloorLocation ? (
                            <>{t('shopfloor')}</>
                        ) : (
                        <>
                            <div>({warehouseLocationCode}) {warehouseLocationName}</div>
                            <div>({warehouseCode}) {warehouseName}</div>
                        </>
                        )}

                    </div>

                    <div style={{marginTop:10}}>
                        <div><b>{t('stock.qty')}</b></div>
                        <div className={styles.qtyContainer}>
                            {totalStockAvailable}
                        </div>
                    </div>
                </div>
                <div>
                <IonButton

                    className={styles.confirmButton}
                    data-btn={"select-location-traced"}
                    onClick={() => {
                        props.onSelectStockLocation({
                            Id_WarehouseLocation:obj.Id_WarehouseLocation,
                            warehouseLocationCode:obj?.warehouseLocationCode,
                            warehouseLocationName:obj?.warehouseLocationName,
                            Id_Warehouse:obj.Id_Warehouse,
                            warehouseCode:obj?.warehouseCode,
                            warehouseName:obj?.warehouseName,
                            defaultNoLot:obj?.defaultNoLot
                        });
                    }}
                >
                   {t('select.location')}
                </IonButton>
                </div>
            </div>
            </>
        )
    }

    useEffect(() => {

        if(!didMount){

       
            let preparedProductObj = {
                Id_Product:props.Id_Product,
                stockMovement_productParameterizedVariables:props.arrayProductParameterizedVariables,
                exactProductStockLocation:false,
                exactProductParameterizedVariables:props?.notDefinedAllParameterizedVariableValues ? false : true
            };

            stockManagementController().getProductsStockAtLocation([preparedProductObj],{lotTraceability:false,includeDefaultNoLot:true}).then((res:any) => {
                setIsLoading(false);

                if(!res?.data?.response?.data){
                    throw "unexpected stock err";

                }
                let stockResult = res?.data?.response?.data?.[0]?.stockResult;
                if(stockResult){
                    setStockRes(stockResult);
                }
            }).catch((res:any) => {
                setIsLoading(false);
                feedbackService.notifyToast(t('generic.critical.error'), "danger");
                props.onClose();
            });
            setDidMount(true);
        }
    },[didMount]);
  
    return (
      <div className={styles.intPopup}>
        <SimpleContentPopup mode="INNER">
          <div>
            <div className={styles.container}>
              <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
              >
                <div></div>
  
                <div className={styles.title}>{t('trace.stock')}</div>
  
                <IonButton
                  color="danger"
                  className={styles.closeWindowButton}
                  onClick={() => {
                    if (props?.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
              </div>
              <div className={styles.selectedProductInfo} style={{backgroundColor: 'rgb(33, 87, 189)'}}>
                <div className={styles.selectedProductInfoLabels}>
                    ({props.productCode}) {props.productName}
                </div>
              </div>
              <div
                className={`popup-content-border-sides-only-styles ${styles.content}`}
              >
                {isLoading ? (<>
                <div style={{display:'flex',justifyContent:'center',marginTop:30, marginBottom:30}}>
                    <CircularProgress/>
                </div>
                </>): (<>
                    {!stockRes || stockRes.length === 0 ? (

                        <>{t('no.results')}</>
                    ) : (<>
                        <div>
                            
                            {stockRes.map((obj:any, index:any) => (
                                <div key={"stockres_" + index}>
                                    {getStockLocationElement(obj,index)}
                                </div>
                            ))}
                            
                        </div>
                    
                    </>)}

                </>)}

              </div>
            
  
            </div>
          </div>
        </SimpleContentPopup>
      </div>
    );
  }
  
  export default TraceStockPopup;
  