import { IonCardHeader } from "@ionic/react";
import CustomArrowBack from "../../../../../CustomArrowBack/CustomArrowBack";
import styles from "./SegmentGoBack.module.scss";
interface ICustomInterface {
  onClick: any;
}
function SegmentGoBack(props: ICustomInterface) {
  return (
    <div className={`${styles.activeProductionElement}  `}>
      <IonCardHeader
        className={`${styles.elemElement}`}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        <div className={styles.elemBackgroundContainer}>
          <CustomArrowBack />
        </div>
      </IonCardHeader>
    </div>
  );
}

export default SegmentGoBack;
