import styles from "./DefineProductParameterizedVariableValuesConfirmationButtonsPopup.module.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
interface IProps {
  onClickConfirm: any;
  disabled?:any;
}

function DefineProductParameterizedVariableValuesConfirmationButtonsPopup(
  props: IProps
) {
  const {t} = useHxfTranslation();
  
  return (
    <div
      className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
    >
      <div>
        <div className={styles.container}>
          <div className={styles.buttonsContainer}>
            <div className={`${styles.customConfirmationContainer} `}>
              <IonButton
              data-btn={"confirm-define-pv-value"}
              disabled={props.disabled}
                className={styles.confirmButton}
                onClick={() => {
                  if (props.onClickConfirm) {
                    props.onClickConfirm();
                  }
                }}
              >
                {t('caps.continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefineProductParameterizedVariableValuesConfirmationButtonsPopup;
