import styles from "./OnGoingMultiProductionsFinishSubscreenConfirmationButtons.module.scss";

import { IonButton } from "@ionic/react";
import { useState } from "react";
import productionController from "../../../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
  enabled: boolean;
}
function OnGoingMultiProductionsFinishSubscreenConfirmationButtons(
  props: IProps
) {
  const {t} = useHxfTranslation();
  
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={styles.confirmationContainer}>
          <div>
            <div>
              <IonButton
                disabled={!props.enabled}
                className={styles.confirmButton}
                onClick={() => {
                  if (props.onClickConfirm) {
                    props.onClickConfirm();
                  }
                }}
              >
                {t('finalize')}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnGoingMultiProductionsFinishSubscreenConfirmationButtons;
