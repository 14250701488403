import styles from "./PrActionButton.module.scss";
import { IonIcon } from "@ionic/react";
import { homeOutline } from "ionicons/icons";
import Ripples from "react-ripples";
function PrActionButton(props: any) {
  const getProperButtonTypeStyle = () => {
    if (props?.btnType === "primary") {
      return styles.primaryButton;
    }

    if (props?.btnType === "danger") {
      return styles.dangerButton;
    }

    if (props?.btnType === "success") {
      return styles.successButton;
    }

    return styles.primaryButton;
  };

  let btnTypeStyle = getProperButtonTypeStyle();
  let iconSlot = props?.iconSlot === "end" ? "end" : "start";
  let iconPassed = props?.iconPassed ? props?.iconPassed : null;
  return (
    <div className={`${styles.buttonContainer}`}>
      <Ripples className={styles.ripplesContainer}>
        <div
          className={`${btnTypeStyle !== null ? btnTypeStyle : ""} ${
            styles.buttonContent
          }`}
          onClick={() => {
            if (props?.onClick) {
              props.onClick();
            }
          }}
        >
          {iconPassed !== null && iconSlot === "start" && (
            <div className={styles.iconContainer}>
              <IonIcon icon={iconPassed} />
            </div>
          )}
          <div className={`${styles.buttonLabelContainer}`}>{props?.label}</div>
          {iconPassed !== null && iconSlot === "end" && (
            <div className={styles.iconContainer}>
              <IonIcon icon={iconPassed} />
            </div>
          )}
        </div>
      </Ripples>
    </div>
  );
}

export default PrActionButton;
