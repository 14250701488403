function UnavailabilitiesService(){

    const checkPreventStartError = (errorsMap:any) => {
        if(errorsMap?.["prevent.start.production"]){
            let preventIssue = errorsMap?.["prevent.start.production"];
            if(preventIssue?.onGoingUnavailability){
              let canEntityUserStopThisUnav = preventIssue?.canEntityUserStopThisUnav;
              if(canEntityUserStopThisUnav){
                window.location.href = "/feature/downtime/occurrence/" + preventIssue?.onGoingUnavailability?.Id_UnavailabilityOccurrence;
                return;
              }else{
                return "UNAV_CONTACT_SUPERVISOR";
              }
            }
          }

          return;
    }


    return {checkPreventStartError}
}

export default UnavailabilitiesService;