import { useAppOfflineContext } from "../../contexts/AppOfflineProvider";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomIntegrationService from "../../pages/CustomElements/ProductionUI/CustomPopups/CustomIntegrationsPopup/IntegrationServices/CustomIntegrationService";
import entityUserController from "../controllers/entityUserController";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import MobileWrapperCommunicationService from "./mobileWrapperCommunicationService";
function AppSessionService() {
  const { saveRefreshToken } = useLocalStorageHandler();
  const { sessionState } = useGlobalState();

  const { appOffline } = useAppOfflineContext();
  const tryRefreshSession = (callbackSuccess, callbackFail) => {
    entityUserController()
      .sessionCheck()
      .then((responseObject) => {
        let response = responseObject.data.response;
        let refreshToken = response.refresh_token;
        if (refreshToken !== undefined) {
          saveRefreshToken(refreshToken);
        }
        MobileWrapperCommunicationService().notifyChangedAccessTokenFrontoffice();
        callbackSuccess(responseObject);
        CustomIntegrationService().startEnabledIntegrations();
      })
      .catch((resp) => {
        callbackFail(resp);
      });

  };

  const handleSuccessLoginResponse = (responseObject) => {
    console.log("login successful");

    sessionState.startAutoRefresher();
    let response = responseObject.data.response;
    sessionState.setUserSessionData(response);
    let refreshToken = response.refresh_token;
    saveRefreshToken(refreshToken);
   
    sessionState.checkMembershipData(response?.membershipData);
  };

  return { tryRefreshSession, handleSuccessLoginResponse };
}
export default AppSessionService;
