import {
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonSpinner,
  } from "@ionic/react";
  import LocalShippingIcon from "@mui/icons-material/LocalShipping";
  import { searchCircleOutline } from "ionicons/icons";
  import { useCallback, useEffect, useRef, useState } from "react";
  import shippingOrdersController from "../../../../../../barrel/controllers/shippingOrdersController";
  import usePrevious from "../../../../../../barrel/hooks/usePrevious";
  import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
  import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
  import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
  import CustomErrorNotice from "../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
  import CustomSearchBar from "../../../../../CustomElements/CustomSearchBar/CustomSearchBar";
  import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
  import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
  import ProgressBar from "../../../../../CustomElements/ProgressBar/ProgressBar";
  import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
  import styles from "./ReceivingsReturnedMaterial.module.scss";
  import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
  import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
  import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
  import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import SARScanner from "../../../Comps/SARScanner/SARScanner";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
  
  function ReceivingsReturnedMaterial(props:any) {
    const {t} = useHxfTranslation();
    const syncSettings = useSyncSettings();
    const { sessionState } = useGlobalState();
    const [didMount, setDidMount] = useState(false);
    const previousDidMount = usePrevious(didMount);
    const sizePage = 25;
    const posContainerRef = useRef(null);
    const loadedObjsRef = useRef<any>(null);
  
    const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
    const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
      useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
  
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const infiniteScrollEnabledRef = useRef(false);
    const allowedInfiniteScrollLoadPageRef = useRef(0);
    const [indexReRender, setIndexReRender] = useState(0);
    
    const feedbackService = useFeedbackService();
    const [searchbarInput, setSearchbarInput] = useState("");
    const timerStartSearching = useRef<any>(0);
  
    const shippingOrdersSearchFilters = useRef({});
  
    const [filtersApplied, setFiltersApplied] = useState([]);
    const previousFiltersApplied = usePrevious(filtersApplied);

  
    const initiateFilterCode = (val:any) => {
      setSearchbarInput(val);
      clearTimeout(timerStartSearching.current); //stops spam requests
      timerStartSearching.current = setTimeout(
        () => {
          shippingOrdersSearchFilters.current = {
            ...shippingOrdersSearchFilters.current,
            searchByCode: val,
          };
          restartSearch();
        },
        500,
        val
      );
    };
  
    const onFilterChipRemove = (index:any) => {
      let newFiltersApplied:any = [];
      for (let i = 0; i < filtersApplied.length; i++) {
        if (i !== index) {
          newFiltersApplied.push(filtersApplied[i]);
        }
      }
      setFiltersApplied(newFiltersApplied); //will trigger useeffect
    };
  
    const getProperFilterChip = (obj:any, index:any) => {
      //custom styling of chip
      if (obj.filterKey === "Id_SalesOrder") {
        return (
          <CustomAppliedFilterChip
            filterTextElement={
              <>
                <b>{t("salesorder")}:</b> {obj.orderCode}
              </>
            }
            onRemoveFilter={() => {
              onFilterChipRemove(index);
            }}
          />
        );
      }
  
      let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
      let chipVal = obj[obj.filterKey];
      //default
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{labelDefault}:</b> {chipVal}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    };
    const getFormattedFiltersForRequest = (filtersArray:any) => {
      let filtersArr = [...filtersArray];
      let formattedFilters:any = {};
      for (let i = 0; i < filtersArr.length; i++) {
        let filterKey = filtersArr[i].filterKey;
        let filterValue = filtersArr[i][filterKey];
        formattedFilters[filterKey] = filterValue;
      }
  
      return formattedFilters;
    };
  
    const getSalesOrderElement = (obj:any, index:any) => {
      let salesOrderData = obj.salesOrderData;
      salesOrderData = JSON.parse(salesOrderData);
  
      if (!salesOrderData?.Id_SalesOrder) {
        //if has no associated order
        return <></>;
      }
      return (
        <div className={styles.orderNameCodeContainer}>
          <div className={styles.orderTopLabel}>
            <b>{t("salesorder")}</b>
          </div>
          <div className={styles.orderNameCode}>
            <div
              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
            >
              ({salesOrderData.code})
            </div>
            <div
              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
            >
              {salesOrderData.name}
            </div>
          </div>
        </div>
      );
    };
  
    const loadMore = useCallback(
      (passedFilters = null) => {
        let currentPage = allowedInfiniteScrollLoadPageRef.current;

        let extraFiltersApply = {};
        if (passedFilters) {
          extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
        } else {
          extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
        }
       
        extraFiltersApply = {
            ...extraFiltersApply, 
            onlySubcontractedShippingOrders:true
        };
  
        shippingOrdersController()
          .getShippingOrders({
            page: currentPage,
            size: sizePage,
            openOnly: false,
            includeSalesOrderData: true,
            ...shippingOrdersSearchFilters.current,
            ...extraFiltersApply,
          })
          .then((resp) => {
            syncSettings.check(resp);
            let posData = resp.data.response.data.return;
            let posTotalSize = resp.data.response.data.totalSize;
            let posExtraData = resp.data.response.data.extra;
  
            if (currentPage === 0) {
              //first load only
              //loads the extraData that loads in the backend only when the page = 0
              //this is done to avoid double requests just to gather extra unrelated data
            }
  
            let newArrayLoadedObjs = [];
  
            if (loadedObjsRef.current !== null) {
              newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
            } else {
              newArrayLoadedObjs = posData;
            }
  
            loadedObjsRef.current = newArrayLoadedObjs;
  
            let hasMoreElements =
              newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
            if (hasMoreElements) {
              allowedInfiniteScrollLoadPageRef.current =
                allowedInfiniteScrollLoadPageRef.current + 1;
              infiniteScrollEnabledRef.current = true;
            } else {
              infiniteScrollEnabledRef.current = false;
            }
            if (currentPage === 0) {
              setLoadingPage(false);
            }
            //setLoadedObjs(loadedObjsRef.current);
            setIsLoadingMore(false);
            setIndexReRender(indexReRender + 1);
          })
          .catch((resp) => {
            console.log("THE ERROR: ", resp);
            if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
              if (failedToLoadPosError) {
                setFailedToLoadPosError(false);
              }
              setRequiresNetworkLoadError(true);
            } else {
              if (requiresNetworkLoadError) {
                setRequiresNetworkLoadError(false);
              }
              setFailedToLoadPosError(true);
            }
            console.log("FAILED::", resp);
  
            setLoadingPage(false);
          });
      },
      [
        syncSettings,
        failedToLoadPosError,
        indexReRender,
        requiresNetworkLoadError,
        filtersApplied,
      ]
    );
    const restartSearch = useCallback(() => {
      allowedInfiniteScrollLoadPageRef.current = 0;
      loadedObjsRef.current = null;
  
      loadMore();
    }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);
    useEffect(() => {
      if (!didMount) {
        let loadingFilters = props?.location?.state?.applyFilters;
  
        if (loadingFilters) {
          setFiltersApplied(loadingFilters);
        } else {
          loadingFilters = null;
        }
  
        window.history.replaceState({}, document.title); //clear history state
  
        loadMore(loadingFilters);
        setDidMount(true);
      }
    }, [
      props?.location?.state?.applyFilters,
      didMount,
      failedToLoadPosError,
      requiresNetworkLoadError,
      sessionState,
      loadMore,
    ]);
  
    useEffect(() => {
      if (previousDidMount === didMount && didMount) {
        if (previousFiltersApplied !== filtersApplied) {
          console.log("filters were changed");
          restartSearch();
        }
      }
    }, [
      filtersApplied,
      previousDidMount,
      didMount,
      previousFiltersApplied,
      restartSearch,
    ]);
  
    if (!didMount) {
      return <></>;
    }
  
    return (
      <InAppTemplate>
        <CustomArrowBack pushUrl="/feature/receivings" />
        <div className={styles.pageTitle}>{t("returned.from.shippingorder")}</div>
  
        <div className={styles.settingsContainer}>
          <div className={styles.customSearchBarContainer}>
            <CustomSearchBar
              value={searchbarInput}
              onResetTextClick={() => {
                initiateFilterCode("");
              }}
              onChange={(evt:any) => {
                initiateFilterCode(evt.target.value);
              }}
              iconElement={
                <div className={styles.searchIconStyles}>
                  <IonIcon icon={searchCircleOutline} />
                </div>
              }
              placeholder={t('shippingorder')}
            />
            <SARScanner  onReturnSelectedShippingOrderCode={(shippingOrderCode:any) => {
        
              shippingOrdersController().scanByCode(shippingOrderCode).then((res) => {

                  let idShippingOrder = res?.data?.response?.data?.Id;
                  if(!idShippingOrder){
                    throw "invalid shipping order";
                  }
            
                  props.history.push("/feature/receivings/returned-material-shipped-receiving-products/" + idShippingOrder);
                  
              }).catch((res:any) => {
          
                 if(res?.request?.status === 404){
                  feedbackService.notifyToast(t('order.not.found'), "error");
                 }else{
                  feedbackService.notifyToast(t('generic.critical.error'), "error");
                 }
                 
              });
            //Todo send request check if exists and it does then open on Id 
            /*      
            let machineName = "";
            let machineCode = "";
            let machineData = null;
            let segmentData = null;
            let machines = machinesData;

            let machineIndex = 0;
            for(let i = 0; i<machines.length; i++){
              let machineSegments = null;
              if(parseInt(machines[i].Id_Machine) === parseInt(idMachine)){
                machineData = machines[i];
                machineIndex = i;
                if(idMachineSegment){
                  machineSegments = machines[i]?.machineSegmentsData ? machines[i]?.machineSegmentsData : [];
                  for(let j = 0; j<machineSegments.length; j++){
                    if(parseInt(machineSegments[j].Id_MachineSegment) === parseInt(idMachineSegment)){
                      segmentData = machineSegments[j];
                      break;
                    }
                  }

                }

                if(getIsBusyMachine(machines[i])){
                  feedbackService.notifyToast("This machine is currently busy!", "error");
                  return;
                }

                
                break;
              }
            }

            if(machineData){

              clickedMachineIndex(machineIndex, {
                attachMachineSegment:{
                  Id_MachineSegment:idMachineSegment,
                  segmentData:segmentData
                },
                smoothScrollToSelection: true
              });
            }*/



            }}/>
          </div>
        </div>
        {filtersApplied.length > 0 && (
          <div className={styles.appliedFiltersContainer}>
            {filtersApplied.map((obj, index) => (
              <div key={"cafc_" + index}>{getProperFilterChip(obj, index)}</div>
            ))}
          </div>
        )}
  
        <div className={styles.shippingOrdersContainer}>
          <div
            id="shippingOrdersSelectionDiv"
            key={"rerender_ref_selection_" + indexReRender}
            className={styles.shippingOrdersSelection}
            ref={posContainerRef}
          >
            {!failedToLoadPosError && loadedObjsRef.current !== null ? (
              <>
                {loadedObjsRef.current.map((obj:any, index:any) => (
                  <div
                    key={"ro_" + index}
                    className={styles.shippingOrderElement}
                  >
                    <IonCardHeader
                      className={styles.elemElement}
                      onClick={() => {
                        props.history.push(
                          "/feature/receivings/returned-material-shipped-receiving-products/" + obj.Id
                        );
                      }}
                    >
                      <div className={styles.elemBackgroundContainer}>
                        <LocalShippingIcon />
                      </div>
                      <div className={styles.orderNameCodeContainer}>
                        <div className={styles.orderTopLabel}>
                          <b>{t("shippingorder")}</b>
                        </div>
                        <div className={styles.orderNameCode}>
                          <div
                            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                          >
                            ({obj.code})
                          </div>
                          <div
                            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                          >
                            {obj.name}
                          </div>
                        </div>
                      </div>
                      {getSalesOrderElement(obj, index)}
                      <div className={styles.creationDateContainer}>
                        <div>
                          <b>{t("date.due")}</b>
                        </div>
                        <div>
                          {obj?.dueDate
                            ? DatesConversionService().formattedDate(obj.dueDate)
                            : t("date.any")}
                        </div>
                      </div>
                      {/*<div className={styles.progressBarContainer}>
                        <ProgressBar pgValue={obj.shippingOrder_completePct} />
                      </div>*/}
                    </IonCardHeader>
                  </div>
                ))}{" "}
              </>
            ) : (
              <></>
            )}
  
            {isLoadingMore && (
              <IonCardHeader className={styles.elemElement} onClick={() => {}}>
                <div className={styles.elemBackgroundContainer}>
                  <LocalShippingIcon />
                </div>
                <IonCardSubtitle>
                  <div className={styles.elemElementTitle}>Loading...</div>
                </IonCardSubtitle>
                <IonCardTitle className={styles.elemElementDescription}>
                  <IonSpinner />
                </IonCardTitle>
              </IonCardHeader>
            )}
  
            <HxfInfiniteScroll
              containerElementRef={posContainerRef}
              requiresContainerHavingScrollbar={true}
              onLoadMore={() => {
                /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
              
                if(allowingVal > 7){
                  return;
                }
                console.log("allowing more :", allowingVal);
                 allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
                */
  
                if (infiniteScrollEnabledRef.current) {
                  setIsLoadingMore(true);
                  loadMore();
                }
              }}
              allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
            />
          </div>
        </div>
        {failedToLoadPosError ? (
          <div className={styles.errorArea}>
            <CustomUnavailableErrorNotice />
          </div>
        ) : (
          <></>
        )}
  
        {requiresNetworkLoadError ? (
          <div className={styles.errorArea}>
            <ErrorNoticeRequiresNetworkLoadObject />
          </div>
        ) : (
          <></>
        )}
      </InAppTemplate>
    );
  }
  
  export default ReceivingsReturnedMaterial;
  