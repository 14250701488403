import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

import entityUserController from "../barrel/controllers/entityUserController";
import useLocalStorageHandler from "../barrel/hooks/useLocalStorageHandler";
import HxfAppServersService from "../barrel/services/hxfAppServersService";
import MobileWrapperCommunicationService from "../barrel/services/mobileWrapperCommunicationService";
import { useGlobalState } from "../GlobalCustomStateManagement/GlobalStateProvider";

const AxiosIntercepterContext = createContext({});

export function useAxiosIntercepterContext() {
  return useContext(AxiosIntercepterContext);
}

export function AxiosIntercepterProvider({ children }: any) {
  //const [userSessionData, setUserSessionData] = useState();
  const { sessionState, appState } = useGlobalState();

  const [errorConnection, setErrorConnection] = useState(false);

  const [loading, setLoading] = useState(false);

  const [didMount, setDidMount] = useState(false);
  const {getCustomAccountSignInRoute, getSessionCheckRoute, getLogoutSessionRoute, sessionCheck } =
    entityUserController();
  const { saveRefreshToken } = useLocalStorageHandler();

  useEffect(() => {
    if (!didMount) {
      //intercept request before going
      /*axios.interceptors.request.use(
           function(successfulReq) {
             return successfulReq;
           }, 
           function(error) {
             return Promise.reject(error);
           }
         );*/

      //intercept request on returning

      axios.interceptors.response.use(
        (response) => {
          if (HxfAppServersService().hasServerBeenSet()) {
            let responseUrl = response?.config?.url
              ? response?.config?.url
              : "";
            let apiServeUrl: any = HxfAppServersService().getBackend()
              ? HxfAppServersService().getBackend()
              : "some-undefined-error";
            if (apiServeUrl === "some-undefined-error") {
              throw new Error("failed to get env api serve url");
            }
            if (response.config.url?.endsWith(getSessionCheckRoute())) {
              const newRefreshToken = response.data.response.refresh_token;
              saveRefreshToken(newRefreshToken);
            }

            if (responseUrl.startsWith(apiServeUrl)) {
        
              if(appState?.appStateData?.maintenance === true){
                appState.setMaintenance(false);
              }
             
            }
          }

          return Promise.resolve(response);
        },
        (error) => {
   
          if (HxfAppServersService().hasServerBeenSet()) {
            const status = error.response ? error.response.status : null;
            console.log("Axios - Error");
            console.log(status);

            if (
              error?.config?.url.startsWith(
                HxfAppServersService().getBackend()
              ) &&
              status === 503
            ) {
              console.log("503 detected");

              appState.setMaintenance(true);
              return Promise.reject(error);
            }

            if (status == null) {
              //failed to connect to the server

              console.log(
                "failed to connect to the server ",
                JSON.stringify(error)
              );
              //appState.setServerUnavailable(true);
           
              return Promise.reject(error);
            }

            appState.setMaintenance(false);
            if (status === 403) {
              return Promise.reject(error);
            }

            if (status === 404) {
              console.log("ERROR 404");
            }

            if (
              error !== undefined &&
              error.config !== undefined &&
              error.config.do_not_retry_again !== undefined
            ) {
              if (error.config.do_not_retry_again) {
                console.log("todo, if needed logout here too");
                return Promise.reject(error);
              }
            }

            if(status === 401 && error.config.url.endsWith(getCustomAccountSignInRoute())){
              return Promise.reject(error);
            }

            //intercept access_token expired if received 403 error and isnt refresh token url
            if (
              status === 401 &&
              !error.config.url.endsWith(getSessionCheckRoute()) &&
              !error.config.url.endsWith(getLogoutSessionRoute())
            ) {
              console.log("GOT IN HEEEREEE");
              return sessionCheck()
                .then((response) => {
                  let refreshToken = "";

                  if (
                    response.data.response !== undefined &&
                    response.data.response.refresh_token !== undefined
                  ) {
                    refreshToken = response.data.response.refresh_token;
                    saveRefreshToken(refreshToken);
                    MobileWrapperCommunicationService().notifyChangedAccessTokenFrontoffice();
                  } else {
                    //session status check responded successful but invalid
                    console.log("LOGOUT NOW!!");
                    sessionState.logout();
                    return Promise.reject(error);
                  }
                  error.config["do_not_retry_again"] = true;
                  return axios.request(error.config);
                })
                .catch(() => {

                  
                  console.log("LOGOUT NOW!!");
                  sessionState.logout();
                });
            }

            if (
              status === 401 &&
              error.config.url.endsWith(getSessionCheckRoute())
            ) {
              console.log(
                "session-status forbidden, logout now!",
                sessionState
              );

              if (!error.config.url.endsWith(getLogoutSessionRoute())) {
                //avoid infinite loop ou log off
                sessionState.logout();
              }
            }
          }
          return Promise.reject(error);
        }
      );

      setDidMount(true);
    }
  }, [
    didMount,
    getSessionCheckRoute,
    saveRefreshToken,
    sessionCheck,
    sessionState,
    getLogoutSessionRoute,
    getCustomAccountSignInRoute,
    appState,
  ]);

  if (!didMount) {
    return <></>;
  }

  const value = {};

  //todo custom loading
  if (!didMount) {
    console.log("Passing on loading AxiosInterceptCont");
    return <div>loading..</div>;
  }

  return (
    <AxiosIntercepterContext.Provider value={value}>
      {didMount && children}
    </AxiosIntercepterContext.Provider>
  );
}
