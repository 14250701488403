import { IonIcon, IonItem, IonList, IonListHeader, useIonAlert, useIonPopover } from "@ionic/react";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import ToolbarLangSwitcher from "../ToolbarLangSwitcher/ToolbarLangSwitcher";
import styles from "./ToolbarAuthenticatedWorkerPopover.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { refreshCircleSharp, swapHorizontalSharp } from "ionicons/icons";
type Props = {
  onHide: any;
  onLogOff: any;
  onSwitchEntity: any;
  getSessionState: any;
};

const ToolbarAuthenticatedWorkerPopover: React.FC<{
  onHide: () => void;
  onLogOff: () => any;
  onSwitchEntity: () => any;
  getSessionState: () => any;
}> = ({ onHide, onLogOff, onSwitchEntity, getSessionState }: Props) => {
  const [present] = useIonAlert();
  const {t} = useHxfTranslation();
  const [presentAlert] = useIonAlert();
  const {i18n} = useTranslation();

  const [presentLangSwitch, dismissLangSwitch] = useIonPopover(ToolbarLangSwitcher, {
    onHide: () => dismissLangSwitch(),
    getSessionState: () => {
      return getSessionState();
    },
  });
  const { state, setState, stuff, setStuff, dothis, sessionState } = useGlobalState();
  const getLoggedInPropername = () => {
    let objSess = getSessionState();
    return objSess.userSessionData.workerName;
  };

  const myLocalStorage = useLocalStorageHandler();
  const hideSignOffBtn = () => {
    let objSess = getSessionState();
    return objSess?.userSessionData?.hideSignOffWorkstation === 1;
  }

  const showAuthenticatedAs = () => {
    let objSess = getSessionState();
    return (
      objSess.userSessionData?.Id_Worker &&
      objSess.userSessionData.Id_Worker !== 0 
    );
  };


  return (
    <IonList>
      <IonListHeader>
          <div>
            {t('connected.to')} <b>{getSessionState().userSessionData?.entityName}</b> 
          </div>
          <IonItem
          lines="none"
          detail={false}
          button
          onClick={(e) => {
          
            presentLangSwitch({
                event: e.nativeEvent,
              });
            onHide();
          }}
        >
      <div className={styles.toolbarElement}>
                  <div
                    onClick={(e) => {
             
                    }}
                    className={styles.toolbarLangSwitcher}
                  >
                    <div className={`${styles.toolbarLangSection}`}>
                      <div>
                        <div>
                        <img src={"/assets/images/flags/" + i18n.language + ".png"} alt="lang flag" />
                        
                        </div>
                      </div>
                    </div>
                    <div className={styles.langLabel}>{t(i18n.language)}</div>
                    
                    <KeyboardArrowDownIcon />
                  </div>
                </div>
      </IonItem>
      </IonListHeader>

      <IonItem
          lines="none"
          detail={false}
          button
          onClick={() => {
           
            presentAlert({
                cssClass: "my-css",
                header: t('reload.app'),
                message: t("are.you.sure.reload"),
                buttons: [
                  t("cancel"),
                  {
                    text: t("yes"),
                    handler: (d) => {
          
                      window.location.reload();
                    },
                  },
                ],
                onDidDismiss: (e) => {},
              });
            onHide();
          }}
        >
            <IonIcon
                icon={refreshCircleSharp}
                className={`${styles.iconLeftContainer}`}
              />
              
              <div>{t('reload.app')}</div> 
        </IonItem>

      
      <IonItem
          lines="none"
          detail={false}
          button
          onClick={() => {
        
            presentAlert({
                cssClass: "my-css",
                header: t('exit.worker'),
                message: t("are.you.sure.exit.from",{workername:getSessionState().userSessionData?.workerName}),
                buttons: [
                  t("cancel"),
                  {
                    text: t("yes"),
                    handler: (d) => {
                      myLocalStorage.clearWorkerIdentificationCode();
                      window.location.reload();
                    },
                  },
                ],
                onDidDismiss: (e) => {},
              });
            onHide();
          }}
        >
            <IonIcon
                icon={swapHorizontalSharp}
                className={`${styles.iconLeftContainer}`}
              />
              
              <div>{t('switch.worker')}</div> 
        </IonItem>

    </IonList>
  );
};

export default ToolbarAuthenticatedWorkerPopover;
