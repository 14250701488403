import createStore from "../../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IActionData {
  totalReservedSerials: any;
}

interface IActionInput {
  type: any;
  payload?: any;
}

const initialState = {
    totalReservedSerials:null
};

const reducer = (
  store: IActionData,
  action: IActionInput
) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    case "SET_TOTAL_RESERVED_SERIALS":
      store = { ...store, totalReservedSerials: action.payload };
      return store;

    default:
      return store;
  }
};

export const [
  useActionScreenRegisterProductSerializationStateStore,
  dispatchActionScreenRegisterProductSerializationStateStore,
] = createStore(initialState, reducer);
