import WSPopup from "../../../CustomElements/WSPopup/WSPopup";
import EditPrMatchingFixedVariablesView from "./views/EditPrMatchingFixedVariables/EditPrMatchingFixedVariablesView";

interface IProps{
    Id_ProductionRecord:any;
    editView:"EDITPR_MATCHING_FIXED_VARIABLES";
    onClose:any;
}

function EditProductionRecordElement(props:IProps){


    if(props.editView === "EDITPR_MATCHING_FIXED_VARIABLES"){
        return (
        <>
            <EditPrMatchingFixedVariablesView Id_ProductionRecord={props.Id_ProductionRecord} onClose={() => {
                props.onClose();
            }}/>
        </>)
    }
    return (<></>)
}

export default  EditProductionRecordElement;