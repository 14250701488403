import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import useWorkerMode from "../../../barrel/hooks/useWorkerMode";
import styles from "./ToolbarWorkersMode.module.scss";
function ToolbarWorkersMode(){
   

    const {currentWorkerMode} = useWorkerMode();

    const isSingleMode = () => {
        let isSingle = currentWorkerMode === "SMW_MODE_SINGLE";
        return isSingle;
    };

    return (
        <div className={styles.toolbarBtn}>
        <div
        onClick={(e) => {

        }}
        className={styles.toolbarWorkersMode}
        >
        <div className={`${styles.toolbarLangSection}`}>
            <div>
            <div>
                {isSingleMode() ? ( <PersonIcon /> ) : (    <PeopleIcon />)}
             
            </div>
            </div>
        </div>

        </div>
    </div>
    )
}

export default ToolbarWorkersMode;