function entityUsersValidations() {
  const responseSignInCheckIncorrectDetails = (error) => {
    let expectedError = "login.incorrectDetails";
    let respData = error?.response?.data

    if(!respData){
      return false;
    }

    if (
      error.response.status === 401 &&
      respData !== null &&
      respData.issues !== null &&
      respData.response.issues.errors !== null
    ) {
      let errorsArr = respData.response.issues.errors;
      console.log("errosARgg", errorsArr);
      if (errorsArr.includes(expectedError)) {
        return expectedError;
      }
    }
    return false;
  };

  const responseSuccessLogin = (response) => {};

  return { responseSignInCheckIncorrectDetails };
}

export default entityUsersValidations;
