import styles from "./ReloadButton.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline, printOutline } from "ionicons/icons";


function ReloadButton(){


    return (
        <div
        className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
      >
        <div>
          <div className={styles.container}>
            <div className={styles.buttonsContainer}>
              <div className={`${styles.customConfirmationContainer} `}>
  
            
                <div className={`${styles.customConfirmationContainer} ${styles.continueBtn} `}>
                  <IonButton
                        size={"large"}
                      style={{width:'100%'}}
                      className={styles.confirmButton}
                      onClick={() => {
                        localStorage.removeItem("lasttimeUpdateDetected");
                        window.location.reload();
                      }}
                    >
                    
                      Reload
                  </IonButton>
                </div>
             

            </div>
          </div>
        </div>
        </div>
      </div>
    )
}

export default ReloadButton;