import { useEffect, useRef, useState } from "react";
import MobileWrapperCommunicationService from "../../barrel/services/mobileWrapperCommunicationService";
import ScannablesService from "../../barrel/services/ScannablesService";
import SystemSoundService from "../../barrel/services/systemSoundService";
import ZebraService from "../../barrel/services/zebraService";

export interface IHxfBarcodeScanFormat {
  type: "barcode128" | "barcode39" | "qrcode" | "unspecified";
  pattern: string | "[]"; //if pattern string is "[]", all patterns are accepted


  _skipMatchFindDelimiterProcessing?:boolean; //usefull when barcode scanners also scan qrcodes
}
export interface IHxfBarcodeSettings {
  useCamera: boolean;
  useExternalDevice: boolean;
  allowedScanFormats: IHxfBarcodeScanFormat[];
  allowAllFormats?:boolean; //ignores allowedScanFormats and validation always returns true
  initCameraOnStartUp?: boolean;
}

export interface IHxfBarcodeScanResult {
  resultFormat: IHxfBarcodeScanFormat | null;
  resultScan: string | null;
}

interface IProps {
  settings: IHxfBarcodeSettings;
  onFinishedScan(result: IHxfBarcodeScanResult): void;
  onClosedCamera?: any; //ios or android canceled scan
  onInvalidScan?:any;
  freeScannerMode?:boolean; //does not use SFSNR + delimiter to validate, accepts everything
}

function HxfBarcodeScanner(props: IProps) {
  const SMART_AUTO_PREFIX_CLEARER_SFS_OFFICIAL_BARCODES = true; //if the physical barcode scanner adds prefix chars like: "[Q1SFS02-123-123", it ignores everything before SFS02-
  const [didMount, setDidMount] = useState(false);
  const timerCheckScanMobile = useRef<any>(null);
  const finishScan = (
    scannedFormat: IHxfBarcodeScanFormat,
    textScan: string
  ) => {
    SystemSoundService().scannedBeepSound();
    textScan = ZebraService().clearFillingCharacter(textScan);
    let result: IHxfBarcodeScanResult = {
      resultFormat: scannedFormat,
      resultScan: textScan,
    };
    props.onFinishedScan(result);
    detectedPossibleScan.current = "";
  };

  const getProperDelimiter = (hxfBarcodeType: string) => {
    let delimiter = null;
    if (hxfBarcodeType === "barcode39") {
      delimiter = ScannablesService().hxfBarcode39Delimiter;
    }else if (hxfBarcodeType === "barcode128"){
      delimiter = ScannablesService().hxfBarcode128Delimiter;
    } else if (hxfBarcodeType === "qrcode") {
      delimiter = ScannablesService().hxfQrcodeDelimiter;
    }

    if (delimiter === null) {
      throw "hxf, delimiter not implemented for this scan type";
    }
    return delimiter;
  };

  const validateScannedBarcodeCamera = (
    scannedResultDetected: string,
    barcodeType: string | null,// null for physical scanners, as we cannot know the type
  ) => {
    if(!props.settings.allowedScanFormats){
      return null;
    }
    let skipTypeValidation = barcodeType === null;
    for (let i = 0; i < props.settings.allowedScanFormats.length; i++) {
      //for each possible format, get the delimiter character and check if the count matches
      let type = props.settings.allowedScanFormats[i].type;
      let pattern = props.settings.allowedScanFormats[i].pattern;
      if (skipTypeValidation || type === barcodeType) {
        let delimiter = getProperDelimiter(type);

        let totalDelimiterOccurrencesOnDetection =
          scannedResultDetected.split(delimiter).length - 1;

        let totalDelimiterOccurencesOnPattern =
          pattern.split(delimiter).length - 1;

        if (
          totalDelimiterOccurencesOnPattern ===
          totalDelimiterOccurrencesOnDetection
        ) {
          
          if(!props.freeScannerMode){
            //NEW validation method
            //check if starts with SFSNR + delimiter
            
            let sfsScannableIdentifier = null;
            if(totalDelimiterOccurencesOnPattern > 0){
              let splittedPattern = pattern.split(delimiter);
              if(splittedPattern[0]){
                sfsScannableIdentifier = splittedPattern[0];
              }
            }

            let sfsScannedIdentifier = null;
            if(totalDelimiterOccurrencesOnDetection > 0){
              let splittedResult = scannedResultDetected.split(delimiter);
              if(splittedResult[0]){
                sfsScannedIdentifier = splittedResult[0];
              }
            }

            if(sfsScannedIdentifier && sfsScannedIdentifier){ //to guarantee they have the same delimiter options
              if(sfsScannedIdentifier === sfsScannableIdentifier){
                return props.settings.allowedScanFormats[i];
              }
            }



          }else{
            return props.settings.allowedScanFormats[i];
          }
          
        }
      }
    }

    return null;
  };

  //check if scanned barcode is in one of the expected scan formats
  const validateScannedBarcodeExternalDevice = (
    scannedResultDetected: string
  ) => {
    //external devices do not return what type of scan they are delivering
    //so if accepting multiple external devices barcode types through different devices they must accept the same delimiter character or one barcode might be mistaken by another pattern
    //which is not a big problem, it will just return the wrong "type", unless different barcodes have different elements on the same positions. Ex: if it allows 2 barcode types with a pattern that contains 3 elements, if the first element is a productionorder id in one barcode type it must be the first in all barcode types (for external devices), this also must happen in the successive elements of other positions

    //issue doesnt exist with the camera as the camera returns the type of barcode detected.

    return validateScannedBarcodeCamera(scannedResultDetected, null);
    /*if(!props?.settings?.allowedScanFormats){
      return null;
    }
    for (let i = 0; i < props.settings.allowedScanFormats.length; i++) {
      //for each possible format, get the delimiter character and check if the count matches
      let type = props.settings.allowedScanFormats[i].type;
      let pattern = props.settings.allowedScanFormats[i].pattern;
      let delimiter = getProperDelimiter(type);

      let totalDelimiterOccurrencesOnDetection =
        scannedResultDetected.split(delimiter).length - 1;

      let totalDelimiterOccurencesOnPattern =
        pattern.split(delimiter).length - 1;
      if (
        totalDelimiterOccurencesOnPattern ===
        totalDelimiterOccurrencesOnDetection
      ) {
        return props.settings.allowedScanFormats[i];
      }
    }

    return null;*/
  };

  /**
   * 
   * @param inputText scanned barcode, if it contains prefix characters like ]Q1 etc from some scanners, it gnore sthat part
   * @returns 
   */
  const smartSFSBarcodesAutoPrefixCleaner = (inputText:any) => {

    let allDelimiters = ScannablesService().getArrayAllDelimiters();
    for(let i = 0; i<allDelimiters.length; i++){
      let delimiter = allDelimiters[i];
          // Escape the delimiter to ensure it is treated as a literal in the regular expression
      const escapedDelimiter = delimiter.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      // Define the regular expression to match the barcode pattern
  
      const barcodePattern = new RegExp(`SFS\\d+${escapedDelimiter}`);
      // Find the position of the barcode in the input text
      const match = inputText.match(barcodePattern);

      // If a match is found, return the substring from the match position onwards
      if (match) {
        return inputText.substring(match.index);
      }
    }


    // If no match is found, return the original input text
    return inputText;
  }


  /**
   * 
   * @param str checks if starts with SFSnumber + "delimiter"
   * @param delimiter 
   * @returns 
   */
  function isOfficialSFSGeneratedBarcode(str:any, delimiter:any) {
    // Escape the delimiter to ensure it is treated as a literal in the regular expression
    const escapedDelimiter = delimiter.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    
    // Construct the regular expression pattern dynamically
    const pattern = new RegExp(`^SFS\\d+${escapedDelimiter}`);
    
    // Test the string against the pattern
    return pattern.test(str);
  }

  const initiateCustomListenerForMobile = () => {
  
    timerCheckScanMobile.current = setTimeout(() => {
      let valFound = localStorage.getItem("webcom_rn");
    
      if (!valFound || valFound === "") {
        initiateCustomListenerForMobile();
      } else {
        try {
          let receivedMessage = JSON.parse(valFound);
          let action = receivedMessage.action;
          let payload = receivedMessage.payload;

          if (action === "wrapcom_returned_barcodescan") {
            clearTimeout(timerCheckScanMobile.current);

            payload.resultScan = ZebraService().clearFillingCharacter(payload.resultScan);
            //alert('RECEIVED SCAN RESULT!');
            //alert('barcode type:' + payload.barcodeType + " res scan: " + payload.resultScan);
            //todo handle this similar to the external like this:
 

            if(props.settings?.allowAllFormats){
              let unspecifiedFormat:IHxfBarcodeScanFormat = {type:"unspecified",pattern:"[]"};
              finishScan(unspecifiedFormat, payload.resultScan);
            }else{
             
                let scannedFormat: IHxfBarcodeScanFormat | null =
                validateScannedBarcodeCamera(
                  payload.resultScan,
                  payload.barcodeType
                );
              
                if (scannedFormat) {
                 
                  finishScan(scannedFormat, payload.resultScan);
                }else{
                  if(props?.onInvalidScan){
                    props.onInvalidScan();
                  }
                  initiateCustomListenerForMobile();
                }
            }

            detectedPossibleScan.current = "";
          }

          if (action === "wrapcom_closed_camera") {
            props.onClosedCamera();
          }
          if(action === "wrapcom_returned_barcodescan"){
            props.onClosedCamera();
          }
        } catch (exception) {
     
          alert("critical error occured when trying to read rn message");
          props.onClosedCamera();
          //initiateCustomListenerForMobile();
        }

        localStorage.setItem("webcom_rn", "");
      }
    }, 250);
  };
  //scanner detector functionality auto type
  const detectedPossibleScan = useRef("");
  const timerClearerRef: any = useRef(null);
  useEffect(() => {
    const enabledCameraDevice = props.settings.useCamera;
    const enabledExternalScanners = props.settings.useExternalDevice;
   
    const externalHandlerKeyPressDetect = (e: KeyboardEvent) => {
      // console.log('key detected', e);
      let keyTyped: any = e.key;

      if (keyTyped !== "Enter" && keyTyped !== "Shift") {
        detectedPossibleScan.current =
          detectedPossibleScan.current + "" + keyTyped;
        console.log("Setting detectedPoss:", detectedPossibleScan.current);
        if (timerClearerRef?.current) {
          clearTimeout(timerClearerRef.current);
        }
        timerClearerRef.current = setTimeout(() => {
          detectedPossibleScan.current = "";
          //setInsertedNumber("");
          console.log("Cleared detected poss");
        }, 250);
      }

      if (
        keyTyped === "Enter" &&
        detectedPossibleScan?.current &&
        detectedPossibleScan?.current !== ""
      ) {
        clearTimeout(timerClearerRef.current);
        console.log("Confirming detected poss: ", detectedPossibleScan.current);

        if(SMART_AUTO_PREFIX_CLEARER_SFS_OFFICIAL_BARCODES){ //if some company requires it we turn this into a flag and only allow the specific format instead, set that flag = true for all previous companies in a hotfix.
          detectedPossibleScan.current = smartSFSBarcodesAutoPrefixCleaner(detectedPossibleScan.current);
          console.log("CLEARED : ", detectedPossibleScan.current);
        }
        
        if(props.settings.allowAllFormats){
          let unspecifiedFormat:IHxfBarcodeScanFormat = {type:"unspecified",pattern:""};
          finishScan(unspecifiedFormat, detectedPossibleScan.current);
        }else{
          let scannedFormat: IHxfBarcodeScanFormat | null =
          validateScannedBarcodeExternalDevice(detectedPossibleScan.current);

          
          if (scannedFormat) {
            finishScan(scannedFormat, detectedPossibleScan.current);
          }
        }

        detectedPossibleScan.current = "";
      }
    };

    if (enabledCameraDevice) {
      
      //send event to open camera
      if (props.settings?.initCameraOnStartUp) {

          MobileWrapperCommunicationService().notifyWaitingForCameraScan(
            props.settings.allowedScanFormats
          );
    

      }

      //todo add event listener for scan result

      //has to be "message" since its the event react native sends

      initiateCustomListenerForMobile();
      //workaround using localstorage to avoid issues in iOS vs android

      /*if (navigator.appVersion.includes('Android')) {
                document.addEventListener("message", function (data) {
                    alert("you are in android OS");
                  });
              }
              else {
                window.addEventListener("message", function (data) {
                    alert("you are in android OS");
                });
              }*/
    }
    if (enabledExternalScanners) {
      console.log("[HxfBarcodeScanner] enabled scanenrs on");
      window.addEventListener("keydown", externalHandlerKeyPressDetect, false);
    }

    return () => {
      console.log("[HxfBarcodeScanner] cleanup clearing events");
      if (enabledExternalScanners) {
        window.removeEventListener(
          "keydown",
          externalHandlerKeyPressDetect,
          false
        );
      }

      if (enabledCameraDevice) {
        //clear listener
        localStorage.setItem("webcom_rn", "");
        clearTimeout(timerCheckScanMobile.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!didMount) {
      localStorage.setItem("webcom_rn", "");
      setDidMount(true);
    }
  }, [didMount]);
  return <></>;
}

export default HxfBarcodeScanner;
