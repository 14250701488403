import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function entityController() {

  const codeConnect = (entityCode) => {

    if(process.env.REACT_APP_DEV_CODE_CONNECT === "true" || process.env.REACT_APP_MODE === "dev"){
        return BarrelWrapper().simplePostRequest(
          process.env.REACT_APP_API_SERVE_DEV + "entity/dev-code-connect",
          { entityCode: entityCode },
          { withCredentials: false }
        );
    }    
    return BarrelWrapper().simplePostRequest(
      process.env.REACT_APP_CENTRAL_API_SERVE +
        "shopfloor-studio/public/fetch-entity",
      { entityCode: entityCode },
      { withCredentials: false }
    );
  };

  const getAccessibleUserEntities = () => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "entity-user/accessible-entities",
      {}
    );
  };

  return { codeConnect, getAccessibleUserEntities };
}

export default entityController;
