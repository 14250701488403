import CustomArrowBack from "../CustomArrowBack/CustomArrowBack";

interface IProps {
  titleElement: any;
  arrowBackUrl: string;
}

function CustomFeatureTitle(props: IProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <CustomArrowBack pushUrl={props.arrowBackUrl} />
      </div>

      <div style={{ margin: "auto" }}>{props.titleElement}</div>
    </div>
  );
}

export default CustomFeatureTitle;
