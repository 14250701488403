import { IonButton, IonIcon } from "@ionic/react";
import SimpleContentPopup from "../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./WSPopup.module.scss";
import { closeOutline } from "ionicons/icons";

interface IProps{
    title:any;
    onClose?:any;
    infoBarsContent?:any;
    confirmationContentArea?:any;
    content?:any;
}
function WSPopup(props:IProps){


    return (<>
    
    <SimpleContentPopup left={"0xp"}>
        <div>

          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClose) {
                    props.onClose();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            {props?.infoBarsContent && (
                <>{props.infoBarsContent}</>
            )}
            {/*<div className={styles.selectedProductInfo}>
              <div className={styles.subBarInfo}>
                <div>
                  <div>
                  ({props.printableLabels[currentPrintableLabelIndex].code}) {props.printableLabels[currentPrintableLabelIndex].name} 
                  </div>
                  <div style={{display:'flex', justifyContent:'center',fontSize:'29px'}}>
                    ({currentPrintableLabelIndex + 1}/{props.printableLabels.length})
                  </div>
                </div>
    
               
              </div>
            </div>*/}
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
                {props?.content ? props.content : ""}
                
            </div>
          </div>

     
            {props?.confirmationContentArea && (
                <>{props.confirmationContentArea}</>
            )}
        
        </div>
      </SimpleContentPopup>
    </>)
}

export default WSPopup;