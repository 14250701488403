import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function scannerBridgeController() {
  const getScannedProductionRecord = (
    options: any = { Id_ProductionRecord: null }
  ) => {
    let requestUrl = "scanner-bridge/production-record";

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      { Id_ProductionRecord: options?.Id_ProductionRecord }
    );
  };

  return { getScannedProductionRecord };
}

export default scannerBridgeController;
