import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

interface IDirectPdfViewOptions{
    Id_ProductionRecord?:any;
    Id_ProductionOrder?:any;
    Id_Label?:any;
    Id_ProductionOrder_Product?:any;
    Id_SerializedProduct?:any;
}


function labelsController() {
  const getDirectViewToken = (options:IDirectPdfViewOptions = {}) => {
    let requestUrl = "label/get-label-direct-view-token";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl, {...options}
    );
  };

  const generateZpl = (options:IDirectPdfViewOptions = {}) => {
    let requestUrl = "label/generate-zpl";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl, {...options}
    );
  }

  return { getDirectViewToken,generateZpl };
}

export default labelsController;


//