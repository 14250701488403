import styles from "./SimpleContentPopup.module.scss";

interface IProps{
  dataPopupContainerValue?:any;
  style?:any;
  mode?:"INNER"; //overlay doesnt cover the whole page, position popup accordingly
}

//deprecated use HxfIonPopup
function SimpleContentPopup(props: any) {


  const getCustomStyle = () =>{
    
    if(!props?.style){
      return {};
    }
    return {...props.style};
  }

  return (
    <>
      <div
        data-popup-container={props?.dataPopupContainerValue ? props.dataPopupContainerValue : null}
        style={{position:'fixed',left:'0px',...getCustomStyle()}}
        className={`${styles.popupWindow}  ${props?.mode !== "INNER" ? styles.topAdjustment : styles.topAdjustmentInner} ${
          props.className ? props.className : ""
        }`}
      >
        {props.children}
      </div>
      <div className={styles.popupPage}></div>
    </>
  );
}

export default SimpleContentPopup;
