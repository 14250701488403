import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, useIonAlert, useIonToast } from "@ionic/react";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { Backdrop, CircularProgress } from "@mui/material";
import { closeOutline, hammerOutline, searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import productionController from "../../../../../barrel/controllers/productionController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomSearchBar from "../../../CustomSearchBar/CustomSearchBar";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import styles from "./OnGoingProductionsPopup.module.scss";
import OnGoingProductionsPopupConfirmationButtons from "./OnGoingProductionsPopupConfirmationButtons";
import DoneAllIcon from '@mui/icons-material/DoneAll';
interface IProps{
    onClose?:any;
    filters?:any;
    doNotReloadOnStartup?:boolean;
    defaultOnGoingPrsData?:any;
    onOperationSelectStart?:any;
    hideCollabRecords?:boolean;
}

function OnGoingProductionsPopup(props: any) {
  const selectFirstByDefault = true;
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "ProductionStartStage",
  });
  const { sessionState } = useGlobalState();
  const {t} = useHxfTranslation();
  const myLocalStorage = useLocalStorageHandler();
  const [searchbarInput, setSearchbarInput] = useState("");
  const [selectedProduction, setSelectedProduction] = useState<any>(null);
  const [checkedMinimized, setCheckedMinimized] = useState(false);
  const [hideCancelAllButton, setHideCancelAllButton] = useState(true); //for now decided to hide cancel all button, but it is working as intended.
  const [enabledCancelAllButton, setEnabledCancelAllButton] = useState(true);
  //enable popup with:  dispatchCustomPopupsStore({type:"SET_STORE",payload:{...popupsStore, onGoingProductionsPopup:true}});
  //on close, clear state and clear popup data
  const [didMount, setDidMount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const feedbackService = useFeedbackService();

  const [onGoingPrs, setOnGoingPrs] = useState<any>(props?.defaultOnGoingPrsData ? props.defaultOnGoingPrsData : []);

  const isParentCollaborationPr = (row:any) => {
    let myWorkerId = sessionState.userSessionData.Id_Worker;
    return  parseInt(row.Id_Worker) !== parseInt(myWorkerId) && row?.collabParentId_ProductionRecord === null;
  }

  const getPrInfoElement = (row:any) => {

    return (
      <>
            {isParentCollaborationPr(row) && (
              <div className={styles.cornerIconBtn}>
                  <div className={styles.cornerIconContainer}>
                    <ConnectWithoutContactIcon/>
                  </div>
                  
              </div>
            )}

            <IonCardSubtitle>
            {shouldShowRef() && (
                  <div className={styles.elemElementTitle}>
                    <b>{t("ref")}:</b> {row.ref}
                  </div>
            )}
            {row?._collabParentRef && (
              <div className={styles.elemElementTitle}>
                <b>{t("ref")}:</b> {row._collabParentRef}
              </div>
            )}
            <div className={styles.elemElementTitle}>
            <b>{t("order")}:</b> {row.productionOrderData.name}
            </div>
            <div className={styles.elemElementTitle}>
            <b>{t("product")}:</b> {row.productData.name}
            </div>
            <div className={styles.elemElementTitle}>
            <b>{t("operation")}:</b> {row.operationProductData.name}
            </div>
          </IonCardSubtitle>
      </>

    )
  }

  const shouldShowRef = useCallback(() => {
    let curPrs = onGoingPrs;

    for(let i = 0; i<curPrs.length; i++){

      let curPrRef = curPrs[i].ref;
      if(curPrRef !== null && curPrRef !== ""){
        return true;
      }
      
    }

    return false;
   },[onGoingPrs]);

   const reloadOnGoing = () => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    setIsLoading(true);
    let filters = props?.filters;
    productionController().getOnGoingProductionRecords({workerCode:currentWorkerCode,...filters}).then((res) => {
      setIsLoading(false);

      let onGoingProductionRecords = res?.data?.onGoingProductionRecords;
      if(!onGoingProductionRecords){
        throw "unexpected response onGoingProductionRecords";
      }
   
      setOnGoingPrs(onGoingProductionRecords);

      if(onGoingProductionRecords.length === 1){
        setTimeout( () => {
          setSelectedProduction(null);
          setCheckedMinimized(true);
        },200);
      }

    }).catch((res) => {
      setIsLoading(false);
      feedbackService.notifyToast(t('generic.critical.error'), "error");
      closeMe();
    });
   }


  const getRelevantOnGoingPrs = useCallback(() => {
    let curPrs = onGoingPrs ? onGoingPrs : [];
    let relevantPrs = [];
   
    for(let i = 0; i<curPrs.length; i++){

      let curPrRef = curPrs[i].ref;
   
        let lowercaseInput = searchbarInput ? searchbarInput.toLowerCase() : "";

        if(lowercaseInput == "" || (curPrRef && curPrRef.toLowerCase().includes(lowercaseInput))){
  
          
          let isChildPrCollab = curPrs[i]?.collabParentId_ProductionRecord ? true : false;
          if(!props?.hideCollabRecords || (props?.hideCollabRecords && !isChildPrCollab)){
         
              relevantPrs.push(curPrs[i]);
            
              
          }
         
        }
      

     
    }
   
    if(relevantPrs.length > 0){

      relevantPrs.sort((a:any, b:any) => b.Id - a.Id);
      // Sort the array by the 'ref' property in descending order
      /*relevantPrs.sort((a, b) => {
        // Use localeCompare for string comparison

        if(!a?.ref){
          a.ref = "";
        }
        if(!b?.ref){
          b.ref = "";
        }
        return b.ref.localeCompare(a.ref);
      });*/
    }

    return relevantPrs;
  },[onGoingPrs,searchbarInput]);

  const closeMe = () => {
    setOnGoingPrs([]);
    setSelectedProduction(null);
    setSearchbarInput("");


    if(props?.onClose){
        props.onClose();
    }

  }

  useEffect(() => {
    if (!didMount) {
      console.log("[OnGoingProductionsPopup mounted]");
      if(!props?.doNotReloadOnStartup){
        reloadOnGoing();
      }
       
      setDidMount(true);
    }
  }, [didMount]);







  if(isLoading){
    return (<>
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={() => {
  
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      </>);
  }
  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t("pending.minimized.productions")}</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                let redirectTo = null;
                closeMe();
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>


          <div className={`${styles.content} ${getRelevantOnGoingPrs().length === 0 && searchbarInput === "" ? styles.hasNoRecords : styles.hasRecords}`}>
            {shouldShowRef() && (
            <div style={{margin:10}}>

            <CustomSearchBar
              value={searchbarInput}
              onResetTextClick={() => {
                setSearchbarInput("");
              }}
              onChange={(evt:any) => {
              
                setSearchbarInput(evt.target.value);
              }}
              iconElement={
                <div className={styles.searchIconStyles}>
                  <IonIcon icon={searchCircleOutline} />
                </div>
              }
              placeholder={t('search.ref')}
            />
            </div>
            )}

            <div className={styles.activeProductionsContainer}>
              <div className={styles.activeProductionsSection}>
                {getRelevantOnGoingPrs().length === 0 && searchbarInput !== "" && (
                  <>
                  <div style={{fontSize:30, marginTop:30,color:"#37333396"}}>{t('no.available.record')}</div>
                  </>
                )}
                {getRelevantOnGoingPrs().length === 0  && searchbarInput === "" && (
                    <div>
                    <div style={{fontSize:30, marginTop:30,color:"#37333396"}}>{t('no.available.record')}</div>
                    <div style={{display:'flex',justifyContent:'center',color:"#37333396",marginTop:10}}><DoneAllIcon fontSize="large"/></div>
                    </div>
                )}
                {getRelevantOnGoingPrs().map(
                  (row: any, index: number) => (
                    <div
                      key={"onGoingProd_" + index}
                      className={`${styles.activeProductionElement}  `}
                    >
                      <IonCardHeader
                        data-minimized-index={index}
                        className={`${styles.elemElement}
                        ${isParentCollaborationPr(row) ? styles.elemElementCollab : styles.elemElementMyPr}                        
                        ${
                          row.Id === selectedProduction
                            ? styles.selectedActiveProductionElement
                            : ""
                        }`}
                        onClick={() => {
                          if (row.Id === selectedProduction) {
                            setSelectedProduction(null);
                          } else {
                            setSelectedProduction(row.Id);
                          }
                        }}
                      >
                        <div className={styles.elemBackgroundContainer}>
                          
                        <IonIcon aria-hidden={true} className={styles.iconElement} slot="icon-only" size="large" icon={hammerOutline} />
                        </div>
                        {getPrInfoElement(row)}
                        <IonCardTitle className={styles.elemElementDescription}>
                        {row?.productionRecordDateStart && (
                          <>
                          {DatesConversionService().tableFormattedDateTimeLabel(
                            row.dateStart
                          )}
                          </>
                        )}

                        </IonCardTitle>
                      </IonCardHeader>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <OnGoingProductionsPopupConfirmationButtons
          enabled={selectedProduction !== null}
          onClickConfirm={() => {
            let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();

            let prObj = null;

            for(let i = 0; i<onGoingPrs.length; i++){
              if(onGoingPrs[i].Id === selectedProduction){
                prObj = onGoingPrs[i];
                break;
              }
            }
        
            if(!prObj){
              return;
            }
       
          
            let parentCollabPr = null;
            
            let isWorkerPrOwner = parseInt(prObj?.Id_Worker) === parseInt(sessionState?.userSessionData?.Id_Worker);
            let isACollabChild = prObj?.collabParentId_ProductionRecord ? true : false;

            let allowOperationPrCollab = parseInt(prObj?.operationProductData?.allowOperationPrCollab) === 1;


            if(isACollabChild){
                for(let i = 0;i<onGoingPrs.length; i++){
                    if(onGoingPrs[i]?.Id === prObj?.collabParentId_ProductionRecord){
                        parentCollabPr = onGoingPrs[i];
                        break;
                    }
                }
            }


            if(isACollabChild && !allowOperationPrCollab){
                feedbackService.notifyToast(t('collaboration.work.feature.disabled'),"error");
                return;
            }

            let isCollabAParent = !isACollabChild && allowOperationPrCollab;
            let childCollabPr = null;
            if(isCollabAParent){
              for(let i = 0;i<onGoingPrs.length; i++){
                if(onGoingPrs[i]?.collabParentId_ProductionRecord === prObj?.Id){
                  childCollabPr = onGoingPrs[i];
                }
              }
            }

            if(isWorkerPrOwner && !isACollabChild){
                props.history.push({
                    pathname:
                      "/feature/production-order/" +
                      prObj.Id_ProductionOrder +
                      "/po-product/" +
                      prObj.Id_ProductionOrder_Product +
                      "/production/" +
                      prObj.Id,
                  });
                  closeMe();
            }else{
                if(allowOperationPrCollab){

                    //!isACollabChild to ignore creating a new if ongoingpopup is not hiding child collab records
                    if(!childCollabPr && !isACollabChild && !parentCollabPr){  //if authenticated worker has no pr child going on, associated with this "parent" then create a new child otherwise resume it

                        //create a child for the parent
                        props?.onOperationSelectStart({isNewCollabWork:true, _new_collabParentId_ProductionRecord:prObj.Id});
                    }else{

                      let idPr = childCollabPr ? childCollabPr.Id : prObj.Id;
                        props.history.push({
                            pathname:
                              "/feature/production-order/" +
                              prObj.Id_ProductionOrder +
                              "/po-product/" +
                              prObj.Id_ProductionOrder_Product +
                              "/production/" +
                              idPr,
                          });
                          closeMe();
                    }


                }

            }


          }}
          hideCancelAllButton={hideCancelAllButton}
          enabledCancelAll={enabledCancelAllButton && selectedProduction === null}
          onCancelAllClick={() => {
            present({
              cssClass: "my-css",
              header: t("production.cancellation"),
              message:
              t("minimized.productions.confirm.sure"),
              buttons: [
                t("cancel"),
                {
                  text: t("yes"),
                  handler: (d) => {
                    let currentWorkerCode =
                      myLocalStorage.getWorkerIdentificationCode();
                    setEnabledCancelAllButton(false);
                    productionController()
                      .cancelAllMinimizedProductionRecords(currentWorkerCode)
                      .then((res: any) => {
                        console.log("cancelled successfully");
                        closeMe();

                      })
                      .catch((res: any) => {
                        console.log("failed cancel");
                        setEnabledCancelAllButton(true);
                        presentToast(
                          t("production.cancel.failed"),
                          3000
                        );

                        closeMe();
                      });
                  },
                },
              ],
              onDidDismiss: (e) => {},
            });
          }}
        />
      </div>
    </SimpleContentPopup>
  );
}

export default withRouter(OnGoingProductionsPopup);
