import { IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { downloadOutline, shareOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import ProductionOrdersDao from "../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import useStateCallback from "../../../../barrel/hooks/useStateCallback";
import LoadingSpinnerImg from "../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../../InAppTemplate/InAppTemplate";

import styles from "./MoveStockStandard.module.scss";
function MoveStockStandard(props) {
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);

  const sizePage = 25;
  const [loadedPOS, setLoadedPOS] = useStateCallback(null);
  const loadedPOSRef = useRef(null);
  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const {t} = useHxfTranslation();
  const [activeProductionsButtonData, setActiveProductionsButtonData] =
    useState(null);
  const [simultaneousOperationPopsData, setSimultaneousOperationPopsData] =
    useState(null);
  const [
    activeMultiProductionsButtonData,
    setActiveMultiProductionsButtonData,
  ] = useState(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const loadPageChecks = useCallback(() => {
    setLoadingPage(false);
  }, [setLoadingPage]);

  useEffect(() => {
    if (!didMount) {
      loadPageChecks();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,

    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/home" />
      <div className={styles.pageTitle}>{t('movestock')}</div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
          <IonCardHeader
            className={`${styles.elemElement}`}
            data-btn="pick-btn"
            onClick={() => {
              props.history.push({ pathname: "/feature/move-stock/pick" });
            }}
          >
            <div className={styles.elemBackgroundContainer}>
            <IonIcon
                icon={shareOutline}
                size={"large"}
                className={styles.iconContainer}
              />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>{t('pick.up.material')}</div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t('pick.up')}
            </IonCardTitle>
          </IonCardHeader>

          <IonCardHeader
            className={`${styles.elemElement}`}
            onClick={() => {
              props.history.push({ pathname: "/feature/move-stock/drop" });
            }}
            data-btn="drop-btn"
          >
            <div className={styles.elemBackgroundContainer}>
            <IonIcon
                icon={downloadOutline}
                size={"large"}
                className={styles.iconContainer}
              />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>
                {t('drop.material.moving')}
              </div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t('drop.off')}
            </IonCardTitle>
          </IonCardHeader>
        </div>
      </div>
    </InAppTemplate>
  );
}

export default MoveStockStandard;
