import { useRef, useState } from "react";
import styles from "./CustomSearchBar.module.scss";
import { closeCircle, closeCircleOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";
interface ICustomSearchbar {
  onClick?: any;
  onChange: any;
  value: any;
  placeholder: any;
  iconElement: any;
  onResetTextClick: any;
  dataInputFieldIdentifier?:any;
}
function CustomSearchBar(props: ICustomSearchbar) {
  const ref = useRef<any>(null);
  const [isOnFocus, setIsOnFocus] = useState(false);

  const getPlaceholder = () => {
    if (isOnFocus) {
      return "";
    }
    return props?.placeholder ? props.placeholder : "";
  };

  return (
    <>
      <div className={styles.inputBarContainer}>
        <input
          data-input-field={props?.dataInputFieldIdentifier}
          ref={ref}
          onBlur={() => {
            setIsOnFocus(false);
          }}
          onFocus={() => {
            setIsOnFocus(true);
          }}
          onKeyDown={(event) => {
            if(event.key === 'Enter'){
              if(ref){
                ref.current.blur();
              }
            }
          }}
          onClick={() => {
            if (props?.onClick) {
              props.onClick();
            }
          }}
          onChange={(evt) => {
            if (props?.onChange) {
              props.onChange(evt);
            }
          }}
          placeholder={getPlaceholder()}
          value={props?.value ? props.value : ""}
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "12px",
            borderWidth: "2px",
          }}
          className={styles.inputbar}
          type="text"
        />
        {props?.value === "" && props?.iconElement && (
          <div className={styles.iconContainer}>
            <div className={styles.iconElement}>{props.iconElement}</div>
          </div>
        )}

        {props?.value !== "" && (
          <div className={styles.iconContainer}>
            <div
              className={`${styles.iconElement} ${styles.closeIconElement}`}
              onClick={() => {
                if (props?.onResetTextClick) {
                  props.onResetTextClick();
                }
              }}
            >
              <IonIcon icon={closeCircle} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomSearchBar;
