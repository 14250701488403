import styles from "./OnGoingProductionsPopupConfirmationButtons.module.scss";

import { IonButton } from "@ionic/react";
import { useState } from "react";
import productionController from "../../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onCancelAllClick: any;
  onClickConfirm: any;
  enabled: boolean;
  enabledCancelAll: boolean;
  hideCancelAllButton?: boolean;
}
function OnGoingProductionsPopupConfirmationButtons(props: IProps) {
  const {t} = useHxfTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={styles.confirmationContainer}>
          <div>
            {!props?.hideCancelAllButton && (
              <div>
                <IonButton
                  
                  color="danger"
                  disabled={!props.enabledCancelAll}
                  className={styles.confirmButton}
                  onClick={() => {
                    if (props.onCancelAllClick) {
                      props.onCancelAllClick();
                    }
                  }}
                >
                  {t("cancel.all")}
                </IonButton>
              </div>
            )}

            <div>
              <IonButton
                data-btn={"minimizedPrResume"}
                disabled={!props.enabled}
                className={styles.confirmButton}
                onClick={() => {
                  if (props.onClickConfirm) {
                    props.onClickConfirm();
                  }
                }}
              >
                {t("resume")}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnGoingProductionsPopupConfirmationButtons;
