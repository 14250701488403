import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./DefineInputsDataPopup.module.scss";
import { TextField,Button } from "@mui/material";
import { useEffect, useState } from "react";

interface IDefInput {
    label:string;
    inputType:"TEXTFIELD"
    hasError?:boolean;
    defaultValue?:string;
    typeInputTextMaxLength?:number;
    typeInputTextHelper?:string;
    typeInputTextAutoFocus?:boolean;
}

interface IDefBtn{
    label:string;
    btnStyles?:any;
    labelStyles?:any;
    onClick?:(data: any) => any;
}

interface IPopup {
  onClose: any;

  
  title: string;
  topBarTitle?:string;
  arrayInputs:IDefInput[];
  arrayConfirmationButtons:IDefBtn[];
  mapValuesErrors?:any; //not implemented yet
}

function DefineInputsDataPopup(props: IPopup) {

    const [mapInputsValues, setMapInputsValues] = useState([]);
    const [didMount, setDidMount] = useState(false);

    const buildConfirmData = () => {
        return {mapInputsValues:mapInputsValues};
    }

    const getProperConfirmBtn = (iBtn:IDefBtn, index:number) => {

        let btnStyles:any = {};
        if(iBtn?.btnStyles){
            btnStyles = {...btnStyles, ...iBtn.btnStyles};
        }

        let labelStyles:any = {};
        if(iBtn?.labelStyles){
            labelStyles = {...labelStyles, ...iBtn.labelStyles};
        }
        return (
            <>
              <IonButton
                style={{...btnStyles}}
                className={styles.confirmButton}
                data-btn={"set-location"}
                onClick={() => {
                  if(iBtn?.onClick) {
                    iBtn.onClick(buildConfirmData());
                  }
                }}>
                <div style={{...labelStyles}}>{iBtn.label.toUpperCase()}</div>
              </IonButton>
            </>
        )
    }

    const getProperInput = (iInput:IDefInput, index:number) => {

        let inputPropsData:any = {};
        if(iInput?.typeInputTextMaxLength){
            inputPropsData.maxLength = iInput.typeInputTextMaxLength;
        }

        return (
            <TextField
            autoFocus={iInput.typeInputTextAutoFocus}
            helperText
            inputProps={{...inputPropsData}}
            fullWidth
            error={false}
            onChange={(evt:any) => {
         
                let newDesc = evt.target.value;
                let mapInpts:any = mapInputsValues;
                mapInpts[index] = newDesc;
                setMapInputsValues({...mapInpts});
        
            }}
            value={
                mapInputsValues?.[index] ?  mapInputsValues?.[index] : ""
            }
            label={iInput?.label}
            variant="outlined"
            
            
        />
        )
    }

    useEffect(() => {
        if(!didMount){
            let mappedInputsVals:any = {};
            let arrInputs:IDefInput[] = props.arrayInputs;
            for(let i = 0; i<arrInputs.length; i++){
                mappedInputsVals[i] = arrInputs[i]?.defaultValue ? arrInputs[i]?.defaultValue : null;
            }
            setMapInputsValues(mappedInputsVals);
            setDidMount(true);
        }
    },[didMount]);


    return (
    <div className={styles.intPopup}>
        <SimpleContentPopup>
        <div>
            <div className={styles.container}>
            <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
                <div></div>

                <div className={styles.title}>{props.title}</div>

                <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                    if (props?.onClose) {
                    props.onClose();
                    }
                }}
                >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
            </div>
            {props?.topBarTitle && (
            <div className={styles.barContainerInfo}>
                <div className={styles.barContainerInfoLabels}>
                    {props?.topBarTitle}
                </div>
            </div>
            )}

            <div className={`popup-content-border-sides-only-styles ${styles.content}`}>
                <div className={styles.inputsContainer}>
                    {props?.arrayInputs.map((objInput:IDefInput, index:number) => (
                        <div key={"inputProper_" + index}>
                            {getProperInput(objInput,index)}
                        </div>
                    ))}
                </div>

            </div>
            </div>

            <div className={`${styles.confirmationBottomContainer} popup-bottom-confirmation-border-styles `}>
            <div className={styles.dividerBottom}></div>
            <div className={styles.confirmBtnsContainer}>
                {props.arrayConfirmationButtons.map((objConfirmBtn:IDefBtn, index:number) => (
                    <div key={"confBtn_" + index}>
                        {getProperConfirmBtn(objConfirmBtn, index)}
                    </div>
                ))}
            </div>

            </div>

        </div>
        </SimpleContentPopup>
    </div>
    );
}

export default DefineInputsDataPopup;
