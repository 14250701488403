import { IonButton, IonIcon, IonLoading } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./UnavailabilityActionWindow.module.scss";

interface IProps{
    children:React.ReactNode;
    title:any;
    onCancel:any;
}
function UnavailabilityActionWindow(props:IProps){

    return (

        <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  props.onCancel();
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
          
            {props.children}
           
          </div>
        </div>
      </SimpleContentPopup>
    )
}

export default UnavailabilityActionWindow;