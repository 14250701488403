import { Button } from "@mui/material";
import SomeComponentDemoStoreStateManagementChild from "./someChild/someChild";
import { useCount, dispatchCount } from "./store";
function SomeComponentDemoStoreStateManagement() {
  const count = useCount();

  return (
    <>
      Hello world count: {count}
      <Button
        onClick={() => {
          console.log("HEY");
          dispatchCount({ type: "INCREASE" });
          console.log("SUP");
        }}
      >
        {" "}
        button{" "}
      </Button>
      <div>
        child div:
        <SomeComponentDemoStoreStateManagementChild />
      </div>
    </>
  );
}

export default SomeComponentDemoStoreStateManagement;
