import { useCallback, useEffect, useState } from "react";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenAskUseMachine.module.scss";
import ActionScreenAskUseMachineConfirmationButtons from "./ActionScreenAskUseMachineConfirmationButtons";
import ActionScreenMachineElement from "./ActionScreenMachineElement";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

interface IResult {
  irregularitysList: any;
}

function ActionScreenAskUseMachine(props: any) {
  const [indexkey, setIndexkey] = useState(0);
  const {t} = useHxfTranslation();

  const preConfirm = useActionPreConfirm();
  const currentActionScreenManager = useActionScreenManagerStore();

  const [selectedMachine, setSelectedMachine] = useState({
    temp_name: "",
    temp_code: "",
    Id_Machine: 0,
  });


  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  // states to allow clearing when loading a default stock location config

  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);

  function forceRerender() {
    setIndexkey(indexkey + 1);
  }

  function confirmMachineSelection() {
    return { Id_Machine: selectedMachine.Id_Machine };
  }


  const confirmClick = () => {
    if(!preConfirm.check()){
      return;
    }
    let storeResult = confirmMachineSelection();

    props.callbackSuccessConfirm(storeResult);
  };

  const clickedMachineIndex = useCallback(
    (index: number) => {
      let obj =
        currentActionScreenManager?.currentActionConfig?.machinesList[index];
      setSelectedMachine({
        Id_Machine: obj.Id_Machine,
        temp_name: obj.name,
        temp_code: obj.code,
      });
      setRemountKeypadIndex(remountKeypadIndex + 1);
    },
    [
      remountKeypadIndex,
      currentActionScreenManager?.currentActionConfig?.machinesList,
    ]
  );

  const closeCallback = () => {
    props.callbackOnClose(
      currentActionScreenManager?.currentActionConfig?.machinesList
    );
  };
  function stepConfirm(res: any) {
    /*let storeResult = confirmQuantity(res);
            if(storeResult !== false){
                    //
                    if(storeResult.quantity > 0){
                        let addingIrregularityObj = {...addingIrregularityData, filledIrregularityData:{...addingIrregularityData.filledIrregularityData, quantity: storeResult.quantity}};
                    
                        
                        
                    }
                    
                    setCurrentStep("SELECT_IRREGULARITY");
            } */
  }

  const isEnabledMachineElement = (obj: any) => {
    if (obj.temp_isBusyFull) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!didMount) {
      //mounting

      if (
        currentActionScreenManager?.currentActionConfig?.machinesList
          ?.length === 1 &&
        currentActionScreenManager?.currentActionConfig?.machinesList[0]
          ?.temp_isBusyFull === false
      ) {
        clickedMachineIndex(0);
      }
      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager?.currentActionConfig?.irregularitysList,
    currentActionScreenManager?.currentActionConfig?.machinesList,
    clickedMachineIndex,
  ]);

  useEffect(() => {
    return () => {
      //unmounting
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar callbackOnClose={closeCallback} title="Machines" />

        <div className={styles.content}>
          {currentActionScreenManager?.currentActionConfig?.machinesList.length === 1 ? (<>Confirm you wish to start using this machine</>) : (
            <>{t("machinestart.confirm")}</>
          )}
          
          <div className={`${styles.selectionOuter}`}>
            <div className={styles.selectionContainer}>
              {currentActionScreenManager?.currentActionConfig?.machinesList.map(
                (obj: any, index: number) => (
                  <div key={"machine_" + index}>
                    <ActionScreenMachineElement
                      enabled={isEnabledMachineElement(obj)}
                      selected={obj.Id_Machine === selectedMachine.Id_Machine}
                      name={obj.temp_name}
                      code={obj.temp_code}
                      onClick={() => {
                        clickedMachineIndex(index);
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <ActionScreenAskUseMachineConfirmationButtons
        enabled={selectedMachine.Id_Machine !== 0}
        typeShow={"CONFIRM"}
        onClickConfirm={() => {


          confirmClick();
        }}
      />
    </>
  );
}
export default ActionScreenAskUseMachine;
