import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";
import GenericListJsonService from "../services/genericListJsonService";
function expectedProductsController() {
  const fetch = (
    options: any = {}
  ) => {
    let requestUrl = "expected-products/fetch";//page=" + options["page"] + "&size=" + options["size"]


    /*if (options?.searchingCodeName) {
      requestUrl =
        requestUrl + "&searchingCodeName=" + options.searchingCodeName;
    }

    if (options?.includeParameterizedVariables) {
      requestUrl =
        requestUrl +
        "&includeParameterizedVariables=" +
        options.includeParameterizedVariables;
    }*/

    if(!options){
        options = {};
      }
      return BarrelWrapper().appPostRequest(
        HxfAppServersService().getBackend() +
        requestUrl,
        { ...options }
      );
  };

  return { fetch };
}

export default expectedProductsController;
