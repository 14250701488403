import { IonCardTitle } from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";



import styles from "./StartDowntime.module.scss";

import PanToolIcon from '@mui/icons-material/PanTool';
import machinesController from "../../../../../barrel/controllers/machinesController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import useMachineManagementShortcutReceiver from "../../../../../barrel/hooks/useMachineManagementShortcutReceiver/useMachineManagementShortcutReceiver";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
function StartDowntime(props:any) {
    
  const { cancelToken, isCancel } = useCancelToken();
  const paramsData = props?.location?.state; 
  const myLocalStorage = useLocalStorageHandler();
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const feedbackService = useFeedbackService();
  const timerRecalc = useRef<any>(null);
  const [loadedMachine, setLoadedMachine] = useState<any>(null);
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedMachine,setSelectedMachine] = useState({})
  const machineManagementShortcutReceiver = useMachineManagementShortcutReceiver();
  
  const {t} = useHxfTranslation();

  const loadPageChecks = useCallback(() => {
   

    let idMachine = paramsData?.Id_Machine;
    let machineData = paramsData?.machineData;
    if(!idMachine || !machineData){
        feedbackService.notifyToast(t('generic.critical.error'), "error");
        props.history.push({
            pathname:"/",
            state: {},
          });
          return;
    }
    setLoadedMachine(machineData);
    setLoadingPage(false);
  }, [setLoadingPage, feedbackService, t, paramsData?.Id_Machine, props.history,paramsData?.machineData]);

  const getMachineDowntimes = useCallback(() => {

    let unavs:any = loadedMachine?.unavailabilities;
    if(!unavs){
        return [];
    }
    //order desc
    unavs.sort((a:any, b:any) => {
        if (a.code < b.code) {
          return 1; // swap the elements
        } else if (a.code > b.code) {
          return -1; // maintain the order
        }
        return 0; // leave the elements unchanged with respect to each other
    });
    return unavs;
  },[loadedMachine?.unavailabilities]);

  useEffect(() => {
   
    if (!didMount) {

      loadPageChecks();
      timerRecalc.current = setInterval( () => {
        loadPageChecks();
      },1000*10);
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  useEffect(() => {
    return () => {
        if(timerRecalc.current){
            console.log("clearing interval");
            clearInterval(timerRecalc.current);
        }
    }
  /* eslint-disable-line */
  },[]);



  const getInitiateUnavailabilityButton = (obj:any) => {
  
    return (
      <div
      data-btn-unavailability-code={obj.code}
      className={`${styles.elemElement} ${styles.unavElem}`}
      onClick={() => {
            let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
            setLoadingPage(true);
            machinesController().startDowntime({workerCode:currentWorkerCode}, obj.Id, loadedMachine.Id, null, cancelToken).then((res:any) => {
                let createdIdUnavOccurrence = res?.data?.response?.Id_UnavailabilityOccurrence;
                
                if(!createdIdUnavOccurrence){
                    throw "error invalid created unav occur";
                }
            
                props.history.push({
                    pathname:"/feature/downtime/occurrence/" + createdIdUnavOccurrence,
                    state: {
                        Id_Machine:loadedMachine.Id
                    },
                  });
          
             
                setLoadingPage(false);
            }).catch((res:any) => {
                if (isCancel(res)) {
                    return;
                }
                setLoadingPage(false);
                let result = res?.response?.data?.response;
                if(result?.issues?.errors.includes("error.thisUnavailabilityAlreadyRunning")){
                    feedbackService.notifyToast(t('unav.already.running'), "error");
                    return;
                }
             
                feedbackService.notifyToast(t('generic.critical.error'), "error");
            });
        
      }}
    >
        
      <div className={styles.actionBtnContainer}>
      <div className={styles.elemBackgroundContainer}>
        <PanToolIcon />
      </div>
        <div className={`${styles.elemElementTitle}`}>
         {obj.name}
        </div>

      </div>
      <IonCardTitle className={styles.elemElementDescription}>
      
      </IonCardTitle>

    </div>
    )
  }

  if(!loadedMachine || loadingPage){
    return (

        <InAppTemplate>
          <CustomArrowBack pushUrl="/feature/machines" additionalRouteState={{preserveShortcutData:true}}/>
          <div className={styles.pageTitle}>{!loadingPage && (t('downtime.reason'))}</div>
          {loadingPage && <LoadingSpinnerImg />}
    
        </InAppTemplate>
      );
  }

  return (

    <InAppTemplate>
      <CustomArrowBack pushUrl={"/feature/machine-management/" + loadedMachine?.Id} additionalRouteState={{preserveShortcutData:true}} />
      <div className={styles.pageTitle}>{!loadingPage && (t('downtime.reason'))}</div>

      <div className={styles.topInfoContainer}>
        {machineManagementShortcutReceiver.shortcutReceivedData?.operationProductSelected && (
          <>{machineManagementShortcutReceiver.elementPreloadedShortcutData}</>
        )}
      </div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
            {getMachineDowntimes().length > 0 && (
                <>
                {getMachineDowntimes().map((obj:any,index:any) => (
                    <div key={"dt_reason_" + index}>
                        {getInitiateUnavailabilityButton(obj)}
                    </div>
                ))}
                </>
            )}
            {getMachineDowntimes().length === 0 && (
                <>
                <div>{t('no.possible.downtimes')}</div>
                </>
            )}
        </div>
      </div>
    </InAppTemplate>
  );
}

export default StartDowntime;
