import CustomErrorNotice from "../../CustomElements/CustomErrorNotice/CustomErrorNotice";
function NotFoundPage(props) {
  return (
    <div>
      <CustomErrorNotice
        goBackArrow={true}
        goBackFunction={() => {
          props.history.push("/");
        }}
        title="Page not found"
        description="The page you are trying to access does not exist."
      ></CustomErrorNotice>
    </div>
  );
}

export default NotFoundPage;
