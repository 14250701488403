import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import ProgressBar from "../../../../../ProgressBar/ProgressBar";
import styles from "./MultiPopPopupProductionOrderSelectionSubscreen.module.scss";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import PrecisionManufacturing from "@mui/icons-material/PrecisionManufacturing";
interface IPropsSubSel {
  selectionData: any;
  onProductionOrderSelect: any;
  onGoBack: any;
}
function MultiPopPopupProductionOrderSelectionSubscreen(props: IPropsSubSel) {
  const {t} = useHxfTranslation();
  const getProductionOrderElement = (obj: any, index: number) => {
    return (
      <IonCardHeader
        className={`${styles.elemElement}`}
        onClick={() => {
          props.onProductionOrderSelect(obj.Id_ProductionOrder);
        }}
      >
        <div className={styles.elemBackgroundContainer}>
        <PrecisionManufacturing />
        </div>
        <IonCardTitle className={styles.elemElementDescription}>
          {obj.name}
        </IonCardTitle>
        <IonCardSubtitle>
          <div className={styles.elemElementTitle}>{obj.code}</div>
        </IonCardSubtitle>
        <ProgressBar pgValue={obj.productionOrder_completePct} />
      </IonCardHeader>
    );
  };

  if (!props?.selectionData?.productionOrdersData) {
    return <></>;
  }

  return (
    <>
      <div className={styles.selectedOperationInfo}>
        <div>{t('operation')}: {props.selectionData.operationCode}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>{t('select.a.productionorder')}:</div>
        <div className={styles.operationsContainer}>
          {/*<CustomElementGoBack onClick={() => {
                                        props.onGoBack();
                                    }}/> 
                                    <div className={styles.spaceArrow}></div>*/}
          {props.selectionData.productionOrdersData.map(
            (row: any, index: number) => (
              <div
                key={"multiOperation_po_" + index}
                className={`${styles.activeProductionElement}  `}
              >
                {getProductionOrderElement(row, index)}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default MultiPopPopupProductionOrderSelectionSubscreen;
