import styles from "./ProductionBottomInfo.module.scss";
import { useProductionOrderSelectionStore } from "../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { withRouter } from "react-router";
import ProductionService from "../../../../barrel/services/productionService";
function ProductionBottomInfo(props) {
  const productionService = ProductionService();
  const selectionData = useProductionOrderSelectionStore();
  let currentOperation = selectionData?.currentOperation?.name
    ? selectionData.currentOperation
    : null;
  let nextOperation = selectionData?.nextOperation?.name
    ? selectionData.nextOperation
    : null;

  if (!productionService.isProductionRoute(props.match.path)) {
    return <></>;
  }

  //path validation to show
  /*if(!productionService.isInProductionRoute()){
    return (<></>)
  }*/

  return (
    <>
      {currentOperation !== null && nextOperation !== null && (
        <div className={styles.bottomInfo}>
          <div className={styles.separator}></div>
          <div className={styles.operationInfo}>
            <div className={styles.currentOperationContainer}>
              <div className={styles.currentOperation}>
                {currentOperation.name}
              </div>
              <div className={styles.triangleCorner}></div>
            </div>

            <div className={styles.nextOperation}>{nextOperation.name}</div>
          </div>
        </div>
      )}
    </>
  );
}
export default withRouter(ProductionBottomInfo);
