
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import AddRemoveStockComponent from "./Component/AddRemoveStockComponent";
function AddRemoveStock(props) {

  const action = props.match.params.action;


 return (
  <InAppTemplate>
    <AddRemoveStockComponent mode={action} />
  </InAppTemplate>
 )
}

export default AddRemoveStock;
