import MobileWrapperCommunicationService, { ICustomIntegrationNotificationData } from "../../../../../../barrel/services/mobileWrapperCommunicationService";
//CustomIntegrationService().loadEnabledIntegrations() called on success login or session status 
function CustomIntegrationService(){


    const getCurrentIntegrationsMap:any = () => {
     
        let currentIntegrationsDataStr = localStorage.getItem("customIntegrations");
        if(!currentIntegrationsDataStr){
            return {};
        }

        let currentIntegrationsDataObj = {};
        try {
            currentIntegrationsDataObj = JSON.parse(currentIntegrationsDataStr);
        } catch (e) {
          console.log("failed conversion");
        }
  
        return currentIntegrationsDataObj;
        
    }

    const getIntegrationDataByIdentifier = (identifier:any) => {
        let integrationsData = getCurrentIntegrationsMap();
        if(!integrationsData[identifier]){
            return {};
        }

        return integrationsData[identifier];
    }
    const setIntegration = (identifier:string, isEnabled:any ,integrationData?:any) => {
       
        let integrationObject = {identifier:"",isEnabled:false,integrationData:{}};
        integrationObject.identifier = identifier;
        integrationObject.isEnabled = isEnabled;
        integrationObject.integrationData = integrationData;
     
        let currentIntegrationsData = getCurrentIntegrationsMap();
        currentIntegrationsData[identifier] = integrationObject;
        localStorage.setItem("customIntegrations",JSON.stringify(currentIntegrationsData));
     
    }

    const startEnabledIntegrations = () => {
     
        let currentIntegrationsData = getCurrentIntegrationsMap();
        let keysIdentifiers = Object.keys(currentIntegrationsData);
        for(let i = 0; i<keysIdentifiers.length; i++){
            let integrationIdentifier:any = keysIdentifiers[i];
            if(currentIntegrationsData[integrationIdentifier].isEnabled){
                let integrationData = currentIntegrationsData[integrationIdentifier]?.integrationData ? currentIntegrationsData[integrationIdentifier]?.integrationData : {};
                
                let notification:ICustomIntegrationNotificationData = {
                    identifier:integrationIdentifier,
                    data:integrationData
                };

                MobileWrapperCommunicationService().notifyStartCustomIntegration(notification);
            }
        }
    }

    return {setIntegration,startEnabledIntegrations,getIntegrationDataByIdentifier};

}

export default CustomIntegrationService;