function ScannablesService() {
  //if this file is updated, it must be also updated on the worker app project

  //delimiters is what goes between two or + elements when generating barcodes
  const hxfBarcode39Delimiter = "-";
  const hxfQrcodeDelimiter = ",";
  const hxfBarcode128Delimiter = "|";

  const getArrayAllDelimiters = () => {
    return [hxfBarcode39Delimiter,hxfQrcodeDelimiter,hxfBarcode128Delimiter];
  }

  const formatForSelector = (scannablesArr) => {
    let arrOptionsScannables = [];
    for (let i = 0; i < scannablesArr.length; i++) {
      let val = scannablesArr[i].scannable;
      let label = scannablesArr[i].label;
      arrOptionsScannables.push({ label: label, value: val });
    }
    return arrOptionsScannables;
  };

  const getBarcodeCODE128Scannables = (options = {}) => {
    let data = [
      {
        scannable: "[productionorder.id]",
        description: "",
        label: "Production Order Id",
      },
      {
        scannable:
          "[productionorder.id]" +
          hxfBarcode128Delimiter +
          "[productionorder.poproduct.id]",
        description: "",
        label: "Production Order Id & Production Order Product Id",
      },
    ];

    if (options?.formatForSelector && options.formatForSelector) {
      return formatForSelector(data);
    }
    return data;
  };
  
  const getBarcodeCODE39Scannables = (options = {}) => {
    let data = [
      {
        scannable: "[productionorder.id]",
        description: "",
        label: "Production Order Id",
      },
      {
        scannable:
          "[productionorder.id]" +
          hxfBarcode39Delimiter +
          "[productionorder.poproduct.id]",
        description: "",
        label: "Production Order Id & Production Order Product Id",
      },
    ];

    if (options?.formatForSelector && options.formatForSelector) {
      return formatForSelector(data);
    }
    return data;
  };

  const getQrcodeScannables = (options = {}) => {
    let data = [
      {
        scannable: "[productionorder.id]",
        description: "",
        label: "Production Order Id",
      },
      {
        scannable:
          "[productionorder.id]" +
          hxfQrcodeDelimiter +
          "[productionorder.poproduct.id]",
        description: "",
        label: "Production Order Id & Production Order Product Id",
      },
      {
        scannable: "[productionorder.code]",
        description: "",
        label: "Production Order Code",
      },
    ];

    if (options?.formatForSelector && options.formatForSelector) {
      return formatForSelector(data);
    }
    return data;
  };

  return {
    getBarcodeCODE39Scannables,
    getQrcodeScannables,
    hxfBarcode39Delimiter,
    hxfQrcodeDelimiter,
    getBarcodeCODE128Scannables,
    hxfBarcode128Delimiter,
    getArrayAllDelimiters
  };
}

export default ScannablesService;
