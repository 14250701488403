import { useEffect, useState } from "react";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import ProductionStartStage from "./ProductionStage/ProductionStartStage/ProductionStartStage";

function ProductionInitiation(props) {
  const [didMount, setDidMount] = useState(false);
  const [dataPassed, setDataPassed] = useState(
    props.location.state != undefined ? props.location.state : {}
  );
  //const unrefreshable = useUnrefreshablePage();
  useEffect(() => {
    if (!didMount) {
      if (props.location.state !== undefined) {
        console.log("Cleared location state");
        setDataPassed(props.location.state);
        props.history.replace(); //clear passed data, so it doesnt persist on refresh
        setDidMount(true);
      }
    }
  }, [didMount, props.history, props.location.state]);

  /*if (isEmptyObject(dataPassed) && props.location.state === undefined) {
    console.log("undefined state, redirecting to /");
    return <Redirect to="/" />;
  }*/

  /* if (isEmptyObject(dataPassed)) {
    return <LoadingSpinnerImg />;
  }*/

  return (
    <InAppTemplate>
      <ProductionStartStage
        {...props}
        history={props.history}
        dataPassed={dataPassed}
      />
    </InAppTemplate>
  );
}
export default ProductionInitiation;
