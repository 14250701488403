import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner
} from "@ionic/react";
import TokenIcon from '@mui/icons-material/Token';
import { searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import productsController from "../../../../../../barrel/controllers/productsController";
import stockMovementsController from "../../../../../../barrel/controllers/stockMovementsController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
import LoadingSpinnerImg from "../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import CustomSearchBar from "../../../../../CustomElements/CustomSearchBar/CustomSearchBar";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import AddingProductQtyConfirmationPopup from "../../../../../CustomElements/statePopups/addingProductQtyConfirmationPopup/AddingProductQtyConfirmationPopup";
import DefineProductParameterizedVariableValuesPopup from "../../../../../CustomElements/statePopups/defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import DefineProductQuantityPopup from "../../../../../CustomElements/statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineStockLocationPopup from "../../../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import DefineUomPopup from "../../../../../CustomElements/statePopups/defineUomPopup/DefineUomPopup";
import NotFoundPage from "../../../../../ErrorPages/NotFoundPage/NotFoundPage";
import styles from "./AddRemoveStockComponent.module.scss";


interface IProps{
    mode:'add'|'remove';
    openFrom?:null | 'receivings' | 'shippings'; //if receivings or shippings, and if the product has a default uom on shippings or receivings it will use that uom
    hideTitle?:any;
    hideGoBack?:any;
    fixedSearchbar:any;
    customOnConfirm?:any;
    skipAskStockLocation?:boolean;
}
function AddRemoveStockComponent(props:IProps) {
    const syncSettings = useSyncSettings();
    const loadMoreRequestingIndex = useRef(0);
    const {t} = useHxfTranslation();
    const action = props.mode;
    const history:any = useHistory();
    const feedbackService = useFeedbackService();
    const { sessionState } = useGlobalState();
    const [didMount, setDidMount] = useState(false);
    const previousDidMount = usePrevious(didMount);
    const sizePage = 25;
    const posContainerRef = useRef(null);
    const loadedObjsRef = useRef<any>(null);

    const [qrScannerEnabled, setQrScannerEnabled] = useState(false);
    const [barcodeScannerEnabled, setBarcodeScannerEnabled] = useState(false);
    const [showProductsFamilies, setShowProductsFamilies] = useState(false);
    const [traceStockEnabled,setTraceStockEnabled]= useState(false);
    const [askConfirmationOnAdd,setAskConfirmationOnAdd] = useState(false);
    const [addingQtyRes,setAddingQtyRes] = useState<any>(null);
    const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
    const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
      useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
  
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const infiniteScrollEnabledRef = useRef(false);
    const allowedInfiniteScrollLoadPageRef = useRef(0);
    const [indexReRender, setIndexReRender] = useState(0);
  
    const [searchbarInput, setSearchbarInput] = useState("");
    const timerStartSearching = useRef<any>(0);
  
    const objSearchFilters = useRef({});
  
    const [filtersApplied, setFiltersApplied] = useState([]);
    const previousFiltersApplied = usePrevious(filtersApplied);
  
    const [objProduct, setObjProduct] = useState<any>({});

    const [defaultId_Warehouse_Location, setDefaultId_WarehouseLocation] = useState(null);
    const [defaultWarehouseLocationData, setDefaultWarehouseLocationData] = useState(null);

    const [defaultId_Warehouse, setDefaultId_Warehouse] = useState(null);
    const [defaultWarehouseData, setDefaultWarehouseData] = useState(null);

    const [defaultId_Lot, setDefaultId_Lot] = useState(null);
    const [defaultLotData, setDefaultLotData] = useState(null);

    const [currentSelectedProductData, setCurrentSelectedProductData] = useState<any>(
      {}
    );
  
    const finishAddProduct = (res:any) => {
      let newObjProduct = { ...objProduct, Quantity: res.quantityResult };
      setObjProduct({}); //finished, closes all popups
      setAddingQtyRes(null);
      let smQuantity = newObjProduct.Quantity;
      if (action === "remove") {
        smQuantity = smQuantity * -1;
      }
      let preparedStockMovement:any = {
        Id_Product: newObjProduct.Id_Product,
        name:res?.newProductData.name,
        code:res?.newProductData.code,
        Quantity: smQuantity,
        Id_WarehouseLocation: newObjProduct?.Id_WarehouseLocation,
        Id_Warehouse: newObjProduct?.Id_Warehouse,
      };

      if (newObjProduct?.Id_Lot && newObjProduct.Id_Lot !== "new") {
        preparedStockMovement["Id_Lot"] = parseInt(newObjProduct.Id_Lot);
      }

      if (newObjProduct?.newLotNameCode) {
        preparedStockMovement["newLotNameCode"] =
          newObjProduct.newLotNameCode;
      }

      if (newObjProduct?.productParameterizedVariables) {
        let stockMovementProductParameterizedVariables = [];
        for (
          let i = 0;
          i < newObjProduct.productParameterizedVariables.length;
          i++
        ) {
          let ppvv = newObjProduct.productParameterizedVariables[i];
          let newSmPPVV = {
            Id_Product_ParameterizedVariable: parseInt(
              ppvv.Id_Product_ParameterizedVariable
            ),
            Value: ppvv.Value,
          };
          stockMovementProductParameterizedVariables.push(newSmPPVV);
        }
        preparedStockMovement[
          "stockMovementProductParameterizedVariablesArray"
        ] = stockMovementProductParameterizedVariables;
      }
      

      if(props?.customOnConfirm){
        props.customOnConfirm(preparedStockMovement);
      }else{

        //normal add / remove beahviour
        stockMovementsController()
        .saveStockMovement(preparedStockMovement)
        .then((res:any) => {
          if (!res?.data?.code || res.data.code !== 200) {
            throw "unexpected error";
          }

          feedbackService.notifyToast(t("stockmovement.created"),"success");
        })
        .catch((res:any) => {

          feedbackService.notifyToast("generic.critical.error","error");
        });
      }
    }
    const getTraceStockProductData = () => {
      let traceStockProduct:any = {
        Id_Product:objProduct.Id_Product,
        name:objProduct.name,
        code:objProduct.code,
        arrayProductParameterizedVariables:[]
      };
  
      let arrayPPVVs = [];
      if(objProduct?.productParameterizedVariables){
        for(let i = 0; i<objProduct?.productParameterizedVariables.length; i++){
          let idppv = objProduct.productParameterizedVariables[i].Id_Product_ParameterizedVariable;
          let name =objProduct.productParameterizedVariables[i]?.name;
          let code =objProduct.productParameterizedVariables[i]?.code;
          let value = objProduct.productParameterizedVariables[i]?.value ? objProduct.productParameterizedVariables[i]?.value : null;
          if(!value){
            value = objProduct.productParameterizedVariables[i]?.Value ? objProduct.productParameterizedVariables[i]?.Value : null;
          }
  
          if(!value){
            throw "invalid ppvv value";
          }
  
          arrayPPVVs.push({Id_Product_ParameterizedVariable: idppv, name:name, code:code, Value:value});
        }
      }
  
      traceStockProduct.arrayProductParameterizedVariables = arrayPPVVs;
  
      return traceStockProduct;
    }

    const onCancelStockAction = () => {
      setObjProduct({});
      setCurrentSelectedProductData({});
      setAddingQtyRes(null);
    };
    const getPopupElement = () => {
      if (!currentSelectedProductData?.Id || !objProduct?.Id_Product) {
        return <></>;
      }
      
      //if it requires confirmation popup
      if(addingQtyRes){
        return(
          <AddingProductQtyConfirmationPopup productData={objProduct} qtyResult={addingQtyRes} onClosePopup={() => {
            onCancelStockAction();
          }} onSuccessConfirm={() => {
            finishAddProduct(addingQtyRes);
          }}/>
        )
      }

      //product has product parameterized variables  but have not been defined yet
      if (
        currentSelectedProductData?.productParameterizedVariables &&
        !objProduct?.productParameterizedVariables
      ) {
        return (
        
          <DefineProductParameterizedVariableValuesPopup
            currentData={currentSelectedProductData}
            title={t('product.parameters')}
            onClosePopup={() => {
              onCancelStockAction();
            }}
            onSuccessConfirm={(res:any) => {
              let objProductData = { ...objProduct };
              objProductData.productParameterizedVariables = res;
              setObjProduct(objProductData);
            }}
          />
        );
      }
  
      //user has not defined yet the stock location to pick stock from
      if (objProduct?.Id_WarehouseLocation === undefined && !props.skipAskStockLocation) {
        return (
          <DefineStockLocationPopup
            title={t('stock.location')}
            qrScannerEnabled={qrScannerEnabled}
            barcodeScannerEnabled={barcodeScannerEnabled}
            traceStockEnabled={traceStockEnabled}
            traceProductData={getTraceStockProductData()}
            allowLotCreation={true}

            defaultId_Warehouse_Location={defaultId_Warehouse_Location}
            defaultWarehouseLocationData={defaultWarehouseLocationData}

            defaultId_Warehouse={defaultId_Warehouse}
            defaultWarehouseData={defaultWarehouseData}

            defaultId_Lot={defaultId_Lot}
            defaultLotData={defaultLotData}

            infoData={{
              productData: {
                code: currentSelectedProductData.code,
                name: currentSelectedProductData.name,
              },
            }}
            onClosePopup={() => {
              onCancelStockAction();
            }}
            onSuccessConfirm={(res:any) => {
              let idWarehouseLocation = null;
              let idWarehouse = null;
              let idLot = null;
  
              let invalidLocation = true;
              if (
                !res?.Id_WarehouseLocation &&
                !res?.Id_Warehouse &&
                !res?.Id_Lot
              ) {
                invalidLocation = false;
              } else {
                idWarehouseLocation = res.Id_WarehouseLocation;
                idWarehouse = res.Id_Warehouse;
                if (res?.Id_Lot) {
                  idLot = res.Id_Lot;
                  invalidLocation = false;
                } else if (!res?.Id_Lot && res?.defaultNoLotObj?.Id_Lot) {
                  idLot = res?.defaultNoLotObj?.Id_Lot;
                  invalidLocation = false;
                }
              }
  
              if (invalidLocation) {
                throw "error invalid location passed on stock move";
              }
  
              let newObjProduct = {
                ...objProduct,
                Id_WarehouseLocation: idWarehouseLocation,
                Id_Warehouse: idWarehouse,
                Id_Lot: idLot,
              };
              if (res?.Id_Lot === "new") {
                newObjProduct["newLotNameCode"] = res.defaultNoLotObj.code;
              }
  
              setObjProduct(newObjProduct);
            }}
          />
        );
      }
  
      //the user has not yet defined the quantity he is moving
      if (!objProduct?.quantity) {
        if (
          !objProduct?.Id_Selected_UoM &&
          currentSelectedProductData?.flag_uomsEnabled_addAndRemoveStock === 1 &&
          currentSelectedProductData?.uomConversionsData?.length > 0
        ) {
          return (
            <DefineUomPopup
              title={t('uom')}
              productData={currentSelectedProductData}
              onClosePopup={() => {
                onCancelStockAction();
              }}
              onSuccessConfirm={(idSelectedUom:any) => {
                let selectedUomData:any = {};
  
                if (idSelectedUom !== -1) {
                  let uomConversionsData =
                    currentSelectedProductData.uomConversionsData;
                  for (let i = 0; i < uomConversionsData.length; i++) {
                    if (uomConversionsData[i].Id_UoM_Target === idSelectedUom) {
                      selectedUomData = { ...uomConversionsData[i] };
                    }
                  }
                }
  
                let selUomLabel = "";
                if (idSelectedUom !== -1 && idSelectedUom) {
                  selUomLabel = selectedUomData.UoM_Target_Code;
                }
  
                setObjProduct({
                  ...objProduct,
                  Id_Selected_UoM: idSelectedUom,
                  uomLabel: selUomLabel,
                  temp_selectedUom_Data: selectedUomData,
                });
              }}
            />
          );
        }
  
        return (
          <DefineProductQuantityPopup
            title={t('quantity')}
            requirePositiveQuantity={true}
            qtyInputPlaceholder={t('insert.quantity')}
            productData={{
              code: currentSelectedProductData?.code,
              name: currentSelectedProductData?.name,
              uomLabel: objProduct.uomLabel,
              Id_Selected_UoM: objProduct?.Id_Selected_UoM
                ? objProduct?.Id_Selected_UoM
                : -1,
              qtyValue: objProduct?.quantity ? objProduct.quantity : 0,
              uomConversionsData: currentSelectedProductData?.uomConversionsData,
            }}
            onClosePopup={() => {
              onCancelStockAction();
            }}
            onSuccessConfirm={(res:any) => {
              if(askConfirmationOnAdd){
                setAddingQtyRes(res);
              }else{
                finishAddProduct(res);
              }
            }}
          />
        );
      }
  
      return <></>;
    };
  
    const initiateFilterCode = (val:any) => {
      setSearchbarInput(val);
      clearTimeout(timerStartSearching.current); //stops spam requests
      loadMoreRequestingIndex.current = loadMoreRequestingIndex.current + 1;
      let requestinIndex = loadMoreRequestingIndex.current;

      timerStartSearching.current = setTimeout(
        () => {
          objSearchFilters.current = {
            ...objSearchFilters.current,
            searchBarFilter: val,
          };
          restartSearch(requestinIndex);
        },
        500,
        val,
        requestinIndex
      );
    };
  
    const onFilterChipRemove = (index:any) => {
      let newFiltersApplied:any = [];
      for (let i = 0; i < filtersApplied.length; i++) {
        if (i !== index) {
          newFiltersApplied.push(filtersApplied[i]);
        }
      }
      setFiltersApplied(newFiltersApplied); //will trigger useeffect
    };
  
    const getProperFilterChip = (obj:any, index:any) => {
      //custom styling of chip
      if (obj.filterKey === "Id_Product") {
        return (
          <CustomAppliedFilterChip
            filterTextElement={
              <>
                <b>Product:</b> {obj.productCode}
              </>
            }
            onRemoveFilter={() => {
              onFilterChipRemove(index);
            }}
          />
        );
      }
  
      let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
      let chipVal = obj[obj.filterKey];
      //default
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{labelDefault}:</b> {chipVal}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    };
    const getFormattedFiltersForRequest = (filtersArray:any) => {
      let filtersArr = [...filtersArray];
      let formattedFilters:any = {};
      for (let i = 0; i < filtersArr.length; i++) {
        let filterKey = filtersArr[i].filterKey;
        let filterValue = filtersArr[i][filterKey];
        formattedFilters[filterKey] = filterValue;
      }
  
      return formattedFilters;
    };
  
    const loadMore = useCallback(
      (opts = null) => {

        let passedFilters = opts?.passedFilters;
        let requestinIndex = opts?.requestinIndex;
        let currentPage = allowedInfiniteScrollLoadPageRef.current;
  
        let extraFiltersApply = {};
        if (passedFilters) {
          extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
        } else {
          extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
        }
        setIsLoadingMore(true);
        productsController()
          .getProductsListJson({
            page: currentPage,
            size: sizePage,
            includeAddRemSettings:true,
            includeParameterizedVariables: true,
            ...objSearchFilters.current,
            ...extraFiltersApply,
          })
          .then((resp:any) => {
            setIsLoadingMore(false);
            syncSettings.check(resp);
            let posData = resp?.data?.response?.data?.return;
            let posTotalSize = resp.data.response.data.totalSize;
            let posExtraData = resp.data.response.data.extra;
            
            let addRemSettings = resp?.data?.response?.addRemSettings;

            if(addRemSettings?.addRemQrcodeLocationFiller === 1){
              setQrScannerEnabled(true);
            }
            if(addRemSettings?.addRemBarcode39LocationFiller === 1){
              setBarcodeScannerEnabled(true);
            }

            if(addRemSettings?.addRemShowProductsFamilies === 1){
              if(!showProductsFamilies){
                setShowProductsFamilies(true);
              }
            }

            if(addRemSettings?.remStockTraceStock === 1){
              setTraceStockEnabled(true);
            }

            if(addRemSettings?.addRemAskConfirmationBeforeAdd === 1){
              setAskConfirmationOnAdd(true);
            }

            if(addRemSettings){
              setDefaultId_WarehouseLocation(addRemSettings?.Id_WarehouseLocation);
              setDefaultWarehouseLocationData(addRemSettings?._addRemStock_warehouseLocationData);

              setDefaultId_Warehouse(addRemSettings?.Id_Warehouse);
              setDefaultWarehouseData(addRemSettings?._addRemStock_warehouseData);

              setDefaultId_Lot(addRemSettings?.Id_Lot);
              setDefaultLotData(addRemSettings?._addRemStock_lotData);
            }
      

            if (currentPage === 0) {
              //first load only
              //loads the extraData that loads in the backend only when the page = 0
              //this is done to avoid double requests just to gather extra unrelated data
            }
  
            let newArrayLoadedObjs = [];
  
            if (loadedObjsRef.current !== null) {
              newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
            } else {
              newArrayLoadedObjs = posData;
            }
  
            loadedObjsRef.current = newArrayLoadedObjs;
  
            let hasMoreElements =
              newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
            if (hasMoreElements) {
              allowedInfiniteScrollLoadPageRef.current =
                allowedInfiniteScrollLoadPageRef.current + 1;
              infiniteScrollEnabledRef.current = true;
            } else {
              infiniteScrollEnabledRef.current = false;
            }
  
            //setLoadedObjs(loadedObjsRef.current);
            setLoadingPage(false);
           
            if (currentPage === 0) {
              setIndexReRender(indexReRender + 1); //important due to the fact we're using ref (to map)
            }
          })
          .catch((resp:any) => {
            setIsLoadingMore(false);

            if(requestinIndex){
              if(requestinIndex !== loadMoreRequestingIndex.current){
                console.log("Aborted");
                return;
              }
            }

            console.log("THE ERROR: ", resp);
            if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
              if (failedToLoadPosError) {
                setFailedToLoadPosError(false);
              }
              setRequiresNetworkLoadError(true);
            } else {
              if (requiresNetworkLoadError) {
                setRequiresNetworkLoadError(false);
              }
              setFailedToLoadPosError(true);
            }
            console.log("FAILED::", resp);
  
            setLoadingPage(false);
          });
      },
      [
        syncSettings,
        failedToLoadPosError,
        indexReRender,
        requiresNetworkLoadError,
        filtersApplied,
      ]
    );
    const restartSearch = useCallback((requestinIndex = null) => {
      allowedInfiniteScrollLoadPageRef.current = 0;
      loadedObjsRef.current = null;
  
      loadMore({requestinIndex:requestinIndex});
    }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);
    useEffect(() => {
      if (!didMount) {
        let loadingFilters = history.location?.state?.applyFilters;
  
        if (loadingFilters) {
          setFiltersApplied(loadingFilters);
        } else {
          loadingFilters = null;
        }
  
        window.history.replaceState({}, document.title); //clear history state
  
        loadMore({passedFilters:loadingFilters});
        setDidMount(true);
      }
    }, [
      history.location?.state?.applyFilters,
      didMount,
      failedToLoadPosError,
      requiresNetworkLoadError,
      sessionState,
      loadMore,
    ]);
  
    useEffect(() => {
      if (previousDidMount === didMount && didMount) {
        if (previousFiltersApplied !== filtersApplied) {
          console.log("filters were changed");
          restartSearch();
        }
      }
    }, [
      filtersApplied,
      previousDidMount,
      didMount,
      previousFiltersApplied,
      restartSearch,
    ]);
  
    if (!didMount) {
      return <></>;
    }
  
    if (action !== "add" && action !== "remove") {
      return (
        <>
          {!props.hideGoBack && (
                <CustomArrowBack pushUrl="/" />
          )}
        
          <div className={styles.pageTitle}>Not Found</div>
          <div className={styles.mainContainer}>
            <NotFoundPage />
          </div>
        </>
      );
    }

    const getProperProductFamilies = (objProduct:any) => {

 
      if(!objProduct?.families || objProduct?.families.length === 0){
        return (<></>)
      }
      
      
      return (
        <>
        <div className={styles.familiesLabel}>{t('families')}</div>
        <div className={styles.limitedFamiliesContainer}>
          {objProduct.families.map((obj:any, index:any) => (
              <span key={"fam_" + index} className={styles.family}>
                {index > 0 ? (<>, </>) : (<></>)}
                <i>{obj.name}</i>
              </span>
          ))}
        </div>

        </>
      )
    }
  
    return (
      <>
        {!props.hideTitle && (
          <CustomArrowBack pushUrl="/feature/stock-in-out" />
        )}
        
        {!props.hideTitle && (
        <div className={styles.pageTitle}>
          {action === "add" ? t('add.stock')  : ""}
          {action === "remove" ? t('remove.stock') : ""}
        </div>
        )}

        {!loadingPage && (
          <>
          
            <div className={`${styles.settingsContainer} ${props?.fixedSearchbar ? styles.fixedSearchbar : styles.normalSearchbar} `}>
              <div className={` ${styles.customSearchBarContainer}  `}>
                <div>
                <CustomSearchBar
                  value={searchbarInput}
                  onResetTextClick={() => {
                    initiateFilterCode("");
                  }}
                  onChange={(evt:any) => {
                    initiateFilterCode(evt.target.value);
                  }}
                  iconElement={
                    <div className={styles.searchIconStyles}>
                      <IonIcon icon={searchCircleOutline} />
                    </div>
                  }
                  placeholder={t('search.product')}
                />
                </div>

              </div>
            </div>
            {filtersApplied.length > 0 && (
              <div className={styles.appliedFiltersContainer}>
                {filtersApplied.map((obj, index) => (
                  <div key={"cafc_" + index}>
                    {getProperFilterChip(obj, index)}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
  
        {getPopupElement()}
  
        <div className={styles.mainContainer}>
          {loadingPage && <LoadingSpinnerImg />}
          <div
            id="objSelectionDiv"
            key={"rerender_ref_selection_" + indexReRender}
            className={styles.objsSelection}
            ref={posContainerRef}
          >
            {!loadingPage &&
            !failedToLoadPosError &&
            loadedObjsRef.current !== null ? (
              <>
                {loadedObjsRef.current.map((obj:any, index:any) => (
                  <div key={"pick_prd_" + index} className={styles.objElement}>
                    <IonCardHeader
                      className={styles.elemElement}
                      onClick={() => {

                        setCurrentSelectedProductData(obj);
                        

                        let uomLabel = null;
                        let Id_Selected_UoM = null;
                        if(props.openFrom === "receivings"){
                          let idDefaultReceivingUom = obj?.defaultReceivings_Id_UoM;
                          Id_Selected_UoM = idDefaultReceivingUom;
                          uomLabel = obj?.defaultReceivings_UoM_code;
                        }

                        if(props.openFrom === "shippings"){
                          let idDefaultShippingUom = obj?.defaultShippings_Id_UoM;
                          Id_Selected_UoM = idDefaultShippingUom;
                          uomLabel = obj?.defaultShippings_UoM_code;
                        }
                        
                        setObjProduct({ Id_Product: obj.Id,code:obj.code,name:obj.name, Id_Selected_UoM:Id_Selected_UoM, uomLabel:uomLabel });
                      }}
                    >
                      <div className={styles.elemBackgroundContainer}>
                        <TokenIcon />
                      </div>
                      <div className={styles.orderNameCodeContainer}>

                        <div className={styles.orderNameCode}>
                          <div
                            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                          >
                            ({obj.code})
                          </div>
                          <div
                            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                          >
                            {obj.name}
                          </div>

                          {showProductsFamilies && (
                            <>{getProperProductFamilies(obj)}</>
                          )}
                        </div>
                      </div>
                    </IonCardHeader>
                  </div>
                ))}{" "}
              </>
            ) : (
              <></>
            )}
  
            {isLoadingMore && (
              <IonCardHeader className={styles.elemElement} onClick={() => {}}>
                <div className={styles.elemBackgroundContainer}>
                  <TokenIcon />
                </div>
                <IonCardSubtitle>
                  <div className={styles.elemElementTitle}>Loading...</div>
                </IonCardSubtitle>
                <IonCardTitle className={styles.elemElementDescription}>
                  <IonSpinner />
                </IonCardTitle>
              </IonCardHeader>
            )}
  
            <HxfInfiniteScroll
              containerElementRef={posContainerRef}
              requiresContainerHavingScrollbar={true}
              onLoadMore={() => {
                /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
              
                if(allowingVal > 7){
                  return;
                }
                console.log("allowing more :", allowingVal);
                 allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
                */
  
                if (infiniteScrollEnabledRef.current) {
                  
                  loadMore();
                }
              }}
              allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
            />
          </div>
        </div>
        {failedToLoadPosError ? (
          <div className={styles.errorArea}>
            <CustomErrorNotice
              title={t('an.error.occurred')}
              description={t('failed.load.receiving.orders')}
              secondDescription={t('failed.error.generic')}
              extraContent={<></>}
            />
          </div>
        ) : (
          <></>
        )}
  
        {requiresNetworkLoadError ? (
          <div className={styles.errorArea}>
            <ErrorNoticeRequiresNetworkLoadObject />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
  
  export default AddRemoveStockComponent;
  