import { IonLoading } from "@ionic/react";

import styles from "./index.module.scss";
/**
 *
 * @param props
 * @returns
 */

interface IProps{
  onTimeout?:any;
  timeOutMilliseconds?:any;
}
function CustomLoadingAction(props: IProps) {
  return (
    <IonLoading
    
      cssClass={styles.customLoadingFrame}
      isOpen={true}
      onDidDismiss={() => {
        if (props?.onTimeout) {
          props.onTimeout();
        }
      }}
      message={"Please wait..."}
      duration={props?.timeOutMilliseconds}
    />
  );
}

export default CustomLoadingAction;
