import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import styles from "./ActionScreenTopBar.module.scss";
import { dispatchProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { dispatchActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import useActionScreenTopBar from "./hooks/useActionScreenTopBar";
interface IActionScreenTopBar {
  title: string;
  callbackOnClose?: () => any;
  closeOverride?: () => any;
}

function ActionScreenTopBar(props: IActionScreenTopBar) {

  const actTopBar = useActionScreenTopBar();

  return (
    <div className={styles.barContainer}>
      <div></div>

      <div className={styles.title}>{props.title}</div>

      <IonButton
        data-btn={"actionScreenTop_close"}
        color="danger"
        className={styles.closeWindowButton}
        onClick={() => {
          if (props?.closeOverride) {
            props.closeOverride();
          } else {
            console.log("CLOSING");
            if (props?.callbackOnClose) {
              props.callbackOnClose();
            }

            actTopBar.closeActionWindows();
          }
        }}
      >
        <IonIcon slot="icon-only" size="large" icon={closeOutline} />
      </IonButton>
    </div>
  );
}

export default ActionScreenTopBar;
