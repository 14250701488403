import styles from "./ActionScreenMatchFixedVariablesConfirmationButtons.module.scss";
import confirmationStyles from "../../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";

import { t } from "i18next";
interface IProps {
  onClickSaveForLater:any;
  onClickConfirm: any;
  disabledConfirm?:any;
  disabledShowSaveForLater?:any;
  showSaveForLater?:any;
}

function ActionScreenMatchFixedVariablesConfirmationButtons(props: IProps) {
  const getButtonLabel = () => {
    return t("finalize");
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        {props?.showSaveForLater ? (
          <div className={`${styles.customConfirmationContainer} `}>
            <IonButton
              data-btn="asmfvc_later"
              className={` ${styles.confirmSaveLater} ${styles.confirmButton}`}
              disabled={props.disabledConfirm || props.disabledShowSaveForLater}
              onClick={() => {
                if (props.onClickSaveForLater) {
                  props.onClickSaveForLater();
                }
              }}
            >
              {/*{props.typeShow === "PREVIOUS" && (
                <IonIcon slot="start" icon={caretBackOutline} />
              )}{" "}*/}
              {t('save.for.later')}
      
            </IonButton>
          </div>

         ) : (<></>)}
        
        <div className={`${styles.customConfirmationContainer} `}>
          <IonButton
            data-btn="asmfvc_finalize"
            className={styles.confirmButton}
            disabled={props.disabledConfirm}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {/*{props.typeShow === "PREVIOUS" && (
              <IonIcon slot="start" icon={caretBackOutline} />
            )}{" "}*/}
            {getButtonLabel()}{" "}
     
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default ActionScreenMatchFixedVariablesConfirmationButtons;
