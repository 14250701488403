import { IonButton, IonCol, IonGrid, IonIcon, IonRow, useIonAlert, useIonToast } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import styles from "./ProductionHistory.module.scss";
import { styled, Table, TableContainer } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomFeatureTitle from "../../CustomElements/CustomFeatureTitle/CustomFeatureTitle";
import productionHistoryController from "../../../barrel/controllers/productionHistoryController";
import { useCancelToken } from "../../../barrel/hooks/useCancelToken";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import DatesConversionService from "../../../barrel/services/datesConversionService";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import SystemService from "../../../barrel/services/systemService";
import PrintSerialLabelProductPopup from "../../CustomElements/statePopups/printSerialLabelPopup/printSerialLabelProductPopup";
import HxfAppServersService from "../../../barrel/services/hxfAppServersService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import { addOutline, arrowUndoCircleOutline, checkmarkCircleOutline, closeCircle, closeOutline } from "ionicons/icons";
import CornerButton from "../Productions/Standard/ProductionProcess/CornerButton/CornerButton";
import productionController from "../../../barrel/controllers/productionController";
import EditProductionRecordElement from "./EditProductionRecordElement/EditProductionRecordElement";
import PrActionButton from "./EditProductionRecordElement/btnElement/PrActionButton";
import AddIrregularityToPrPopup from "./AddIrregularityToPrPopup/AddIrregularityToPrPopup";
const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: "300px",
  maxWidth: "900px",

}));


interface IProductionHistoryItemOperationProductData{
  Id_OperationProduct?:any;
  code?:string;
  name?:string;
}

interface IProductionHistoryItemProductData{
  Id_Product?:any;
  code?:string;
  name?:string;
  printSerial_Id_Label?:any;
  _printSerial_labelData?:any;
}

interface IProductionHistoryItemLabelsDataItem{
  Id_Label:any;
}

interface IProductionHistoryItem{
  Id:any;
  ref?:any;
  _collabParentRef?:any;
  collabParentId_ProductionRecord?:any;
  dateEnd:Date;
  dateStart:Date;
  Id_OperationProduct:any;
  operationProductData:IProductionHistoryItemOperationProductData;
  productData:IProductionHistoryItemProductData;
  Id_ProductionOrder:any;
  Id_ProductionOrder_Product:any;
  labelsData:IProductionHistoryItemLabelsDataItem[];
  _reservedSerializedProducts?:[];
  matchingFixedVarsRecordsData?:any;
  canceled:any;
  _operationActionsCausingIrregularities?:any;
}

interface IEditPr{
  Id_ProductionRecord:any;
  edittingTag:any;
}

const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));

function ProductionHistory(props: any) {
  const [presentToast] = useIonToast();
  const [present] = useIonAlert();
  const [askConfirmCancelProductionRecord,setAskConfirmCancelProductionRecord] = useState(null);
  const DISABLE_EDIT_MATCHING_FIXED_VARS = true; //before allowing this 
  const feedbackService = useFeedbackService();
  const myLocalStorage = useLocalStorageHandler();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "productionHistory",
  });
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);

  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [currentItems ,setCurrentItems] = useState<IProductionHistoryItem[]>([]);
  const [showPopupIrregularity,setShowPopupIrregularity] = useState<any>(null);
  const [popupPrintSerialLabel, setPopupPrintSerialLabel] = useState<any>({Id_Label:null,serializedProducts:[], labelCode:"",labelName:""});

  const [showEditPR, setShowEditPR] = useState<IEditPr | null>(null);
  const [selectedPrintLabelsProductionRecord, setSelectedPrintLabelsProductionRecord] = useState<IProductionHistoryItem | null>(null);
  const [renderKey, setRenderKey] = useState(0);
  const {t} = useHxfTranslation();
  const loadPageChecks = useCallback(() => {
    setLoadingPage(false);
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    productionHistoryController().getProductionHistory({
      workerCode:currentWorkerCode,
      includeMatchingFixedVarsRecords:true,
    },cancelToken).then((res:any) => {
      let returnData = res?.data?.response?.data?.return;
      if(!returnData){
        throw "invalid return data";
      }
      let prodHistAllowAddIrr = false;

      let mapOperations_OperationActionIrregularity = res?.data?.response?.data?.maps?.mapOperations_OperationActionIrregularity;
      if(mapOperations_OperationActionIrregularity){
        prodHistAllowAddIrr = true;
      }
      let itemsList = [];
      //prepare received data;
  
      for(let i = 0; i<returnData.length; i++){
        let dateEndFormatted = DatesConversionService().convertedDateObject(returnData[i].dateEnd);
        let newDateEnd = dateEndFormatted;
        let dateStartFormatted = DatesConversionService().convertedDateObject(returnData[i].dateStart);
        let newDateStart = dateStartFormatted;
        
        let newItem:IProductionHistoryItem = {
          Id:returnData[i].Id,
          ref:returnData[i]?.ref,
          _collabParentRef:returnData[i]?._collabParentRef,
          collabParentId_ProductionRecord:returnData[i]?.collabParentId_ProductionRecord,
          Id_OperationProduct:returnData[i].Id_OperationProduct,
          canceled:returnData[i].canceled,
          dateEnd:newDateEnd,
          dateStart:newDateStart,
          operationProductData:returnData[i].operationProductData,
          productData:returnData[i].productData,
          Id_ProductionOrder:returnData[i].Id_ProductionOrder,
          Id_ProductionOrder_Product:returnData[i].Id_ProductionOrder_Product,
          labelsData:returnData[i]?.labelsData ? returnData[i].labelsData : [],
          _reservedSerializedProducts:returnData[i]?._reservedSerializedProducts ? returnData[i]._reservedSerializedProducts : [],
          matchingFixedVarsRecordsData:returnData[i]?.matchingFixedVarsRecordsData ? returnData[i].matchingFixedVarsRecordsData : []
        };

        if(prodHistAllowAddIrr){
          newItem._operationActionsCausingIrregularities = mapOperations_OperationActionIrregularity?.[returnData[i].Id_OperationProduct];
        }
        itemsList.push(newItem);
      }
  
    
      setCurrentItems(itemsList);

    }).catch((res:any) => {
      if (isCancel(res)) {
        return;
      }
      feedbackService.notifyToast("An error occurred, contact us if the error persists","error");
    });

  }, [setLoadingPage, cancelToken, isCancel, myLocalStorage,feedbackService]);

  useEffect(() => {
    if (!didMount) {
      loadPageChecks();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  const itemRef = (obj:IProductionHistoryItem) => {

    return obj?.ref;
  }

  const itemCollabParentRef = (obj:IProductionHistoryItem) => {
    return obj?._collabParentRef;
  }
  const itemDate = (obj:IProductionHistoryItem) => {


    //let d = obj.dateEnd;
    let d = obj.dateStart;
    let datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    return datestring;

  }

  const itemOperation = (obj:IProductionHistoryItem) => {
   
    return "("+ obj.operationProductData.code+") " +  obj.operationProductData.name;
  }

  const itemProduct = (obj:IProductionHistoryItem) => {
    if(!obj?.productData?.code){
      return "";
    }
    return "("+ obj.productData.code+") " +  obj.productData.name;
  }

  const hasLabels = () => {
    for(let i = 0;i <currentItems.length; i++){
      if(currentItems[i]?.labelsData?.length > 0){
        return true;
      }
    }
    return false;
  }

  const hasSerialNumbers = () => {
    for(let i = 0;i <currentItems.length; i++){

      let hasReservedSerialProducts = false;
      let countResSerials:any = [];
      if(currentItems[i]?._reservedSerializedProducts?.length !== undefined){
        countResSerials = currentItems[i]?._reservedSerializedProducts?.length;
        if(countResSerials && countResSerials > 0){
          hasReservedSerialProducts = true;
        }
      }
      if(currentItems[i] && currentItems[i]?._reservedSerializedProducts && hasReservedSerialProducts && currentItems[i]?._reservedSerializedProducts  && currentItems[i]?.productData?._printSerial_labelData?.code){
        return true;
      }
    }
    return false;
    //if(obj?._reservedSerializedProducts && obj?._reservedSerializedProducts?.length > 0 && obj?.productData?._printSerial_labelData?.code){
  }
  const itemLabelsPrint = (obj:IProductionHistoryItem) => {

    if(obj.labelsData.length > 0){
      return (<>
        <IonButton onClick={() => {

          if(selectedPrintLabelsProductionRecord?.Id == obj.Id){
            setRenderKey(renderKey + 1);
          }else{
            setSelectedPrintLabelsProductionRecord(obj);
          }
          
        }}>{t('print')}</IonButton>
      
      </>)
    }
    return (<></>)
  }

  const isCollabChild = (obj:any) => {
    return obj?.collabParentId_ProductionRecord !== null;
  }
  const isRecordCanceled = (obj:any) => {
    return obj.canceled === 1;
  }

  const isRecordOnGoing = (obj:any) => {
    return obj.canceled === 0 && obj?.dateStart && obj?.dateEnd && obj.dateStart.getTime() === obj.dateEnd.getTime();
  }
  const isRecordClosed = (obj:any) => {
    return obj?.dateStart && obj?.dateEnd && obj.dateStart.getTime() !== obj.dateEnd.getTime();
  }

  const itemAddIrregularityOccurrence = (obj:any) => {


    return (
      <>
      <IonButton onClick={() => {

        setShowPopupIrregularity({productionRecordData:obj,operationProductActionIrregularities:obj._operationActionsCausingIrregularities});
      
      }}>
        <IonIcon slot="start" icon={addOutline} />
        {t('add.irregularity')}</IonButton>
      </>
    )
  }

  const itemSerialLabelPrint = (obj:IProductionHistoryItem) => {

    if(obj?._reservedSerializedProducts && obj?._reservedSerializedProducts?.length > 0 && obj?.productData?._printSerial_labelData?.code){
      return (<>
        <IonButton onClick={() => {
         
          let serializedProducts = obj?._reservedSerializedProducts ? obj._reservedSerializedProducts : [];
          setPopupPrintSerialLabel({Id_Label:obj.productData.printSerial_Id_Label,serializedProducts:serializedProducts, labelCode:obj.productData._printSerial_labelData.code,labelName:obj.productData._printSerial_labelData.name});
        }}>{t('print.serials')}</IonButton>
      
      </>)
    }
    return (<></>)
  }

  const getCurrentServerServe = () => {

    if (process.env.REACT_APP_MODE === "dev") {
      return process.env.REACT_APP_API_SERVE_DEV;
    } 

    let server = localStorage.getItem("srv");
    if (!server) {
      throw "x3Unexpected err, server has not been set yet";
    }

  
    return HxfAppServersService().getServerEndpoint(server);

  }

  const getLabelContentData = (obj: any) => {
    let idLabel = obj.Id_Label;
    let idProductionOrder = selectedPrintLabelsProductionRecord?.Id_ProductionOrder;
    let idProductionOrderProduct = selectedPrintLabelsProductionRecord?.Id_ProductionOrder_Product;
    let idProductionRecord = selectedPrintLabelsProductionRecord?.Id;

    const queryBuilder = new URLSearchParams({
      Id_Label: idLabel,
      Id_ProductionOrder: idProductionOrder,
      Id_ProductionOrder_Product: idProductionOrderProduct,
      Id_ProductionRecord: idProductionRecord,
    });

    return queryBuilder;
  };

  const renderFrame = (obj:IProductionHistoryItemLabelsDataItem) => {

    let queryBuilder = getLabelContentData(obj);

    
    let queryString = queryBuilder.toString();
    const isMobile = SystemService().isMobile();
    if (isMobile) {
      console.log("mobile mode - getDownloadable = true");
      queryString = queryString + "&getDownloadable=true";
    }

    return (
      <iframe
        style={{display:'none'}}
        name="output_frame_pdf"
        src={
          getCurrentServerServe() +
          "label/print-pdf/?" +
          queryBuilder.toString()
        }
        width="100"
        height="100"
        onLoad={() => {
          console.log("finished loading");
          /*printShownCount.current = printShownCount.current + 1;
          checkIfFinishedPrints();*/
        }}
      ></iframe>
    );
  }

  const checkHasMatchingFixedVarsRecords = (obj:any) => {
   
    if(obj?.matchingFixedVarsRecordsData){
      
      return obj.matchingFixedVarsRecordsData.length > 0;
    }
    return false;
  }

  return (
    <InAppTemplate>
      <CustomFeatureTitle
        titleElement={
          <div className={styles.pageTitle}>
            {t('production.history.title')}
          </div>
        }
        arrowBackUrl={"/home"}
      />
      

      {popupPrintSerialLabel?.Id_Label && (
        <PrintSerialLabelProductPopup title={"Serial Label Printing"}
         onClosePopup={() => {
           setPopupPrintSerialLabel({Id_Label:null,Id_Product:null,Id_ProductionRecord:null});
         }}
         onSuccessConfirm={() => {
           
         }}
         Id_Label={popupPrintSerialLabel.Id_Label}
         serializedProducts={popupPrintSerialLabel.serializedProducts}
         labelCode={popupPrintSerialLabel.labelCode}
         labelName={popupPrintSerialLabel.labelName}
        />
      )}


      {loadingPage && <LoadingSpinnerImg />}
      {selectedPrintLabelsProductionRecord && (
        <div style={{ display: "none" }} key={renderKey}>
          {selectedPrintLabelsProductionRecord.labelsData.map(
            (obj: any, index: number) => (
              <div key={"pdfFrame_" + index}>{renderFrame(obj)}</div>
            )
          )}
        </div>
      )}
      {showEditPR?.Id_ProductionRecord && (
        <EditProductionRecordElement Id_ProductionRecord={showEditPR.Id_ProductionRecord} editView={showEditPR.edittingTag} onClose={() =>{
          setShowEditPR(null);
        }}/>
      )}
      {showPopupIrregularity && (
        <AddIrregularityToPrPopup productionRecordData={showPopupIrregularity.productionRecordData} operationProductActionIrregularities={showPopupIrregularity?.operationProductActionIrregularities} onClose={() => {
          setShowPopupIrregularity(null);
        }}/>
      )}
      {currentItems && currentItems.length === 0 ? (
        <div style={{display:'flex',justifyContent:'center',fontSize:20,top:20,color:'gray'}}>{t('no.production.records')}</div>
      ) : (
        <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
          <div className={styles.tableContainer}>

            <TableContainer component={Paper}>
              <StyledTable aria-label="simple table">
                <CustomTableHead>
                  <TableRow>
                    <TableCell style={{minWidth:'150px'}}><b>{t('information')}</b></TableCell>


                    <TableCell align="right"></TableCell>
                  </TableRow>
                </CustomTableHead>
                <TableBody>
                  {currentItems.map((obj:any,index:any) => (
                      <TableRow
                        key={index + "_ph"}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <div className={styles.prDataContainer}>
                            <div>
                              <div className={styles.operationNamingContainer}>
                                <div style={{margin:5}}>{itemOperation(obj)}</div>
                              </div>
                              <div style={{margin:10}}>
                              {obj?.ref && obj?.ref !== "" && (
                                <>
                                  <div><b>{t('reference')}</b></div>
                                  <div>{itemRef(obj)}</div>
                                </>
                              )}
                              {obj?._collabParentRef && obj?._collabParentRef !== "" && (
                                <>
                                  <div><b>{t('short.shared.reference')}</b></div>
                                  <div>{itemCollabParentRef(obj)}</div>
                                </>
                              )}
                              <div><b>{t('date')}</b></div>
                              <div>{itemDate(obj)}</div>
                              

                              <div><b>{t('product')}</b></div>
                              <div>{itemProduct(obj)}</div>

                              </div>
                            </div>


                          </div>

                        </TableCell>
   
                          <TableCell align="center">
                            
                            {
                              (obj?._operationActionsCausingIrregularities && obj.canceled === 0) && (
                                <>{itemAddIrregularityOccurrence(obj)}</>
                              )
                              //
                            
                            }
                            {obj.canceled === 0 && hasSerialNumbers() && (
                              <>{itemSerialLabelPrint(obj)}</>
                            )}
                            {obj.canceled === 0 && hasLabels() && (
                              <>{itemLabelsPrint(obj)}</>
                            )}
                            {!DISABLE_EDIT_MATCHING_FIXED_VARS && (
                              <>
                              {obj.canceled === 0 && checkHasMatchingFixedVarsRecords(obj) && (

                              <PrActionButton
                              onClick={() => {
                                setShowEditPR({Id_ProductionRecord:obj.Id, edittingTag:"EDITPR_MATCHING_FIXED_VARIABLES"});
                              }}
                              btnType={"primary"}
                              label={t("view.matching.fixed.vars.btn")}
                              iconPassed={checkmarkCircleOutline}
                              />
                            )}
                            </>
                            )}

                          {isRecordCanceled(obj) && !isRecordClosed(obj) && (
                            <div className={styles.statusPr}><i>{t('canceled')}</i></div>
                          )}

                          {isRecordCanceled(obj) && isRecordClosed(obj) && (
                            <div className={styles.statusPr}><i>{t('nulled')}</i></div>
                          )}

                          {isRecordOnGoing(obj) && (
                            <div className={styles.statusPr}><i>{t('ongoing')}</i></div>
                          )}

                          {!isRecordCanceled(obj) && isRecordClosed(obj) && (
                            <div className={styles.statusPr}><i>{t('finished')}</i></div>
                          )}

                          { /* isRecordClosed is more like to check if it was canceled before being closed, when a pr is canceled before closed date start = date end */}
                          { /* also we currently only allow restoration of collab canceled records, in the future perhaps create a flag */ }
                          {isRecordCanceled(obj) && !isRecordClosed(obj) && isCollabChild(obj) && (
                          <PrActionButton
                          onClick={() => {
                            present({
                              cssClass: "my-css",
                              header: t("restore.record"),
                              message: t("restore.record.confirm"),
                              buttons: [
                                t("cancel"),
                                {
                                  text: t("yes"),
                                  handler: (d:any) => {
                                  
                                    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();

                                   
                                    let productionRecordId = obj.Id;
                        
                            
                                    productionController().undoCancel({workerCode:currentWorkerCode, Id_ProductionRecord:productionRecordId}).then((res) => {
      
                                        if(res?.data?.undone !== "ok"){
                                            throw "unexpected response";
                                        }
                                        feedbackService.notifyToast(t('record.cancel.undone.success'), "info");
                                        loadPageChecks();
                                        
                                    }).catch((res) => {
                                        feedbackService.notifyToast(t('failed.error.generic'), "error");
                                    });
                                  },
                                },
                              ],
                              onDidDismiss: (e:any) => {},
                            });
                          }}
                          btnType={"success"}
                          label={t("restore.record")}
                          iconPassed={arrowUndoCircleOutline}
                        />
                          )}


                          {!isRecordCanceled(obj) ? (
                          <PrActionButton
                          onClick={() => {
                            present({
                              cssClass: "my-css",
                              header: t("production.cancellation"),
                              message: t("production.cancel.confirm"),
                              buttons: [
                                t("cancel"),
                                {
                                  text: t("yes"),
                                  handler: (d:any) => {
                                  
                                    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();

                                   
                                    let productionRecordId = obj.Id;
                                    //todo, if parent  collabCancelParentRecord = true
                                    productionController()
                                      .cancelProduction({},currentWorkerCode, productionRecordId)
                                      .then((res:any) => {
                            
                                        console.log("RESULTADO CANCEL: ", res);
                                        if(!res?.data?.code){
                                          throw "failed to cancel";
                                        }

                                        feedbackService.notifyToast(t('canceled.successfully'), "success");
                                        setLoadingPage(true);
                                        loadPageChecks();
                                      })
                                      .catch((res:any) => {
                                        console.log("Failed cancel production");
                                        presentToast(
                                          t("production.cancel.failed"),
                                          3000
                                        );
                                      });
                                  },
                                },
                              ],
                              onDidDismiss: (e:any) => {},
                            });
                          }}
                          btnType={"danger"}
                          label={!isRecordCanceled(obj) && isRecordClosed(obj) ? t("null.record") : t("cancel")}
                          iconPassed={closeCircle}
                        />
                            ) : (<></>)}

                          </TableCell>
                      </TableRow>
                    )
                  )}

                </TableBody>
              </StyledTable>
            </TableContainer>
          </div>
        </div>
      </div>
      )}

    </InAppTemplate>
  );
}

export default ProductionHistory;
