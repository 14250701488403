import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useCallback, useEffect, useRef, useState } from "react";
import ProductionOrdersDao from "../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import useStateCallback from "../../../../barrel/hooks/useStateCallback";
import LoadingSpinnerImg from "../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../../InAppTemplate/InAppTemplate";
import styles from "./StockInOutStandard.module.scss";
function StockInOutStandard(props) {
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);


  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const {t} = useHxfTranslation();

  function isHidden(el) {
    return el.offsetParent === null;
  }

  const loadPageChecks = useCallback(() => {
    setLoadingPage(false);
  }, [setLoadingPage]);

  useEffect(() => {
    if (!didMount) {
      loadPageChecks();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,

    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/home" />
      <div className={styles.pageTitle}>{t('stock.in.out')}</div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
          <IonCardHeader
            className={`${styles.elemElement}`}
            onClick={() => {
              props.history.push({ pathname: "/feature/stock-in-out/add" });
            }}
          >
            <div className={styles.elemBackgroundContainer}>
              <LocalShippingIcon />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>{t('stock.in')}</div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t('add')}
            </IonCardTitle>
          </IonCardHeader>

          <IonCardHeader
            className={`${styles.elemElement}`}
            onClick={() => {
              props.history.push({ pathname: "/feature/stock-in-out/remove" });
            }}
          >
            <div className={styles.elemBackgroundContainer}>
              <LocalShippingIcon />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>{t('stock.out')}</div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t('remove')}
            </IonCardTitle>
          </IonCardHeader>
        </div>
      </div>
    </InAppTemplate>
  );
}

export default StockInOutStandard;
