import { useActionScreenScaleProductivityStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenScaleProductivity/ActionScreenScaleProductivityStore";
import { useProductionOrderSelectionStore } from "../../pages/FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";

function useProductivityScale(){

    
    const productionOrderSelectionStore = useProductionOrderSelectionStore();
    const scaleProductivityStore = useActionScreenScaleProductivityStore();
 
  
  
    const getProductivityScale = (options = {}) => {
      
      let countPrScale = productionOrderSelectionStore.productionRecord.scaleProductivity;
      let countActionScale = scaleProductivityStore.quantity;
    
      //checks on the production record and action scale productivity
      if(countActionScale !== ""){ //if action scale productivity defined  set scale
        return countActionScale;
      }
    
      return countPrScale;
    }
  
    return {getProductivityScale};
}

export default useProductivityScale;