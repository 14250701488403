import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import useLocalStorageHandler from "./useLocalStorageHandler";

/**
 *
 * hook to synchronize entity settings when an update is detected
 * @returns
 */
function useSyncSettings() {
  const { sessionState } = useGlobalState();
  const myStorageHandler = useLocalStorageHandler();

  function triggerReloadPage(){
    myStorageHandler.clearWaitingToReloadWhenPossible();
    localStorage.removeItem("lasttimeUpdateDetected"); //avoid false positive required update
    //window.location.reload();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  function allGoodContinueCheck(response:any){
    //other checks unrelated to update version
    let rel_att = response?.data?.response?.rel_att;
    if(rel_att){
 
      let onGoingUnavailability = rel_att?.onGoingUnavailability;
      if(onGoingUnavailability?.canEntityUserStopThisUnav){ //does current authenticated user have the 'power' to stop the unav? 
          window.location.href = "/feature/downtime/occurrence/" + onGoingUnavailability?.onGoingUnavailability?.Id_UnavailabilityOccurrence;

      }
    }

    return true;
  }

  function check(response: any) {

    if (process.env.NODE_ENV === "production"){
      //check if service worker update checker was killed by browser throttling etc
      let appInitiatedTs:any = localStorage?.getItem("appInitiatedTs") ? localStorage.getItem("appInitiatedTs") : 0;
      let appLastSwCheck:any =  localStorage?.getItem("appLastSwCheck") ? localStorage.getItem("appLastSwCheck") : 0;
      
      let tsNow = Math.floor(Date.now() / 1000);

      //check if initiated at more than enough time for the first service worker update check tick
      //tick is every 15 secs we check if app initiated at least 30 seconds ago and last time sw checked was more than 2 mins ago
      if(tsNow-parseInt(appInitiatedTs) > 30 && tsNow-parseInt(appLastSwCheck) > 120){
        triggerReloadPage();
      }
      //----
    }

    if(myStorageHandler.isWaitingToReloadWhenPossible()){
      triggerReloadPage();
      return false;
    }

    let version: any = parseInt(response?.headers?.ecvcur);
    let currentEntityVersion =
      sessionState?.userSessionData?.entityCacheCurrentVersion;
    if (currentEntityVersion === false || currentEntityVersion === "undefined") {
      return allGoodContinueCheck(response);
    }
    if (parseInt(version) <= parseInt(currentEntityVersion)) {
      return allGoodContinueCheck(response);
    }

    // force page reload now
    console.log("Detected entity settings change, forcing full page reload..!");
    triggerReloadPage();
    return false;
  }

  return { check };
}

export default useSyncSettings;
