import { useEffect, useState } from "react";
import entityUserController from "../../barrel/controllers/entityUserController";
import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import HxfAppServersService from "../../barrel/services/hxfAppServersService";
function DirectWorkstationConnect(){

    const [didMount,setDidMount] = useState(false);
    const { saveRefreshToken } = useLocalStorageHandler();
    function getQueryParam(param:any) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    useEffect(() =>{
        if(!didMount){
            localStorage.removeItem("refresh_token"); //remove current just to make sure it doesnt log in in the wrong one
            const directConnectToken = getQueryParam('directConnectToken');
            const serverToSet = getQueryParam('server');
            HxfAppServersService().setCurrentServer(serverToSet);
            entityUserController().directWorkstationConnectToken({directConnectToken:directConnectToken}).then((res) => {
                  let reftoken = res?.data?.response?.refresh_token;
                  if(!reftoken){
                    throw "unexpected err";
                  }
                  saveRefreshToken(reftoken);
   
                  window.location.href = "/";
            }).catch((res) => {
                alert('Failed to connect');
                //redirect to login instead
            });
            setDidMount(true);
        }
    },[didMount]);

    return (<div style={{margin:10,fontWeight:900}}>Direct Workstation Connecting...</div>)
}
export default DirectWorkstationConnect;