import { TextField } from "@mui/material";
import { useState } from "react";
import styles from "./HxfTextfieldNumber.module.scss";
interface IProps{
    error:boolean;
    required?:boolean;
    label:string;
    placeholder?:any;
    value?:any;
    onChange?:any;
    disabled?:any;
    style?:any;
    centerInputText?:boolean;
    onBlur?:any;
    onFocus?:any;
    endAdornment?:any;
    startAdornment?:any;
    fullWidth?:any;

    dataInputFieldAttributeValue?:any; // for testing purposes

    customClickOverride?:boolean;
    onCustomClick?:any; // if set, disables normal onClick textfield 
    defaultValue?:any;
}

function HxfTextfieldNumber(props:IProps){
    console.log("rerender");
    const isUsingDefaultValue = props?.defaultValue !== undefined;

    const [defValue, setDefValue] = useState(isUsingDefaultValue ? props.defaultValue : null);

    const readFormattedValue = () => {
        let value = isUsingDefaultValue ? defValue : props?.value;
        if(!value){
            return "";
        }
        return value;
    }

    const getInputProps = () => {

        let fullObj = {};

        if(props.endAdornment){
            fullObj = {...fullObj, endAdornment:props.endAdornment};
        }

        if(props.startAdornment){
            fullObj = {...fullObj, startAdornment:props.startAdornment};
        }

        return fullObj;
    }

    const getSx = () => {
        let sxElem = {input:{}};
        if(props?.centerInputText){
            sxElem.input = {textAlign: "center"};
        }
        return sxElem;
    }



    const getStyle = () => {
        if(props.style){
            return props.style;
        }
        return {};
    }
    
    return (
        <TextField
        className={styles.noSpinner}
        onMouseDown={(event) => {
            if(props.customClickOverride && props?.onCustomClick){
                event.preventDefault();
                props.onCustomClick();
            }
            
        }}
        onTouchStart={(event) => {
            if(props.customClickOverride && props?.onCustomClick){
                event.preventDefault();
                props.onCustomClick();
            }
        }}
        onBlur={() => {
            if(props?.onBlur){
                props.onBlur();
            }
        }}
        onFocus={() => {
            if(props?.onFocus){
                props.onFocus();
            }
        }}
       
        /*
        helperText="testxx"
        
        FormHelperTextProps={{
            style: {
              textAlign: 'center'
            }
        }}*/
        placeholder={props?.placeholder ? props.placeholder : null}
        autoComplete={'off'}
		disabled={props.disabled}						
        error={props.error}
        required={props?.required}
        label={props.label}
        inputProps={{ 
            style:getStyle(),
            inputMode:"decimal" //shows keypad number and comma on mobile
        }}
        value={readFormattedValue()}

        onChange={(evt) => {
        
            if(props?.onChange){
              
               
                let value:any = evt.target.value;
                
                value = value.toString();
                value = value.replaceAll(",","."); //number  replace all "," with "." decimals
                value = value.replaceAll("e","");
                if(value === ""){
                    props.onChange("");
                    if(isUsingDefaultValue){
                        setDefValue("");
                    }
                }
            
    
        
                let isValidNumber = !isNaN(value) && !isNaN(parseFloat(value)); // ...and ensure strings of whitespace fail
                if(isValidNumber){
                    console.log("Yes valid nr");
                    props.onChange(value);
                    if(isUsingDefaultValue){
                        setDefValue(value);
                    }
                }
            }

        
        }}
        variant="outlined"
        fullWidth={props?.fullWidth}
        InputProps={getInputProps()}
        sx={getSx()}
        data-input-field={props?.dataInputFieldAttributeValue}
    />
    )
}

export default HxfTextfieldNumber;