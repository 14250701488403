function MachineIdentifierService(){

    const getMachineIdentifierFormatQrcode = () => {
        return "SFSMACH-[machine.id]";
    }

    const getMachineAndSegmentIdentifierFormatQrcode = () => {
        return "SFSMACHANDSEG-[machine.id]-[machinesegment.id]";
    }

    return {getMachineAndSegmentIdentifierFormatQrcode,getMachineIdentifierFormatQrcode};
}
export default MachineIdentifierService;