import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import { useCallback, useEffect, useRef, useState } from "react";
import punchclocksController from "../../../barrel/controllers/punchclocksController";
import ProductionOrdersDao from "../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import { useIonToast } from "@ionic/react";
import styles from "./Punchclock.module.scss";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import SystemSoundService from "../../../barrel/services/systemSoundService";
import { t } from "i18next";
import { Trans } from 'react-i18next';
import useSyncSettings from "../../../barrel/hooks/useSyncSettings";

function Punchclock(props: any) {
  const { sessionState } = useGlobalState();
  const myLocalStorage = useLocalStorageHandler();
  const [didMount, setDidMount] = useState(false);
  const syncSettings = useSyncSettings();
  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [presentToast] = useIonToast();
  const feedbackService = useFeedbackService();
  const [punchclockActionConfirmation, setPunchclockActionConfirmation] =
    useState<any>(false);

  const [loadingPage, setLoadingPage] = useState(true);

  const [stage, setStage] = useState<"ACTION" | "SUCCESS_IN" | "SUCCESS_OUT">(
    "ACTION"
  );

  const timerRedir = useRef<any>(null);

  const maxTimeToConfirmAction = 10; //Seconds
  const timerCountdown = useRef<any>(null);
  const countdownVal = useRef<number>(maxTimeToConfirmAction);
  const [countdownValUI, setCountdownValUI] = useState<number>(
    maxTimeToConfirmAction
  );

  const overridingPunchclockMode =
    props?.history?.location?.state?.usePunchclockMode;
  const shouldHideConfirmPunchOut =
    props?.history?.location?.state?.hidePunchout;
  const getPunchclockMode = useCallback(() => {
    if (overridingPunchclockMode) {
      return overridingPunchclockMode;
    }
    return sessionState.userSessionData.punchclockMode;
  }, [overridingPunchclockMode, sessionState.userSessionData.punchclockMode]);

  const isHideArrowback = () => {
    if (
      getPunchclockMode() === "DEDICATED_DETECT" ||
      getPunchclockMode() === "DEDICATED_SELECT"
    ) {
      return true;
    }

    if (stage === "SUCCESS_OUT") {
      return true;
    }

    return false;
  };

  const dedicatedRedirectIdentify = useCallback(() => {
    myLocalStorage.clearWorkerIdentificationCode();
    props.history.push({
      pathname: "/worker-identify",
      state: {
        pageToAccessAfterIdentification: "/feature/punch-clock",
        options: { hideToolbar: true },
      },
    });
  }, [myLocalStorage, props.history]);

  const countdownTick = useCallback(() => {
    timerCountdown.current = setTimeout(() => {
      countdownVal.current = countdownVal.current - 1;
      setCountdownValUI(countdownVal.current);
      console.log("tick: ", countdownVal.current);
      if (countdownVal.current > 0) {
        countdownTick();
      } else {
        feedbackService.notifyToast("You took too long!", "warning");
        clearTimeout(timerCountdown.current);
        dedicatedRedirectIdentify();
      }
    }, 1000);
  }, [countdownVal, dedicatedRedirectIdentify, feedbackService]);
  const startCountdown = useCallback(() => {
    setCountdownValUI(maxTimeToConfirmAction);
    countdownVal.current = maxTimeToConfirmAction;
    countdownTick();
  }, [countdownVal, countdownTick]);

  const clearDedicatedSelectTimerWaitingConfirm = () => {
    if (timerCountdown.current) {
      clearTimeout(timerCountdown.current);
    }
  };

  const initiatePunchclock = useCallback(
    (direction: "IN" | "OUT" | "AUTO", options = {}) => {
      let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();

      setLoadingPage(true);
      punchclocksController()
        .punchclockAuto(currentWorkerCode, options)
        .then((res: any) => {
          setLoadingPage(false);

         
          let resultedAction = res?.data?.response?.resultedAction;

          /*
        
          if (res?.data?.response?.warning === "error.actionDoesntMatch") {
            //exception that isnt an error, in this specific situation and should warn even though it closed a punchclock registration in the background, warning occurs when user has no shifts but punchclocks
            feedbackService.notifyToast(
              "The system is expecting a punch clock IN.",
              "error",
              { duration: 5000 }
            );
            return;
          }*/
          if (!resultedAction) {
            throw "Unexpected resulted action.";
          }

          if (resultedAction === "OUT") {
            setStage("SUCCESS_OUT");
            myLocalStorage.clearWorkerIdentificationCode();

            if (
              getPunchclockMode() === "MANUAL_DETECT" ||
              getPunchclockMode() === "MANUAL_SELECT"
            ) {
              timerRedir.current = setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          }

          if (resultedAction === "IN") {
            setStage("SUCCESS_IN");
            setLoadingPage(false);
            if (
              getPunchclockMode() === "MANUAL_DETECT" ||
              getPunchclockMode() === "MANUAL_SELECT"
            ) {
              let redirection =
                props?.history?.location?.state?.pageToAccessAfterPunchclockIn;
              if (!redirection) {
                redirection = "/";
              }
              timerRedir.current = setTimeout(() => {
                props.history.push(redirection);
              }, 2000);
            }
          }

          if (
            getPunchclockMode() === "DEDICATED_DETECT" ||
            getPunchclockMode() === "DEDICATED_SELECT"
          ) {
            timerRedir.current = setTimeout(() => {
              dedicatedRedirectIdentify();
            }, 3000);
          }
        })
        .catch((res) => {
          setLoadingPage(false);

          let errors = res?.response?.data?.response?.issues?.errors;

          if (errors && errors.includes("error.actionDoesntMatch")) {
            if (direction === "AUTO") {
              feedbackService.notifyToast(
                "The system is not expecting this type of punch clock. Try again later or contact us if the error persists.",
                "error"
              );
            } else if (direction === "IN") {
              feedbackService.notifyToast(
                "The system is expecting a punch clock OUT.",
                "error",
                { duration: 5000 }
              );
            } else if (direction === "OUT") {
              feedbackService.notifyToast(
                "The system is expecting a punch clock IN.",
                "error",
                { duration: 5000 }
              );
            }
          } else {
            feedbackService.notifyToast(
              "An unexpected error occurred. Try again or contact us if the error persists.",
              "error"
            );
          }

          if (
            getPunchclockMode() === "DEDICATED_DETECT" ||
            getPunchclockMode() === "DEDICATED_SELECT"
          ) {
            dedicatedRedirectIdentify();
          }
        });
    },
    [
      getPunchclockMode,
      dedicatedRedirectIdentify,
      myLocalStorage,
      feedbackService,
      props.history,
    ]
  );

  const loadPageChecks = useCallback(() => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    punchclocksController()
      .getNextPunchclockAction(currentWorkerCode)
      .then((res) => {
        syncSettings.check(res);
        let nextAction = res?.data?.response?.next;
        if (!nextAction || (nextAction !== "IN" && nextAction !== "OUT")) {
          throw "unexpected error, didnt find next action";
        }
        setPunchclockActionConfirmation(nextAction);
        setLoadingPage(false);
      })
      .catch((res) => {
        //error show
        setLoadingPage(false);
      });
  }, [setLoadingPage, myLocalStorage,syncSettings]);

  useEffect(() => {
    if (!didMount) {
      if (getPunchclockMode() === "MANUAL_DETECT") {
        loadPageChecks();
      } else if (getPunchclockMode() === "DEDICATED_DETECT") {
        initiatePunchclock("AUTO");
      } else if (
        getPunchclockMode() === "MANUAL_SELECT" ||
        getPunchclockMode() === "DEDICATED_SELECT"
      ) {
        if (getPunchclockMode() === "DEDICATED_SELECT") {
          //reinitiateTimerPleaseConfirm();
          startCountdown();
        }
        setPunchclockActionConfirmation("SELECT");
        setLoadingPage(false);
      }

      setDidMount(true);
    }
  }, [
    didMount,
    initiatePunchclock,
    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
    startCountdown,
    getPunchclockMode,
  ]);

  useEffect(() => {
    return () => {
      if (timerRedir.current) {
        clearTimeout(timerRedir.current);
      }

      if (timerCountdown.current) {
        clearTimeout(timerCountdown.current);
      }
    };
  }, []);

  const isExitDisabled = () => {
    let punchclockMode = getPunchclockMode();

    let res =
      punchclockMode === "DEDICATED_DETECT" ||
      punchclockMode === "DEDICATED_SELECT";

    return res;
  };
  return (
    <InAppTemplate disableExitWorkerClickable={isExitDisabled()}>
      {!isHideArrowback() && <CustomArrowBack pushUrl="/home" />}

      <div className={styles.pageTitle}>{t("punchclock")}</div>
      {loadingPage && <LoadingSpinnerImg />}

      {stage === "SUCCESS_OUT" && (
        <div className={styles.successOut}>
          <div className={styles.successOutMsg}>{t("goodbye")}</div>
        </div>
      )}
      {stage === "SUCCESS_IN" && (
        <div className={styles.successOut}>
          <div className={styles.successOutMsg}>

          <Trans i18nKey="welcome.worker" values={{worker:sessionState.userSessionData.workerName}}>
            Welcome back, <b>{sessionState.userSessionData.workerName}</b>!
          </Trans>
           
          </div>
        </div>
      )}

      {stage === "ACTION" && (
        <>
          {!loadingPage && (
            <div className={styles.optionsContainer}>
              {getPunchclockMode() === "DEDICATED_SELECT" && (
                <div className={styles.pleaseConfirmPunchclockSection}>
                  <div>
                    <div className={styles.confirmMessage}>
                      {t("please.confirm.punchclock")}
                    </div>
                    <div className={styles.confirmCountdown}>
                      {countdownValUI}
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.optionsContent}>
                {(punchclockActionConfirmation === "IN" ||
                  punchclockActionConfirmation === "SELECT") && (
                  <IonCardHeader
                    className={`${styles.elemElement} ${styles.elementEntry}`}
                    onClick={() => {
                      clearDedicatedSelectTimerWaitingConfirm();
                      if (getPunchclockMode() === "MANUAL_SELECT") {
                        initiatePunchclock("IN", { expectedNextAction: "IN" });
                      } else {
                        initiatePunchclock("AUTO", {
                          expectedNextAction: "IN",
                        });
                      }
                    }}
                  >
                    <div className={styles.elemBackgroundContainer}>
                      <LoginIcon />
                    </div>
                    <IonCardSubtitle>
                      <div className={styles.elemElementTitle}>
                        {t("punch.in")}
                      </div>
                    </IonCardSubtitle>
                    <IonCardTitle className={styles.elemElementDescription}>
                        {t("confirm.entry")}
                    </IonCardTitle>
                  </IonCardHeader>
                )}

                {!shouldHideConfirmPunchOut &&
                  (punchclockActionConfirmation === "OUT" ||
                    punchclockActionConfirmation === "SELECT") && (
                    <IonCardHeader
                      className={`${styles.elemElement} ${styles.elementExit}`}
                      onClick={() => {
                        clearDedicatedSelectTimerWaitingConfirm();
                        if (getPunchclockMode() === "MANUAL_SELECT") {
                          initiatePunchclock("OUT", {
                            expectedNextAction: "OUT",
                          });
                        } else {
                          initiatePunchclock("AUTO", {
                            expectedNextAction: "OUT",
                          });
                        }
                      }}
                    >
                      <div className={styles.elemBackgroundContainer}>
                        <ExitToAppIcon />
                      </div>
                      <IonCardSubtitle>
                        <div className={styles.elemElementTitle}>
                        {t("punch.out")}
                        </div>
                      </IonCardSubtitle>
                      <IonCardTitle className={styles.elemElementDescription}>
                        {t("confirm.exit")}
                      </IonCardTitle>
                    </IonCardHeader>
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </InAppTemplate>
  );
}

export default Punchclock;
