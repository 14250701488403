import styles from "./PopsWithUnavailablePreviousProductionRecord.module.scss";
import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PopsWithUnavailablePreviousProductionRecordConfirmationButtons from "./PopsWithUnavailablePreviousProductionRecordConfirmationButtons";
function PopsWithUnavailablePreviousProductionRecord(props: any) {
  console.log("PopsWithUnavailablePreviousProductionRecord", props);

  const noMoreProblematicPops = () => {
    return props.arrayPopsWithUnavailablePreviousProductionRecord.length === 0;
  };

  const getOperationElement = (obj: any, index: number) => {
    let popId = obj;
    let productLabel = "";
    let orderCode = "";
    let orderName = "";
    for (let i = 0; i < props.addedPopsArray.length; i++) {
      if (props.addedPopsArray[i].Id_ProductionOrder_Product === popId) {
        productLabel = props.addedPopsArray[i].name;
        orderCode = props.addedPopsArray[i].productionOrderCode;
        orderName = props.addedPopsArray[i].productionOrderName;
        break;
      }
    }

    return (
      <IonCardHeader className={`${styles.elemElement}`} onClick={() => {}}>
        <div className={styles.elemBackgroundContainer}>
          <LocalShippingIcon />
        </div>
        <IonCardSubtitle>
          <div className={styles.orderDescContainer}>
            <div>
              <b>Order:</b>
            </div>
            <div>
              <div className={styles.orderDescElem}>({orderCode})</div>
              <div className={styles.orderDescElem}>{orderName}</div>
            </div>
          </div>
        </IonCardSubtitle>
        <IonCardTitle className={styles.elemElementDescription}>
          <div className={styles.elemElementTitle}>
            <b>Product:</b> {productLabel}
          </div>
        </IonCardTitle>
        <div className={styles.removePopBtnContainer}>
          <IonButton
            color="danger"
            className={styles.popRemoveBtn}
            onClick={() => {
              props.onRemovePopByIdCallback(obj);
            }}
          >
            <IonIcon slot="icon-only" size="large" icon={closeOutline} />
          </IonButton>
        </div>
      </IonCardHeader>
    );
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.selectedOperationInfo}>
          <div>Operation: {props.currentSelectionData.operationCode}</div>
        </div>
        {noMoreProblematicPops() ? (
          <>
            <div className={styles.contentTitleSolved}>
              <div>
                All Problematic operations <b>removed!</b>
              </div>
            </div>
            <div className={styles.contentSubTitle}>
              <div>
                <b>Please try again</b> now that the problematic operations have
                been removed
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.contentTitle}>
              <div>
                Some operation records are <b>no longer available</b>
              </div>
            </div>
            <div className={styles.contentSubTitle}>
              <div>
                <b>Please remove</b> the following products from your
                multi-production before continuing and/or edit your
                multi-production
              </div>
            </div>
            <div className={styles.contentSubTitleSmall}>
              Some operations are associated with a no longer available previous
              operation record, perhaps because the selected productions might
              have been selected by another worker at the same time.
            </div>

            <div className={styles.operationsContainer}>
              {props.arrayPopsWithUnavailablePreviousProductionRecord.map(
                (row: any, index: number) => (
                  <div
                    key={"popsWithUnavPrevRecord_" + index}
                    className={`${styles.activeProductionElement}  `}
                  >
                    {getOperationElement(row, index)}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
      <PopsWithUnavailablePreviousProductionRecordConfirmationButtons
        onClickEditCallback={props.onClickEditCallback}
        onClickTryAgain={props.onClickTryAgain}
        enabled={true}
      />
    </>
  );
}

export default PopsWithUnavailablePreviousProductionRecord;
