import CustomErrorNotice from "../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
function InternetRequiredPage() {
  return (
    <InAppTemplate>
      <CustomErrorNotice
        title="Network Access Required"
        description="You must be connected to the internet."
        secondDescription="Refresh the app once you are connected to the internet."
      />
    </InAppTemplate>
  );
}

export default InternetRequiredPage;
