import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import { useProductionOrderSelectionStore } from "../../../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import ActionScreenTopBar from "../../../Components/ActionScreenTopBar";
import useActionScreenTopBar from "../../../Components/hooks/useActionScreenTopBar";
import styles from "./ActionRegisterProductSerializationAutoView.module.scss";
import { Trans } from "react-i18next";
import { useActionScreenScaleProductivityStore } from "../../../ActionScreenScaleProductivity/ActionScreenScaleProductivityStore";
import useProductivityScale from "../../../../../../../../barrel/hooks/useProductivityScale";
import { useActionScreenManagerStore } from "../../../../ActionScreensManager/ActionScreenManagerStore";

interface IResult {
  irregularitysList: any;
}

interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}

function ActionRegisterProductSerializationAutoView(props: IProps) {
  const actTopBar = useActionScreenTopBar();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();

  const {t} = useHxfTranslation();
  const currentActionScreenManager = useActionScreenManagerStore();
  const productivityScale = useProductivityScale();


  const getProductsCount = () => {

    let isSingleReservation = currentActionScreenManager?.currentActionConfig?.singleReservation === 1;
    if(isSingleReservation){
      return 1;
    }
    return productivityScale.getProductivityScale();
  }

  return (
    <>
      <div className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('register.product.serialization')}
        />

        <div className={styles.content}>
          <div className={styles.popupInfoQuestion}>
          
          {getProductsCount() === 1 ? (
              <Trans
              i18nKey="sure.generate.serials.single" // optional -> fallbacks to defaults if not provided
              defaults="Are you sure you want to generate serial numbers to <bold>{{countProducts}}</bold> product?" // optional defaultValue
              values={{ countProducts:getProductsCount()}}
              components={{ bold: <b />}}
            />
          ) : (
            <Trans
            i18nKey="sure.generate.serials" // optional -> fallbacks to defaults if not provided
            defaults="Are you sure you want to generate serial numbers to <bold>{{countProducts}}</bold> products?" // optional defaultValue
            values={{ countProducts:getProductsCount()}}
            components={{ bold: <b />}}
            />
          )}

          </div>

          <div className={`${styles.selectionOuter}`}></div>

        </div>
      </div>


      <div className={styles.confirmationButtons}>

        <div className={styles.confirmBtnContainer}>
            <IonButton
              className={styles.confBtn}
              onClick={() => {

                let scaleProductivityVal = getProductsCount();
                
                if(scaleProductivityVal <= 0){
                  throw "unexpected scale productivity value";
                }
                props.callbackSuccessConfirm(
                  {method:'AUTO_GENERATE',quantity:scaleProductivityVal}
                );
              }}>
              <div className={styles.btnIcon}>
              
              </div>
              {t('confirm')}
            </IonButton>

        </div>

        <div className={styles.confirmBtnContainer}>
            <IonButton
                    className={`${styles.confBtn} ${styles.cancelBtn} `}
                    color="danger"
                    onClick={() => {
                        props.callbackOnClose();
                        actTopBar.closeActionWindows();
                    }}
                >
                    {t('cancel')}
            </IonButton>

        </div>
      </div>

    </>
  );
}
export default ActionRegisterProductSerializationAutoView;
