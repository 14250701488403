import { useEffect, useMemo, useRef, useState } from "react";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import HxfTextfieldNumber from "../../../../../HxfTextfieldNumber/HxfTextfieldNumber";
import { useActionScreenMatchFixedVariablesStore } from "../ActionScreenMatchFixedVariablesStore";

interface IProps{
    startAdornment?:any;
    endAdornment?:any;
    customClickOverride?:any;
    obj?:any;
    onCustomClick?:any;
    error?:any;
    defaultValue?:any;
    onChange?:any;
    onFocus?:any;
    onBlur?:any;
    index?:any; 
 

}

function ASMFVFixedVariableNumberInput(props:IProps){
    const {t} = useHxfTranslation();

    const [didMount, setDidMount] = useState(false);

    const screenState = useActionScreenMatchFixedVariablesStore();

    const [pendingValueChange, setPendingValueChange] = useState<any>(null);
    const [pendingCustomClick, setPendingCustomClick] = useState<any>(false);
    const perfTimer = useRef<any>(null);

    useEffect(() => {
        if(!didMount){
            if(props?.index == 0){
                /* setInterval(() => {
                     console.log("DBG state: ",screenState);
                 },1000);*/
                 
            }
            console.log("RENDERED INPUT");
            setDidMount(true);
        }

    },[didMount]);

    const getInputCalc = () => {
        return (
            <HxfTextfieldNumber
              dataInputFieldAttributeValue={props.obj.code}
              fullWidth={true}
              startAdornment={props.startAdornment}
              endAdornment={props.endAdornment}
              customClickOverride={props.obj?.fillAutoCalc && props.obj?.fillAutoCalc !== null }
              onCustomClick={() => {
                //setPendingCustomClick(true);
                if(props?.onCustomClick){
                    console.log("deploying custom click");
                    props.onCustomClick();
                }
              }}
              onFocus={() => {
             
    
              }}
              onBlur={() => {
                
              }}
              centerInputText={true} error={props.error} label={props.obj.name} placeholder={t('value')}
              onChange={(evt:any) => {
             
                console.log("PRECHANGE");
                console.log("DBG state: ",screenState);

                //not changing directly here because when we setPendingValueChange it forced the current memoized component to rerender (having access to the updated store states)
                //and only then deploy the value to the onChange (that dispatches it to the store)
                setPendingValueChange(evt);
           
              }} defaultValue={props.defaultValue}/> 
       
          )
    }

    useEffect(() => {
        if(pendingValueChange !== null){
            console.log("deploying value change");

            props.onChange(pendingValueChange);
            setPendingValueChange(null);
     
        }
    },[pendingValueChange]);

    /*
    useEffect(() => {
        if(pendingCustomClick !== false){

            setPendingCustomClick(false);
            if(props?.onCustomClick){
                console.log("deploying custom click");
                props.onCustomClick();
            }
        }
    },[pendingCustomClick]);*/
    
    const memoizedInput = useMemo(() => {
        return getInputCalc();
    },[props.defaultValue,pendingValueChange, props.error]);

    return (<>{memoizedInput}</>)


}

export default ASMFVFixedVariableNumberInput;