function GenericListJsonService(){

    function getUrlFromOptions(urlListJson:any, extraOptions:any){
        let url = urlListJson;
        if(extraOptions){
            let optionKeys = Object.keys(extraOptions);
            let isFirstElementAdded = true;
            for(let i = 0; i< optionKeys.length; i++){
                let key = optionKeys[i];
                let value = extraOptions[key];
                if(value){
                    if(isFirstElementAdded){
                        isFirstElementAdded = false;
                    }else{
                        url = url + "&";
                    }
                    url = url + key + "=" + value;
                }
            }    
        }

        return url;
    }


    return {getUrlFromOptions};
}

export default GenericListJsonService;