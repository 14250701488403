import { IonButton, IonIcon, IonLoading } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import labelsController from "../../../../barrel/controllers/labelsController";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import HxfAppServersService from "../../../../barrel/services/hxfAppServersService";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import CustomLoadingAction from "../../../../components/CustomLoadingAction";
import { useActionScreenManagerStore } from "../../ProductionUI/ProductionOperationConfirmation/ActionScreensManager/ActionScreenManagerStore";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import PrintSerialLabelProductConfirmationButtons from "./printLabelConfirmationButtons/printSerialLabelProductConfirmationButtons";
import styles from "./printSerialLabelProductPopup.module.scss";



interface IPrintSerialLabelProductSerializedProduct{
  Id?:any;
  serial?:any;
}

interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  onTerminateButton?:any;
  terminateButtonVisible?:boolean;
  title: string;
  Id_Label?:any;
  serializedProducts:IPrintSerialLabelProductSerializedProduct[];
  labelCode:string;
  labelName:string;
}
function PrintSerialLabelProductPopup(props: IPopup) {
  const {t} = useHxfTranslation();
  const timerCheckScanMobile = useRef<any>(null);
  const currentActionScreenManager = useActionScreenManagerStore();
  const feedbackService = useFeedbackService();
  const [didMount, setDidMount] = useState(false);
  const [isLoading,setIsLoading] = useState(true);
  const [printButtonEnabled, setPrintButtonEnabled] = useState(true);
  const [currentSerializedProductIndex,setCurrentSerializedProductIndex] = useState(0);
  

  const [renderPrintUrlKey, setRenderPrintUrlKey] = useState(0);

  const renderPrintUrl = useRef("");

  const getCurrentServerServe = () => {

    if (process.env.REACT_APP_MODE === "dev") {
      return process.env.REACT_APP_API_SERVE_DEV;
    } 

    let server = localStorage.getItem("srv");
    if (!server) {
      throw "x3Unexpected err, server has not been set yet";
    }

  
    return HxfAppServersService().getServerEndpoint(server);

  }

  const callPrintZebraLabelsNetwork = (genericLabelData:any,numberOfLabels:number) => {
    labelsController().generateZpl(genericLabelData).then((res:any) => {
      let zplData = res?.data?.response?.zplLabelConverted;

      let zplLabelFull = "";
      for(let i = 0; i<zplData.length; i++){
        zplLabelFull += zplLabelFull + "" + zplData[i];
      }
    
      initiateCustomListenerForMobile();
      setPrintButtonEnabled(false);
      MobileWrapperCommunicationService().notifyPrintLabel(zplLabelFull,numberOfLabels);
   }).catch((res) => {
    feedbackService.notifyToast(t("generic.critical.error"), "error");
   });
  }

  const initiateCustomListenerForMobile = () => {
    timerCheckScanMobile.current = setTimeout(() => {
      let valFound = localStorage.getItem("webcom_rn");

      if (!valFound || valFound === "") {
        initiateCustomListenerForMobile();
      } else {
        try {
          let receivedMessage = JSON.parse(valFound);
          let action = receivedMessage.action;
          let payload = receivedMessage.payload;
          if (action === "wrapcom_network_print_zpl_return") {
            clearTimeout(timerCheckScanMobile.current);
         
           
            if(payload.printStatus === "success"){
            
              feedbackService.notifyToast(t('print.label.success'), "success");
            }else if(payload.printStatus === "failed"){
              feedbackService.notifyToast(t('print.label.fail'), "error");
            }
        
            setPrintButtonEnabled(true);
          
          }else{
            initiateCustomListenerForMobile();
          }

 
        } catch (exception) {
          alert("critical error occured when trying to read rn message");
          initiateCustomListenerForMobile();
        }

        localStorage.setItem("webcom_rn", "");
      }
    }, 250);
  };

  const printLabel = () => {
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;

    let idSerializedProduct = props.serializedProducts[currentSerializedProductIndex].Id;

    let objParamsRenderPdf:any = {
      Id_Label: props.Id_Label,
      Id_SerializedProduct:idSerializedProduct
    };
    let queryBuilder:any = null;
    let isZebraLabel = currentActionConfig?.zebraLabel === 1;
    if(currentActionConfig?.zebraLabel === 1){
    
      if(currentActionConfig?.zebraLabelMode === "DIRECT_NETWORK_PRINT_ZPL"){
         
        callPrintZebraLabelsNetwork(objParamsRenderPdf, 1);

      }else{
        labelsController().getDirectViewToken(objParamsRenderPdf).then((res:any) => {
          
            let tokenDvt = res?.data?.response?.data?.token;
            if(!tokenDvt){
              throw "failed to fetch token";
            }
            objParamsRenderPdf["dvt"] = tokenDvt;
            let queryBuilder = new URLSearchParams(objParamsRenderPdf);

            if(isZebraLabel){
              let renderUrl:any = getCurrentServerServe() + "label/render-zpl/label.zpl?" + queryBuilder.toString() + "&x=label.zpl";
              window.location.href = renderUrl;
            }else{
              let renderUrl = getCurrentServerServe() + "label/render-pdf/label.pdf?" + queryBuilder.toString() + "&x=outputfile.pdf";
              console.log("GEN URL: ", renderUrl);
              MobileWrapperCommunicationService().notifyOpenPdf(renderUrl);
            }
          
        }).catch((res:any) => {
          feedbackService.notifyToast("Failed to fetch token", "error");
        });
      }
    }else if(MobileWrapperCommunicationService().isDeviceMobileWrapper()){

      labelsController().getDirectViewToken(objParamsRenderPdf).then((res:any) => {
        //1 get file visual token
        let tokenDvt = res?.data?.response?.data?.token;
        if(!tokenDvt){
          throw "failed to fetch token";
        }
        objParamsRenderPdf["dvt"] = tokenDvt;
        queryBuilder = new URLSearchParams(objParamsRenderPdf);
        let renderUrl = getCurrentServerServe() + "label/render-pdf/label.pdf?" + queryBuilder.toString() + "&x=outputfile.pdf";
        console.log("GEN URL: ", renderUrl);
        MobileWrapperCommunicationService().notifyOpenPdf(renderUrl);
      }).catch((res:any) => {
        feedbackService.notifyToast("Failed to fetch token", "error");
      });
     
    }else{
      labelsController().getDirectViewToken(objParamsRenderPdf).then((res:any) => {
        //1 get file visual token
        let tokenDvt = res?.data?.response?.data?.token;
        if(!tokenDvt){
          throw "failed to fetch token";
        }
        objParamsRenderPdf["dvt"] = tokenDvt;
        queryBuilder = new URLSearchParams(objParamsRenderPdf);
        let renderUrl = getCurrentServerServe() + "label/print-pdf/?" + queryBuilder.toString() + "&x=outputfile.pdf";
        console.log("GEN URL: ", renderUrl);
        renderPrintUrl.current = renderUrl;
        setRenderPrintUrlKey(renderPrintUrlKey + 1);
      }).catch((res:any) => {
        feedbackService.notifyToast("Failed to fetch token", "error");
      });

      /*
      queryBuilder = new URLSearchParams(objParamsRenderPdf);

      let labelRenderUrl =getCurrentServerServe() +
      "label/print-pdf/?" +
      queryBuilder.toString();
  
      renderPrintUrl.current = labelRenderUrl;
      setRenderPrintUrlKey(renderPrintUrlKey + 1);*/
    }
  
   
  }
  /*
  if (!props?.productData) {
    return <></>;
  }
  */
  useEffect(() => {
    if(!didMount){
      //todo show 0/y, print, render label etc..
      setIsLoading(false);
      setDidMount(true);
    }
    
  },[didMount]);

  if(isLoading){
    return (
      <CustomLoadingAction/>
    )
  }

  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup>
        <div key={"printLblPop_" + renderPrintUrlKey}>
              <iframe
              style={{display:'none'}}
              name="seriallabel_print_output"
              src={
                renderPrintUrl.current
              }
              width="0"
              height="0"
              onLoad={() => {
                console.log("finished loading");
                /*printShownCount.current = printShownCount.current + 1;
                checkIfFinishedPrints();*/
              }}
            ></iframe>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.selectedProductInfo}>
              <div className={styles.subBarInfo}>
                {props.labelCode} ({currentSerializedProductIndex + 1}/{props.serializedProducts.length})
              </div>
            </div>
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
              <div className={styles.serialTxt}>
                <b>Serial </b>
              </div>
              <div className={styles.serialCode}>
                {props?.serializedProducts?.[currentSerializedProductIndex]?.serial}
              </div>
                
            </div>
          </div>

     
          <PrintSerialLabelProductConfirmationButtons
           terminateButtonVisible={props.terminateButtonVisible}
           previousEnabled={currentSerializedProductIndex !== 0}
           nextEnabled={currentSerializedProductIndex<props.serializedProducts.length - 1}
           onClickNext={() => {
            setCurrentSerializedProductIndex(currentSerializedProductIndex + 1);
           }}
           onClickPrevious={() => {
            setCurrentSerializedProductIndex(currentSerializedProductIndex - 1);
           }}
           onClickPrint={() => {
            printLabel();
           }}
           onClickTerminate={() => {
             if(props?.onTerminateButton){
               props.onTerminateButton();
             }
           }}
          />
        
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default PrintSerialLabelProductPopup;
