import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import styles from "./CustomErrorNotice.module.scss";

/**
 * props: description, secondDescription, goBackArrow (true / false), goBackFunction
 * @param {*} props
 * @returns
 */
function CustomErrorNotice(props) {
  return (
    <div className={styles.errorDivContainer}>
      <div className={styles.errorDivInner}>
        {props.goBackArrow && (
          <div
            className={styles.entityGoBack}
            onClick={() => {
              if (props.goBackFunction) {
                props.goBackFunction();
              }
            }}
          >
            <KeyboardArrowLeftIcon /> Go back
          </div>
        )}

        <IonCardHeader className={styles.errorDivElement}>
          <div className={styles.title}>
            <div className={styles.divTitleIcon}>
              <WarningAmberIcon />
            </div>
            <div>{props.title}</div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.description}>{props.description}</div>
          <div className={styles.secondDescription}>
            {props.secondDescription}
          </div>
          {props?.extraContent ? props.extraContent : ""}
        </IonCardHeader>
      </div>
    </div>
  );
  //return(<>{props.title} {props.description} </>)
}

export default CustomErrorNotice;
