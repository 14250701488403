function MultiProductionRecordDto() {
  const listMultiProductionRecordsToActiveMultiProductionRecords = (
    listMultiProduction: any
  ) => {
    let activeMultiProductions = [];

    for (let i = 0; i < listMultiProduction.length; i++) {
      let idMultiProductionRecord = listMultiProduction[i]["Id"];
      let dateStart = listMultiProduction[i]["dateStart"];

      let operationCode = listMultiProduction[i]["temp_OperationCode"];
      let activeMultiProduction = {
        Id_MultiProduction: idMultiProductionRecord,
        operationCode: operationCode,
        dateStart: dateStart,
      };
      activeMultiProductions.push(activeMultiProduction);
    }
    return activeMultiProductions;
  };

  return { listMultiProductionRecordsToActiveMultiProductionRecords };
}

export default MultiProductionRecordDto;
