import BarrelWrapper from "./barrelWrapper";
import HxfAppServersService from "../services/hxfAppServersService";
function warehouseLocationsController() {
  const getWarehouseLocationsListJson = (extraOptions: any = null) => {
    let url =
      HxfAppServersService().getBackend() + "warehouse-location/listJson/?";
    if (extraOptions !== null) {
      let optionKeys = Object.keys(extraOptions);
      let addedFirst = false;
      for (let i = 0; i < optionKeys.length; i++) {
        let key = optionKeys[i];
        let value = extraOptions[key];
        if (value !== "") {
          if (!addedFirst) {
            addedFirst = true;
          } else {
            url = url + "&";
          }
          url = url + key + "=" + value;
        }
      }
    }

    return BarrelWrapper().appPostRequest(url, {});
  };

  return { getWarehouseLocationsListJson };
}

export default warehouseLocationsController;
