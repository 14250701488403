import { IonContent, IonLoading, IonPage } from "@ionic/react";

function LoadingApp() {
  return (
    <IonPage>

      <IonContent fullscreen>
        <div style={{width:'100%',height:'100%',backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center'}}> 
              <IonLoading
              isOpen={true}
              
              message={'Loading...'}
            
            />
        </div>

      </IonContent>
    </IonPage>
  );
}

export default LoadingApp;
