import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import styles from "./UnavailabilityActionConsumeMaterialConfirmButtons.module.scss";


interface IProps{
    onConfirm?:any;
}
function UnavailabilityActionConsumeMaterialConfirmButtons(props:IProps){
    const {t} = useHxfTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.lineBreaker}></div>
            <div className={styles.buttonsContainer}>
            <div className={styles.confirmationContainer}>
                <div>
                <div>
                    <IonButton
                    disabled={false}
                    className={styles.confirmButton}
                    onClick={() => {
                        props.onConfirm();
                    }}
                    >
                    {t("done")}
                    </IonButton>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default UnavailabilityActionConsumeMaterialConfirmButtons;