import { IonButton, IonIcon } from "@ionic/react";
import i18next from "i18next";
import { arrowUndoCircleOutline, closeOutline } from "ionicons/icons";
import { useState } from "react";
import operationProductActionController from "../../../../barrel/controllers/operationProductActionController";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import HxfOverlayLoading from "../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import SimpleContentPopup from "../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import AddingIrregularityProcessView from "./addingIrregularityProcess/AddingIrregularityProcessView";
import styles from "./AddIrregularityToPrPopup.module.scss";

interface IAddIrrPopupIrregularity{
    Id:any;
    code:any;
}

export interface AddIrrPopupProps {
    operationProductActionIrregularities:IAddIrrPopupIrregularity[];
    onClose?:any;
    productionRecordData:any;
}

const getProperpopupName = (objIrr:any) => {
    let replacedName = "---";
    if(objIrr.code === "ASK_PRODUCED_PRODUCT_IRREGULARITY"){
        replacedName = i18next.t('irregularities');
    }
    if(objIrr.code === "PRODUCE_TRANSFORMATION_FROM_MACHINE_ADJUSTMENT"){
        replacedName = i18next.t('short.machine.adjustment.transformation');
    }
    return (<>{replacedName}</>)
}
const AddIrregularityToPrPopup = (props:AddIrrPopupProps) => {
    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();
    const [isLoading, setIsLoading] = useState(false);
    const [stage,setStage] = useState<"SELECT_ACT"|"SELECT_IRR"|"PROCESS_IRREGULARITY">("SELECT_ACT");

    const [selectionData, setSelectionData] = useState<any>({});
    const [selectablePossibleIrregularities, setSelectablePossibleIrregularities] = useState([]);
    const [showAddingIrrProcessData,setShowAddingIrrProcessData] = useState<any>(null);

    return (<>
      <SimpleContentPopup>
        <div>

          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}>{t("add.irregularity")}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                 props.onClose();
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.content}>
            {isLoading && (
                <HxfOverlayLoading/>
            )}
                {stage === "SELECT_ACT" && (
                <div className={styles.selectOperActContainer}>
                    {t('select.operation.action')}
                </div>
                )}
                {stage === "SELECT_IRR" && (
                <div className={styles.selectIrrActContainer}>
                   <div>
                   <IonButton
                        color="primary"
                     
                        onClick={() => {
                            setStage("SELECT_ACT");
                        }}
                    >
                        <IonIcon slot="icon-only" size="large" icon={arrowUndoCircleOutline} />
                    </IonButton>
                    </div> <div className={styles.selIrrDiv}>{t('select.irregularity')}</div>
                </div>
                )}

                {stage === "PROCESS_IRREGULARITY" && (<>
                {/*<div className={styles.selectIrrActContainer}>
                  <div>
                   <IonButton
                        color="primary"
                        onClick={() => {
                            setShowAddingIrrProcessData(null);
                            setStage("SELECT_IRR");
                        }}
                    >
                        <IonIcon slot="icon-only" size="large" icon={arrowUndoCircleOutline} />
                    </IonButton>
                    </div> <div className={styles.selIrrDiv}>{t('process.irregularity')}</div>
                </div>*/}
                </>
                )}

                {stage === "SELECT_ACT" && (
                    <div className={styles.irrActContainer}>
                        {props.operationProductActionIrregularities.map((objActIrr:IAddIrrPopupIrregularity,idx:number) => (
                            <div key={"idx_irr_" + idx} className={styles.irrActSelect} onClick={() => {
                                //todo fetch irregularities data by Id_OperationAction
                                let idOperationAction = objActIrr.Id;
                                debugger;
                                setIsLoading(true);
                                operationProductActionController().fetchData({
                                    Id_OperationProductAction:idOperationAction,
                                    includeOPAPossibleIrregularitiesData:true,
                                    includeProductBom:true
                                }).then((res) =>{
                                    setIsLoading(false);
                                    let opaObj = res?.data?.object;
                                    if(!opaObj.Id){
                                        throw "unexpected res";
                                    }

                                    let opaPossibleIrregularities = res?.data?.opaPossibleIrregularities;
                                    if(!opaPossibleIrregularities || opaPossibleIrregularities.length === 0){
                                        feedbackService.notifyToast(t('no.possible.irregularities.found'), "info");
                                    }

                                    let selData:any = {operationProductActionData:opaObj};
                                    
                                    if(res?.data?.productBom){
                                        selData.productBom = res?.data?.productBom;
                                    }
                                    setSelectionData(selData);
                                    setSelectablePossibleIrregularities(opaPossibleIrregularities);
                                    setStage("SELECT_IRR");
                                   
                                }).catch((res) => {
                                    setIsLoading(false);
                                    feedbackService.notifyToast(t('failed.error.generic'),"error");
                                });
                            }}>
                                <div>
                                    {getProperpopupName(objActIrr)}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {stage === "SELECT_IRR" && (
                    <div className={styles.irrActContainer}>
                        {selectablePossibleIrregularities.map((objIrr:any,idx:number) => (
                            <div key={"idx_irr_" + idx} className={styles.irrActSelect} onClick={() => {
                               let addingIrrProcessData:any = {
                                operationProductActionData:selectionData.operationProductActionData,
                                irregularityData:objIrr
                               };

                               if(selectionData?.productBom){
                                addingIrrProcessData.productBom = selectionData.productBom;
                               }

                               setShowAddingIrrProcessData(addingIrrProcessData);
                               setStage("PROCESS_IRREGULARITY");
                            }}>
                                <div>
                                    {objIrr.code}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {(stage === "PROCESS_IRREGULARITY" && showAddingIrrProcessData !== null) && (
                    <AddingIrregularityProcessView onClose={() => {
                        props.onClose();
                    }} productionRecordData={props.productionRecordData} productBom={showAddingIrrProcessData?.productBom} operationProductActionData={showAddingIrrProcessData?.operationProductActionData} irregularityData={showAddingIrrProcessData?.irregularityData}/>
                )}

              <div className={styles.activeProductionsContainer}>
                <div className={styles.activeProductionsSection}></div>
              </div>
            </div>
          </div>
        </div>
      </SimpleContentPopup>
    </>)
}

export default AddIrregularityToPrPopup;