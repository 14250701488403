import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLoading,
  useIonToast,
} from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { t } from "i18next";
import { closeOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import productionController from "../../../../../barrel/controllers/productionController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import useProductionHook from "../../../../../barrel/hooks/useProductionHook";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import {
  dispatchCustomPopupsStore,
  useCustomPopupsStore,
} from "../CustomPopupsStore";
import styles from "./AssociatePreviousProductionRecordPopup.module.scss";
import AssociatePreviousProductionRecordPopupConfirmationButtons from "./AssociatePreviousProductionRecordPopupConfirmationButtons";

function AssociatePreviousProductionRecordPopup(props: any) {
  const { cancelToken, isCancel } = useCancelToken({
    alias: "AssociatePreviousProductionRecordPopup",
  });

  const productionHook = useProductionHook();
  const localStorage = useLocalStorageHandler();
  const popupsStore = useCustomPopupsStore();

  const [present, dismiss] = useIonToast();
  const [selectedProduction, setSelectedProduction] = useState(-1); // -1 No selection, -2 New Production, -3 automatic calculate
  const [confirmEnabled, setConfirmEnabled] = useState(true);
  const [didMount, setDidMount] = useState(false);
  const [shownPopup, setShownPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [executeAutoCalculate, setExecuteAutoCalculate] = useState(false);

  const previousAutoCalculate = usePrevious(executeAutoCalculate);
  const [storedNotAvailableItem, setStoredNotAvailableItem] = useState<any>({});

  const resetInternalPopupState = () => {
    setSelectedProduction(-1);
    setConfirmEnabled(true);
  };

  const getProperWorkerName = (row: any) => {
    let workerObj: any = row.workerData;
    if (
      typeof row?.workerData === "string" ||
      row.workerData instanceof String
    ) {
      workerObj = JSON.parse(row.workerData);
    }

    return <>{workerObj.name}</>;
  };

  const getProperOperationName = (row: any) => {
    let operationObj = row.operationData;
    if (
      typeof row?.workerData === "string" ||
      row.workerData instanceof String
    ) {
      operationObj = JSON.parse(row.operationData);
    }

    return <>{operationObj.name}</>;
  };

  const calculatePctProgressExecutedTimes = (row: any) => {
    let total = parseInt(row?.temp_executionNumberOfTimes);
    let val = parseInt(row?.temp_executedTimes);
    let pctRes = (val / total) * 100;

    if (pctRes < 25) {
      return "25%";
    }

    return "" + pctRes + "%";
  };

  const noOperationsAvailable = () => {
    return (
      popupsStore.associatePreviousProductionRecordPopupData
        ?.candidatePreviousProductionRecordsToAssociate?.length === 0 &&
      !popupsStore.associatePreviousProductionRecordPopupSettings
        ?.showButtonAutoCalculateLatestOperation
    );
  };
  const popupClose = useCallback(
    (closeReason = null) => {
      dispatchCustomPopupsStore({
        type: "CLOSE_ASK_PREV_PRODUCTIONRECORD_POPUP",
      });
      resetInternalPopupState();

      let allowRedirect = true;
      if (
        popupsStore?.associatePreviousProductionRecordsCustomCallbacks
          ?.onPopupClose
      ) {
        let returnPopupClose =
          popupsStore.associatePreviousProductionRecordsCustomCallbacks.onPopupClose(
            closeReason
          );
        if (returnPopupClose === "NO_REDIRECT") {
          allowRedirect = false;
        }
      }

      if (allowRedirect) {
        //force refresh data
        let redirectUrl =
          "/feature/production-order/" +
          popupsStore.associatePreviousProductionRecordPopupData
            .productionOrderId +
          "/po-product/" +
          popupsStore.associatePreviousProductionRecordPopupData.popId;

        if (
          popupsStore?.associatePreviousProductionRecordOnConfirmMultiPop
            ?.customConfirm
        ) {
          redirectUrl =
            popupsStore?.associatePreviousProductionRecordOnConfirmMultiPop
              ?.popupCloseRedirectTo;
        }
        props.history.push({
          pathname: "/page-redirector",
          state: { redirectTo: redirectUrl },
        });
      }
    },
    [popupsStore, props.history]
  );

  const hasInfoBarSettings = () => {
    if (
      popupsStore.associatePreviousProductionRecordInfoBarSettings
        ?.productionOrderCode
    ) {
      return true;
    }
    return false;
  };

  const getInfoBar = () => {
    let infoBarSettings =
      popupsStore.associatePreviousProductionRecordInfoBarSettings;
    return (
      <>
        <div className={styles.infoBarContainer}>
          <div className={styles.operationBar}>
            <div className={styles.operationLabel}>Operation:</div>
            <div className={styles.operationCodeAndNameContainer}>
              <div className={styles.flexCenter}>
                <div
                  className={`${styles.singleLineOverFlowElipsis} ${styles.operationBarCode}`}
                >
                  ({infoBarSettings.operationCode}){" "}
                </div>
              </div>
              <div className={styles.flexCenter}>
                <div
                  className={`${styles.operationBarName} ${styles.singleLineOverFlowElipsis}`}
                >
                  {infoBarSettings.operationName}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.productionOrderBar}>
            <div className={styles.productionOrderLabel}>Order:</div>
            <div className={styles.productionOrderCodeAndNameContainer}>
              <div className={styles.flexCenter}>
                <div
                  className={`${styles.singleLineOverFlowElipsis} ${styles.productionOrderBarCode}`}
                >
                  ({infoBarSettings.productionOrderCode})
                </div>
              </div>
              <div className={styles.flexCenter}>
                <div
                  className={`${styles.productionOrderBarName} ${styles.singleLineOverFlowElipsis}`}
                >
                  {infoBarSettings.productionOrderName}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.productBar}>
            <div className={styles.productionOrderLabel}>{t("product")}:</div>
            <div className={styles.productionOrderCodeAndNameContainer}>
              <div className={styles.flexCenter}>
                <div
                  className={`${styles.singleLineOverFlowElipsis} ${styles.productionOrderBarCode}`}
                >
                  ({infoBarSettings.productCode})
                </div>
              </div>
              <div className={styles.flexCenter}>
                <div
                  className={`${styles.productionOrderBarName} ${styles.singleLineOverFlowElipsis}`}
                >
                  {infoBarSettings.productName}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const executeConfirm = useCallback(
    (idSelectedPreviousProductionId: any) => {
      let workerCode = localStorage.getWorkerIdentificationCode();
      if (
        popupsStore?.associatePreviousProductionRecordOnConfirmMultiPop
          ?.customConfirm
      ) {
        dispatchCustomPopupsStore({
          type: "CLOSE_ASK_PREV_PRODUCTIONRECORD_POPUP",
        }); //clear current list etc..
        resetInternalPopupState();
        dispatchCustomPopupsStore({
          type: "TRIGGER_MULTI_POP_CONFIRM_ASK_PREVIOUS_PRODUCTION_RECORD",
          payload: {
            workerCode: workerCode,
            idOperation:
              popupsStore.associatePreviousProductionRecordPopupData
                .idOperation,
            idSelectedPreviousProductionId: idSelectedPreviousProductionId,
            popId: popupsStore.associatePreviousProductionRecordPopupData.popId,
            preActionsData: popupsStore.associatePreviousProductionRecordPopupData.preActionsData
          },
        });

        return;
      }


      productionController()
        .createProduction(
          {},
          workerCode,
          null,
          popupsStore.associatePreviousProductionRecordPopupData.idOperation,
          idSelectedPreviousProductionId,
          popupsStore.associatePreviousProductionRecordPopupData.popId,
          null,
          popupsStore.associatePreviousProductionRecordPopupData.preActionsData,
          cancelToken
        )
        .then((res) => {
          let responseData = res.data.response.data;
          let createdProductionId = responseData.Id;
          console.log("production created successfully", res);
          dispatchCustomPopupsStore({
            type: "CLOSE_ASK_PREV_PRODUCTIONRECORD_POPUP",
          });

          props.history.push(
            "/feature/production-order/" +
              popupsStore.associatePreviousProductionRecordPopupData
                .productionOrderId +
              "/po-product/" +
              popupsStore.associatePreviousProductionRecordPopupData.popId +
              "/production/" +
              createdProductionId
          );
        })
        .catch((res) => {
          if (isCancel(res)) {
            return;
          }
          console.log(" THE CATCH RES", JSON.stringify(res));

          let receivedErrors = res?.response?.data?.response?.issues?.errors;
          if (receivedErrors) {
            for (let i = 0; i < receivedErrors.length; i++) {
              if (
                receivedErrors[i] ===
                "error.NoCalculatedProductionRecordAvailable"
              ) {
                present(
                 t("all.previous.productions.selected.someone"),
                  3000
                );
                popupClose();
                return;
              }

              if (
                receivedErrors[i] === "error.previousProductionAlreadySelected"
              ) {
                let currentSelectedProduction =
                  popupsStore.associatePreviousProductionRecordPopupData
                    ?.candidatePreviousProductionRecordsToAssociate &&
                  popupsStore.associatePreviousProductionRecordPopupData
                    ?.candidatePreviousProductionRecordsToAssociate[
                    selectedProduction
                  ];

                setStoredNotAvailableItem({ ...currentSelectedProduction });
                setSelectedProduction(-1);

                let receivedErrorsMap =
                  res?.response?.data?.response?.issues?.errorsMap;
                if (!receivedErrorsMap?.refetchedOperations) {
                  throw new Error("didnt receive refetched candidates");
                } else {
                  let found = false;
                  let findOperation =
                    popupsStore.associatePreviousProductionRecordPopupData
                      .idOperation; //previously clicked operation that open poup
                  for (
                    let j = 0;
                    j < receivedErrorsMap?.refetchedOperations.length;
                    j++
                  ) {
                    if (
                      receivedErrorsMap?.refetchedOperations[j].Id ===
                      findOperation
                    ) {
                      let newCandidatePreviousRecords =
                        receivedErrorsMap.refetchedOperations[j]
                          .candidatePreviousProductionRecords;

                      let newPopupStore = { ...popupsStore };
                      newPopupStore.associatePreviousProductionRecordPopupData.candidatePreviousProductionRecordsToAssociate =
                        newCandidatePreviousRecords;

                      let dispatchData = {
                        type: "SET_STORE",
                        payload: {
                          ...newPopupStore,
                        },
                      };

                      dispatchCustomPopupsStore(dispatchData);

                      found = true;
                      setConfirmEnabled(true);
                      break;
                    }
                  }

                  if (!found) {
                    throw new Error("didnt find the current operation");
                  }
                }

                //setStoredAlreadySelectedItem
                return;
              }
            }
          }
          if (res["error"] && res["error"]["error_type"]) {
            if (res["error"]["error_type"] == "online_response_error") {
              present(
                t("failed.error.generic") + " (3e83yf3g67)",
                3000
              );
              setConfirmEnabled(true);
            }

            if (res["error"]["error_type"] == "offline_fail") {
              present(
                t("failed.error.generic") + " (jdy73teeu)",
                3000
              );
            }
          } else {
            present(
              t("failed.error.generic") + " (k36dg28j9)",
              3000
            );
          }
        });
    },
    [
      localStorage,
      popupClose,
      popupsStore,
      present,
      props.history,
      isCancel,
      selectedProduction,
      cancelToken,
    ]
  );

  useEffect(() => {
    if (
      shownPopup &&
      executeAutoCalculate &&
      previousAutoCalculate !== executeAutoCalculate
    ) {
      if (
        popupsStore.associatePreviousProductionRecordPopupSettings
          ?.autoCalculatePreviousProductionRecord === 1
      ) {
        console.log("execute now");

        executeConfirm("AUTOMATICALLY_CALCULATE_LATEST");
      }
    }
  }, [
    popupsStore,
    executeConfirm,
    executeAutoCalculate,
    previousAutoCalculate,
    shownPopup,
  ]);

  if (
    popupsStore.associatePreviousProductionRecordPopup &&
    popupsStore?.associatePreviousProductionRecordPopupData?.length === 0
  ) {
    console.log("Parou aqui");
    return (
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}>{t("associate.previous.operation")}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  popupClose("USER_CANCEL");
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.content}>
              <div>
                <IonLoading
                  isOpen={true}
                  message={"Loading..."}
                  duration={5000}
                />
              </div>
              <div className={styles.activeProductionsContainer}>
                <div className={styles.activeProductionsSection}></div>
              </div>
            </div>
          </div>
        </div>
      </SimpleContentPopup>
    );
  }

  if (!popupsStore.associatePreviousProductionRecordPopup) {
    return <></>;
  }

  if (!shownPopup) {
    setShownPopup(true);
    if (
      popupsStore.associatePreviousProductionRecordPopupSettings
        ?.autoCalculatePreviousProductionRecord
    ) {
      //skip selection just insta select
      //do nothing, it will be handled on useeffect
      if (!executeAutoCalculate) {
        setExecuteAutoCalculate(true);
      }
    } else {
      let autoSelectFirstOption =
        popupsStore.associatePreviousProductionRecordPopupSettings
          ?.autoSelectLatestOperation;
      if (
        autoSelectFirstOption &&
        popupsStore.associatePreviousProductionRecordPopupData
          ?.candidatePreviousProductionRecordsToAssociate &&
        popupsStore.associatePreviousProductionRecordPopupData
          ?.candidatePreviousProductionRecordsToAssociate.length > 0
      ) {
        if (
          autoSelectFirstOption &&
          popupsStore.associatePreviousProductionRecordPopupSettings
            ?.showButtonAutoCalculateLatestOperation
        ) {
          setSelectedProduction(-3);
        } else {
          setSelectedProduction(0);
        }
      }
    }
  }

  if (
    popupsStore.associatePreviousProductionRecordPopupSettings
      ?.autoCalculatePreviousProductionRecord
  ) {
    return <></>;
  }

  console.log("Prods data: ", popupsStore);
  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t("associate.previous.operation")}</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                popupClose("USER_CANCEL");
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          {hasInfoBarSettings() && <>{getInfoBar()}</>}
          <div className={styles.content}>
            {!noOperationsAvailable() && (
              <div>{t("select.previous.finished.production.operation")}:</div>
            )}

            <div className={styles.activeProductionsContainer}>
              <div className={styles.activeProductionsSection}>
                {storedNotAvailableItem?.workerData ? (
                  <IonCardHeader
                    className={`${styles.elemElement} ${styles.notSelectableProduction}`}
                    onClick={() => {}}
                  >
                    <div className={styles.elemBackgroundContainer}>
                      <LocalShippingIcon />
                    </div>
                    <IonCardSubtitle>
                      <b>
                        {t("operation.already.selected.someone")}
                      </b>
                      <div className={styles.elemElementTitle}>
                      {t("worker")}: {getProperWorkerName(storedNotAvailableItem)}
                      </div>
                      <div className={styles.elemElementTitle}>
                        {t("operation")}:{" "}
                        {getProperOperationName(storedNotAvailableItem)}
                      </div>
                    </IonCardSubtitle>
                    <IonCardTitle className={styles.elemElementDescription}>
                      {DatesConversionService().formattedDate(
                        storedNotAvailableItem.dateEnd
                      )}
                    </IonCardTitle>
                    {storedNotAvailableItem?.temp_executionNumberOfTimes >
                      1 && (
                      <div className={styles.pgrBr}>
                        <div className={styles.progressBarContainer}>
                          <div className={styles.elemElementDescriptionBar}>
                            <div className={styles.barLabel}>
                              <p>
                                {storedNotAvailableItem?.temp_executedTimes}/
                                {
                                  storedNotAvailableItem?.temp_executionNumberOfTimes
                                }
                              </p>
                            </div>
                            <div
                              className={styles.fillerBar}
                              style={{
                                width: calculatePctProgressExecutedTimes(
                                  storedNotAvailableItem
                                ),
                              }}
                            >
                              {" "}
                              <div className={styles.fillerBarContent}> </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </IonCardHeader>
                ) : (
                  ""
                )}

                {popupsStore.associatePreviousProductionRecordPopupSettings
                  ?.showButtonAutoCalculateLatestOperation ? (
                  <>
                    <IonCardHeader
                      className={`${styles.elemElement} ${
                        styles.elemElementHover
                      } ${
                        selectedProduction === -3
                          ? styles.selectedActiveProductionElement
                          : ""
                      } `}
                      onClick={() => {
                        setSelectedProduction(-3);
                      }}
                    >
                      <div className={styles.elemBackgroundContainer}>
                        <LocalShippingIcon />
                      </div>
                      <IonCardSubtitle>
                        <div className={styles.elemElementTitle}>
                          {t("auto.calculate.previous.operation")}
                        </div>
                      </IonCardSubtitle>
                      <IonCardTitle className={styles.elemElementDescription}>
                        {t("auto.detection")}
                      </IonCardTitle>
                    </IonCardHeader>{" "}
                  </>
                ) : (
                  ""
                )}

                {noOperationsAvailable() && (
                  <div>
                    {t("current.operation.requires.previous")}
                  </div>
                )}
                {popupsStore.associatePreviousProductionRecordPopupData?.candidatePreviousProductionRecordsToAssociate?.map(
                  (row: any, index: number) => (
                    <div
                      key={"associateProd_" + index}
                      className={`${styles.activeProductionElement}  `}
                    >
                      <IonCardHeader
                        className={`${styles.elemElement} ${
                          styles.elemElementHover
                        } ${
                          index === selectedProduction
                            ? styles.selectedActiveProductionElement
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedProduction(index);
                        }}
                      >
                        <div className={styles.elemBackgroundContainer}>
                          <LocalShippingIcon />
                        </div>
                        <IonCardSubtitle>
                          <div className={styles.elemElementTitle}>
                            {t("worker")}: {getProperWorkerName(row)}
                          </div>
                          <div className={styles.elemElementTitle}>
                            {t("operation")}: {getProperOperationName(row)}
                          </div>
                        </IonCardSubtitle>
                        <IonCardTitle className={styles.elemElementDescription}>
                          {DatesConversionService().formattedDate(row.dateEnd)}
                        </IonCardTitle>
                        {row?.temp_executionNumberOfTimes > 1 && (
                          <div className={styles.pgrBr}>
                            <div className={styles.progressBarContainer}>
                              <div className={styles.elemElementDescriptionBar}>
                                <div className={styles.barLabel}>
                                  <p>
                                    {row?.temp_executedTimes}/
                                    {row?.temp_executionNumberOfTimes}
                                  </p>
                                </div>
                                <div
                                  className={styles.fillerBar}
                                  style={{
                                    width:
                                      calculatePctProgressExecutedTimes(row),
                                  }}
                                >
                                  {" "}
                                  <div className={styles.fillerBarContent}>
                                    {" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </IonCardHeader>
                    </div>
                  )
                )}

                {popupsStore.associatePreviousProductionRecordPopupSettings
                  ?.allowNonePreviousProductionRecord && (
                  <div className={`${styles.activeProductionElement}  `}>
                    <IonCardHeader
                      className={`${styles.elemElement} ${
                        -2 === selectedProduction
                          ? styles.selectedActiveProductionElement
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedProduction(-2);
                      }}
                    >
                      <div className={styles.elemBackgroundContainer}>
                        <LocalShippingIcon />
                      </div>
                      <IonCardSubtitle>
                        <div className={styles.elemElementTitle}>
                          {t("no.previous.operation")}
                        </div>
                      </IonCardSubtitle>
                      <IonCardTitle className={styles.elemElementDescription}>
                      {t("new.production.operation")}
                      </IonCardTitle>
                    </IonCardHeader>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <AssociatePreviousProductionRecordPopupConfirmationButtons
          enabled={selectedProduction !== -1 && confirmEnabled}
          onClickConfirm={() => {
            let idSelectedPreviousProductionId = null;

            if (selectedProduction !== -2 && selectedProduction !== -3) {
              //selected a normal previous operation and not "new Operation" or "automatically calculate"
              let selectedPreviousProductionRecord =
                popupsStore.associatePreviousProductionRecordPopupData
                  .candidatePreviousProductionRecordsToAssociate[
                  selectedProduction
                ];
              idSelectedPreviousProductionId =
                selectedPreviousProductionRecord.Id;
            }

            if (selectedProduction === -3) {
              //selected "Automatically Calculate on backend"

              idSelectedPreviousProductionId = "AUTOMATICALLY_CALCULATE_LATEST";
            }

            if (idSelectedPreviousProductionId === null) {
              idSelectedPreviousProductionId = "none";
            }

            setConfirmEnabled(false);
            //todo on backend set sent Id_Previous
            executeConfirm(idSelectedPreviousProductionId);
          }}
        />
      </div>
    </SimpleContentPopup>
  );
}

export default withRouter(AssociatePreviousProductionRecordPopup);
