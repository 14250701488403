import { useIonToast } from "@ionic/react";
import { useRef } from "react";
import { useSnackbar, VariantType } from "notistack";
interface IFeedbackOptions {
  duration: number;
}
function useFeedbackService() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [presentToast,dismissToast] = useIonToast();
  const timerDismiss = useRef<any>(null);

  const notifyToast = (
    message: string,
    alertType: "success" | "warning" | "error" | "danger" | "info",
    options: IFeedbackOptions | null = null
  ) => {
    clearTimeout(timerDismiss.current);
    // 'default' | 'error' | 'success' | 'warning' | 'info';
    let toastType: VariantType = "default";
    if (alertType === "success") {
      toastType = "success";
    } else if (alertType === "info") {
      toastType = "info";
    } else if (alertType === "warning") {
      toastType = "warning";
    } else if (alertType === "error" || alertType === "danger") {
      toastType = "error";
    }

    let duration = 3000; //default
    if (options?.duration) {
      duration = options.duration;
    }

    enqueueSnackbar(message, {
      autoHideDuration: duration,
      variant: toastType,
      anchorOrigin: { horizontal: "center", vertical: "top" },
    });
    /*
        dismissToast();
        
        presentToast(
            {color:toastType, message:message, duration:duration, position:'top'}
        );
        
        timerDismiss.current = setTimeout(dismissToast, duration); //tempfix
        */
  };

  return { notifyToast };
}

export default useFeedbackService;
