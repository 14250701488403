import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import styles from "./AppOfflineInaccessible.module.scss";
import { Button } from "@mui/material";
import { useNavigatorOnline } from "@oieduardorabelo/use-navigator-online";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";

function AppOfflineInaccessible() {
  const { isOnline } = useNavigatorOnline({ startOnline: true });
  const {t} = useHxfTranslation();

  return (
    <div className={styles.pageContainer}>
      <div className={styles.messageContainer}>
        <div className={styles.messageTitle}>Shopfloor Studio</div>
        <div className={styles.messageText}>
          {!isOnline ? (<>
            <>{t("no.internet")}</>
          </>) : (<>{t("service.temporarily.unav")}</>)}
     
        </div>
        <div className={styles.buttonRetry}>
          <Button
            onClick={() => {
              location.reload();
            }}
          >
            {t('reload')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AppOfflineInaccessible;
