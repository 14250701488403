import styles from "./PrintStockLocationLabelButton.module.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { caretForwardOutline, printOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
interface IProps {
  onClickConfirm: any;
  disabled?:any;
}

function PrintStockLocationLabelButton(props: IProps) {

  const {t} = useHxfTranslation();
  
  return (

      <div>
        <div className={styles.container}>
          <div className={styles.buttonsContainer}>
            <div className={`${styles.customConfirmationContainer} `}>
              <IonButton
              disabled={props.disabled}
                className={styles.confirmButton}
                data-btn={"print-label"}
                onClick={() => {
                  if (props.onClickConfirm) {
                    props.onClickConfirm();
                  }
                }}
              >
                <IonIcon slot="start" icon={printOutline}></IonIcon>
                {t('print.label').toUpperCase()}
                
              </IonButton>
            </div>
          </div>
        </div>
      </div>
 
  );
}

export default PrintStockLocationLabelButton;
