import { dispatchActionScreenProduceQuantityProductStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenProduceQuantityProduct/ActionScreenProduceQuantityProductStore";
import { dispatchActionScreenDefineProducedProductParameterizedVariableValuesStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenDefineProducedProductParameterizedVariableValues/ActionScreenDefineProducedProductParameterizedVariableValuesStore";
import { dispatchActionScreenAskBomConsumptionsStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptionsStore";
import { dispatchActionScreenAskProducedProductIrregularityStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskProducedProductIrregularity/ActionScreenAskProducedProductIrregularityStore";
import { dispatchActionScreenFillChecklistsStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenFillChecklists/ActionScreenFillChecklistsStore";
import { dispatchActionScreenScaleProductivityStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenScaleProductivity/ActionScreenScaleProductivityStore";
import { dispatchActionScreenMatchFixedVariablesStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenMatchFixedVariables/ActionScreenMatchFixedVariablesStore";
import { dispatchActionScreenProduceTransformationFromMachineAdjustmentStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenProduceTransformationFromMachineAdjustment/ActionScreenProduceTransformationFromMachineAdjustmentStore";
import { dispatchActionScreenAskUseMachineCurrentElementStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/ActionScreenAskUseMachineCurrentElementStore";
import { dispatchActionScreenRegisterProductSerializationStateStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenRegisterProductSerialization/subviews/ActionScreenRegisterProductSerializationStore";
import { dispatchActionScreenPrintSerializationLabelsStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenPrintSerializationLabels/ActionScreenPrintSerializationLabelsStore";
function ActionManagerService() {
  const resetAllScreensState = () => {
    console.log("reseting all action screens states..");
    dispatchActionScreenProduceQuantityProductStore({ type: "RESET_STORE" });
    dispatchActionScreenDefineProducedProductParameterizedVariableValuesStore({
      type: "RESET_STORE",
    });
    dispatchActionScreenAskBomConsumptionsStore({ type: "RESET_STORE" });
    dispatchActionScreenAskProducedProductIrregularityStore({
      type: "RESET_STORE",
    });
    dispatchActionScreenFillChecklistsStore({ type: "RESET_STORE" });
    dispatchActionScreenScaleProductivityStore({ type: "RESET_STORE" });
    dispatchActionScreenMatchFixedVariablesStore({ type: "RESET_STORE" });
    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({type:"RESET_STORE"});
    dispatchActionScreenAskUseMachineCurrentElementStore({type:"RESET_STORE"});
    dispatchActionScreenRegisterProductSerializationStateStore({type:"RESET_STORE"});
    dispatchActionScreenPrintSerializationLabelsStore({type:"RESET_STORE"});
  };

  return { resetAllScreensState };
}

export default ActionManagerService;
