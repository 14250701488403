import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function oauthBridgeController() {
  /**
   * oauth-bridge/login is the App Server url, which will use the access token to get the "Client_App_UserId"
   */
  const loginWithAccessToken = (access_token) => {
    console.log("Sending at ", access_token);
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "oauth-bridge/login",
      { access_token: access_token }
    );
  };

  return { loginWithAccessToken };
}

export default oauthBridgeController;
