import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useLocation, withRouter } from "react-router";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import { useCustomPopupsStore } from "../CustomPopupsStore";
import styles from "./OnGoingMultiProductionsPopup.module.scss";
import ProductionService from "../../../../../barrel/services/productionService";
import { useEffect } from "react";
import { useState } from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { dispatchCustomPopupsStore } from "../CustomPopupsStore";
import OnGoingMultiProductionsPopupConfirmationButtons from "./OnGoingMultiProductionsPopupConfirmationButtons";
import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
import productionController from "../../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import { useIonAlert } from "@ionic/react";
import { useIonToast } from "@ionic/react";
import { useCallback } from "react";
import OnGoingMultiProductionsFinishSubScreen from "./SubSelectionScreens/OnGoingMultiProductionsFinishSubScreen";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
function OnGoingMultiProductionsPopup(props: any) {
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const popupsStore = useCustomPopupsStore();
  const previousPopupsStore = usePrevious(popupsStore);
  const myLocalStorage = useLocalStorageHandler();
  const [selectedMultiProduction, setSelectedMultiProduction] = useState(-1);
  const previousSelectedMultiProduction = usePrevious(selectedMultiProduction);
  const [selectedMultiData, setSelectedMultiData] = useState([]);
  const [selectedOperationCode, setSelectedOperationCode] = useState("");
  const [didMount, setDidMount] = useState(null);

  const [openingPopup, setOpeningPopup] = useState(false);
  const [isFinishLoading, setIsFinishLoading] = useState(true);
  //enable popup with:  dispatchCustomPopupsStore({type:"SET_STORE",payload:{...popupsStore, onGoingProductionsPopup:true}});
  //on close, clear state and clear popup data

  const [currentStep, setCurrentStep] = useState("SELECT_MULTI");
  const {t} = useHxfTranslation();

  const onResetInternalState = useCallback(() => {
    setCurrentStep("SELECT_MULTI");
    setIsFinishLoading(true);
  
    setSelectedMultiData([]);
    setSelectedOperationCode("");


    let onGoingMultis = popupsStore?.onGoingMultiProductionsPopupData;
    if(onGoingMultis.length === 1 && selectedMultiProduction === -1){
      setSelectedOperationCode(onGoingMultis[0].operationCode);
      setSelectedMultiProduction(0); //auto select if only 1
    }else{
      setSelectedMultiProduction(-1);
    }
    setOpeningPopup(true);
   // eslint-disable-next-line
  }, [setCurrentStep,popupsStore]);

  const onOpenPopup = useCallback(() => {
    onResetInternalState();
  }, [onResetInternalState]);

  /**
   *
   * @param multiData prepares data and orders by production order
   * @returns
   */
  const prepareMultiData = (multiData: any) => {
    let productionRecordsData = multiData?.productionRecordsData;
    if (!productionRecordsData) {
      return [];
    }

    let preparedMap: any = {};
    for (let i = 0; i < productionRecordsData.length; i++) {
      let productionOrderData = productionRecordsData[i].productionOrderData;
      productionOrderData = JSON.parse(productionOrderData);

      let popData = productionRecordsData[i].popData;
      popData = JSON.parse(popData);

      let productData = productionRecordsData[i].productData;
      productData = JSON.parse(productData);

      productionRecordsData[i].productData = productData;
      productionRecordsData[i].popData = popData;
      productionRecordsData[i].productionOrderData = productionOrderData;

      let arrayCurrentProductionRecords = [];
      if (preparedMap[productionOrderData.Id_ProductionOrder]) {
        arrayCurrentProductionRecords =
          preparedMap[productionOrderData.Id_ProductionOrder];
      }

      arrayCurrentProductionRecords.push(productionRecordsData[i]);

      preparedMap[productionOrderData.Id_ProductionOrder] =
        arrayCurrentProductionRecords;
    }

    let mapToArray: any = [];
    let mapKeys = Object.keys(preparedMap);
    for (let i = 0; i < mapKeys.length; i++) {
      let idProductionOrder = mapKeys[i];

      let productionOrderName =
        preparedMap[idProductionOrder][0]["productionOrderData"].name;
      let productionOrderCode =
        preparedMap[idProductionOrder][0]["productionOrderData"].name;

      mapToArray.push({
        Id_ProductionOrder: idProductionOrder,
        productionOrderName: productionOrderName,
        productionOrderCode: productionOrderCode,
        productionRecords: preparedMap[idProductionOrder],
      });
    }

    return mapToArray;
  };
  const getSelectedMultiId = useCallback(() => {
    if (selectedMultiProduction === -1) {
      return null;
    }

    return popupsStore.onGoingMultiProductionsPopupData[selectedMultiProduction]
      .Id_MultiProduction;
  },[selectedMultiProduction,popupsStore]);
  const confirmBtnAction = useCallback(() => {
    setCurrentStep("FINISH_MULTI");
    productionController()
      .getWorkerMultiProduction(getSelectedMultiId())
      .then((res) => {
        let returnData = res?.data?.response?.data;
        if (returnData) {
          setSelectedMultiData(prepareMultiData(returnData));
        } else {
          //error and close
        }

        setIsFinishLoading(false);
      })
      .catch((res) => {
        //error and close
        setIsFinishLoading(false);
      });
    /*let productionToOpen = popupsStore.onGoingProductionsPopupData[selectedMultiProduction];
              props.history.push({pathname:"/feature/production-order/"+ productionToOpen.Id_ProductionOrder + "/po-product/" + productionToOpen.Id_ProductionOrder_Product +"/production/" + productionToOpen.Id_ProductionRecord});
              dispatchCustomPopupsStore({type:"CLOSE_ON_GOING_MULTI_PRODUCTIONS_POPUP"});
            */
  },[getSelectedMultiId]);


  useEffect(() => {
    if (
      previousPopupsStore &&
      popupsStore &&
      previousPopupsStore?.onGoingMultiProductionsPopup !==
        popupsStore?.onGoingMultiProductionsPopup
    ) {
      if (
        popupsStore.onGoingMultiProductionsPopup &&
        !previousPopupsStore.onGoingMultiProductionsPopup
      ) {
        console.log("onOpenPopup");
        onOpenPopup();
      }
    }
  }, [popupsStore, previousPopupsStore, onOpenPopup]);

  useEffect(() => {

    let onGoingMultis = popupsStore?.onGoingMultiProductionsPopupData;

    if(currentStep === "SELECT_MULTI" && openingPopup && selectedMultiProduction === 0 && onGoingMultis.length === 1){ //&& previousSelectedMultiProduction === -1 
      console.log("Auto selecting the only multi available");
      setOpeningPopup(false);
      confirmBtnAction();
   
    }


  },[selectedMultiProduction,currentStep,openingPopup,confirmBtnAction,popupsStore,previousSelectedMultiProduction]);


  if (!popupsStore.onGoingMultiProductionsPopup) {
    return <></>;
  }


  const onClickClose = (data?:any) => {

    let closeRedirect = null;
    if(data?.redirect){ 
         closeRedirect = data?.redirect;
    }
    let redirectTo = null;
    if (
      popupsStore.onGoingMultiProductionsPopupSettings
        ?.onCloseGoToPath || closeRedirect
    ) {

      if(closeRedirect){
        redirectTo = closeRedirect;
      }else{
        redirectTo =
        popupsStore.onGoingMultiProductionsPopupSettings
          ?.onCloseGoToPath;
      }

    }
    setSelectedMultiProduction(-1);
    setCurrentStep("SELECT_MULTI");
    dispatchCustomPopupsStore({
      type: "CLOSE_ON_GOING_MULTI_PRODUCTIONS_POPUP",
    });

    if (redirectTo !== null) {
      props.history.push({
        pathname: "/page-redirector",
        state: { redirectTo: redirectTo },
      });
    }
  }

  if (currentStep === "FINISH_MULTI" && getSelectedMultiId() !== null) {
    return (
      <OnGoingMultiProductionsFinishSubScreen
        isLoading={isFinishLoading}
        Id_Multi_Production={getSelectedMultiId()}
        multiProductionData={selectedMultiData}
        selectedOperationCode={selectedOperationCode}
        onClickClose={(data?:any) => {
          onClickClose(data);
        }}
      />
    );
  }


  if(currentStep === "SELECT_MULTI"){
    return (
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>
  
              <div className={styles.title}>{t('multi.operations')}</div>
  
              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  onClickClose();
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
  
            <div className={styles.content}>
              <div className={styles.activeProductionsContainer}>
                <div className={styles.activeProductionsSection}>
                  {popupsStore.onGoingMultiProductionsPopupData.map(
                    (row: any, index: number) => (
                      <div
                        key={"onGoingProd_" + index}
                        className={`${styles.activeProductionElement}  `}
                      >
                        <IonCardHeader
                          className={`${styles.elemElement} ${
                            index === selectedMultiProduction
                              ? styles.selectedActiveProductionElement
                              : ""
                          }`}
                          onClick={() => {
  
                            if (index === selectedMultiProduction) {
                              setSelectedMultiProduction(-1);
                            } else {
                              setSelectedOperationCode(row.operationCode);
                              setSelectedMultiProduction(index);
                            }
                          }}
                        >
                          <div className={styles.elemBackgroundContainer}>
                            <LocalShippingIcon />
                          </div>
                          <IonCardSubtitle>
                            <div className={styles.elemElementTitle}>
                              {t('operation')}: {row.operationCode}
                            </div>
                          </IonCardSubtitle>
                          <IonCardTitle className={styles.elemElementDescription}>
                            {DatesConversionService().formattedDate(
                              row.dateStart
                            )}
                          </IonCardTitle>
                        </IonCardHeader>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <OnGoingMultiProductionsPopupConfirmationButtons
            enabled={selectedMultiProduction !== -1}
            onClickConfirm={() => {
              confirmBtnAction();
            }}
          />
        </div>
      </SimpleContentPopup>
    );
    
  }

  return (<></>)
}

export default withRouter(OnGoingMultiProductionsPopup);
