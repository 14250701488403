import { useIonToast } from "@ionic/react";
import { useHistory } from "react-router";
import { useProductionOrderSelectionStore } from "../../pages/FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import productionController from "../controllers/productionController";
import useFeedbackService from "./useFeedbackService";
import useHxfTranslation from "./useHxfTranslation";
import useLocalStorageHandler from "./useLocalStorageHandler";

function useProductionProcess(){
    const {t} = useHxfTranslation();
    const [presentToast] = useIonToast();
    const history = useHistory();
    const currentProductionOrderSelectionData =  useProductionOrderSelectionStore();
    const myLocalStorage = useLocalStorageHandler();
    const feedbackService = useFeedbackService();

    const cancelRecord = (options:any = {}) => {
        let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
        let prId = currentProductionOrderSelectionData.productionRecord.Id;
      
        let poId = currentProductionOrderSelectionData?.productionOrder?.Id;
        let popId = currentProductionOrderSelectionData?.productionRecord?.Id_ProductionOrder_Product;
        
   
        productionController().cancelProduction(options,currentWorkerCode, prId)
        .then((res) => {
          console.log("RESULTADO CANCEL: ", res);
          feedbackService.notifyToast(t('record.successfully.canceled'), "success");
          if(poId && popId){
            let newLink = "/feature/production-order/" + poId + "/po-product/" + popId;
       
            history.push(newLink);
          }else{
            history.push("/");
          }
          
        })
        .catch((res) => {
          console.log("Failed cancel production");
          presentToast(
            t("production.cancel.failed"),
            3000
          );
    
         history.push("/");
        });
      }

      return {cancelRecord};
}

export default useProductionProcess;