import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import { InputAdornment } from "@mui/material";
import { closeOutline, hammerOutline } from "ionicons/icons";
import { useState } from "react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import HxfTextfieldNumber from "../../../../../../HxfTextfieldNumber/HxfTextfieldNumber";

import SimpleContentPopup from "../../../../../SimpleContentPopup/SimpleContentPopup";
import AutoCalculateWholesConfirmationButtons from "./ConfirmationButtons/AutoCalculateWholesConfirmationButtons";

import styles from "./AutoCalculateWholesMethods.module.scss";

interface IProps{
    onClose:any;
    onConfirm:any;
    defaultValue?:any;
    autoCalculationMode:any;
}
function AutoCalculateWholesMethods(props:IProps){
  
    const {t} = useHxfTranslation();

    const [diameterWhole1, setDiameterWhole1] = useState<any>(0);
    const [diameterWhole2, setDiameterWhole2] = useState<any>(0);
    const [cornersDistance, setCornersDistance] = useState<any>(0);

    const [realDistance, setRealDistance] = useState<any>(props?.defaultValue ? props.defaultValue : 0);


    const [hasErrorRealDistance, setHasErrorRealDistance] = useState(false);

    const isSpaceLabeled = () => {
      return props.autoCalculationMode === "DIST_DIFF_SPACES_CENTER" || props.autoCalculationMode === "DIST_EQUAL_SPACES_CENTER" || props.autoCalculationMode === "DIST_UNTIL_SPACE_CENTER";
    }

    const getProperLabelDiameter = () => {
      if(isSpaceLabeled()){
        return t('space.diameter');
      }

      return t('whole.diameter');
    }

    const syncRealDistance = (wholeDiameter1:any,wholeDiameter2:any,distanceBetweenWholesCorners:any) => {

        if(wholeDiameter1 === 0 || wholeDiameter2 === 0 || distanceBetweenWholesCorners === 0){

            setRealDistance(0);
            return;
        }
        let calculateRealDistance:any = 0;
    
        if(props.autoCalculationMode === "DIST_EQUAL_WHOLES_CENTER" || props.autoCalculationMode === "DIST_EQUAL_SPACES_CENTER"){
          
          calculateRealDistance = (parseFloat(wholeDiameter1)  + parseFloat(distanceBetweenWholesCorners)).toFixed(2);;
        }

        if(props.autoCalculationMode === "DIST_UNTIL_WHOLE_CENTER" || props.autoCalculationMode === "DIST_UNTIL_SPACE_CENTER"){
          calculateRealDistance = (parseFloat(wholeDiameter1) + parseFloat(distanceBetweenWholesCorners) - (parseFloat(wholeDiameter1)/2)).toFixed(2);
        }

        if(props.autoCalculationMode === "DIST_DIFF_WHOLES_CENTER" || props.autoCalculationMode === "DIST_DIFF_SPACES_CENTER"){

          calculateRealDistance = ((parseFloat(wholeDiameter1)/2) + (parseFloat(wholeDiameter2)/2) + parseFloat(distanceBetweenWholesCorners)).toFixed(2);
        }

        
        setRealDistance(calculateRealDistance);
    }

    return (
    <>
        <SimpleContentPopup>
        <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t("calculation.help")}</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                props.onClose();
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          <div className={styles.content}>
              <div style={{display:'flex',marginTop:20}}>
                <div style={{marginRight:2}}>
                    <HxfTextfieldNumber
                        dataInputFieldAttributeValue={"autoCalculateInput1"}
                        startAdornment={(<InputAdornment position="start">{isSpaceLabeled() && (<div style={{fontWeight:600, fontSize:20}}>∅</div>)}</InputAdornment>)}
                        fullWidth={true} 
                        centerInputText={true} error={false} label={getProperLabelDiameter()} 
                        onChange={(evt:any) => {
                            let value = evt;
               
                            setDiameterWhole1(value);
                            let whole2Dia = diameterWhole2;
                            if(props.autoCalculationMode !== "DIST_DIFF_WHOLES_CENTER" && props.autoCalculationMode !== "DIST_DIFF_SPACES_CENTER"){
                              setDiameterWhole2(value);
                              whole2Dia = value;
                            }
                            let wholesDiameter = value !== null && value !== "" ? value : 0;
                            let distanceBetweenWholesCorners = cornersDistance !== null && cornersDistance !== "" ? cornersDistance : 0;
                            
                            syncRealDistance(wholesDiameter,whole2Dia,distanceBetweenWholesCorners);
                        }} value={diameterWhole1}/> 
                </div>

                {(props.autoCalculationMode === "DIST_DIFF_WHOLES_CENTER" || props.autoCalculationMode === "DIST_DIFF_SPACES_CENTER") && (
                  <div style={{marginLeft:2}}>
                        <HxfTextfieldNumber
                        dataInputFieldAttributeValue={"autoCalculateInput2"}
                        startAdornment={(<InputAdornment position="start">{isSpaceLabeled() && (<div style={{fontWeight:600, fontSize:20}}>∅</div>)}</InputAdornment>)}
                        fullWidth={true} 
                        centerInputText={true} error={false} label={getProperLabelDiameter()} 
                        onChange={(evt:any) => {
                            let value = evt;
                            setDiameterWhole2(value);
                            let whole1Dia = diameterWhole1;
                      
                            let wholesDiameter = value !== null && value !== "" ? value : 0;
                            let distanceBetweenWholesCorners = cornersDistance !== null && cornersDistance !== "" ? cornersDistance : 0;
                            
                            syncRealDistance(whole1Dia,wholesDiameter,distanceBetweenWholesCorners);
                        }} value={diameterWhole2}/> 
                    </div>
                )}
                <div style={{marginLeft:2}}>
                    <HxfTextfieldNumber
                        dataInputFieldAttributeValue={"autoCalculateInput3"}
                        startAdornment={(<InputAdornment position="start"><div style={{fontWeight:600, fontSize:20}}>☍</div></InputAdornment>)}
                        fullWidth={true} 
                        centerInputText={true} error={false} label={t('current.distance')} 
                        onChange={(evt:any) => {
                            let value = evt;
                            setHasErrorRealDistance(false);
                            setCornersDistance(value);
                            let wholesDiameter = diameterWhole1 !== null && diameterWhole1 !== "" ? diameterWhole1 : 0;
                            let whole2Dia = diameterWhole2;
                            if(props.autoCalculationMode !== "DIST_DIFF_WHOLES_CENTER" && props.autoCalculationMode !== "DIST_DIFF_SPACES_CENTER"){
                              whole2Dia = wholesDiameter;
                            }
                            let distanceBetweenWholesCorners = value !== null && value !== "" ? value : 0;
                            syncRealDistance(wholesDiameter,whole2Dia,distanceBetweenWholesCorners);
                        }} value={cornersDistance}/> 
                </div>
                


                
              </div>
              
              <div style={{marginTop:10}}>
                    <HxfTextfieldNumber
                      startAdornment={(<InputAdornment position="start"><div style={{fontWeight:600, fontSize:20}}>↔</div></InputAdornment>)}
                        dataInputFieldAttributeValue={"autoCalculateInputResult"}  
                        fullWidth={true} 
                        centerInputText={true} label={t('real.distance')} 
                        error={hasErrorRealDistance}
                        onChange={(evt:any) => {
                            let value = evt;
                            setRealDistance(value);
                            setCornersDistance(null),
                            setDiameterWhole1(null);
                            setDiameterWhole2(null);
                        }} value={realDistance}/> 
              </div>
          </div>
        </div>
        <AutoCalculateWholesConfirmationButtons
      
            onClickConfirm={() => {
              //allow 0
              /*
                if(!realDistance || realDistance === "" || realDistance === 0){
                    setHasErrorRealDistance(true);
                    return;
                }*/

                if(realDistance == ""){
                  props.onConfirm("");
                  return;
                }
                let floatDistance = parseFloat(realDistance).toFixed(2);
                props.onConfirm(floatDistance);

            }}
        />
      </div>
        </SimpleContentPopup>
    </>)
}

export default AutoCalculateWholesMethods;