import { withRouter } from "react-router";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import CustomArrowBack from "../CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../CustomErrorNotice/CustomErrorNotice";
function ErrorNoticeCriticalError(props) {
  const {t} = useHxfTranslation();
  return (
    <div>
      <CustomArrowBack pushUrl="/" />
      <CustomErrorNotice
        title={t('critical.error.page.title')}
        description={t('critical.error.content')}
        secondDescription=""
      />
    </div>
  );
}

export default withRouter(ErrorNoticeCriticalError);
