import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";
import GenericListJsonService from "../services/genericListJsonService";
function unavailabilitiesController() {



  const getUnavailabilityAdjustmentLatestTransformationsConfig = (idMachine:any, idProduct:any, arrayPPVVS:any) => {

    let requestUrl = "unavailability-action/adjust-to-product/get-latest-transformation-config";

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {Id_Machine:idMachine,Id_Product:idProduct,arrayProductParameterizedVariables: arrayPPVVS ? arrayPPVVS : []}
    );
  }
  const getListJson = (
    options: any = { page: 0, size: 10, openOnly: true }, postOptions = {}
  ) => {
    let requestUrl = "unavailabilities/listJson/?";



    requestUrl = GenericListJsonService().getUrlFromOptions(requestUrl, {...options});

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      postOptions
    );
  };

  return { getListJson,getUnavailabilityAdjustmentLatestTransformationsConfig };
}

export default unavailabilitiesController;
