import { useEffect } from "react";
import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IAction {
  checklistsData: any;
}

interface IActionScreen {
  type: any;
  payload?: any;
}

const initialState = {
    selectedMachineAndSegmentData: null,
};

const reducer = (store: IAction, action: IActionScreen) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState, checklistsData: {} };
      return store;
    case "SET_SELECTED_MACHSEG":
      let newStore = { ...store, selectedMachineAndSegmentData: { ...action.payload } };
      store = { ...newStore };
      return store;

    default:
      return store;
  }
};

export const [
  useActionScreenAskUseMachineCurrentElementStore,
  dispatchActionScreenAskUseMachineCurrentElementStore,
] = createStore(initialState, reducer);
