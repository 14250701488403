import MoveStockPick from "../MoveStock/SubViews/MoveStockPick/MoveStockPick";


function LocateStock(props:any){

    return (
        <>
        
        <MoveStockPick {...props} mode="LOCATE_STOCK"/>
        </>
    )
}

export default LocateStock;