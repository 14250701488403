import styles from "./AssociatePreviousProductionRecordPopupConfirmationButtons.module.scss";

import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
  enabled: boolean;
}
function AssociatePreviousProductionRecordPopupConfirmationButtons(
  props: IProps
) {

  const {t} = useHxfTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={styles.confirmationContainer}>
          <IonButton
            disabled={!props.enabled}
            className={styles.confirmButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {t("confirm.caps")}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default AssociatePreviousProductionRecordPopupConfirmationButtons;
