import { useEffect, useState } from "react";

import styles from "./ProgressBar.module.scss";

interface IProps {
  pgValue: number;
  size?: "small" | "medium";
  customBorderStyle?: any;
  customWidth?:any;
  minFillerWidth?:any;
}
function ProgressBar(props: IProps) {
  const progressBarValue = props.pgValue;
  const [didMount, setDidMount] = useState(false);

  const getProgressBarContainerExtraStyle = () => {
    let styleObj: any = {};
    if (!props.size || props.size === "small") {
      styleObj["height"] = "10px";
    } else {
      styleObj["height"] = "15px";
    }

    if(props?.customWidth){
      styleObj["width"] = props.customWidth;
    }

    if (props?.customBorderStyle) {
      styleObj = { ...styleObj, ...props.customBorderStyle };
    }

    return styleObj;
  };

  const getProgressBarFillExtraStyle = () => {
    let actValue = progressBarValue;

    if (!props.size || props.size === "small") {
      if (actValue > 0 && actValue < 10) {
        actValue = 10;
        if(props?.minFillerWidth){
          actValue = props.minFillerWidth;
        }
      }
    } else {
      if(props?.minFillerWidth && actValue > 0 && actValue < props?.minFillerWidth){
        actValue = props.minFillerWidth;
      }else if (actValue > 0 && actValue < 15) {
        actValue = 15;
      }
    }

    if(actValue > 100){
      actValue = 100;
    }

    let styleObj: any = { width: actValue + "%" };
    if (props?.customBorderStyle) {
      styleObj = { ...styleObj, ...props.customBorderStyle };
    }

    return styleObj;
  };

  const getSmartAdjustmentValueTextStyle : any = () => {
  
    if(props?.minFillerWidth && parseFloat(props.minFillerWidth) > 0){
      let fillerval = parseFloat(props?.minFillerWidth);
     
      if(fillerval <= 22 && progressBarValue < 25){
        return {position:'relative',left:'5px'};
      }
    }
    return {};
  }

  const getProgressLabelExtraStyle = () => {
    if (!props.size || props.size === "small") {
      return { fontSize: "8px" };
    }

    return { fontSize: "14px" };
  };
  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
    }
  }, [didMount]);

  return (
    <div
      className={`${styles.progressBarContainer} ${
        props?.customBorderStyle ? "" : styles.pgBorderStyleDefault
      }`}
      style={getProgressBarContainerExtraStyle()}
    >
      <div
        className={`${styles.progressBarFill} ${
          props?.customBorderStyle ? "" : styles.pgBorderStyleDefault
        }`}
        style={getProgressBarFillExtraStyle()}
      >
        {progressBarValue > 0 && (
          <div
            className={styles.progressLabel}
            style={getProgressLabelExtraStyle()}
          >
            <div style={getSmartAdjustmentValueTextStyle()}>{progressBarValue}%</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgressBar;
