import { IonButton, IonIcon, useIonAlert, useIonToast } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import {
  dispatchCustomPopupsStore,
  useCustomPopupsStore,
} from "../CustomPopupsStore";
import styles from "./ZebraPrintersPopup.module.scss";
import ZebraPrintersSetupView from "./ZebraPrintersSetupView/ZebraPrintersSetupView";

function ZebraPrintersPopup(props: any) {
  const popupsStore = useCustomPopupsStore();

  //enable popup with:  dispatchCustomPopupsStore({type:"SET_STORE",payload:{...popupsStore, onGoingProductionsPopup:true}});
  //on close, clear state and clear popup data
  const [didMount, setDidMount] = useState(false);
  console.log("popupstore", popupsStore);
  useEffect(() => {
    if (!didMount) {
      console.log("MONTOU AJANELA");

      setDidMount(true);
    }
  }, [didMount]);

  if (!popupsStore.zebraPrintersPopup) {
    return <></>;
  }

  if (popupsStore.zebraPopupStep === "SETUP") {
    return <ZebraPrintersSetupView />;
  }

  console.log("Prods data: ", popupsStore);
  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>Zebra Printers</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                dispatchCustomPopupsStore({
                  type: "CLOSE_ZEBRA_PRINTERS_POPUP",
                });
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          <div className={styles.content}>...</div>
        </div>
        {/*<OnGoingProductionsPopupConfirmationButtons enabled={selectedProduction !== -1} onClickConfirm={() => {
                      let productionToOpen = popupsStore.onGoingProductionsPopupData[selectedProduction];
                      props.history.push({pathname:"/feature/production-order/"+ productionToOpen.Id_ProductionOrder + "/po-product/" + productionToOpen.Id_ProductionOrder_Product +"/production/" + productionToOpen.Id_ProductionRecord});
                      dispatchCustomPopupsStore({type:"CLOSE_ON_GOING_PRODUCTIONS_POPUP"});

                  }} hideCancelAllButton={hideCancelAllButton} enabledCancelAll={enabledCancelAllButton && selectedProduction === -1} onCancelAllClick={() => {
                    present({
                        cssClass: "my-css",
                        header: "Production Cancelation",
                        message: "Are you sure you wish to cancel all the minimized productions?",
                        buttons: ["Cancel", { text: "Yes", handler: (d) => {
                            let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
                            setEnabledCancelAllButton(false);
                            productionController().cancelAllMinimizedProductionRecords(currentWorkerCode).then((res:any) => {
                                console.log("cancelled successfully");
                                
                                dispatchCustomPopupsStore({type:"CLOSE_ON_GOING_PRODUCTIONS_POPUP"});
                                props.history.push({pathname:"/page-redirector",state:{"redirectTo":"/feature/production-orders"}}); 
                               
                            }).catch((res:any) => {
        
                                console.log("failed cancel");
                                setEnabledCancelAllButton(true);
                                presentToast(
                                    "Failed to properly cancel the production",
                                    3000
                                  );
                                  
                                //show error popup and close
                                dispatchCustomPopupsStore({type:"CLOSE_ON_GOING_PRODUCTIONS_POPUP"});
                            });
                        } }],
                        onDidDismiss: (e) => {},
                      });
                                                        

                  }}/> */}
      </div>
    </SimpleContentPopup>
  );
}

export default withRouter(ZebraPrintersPopup);
