import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function serializedProductsController() {
  const localStorage = useLocalStorageHandler();

  const getInfo = (options:any = {}) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "serialized-product/get-info";

  
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        ...options
      }
    );
  }
  //serialized-product/get-info
  const validateSerial = (serial: any,options:any) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "serialized-product/validate-serial";

    let Id_Product = options?.Id_Product ? options.Id_Product : null;
    let productParameterizedVariableValues = options?.productParameterizedVariableValues ? options.productParameterizedVariableValues : [];
    let Id_OperationProduct = options?.Id_OperationProduct ? options.Id_OperationProduct : null;
    let Id_ProductionRecord = options?.Id_ProductionRecord ? options.Id_ProductionRecord : null;
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        productParameterizedVariableValues:productParameterizedVariableValues,
        Id_Product:Id_Product,
        Id_OperationProduct:Id_OperationProduct,
        Id_ProductionRecord:Id_ProductionRecord,
        serial:serial
      }
    );
  };

  const reserveSerial = (options:any) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "serialized-product/reserve-serial";

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        ...options
      }
    );
  };

  return { validateSerial,reserveSerial,getInfo };
}

export default serializedProductsController;
