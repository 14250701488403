import styles from "./PopsWithUnavailablePreviousProductionRecordConfirmationButtons.module.scss";

import { IonButton } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { reloadOutline } from "ionicons/icons";
import { createOutline } from "ionicons/icons";
import { useState } from "react";
import productionController from "../../../../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../../../../barrel/hooks/useLocalStorageHandler";
interface IProps {
  enabled: boolean;
  onClickEditCallback: any;
  onClickTryAgain: any;
}
function PopsWithUnavailablePreviousProductionRecordConfirmationButtons(
  props: IProps
) {
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={styles.confirmationContainer}>
          <div>
            <div className={styles.btnsContainer}>
              <IonButton
                disabled={!props.enabled}
                className={styles.confirmButton}
                onClick={() => {
                  /*if(props.onClickConfirm){
                                            props.onClickConfirm();
                                        }*/
                  props.onClickEditCallback();
                }}
              >
                <IonIcon slot="start" size="large" icon={createOutline} />
                EDIT
              </IonButton>
              <IonButton
                disabled={!props.enabled}
                className={styles.confirmButton}
                onClick={() => {
                  /*if(props.onClickConfirm){
                                            props.onClickConfirm();
                                        }*/
                  props.onClickTryAgain();
                }}
              >
                <IonIcon slot="start" size="large" icon={reloadOutline} />
                TRY AGAIN
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopsWithUnavailablePreviousProductionRecordConfirmationButtons;
