import { IonSpinner } from "@ionic/react";
import { styled } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useOngoingUnavailabilityOccurrenceHook from "../../../../FeaturePages/OnGoingUnavailabilityOccurrence/store/onGoingUnavailabilityOccurrenceStore";
import HxfIonLoading from "../../../HxfIonLoading/HxfIonLoading";
import UnavailabilityActionAdjustMachineToProductConfirmationButtons from "./ConfirmationButtons/UnavailabilityActionAskIfProductiveStarterWorker";
import styles from "./UnavailabilityActionAskIfProductiveStarterWorker.module.scss";
interface IProps{
    onConfirm?:any;
    onCancel?:any;
}

export const getUnavailabilityActionAskIfProductiveStarterWorker = () => {
    return "UNAVACT_ASK_IF_PRODUCTIVE_STARTER_WORKER";
}

const StyledRadio = styled(Radio)(({ theme }) => ({

    "& .MuiSvgIcon-root": {
      width:45,
      height:45
    },
  
  }));

function UnavailabilityActionAskIfProductiveStarterWorker(props:IProps){

    const [adjustingToProducts, setAdjustingToProducts] = useState<any>([null]);
    const [didMount, setDidMount] = useState(false);
    const prevDidMount = usePrevious(didMount);
    const {t} = useHxfTranslation();
    const occurrenceState = useOngoingUnavailabilityOccurrenceHook();
    const {sessionState} = useGlobalState();
    const [returningObj, setReturningObj] = useState<any>({considerWorkerProductivity:false});
    
    const [isLoading, setIsLoading] = useState(true);


    const getAskWorkerElement = () => {

        let currentActionData = occurrenceState.getCurrentUnavailabilityAction();

        let starterWorkerData = currentActionData?._actionData?._starterWorker;

        let workerName = starterWorkerData?.name ? starterWorkerData.name : "";
        return (
            <div>


                <div style={{textAlign:'center'}}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                    <div className={styles.askIfAssignedWork}>
                        <Trans i18nKey="ask.worker.had.work" values={{workername:workerName}}>
                                The worker <b>{workerName}</b> had assigned work?
                        </Trans>
                    </div>
                    </FormLabel>
                    <div style={{fontSize:20}}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="no"
                        name="radio-buttons-group"
                        value={returningObj?.considerWorkerProductivity ? "yes" : "no"}
                        onChange={(evt:any,val:any) => {
                            setReturningObj({...returningObj, considerWorkerProductivity: val === "yes" ? true : false});
                        }}
                    >
                        <FormControlLabel value="yes" control={<StyledRadio />} label={t('yes.yes.work.assigned')} />
                        <FormControlLabel value="no" control={<StyledRadio />} label={t('no.no.work.assigned')} />
             
                    </RadioGroup>
                    </div>

                </FormControl>
                </div>
                
            </div>
        )
    }
   


    useEffect(() => {
        if(!didMount){

            setDidMount(true);
        }
    },[didMount,occurrenceState,props,returningObj,sessionState]);

    useEffect(() => {
        //after mounting
        if(!prevDidMount && didMount){
            let selfIdWorker = sessionState.userSessionData.Id_Worker;

            //1- check if any worker "started" this from an irregularity
            let currentActionData = occurrenceState.getCurrentUnavailabilityAction();
            let starterWorkerData = currentActionData?._actionData?._starterWorker;

            //when the worker who started the unav and finished it is the same do not count productivity meanwhile (?)
            if(parseInt(selfIdWorker) === parseInt(starterWorkerData?.Id_Worker)){
                    console.log("Skipping action , starting worker = stopping worker,do not consider shift by default");
                    let confirmObj = {...returningObj};
                    confirmObj.considerWorkerProductivity = false;
                     //skip this action
                     props.onConfirm({
                        ...confirmObj
                    });
                    return;
            }else if(!starterWorkerData?.Id_Worker){
                console.log("Skipping action no id worker set");
                //skip this action
                props.onConfirm({
                    ...returningObj
                });
                return;
            }

            setIsLoading(false);
        }
    }, [didMount,occurrenceState,props,returningObj,sessionState, prevDidMount]);


    if(isLoading){
        return (
            <>
             
               <div className={styles.content}>
                <div style={{marginBottom:50, marginTop:50}}>
                   
                    <IonSpinner/>
                    
                </div>
            </div>
            </>
        )
    }


    return (
        <>
            <div className={styles.content}>
                <div style={{marginBottom:50, marginTop:50}}>
                   
                  {getAskWorkerElement()}
                    
                </div>
            </div>
            <div>
               <UnavailabilityActionAdjustMachineToProductConfirmationButtons
                disabled={false}
                onConfirm={() => {
                    //validate etc..
                   //yes or no
                    props.onConfirm({
                        ...returningObj
                    });
             
                }}
                />
            </div>
        </>
    )
}

export default UnavailabilityActionAskIfProductiveStarterWorker;