/**
 * Purpose of this popup is to simply show an overlay
 * that covers everything , and then we can add statepopups on top of it
 */
import { useCustomPopupsStore } from "../CustomPopupsStore";
import styles from "./GenericOverlayPopup.module.scss";
function GenericOverlayPopup(){

    const popupsStore = useCustomPopupsStore();
    if (!popupsStore.genericOverlayPopup) {
        return <></>;
      }
    return (
        <>
        <div
          style={{position:'fixed',left:'0px'}}
          className={`${styles.popupWindow}`}
        >
        </div>
        <div className={styles.popupPage}></div>
      </>
    )
}

export default GenericOverlayPopup;