import { useCallback, useEffect, useState } from "react";

import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../barrel/hooks/usePrevious";
import AppSessionService from "../../barrel/services/appSessionService";
import { useAppOfflineContext } from "../../contexts/AppOfflineProvider";
import { deleteCookie } from "../../barrel/utils/CookieUtils";
import entityUserController from "../../barrel/controllers/entityUserController";
import { useNavigatorOnline } from "@oieduardorabelo/use-navigator-online";
import { useIonToast } from "@ionic/react";
const initialState = {
  maintenance: false
};
function AppState() {
  const { isOnline } = useNavigatorOnline({ startOnline: true });
  const [presentToast] = useIonToast();

  const [appStateData, setAppStateData] = useState(initialState);
  const [appOutdated, setAppOutdated] = useState(false);
  const setServerUnavailable = (state: any) => {
    if (state) {
      presentToast({
        color: "danger",
        message:
          "Failed to connect, try again and contact us if the error persists",
        duration: 2000,
      });
    }
  };
  const setMaintenance = (state: any) => {
    //if(state !== appStateData.maintenance){
    console.log(" setting maint.");
    setAppStateData({ ...appStateData, maintenance: state ? true : false });
    //}
    //setAppStateData({...appStateData, maintenance: state ? true : false});
  };


  /**
   * checks if the user is online
   * @returns
   */
  const isConnectionAvailable = () => {
    return isOnline;
    //return navigator.onLine;
  };

  const isAppOutdated = () => {
    return appOutdated;
  }
  //checks if device network is enabled
  const isNetworkEnabled = () => {
    return isOnline;
  };

  useEffect(() => {
    console.log("appState MAINTENANCE STATE: ", appStateData.maintenance);
  }, [appStateData]);
  return {
    isNetworkEnabled,
    appStateData,
    setMaintenance,
    isConnectionAvailable,
    setServerUnavailable,
    isAppOutdated,
    setAppOutdated
  };
}

export default AppState;
