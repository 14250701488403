import { dispatchProductionOrderSelectionStore } from "../../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { dispatchActionScreenManagerStore } from "../../../ActionScreensManager/ActionScreenManagerStore";

function useActionScreenTopBar(){


    const closeActionWindows = () => {
        
        dispatchActionScreenManagerStore({ type: "RESET_STORE" }); //closes all windows
        dispatchProductionOrderSelectionStore({
          type: "RESET_ACTION_INDEX",
        }); //next time he presses finish again, opens first action window
    }


    return {closeActionWindows};
}

export default useActionScreenTopBar;