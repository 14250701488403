import useProductivityScale from "./useProductivityScale";

function useProductSerialization(){

    let productivityScale = useProductivityScale();

    const getTotalSerialsWouldRegister = () => {
        //for now we are assuming the total serials to register = productivity scale
        let val = productivityScale.getProductivityScale();
        if(!val){
            return 0;
        }

        return parseInt(val);
    }

    return {getTotalSerialsWouldRegister};
}

export default useProductSerialization;