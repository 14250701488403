import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function productionOrderProductsController() {
  const getPopsDataByProductionOrder = (
    idProductionOrder: any,
    filterByOperationCode: any = null,
    fetchProductData = false,
    fetchPPVs = false,
    cancelToken: any
  ) => {
    let postObj: any = { Id_ProductionOrder: idProductionOrder };
    if (filterByOperationCode) {
      postObj["filterByOperationCode"] = filterByOperationCode;
    }
    if (fetchProductData) {
      postObj["fetchProductData"] = true;
    }
    if (fetchPPVs) {
      postObj["fetchPPVs"] = true;
    }

    postObj["includeOperationsAccessData"] = true;

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "pops/get-data-by-production-order",
      postObj,
      cancelToken
    );
  };

  return { getPopsDataByProductionOrder };
}

export default productionOrderProductsController;
