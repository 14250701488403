import createStore
 from "../../../AppCustomStateManagement/createStore";
// Library interfaces


interface IPreActionProps {
    routeState:any;
}

interface IMachineManagementReceiverStoreAction {
  type: any;
  payload?: any;
}

const initialState = {
    routeState:null,
};

const reducer = (
  store: IPreActionProps,
  action: IMachineManagementReceiverStoreAction
) => {
  switch (action.type) {

    case "SET_STORE":
      store = {...action.payload};
      return store;
    case "RESET_STORE":
        store = {...initialState};
        return store;
    default:
      return store;
  }
};

export const [
  useMachineManagementShortcutReceiverStore,
  dispatchMachineManagementShortcutReceiverStore,
] = createStore(initialState, reducer);


