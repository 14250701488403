import {
    IonButton, IonIcon, IonLoading
} from "@ionic/react";
import { closeOutline, flashOffSharp } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import operationProductsController from "../../../../../../../../barrel/controllers/operationProductsController";
import useFeedbackService from "../../../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import useMachineManagementShortcutReceiver from "../../../../../../../../barrel/hooks/useMachineManagementShortcutReceiver/useMachineManagementShortcutReceiver";
import usePrevious from "../../../../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import ActionScreenMachineElement from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachine/ActionScreenMachineElement";
import ActionScreenAskUseMachineCurrentConfirmationButtons from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/ActionScreenAskUseMachineCurrentConfirmationButtons";
import ActionScreenMachineCurrentElement from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/ActionScreenMachineCurrentElement";
import MachineIdentifierScanner from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/MachineIdentifierScanner/MachineIdentifierScanner";
import SegmentGoBack from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/SegmentGoBack/SegmentGoBack";

  
  import SimpleContentPopup from "../../../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import { dispatchPreActionsStore, usePreActionsStore } from "../../../preActionsStore";
import styles from "./PreActionSelectMachine.module.scss";
import PreActionSelectMachineConfirmationButtons from "./PreActionSelectMachineConfirmationButtons";

  interface IPopup {
    onFinish:any;
    onClose:any;
    Id_ProductionOrder?:any;
    Id_ProductionOrder_Product?:any;
    Id_OperationProduct_Action?:any;
    settings?:any;
  }
  function PreActionSelectMachine(props: IPopup) {
    const RESYNC_FREQ_SECONDS = 5;
    let preAction = usePreActionsStore();
    const { sessionState } = useGlobalState();
    
    const [didMount, setDidMount] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [machinesData, setMachinesData] = useState<any>(null);
    const previousMachineData = usePrevious(machinesData);
    const feedbackService = useFeedbackService();
    const [selectedMachine, setSelectedMachine] = useState<any>({Id_Machine:null, Id_MachineSegment:null,segmentData:null,machineData:null});
    const [isSelectingSegment, setIsSelectingSegment] = useState(false);
    const [optionsMachineSegmentsData,setOptionsMachineSegmentsData] = useState<any>([]);
    const {t} = useHxfTranslation();
    const [disableConfirmationButtons, setDisableConfirmationButtons] = useState(false);
    const timerScroll = useRef<any>(null);
    const timerResync = useRef<any>(null);
    const isFirstResync = useRef<any>(true);
    const history = useHistory();
    const isAlreadyResyncing = useRef<any>(false);
    const machineManagementShortcutReceiver = useMachineManagementShortcutReceiver();
    const isShortcutQuickUnavMachine = props?.settings?.isShortcutQuickUnavMachine;

    const resyncMachinesData = () => {
      if(!isAlreadyResyncing.current){
        isAlreadyResyncing.current = true;
      }else{
        return;
      }
      let idOperationProduct = props?.Id_OperationProduct_Action ? props.Id_OperationProduct_Action : preAction.pendingPreActionsOperation?.obj?.Id;
      let idPop = props?.Id_ProductionOrder_Product;
      operationProductsController().getMachines(idOperationProduct,{Id_ProductionOrder_Product:idPop}).then((res:any) => {
        isAlreadyResyncing.current = false;
  
        let machinesDataRes = res?.data?.response?.data;
        if(!machinesDataRes){
          throw "invalid return machines data";
        }
  
   
        setMachinesData(machinesDataRes);
        setIsLoading(false);

      }).catch((res) => {
        isAlreadyResyncing.current = false;
        feedbackService.notifyToast("error","error");
        setIsLoading(false);
        
      });
    }


    useEffect(() => {
      return () => {
        // clear the timer reference when the component unmounts
        console.log("Clear timer scroll");
        if(timerScroll?.current){
          clearTimeout(timerScroll.current);
        }

        if(timerResync?.current){
          clearInterval(timerResync.current);
        }
        
      };
    }, []);



    useEffect(() => {
      if(!didMount){
        resyncMachinesData();
        timerResync.current = setInterval(() => {
          resyncMachinesData();
        },RESYNC_FREQ_SECONDS*1000);
      }

      setDidMount(true);
    
    //eslint-disable-next-line
    },[didMount]);
    const getIsBusyMachine = useCallback((obj:any) => {

      
      if(obj?.allowParallelization === 0 && obj?.runningProcesses > 0){
        return true;
      }
      return false;
    },[]);

    const getIsEnabledMachine = useCallback((obj:any) => {
      if(obj._unavailable && obj.allowEvenIfUnavailable == 0){
        return false;
      }
      if(obj.allowWorkerActivityOverride === 1){
        return true;
      }
      return !getIsBusyMachine(obj);
    },[getIsBusyMachine]);



    const goBackFromSegment = () => {
      setSelectedMachine({...selectedMachine, Id_Machine:0,Id_MachineSegment:null,segmentData:null});
      setIsSelectingSegment(false);
    }

    const clickedMachineSegment = (optionSegment:any) => {

        setSelectedMachine({...selectedMachine,
          Id_MachineSegment:optionSegment.Id_MachineSegment,
          segmentData:optionSegment
        });
    
    }

    const finishSelection = () => {
      
      setDisableConfirmationButtons(true);
      props.onFinish({
        Id_Machine:selectedMachine.Id_Machine,
        Id_MachineSegment:selectedMachine.Id_MachineSegment,
        machineData:selectedMachine?.machineData
      });
      
    }
    // eslint-disable-next-line
    const scrollToMachine = (idMachine = null, idMachineSegment = null) => {

      if(idMachineSegment){
        //todo
        //

        //data-machine-segment-id-selection

        let content = document.querySelector("[data-content='true']");
        let elem:any = document.querySelector("[data-machine-segment-id-selection='" + idMachine  +"']");
        if(elem && content){
          content.scrollTo({
            top: elem.offsetTop,
            behavior: "smooth"
          });
        }
      }else{
    

        let content = document.querySelector("[data-content='true']");
        let elem:any = document.querySelector("[data-machine-id-selection='" + idMachine  +"']");

        if(elem && content){
          content.scrollTo({
            top: elem.offsetTop,
            behavior: "smooth"
          });
        }
      }

    }
    
    const clickedMachineIndex = useCallback((index:any, options:any = {}) => {


        let machineSegmentsData = machinesData[index]?.machineSegmentsData;
        let selectingMachineObj = {
          Id_Machine:machinesData[index].Id_Machine,
          Id_MachineSegment:null,
          machineData:machinesData[index],
          segmentData:null
        };


        if(!getIsEnabledMachine(machinesData[index])){
          return;
        }

        if(options?.attachMachineSegment){
          selectingMachineObj.Id_MachineSegment = options.attachMachineSegment.Id_MachineSegment;
          selectingMachineObj.segmentData = options.attachMachineSegment.segmentData;

          if(selectingMachineObj?.Id_MachineSegment){
            setOptionsMachineSegmentsData(machineSegmentsData);
            setIsSelectingSegment(true);
          }
        }else{
          if(machineSegmentsData?.length > 0){
            if(machineSegmentsData.length === 1){
              //select the only one available as selected in the background
              selectingMachineObj.Id_MachineSegment = machineSegmentsData[0].Id_MachineSegment;
              selectingMachineObj.segmentData = machineSegmentsData[0].optionSegment;
              setIsSelectingSegment(false);
            }else{
              setOptionsMachineSegmentsData(machineSegmentsData);
              setIsSelectingSegment(true);
            }
          }
        }
        setSelectedMachine({...selectingMachineObj});
        if(options?.smoothScrollToSelection){
          timerScroll.current = setTimeout(() => {
            
            scrollToMachine(selectingMachineObj.Id_Machine,selectingMachineObj.Id_MachineSegment);
          },250);
        }
    },[machinesData,getIsEnabledMachine]);

    useEffect(() => {
      if(isFirstResync.current){
        if(machinesData && JSON.stringify(machinesData) !== JSON.stringify(previousMachineData)){
          isFirstResync.current = false;
          if(machinesData.length === 1){
     
            clickedMachineIndex(0);
          }
        }
      }
    },[machinesData, previousMachineData, clickedMachineIndex]);

    if(isLoading){
      return (
        <IonLoading message={"Loading..."} isOpen={true}/>
      )
    }

    if(!isLoading && !machinesData){
      return (
        <div className={styles.intPopup}>
        
        <SimpleContentPopup mode="INNER">
          <div>
            <div className={styles.container}>
              <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
              >
                <div></div>
  
                <div className={styles.title}>Machine</div>
  
                <IonButton
                  color="danger"
                  className={styles.closeWindowButton}
                  onClick={() => {
                    if (props?.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
              </div>
              <div
                className={`popup-content-border-sides-only-styles ${styles.content}`}
              >
                <div>
                 
                    <div className={styles.scantip}>
                      An error occurred
                    </div>
                
  
                 
                </div>
              </div>

            </div>
          </div>
        </SimpleContentPopup>
      </div>
      )
    }

    if(isSelectingSegment && optionsMachineSegmentsData?.length > 0){
      
      return (
        <div className={styles.intPopup}>
          
          <SimpleContentPopup mode="INNER">
            <div>
              <div className={styles.container}>
                <div
                  className={`popup-barcontainer-border-styles ${styles.barContainer}`}
                >
                  <div></div>
    
                  <div className={styles.title}>Machine</div>
    
                  <IonButton
                    color="danger"
                    className={styles.closeWindowButton}
                    onClick={() => {
                      if (props?.onClose) {
                        props.onClose();
                      }
                    }}
                  >
                    <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                  </IonButton>
                </div>
                <div
                  data-content="true"
                  className={`popup-content-border-sides-only-styles ${styles.content}`}
                >
                  <div>
                      <div className={styles.machineTip}><div className={styles.machineContainer}>{selectedMachine?.machineData?.code}</div></div>
                      <div className={styles.scantip}>
                        {t('select.a.machinesegment')}
                      </div>
                      <div className={styles.objsContainer}>
                        <SegmentGoBack onClick={() => {
                          goBackFromSegment();
                        }}/>
                        {optionsMachineSegmentsData?.length > 0 && optionsMachineSegmentsData.map((obj:any,index:any) => (
                          <div key={"machine_" + index} data-machine-segment-id-selection={obj.Id_MachineSegment}>
                              
                              <ActionScreenMachineCurrentElement
                                isSegment={true}
                                unavailable={obj._unavailable}
                                enabled={true}
                                selected={obj.Id_MachineSegment === selectedMachine.Id_MachineSegment}
                                name={obj.name}
                                code={obj.code}
                                onClick={() => {
                                  clickedMachineSegment(obj);
                                }}
                              />
                          </div>
                        ))}
  
                      </div>
    
                   
                  </div>
                </div>
  
        
              </div>

            <PreActionSelectMachineConfirmationButtons
                  enabled={!disableConfirmationButtons && selectedMachine.Id_MachineSegment !== null}
                  typeShow={"CONFIRM"}
                  onClickConfirm={() => {
                    //set machine segment and confirm
                    finishSelection();
                  }}
                />
            </div>
          </SimpleContentPopup>
        </div>
      );
    }

    return (
      <div className={styles.intPopup}>
        
        <SimpleContentPopup mode="INNER">
          <div>
            <div className={styles.container}>
              <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
              >
                <div></div>
  
                <div className={styles.title}>{t('machine')}</div>
  
                <IonButton
                  color="danger"
                  className={styles.closeWindowButton}
                  onClick={() => {
                    if (props?.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
              </div>
              <div
                data-content="true"
                className={`popup-content-border-sides-only-styles ${styles.content}`}
              >

                <div style={{display:'flex',justifyContent:'center'}}>

                  
                  {isShortcutQuickUnavMachine && (
                    <div>
                      <IonButton
                        color="danger"
                        className={styles.closeWindowButton}
                        onClick={() => {
                          let shortcutQuickUnavMachineData = props?.settings?.shortcutQuickUnavMachineData;
                          if(shortcutQuickUnavMachineData){

                            machineManagementShortcutReceiver.resetShortcutData();
                            let idPop = props?.Id_ProductionOrder_Product;
                            let idPo = props?.Id_ProductionOrder;
              
                            let stateObj:any = {
                              isLoadingFromQuickShortcut:true,
                              operationProductSelected:shortcutQuickUnavMachineData?.operationProductSelected,
                              productSelected:shortcutQuickUnavMachineData?.productSelected
                              
                            };

                            if(idPop && idPo){
                              stateObj.unavailabilityReturnTo = "/feature/production-order/" + idPo + "/po-product/" + idPop;
                            }
                            history.push({pathname:"/feature/machines",state:stateObj});
                          }
                        }}
                      >
                        <IonIcon slot="icon-only" size="large" icon={flashOffSharp} />
                        {t('quick.maintenance')}
                      </IonButton>
                    </div>
                  )}
                </div>
                {machinesData?.length > 0 ? (
                  <>
                      <div>
                      
                      <div className={styles.scantip}>
                        {t('select.a.machine')}
                      </div>
                      <div className={styles.objsContainer} data-content="true">
                      
                      {sessionState.userSessionData?.machinesCamScanner === 1 && (
                      <MachineIdentifierScanner onReturnSelectedMachine={(idMachine:any, idMachineSegment:any) => {

                          let machineData = null;
                          let segmentData = null;
                          let machines = machinesData;
                    
                          let machineIndex = 0;
                          for(let i = 0; i<machines.length; i++){
                            let machineSegments = null;
                            if(parseInt(machines[i].Id_Machine) === parseInt(idMachine)){
                              machineData = machines[i];
                              machineIndex = i;
                              if(idMachineSegment){
                                machineSegments = machines[i]?.machineSegmentsData ? machines[i]?.machineSegmentsData : [];
                                for(let j = 0; j<machineSegments.length; j++){
                                  if(parseInt(machineSegments[j].Id_MachineSegment) === parseInt(idMachineSegment)){
                                    segmentData = machineSegments[j];
                                    break;
                                  }
                                }

                              }

                              if(getIsBusyMachine(machines[i])){
                                feedbackService.notifyToast("This machine is currently busy!", "error");
                                return;
                              }

                              break;
                            }
                          }
                    
                          if(machineData){
                      
                            clickedMachineIndex(machineIndex, {
                              attachMachineSegment:{
                                Id_MachineSegment:idMachineSegment,
                                segmentData:segmentData
                              },
                              smoothScrollToSelection: true
                            });
                          }else{
                            feedbackService.notifyToast("This machine cannot be selected", "warning",{duration:8000});
                          }

                          }}/>
                        )}

                        {machinesData?.length > 0 && machinesData.map((obj:any,index:any) => (
                          <div key={"machine_" + index} data-machine-id-selection={obj.Id_Machine}>
                              
                              <ActionScreenMachineCurrentElement
                                unavailable={obj._unavailable}
                                enabled={getIsEnabledMachine(obj)}
                                isBusy={getIsBusyMachine(obj)}
                                selected={selectedMachine.Id_Machine === obj.Id_Machine}
                                
                                name={obj.name}
                                code={obj.code}
                                onClick={() => {
                                  clickedMachineIndex(index);
                                }}
                              />
                          </div>
                        ))}
                      </div>
                  </div>
                  </>
                ) : (
                  <>
                      <div className={styles.scantip}>
                        {t('no.machine.available')}
                      </div>
                  </>
                )}

              </div>

   
            </div>

            <PreActionSelectMachineConfirmationButtons
                  enabled={!disableConfirmationButtons && selectedMachine.Id_Machine !== null}
                  typeShow={"CONFIRM"}
                  onClickConfirm={() => {
                 
                    if(getIsEnabledMachine({
                      Id_Machine:selectedMachine.Id_Machine,
                      allowParallelization:selectedMachine.machineData.allowParallelization,
                      runningProcesses:selectedMachine.machineData.runningProcesses,
                      allowWorkerActivityOverride:selectedMachine.machineData.allowWorkerActivityOverride
                    })){
                      finishSelection();
                    }
                    
               
                  }}
                />
          </div>
        </SimpleContentPopup>
      </div>
    );
  }
  
  export default PreActionSelectMachine;
  