import { useAppOfflineContext } from "../../../../contexts/AppOfflineProvider";
import workerController from "../../../controllers/workerController";
import DaoData from "../daoData";

function WorkerDao() {
  const daoData = DaoData();

  const getByCode = (code) => {
    return new Promise((resolve, reject) => {
      daoData
        .accessData(
          () => {
            return workerController().getByCode(code);
          },
          {
            onlineCondition: {
              objectStorageStore: true,
              objectStorageKey: "worker/fetch-by-code/_" + code,
            },
            offlineCondition: {
              objectStorageFetch: true,
              objectStorageKey: "worker/fetch-by-code/_" + code,
              throwExceptionIfNotFound: true,
              returnFirstOnly: true,
            },
            offlineValidDuration: 3600 * 24 * 2,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  };

  return { getByCode };
}

export default WorkerDao;
