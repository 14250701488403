import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function workerController() {
  const getByCode = (code:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "worker/fetch-by-code",
      {
        code: code,
      }
    );
  };

  const startDowntime = (options = {},Id_Unavailability:any, cancelToken:any) => {
    let requestUrl =
    "worker/initiate-downtime";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {Id_Unavailability:Id_Unavailability,...options},
      cancelToken
    );
  }

  return { getByCode,startDowntime };
}

export default workerController;
