import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";
function punchclocksController() {
  const getNextPunchclockAction = (workerCode: any) => {
    let requestUrl = "punch-clock/fetch-next-action";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      { workerCode: workerCode }
    );
  };

  const punchclockAuto = (workerCode: any, options: any = {}) => {
    let requestUrl = "punch-clock/auto-detect";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      { workerCode: workerCode, ...options }
    );
  };

  return { getNextPunchclockAction, punchclockAuto };
}

export default punchclocksController;
