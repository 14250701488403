import axios from "axios";
import { useEffect, useRef } from "react";
import RandomizationService from "../services/randomizationService";

interface IProps {
  alias?: string;
}
export const useCancelToken = (props?: IProps | null) => {
  const axiosSource = useRef<any>(axios.CancelToken.source());
  const isCancel = axios.isCancel;



  useEffect(
    () => () => {
      let aliasStr = props?.alias ? "(" + props.alias + ")" : RandomizationService().generateRandomString(20);
      console.log("Aborting ... " + aliasStr);
      axiosSource.current.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { cancelToken: axiosSource.current.token, isCancel };
};
