import createStore from "../../../../../AppCustomStateManagement/createStore";

// Library interfaces

interface IPreActionPendingPreActionsOperation{
    obj?:any;
    index?:any;
}

interface IPreActionProps {
    pendingPreActionsOperation:IPreActionPendingPreActionsOperation | null;
    pendingAction: null | string;
    preActionsData: any;
    triggerFinishedPreActions:boolean;
}

interface IPreActionAction {
  type: any;
  payload?: any;
}

const initialState = {
    pendingPreActionsOperation:null,
    pendingAction:null,
    preActionsData:null,
    triggerFinishedPreActions:false
};

const reducer = (
  store: IPreActionProps,
  action: IPreActionAction
) => {
  switch (action.type) {
    case "SET_PENDING_PREACT":
        let payload = action.payload;

        store = { ...store, pendingAction: payload.pendingAction,pendingPreActionsOperation: payload.pendingPreActionsOperation };
        return store;
    
    case "TRIGGER_FINISHED":
        store = { ...store, triggerFinishedPreActions: true};
        return store;
    case "SET_PREACTIONS_DATA":
        store = {...store, preActionsData:action.payload};
        return store;
    case "SET_STORE":
      store = {...action.payload};
      return store;
    case "RESET_STORE":
        store = {...initialState};
        return store;
    default:
      return store;
  }
};

export const [
  usePreActionsStore,
  dispatchPreActionsStore,
] = createStore(initialState, reducer);


