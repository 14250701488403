import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
//deprecated
function OfflineSessionState(db) {
  //todo, solve erorrs issues etc.
  const { sessionState } = useGlobalState();

  const set = (data) => {
    db.ObjectStorage.put({ key: "userSessionData", obj: data });
  };

  const syncState = () => {
    //PROBLEMA AQUI..
    set(sessionState.userSessionData);
  };

  /*
    offlineSessionState.getData().then((res)=>{
                                    console.log("SUCCES" , res);
                                }).catch(() => {
                                        console.log("ERROR");
                                })

     returns promise
    */
  const getData = () => {
    let theData = db.ObjectStorage.get("userSessionData");
    console.log("DATA RET: ", theData);
    return theData;
  };

  return { set, syncState, getData };
}

export default OfflineSessionState;
