import styles from "./printSerialLabelProductConfirmationButtons.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline, printOutline } from "ionicons/icons";

import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";

interface IProps{
    onClickPrint?:any;
    nextEnabled?:boolean;
    previousEnabled?:boolean;
    onClickNext?:any;
    onClickPrevious?:any;
    terminateButtonVisible?:boolean;
    onClickTerminate?:any;
}
function PrintSerialLabelProductConfirmationButtons(props:IProps){

  const {t} = useHxfTranslation();
    return (
        <div
        className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
      >
        <div>
          <div className={styles.container}>
            <div className={styles.buttonsContainer}>
              <div className={`${styles.customConfirmationContainer} `}>
              <IonButton
                  disabled={!props.previousEnabled}
                  className={styles.confirmButton}
                  onClick={() => {
                    if (props?.onClickPrevious) {
                      props.onClickPrevious();
                    }
                  }}
                >
                 <IonIcon slot="icon-only" size="large" icon={arrowBackOutline} />
                 
                </IonButton>

                <IonButton
                  className={styles.confirmButton}
                  onClick={() => {
              
                    if (props?.onClickPrint) {
                      props.onClickPrint();
                    }
                  }}
                >
                 <IonIcon slot="start" size="large" icon={printOutline} />
                  {t('print.label')}
                </IonButton>

                <IonButton
                disabled={!props.nextEnabled}
                  className={styles.confirmButton}
                  onClick={() => {
                    if (props?.onClickNext) {
                      props.onClickNext();
                    }
                  }}
                >
                 <IonIcon slot="icon-only" size="large" icon={arrowForwardOutline} />
                 
                </IonButton>
              </div>
              {props.terminateButtonVisible && (
                <div className={`${styles.customConfirmationContainer} ${styles.continueBtn} `}>
                  <IonButton
                      style={{width:'100%'}}
                      className={styles.confirmButton}
                      onClick={() => {
                        if (props?.onClickTerminate) {
                          props.onClickTerminate();
                        }
                      }}
                    >
                    
                      {t("finished")}
                  </IonButton>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    )
}

export default PrintSerialLabelProductConfirmationButtons;