import { useEffect } from "react";
import createStore from "../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IOngoingUnavOccurrenceStore {
    unavailabilityOccurrenceData?:any;
}

interface IUnavAction {
  type: any;
  payload?: any;
}

const initialState = {
  unavailabilityOccurrenceData:{},
  submittingDataList:[],
  currentActionIndex:0
};

const reducer = (store: IOngoingUnavOccurrenceStore, action: IUnavAction) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState };
      return store;
    case "SET_STATE":
  
      store = { ...store, ...action.payload };
      return store;
    default:
      return store;
  }
};

export const [
  useOngoingUnavailabilityOccurrenceStore,
  dispatchOnGoingUnavailabilityOccurrenceStoreStore,
] = createStore(initialState, reducer);


const useOngoingUnavailabilityOccurrenceHook = () => {

    const occurrenceStore = useOngoingUnavailabilityOccurrenceStore();

    const restartActionIndex = () => {
        dispatchOnGoingUnavailabilityOccurrenceStoreStore({type:"SET_STATE",payload:{
            currentActionIndex:0,
            submittingDataList:[]
        }});
    }

    const resetOccurrence = () => {
        dispatchOnGoingUnavailabilityOccurrenceStoreStore({type:"RESET_STORE"});
        return;
    }
    const setupOccurrence = (unavailabilityOccurrenceData:any) => {
  
        dispatchOnGoingUnavailabilityOccurrenceStoreStore({type:"SET_STATE",payload:{
            currentActionIndex:0,
            submittingDataList:[],
            unavailabilityOccurrenceData:JSON.parse(JSON.stringify(unavailabilityOccurrenceData))
        }});
        return;
    }

    const getCurrentUnavailabilityAction = () => {

        let currentActionIndex = occurrenceStore.currentActionIndex;

        let unavailabilityActions = occurrenceStore?.unavailabilityOccurrenceData?.unavailabilityActions;
        if(!unavailabilityActions){
            throw "unexpectedly did not find actions object";
        }

        
        if(unavailabilityActions.length === 0){
            return "NO_ACTIONS";
        }

        

        return unavailabilityActions[currentActionIndex];
    }

    const endUnavailabilityAction = (resultCurrentAction:any) => {
        return new Promise((resolve, reject) => {

            let unavailabilityActions = occurrenceStore?.unavailabilityOccurrenceData?.unavailabilityActions;
            if(!unavailabilityActions){
           
                reject("unexpectedly did not find actions object")
            }

            if(resultCurrentAction === "NO_ACTIONS"){
                resolve({nextUnavailabilityAction:"FINISHED",newOccurrenceStore:{...occurrenceStore}});
            }
            let submittingDataList = occurrenceStore.submittingDataList;
            let currentUnavailabilityAct = unavailabilityActions[occurrenceStore.currentActionIndex];
            submittingDataList.push({Id_UnavailabilityAction:currentUnavailabilityAct.Id,code:currentUnavailabilityAct.code, submittingData:resultCurrentAction});
            let nextActionIndex = occurrenceStore.currentActionIndex + 1;

            
            let isLast = unavailabilityActions.length <= nextActionIndex;
           
            let newObject = {...occurrenceStore,submittingDataList:JSON.parse(JSON.stringify(submittingDataList)),currentActionIndex:nextActionIndex};
            dispatchOnGoingUnavailabilityOccurrenceStoreStore({type:"SET_STATE",payload:newObject});
    
            if(isLast){
                //trigger something to call "finish"
                console.log("should attempt to finish now");
                resolve({nextUnavailabilityAction:"FINISHED",newOccurrenceStore:newObject});
            }else{
                
                let resolvingObject = {nextUnavailabilityAction:unavailabilityActions[nextActionIndex],newOccurrenceStore:newObject};
                resolve(resolvingObject);
               // resolve
            }

        });

    }

  
    /*
    useEffect(() => {
        console.log("occurrence store modified: ", occurrenceStore);
    },[occurrenceStore]);
*/

    return {occurrenceStore,restartActionIndex,setupOccurrence,resetOccurrence,getCurrentUnavailabilityAction,endUnavailabilityAction};

}
export default useOngoingUnavailabilityOccurrenceHook;
