import createStore from "../../../../../../AppCustomStateManagement/createStore";
import { IPopupPrintSPSerializedProduct } from "./ActionScreenPrintSerializationLabels";
// Library interfaces
interface IActionData {
  serializedProducts: null | IPopupPrintSPSerializedProduct;
}

interface IActionInput {
  type: any;
  payload?: any;
}

const initialState = {
    serializedProducts:null
};

const reducer = (
  store: IActionData,
  action: IActionInput
) => {
    switch (action.type) {
        case "RESET_STORE":
        store = { ...initialState };
        return store;
        case "SET_STORE":
        store = { ...action.payload };
        return store;
        case "SET_SERIALIZED_PRODUCTS":
  
        store = { ...store, serializedProducts: action.payload };
        return store;



        default:
        return store;
    }
};

export const [
  useActionScreenPrintSerializationLabelsStore,
  dispatchActionScreenPrintSerializationLabelsStore,
] = createStore(initialState, reducer);
