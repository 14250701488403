//{param}
import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function operationProductsController() {

  const getMachines = (idOperationProduct: any, submitOptions = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "operation-product/machines/" +
        idOperationProduct,
        submitOptions
    );
  };

  return {
    getMachines
  };
}

export default operationProductsController;
