import styles from "./HxfKeyRippleEffectCustom.module.scss";
import Ripples from "react-ripples";

function HxfKeyRippleEffectCustom(props: any) {
  let overrideType = props.overrideType != null ? props.overrideType : 1;
  let effectEnabled = props.effectEnabled != null ? props.effectEnabled : true;

  if (!effectEnabled) {
    return <div className={`${overrideType == 1 ? styles.ripplesOverride : ""}${
      overrideType == 2 ? styles.ripplesOverride2 : ""
    } ${overrideType == 4 ? styles.ripplesOverride4 : ""}`}>{props.children}</div>;
  }

  return (
    <div

      className={`${overrideType == 1 ? styles.ripplesOverride : ""}${
        overrideType == 2 ? styles.ripplesOverride2 : ""
      } ${overrideType == 4 ? styles.ripplesOverride4 : ""}`}
    >
      <Ripples>{props.children}</Ripples>
    </div>
  );
}

export default HxfKeyRippleEffectCustom;
