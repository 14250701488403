function ProductService(){

    const buildProductKey = (idProduct, ppvvs) => {
        let key = "id_" + idProduct;

        const hasPPVVS = ppvvs && ppvvs.length > 0;
        if (hasPPVVS) {
            
            ppvvs.sort((a, b) => a.Id_Product_ParameterizedVariable - b.Id_Product_ParameterizedVariable);
    
            ppvvs.forEach(ppvv => {
                const idPPV = ppvv.Id_Product_ParameterizedVariable;
                let val = ppvv.Value;
                val = prepareParameterizedVariableValueAsProductKey(val);
                key += "_|_" + idPPV + "_" + val;
            });
        }
    
        return key;
    }
    function nullParameterizedVariableValueKey(){
        return "sfsnull";
    }
    function prepareParameterizedVariableValueAsProductKey(pvv) {
        return pvv !== null && pvv !== "" ? pvv : nullParameterizedVariableValueKey();
    }
    return {buildProductKey};
}

export default ProductService;