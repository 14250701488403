import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { hardwareChipOutline } from "ionicons/icons";
import HxfKeyRippleEffectCustom from "../../../../HxfDigitKeyboard/HxfKeyRippleEffectCustom/HxfKeyRippleEffectCustom";
import styles from "./ActionScreenMachineCurrentElement.module.scss";
import Ripples from "react-ripples";
import { fileTrayOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
function ActionScreenMachineCurrentElement(props: any) {
  const {t} = useHxfTranslation();
  const getElement = () => {
    let innerClickableElement = (
      <div
        data-btn-machine-code={props.code}
        className={` ${styles.descIconContainer} ${
          !props?.enabled ? styles.disabledBtn : ""
        }`}
      >
        <div className={styles.addIconContainer}>
          {props.isSegment ? (   <IonIcon icon={fileTrayOutline} size="large" /> ) : (   <IonIcon icon={hardwareChipOutline} size="large" />)}
       
        </div>
        <div className={styles.codeNameContainer}>
          <div className={styles.code}>({props.code})</div>
          <div>{props.name}</div>
        </div>
      </div>
    );

    if (!props?.enabled) {
      return <> {innerClickableElement}</>;
    }
    return (
      <Ripples
      
        onClick={() => {
          if (props?.onClick) {
            props.onClick();
          }
        }}
      >
        {innerClickableElement}
      </Ripples>
    );
  };




  return (
    <div
      className={`${styles.outerContainer} ${
        props.selected === true
          ? styles.outerContainerSelected
          : styles.outerContainerUnselected
      }`}
    >
      {getElement()}

        {props.unavailable ? (
          <div className={styles.machineStatus}>
              <div><b>{t('status')}</b>:</div><div className={styles.busyMachine}>{t('unavailable')}</div>
            </div>
        ) : (<>
              {props.isBusy && (
                  <div className={styles.machineStatus}>
                    <div><b>{t('status')}</b>:</div><div className={styles.busyMachine}>{t('busy')}</div>
                  </div>
              )}
        </>)}
    </div>
  );
}

export default ActionScreenMachineCurrentElement;
