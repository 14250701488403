
function DatesConversionService() {


  /**
   * 
   * @param {receives the date Y-m-d H:i:s from the server and converts it to a date object, crossplatform (otherwise wouldnt work on iOS)} utcStringFromServer 
   * @returns 
   */
  function crossPlatformStringDateConvert(utcStringFromServer){
    var t = utcStringFromServer.split(/[- :]/);

    // Apply each element to the Date function
    var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
    var actiondate = new Date(d);

    return actiondate;
  }

  /**
   * 
   * scenario: Received a datestring from the backend (UTC) and converted to date with new Date(string), now we need to apply the timezone offset to see the expected date
   * 
   * @param } date 
   * @returns 
   */
  function convertUTCStringToLocalDate(utcString){
    let utcDate = crossPlatformStringDateConvert(utcString);
    return convertUTCDateToLocalDate(utcDate);
  }

  function convertUTCDateToLocalDate(date) {
    let timezoneOffsetAtTheTime = date.getTimezoneOffset(); //since timezones might be different depending on the date summer time, or browsers default tz
    var newDate = new Date(date.getTime()-timezoneOffsetAtTheTime*60*1000);
    return newDate;    
  }

  const tableFormattedDateTimeLabel = (dateTimeStringUtc) => {
    const dateObj = crossPlatformStringDateConvert(dateTimeStringUtc);
  
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const year = dateObj.getFullYear();
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
  
    if(hours < 10){
      hours = "0"+hours;
    }
    if(minutes < 10){
      minutes = "0"+minutes;
    }
  
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }
  const formattedDate = (dateTimeStringUtc) => {
    return convertedDateObject(dateTimeStringUtc).toLocaleString();
  };

  /**
   *
   * @param {receives a string representing the date on the server time,} dateTimeStringUtc
   * @return {returns a date object set on the user browser date time the object}
   */
  const convertedDateObject = (dateTimeStringUtc) => {
  
    let dateFromServerAsDate = crossPlatformStringDateConvert(dateTimeStringUtc);;
    return convertUTCDateToLocalDate(dateFromServerAsDate);
  };

  return { tableFormattedDateTimeLabel,formattedDate, convertedDateObject,convertUTCStringToLocalDate };
}

export default DatesConversionService;
