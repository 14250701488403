import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
function entityUserController() {
  const myLocalStorage = useLocalStorageHandler();

  const switchEntityUser = (Id_Entity) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "entity-user/switch/" + Id_Entity,
      {}
    );
  };

  const customAccountSignIn = (
    hxfentityid,
    customAccountId,
    customAccountPassword
  ) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        getCustomAccountSignInRoute(),
      {
        haxify_shopfloorstudioentity_id: hxfentityid,
        entityCustomAccountId: customAccountId,
        entityCustomAccountPassword: customAccountPassword,
      }
    );
  };


  const directWorkstationConnectToken = (options = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + 'entity-user/direct-workstation-connect-token',
      options
    );

    
  }
  function getSessionCheckRoute() {
    return "entity-user/session-status";
  }

  function getCustomAccountSignInRoute(){
      return "entity-user/custom-entity-account/sign-in";
  }

  /**
   * When this method is called, it returns a new refresh token which must be stored with saveRefreshToken from useLocalStorage, otherwise next request will fail
   * @returns
   */
  const sessionCheck = () => {
    let postData = {};
    if (
      myLocalStorage.getWorkerIdentificationCode() != null &&
      myLocalStorage.getWorkerIdentificationCode() !== ""
    ) {
      postData = {
        workerIdentificationCode: myLocalStorage.getWorkerIdentificationCode(),
      };
    }

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + getSessionCheckRoute(),
      postData
    );
  };

  const createCsrfToken = () => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "entity-user/create-csrftoken",
      {}
    );
  }

  const getLogoutSessionRoute = () => {
    return "entity-user/logout";
  };
  const logoutSession = () => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "entity-user/logout",
      {}
    );
  };

  const checkApiMajorVersion = () => {
    let postData = {};
    return BarrelWrapper().simplePostRequest(
      HxfAppServersService().getBackend() +
        "entity-user/check-api-major-version/frontoffice",
      postData,
      { withCredentials: false }
    );
  };

  const testApi = () => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "entity-user/test"
    );
  };
  return {
    checkApiMajorVersion,
    testApi,
    getLogoutSessionRoute,
    logoutSession,
    customAccountSignIn,
    sessionCheck,
    getSessionCheckRoute,
    getCustomAccountSignInRoute,
    switchEntityUser,
    createCsrfToken,
    directWorkstationConnectToken
  };
}

export default entityUserController;
