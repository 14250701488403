import { useState } from "react";
import styles from "./viewFileImage.module.scss";
interface IProps{
    imageUrl:any;
    disableZoomFunctionalityExperimental:boolean;
}
const ViewFileImage = (props:IProps) => {
    const [isZoomed, setZoomed] = useState(false);
    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  
    const handleMouseDown = (event:any) => {
        if(props.disableZoomFunctionalityExperimental){
            return;
        }
      setZoomed(true);
      updateZoomPosition(event);
    };
  
    const handleMouseUp = () => {
        if(props.disableZoomFunctionalityExperimental){
            return;
        }
      setZoomed(false);
    };
  
    const handleMouseMove = (event:any) => {
        if(props.disableZoomFunctionalityExperimental){
            return;
        }
      if (isZoomed) {
        updateZoomPosition(event);
      }
    };
  
    const updateZoomPosition = (event:any) => {
      const boundingBox = event.currentTarget.getBoundingClientRect();
      const x = (event.clientX - boundingBox.left) / boundingBox.width;
      const y = (event.clientY - boundingBox.top) / boundingBox.height;
  
      setZoomPosition({ x, y });
    };
  
    const containerStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    };
  
    const imageStyle = {
      /*width: '100%',
      height: '100%',*/
      transition: 'transform 0.2s',
      transform: isZoomed
        ? `scale(1.1) translate(-${zoomPosition.x * 30}%, -${zoomPosition.y * 30}%)`
        : 'scale(1)',
    };
  
    return (
      <div
        style={containerStyle}

      >
        <img onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove} src={props.imageUrl} alt="Zoomable" style={imageStyle} className={styles.img} draggable={false}/>
      </div>
    );
  };
  
  export default ViewFileImage;