import { IonLoading } from "@ionic/react";
import { useEffect, useRef, useState } from "react";


interface IProps{
    isOpen?:any;
    message:string;
    renderDelay?:number;
}

/**
 * 
  same as ionloading but supports render delay to avoid showing unless the request is taking longer than X
 */
function HxfIonLoading(props:IProps){

    const [didMount, setDidMount] = useState(false);
    const [canRender, setCanRender] = useState(props?.renderDelay && props.renderDelay > 0 ? false : true);

    const timerRef = useRef<any>(null);
    useEffect(() => {
        
        if(!didMount){
            if(props.renderDelay){
                timerRef.current = setTimeout(() => {
                    setCanRender(true);
                }, props.renderDelay);
            }
            setDidMount(true);
        }

        return () => {
            console.log("unmount");
            if(timerRef && timerRef.current){
                clearInterval(timerRef.current);
            }
        
          };
    },[didMount, props.renderDelay]);

    if(!canRender){
        return (<></>)
    }

    return (
        <IonLoading
        isOpen={props?.isOpen}
        message={props?.message}
      />
    )
}

export default HxfIonLoading;