import { IonButton, IonIcon } from "@ionic/react";
import {
  addCircleOutline,
  closeOutline,
  optionsOutline,
  wifiOutline,
} from "ionicons/icons";
import SimpleContentPopup from "../../../SimpleContentPopup/SimpleContentPopup";
import { dispatchCustomPopupsStore } from "../../CustomPopupsStore";

import styles from "./ZebraPrintersSetupView.module.scss";
declare let BrowserPrint: any; //using variable from asset js lib, fixes false positive errors

function ZebraPrintersSetupView() {
  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>Zebra Printers - Setup</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                dispatchCustomPopupsStore({
                  type: "CLOSE_ZEBRA_PRINTERS_POPUP",
                });
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          <div className={styles.content}>
            <div className={styles.btnContainer}>
              <div className={styles.buttonsContainer}>
                <div className={styles.confirmationContainer}>
                  <div>
                    <div>
                      <IonButton
                        disabled={false}
                        className={styles.confirmButton}
                        onClick={() => {
                          BrowserPrint.getLocalDevices((res: any) => {
                            console.log("res:", res);
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={wifiOutline}></IonIcon>Scan
                      </IonButton>
                    </div>
                    <div>
                      <IonButton
                        disabled={true}
                        className={styles.confirmButton}
                        onClick={() => {}}
                      >
                        <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
                        Add
                      </IonButton>
                    </div>
                    <div>
                      <IonButton
                        disabled={true}
                        className={styles.confirmButton}
                        onClick={() => {}}
                      >
                        <IonIcon slot="start" icon={optionsOutline}></IonIcon>
                        Manage
                      </IonButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SimpleContentPopup>
  );
}

export default ZebraPrintersSetupView;
