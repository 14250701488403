import { useRef, useState } from "react";
import MobileWrapperCommunicationService from "../../../barrel/services/mobileWrapperCommunicationService";
import styles from "./HxfImageInput.module.scss";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HxfImageUploaderPreviewPopup from "./HxfImageUploaderPreviewPopup/HxfImageUploaderPreviewPopup";
import imageCompression from 'browser-image-compression'; // or any other image compression library
import useHxfNativeAppCameraPictureListener from "../../../barrel/hooks/useHxfNativeAppCameraPictureListener";

interface IProps{
    onChange?:any;
    onResetValue?:any;
    value?:any;
    miniatureImgValue?:any;
    isCompressionModeEnabled?:boolean;
    error?:any;
    generateMiniature?:boolean;
    previewModeOnly?:boolean;
}

interface IResult{
    returningFile:any;
    base64File:any;

    miniature_ReturningFile?:any;
    miniature_base64File?:any;  
}
function HxfImageInput(props:IProps){
    const [selectedFile, setSelectedFile] = useState(null);
    const refImgFromCamera = useRef<any>(null);
    const [showPopupImage,setShowPopupImage] = useState<any>(null);


    // Function to convert a Base64 string to a Blob
    function base64ToBlob(base64:any, mimeType:any) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }

    const nativeAppCameraPictureListener = useHxfNativeAppCameraPictureListener({onReceivedImageFromCamera:(result:any)=> {
       
        if(result?.imagePartBase64){
 
            let imgType = result?.type;
            let fileFormatted = base64ToBlob(result.imagePartBase64, imgType);
            
            processFile(fileFormatted,result?.imagePartBase64);
        }else{
            //failed or canceled
            alert('canceled camera');
        }
    }});

    const returningFile = useRef<IResult | null>(null);
    const onReturningFile =(result:IResult) => {
        props.onChange(result);
    }

    const convertToBase64 = async (file:any ,customCallback?:any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
         
            let base64String = reader.result;
            let result:IResult = {
                returningFile:file,
                base64File:base64String
            };

      
                customCallback(result);
          
        };
        reader.onerror = (error) => {
          console.error('Error converting image to base64:', error);
        };
    };

    const generateMiniatureProcedure = async (file:any) => {
 
            const optionsMini = {
                maxSizeMB: 0.01,
                maxWidthOrHeight: 320,
                useWebWorker: true,
            }
            const compressedFileMini = await imageCompression(file, optionsMini);
            console.log('compressedFile instanceof Blob', compressedFileMini instanceof Blob); // true
            console.log(`compressedFile size ${compressedFileMini.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            convertToBase64(compressedFileMini, async function(result:IResult) {
                if(returningFile.current){
                    let actualResult:IResult = {
                        ...returningFile.current,
                        miniature_ReturningFile:result.returningFile,
                        miniature_base64File:result.base64File
                    }
                    onReturningFile(actualResult);
                }

            });

        
    }

    const processFile = async (file:any, base64String:any) => {
        if(props.isCompressionModeEnabled){
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            }
            try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            file = compressedFile;
            
            if(props.generateMiniature){
                let compressedFile = file;
                convertToBase64(compressedFile,async function (result:IResult){

                    returningFile.current = result;
                    generateMiniatureProcedure(file);
                });
                
            }else{
                convertToBase64(compressedFile,function(result:IResult){
                    onReturningFile(result);
                });
            }
            

            return;
            } catch (error) {
                console.log(error);
                throw error;
            }
        }


        let result:IResult = {
            returningFile:file,
            base64File:base64String
        };
        if(props.generateMiniature){

            returningFile.current = result;
            generateMiniatureProcedure(file);
            return;
        }
        onReturningFile(result);
    }

    const onFileInputChange = (event:any) => {
                     
        let file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                let base64String = reader.result;
                processFile(file,base64String);

            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            {showPopupImage && (
                <>
                <HxfImageUploaderPreviewPopup imgSrc={showPopupImage} onClose={() => {
                    setShowPopupImage(false);
                }}/>
                </>
            )}
            <div className={styles.container}>

                <div onClick={() => { 

                    if(props?.value){
                        setShowPopupImage(props.value);
                        return;
                    }
                    let askImagesFromMobileDevice = false;

                        if(MobileWrapperCommunicationService().isDeviceMobileWrapper()){
                            if(askImagesFromMobileDevice){
                                //tested working
                                refImgFromCamera.current.click();
                            }else{
                                //ask for react native camera
                                nativeAppCameraPictureListener.initiateCustomListenerForMobile();
                                MobileWrapperCommunicationService().notifyWaitingForCameraPicture();
                        
                                
                            }
                        }else{
                            //non iOS or Android Native app
                            if(refImgFromCamera?.current){
                                refImgFromCamera.current.click();
                            }
                        }
        
                    

                  
                }} className={`${styles.selectorInput} ${props.error ? styles.selectorError : ""}`}>
                    {props?.value ? (
                        <>
                            <img className={styles.imagePrev} src={props?.miniatureImgValue ? props.miniatureImgValue : props.value} alt="imgref"/>
                        </>
                    ) : (
                        <div>
                            <CameraAltIcon/>
                        </div>
                    )}
                    {MobileWrapperCommunicationService().isDeviceMobileWrapper() ? (
                        <>
                            <input 
                                className={styles.hiddenInput}
                                ref={refImgFromCamera}
                            
                                type='file' 
                                accept="image/jpg,image/jpeg"
                                onChange={(evt:any) => {
                                    onFileInputChange(evt);
                                }}
                            />
                        </>
                    ) : (<>
                            <input 
                                className={styles.hiddenInput}
                                ref={refImgFromCamera}
                            
                                type='file' 
                                accept="image/jpg,image/jpeg" capture="environment"
                                onChange={(evt:any) => {
                                    console.log("img chg",evt);
                                    onFileInputChange(evt);
                                }}
                            />
                    </>)}

                </div>
                
                {(props?.value && !props?.previewModeOnly) && (
                    <div className={styles.resetImgIcon} onClick={() => {
                        refImgFromCamera.current.value = null;
                        props.onResetValue();
                    }}>
                        <RestartAltIcon/>
                    </div>
                )}

            </div>
        </>
    )
}

export default HxfImageInput;