import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function buyOrdersController() {
  const mylocalStorage = useLocalStorageHandler();
  const getBuyOrders = (
    options: any = { page: 0, size: 10, openOnly: true }
  ) => {
    let currentWorkerCode = mylocalStorage.getWorkerIdentificationCode();

    let requestUrl =
      "buy-orders/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"];

    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }
    if (options?.openOnly === true) {
      requestUrl = requestUrl + "&openOnly=true";
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  return { getBuyOrders };
}

export default buyOrdersController;
