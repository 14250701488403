import styles from "./StageProductComponentPopupConfirmationButtons.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline, printOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";

interface IProps{
    onConfirm?:any;
}
function StageProductComponentPopupConfirmationButtons(props:IProps){
    const {t} = useHxfTranslation();

    return (
        <div
        className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
      >
         <div className={styles.divider}></div>
        <div>
       
    
           
                <div className={`${styles.customConfirmationContainer} ${styles.continueBtn} `}>
                  <IonButton
                      style={{width:'100%',height:'60px',fontSize:'25px'}}
                      className={styles.confirmButton}
                      onClick={() => {
                        props.onConfirm();
                      }}
                    >
                    
                    {t("confirm")}
                  </IonButton>
                </div>
            

     
        </div>
      </div>
    )
}

export default StageProductComponentPopupConfirmationButtons;