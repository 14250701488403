import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IActionScreenProduceTransformationFromMachineAdjustment {
  quantities: any;
  arrayRemains:any;
}

interface IActionScreenProduceTransformationFromMachineAdjustmentAction {
  type: any;
  payload?: any;
}

const initialState = {
  quantities: {},
  selectedAdjustedProduct:null,
  arrayRemains:[]
};

const reducer = (
  store: IActionScreenProduceTransformationFromMachineAdjustment,
  action: IActionScreenProduceTransformationFromMachineAdjustmentAction
) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState };
      return JSON.parse(JSON.stringify(store));
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    default:
      return store;
  }
};

export const [
  useActionScreenProduceTransformationFromMachineAdjustmentStore,
  dispatchActionScreenProduceTransformationFromMachineAdjustmentStore,
] = createStore(initialState, reducer);
