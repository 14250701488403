import { useCallback, useEffect, useState } from "react";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenIdentifyProductSerial.module.scss";
import ActionScreenIdentifyProductSerialIdentify from "./subviews/Identify/ActionScreenIdentifyProductSerialIdentify";




interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}

function ActionScreenIdentifyProductSerial(props: IProps) {
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);

  const currentActionScreenManager = useActionScreenManagerStore();

  
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  // states to allow clearing when loading a default stock location config

  const closeCallback = () => {
    props.callbackOnClose(
      currentActionScreenManager?.currentActionConfig?.method
    );
  };
  function stepConfirm(res: any) {
    /*let storeResult = confirmQuantity(res);
            if(storeResult !== false){
                    //
                    if(storeResult.quantity > 0){
                        let addingIrregularityObj = {...addingIrregularityData, filledIrregularityData:{...addingIrregularityData.filledIrregularityData, quantity: storeResult.quantity}};
                    
                        
                        
                    }
                    
                    setCurrentStep("SELECT_IRREGULARITY");
            } */
  }

  useEffect(() => {
    if (!didMount) {
      //mounting 

      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager?.currentActionConfig,
  ]);

  if (isLoading) {
    return <></>;
  }

  return(
      <ActionScreenIdentifyProductSerialIdentify callbackOnClose={()=>{
        props.callbackOnClose();
      }} callbackSuccessConfirm={(submitRes:any) => {
          props.callbackSuccessConfirm(submitRes);
      }}/>
  )

  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={closeCallback}
          title="Register Product Serialization"
        />

        <div className={styles.content}>
          <div className={styles.popupInfoQuestion}>
            Unexpected view
          </div>

          <div className={`${styles.selectionOuter}`}></div>

        </div>
      </div>
    </>
  );
}
export default ActionScreenIdentifyProductSerial;
