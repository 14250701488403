import createStore from "../../../../../../AppCustomStateManagement/createStore";

interface IMapObj {
  key: string;
  consumptionObj?: IArrObj | null;
}
interface IArrObj {
  Id_Product: number;
  Quantity: number;
  Id_WarehouseLocation: number;
  temp_warehouseLocationLabel?: string;
  Id_Warehouse: number;
  temp_warehouseLabel?: string;
  Id_Lot: number;
  temp_lotLabel?: string;
}



interface IMapFillerObj{
  key: string;
  arrayConsumptions?: IArrObj[] | null;
}
// Library interfaces
interface IActionScreen {
  mapConsumptions: Array<IMapObj> | Array<[]> | null; //used by single consumption mode
  mapFillerConsumptions: Array<IMapFillerObj> | Record<string, unknown> | null; //used by filler mode (one product consumption can be taken from multiple places)
}

interface IActionScreenAction {
  type: any;
  payload?: any;
}

const initialState = {
  mapFillerConsumptions:{},
  mapConsumptions: [],
};

const reducer = (store: IActionScreen, action: IActionScreenAction) => {
  switch (action.type) {
    case "RESET_STORE":
   
      let cleanState = JSON.parse(JSON.stringify(initialState));
      store = { ...cleanState };
      return store;
    case "SET_STATE":
      store = { ...store, ...action.payload };
      return store;
    case "SET_MAP_CONSUMPTIONS":
      store = { ...store, mapConsumptions: action.payload };
      return store;
    case "SET_MAP_FILLER_CONSUMPTIONS":
      store = { ...store, mapFillerConsumptions: action.payload };
      return store;
    
    default:
      return store;
  }
};

export const [
  useActionScreenAskBomConsumptionsStore,
  dispatchActionScreenAskBomConsumptionsStore,
] = createStore(initialState, reducer);
