import { IonButton } from "@ionic/react";
import { useRef, useState } from "react";
import useFeedbackService from "../../../../../../../../barrel/hooks/useFeedbackService";
import MobileWrapperCommunicationService from "../../../../../../../../barrel/services/mobileWrapperCommunicationService";
import { IHxfBarcodeSettings } from "../../../../../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import ScanBarcodePopup from "../../../../../../../FeaturePages/ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import { useProductionOrderSelectionStore } from "../../../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import ActionScreenTopBar from "../../../Components/ActionScreenTopBar";
import useActionScreenTopBar from "../../../Components/hooks/useActionScreenTopBar";
import styles from "./ActionRegisterProductSerializationManualView.module.scss";


interface IResult {
  irregularitysList: any;
}

interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}

function ActionRegisterProductSerializationManualView(props: IProps) {
  const secondsToConfirm = 1;
  const actTopBar = useActionScreenTopBar();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);
  const [lastScannedPossibleSerial, setLastScannedPossibleSerial] = useState<any>(null);

  const [currentSerialIndex, setCurrentSerialIndex] = useState(0);
  const [enabledConfirmBtn, setEnabledConfirmBtn] = useState(false);
  const feedbackService = useFeedbackService();
  const [serials, setSerials] = useState<any>([]);

  const countdown = useRef(0);
  const countdownTimer = useRef<any>(null);
  const [countdownVal, setCountdownVal] = useState<any>(null);

  const barcodeScannerSettings:IHxfBarcodeSettings = {
    useCamera:true,
    useExternalDevice:true,
    allowedScanFormats:[],
    allowAllFormats:true
  };


  const tickCountdown = () => {
      if(countdown.current > 0){
          countdownTimer.current = setTimeout(() => {
            countdown.current = countdown.current - 1;
            if(countdown.current === 0){
                setEnabledConfirmBtn(true);
            }
            setCountdownVal(countdown.current);
            tickCountdown();
          }, 1000);
      }
  }
  const initiateCountdown = () => {
      countdown.current = secondsToConfirm;
      setCountdownVal(secondsToConfirm);
      tickCountdown();
  }

  const resetLastScanned = () => {

    setEnabledConfirmBtn(false);
    setLastScannedPossibleSerial(null);
  }


  return (
    <>
    {showBarcodeScannerPopup && (
        <ScanBarcodePopup
        freeScannerMode={true}
        title="Scanner"
        visible={true}
        barcodeScannerSettings={barcodeScannerSettings}
        onClosedCamera={() => {
        
          if (
            MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
            barcodeScannerSettings.useCamera &&
            !barcodeScannerSettings.useExternalDevice
          ) {
            setShowBarcodeScannerPopup(false);
          }
        }}
        
        onFinishedScan={(result: any) => {
          console.log("RESULT: ", result);
  
         
            if(result?.resultScan){
                if(serials.includes(result.resultScan)){
                    feedbackService.notifyToast("This serial was already added", "error");
                    return;
                }
            }

          setShowBarcodeScannerPopup(false);
          let scannedPossibleSerial = result?.resultScan;
          if(scannedPossibleSerial){
            setLastScannedPossibleSerial(scannedPossibleSerial);
          }

       
          initiateCountdown();

       
        }}
        onClosePopup={() => {
          setShowBarcodeScannerPopup(false);
        }}
      />
    )}
      <div className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title="Register Product Serialization"
        />

        <div className={styles.content}>
          <div className={styles.popupInfoQuestion}>
          Assign Product Serials <b>({currentSerialIndex+1}/{productionOrderSelectionStore.productionRecord.scaleProductivity})</b>
          </div>

            {lastScannedPossibleSerial && (
                    <div className={styles.lastPossSerialContainer}>
                        <div className={styles.centerAskConfirm}>
                            Please confirm the serial number is correct.
                        </div>
                        <div className={styles.centerAskConfirm}>
                            <b>Serial:</b> {lastScannedPossibleSerial}
                        </div>
                       
                    </div>
            )}


          <div className={`${styles.selectionOuter}`}></div>

        </div>
      </div>


      <div className={styles.confirmationButtons}>
            <div className={styles.confirmBtnContainer}>
                <IonButton
                        disabled={showBarcodeScannerPopup}
                        className={`${styles.confBtn} ${styles.cancelBtn} `}
                        color={lastScannedPossibleSerial === null ? "primary" : "warning"}
                        onClick={() => {
                            console.log("SCANNING:");
                            setShowBarcodeScannerPopup(true);
                            resetLastScanned();
                        }}
                    >
                     
                        {lastScannedPossibleSerial === null ? (<>Scan</>) : (<>Scan Again</>)}
                </IonButton>

            </div>
            <div className={styles.confirmBtnContainer}>
                <IonButton
                        className={styles.confBtn}
                        disabled={lastScannedPossibleSerial === null || !enabledConfirmBtn}
                        onClick={() => {
                        if(productionOrderSelectionStore.productionRecord.scaleProductivity <= 0){
                            throw "unexpected scale productivity value";
                        }

                        if(lastScannedPossibleSerial){
                            let curSerials:any = serials;
                            curSerials.push(lastScannedPossibleSerial);
                            
                            
    

                            if(currentSerialIndex+1 >= productionOrderSelectionStore.productionRecord.scaleProductivity){
                                
                                props.callbackSuccessConfirm(
                                    {method:'MANUAL_ASSIGNMENT',serials:curSerials}
                                );
                            }else{
                                resetLastScanned();
                                setSerials(curSerials);
                                setCurrentSerialIndex(currentSerialIndex + 1);
                            }
                       
     
                        }
      
                        }}>
                        <div className={styles.btnIcon}>
                        
                        </div>
                        {!countdownVal ? (<>Confirm</>) : (
                            <>
                                {countdownVal > 0 ? (<>Confirm ({countdownVal})</>) : (<>Confirm</>)}
                            </>
                        )}
                       
                </IonButton>

            </div>


      </div>

    </>
  );
}
export default ActionRegisterProductSerializationManualView;
