import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";

function machineUsageActivityRecordsController() {


  const getById = (
    idMachineRecord:any,
    options:any,
    cancelToken: any
  ) => {
    if(!options){
      options = {};
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "machine-usage-activity-record/get/" + idMachineRecord,
      { ...options },
      cancelToken
    );
  };

  return {
    getById
  };
}

export default machineUsageActivityRecordsController;
