import BarrelWrapper from "./barrelWrapper";
import HxfAppServersService from "../services/hxfAppServersService";
function unavailabilityOccurrencesController() {
  const getByIdUnavailabilityOccurrence = (extraOptions: any = {}, Id_UnavailabilityOccurrence:any) => {
    let url =
      HxfAppServersService().getBackend() + "unavailability-occurrence/get/" + Id_UnavailabilityOccurrence;


    return BarrelWrapper().appPostRequest(url, {...extraOptions});
  };

  const finishUnavailabilityOccurrence = (options = {}, Id_UnavailabilityOccurrence:any) => {
    let url = HxfAppServersService().getBackend() + "unavailability-occurrence/finish";
    return BarrelWrapper().appPostRequest(url, {Id_UnavailabilityOccurrence:Id_UnavailabilityOccurrence,...options});
  }

  const cancelUnavailabilityOccurrence = (options = {}, Id_UnavailabilityOccurrence:any) => {
    let url = HxfAppServersService().getBackend() + "unavailability-occurrence/cancel";


    return BarrelWrapper().appPostRequest(url, {Id_UnavailabilityOccurrence:Id_UnavailabilityOccurrence,...options});
  }

  return { getByIdUnavailabilityOccurrence ,finishUnavailabilityOccurrence,cancelUnavailabilityOccurrence};
}



export default unavailabilityOccurrencesController;
