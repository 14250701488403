import { useCallback, useEffect, useState } from "react";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenProduceTransformationFromMachineAdjustment.module.scss";
import { Trans } from "react-i18next";
import { IonButton, IonIcon, IonLoading, useIonAlert } from "@ionic/react";
import { addOutline, chevronBack, closeOutline, layersOutline, removeOutline, tabletLandscapeOutline, trashOutline, tvOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import { useActionScreenAskBomConsumptionsStore,dispatchActionScreenAskBomConsumptionsStore } from "../ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptionsStore";
import { dispatchActionScreenProduceTransformationFromMachineAdjustmentStore, useActionScreenProduceTransformationFromMachineAdjustmentStore } from "./ActionScreenProduceTransformationFromMachineAdjustmentStore";
import ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons from "./ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons/ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons";
import HxfTextfieldNumber from "../../../../HxfTextfieldNumber/HxfTextfieldNumber";
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import ViewDayIcon from '@mui/icons-material/ViewDay';
import QueueIcon from '@mui/icons-material/Queue';
interface IResult {
  quantity: number;
}
function ActionScreenProduceTransformationFromMachineAdjustment(props: any) {
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const currentActionScreenManager = useActionScreenManagerStore();
  const [currentStep, setCurrentStep] = useState<"SET_TRANSFORMED_PRODUCT_QTY" | "SET_RESULTING_TRANSFORMATION_PRODUCT" | "SET_TRANSFORMATION_IRREGULARITIES" | "SET_TRANSFORMATION_REMAINS">("SET_TRANSFORMED_PRODUCT_QTY");
  const [indexkey, setIndexkey] = useState(0);
  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const [result, setResult] = useState<IResult>({ quantity: 0 });
  const screenState = useActionScreenProduceTransformationFromMachineAdjustmentStore();
  const [didMount, setDidMount] = useState(false);
  const {t} = useHxfTranslation();
  const askBomScreenState = useActionScreenAskBomConsumptionsStore();

  const [adjustedProducts, setAdjustedProducts] = useState<any>([]);

  const [errorNoAdjustedProducts, setErrorNoAdjustedProducts] = useState(false);
  const forceRerender = useCallback(() => {
    setIndexkey(indexkey + 1);
  }, [indexkey]);

  const confirmQuantity = useCallback(
    (res: any) => {
      if (isNaN(res) || res === "") {
        setShowErrorInput(true);
        forceRerender();
        return false;
      }
      let valueNumber = parseFloat(res) + 0;

      let allowZeroQuantity =
        currentActionScreenManager?.currentActionConfig
          ?.allowZeroQuantityProduction;
      if (allowZeroQuantity && valueNumber === 0) {
        let newResult = { ...result, quantity: 0 };
        setResult(newResult);
        return newResult;
      } else if (valueNumber === 0 && !allowZeroQuantity) {
        setShowErrorInput(true);
        forceRerender();
        return false;
      } else {
        //let returnObj = {quantity:valueNumber};
        let newResult = { ...result, quantity: valueNumber };
        setResult(newResult);
        return newResult;
        //props.callbackSuccessConfirm(returnObj);
      }

      return false;
    },
    [
      currentActionScreenManager?.currentActionConfig
        ?.allowZeroQuantityProduction,
      forceRerender,
      result,
    ]
  );

  const stepConfirm = useCallback(
    (res: any) => {
      if (currentStep === "SET_TRANSFORMED_PRODUCT_QTY") {
        let storeResult = confirmQuantity(res);
        if (storeResult !== false) {
          props.callbackSuccessConfirm(storeResult);
        }
      }
    },
    [props, confirmQuantity, currentStep]
  );

  const getAdjProductQty = (index:any) => {
  
    let val = screenState?.quantities?.[index] ? screenState?.quantities?.[index] : 0;

    return val;


  }



  const getRemainsAdjProductQty = (index:any) => {
    let curState = {...screenState};

    let arrayRemains = curState?.arrayRemains;
    if(arrayRemains?.[index]){

      return arrayRemains[index].remainsQuantity;
    }

    return "";
  }
  const setRemainsQuantity = (index: any, newQty:any) => {

    let curState = {...screenState};

    let arrayRemains = curState?.arrayRemains;
    if(arrayRemains?.[index]){


      let remainsProduct = curState.arrayRemains[index];
      remainsProduct.remainsQuantity = newQty;
      arrayRemains[index] = JSON.parse(JSON.stringify(remainsProduct));
      let newState = {...curState};
      newState.arrayRemains =  JSON.parse(JSON.stringify(arrayRemains));
      
      dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
        type: "SET_STORE",
        payload:newState,
      });
    }

  }

  const isEnabledConfirm = () => {
    //todo at least one > 0
    return hasAtLeastOneQtyDefined();

  }

  const getSelectedAdjustedProduct = () => {

    let curState = {...screenState};

    return curState?.selectedAdjustedProduct;
  }
  const setQuantity = (index: any, newQty:any) => {
    let curState = {...screenState};
    curState.quantities[index] = newQty;

    let selectedAdjustedProduct:any = adjustedProducts[index];
    selectedAdjustedProduct._indexSelectedAdjustedProduct = index;

    let selAdjProductTransformations = selectedAdjustedProduct?.transformations;
    if(selAdjProductTransformations){
      for(let i = 0; i<selAdjProductTransformations.length; i++){
        let expectedQtyToProducePerTransformation = selAdjProductTransformations[i].Quantity;
        let defaultProducedQtyTotal = expectedQtyToProducePerTransformation*newQty;
        selAdjProductTransformations[i].producedQuantity = defaultProducedQtyTotal;

      }
    }

    delete selectedAdjustedProduct?.irregularTransformations;

    curState.selectedAdjustedProduct = selectedAdjustedProduct;

    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:JSON.parse(JSON.stringify(curState)),
    });
  }

  const hasAtLeastOneQtyDefined = () => {

    let curState = {...screenState};
    let arrIndexesWQties = Object.keys(curState.quantities);
    for(let i = 0; i < arrIndexesWQties.length; i++){
      let idx = arrIndexesWQties[i];
      if(curState.quantities[idx] > 0){
        return true;
      }
    }

    return false;
  }

  const isEnabledNumericChange = (index:any) => {

    let curState = {...screenState};
    if(curState.quantities){
      let arrIndexesWQties = Object.keys(curState.quantities);
      for(let i = 0; i < arrIndexesWQties.length; i++){
        let idx = arrIndexesWQties[i];
        if(curState.quantities[idx] > 0){
          return parseInt(idx) === parseInt(index);
        }
      }
    }
    return true;
  }

  const isRemainsEnabledNumericChange = (index:any) => {

    return true;
  }

  const getProducedQuantity = (indexTransformation:any) => {

    let selectedAdjustedProduct = getSelectedAdjustedProduct();

    let transformations = selectedAdjustedProduct?.transformations;
    if(transformations?.[indexTransformation]){
      return transformations?.[indexTransformation]?.producedQuantity ? transformations?.[indexTransformation]?.producedQuantity : 0;
    }
    //
    return 0;
  }

  const finish = () => {
    let adjustedProduct = getSelectedAdjustedProduct();
    adjustedProduct.workedQuantity = getAdjProductQty(adjustedProduct._indexSelectedAdjustedProduct);
    let storeResult = {
      workedProductAndTransformations: adjustedProduct,
      transformationRemains:getCurrentRemainsArray()
    };
    props.callbackSuccessConfirm(storeResult);
  }

  const setProducedQuantity = (index: any, newQty:any) => {

    let curState = {...screenState};

    let selectedAdjustedProduct:any = curState.selectedAdjustedProduct;
    
    let selAdjProductTransformations = selectedAdjustedProduct?.transformations;

    if(selAdjProductTransformations?.[index]){
      selAdjProductTransformations[index].producedQuantity = newQty;
    }

    selectedAdjustedProduct.transformations = selAdjProductTransformations;
    delete selectedAdjustedProduct.irregularTransformations; //force to retype if things were changed

    curState.selectedAdjustedProduct = selectedAdjustedProduct;

    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:JSON.parse(JSON.stringify(curState)),
    });
  }

  const getIrregularQuantity = (indexIrregularTransformation:any) => {

    let selectedAdjustedProduct = getSelectedAdjustedProduct();

    let irregularTransformations = selectedAdjustedProduct?.irregularTransformations;
    if(irregularTransformations?.[indexIrregularTransformation]){
      return irregularTransformations?.[indexIrregularTransformation]?.irregularQuantity ? irregularTransformations?.[indexIrregularTransformation]?.irregularQuantity : 0;
    }
    //
    return 0;
  }

  const removeRemains = (index:any) => {
    let curState = {...screenState};
    if(!curState?.arrayRemains || curState.arrayRemains.length === 0){
      curState.arrayRemains = [];
    }
    let newArr = [];
    for(let i = 0; i< curState.arrayRemains.length; i++){
      if(i !== index){
        newArr.push(curState.arrayRemains[i]);
      }
    }
    curState.arrayRemains = JSON.parse(JSON.stringify(newArr));
    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:curState,
    });
  }
  const addNewRemain = () => {
    let curState = {...screenState};

    if(!curState?.arrayRemains || curState.arrayRemains.length === 0){
      curState.arrayRemains = [];
    }

    //currently adj product
    let adjustedProduct = curState.selectedAdjustedProduct;

    let newArrRemains = curState.arrayRemains;

    let newRemainsProductDefault = {
      Id_Product: adjustedProduct.Id_Product,
      productName:adjustedProduct.productName,
      productCode:adjustedProduct.productCode,
      arrayProductParameterizedVariables:[],
      remainsQuantity:0
    };

    let newPPVVs = adjustedProduct?.arrayProductParameterizedVariables ? JSON.parse(JSON.stringify(adjustedProduct?.arrayProductParameterizedVariables)) : [];
    //set the values as empty (numeric only for now)
    for(let i = 0; i<newPPVVs.length; i++){
      if(newPPVVs[i].varType === "numeric"){
        newPPVVs[i].Value = "";
      }
    }
    newRemainsProductDefault.arrayProductParameterizedVariables = newPPVVs;

    
    newArrRemains.push(newRemainsProductDefault);

    curState.arrayRemains = JSON.parse(JSON.stringify(newArrRemains));
    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:curState,
    });
  }

  const setupSetTransformationRemains = () => {

    //check if mapRemains defined already if not define default
    /*let curState = {...screenState};

    if(!curState?.arrayRemains || curState.arrayRemains.length === 0){
      curState.arrayRemains = [];

      //currently adj product
      let adjustedProduct = curState.selectedAdjustedProduct;

      let newRemainsProductDefault = {
        Id: adjustedProduct.Id,
        productName:adjustedProduct.productName,
        productCode:adjustedProduct.productCode,
        arrayProductParameterizedVariables:[],
        remainsQuantity:0
      };

      let newPPVVs = adjustedProduct?.arrayProductParameterizedVariables ? JSON.parse(JSON.stringify(adjustedProduct?.arrayProductParameterizedVariables)) : [];
      //set the values as empty (numeric only for now)
      for(let i = 0; i<newPPVVs.length; i++){
        if(newPPVVs[i].varType === "numeric"){
          newPPVVs[i].Value = "";
        }
      }
      newRemainsProductDefault.arrayProductParameterizedVariables = newPPVVs;

      
      curState.arrayRemains = [newRemainsProductDefault];
    }

    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:curState,
    });*/
    setCurrentStep("SET_TRANSFORMATION_REMAINS");
  }

  const getCurrentRemainsArray = () => {
    let curState = {...screenState};

    if(curState?.arrayRemains){

      return curState.arrayRemains;

    }

    return [];

  }
  
  const setAdjustedProductState = (newAdjProduct:any) => {

    
    let tmpObj = newAdjProduct;
    let convertedObj = JSON.parse(JSON.stringify(tmpObj));


    let curState = {...screenState};
    curState.selectedAdjustedProduct = convertedObj;
    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:curState,
    });
  }

  const checkHasErrorRemains = (index:any) => {
    let currentRemains = getCurrentRemainsArray();
    let productRemains = currentRemains[index];

    let remainsQuantity = productRemains?.remainsQuantity ? parseFloat(productRemains?.remainsQuantity) : 0;
    if(remainsQuantity > 0){
      //has qty defined all ppvvs must be defined!!
      let arrPPVVS = productRemains.arrayProductParameterizedVariables;
      for(let i = 0; i<arrPPVVS.length;i++){
        if(arrPPVVS[i].Value === null || arrPPVVS[i].Value === ""){
          return true;
        }
      }
    }

    return false;
  }

  const updateRemainsProductPPVV = (index:any, idProductParameterizedVariable:any, newValue:any) => {
    let curState = {...screenState};

    let arrayRemains = curState?.arrayRemains;
    if(arrayRemains?.[index]){


      let remainsProduct = curState.arrayRemains[index];
      let arrayPPVVS = remainsProduct?.arrayProductParameterizedVariables ? remainsProduct?.arrayProductParameterizedVariables : [];

      for(let i = 0; i< arrayPPVVS.length; i++){
        if(arrayPPVVS[i].Id_Product_ParameterizedVariable === idProductParameterizedVariable){
          arrayPPVVS[i].Value = newValue;
          break;
        }
      }

      remainsProduct.arrayProductParameterizedVariables = JSON.parse(JSON.stringify(arrayPPVVS));
      arrayRemains[index] = JSON.parse(JSON.stringify(remainsProduct));
      let newState = {...curState};
      newState.arrayRemains =  JSON.parse(JSON.stringify(arrayRemains));
      
      dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
        type: "SET_STORE",
        payload:newState,
      });
    }

  }

  const setIrregularQuantity = (index: any, newQty:any) => {

    let curState = {...screenState};


    let selectedAdjustedProduct:any = curState.selectedAdjustedProduct;
    
    let selAdjProductTransformations = selectedAdjustedProduct?.irregularTransformations;

    if(selAdjProductTransformations?.[index]){
      selAdjProductTransformations[index].irregularQuantity = newQty;
    }

    selectedAdjustedProduct.irregularTransformations = selAdjProductTransformations;

    curState.selectedAdjustedProduct = selectedAdjustedProduct;

    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
      type: "SET_STORE",
      payload:JSON.parse(JSON.stringify(curState)),
    });
  }

  useEffect(() => {
    if (!didMount) {
      let curActionCf = currentActionScreenManager?.currentActionConfig;
    
  
        if(curActionCf?.adjustedProducts){
            setAdjustedProducts(curActionCf?.adjustedProducts);
        }else{
            setErrorNoAdjustedProducts(true);
        }
    
     
    


      setDidMount(true);
    }
  }, [
    didMount,
    forceRerender,
    stepConfirm,
    currentActionScreenManager?.currentActionConfig,
    screenState.quantity,
  ]);


  if(errorNoAdjustedProducts){
    return (
        <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title={t('transformed.product.qty')}
          />
  
          <div className={styles.content}>
            <div className={styles.label}>
               <b>{t('no.products.adjusted.current.machine')}</b>
            </div>

          </div>
        </div>
      </>

    )
  }


  if (currentStep === "SET_TRANSFORMED_PRODUCT_QTY") {

    return (
      <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title={t('transformed.product.qty')}
          />
  
          <div className={styles.content}>
            <div className={styles.label}>
  
               <b>{t('select.the.transformed.product')}</b>
   
            </div>
            <div className={`${styles.stepIcon}`}>
              <div className={styles.stepIconFirst}>
              <ViewDayIcon/>
              </div>
              
            </div>
            {adjustedProducts && adjustedProducts?.length > 0 && (
              <>
              {adjustedProducts.map((objAdjProduct:any, index:any) => (
                  <div key={"adj_product_" + index} className={styles.adjProductContainer}>
                      <div style={{width:'100%',overflow:"hidden"}}>
                          <div className={styles.adjContainerTop}>
                              <b>{objAdjProduct.productName}</b>
                          </div>
                          <div style={{marginLeft:20,marginRight:20,marginTop:10,marginBottom:10,display:'flex'}}>
                      
                          
                          {objAdjProduct?.arrayProductParameterizedVariables?.length > 0 && (
                              <>
                              {objAdjProduct?.arrayProductParameterizedVariables.map((objAdjProductPPV:any, indexPPV:any) => (
                                  <div style={{marginLeft:10}} key={"adjproduct_" + index +"_ppv_" + indexPPV}>
                                      <b>{objAdjProductPPV.name}:</b> {objAdjProductPPV.Value}
                                  </div>
                              ))}
                              </>
                          )}
                          </div>
  
                          <div className={styles.inputContainerQtyWorked}>
                                        <IonButton
                                          data-btn={"subtract-qty-transf-" + index}
                                          disabled={!isEnabledNumericChange(index)}
                                          className={styles.addrembtn}
                                          onClick={() => {
                                            
                                              let qt = getAdjProductQty(index);
                                              if(!qt){
                                                  qt = 0;
                                              }

                                              let newQt = parseInt(qt) - 1;
                                              if(newQt < 0){
                                                  newQt = 0;
                                              }
                                    
                                              setQuantity(index, newQt);
                                          }}
                                      >
                                          <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                      </IonButton>
  
                                          <div>
                                          <HxfTextfieldNumber
                                            dataInputFieldAttributeValue={"input-qty-transf-idx-" + index}
                                            disabled={!isEnabledNumericChange(index)}
                                            fullWidth={true} centerInputText={true} error={false} 
                                            label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {
        
                                                if(evt !== null && evt !== ""){
                                                    evt = parseInt(evt); //ints only
                                                }
                                                setQuantity(index, evt);
                          
                                            }} value={getAdjProductQty(index) }
                                          /> 
                                          </div>
  
  
                                      <IonButton
                                        data-btn={"add-qty-transf-" + index}
                                        disabled={!isEnabledNumericChange(index)}
                                          className={styles.addrembtn}
                                          onClick={() => {
                                            
                                            let qt = getAdjProductQty(index);
                                            if(!qt){
                                                qt = 0;
                                            }
  
                                            let newQt = parseInt(qt) +1;
                                            setQuantity(index, newQt);
                                          }}
                                      >
                                          <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                      </IonButton>
                          </div>
                      </div>
  
  
                      <div>
  
                      </div>
                  </div>
              ))}
              </>
            )}
  
          </div>
        </div>
        <ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons
        enabled={isEnabledConfirm()}
        onClickConfirm={() => {
          setCurrentStep("SET_RESULTING_TRANSFORMATION_PRODUCT");
        }}
        />
      </>
    );

    }


    if(currentStep === "SET_RESULTING_TRANSFORMATION_PRODUCT"){
      return (
        <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title={t('transformed.product.qty')}
          />
  
          <div className={styles.content}>
            <div className={styles.label}>
  
               <b>{t('confirm.the.resulting.products')}</b>
   

            </div>
            <div className={`${styles.stepIcon}`}>
              <div className={styles.stepIconResultingProducts}>
              <IonIcon slot="icon-only" icon={layersOutline} size={"large"}></IonIcon>
              </div>
              
            </div>
            
            <div>
            <IonButton
              disabled={false}
             
                onClick={() => {
                  
                  //reset input qties
                   /* let curState = {...screenState};
                    
                 
                    let selectedAdjustedProduct:any = curState.selectedAdjustedProduct;
                    
                    let selAdjProductTransformations = selectedAdjustedProduct?.transformations;
                    if(selAdjProductTransformations){
                      for(let i = 0; i<selAdjProductTransformations.length; i++){
                        let expectedQtyToProducePerTransformation = selAdjProductTransformations[i].Quantity;
                        let defaultProducedQtyTotal = expectedQtyToProducePerTransformation*getAdjProductQty(selectedAdjustedProduct._indexSelectedAdjustedProduct);
                        selAdjProductTransformations[i].producedQuantity = defaultProducedQtyTotal;
                      }
                    }
                    curState.selectedAdjustedProduct = selectedAdjustedProduct;
      
                    dispatchActionScreenProduceTransformationFromMachineAdjustmentStore({
                      type: "SET_STORE",
                      payload:JSON.parse(JSON.stringify(curState)),
                    });
                 
                
                  */
                    setCurrentStep("SET_TRANSFORMED_PRODUCT_QTY");
                }}
            >
                <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
                {t('return.back')}
            </IonButton>
            </div>
            {getSelectedAdjustedProduct() && getSelectedAdjustedProduct()?.transformations?.length > 0 && (
              <>
              {getSelectedAdjustedProduct()?.transformations?.map((objResultingTransformProduct:any, index:any) => (
                  <div key={"adj_restproduct_" + index} className={styles.transfProductContainer}>
                      <div style={{width:'100%',overflow:"hidden"}}>
                          <div className={styles.transfContainerTop}>
                              <b>{objResultingTransformProduct.name}</b>
                          </div>
                          <div style={{marginLeft:20,marginRight:20,marginTop:10,marginBottom:10,display:'flex'}}>
                      
                          
                          {objResultingTransformProduct?.arrayProductParameterizedVariables?.length > 0 && (
                              <>
                              {objResultingTransformProduct?.arrayProductParameterizedVariables.map((objResTransProductPPV:any, indexPPV:any) => (
                                  <div style={{marginLeft:10}} key={"adjrestproduct_" + index +"_ppv_" + indexPPV}>
                                      <b>{objResTransProductPPV.name}:</b> {objResTransProductPPV.Value}
                                  </div>
                              ))}
                              </>
                          )}
                          </div>
  
                          <div className={styles.inputContainerQtyWorked}>
                                        <IonButton
                                         disabled={false}
                                          className={styles.addrembtn}
                                          data-btn={"subtract-qty-res-transf-" + index}
                                          onClick={() => {
                                           
                                              let qt = getProducedQuantity(index);
                                              if(!qt){
                                                  qt = 0;
                                              }
  
                                              let newQt = parseInt(qt) - 1;
                                              if(newQt < 0){
                                                  newQt = 0;
                                              }
                                    
                                    
                                              setProducedQuantity(index, newQt);
                                          }}
                                      >
                                          <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                      </IonButton>
  
                                          <div>
                                          <HxfTextfieldNumber
                                          dataInputFieldAttributeValue={"input-qty-res-transf-idx-" + index}
                                           disabled={false}
                                      fullWidth={true} centerInputText={true} error={false} 
                                      label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {
  
                                          if(evt !== null && evt !== ""){
                                              evt = parseInt(evt); //ints only
                                          }
                                          setProducedQuantity(index, evt);
                     
                                      }} value={getProducedQuantity(index) }/> 
                                          </div>
  
  
                                      <IonButton
                                      data-btn={"add-qty-res-transf-" + index}
                                        disabled={false}
                                          className={styles.addrembtn}
                                          onClick={() => {
                                            
                                            let qt = getProducedQuantity(index);
                                            if(!qt){
                                                qt = 0;
                                            }
  
                                            let newQt = parseInt(qt) +1;
                                            setProducedQuantity(index, newQt);
                                          }}
                                      >
                                          <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                      </IonButton>
                          </div>
                      </div>
  
  
                      <div>
  
                      </div>
                  </div>
              ))}
              </>
            )}
  
          </div>
        </div>
        <ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons
        enabled={isEnabledConfirm()}
        onClickConfirm={() => {
            let actionCfg = currentActionScreenManager?.currentActionConfig;
            //check if requires "asking transformed product irregular pcs"
            if(!actionCfg?.transformations_Id_Irregularity){

              let curActionCf = currentActionScreenManager?.currentActionConfig;
              if(curActionCf?.askTransfRemains === 1){
                setupSetTransformationRemains();
              }else{
                finish();
              }
      
            }else{
            
              let adjProduct = getSelectedAdjustedProduct();
         
              
              let irregularTransformations = [];
              let transformations = adjProduct?.transformations ? adjProduct.transformations : [];
              if(!adjProduct?.irregularTransformations){
                for(let j = 0; j<transformations.length; j++){

                  let irrTransform = JSON.parse(JSON.stringify(transformations[j]));
                  delete irrTransform.producedQuantity;
                  irrTransform.irregularQuantity = 0;
                  irregularTransformations.push(irrTransform);
                }
  
                adjProduct.irregularTransformations = irregularTransformations;
              
  
                setAdjustedProductState(adjProduct);
              }

              setCurrentStep("SET_TRANSFORMATION_IRREGULARITIES");
            }
 
            
        }}
        />
      </>
      )
    }

    if(currentStep === "SET_TRANSFORMATION_IRREGULARITIES"){
      return (
        <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title={t('irregular.product.qty')}
          />
  
          <div className={styles.content}>
            <div className={styles.label}>
  
               <b>{t('irregular.units')}</b>
   

            </div>
            <div className={`${styles.stepIcon}`}>
              <div className={`${styles.stepTrash}`}>
              <IonIcon slot="icon-only" icon={trashOutline} size={"large"}></IonIcon>
              </div>
              
            </div>
            <div>
            <IonButton
              disabled={false}
             
                onClick={() => {
                  setCurrentStep("SET_RESULTING_TRANSFORMATION_PRODUCT");
                }}
            >
                <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
                {t('return.back')}
            </IonButton>
            </div>
            {getSelectedAdjustedProduct() && getSelectedAdjustedProduct()?.irregularTransformations?.length > 0 && (
              <>
              {getSelectedAdjustedProduct()?.irregularTransformations?.map((objResultingTransformProduct:any, index:any) => (
                  <div key={"adj_restproduct_" + index} className={styles.irregularTransfProductContainer}>
                      <div style={{width:'100%',overflow:"hidden"}}>
                          <div className={styles.irrContainerTop}>
                              <b>{objResultingTransformProduct.name}</b>
                          </div>
                          <div style={{marginLeft:20,marginRight:20,marginTop:10,marginBottom:10,display:'flex'}}>
                      
                          
                          {objResultingTransformProduct?.arrayProductParameterizedVariables?.length > 0 && (
                              <>
                              {objResultingTransformProduct?.arrayProductParameterizedVariables.map((objResTransProductPPV:any, indexPPV:any) => (
                                  <div style={{marginLeft:10}} key={"adjrestproduct_" + index +"_ppv_" + indexPPV}>
                                      <b>{objResTransProductPPV.name}:</b> {objResTransProductPPV.Value}
                                  </div>
                              ))}
                              </>
                          )}
                          </div>
  
                          <div className={styles.inputContainerQtyWorked}>
                                        <IonButton
                                          data-btn={"irr-btn-subtract-idx-" + index}
                                          disabled={false}
                                          className={styles.addrembtn}
                                          onClick={() => {
                                           
                                              let qt = getIrregularQuantity(index);
                                              if(!qt){
                                                  qt = 0;
                                              }
  
                                              let newQt = parseInt(qt) - 1;
                                              if(newQt < 0){
                                                  newQt = 0;
                                              }
                                    
                                    
                                              setIrregularQuantity(index, newQt);
                                          }}
                                      >
                                          <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                      </IonButton>
  
                                          <div>
                                          <HxfTextfieldNumber
                                          dataInputFieldAttributeValue={"input-irr-qty-idx-" + index}
                                           disabled={false}
                                      fullWidth={true} centerInputText={true} error={false} 
                                      label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {
  
                                          if(evt !== null && evt !== ""){
                                              evt = parseInt(evt); //ints only
                                          }
                                          setIrregularQuantity(index, evt);
                     
                                      }} value={getIrregularQuantity(index) }/> 
                                          </div>
  
  
                                      <IonButton
                                        disabled={false}
                                          data-btn={"irr-btn-add-idx-" + index}
                                          className={styles.addrembtn}
                                          onClick={() => {
                                            
                                            let qt = getIrregularQuantity(index);
                                            if(!qt){
                                                qt = 0;
                                            }
  
                                            let newQt = parseInt(qt) +1;
                                            setIrregularQuantity(index, newQt);
                                          }}
                                      >
                                          <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                      </IonButton>
                          </div>
                      </div>
  
  
                      <div>
  
                      </div>
                  </div>
              ))}
              </>
            )}
  
          </div>
        </div>
        <ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons
        enabled={isEnabledConfirm()}
        onClickConfirm={() => {
              let curActionCf = currentActionScreenManager?.currentActionConfig;
              if(curActionCf?.askTransfRemains === 1){
                setupSetTransformationRemains();
              }else{
                finish();
              }
        }}
        />
      </>
      )
    }


    if(currentStep === "SET_TRANSFORMATION_REMAINS"){

      return (
        <>
          <div key={indexkey + "_forceRerender"} className={styles.container}>
            <ActionScreenTopBar
              callbackOnClose={props.callbackOnClose}
              title={t('transformed.remains.product.qty')}
            />
    
            <div className={styles.content}>
              <div className={styles.label}>
    
                 <b>{t('set.the.remains.qty')}</b>
               
              </div>
              <div className={`${styles.stepIcon}`}>
                <div className={`${styles.stepRemains}`}>
                <QueueIcon fontSize={"medium"}/>
                </div>
                
              </div>
              <div>
            <IonButton
              disabled={false}
             
                onClick={() => {
                  setCurrentStep("SET_TRANSFORMATION_IRREGULARITIES");
                }}
            >
                <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
                {t('return.back')}
            </IonButton>
            </div>

            <div style={{marginBottom:50}}>

            
              {getCurrentRemainsArray()?.length > 0 && (
                <>
                {getCurrentRemainsArray().map((objRemainsProduct:any, index:any) => (
                    <div key={"remains_product_" + index} className={styles.remainsProductContainer}>
                        <div style={{width:'100%'}}>
                            <div className={styles.remainsContainerTop}>
                                <div><b>{objRemainsProduct.productName}</b></div>
                                <div onClick={() => {
                                  removeRemains(index);
                                }} className={styles.remainsCloseBtn}>X</div>
                            </div>
                            <div style={{display:'flex'}}>
                        
                            
                            {objRemainsProduct?.arrayProductParameterizedVariables?.length > 0 && (
                                <>
                                {objRemainsProduct?.arrayProductParameterizedVariables.map((objRemainsProductPPV:any, indexPPV:any) => (
                                    <div style={{margin:10,width:'100%',display:'flex',justifyContent:'center'}} key={"remainsproduct_" + index +"_ppv_" + indexPPV}>
                                        {
                                          objRemainsProductPPV?.varType === "numeric" ? (
                                            <>
                                            <HxfTextfieldNumber
                                              dataInputFieldAttributeValue={"input-field-new-pv-value-idxproduct-" + index + "-idxppv-" + indexPPV}
                                              fullWidth={true}
                                              centerInputText={true}
                                              error={checkHasErrorRemains(index)}
                                              label={objRemainsProductPPV.name} 
                                              onChange={(evt:any) => {
                                                  let newValue = evt;
                                                  updateRemainsProductPPVV(index, objRemainsProductPPV.Id_Product_ParameterizedVariable, newValue);
                                              }}
                                              value={objRemainsProductPPV.Value}
                                            /> 
                                            </>
                                          ) : (
                                            <div style={{margin:10}}><b>{objRemainsProductPPV.name}:</b> {objRemainsProductPPV.Value}</div>
                                          )
                                        }
                                        
                                    </div>
                                ))}
                                </>
                            )}
  
                            </div>



                            <div className={styles.inputContainerQtyWorked}>
                                          <IonButton
                                            data-btn={"subcontract-remains-qty-idx-" + index}
                                           disabled={!isRemainsEnabledNumericChange(index)}
                                            color="success"
                                            className={`${styles.addrembtn} ${styles.remainsBtnStyle} `}
                                            onClick={() => {
                                             
                                                let qt = getRemainsAdjProductQty(index);
                                                if(!qt){
                                                    qt = 0;
                                                }
    
                                                let newQt = parseInt(qt) - 1;
                                                if(newQt < 0){
                                                    newQt = 0;
                                                }
                                      
                                      
                                                setRemainsQuantity(index, newQt);
                                            }}
                                        >
                                            <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                        </IonButton>
    
                                            <div>
                                            <HxfTextfieldNumber
                                           
                                              fullWidth={true} centerInputText={true} error={false} 
                                              label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {
          
                                                  if(evt !== null && evt !== ""){
                                                      evt = parseInt(evt); //ints only
                                                  }
                                                  setRemainsQuantity(index, evt);
                            
                                              }}
                                              value={getRemainsAdjProductQty(index)}
                                            /> 
                                            </div>
    
    
                                        <IonButton
                                          disabled={!isRemainsEnabledNumericChange(index)}
                                          data-btn={"add-remains-qty-idx-" + index}
                                            className={styles.addrembtn}
                                            color="success"
                                            onClick={() => {
                                              
                                              let qt = getRemainsAdjProductQty(index);
                                              if(!qt){
                                                  qt = 0;
                                              }
    
                                              let newQt = parseInt(qt) +1;
                                              setRemainsQuantity(index, newQt);
                                            }}
                                        >
                                            <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                        </IonButton>
                            </div>
                        </div>
    
    
                        <div>
    
                        </div>
                    </div>
                ))}
                </>
              )}

              <div style={{display:'flex',justifyContent:'center',marginTop:30}}>
                <IonButton
                  disabled={false}
                    data-btn={"add-new-remains"}
                    className={styles.addrembtn}
                    color="success"
                    onClick={() => {
                      
                      addNewRemain();
                    }}
                >
                    <IonIcon slot="start" icon={addOutline}></IonIcon>
                    {t('add.new.remains')}
                </IonButton>
              </div>
            </div>
            </div>
          </div>
          <ActScreenProduceTransformationFromMachineAdjustmentConfirmationButtons
          enabled={isEnabledConfirm()}
          onClickConfirm={() => {

            let currentRemains = getCurrentRemainsArray();
            for(let i=0; i<currentRemains.length; i++){
              let remainsQuantity = currentRemains[i]?.remainsQuantity ? parseFloat(currentRemains[i]?.remainsQuantity) : 0;
              if(remainsQuantity > 0){
                //has qty defined all ppvvs must be defined!!
                let arrPPVVS = currentRemains[i].arrayProductParameterizedVariables;
                for(let i = 0; i<arrPPVVS.length;i++){
                  if(arrPPVVS[i].Value === null || arrPPVVS[i].Value === ""){
                    console.log("Has errors , remains");
                    return;
                  }
                }
              }

            }

            finish();
          }}
          />
        </>
      );

    }

    return (<>unexpected step</>)
}

export default ActionScreenProduceTransformationFromMachineAdjustment;
