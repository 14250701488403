import { useEffect } from "react";
import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IAction {
  resultMatchingFixedVariables: any;

}

interface IActionScreen {
  type: any;
  payload?: any;
}

const initialState = {
  resultMatchingFixedVariables: [],
};

const reducer = (store: IAction, action: IActionScreen) => {
  switch (action.type) {
    case "RESET_STORE":

      let newStore2 = {...initialState};
   
      return JSON.parse(JSON.stringify(newStore2));
    case "SET_FIXED_VARIABLES_DATA":
      let newStore = { ...store, resultMatchingFixedVariables: [ ...action.payload ] };
      store = { ...newStore };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    default:
      return store;
  }
};

export const [
  useActionScreenMatchFixedVariablesStore,
  dispatchActionScreenMatchFixedVariablesStore,
] = createStore(initialState, reducer);


export const manageActionScreenMatchFixedVariables = () => {
  const screenState = useActionScreenMatchFixedVariablesStore();
  const getTest = () => {
    let aXk = screenState;

    return "test";
  };



  const setMachingFixedVariablesArr = (newArr:any) => {
  
    dispatchActionScreenMatchFixedVariablesStore({
      type: "SET_FIXED_VARIABLES_DATA",
      payload: newArr,
    });
  }
  const setMatchingFixedVariableValue = (index:any, newValue:any) => {
 
      let currentArr = screenState.resultMatchingFixedVariables;
      currentArr[index].Value = newValue;
      dispatchActionScreenMatchFixedVariablesStore({
        type: "SET_FIXED_VARIABLES_DATA",
        payload: currentArr,
      });
      return currentArr;
  }


  const getResultMatchingFixedVariableValue = (index:any) => {
    return screenState.resultMatchingFixedVariables?.[index]?.Value !== null ? screenState.resultMatchingFixedVariables[index]?.Value : null;
  }

  const getCurrentResultMachingFixedVariableObj = (index:any) => {
    return screenState.resultMatchingFixedVariables?.[index];
  }

  const getResultMatchingFixedVariables = () => {
    let data = screenState?.resultMatchingFixedVariables ? screenState?.resultMatchingFixedVariables : [];

    return data;
  }

  const resetStore = () => {
    dispatchActionScreenMatchFixedVariablesStore({
      type: "RESET_STORE",
    });
  }

  useEffect(() => {
    console.log("SCREENSTATE MODFD: ", screenState);
  }, [screenState]);
  return { resetStore,getTest,getCurrentResultMachingFixedVariableObj, setMachingFixedVariablesArr,setMatchingFixedVariableValue, getResultMatchingFixedVariables,getResultMatchingFixedVariableValue };
};
