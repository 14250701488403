import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useCallback, useEffect, useState } from "react";
import ProductionOrdersDao from "../../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import styles from "./ShippingsStandard.module.scss";
import SellIcon from '@mui/icons-material/Sell';
function ShippingsStandard(props) {
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);

  const {t} = useHxfTranslation();
  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const loadPageChecks = useCallback(() => {
    setLoadingPage(false);
  }, [setLoadingPage]);

  useEffect(() => {
    if (!didMount) {
      loadPageChecks();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/home" />
      <div className={styles.pageTitle}>{t("shippings")}</div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
          <IonCardHeader
            className={`${styles.elemElement}`}
            onClick={() => {
              props.history.push({
                pathname: "/feature/shippings/shipping-orders",
              });
            }}
            data-btn={"all-shippings"}
          >
            <div className={styles.elemBackgroundContainer}>
              <LocalShippingIcon />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>
                {t("all.expected.shippings")}
              </div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t("shippings")}
            </IonCardTitle>
          </IonCardHeader>

          <IonCardHeader
            className={`${styles.elemElement}`}
            onClick={() => {
              props.history.push({
                pathname: "/feature/shippings/sales-orders",
              });
            }}
          >
            <div className={styles.elemBackgroundContainer}>
              <SellIcon />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>
                {t("salesorders.shippings")}
              </div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
            {t("salesorders")}
            </IonCardTitle>
          </IonCardHeader>

        </div>
      </div>
    </InAppTemplate>
  );
}

export default ShippingsStandard;
