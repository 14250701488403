import { IonIcon } from "@ionic/react";
import { hardwareChipOutline, qrCodeOutline } from "ionicons/icons";
import styles from "./MachineIdentifierScanner.module.scss";
import Ripples from "react-ripples";
import { fileTrayOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import ScanBarcodePopup from "../../../../../../FeaturePages/ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import { useState } from "react";
import useFeedbackService from "../../../../../../../barrel/hooks/useFeedbackService";
import MobileWrapperCommunicationService from "../../../../../../../barrel/services/mobileWrapperCommunicationService";
import MachineIdentifierService from "../../../../../../../barrel/services/machineIdentifierService";

interface IProps{
    onReturnSelectedMachine:any;
}

function MachineIdentifierScanner(props: IProps) {
  const {t} = useHxfTranslation();
  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);
  const feedbackService = useFeedbackService(); 

  const isCompatible = true;//MobileWrapperCommunicationService().isDeviceMobileWrapper();


  const getElement = () => {
    let innerClickableElement = (
      <div
        className={` ${styles.descIconContainer}`}
      >
        <div className={styles.addIconContainer}>
            <IonIcon icon={qrCodeOutline} size="large" />
        </div>
        <div className={styles.codeNameContainer}>
          <div>Scanner</div>
        </div>
      </div>
    );


    return (
        <>
          {showBarcodeScannerPopup && (
                <ScanBarcodePopup
                freeScannerMode={true}
                title="Scanner"
                visible={true}
                barcodeScannerSettings={{
                    useCamera:true,
                    useExternalDevice:false,
                    allowedScanFormats:[{type:'qrcode',pattern:MachineIdentifierService().getMachineIdentifierFormatQrcode()},{type:'qrcode',pattern:MachineIdentifierService().getMachineAndSegmentIdentifierFormatQrcode()}],
                    allowAllFormats:false,
                    initCameraOnStartUp:true
                }}
                onClosedCamera={() => {
                    setShowBarcodeScannerPopup(false);
                }}
                onInvalidScan={() => {
                    feedbackService.notifyToast("Invalid code scanned","warning");
                }}
                onFinishedScan={(result: any) => {
                 

                    let scannedIdentifier = result?.resultScan;
                    if(!scannedIdentifier){
                        feedbackService.notifyToast("Invalid code scanned","warning");
                        setShowBarcodeScannerPopup(false);
                    }else{
                       

                        let idMachine = null;
                        let idMachineSegment = null;
                        let splittedString = scannedIdentifier.split("-");

            
                        //returns ex:  SFSMACH-3 or SFSMACHANDSEG-[machine.id]-[machinesegment.id]
                        if(scannedIdentifier.includes("SFSMACH-")){
                             idMachine = splittedString[1];
                        }else if(scannedIdentifier.includes("SFSMACHANDSEG-")){
                             idMachine = splittedString[1];
                             idMachineSegment = splittedString[2];
                        }
                        if(!idMachine){
                            throw "invalid machine selected";
                        }
                      
                        props.onReturnSelectedMachine(idMachine, idMachineSegment);
                        setShowBarcodeScannerPopup(false);
                    }
                }}
                onClosePopup={() => {
                    setShowBarcodeScannerPopup(false);
                }}
                />
            )}
        <Ripples
      
      onClick={() => {
  
          setShowBarcodeScannerPopup(true);
      }}
    >
      {innerClickableElement}
    </Ripples>
        </>

    );
  };

  return (
    <>
        {isCompatible && (
            <div
            className={`${styles.outerContainer} ${
                showBarcodeScannerPopup === true
                ? styles.outerContainerSelected
                : styles.outerContainerUnselected
            }`}
            >
            {getElement()}
            </div>
        )}

    </>

  );
}

export default MachineIdentifierScanner;
