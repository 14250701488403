import { useEffect, useRef, useState } from "react";
import useLocalStorageHandler from "./useLocalStorageHandler";

function useWorkerMode(){
    const myLocalStorage = useLocalStorageHandler();
    const [currentWorkerMode, setCurrentWorkerMode] = useState("SMW_MODE_SINGLE");
    const [didMount, setDidMount] = useState(false);
  
    const  changeToSingle = () => {
        myLocalStorage.setSMWMode("SMW_MODE_SINGLE");
        window.dispatchEvent(new Event("useWorkerMode_changed"));
        
    }

    const  changeToMulti = () => {
        myLocalStorage.setSMWMode("SMW_MODE_MULTI");
        window.dispatchEvent(new Event("useWorkerMode_changed"));
      
    }




    useEffect(()=>{

        function handleChangeLocalStorage(){
            let settingTo = myLocalStorage.getSMWMode();
            console.log("Changed Worker Mode ls,",settingTo);
            setCurrentWorkerMode(settingTo);
        }
        window.addEventListener('useWorkerMode_changed', handleChangeLocalStorage);

        //todo cleanup
        return () => {
            console.log("cleanup useWorkerMode");
            window.removeEventListener("useWorkerMode_changed",handleChangeLocalStorage);
        }
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        if(!didMount){

            let loadedMode = myLocalStorage.getSMWMode();
            console.log("loaded mode ",loadedMode);
            if(loadedMode === "SMW_MODE_SINGLE"){
                changeToSingle();
            }else{
                changeToMulti();
            }
         
            setDidMount(true);
        }
    // eslint-disable-next-line
    },[didMount,myLocalStorage]);

    return {changeToSingle,changeToMulti,currentWorkerMode};
}

export default useWorkerMode;