import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function spaceDriveController() {


  const generateDownloadUrl = (objData:any = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + 'sfs-drive/get-download-url',
      objData
    );
  }

  return { generateDownloadUrl };
}

export default spaceDriveController;
