import { useCallback, useEffect, useState } from "react";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./actionScreenScaleProductivity.module.scss";
import { Trans } from "react-i18next";
import {
  dispatchActionScreenScaleProductivityStore,
  useActionScreenScaleProductivityStore,
} from "./ActionScreenScaleProductivityStore";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import { useActionScreenAskBomConsumptionsStore,dispatchActionScreenAskBomConsumptionsStore } from "../ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptionsStore";
import AsspLayoutAskWorkedTime from "./alternativeLayouts/askWorkedTime/asspLayoutAskWorkedTime";

interface IResult {
  quantity: number;
}
function ActionScreenScaleProductivity(props: any) {
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const currentActionScreenManager = useActionScreenManagerStore();
  const [currentStep, setCurrentStep] = useState("CONFIRM_QTY");
  const [indexkey, setIndexkey] = useState(0);
  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const [result, setResult] = useState<IResult>({ quantity: 0 });
  const screenState = useActionScreenScaleProductivityStore();
  const [didMount, setDidMount] = useState(false);
  const {t} = useHxfTranslation();
  const askBomScreenState = useActionScreenAskBomConsumptionsStore();
  const [askWorkedTimeAllowConfirmation, setAskWorkedTimeAllowConfirmation] = useState(true);
  const forceRerender = useCallback(() => {
    setIndexkey(indexkey + 1);
  }, [indexkey]);

  const confirmQuantity = useCallback(
    (res: any) => {
      if (isNaN(res) || res === "") {
        setShowErrorInput(true);
        forceRerender();
        return false;
      }
      let valueNumber = parseFloat(res) + 0;

      let allowZeroQuantity =
        currentActionScreenManager?.currentActionConfig
          ?.allowZeroQuantityProduction;
      if (allowZeroQuantity && valueNumber === 0) {
        let newResult = { ...result, quantity: 0 };
        setResult(newResult);
        return newResult;
      } else if (valueNumber === 0 && !allowZeroQuantity) {
        setShowErrorInput(true);
        forceRerender();
        return false;
      } else {
        //let returnObj = {quantity:valueNumber};
        let newResult = { ...result, quantity: valueNumber };
        setResult(newResult);
        return newResult;
        //props.callbackSuccessConfirm(returnObj);
      }

      return false;
    },
    [
      currentActionScreenManager?.currentActionConfig
        ?.allowZeroQuantityProduction,
      forceRerender,
      result,
    ]
  );

  const genericUpdateProductivityScale = () => {

    if (askBomScreenState?.mapConsumptions?.length > 0) {
      //resetting store allows useEffect recalculation at askBomConsumption
      dispatchActionScreenAskBomConsumptionsStore({
        type: "RESET_STORE",
      });
    }
  }

  const updateProductivityScaleLayoutHours = (res:any) => {
    dispatchActionScreenScaleProductivityStore({
      type: "SET_HOURS",
      payload: res,
    });

    genericUpdateProductivityScale();
  }

  const updateProductivityScaleLayoutMinutes = (res:any) => {
    dispatchActionScreenScaleProductivityStore({
      type: "SET_MINUTES",
      payload: res,
    });

    genericUpdateProductivityScale();
  }
  const updateProductivityScaleVal = (res:any) => {
    dispatchActionScreenScaleProductivityStore({
      type: "SET_QUANTITY",
      payload: res,
    });

    genericUpdateProductivityScale();
  }
  const stepConfirm = useCallback(
    (res: any) => {
      if (currentStep === "CONFIRM_QTY") {
        let storeResult = confirmQuantity(res);
        if (storeResult !== false) {
          props.callbackSuccessConfirm(storeResult);
        }
      }
    },
    [props, confirmQuantity, currentStep]
  );

  const getProperUomLabel = () => {

    let layout = currentActionScreenManager?.currentActionConfig;
    if(layout?.customUnitText){
      return layout.customUnitText;
    }
 
    return "pcs";
  }

  const getDefinedLayout = () => {
    let layout = currentActionScreenManager?.currentActionConfig?.layout;

    if(!layout || layout == null){
      return (
        <div className={`${styles.content} ${styles.normalLayoutHeight}`}>
          
          <div className={styles.label}>
            <Trans i18nKey="type.amount.of.worked.on" values={{productname:productionOrderSelectionStore.product.name}}>
                Type in the amount of <b>{productionOrderSelectionStore.product.name}</b> being worked on
            </Trans>
          </div>

          <div
            className={styles.keyPad}
            key={"produceQtyKeyPad_" + remountKeypadIndex}
          >
            <HxfDigitKeyboard
              uomLabel={getProperUomLabel()}
              defaultValue={screenState.quantity}
              onChange={(res) => {
                console.log("CHANGING::: ", res);

                updateProductivityScaleVal(res);
         
              }}
              showErrorInput={showErrorInput}
              onConfirmation={(res) => {
            
                stepConfirm(res);
              }}
              enableButtonOnMount={true}
            />
          </div>
        </div>
      )
    }

    if(layout === "ASK_WORKED_TIME"){
      return (
        <AsspLayoutAskWorkedTime onClearError={() => {
          if(showErrorInput){
            setShowErrorInput(false);
          }
        }} hasError={showErrorInput} allowConfirmation={askWorkedTimeAllowConfirmation} contentClass={styles.content} currentHours={screenState?.layoutHours} currentMinutes={screenState?.layoutMinutes}
          onUpdatedHours={(res:any) => {
            updateProductivityScaleLayoutHours(res);
          }}
          onUpdatedMinutes={(res:any) => {
            updateProductivityScaleLayoutMinutes(res);
          }}
          onConfirm={() => {
            //setAskWorkedTimeAllowConfirmation(false);
            let calculatedResult:any = 0;
            let hours = screenState?.layoutHours ? screenState?.layoutHours : 0;
            let minutes = screenState?.layoutMinutes ? screenState?.layoutMinutes : 0;

            let minutesToHours:any = 0;
            if(minutes > 0){
              minutesToHours = minutes/60;
            }
            calculatedResult = parseInt(hours) + parseFloat(minutesToHours);
            calculatedResult = calculatedResult.toFixed(2);
            stepConfirm(calculatedResult);
          }}
        />
      )
    }


    return (<>Unexpected layout</>)
  }

  useEffect(() => {
    if (!didMount) {
      let curActionCf = currentActionScreenManager?.currentActionConfig;

      let checkIfCurActionQtyIsNaN = isNaN(curActionCf.quantity);
      if (
        !checkIfCurActionQtyIsNaN &&
        curActionCf?.scaleProductivitySkipConfirmation === 1
      ) {
        stepConfirm(curActionCf.quantity);
      } else if (
        screenState.quantity === "" &&
        curActionCf?.quantity &&
        !checkIfCurActionQtyIsNaN
      ) {
        dispatchActionScreenScaleProductivityStore({
          type: "SET_QUANTITY",
          payload: curActionCf?.quantity,
        });
        forceRerender();
      }
      setDidMount(true);
    }
  }, [
    didMount,
    forceRerender,
    stepConfirm,
    currentActionScreenManager?.currentActionConfig,
    screenState.quantity,
  ]);

  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('quantity')}
        />
        {getDefinedLayout()}

      </div>
    </>
  );
}

export default ActionScreenScaleProductivity;
