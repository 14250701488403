import styles from "./ProductionTopInfo.module.scss";
import { useProductionOrderSelectionStore } from "../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { withRouter } from "react-router";
import ProductionService from "../../../../barrel/services/productionService";
function ProductionTopInfo(props) {
  const productionService = ProductionService();
  const selectionData = useProductionOrderSelectionStore();
  let productionOrder = selectionData?.productionOrder?.name
    ? selectionData.productionOrder
    : null;
  let product = selectionData?.product?.name ? selectionData.product : null;
  let currentOperation = selectionData?.currentOperation?.name
    ? selectionData.currentOperation
    : null;
  let nextOperation = selectionData?.nextOperation?.name
    ? selectionData.nextOperation
    : null;

  //path validation to show
  if (
    !productionService.isProductionOrderSetupRoute(props.match.path) &&
    !productionService.isProductionRoute(props.match.path)
  ) {
    return <></>;
  }

  return (
    <>
      {productionOrder !== null && (
        <div className={styles.productionOrderInfo}>
          ({productionOrder.name}) {productionOrder.code}
        </div>
      )}

      {product !== null && (
        <div className={styles.productInfo}>{product.name}</div>
      )}
    </>
  );
}
export default withRouter(ProductionTopInfo);
