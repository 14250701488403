import { IonLoading } from "@ionic/react";
import SimpleContentPopup from "../../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import UnavailabilityActionConsumeMaterialConfirmButtons from "./ConfirmationButtons/UnavailabilityActionConsumeMaterialConfirmationButtons";
import styles from "./UnavailabilityActionConsumeMaterial.module.scss";
import { Table,TableBody,TableCell,TableContainer,TableHead,TableRow, TextField} from '@mui/material';
import { styled } from '@mui/material';
import { Button, Paper } from '@mui/material';
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useOngoingUnavailabilityOccurrenceHook from "../../../../FeaturePages/OnGoingUnavailabilityOccurrence/store/onGoingUnavailabilityOccurrenceStore";
import { useEffect, useState } from "react";
import HxfTextfieldNumber from "../../../HxfTextfieldNumber/HxfTextfieldNumber";
import { IonButton, IonIcon } from '@ionic/react';
import { add, addCircle, remove } from "ionicons/icons";
import HxfSelectorField from "../../../HxfSelectorField/HxfSelectorField";
interface IProps{
    onConfirm?:any;
    onCancel?:any;
}

export const getUnavailabilityActionConsumeMaterialCode = () => {
    return "UNAVACT_MATERIAL_CONSUMPTION";
}
const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));

 interface IConsumptionObj{
    Id_Product:any;
    name:any;
    code:any;
    arrayProductParameterizedVariables:any;
    Quantity:any;
 }
function UnavailabilityActionConsumeMaterial(props:IProps){
    const {getCurrentUnavailabilityAction} = useOngoingUnavailabilityOccurrenceHook();
    const [didMount, setDidMount] = useState(false);
    const [consumptionsList, setConsumptionsList] = useState<IConsumptionObj[]>([]);
    const [filterInput, setFilterInput] = useState<any>(null);
    const getDefaultOptions = () => {
        let opts = [];
       
        for(let i = 0; i<consumptionsList.length; i++){
            let value = consumptionsList[i].Id_Product;
            let label = "(" + consumptionsList[i].code + ") " + consumptionsList[i].name;
            opts.push({value:value, label:label});
        }
        return opts;
    }

    const getConsumptionListItems = () => {
        let filteredResults = [];
        for(let i = 0; i<consumptionsList.length; i++){
            if(!filterInput?.value || (filterInput?.value && consumptionsList[i].Id_Product === filterInput?.value)){
                filteredResults.push(consumptionsList[i]);
            }
        }

        return filteredResults;
    }

    useEffect(() => {

        if(!didMount){

            let currentUnavAct = getCurrentUnavailabilityAction();

            let consumableProductsArray = currentUnavAct?._actionData?.consumableProductsArray ? currentUnavAct?._actionData?.consumableProductsArray : [];

            let consumptionLocation = currentUnavAct?._actionData?.consumptionLocation ? currentUnavAct?._actionData?.consumptionLocation : {Id_Lot:null,Id_Warehouse:null,Id_WarehouseLocation:null};
            let settingConsumptionsList = [];
            for(let i = 0; i<consumableProductsArray.length; i++){
                settingConsumptionsList.push({
                    Id_Product:consumableProductsArray[i].Id_Product,
                    name:consumableProductsArray[i].name,
                    code:consumableProductsArray[i].code,
                    arrayProductParameterizedVariables:consumableProductsArray[i].arrayProductParameterizedVariables,
                    Quantity:consumableProductsArray[i]?.defaultQuantity ? consumableProductsArray[i]?.defaultQuantity : 0,
                    Id_Lot:consumptionLocation.Id_Lot,
                    Id_Warehouse:consumptionLocation.Id_Warehouse,
                    Id_WarehouseLocation:consumptionLocation.Id_WarehouseLocation
                });
            }
           
            setConsumptionsList(JSON.parse(JSON.stringify(settingConsumptionsList)));
            setDidMount(true);
        }
    },[didMount,getCurrentUnavailabilityAction]);
    
    const {t} = useHxfTranslation();

    if(!didMount){
        return (<>loading...</>)
    }
    return (
        <>
            <div className={`${styles.contentWidthDimensions} `}>
                <div className={styles.maintenanceTitleContainer}>
                    <div className={styles.maintenanceTitl}>{t('maintenance.consumed.material')}</div>
                </div>
                <div className={styles.customFilterContainer}>
                    <div>
                        <HxfSelectorField
                        centerInputText={true}
                            error={false}
                            uniqueId={"actmachunav_sel_consume"}
                            disabled={false}
                            onChange={(evt:any, val:any) => {
                                setFilterInput(val);
                            }}
                            value={filterInput}
                            defaultItems={getDefaultOptions()}
                            labelPlaceholder={t('search.product')}
                        />
                    </div>
                </div>
            </div>
            <div className={`${styles.content} ${styles.contentWidthDimensions} `}>
                <div>
                    
                    <div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center', flexWrap:'wrap', marginBottom:'20px'}}>
                            {getConsumptionListItems().map((obj:IConsumptionObj,index:any) => (
                            <div className={styles.consumptionMatContainer} key={"cons_mat_maint_" + index}>
                                <div className={styles.consumptionMatContainerBox}>
                                    <div>
                                        <div>
                                            ({obj.code}) 
                                        </div>
                                        <div>
                                            {obj.name}
                                        </div>
                                        {obj.arrayProductParameterizedVariables.length > 0 && (
                                        <div>
                                            {obj.arrayProductParameterizedVariables.map((objPV:any, indexPV:any) => (
                                                <div key={"cons__" + index + "__pv__"+ indexPV}>
                                                    <b>{objPV.name}:</b> {objPV.Value}
                                                </div>
                                            ))}
                                        </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className={styles.inputBoxContainer}>
                                        <HxfTextfieldNumber  fullWidth={true} error={false} label={t('quantity')} onChange={(evt:any) => {
                                            let defVal:any = 0;
                                            if(evt === ""){
                                                defVal = 0;
                                            }else{
                                                defVal = parseFloat(evt);
                                            }

                                            if(defVal < 0){
                                                defVal = 0;
                                            }
                                        
                                            let updatedConsList = [...consumptionsList];
                                            updatedConsList[index].Quantity = defVal; 
                                            setConsumptionsList(updatedConsList);
                                        }} value={consumptionsList[index].Quantity}/>
                                        </div>
                                        <div>
                                            <IonButton onClick={() => {
                                                    let updatedConsList = [...consumptionsList];
                                                    if(updatedConsList[index].Quantity === 0){
                                                    return;
                                                    }
                                                    updatedConsList[index].Quantity = updatedConsList[index].Quantity - 1; 
                                                    setConsumptionsList(updatedConsList);
                                            }}>
                                                <IonIcon slot="icon-only" icon={remove}></IonIcon>
                                            </IonButton>
                                            <IonButton onClick={() => {
                                                                let updatedConsList = [...consumptionsList];
                                                                updatedConsList[index].Quantity = updatedConsList[index].Quantity + 1; 
                                                                setConsumptionsList(updatedConsList);
                                            }}>
                                                <IonIcon slot="icon-only" icon={add}></IonIcon>
                                            </IonButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>

 
                    </div>
                    
                </div>
            </div>
            <div>
               <UnavailabilityActionConsumeMaterialConfirmButtons onConfirm={() => {
                    //validate etc..
                    props.onConfirm({thisIsSomeTest:123,consumptionsList:consumptionsList});
               }}/>
            </div>
        </>
    )
}

export default UnavailabilityActionConsumeMaterial;