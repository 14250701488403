import SimpleContentPopup from "../../../SimpleContentPopup/SimpleContentPopup";
import styles from "./OnGoingMultiProductionsFinishSubScreen.module.scss";
import { IonButtons } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { IonIcon } from "@ionic/react";
import { useIonAlert } from "@ionic/react";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import { dispatchCustomPopupsStore } from "../../CustomPopupsStore";
import { useCustomPopupsStore } from "../../CustomPopupsStore";
import { useIonToast } from "@ionic/react";
import { useState } from "react";
import { useCallback } from "react";
import { closeOutline } from "ionicons/icons";
import { useEffect } from "react";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
import OnGoingMultiProductionsFinishSubscreenConfirmationButtons from "./OnGoingMultiProductionsFinishSubscreenConfirmationButtons";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import LoadingSpinnerImg from "../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import productionController from "../../../../../../barrel/controllers/productionController";
import { useHistory, withRouter } from "react-router";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
function OnGoingMultiProductionsFinishSubScreen(props: any) {
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const popupsStore = useCustomPopupsStore();
  const previousPopupsStore = usePrevious(popupsStore);
  const myLocalStorage = useLocalStorageHandler();
  const [selectedProduction, setSelectedProduction] = useState(-1);
  const {t} = useHxfTranslation();
  const [selectedProductionRecordId, setSelectedProductionRecordId] =
    useState(-1);
  const [enabledCancelAllButton, setEnabledCancelAllButton] = useState(true);

  const allowSelection = false; //future might be handly to set to true if needed
  const isLoading = props.isLoading;
  const history = useHistory();

  const onResetInternalState = useCallback(() => {
    setSelectedProduction(-1);
  }, [setSelectedProduction]);

  const getProductionRecordElement = (rowPr: any, indexPr: number) => {
    let isCanceled = rowPr.canceled === 1;

    let isFinished = !isCanceled && rowPr.dateStart !== rowPr.dateEnd;

    let isRunning = !isCanceled && !isFinished;
    return (
      <IonCardHeader
        className={`${styles.elemElement} ${
          rowPr.Id === selectedProductionRecordId
            ? styles.selectedActiveProductionElement
            : ""
        }`}
        onClick={() => {
        
          const behaviour = "REDIRECT_TO_PR";

          if(isFinished || isCanceled){
            return;
          }
          if(behaviour === "REDIRECT_TO_PR"){
            props.onClickClose({redirect:"/feature/production-order/" + rowPr.Id_ProductionOrder + "/po-product/" + rowPr.Id_ProductionOrder_Product + "/production/" + rowPr.Id});
          }else{
            if (!allowSelection) {
              return;
            }
            if (rowPr.Id === selectedProductionRecordId) {
              setSelectedProductionRecordId(-1);
            } else {
              setSelectedProductionRecordId(rowPr.Id);
            }
          }

        }}
      >
        <div className={styles.elemBackgroundContainer}>
          <LocalShippingIcon />
        </div>
        <IonCardSubtitle>
          <div className={styles.elemElementTitle}>
            <b>{t('product')}:</b>  {rowPr.productData.name}
          </div>
          {rowPr?.selectedMachines && rowPr?.selectedMachines.length > 0 && (
          <div className={styles.elemElementTitle}>
            <b>{t("machine")}:</b> {rowPr?.selectedMachines[0].machineName}
          </div>
          )}

          <div className={styles.elemElementTitle}>
            <b>{t("status")}:</b>
            {isCanceled && (
              <span className={styles.recordCanceled}> {t("canceled")}</span>
            )}
            {isFinished && (
              <span className={styles.recordFinished}> {t("finished")}</span>
            )}
            {isRunning && (
              <span className={styles.recordRunning}> {t("on.going")}...</span>
            )}
          </div>
        </IonCardSubtitle>
        <IonCardTitle className={styles.elemElementDescription}>
          {/*DatesConversionService().formattedDate(row.dateStart)*/}
        </IonCardTitle>
      </IonCardHeader>
    );
  };

  const getProductionOrderElement = (row: any, index: number) => {
    return (
      <div>
        <div>
          <div>
            <div className={styles.productionOrderSubtitle}>
              ({row.productionOrderCode})
            </div>
            <div className={styles.productionOrderSubtitle}>
              {row.productionOrderName}
            </div>
          </div>
        </div>
        <div className={styles.activeProductionsContainer}>
          <div className={styles.activeProductionsSection}>
            {row.productionRecords.map((rowPr: any, indexPr: number) => (
              <div
                key={"multiprs_po_" + row.Id_ProductionOrder + "_" + indexPr}
                className={`${styles.activeProductionElement}  `}
              >
                {getProductionRecordElement(rowPr, indexPr)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (!props?.Id_Multi_Production) {
    return <></>;
  }

  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t('simultaneous.operations')}</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                let redirectTo = null;
                if (
                  popupsStore.onGoingProductionsPopupSettings?.onCloseGoToPath
                ) {
                  redirectTo =
                    popupsStore.onGoingProductionsPopupSettings
                      ?.onCloseGoToPath;
                }

                setSelectedProduction(-1);
                dispatchCustomPopupsStore({
                  type: "CLOSE_ON_GOING_MULTI_PRODUCTIONS_POPUP",
                });

                if (redirectTo !== null) {
                  props.history.push({
                    pathname: "/page-redirector",
                    state: { redirectTo: redirectTo },
                  });
                }

                if(props?.onClickClose){
                  props.onClickClose();
                }
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>

          {isLoading || props?.multiProductionData?.length === 0 ? (
            <div className={styles.content}>
              <LoadingSpinnerImg />
            </div>
          ) : (
            <>
              <div className={styles.selectedOperationInfo}>
                <div>{t('operation')}: {props.selectedOperationCode}</div>
              </div>
              <div className={styles.content}>
                <div>
                  {t('about.to.finish.operations.bellow')}:
                </div>
                {props.multiProductionData.map((row: any, index: number) => (
                  <div key={"multiProductionDt_po_" + index}>
                    <div>{getProductionOrderElement(row, index)}</div>
                  </div>
                ))}
                  <div className={styles.bottomSpacing}></div>
              </div>
            
            </>
          )}
        </div>

        {!isLoading && (
          <OnGoingMultiProductionsFinishSubscreenConfirmationButtons
            enabled={true}
            onClickConfirm={() => {
              let productionsRoutesArray = [];
              for (let i = 0; i < props.multiProductionData.length; i++) {
                let idProductionOrder =
                  props.multiProductionData[i].Id_ProductionOrder;
                let productionRecords =
                  props.multiProductionData[i].productionRecords;
                for (let j = 0; j < productionRecords.length; j++) {
                  let idPr = productionRecords[j].Id;
                  let idProductionOrderProduct =
                    productionRecords[j].Id_ProductionOrder_Product;
                  if (
                    productionRecords[j].canceled === 0 &&
                    productionRecords[j].dateStart ===
                      productionRecords[j].dateEnd
                  ) {
                    productionsRoutesArray.push(
                      "/feature/production-order/" +
                        idProductionOrder +
                        "/po-product/" +
                        idProductionOrderProduct +
                        "/production/" +
                        idPr
                    );
                  }
                }
              }

              if (productionsRoutesArray.length === 0) {
                throw new Error(
                  "unexpected error, this multi production should have been closed, all production records are closed or canceled"
                );
              }
              let passingStateObj = {
                multiProductionsRouting: {
                  currentIndex: 0,
                  routesArray: productionsRoutesArray,
                },
              };

              props.history.push({
                pathname: productionsRoutesArray[0],
                state: passingStateObj,
              });
              dispatchCustomPopupsStore({
                type: "CLOSE_ON_GOING_MULTI_PRODUCTIONS_POPUP",
              });
            }}
          />
        )}
    
      </div>
    </SimpleContentPopup>
  );
}

export default withRouter(OnGoingMultiProductionsFinishSubScreen);
