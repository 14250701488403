import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import useProductSerialization from "../../../../../../barrel/hooks/useProductSerialization";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenAskPrintLabels.module.scss";
import ActionScreenAskPrintLabelsConfirmationButtons from "./ActionScreenAskPrintLabelsConfirmationButtons";
interface IResult {
  irregularitysList: any;
  labelsData?:any;
}

function ActionScreenAskPrintLabels(props: any) {
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const {t} = useHxfTranslation();
  const currentActionScreenManager = useActionScreenManagerStore();

  const productSerialization = useProductSerialization();

  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  const [addingLabelsCount, setAddingLabelsCount] = useState(1);

  
  // states to allow clearing when loading a default stock location config

  const closeCallback = () => {
    props.callbackOnClose(
      currentActionScreenManager?.currentActionConfig?.labelsList
    );
  };


  useEffect(() => {
    if (!didMount) {
      //mounting

      let totalProductSerialsWouldRegister = productSerialization.getTotalSerialsWouldRegister();
      let qtyDefaultLabels = totalProductSerialsWouldRegister <= 1 ? 1 : totalProductSerialsWouldRegister;
      setAddingLabelsCount(qtyDefaultLabels);
      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount
  ]);

  if (isLoading) {
    return <></>;
  }


  return (
    <>
    <div>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={closeCallback}
          title="Label Printing"
        />

        <div className={styles.content}>
          <div className={styles.popupInfoQuestion}>
            {currentActionScreenManager?.currentActionConfig?.labelsList?.length === 1 ? (
              <Trans i18nKey="wish.print.label.specific" values={{labelname:currentActionScreenManager?.currentActionConfig?.labelsList[0].code}}>
                  Do you wish to print the label <b>&quot;{currentActionScreenManager?.currentActionConfig?.labelsList[0].code}&quot;</b>?
              </Trans>
            ) : (
              <>{t('wish.print.labels')}</>
            )}
           
          </div>

          
          {currentActionScreenManager.currentActionConfig.zplEditablePrintCount === 1 && (
            <div className={`${styles.selectionOuter}`}>
                        <div className={styles.btnModifyLabelCount} onClick={() => {
              if(addingLabelsCount === 1){
                return;
              }
              setAddingLabelsCount(addingLabelsCount - 1);
            }}>
                <IndeterminateCheckBoxIcon fontSize="inherit"/>
            </div>
            <div className={styles.countLabels}>{addingLabelsCount}</div>
            <div className={styles.btnModifyLabelCount} onClick={() => {
               setAddingLabelsCount(addingLabelsCount + 1);
            }}>
              <AddBoxIcon fontSize="inherit"/>
            </div>
            </div>
          )}
          
          <div style={{ display: "none" }}>
            <iframe
              name="output_frame_pdf"
              src={""}
              id="output_frame_pdf"
              width="100"
              height="100"
            ></iframe>
          </div>
        </div>
      </div>

      <ActionScreenAskPrintLabelsConfirmationButtons
        currentActionConfig={currentActionScreenManager?.currentActionConfig}
        countToPrint={addingLabelsCount}
        finishedActionCallback={() => {
          console.log("Should finish this action now");
          props.callbackSuccessConfirm();
        }}
      />
      </div>
      {/*<ActionScreenAskUseMachineConfirmationButtons enabled={selectedMachine.Id_Machine !== 0} typeShow={"CONFIRM"} onClickConfirm={() => {
                            

                            confirmClick();
                        }} /> */}
    </>
  );
}
export default ActionScreenAskPrintLabels;
