import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
import oauthBridgeController from "../../barrel/controllers/oauthBridgeController";
import useBarrelOauth from "../../barrel/hooks/oauth2/useBarrelOauth";
import usePrevious from "../../barrel/hooks/usePrevious";
import AppSessionService from "../../barrel/services/appSessionService";
import styles from "./index.module.scss";
/**
 * this page handles both scenarios, either Web App Oauth, or Desktop Mobile Oauth setups
 *
 * @param props
 * @returns
 */
function HaxifyOauthReceivePage(props: any) {
  const { handleSuccessLoginResponse } = AppSessionService();
  const {
    webAppExchangeAuthorizationCode,
    getState,
    getCodeVerifier,
    localAppExchangeAuthorizationCode,
    giveOauthResponseFeedback,
  } = useBarrelOauth();
  console.log(props.location.query);
  const search = window.location.search;

  const params = new URLSearchParams(search); // eslint-disable-line react-hooks/exhaustive-deps

  const authorization_code = params.get("authorization_code");
  const [didMount, setDidMount] = useState(false);

  const [showUnexpectedErrors, setShowUnexpectedErrors] = useState(false);
  const [
    showInvalidAuthorizationCodeError,
    setShowInvalidAuthorizationCodeError,
  ] = useState(false);
  const [showIntegrityError, setShowIntegrityError] = useState(false);

  const [receivedTokens, setReceivedTokens] = useState({
    authorized: false,
    access_token: "",
    refresh_token: "",
  });
  const previousReceivedTokens = usePrevious(receivedTokens);

  const handleAuthorizationResponse = (resp: any) => {
    try {
      if (resp.data.response !== undefined && resp.data.response !== null) {
        const responseObj = resp.data.response;
        console.log("Respobj ", responseObj);
        if (
          responseObj["access_token"] !== undefined &&
          responseObj["refresh_token"] !== undefined
        ) {
          console.log("success Ready");

          setReceivedTokens({
            authorized: true,
            access_token: responseObj["access_token"],
            refresh_token: responseObj["refresh_token"],
          });
          return;
        } else if (responseObj["issues"]["errors"].length > 0) {
          const errors = responseObj["issues"]["errors"];
          if (
            errors.includes("error.invalidAuthorizationCode") ||
            errors.includes("error.failedAuthorizationCodeRemove")
          ) {
            setShowInvalidAuthorizationCodeError(true);
            return;
          } else if (errors.includes("error.invalidCodeVerifier")) {
            setShowIntegrityError(true);
            return;
          }
        }
      }
      setShowUnexpectedErrors(true);
    } catch (ex) {
      setShowUnexpectedErrors(true);
    }
  };

  useEffect(() => {
    if (!didMount) {
      let hasErrors = false;
      const stateVerify = getState();
      const state = params.get("state"); //webapp oauth only

      const storedCodeVerifier = getCodeVerifier(); //localapp only

      if (
        state !== undefined &&
        authorization_code !== null &&
        state === stateVerify
      ) {
        //webapps
        console.log("MATCHED CONTINUE EXCH");

        webAppExchangeAuthorizationCode(authorization_code)
          .then((resp) => {
            handleAuthorizationResponse(resp);
          })
          .catch((resp) => {
            //this specific case, it only gets here in case of a server error. All responses are status OK on this endpoint
            setShowUnexpectedErrors(true);
          });
      } else if (
        authorization_code !== undefined &&
        authorization_code !== null &&
        storedCodeVerifier !== ""
      ) {
        //localapps
        localAppExchangeAuthorizationCode(
          authorization_code,
          storedCodeVerifier
        )
          .then((resp) => {
            handleAuthorizationResponse(resp);
          })
          .catch(() => {
            //this specific case, it only gets here in case of a server error. All responses are status OK on this endpoint
            setShowUnexpectedErrors(true);
          });
      } else {
        hasErrors = true;
        setShowUnexpectedErrors(true);
      }

      setDidMount(true);
    }
  }, [
    didMount,
    authorization_code,
    getCodeVerifier,
    getState,
    localAppExchangeAuthorizationCode,
    params,
    webAppExchangeAuthorizationCode,
  ]);

  useEffect(() => {
    if (
      receivedTokens != previousReceivedTokens &&
      previousReceivedTokens !== undefined &&
      previousReceivedTokens.authorized !== undefined &&
      previousReceivedTokens.authorized !== receivedTokens.authorized &&
      receivedTokens.authorized === true
    ) {
      console.log("RCTKS ", receivedTokens);
      oauthBridgeController()
        .loginWithAccessToken(receivedTokens.access_token)
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data.response.loggedIn) {
              handleSuccessLoginResponse(resp);
              giveOauthResponseFeedback("SUCCESS");
            }
          }
        })
        .catch((resp) => {
          console.log("ERR");
          if (resp.status === 401) {
            console.log("HERE");
            giveOauthResponseFeedback("EXPIRED");
            return;
          } else {
            giveOauthResponseFeedback("UNEXPECTED_ERROR");
            return;
          }
        });
    }
  }, [
    receivedTokens,
    giveOauthResponseFeedback,
    handleSuccessLoginResponse,
    previousReceivedTokens,
  ]);

  if (showUnexpectedErrors) {
    giveOauthResponseFeedback("UNEXPECTED_ERROR");
    return <div>Unexpected error occurred...</div>;
  }

  if (showInvalidAuthorizationCodeError) {
    giveOauthResponseFeedback("EXPIRED");
    return (
      <div className={styles.pageContainer}>
        <div className={styles.content}>
          <div className={`${styles.checkIcon} ${styles.redColor}`}>
            <CancelIcon />
          </div>

          <div>Your authorization might have expired. Try again later.</div>
        </div>
      </div>
    );
  }

  if (showIntegrityError) {
    giveOauthResponseFeedback("UNEXPECTED_ERROR");
    return (
      <div className={styles.pageContainer}>
        <div className={styles.content}>
          <div className={`${styles.checkIcon} ${styles.redColor}`}>
            <CancelIcon />
          </div>

          <div>An integrity check error has occurred, please try again.</div>
        </div>
      </div>
    );
  }

  if (receivedTokens.authorized) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.content}>
          <div className={`${styles.checkIcon} ${styles.greenColor}`}>
            <CheckCircleOutlineIcon />
          </div>

          <div>Authenticated Successfully!</div>
          <div>
            <small>You can close this window now.</small>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        <div>Authenticating...</div>
      </div>
    </div>
  );
}
export default withRouter(HaxifyOauthReceivePage);
