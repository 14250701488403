import styles from "./CustomArrowBack.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { withRouter } from "react-router";


function CustomArrowBack(props: any) {
  
  let positioning = props?.positioning ? props.positioning : "fixed"; //fixed or none

  return (
    <div
      data-btn={props?.dataBtnIdentifier}
      className={`${styles.goBackContainer} ${positioning === "fixed" ? styles.fixedPositioning : ""}`}
      onClick={() => {
        if(props?.onBeforeRedirect){
          props.onBeforeRedirect();
        }
        if (props.pushUrl) {
          let additionalRouteState = props?.additionalRouteState ? props.additionalRouteState : null;
          props.history.push({pathname:props.pushUrl,state:additionalRouteState});
        }
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <ArrowBackIcon />
    </div>
  );
}

export default withRouter(CustomArrowBack);
