import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function shippingOrderProductsController() {
  const localStorage = useLocalStorageHandler();

  const sendShippingOrderProducts = (shippingOrderProductsArray: any, extraData?:any) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "shipping-order-products/send";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        shippingOrderProducts: shippingOrderProductsArray,
        ...extraData
      }
    );
  };

  const getShippingOrderProducts = (
    options: any = { page: 0, size: 10, Id_ShippingOrder: 0 }
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl =
      "shipping-order-products/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"];

    if (options?.includeSalesOrderData && options.includeSalesOrderData) {
      requestUrl =
        requestUrl + "&includeSalesOrderData=" + options.includeSalesOrderData;
    }

    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }
    if (options?.Id_ShippingOrder && options?.Id_ShippingOrder !== 0) {
      requestUrl = requestUrl + "&Id_ShippingOrder=" + options.Id_ShippingOrder;
    }

    if(options?.includeCompleteness){
      requestUrl = requestUrl + "&includeCompleteness=" + options.includeCompleteness;
    }

    if (
      options?.getDefaultShippingLocation &&
      options?.getDefaultShippingLocation !== "true"
    ) {
      requestUrl =
        requestUrl +
        "&getDefaultShippingLocation=" +
        options.getDefaultShippingLocation;
    }

    if (options?.getAutoFillSettings) {
      requestUrl =
        requestUrl + "&getAutoFillSettings=" + options.getAutoFillSettings;
    }

    if(options?.getShippingOrderSettings){
      requestUrl = requestUrl + "&getShippingOrderSettings=" + options.getShippingOrderSettings;
    }

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  return { getShippingOrderProducts, sendShippingOrderProducts };
}

export default shippingOrderProductsController;
