import { IonButton, IonIcon, IonLoading, useIonAlert } from "@ionic/react";
import { addOutline, closeOutline, removeOutline, trashOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";

import styles from "./PopupAdjustMachineProductTransformation.module.scss";


import SimpleContentPopup from "../../../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import PopupAMPTConfimrationButtons from "./PopupAMPTConfirmationButtons.tsx/PopupAMPTConfimrationButtons";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import TransformIcon from '@mui/icons-material/Transform';
import DefineProductParameterizedVariableValuesPopup from "../../../../statePopups/defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import { isTheSameProduct } from "../UnavailabilityActionAdjustMachineToProduct";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import HxfTextfieldNumber from "../../../../HxfTextfieldNumber/HxfTextfieldNumber";
interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  title: string;
  currentData: any;
}

interface ITransformedProduct{
    Id_Product:any;
    name:any;
    code:any;
    arrayProductParameterizedVariables:any;
    Quantity?:any;
}
function PopupAdjustMachineProductTransformation(props: IPopup) {
  const [didMount, setDidMount] = useState(false);
  const [present] = useIonAlert();
  const [step, setStep] = useState<null | "DEFINE_PVVS">(null); //DEFINE_PVVS
  const {t} = useHxfTranslation();

 
  const [arrayProductsTransformedConfiguration, setArrayProductsTransformedConfiguration] = useState<ITransformedProduct[]>([]);
  const [transformedProductConfiguration, setTransformedProductConfiguration] = useState<ITransformedProduct | null>(null);

  const feedbackService = useFeedbackService();

  const initNewTransformationProduct = useCallback(() => {
       //any data to load?
       let productLoad = props?.currentData;
      
       let transformToProductObj:ITransformedProduct = {
           Id_Product:productLoad?.Id_Product,
           name:productLoad?.name,
           code:productLoad?.code,
           arrayProductParameterizedVariables:[],
           Quantity:1
       };
       
       //deal with ppvvs
       let arrayPPVVsLoaded = productLoad?.arrayProductParameterizedVariables;
       for(let i = 0; i<arrayPPVVsLoaded.length; i++){
       
            //todo values load from latest configuration to this machine
            //currently values are always emptyu
           transformToProductObj.arrayProductParameterizedVariables.push({...arrayPPVVsLoaded[i], Value:null});
       }

       setTransformedProductConfiguration(transformToProductObj);
       if(arrayPPVVsLoaded.length > 0){
            setTimeout(() => {
            setStep("DEFINE_PVVS");
           },100);
       }


  },[props.currentData]);

  useEffect(() => {
    if (!didMount) {
      if (props?.currentData) {

        if(props?.currentData?.transformations){
            setArrayProductsTransformedConfiguration(props?.currentData?.transformations);
        }
    
      }
      setDidMount(true);
    }
  }, [didMount, props.currentData,initNewTransformationProduct]);


  /*if(!transformedProductConfiguration){
    return (
        <><IonLoading
     
        isOpen={true}
        onDidDismiss={() => {
  
        }}
        message={t('please.wait') + "..."}
      /></>
    )
  }*/

  //defined transformed product PVS
  if(step === "DEFINE_PVVS"){
    return (
        <>
            <DefineProductParameterizedVariableValuesPopup
                label={t('transformed.product')}
                customLabelElement={(
                    <div className={styles.iconTransform}>
                    <TransformIcon fontSize="large"/>
                    </div>
                )}
                customProductInfoBgColor="#98c553"
                currentData={transformedProductConfiguration}
                includeFullParameterizedVariablesData={true}
                title={t('title.transformation.product')}
                onClosePopup={() => {
                    setStep(null);
                }}
                onSuccessConfirm={(res:any) => {
                    let newProductTransformation = transformedProductConfiguration;
                    if(!newProductTransformation){
                        return;
                    }
                    newProductTransformation.arrayProductParameterizedVariables = res;
                    let currentTransformationProductsArr = arrayProductsTransformedConfiguration;
                    for(let i = 0; i<currentTransformationProductsArr.length; i++){
                        if(isTheSameProduct(currentTransformationProductsArr[i], newProductTransformation)){
                            feedbackService.notifyToast(t('product.already.added.same.settings'), "error");
                            setStep(null);
                            return;
                        }
                    }
                    currentTransformationProductsArr.push(newProductTransformation);
                    setArrayProductsTransformedConfiguration(currentTransformationProductsArr);
                    setStep(null);
                }}
            />
        </>
    )
  }

  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
                <div className={styles.title}>{props.title}</div>
                <IonButton
                    color="danger"
                    className={styles.closeWindowButton}
                    onClick={() => {
                    if (props?.onClosePopup) {
                        props.onClosePopup();
                    }
                    }}
                >
                    <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
            </div>
            <div className={styles.selectedProductInfo}>
              <div className={styles.selcetedProductInfoLabels}>
                ({props.currentData?.code}) {props.currentData?.name}
              </div>
            </div>
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >

              <div className={styles.productVariablesSection}>
                <div className={styles.productVariables}>
                {props?.currentData?.arrayProductParameterizedVariables?.length > 0 && (
                    <div className={styles.appvsContainer}>
                        {props?.currentData?.arrayProductParameterizedVariables.map((objcPV:any, indexcpv:any) => (
                            <div key={"idxpv_" + indexcpv} style={{margin:5}}>
                                <b>{objcPV.name}:</b> {objcPV.Value}
                            </div>
                        ))}
                    </div>
                )}


                <div className={styles.iconTransform}>
                    <TransformIcon fontSize="large"/>
                </div>
                <div className={styles.label}>
                    {t('transformed.to')}
                </div>

                {!arrayProductsTransformedConfiguration || arrayProductsTransformedConfiguration.length === 0 && (
                    <>
                    <div className={`${styles.transformedProductContainer} hxfError`}>
                        <div style={{margin:20}}>{t('no.transformations.added')}</div>
                    </div>
                    </>
                )}
                {arrayProductsTransformedConfiguration && arrayProductsTransformedConfiguration.length > 0 && (
                    <>
                        {arrayProductsTransformedConfiguration.map((objProductTransformedConfig:any, idxPTC:any) => (
                            <div key={"idxptc_" + idxPTC}  className={styles.transformedProductContainer}>
                                <div className={styles.transformedProductInner}>
                                    <div style={{alignItems:'center'}}>
                                        <div style={{maxWidth:'250px'}}><b>{objProductTransformedConfig.name}</b></div>
                                        <div style={{display:'flex',alignItems:'center',fontSize:13}}>
                                        {objProductTransformedConfig.arrayProductParameterizedVariables.length > 0 && objProductTransformedConfig.arrayProductParameterizedVariables.map((transPVV:any, tansPVVIdx:any) => (
                                            <div style={{margin:5}} key={"ptc_" + idxPTC + "_pvv_" + tansPVVIdx}>
                                                <b>{transPVV.name}:</b> {transPVV.Value}
                                            </div>
                                        )) }

                                        </div>
                    
                                    </div>
                                    <div style={{width:70}}>
                                        <IonButton
                                            color="danger"
                                            className={styles.btnRemove}
                                            data-btn={"remove-transformation-idx-" + idxPTC}
                                            onClick={() => {
                                            
                                                present({
                                                    cssClass: "my-css",
                                                    header: t("remove.transformation"),
                                                    message: t("remove.transformation.confirm"),
                                                    buttons: [
                                                      t("cancel"),
                                                      {
                                                        text: t("yes"),
                                                        handler: (d) => {
                                                          
                                                            let newArr = [...arrayProductsTransformedConfiguration];
                                                            newArr.splice(idxPTC,1);
                                                            setArrayProductsTransformedConfiguration(newArr);
                                                        },
                                                      },
                                                    ],
                                                    onDidDismiss: (e) => {},
                                                  });

                                           
                                            }}
                                        >
                                        <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                                        </IonButton>
                                    </div>
                                </div>


                                <div className={styles.qtyManageContainer}>
                                    <IonButton
                                        data-btn={"subtract-qty-transformation-idx-" + idxPTC}
                                        className={styles.addrembtn}
                                        onClick={() => {
                                            let newArr = [...arrayProductsTransformedConfiguration];
                                            let qt = newArr[idxPTC].Quantity;
                                            if(!qt){
                                                qt = 0;
                                            }

                                            let newQt = parseInt(qt) - 1;
                                            if(newQt < 0){
                                                newQt = 0;
                                            }
                                            newArr[idxPTC].Quantity = newQt;
                                            setArrayProductsTransformedConfiguration(newArr);
                                        }}
                                    >
                                        <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                    </IonButton>
                                    <HxfTextfieldNumber
                                    dataInputFieldAttributeValue={"field-qty-transformation-idx-" + idxPTC}
                                    fullWidth={true} centerInputText={true} error={false} 
                                    label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {

                                        if(evt !== null && evt !== ""){
                                            evt = parseInt(evt); //ints only
                                        }

                                        let newArr = [...arrayProductsTransformedConfiguration];
                                        newArr[idxPTC].Quantity = evt;
                                        setArrayProductsTransformedConfiguration(newArr);
                                    }} value={objProductTransformedConfig?.Quantity}/> 

                                    <IonButton
                                        className={styles.addrembtn}
                                        data-btn={"add-qty-transformation-idx-" + idxPTC}
                                        onClick={() => {
                                            let newArr = [...arrayProductsTransformedConfiguration];
                                            let qt = newArr[idxPTC].Quantity;
                                            if(!qt){
                                                qt = 0;
                                            }

                                            let newQt = parseInt(qt) + 1;
                                            newArr[idxPTC].Quantity = newQt;
                                            setArrayProductsTransformedConfiguration(newArr);
                                        }}
                                    >
                                        <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                    </IonButton>
                                </div>

                            </div>
                        ))}
                    </>
                )}


                <div>
                    <IonButton
                        data-btn={"add-transformation"}
                        className={styles.confirmButton}
                        onClick={() => {
                            initNewTransformationProduct();
                        }}
                    >
                        + {t('add.transformation')}
                    </IonButton>
                </div>
        
                
                </div>
              </div>
            </div>
          </div>
          <PopupAMPTConfimrationButtons
          disabled={arrayProductsTransformedConfiguration.length === 0}
            onClickConfirm={() => {

                props.onSuccessConfirm(arrayProductsTransformedConfiguration);
        
            }}
          />
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default PopupAdjustMachineProductTransformation;
