import { IonButton, IonIcon } from "@ionic/react";
import { checkmarkCircleOutline, refreshCircleOutline, saveOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import styles from "./UnavailabilityActionAdjustMachineToProductConfirmationButtons.module.scss";


interface IProps{

    disabledConfirm?:any;
    disabledReset?:any;
    onConfirm?:any;
    onResetCurrentAdjustments?:any;
}
function UnavailabilityActionAdjustMachineToProductConfirmationButtons(props:IProps){
    const {t} = useHxfTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.lineBreaker}></div>
            <div className={styles.buttonsContainer}>
            <div className={styles.confirmationContainer}>
                <div>
                    
                <div>
                    <IonButton
                color="#fcdb03"
                    disabled={props.disabledReset}
                    className={styles.confirmButton}
                    onClick={() => {
                        props.onResetCurrentAdjustments();
                    }}
                    >
                         <IonIcon icon={refreshCircleOutline} size="medium" slot="start"></IonIcon>
                    {t("reset.adjustements")}
                    </IonButton>
                </div>
                </div>
                
            </div>
            <div className={styles.confirmationContainer}>
                <div>
                    
                <div>
                    <IonButton
                    data-btn="save-adjustment"
                    disabled={props.disabledConfirm}
                    className={styles.confirmButton}
                    onClick={() => {
                        props.onConfirm();
                    }}
                    >
                          <IonIcon icon={saveOutline} size="medium" slot="start"></IonIcon>
                    {t("save")}
                    </IonButton>
                </div>
                </div>
                
            </div>

            </div>
        </div>
    )
}

export default UnavailabilityActionAdjustMachineToProductConfirmationButtons;