import { Checkbox, Paper,styled, Table, TableBody,TableHead,TableCell, TableContainer, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import HxfImageInput from "../../../../HxfImageUploader/HxfImageInput";
import HxfSelectorField from "../../../../HxfSelectorField/HxfSelectorField";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenFillChecklists.module.scss";
import ActionScreenFillChecklistsConfirmationButtons from "./ActionScreenFillChecklistsConfirmationButtons";
import {
  manageActionScreenFillchecklistsStore, useActionScreenFillChecklistsStore
} from "./ActionScreenFillChecklistsStore";

interface IResult {
  irregularitysList: any;
}

const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
 const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:4
 }));
 const CustomTableCellVar = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:7
 }));
function ActionScreenFillChecklists(props: any) {
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const preConfirm = useActionPreConfirm();

  const screenState = useActionScreenFillChecklistsStore();

  const screenStateManage = manageActionScreenFillchecklistsStore();

  const currentActionScreenManager = useActionScreenManagerStore();
  const [currentStep, setCurrentStep] = useState("SELECT_IRREGULARITY"); //CONFIRM_QTY

  const [currentErrorsMap, setCurrentErrorsMap] = useState<any>({});
  const [result, setResult] = useState<IResult>({ irregularitysList: [] });

  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);
  const {t} = useHxfTranslation();

  const [currentVisibleChecklistIndex, setCurrentVisibleChecklistIndex] =
    useState(0);

  console.log("SELSTORE:", currentActionScreenManager);
  // states to allow clearing when loading a default stock location config

  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);

  function forceRerender() {
    setIndexkey(indexkey + 1);
  }

  function confirmQuantity(res: any) {
    if (isNaN(res) || res == "") {
      setShowErrorInput(true);
      forceRerender();
      return false;
    }
    let valueNumber = parseFloat(res) + 0;

    let allowZeroQuantity = true;
    if (allowZeroQuantity && valueNumber === 0) {
      let newResult = { ...result, quantity: 0 };
      setResult(newResult);
      return newResult;
    } else if (valueNumber === 0 && !allowZeroQuantity) {
      setShowErrorInput(true);
      forceRerender();
      return false;
    } else {
      //let returnObj = {quantity:valueNumber};
      let newResult = { ...result, quantity: valueNumber };
      setResult(newResult);
      return newResult;
      //props.callbackSuccessConfirm(returnObj);
    }

    return false;
  }

  const confirmClick = () => {
    //let storeResult = confirmIrregularitysList();
    //props.callbackSuccessConfirm(storeResult);
  };

  const setChecklistPvValue = (
    indexChecklist: number,
    indexPv: number,
    value: any,
    varType: string,
    extraOpts?:any
  ) => {
    let checklists =
      currentActionScreenManager?.currentActionConfig?.checklistsList;

    let idChecklist = checklists[indexChecklist]["Id_Checklist"];

    let checklistParameterizedVariables =
      checklists[indexChecklist]["parameterizedVariables"];

    let idParameterizedVariable =
      checklistParameterizedVariables[indexPv]["Id_ParameterizedVariable"];

    if (varType === "optionselect") {
      value = value.value;
    }


    screenStateManage.setChecklistField(
      idChecklist,
      idParameterizedVariable,
      value,
      varType,{...extraOpts}
    );
  };

  const doesFieldHaveError = (objPv: any, idChecklist: any) => {
    let idPv = objPv.Id_ParameterizedVariable;
    if (currentErrorsMap[idChecklist + "_" + idPv]) {
      return true;
    }
    return false;
  };

  const getChecklistPvImgMiniature = (indexChecklist: number,indexPv: number) => {
    let checklists =
    currentActionScreenManager?.currentActionConfig?.checklistsList;

    let idChecklist = checklists[indexChecklist]["Id_Checklist"];

    let checklistParameterizedVariables =
      checklists[indexChecklist]["parameterizedVariables"];

    let idParameterizedVariable =
      checklistParameterizedVariables[indexPv]["Id_ParameterizedVariable"];


    let fieldObj = screenStateManage.getChecklistFieldObj(
        idChecklist,
        idParameterizedVariable
    );
      
    return fieldObj?._miniatureImgValue ? fieldObj._miniatureImgValue : null;
  }

  const getChecklistPvValue = (
    indexChecklist: number,
    indexPv: number,
    varType: string
  ) => {
    let checklists =
      currentActionScreenManager?.currentActionConfig?.checklistsList;

    let idChecklist = checklists[indexChecklist]["Id_Checklist"];

    let checklistParameterizedVariables =
      checklists[indexChecklist]["parameterizedVariables"];

    let idParameterizedVariable =
      checklistParameterizedVariables[indexPv]["Id_ParameterizedVariable"];

    let val = screenStateManage.getChecklistFieldValue(
      idChecklist,
      idParameterizedVariable
    );
    if (val === null || val === undefined) {
      if (varType === "text") {
        return "";
      }
      if (varType === "numeric") {
        return "";
      }
      if (varType === "checkbox") {
        return false;
      }
      if (varType === "optionselect") {
        return null;
      }
      return null;
    } else {
      if (varType === "optionselect") {
        return { label: val, value: val };
      }
    }

    return val;
  };

  const getFieldVarType = (
    objPv: any,
    indexChecklist: number,
    indexPv: number,
    idChecklist: any
  ) => {
    let varName = objPv.name;

    if (objPv.varType === "text") {
      return (
        <div>
          <TextField
            disabled={false}
            error={doesFieldHaveError(objPv, idChecklist)}
            onChange={(evt) => {
              setChecklistPvValue(
                indexChecklist,
                indexPv,
                evt.target.value,
                objPv.varType
              );
            }}
            type={"text"}
            value={getChecklistPvValue(indexChecklist, indexPv, objPv.varType)}
            fullWidth={true}
            label={varName}
            variant="outlined"
          />
        </div>
      );
    }

    if (objPv.varType === "numeric") {
      return (
        <div>
          <TextField
            disabled={false}
            error={doesFieldHaveError(objPv, idChecklist)}
            onChange={(evt) => {
              setChecklistPvValue(
                indexChecklist,
                indexPv,
                evt.target.value,
                objPv.varType
              );
            }}
            type={"number"}
            value={getChecklistPvValue(indexChecklist, indexPv, objPv.varType)}
            fullWidth={true}
            label={varName}
            variant="outlined"
          />
        </div>
      );
    }

    if (objPv.varType === "checkbox") {
      return (
        <div className={styles.checkboxCustom}>
          <div className={styles.checkboxCustomInner}>
            <Checkbox
              
              color={"primary"}
              style={{
                backgroundColor:doesFieldHaveError(objPv, idChecklist) ? "#cd1b1b26" : "#ffffff00",
                transform: "scale(2)",
                marginLeft:20
              }}
              onChange={(evt) => {
                let newVal = evt.target.checked;
                //clear error
                let newCurMap = { ...currentErrorsMap };
                if (
                  newCurMap[idChecklist + "_" + objPv.Id_ParameterizedVariable]
                ) {
                  newCurMap[idChecklist + "_" + objPv.Id_ParameterizedVariable] =
                    false;
                  setCurrentErrorsMap(newCurMap);
                }

                console.log("ONCHG: ", evt.target.checked);
                setChecklistPvValue(
                  indexChecklist,
                  indexPv,
                  evt.target.checked,
                  objPv.varType
                );
              }}
              checked={getChecklistPvValue(
                indexChecklist,
                indexPv,
                objPv.varType
              )}
            />
          </div>
        </div>
      );
    }

    if (objPv.varType === "optionselect") {
      let optionsSelectableArr = objPv.varTypeOptionSelect_Opts.split(",");

      let defaultOptionsObj = [];
      for (let i = 0; i < optionsSelectableArr.length; i++) {
        defaultOptionsObj.push({
          label: optionsSelectableArr[i],
          value: optionsSelectableArr[i],
        });
      }

      return (
        <div className={styles.customChecklistComboSelectOptions}>
          <HxfSelectorField
            error={doesFieldHaveError(objPv, idChecklist)}
            uniqueId={"hxfsel_" + indexChecklist + "_" + indexPv}
            disabled={false}
            onChange={(evt, val) => {
              console.log("ONCHG: ", val);
              if (val === null) {
                val = [];
              }
              setChecklistPvValue(indexChecklist, indexPv, val, objPv.varType);

              //clear error

              let newCurMap = { ...currentErrorsMap };
              if (
                newCurMap[idChecklist + "_" + objPv.Id_ParameterizedVariable]
              ) {
                newCurMap[idChecklist + "_" + objPv.Id_ParameterizedVariable] =
                  false;
                setCurrentErrorsMap(newCurMap);
              }
            }}
            value={getChecklistPvValue(indexChecklist, indexPv, objPv.varType)}
            defaultItems={defaultOptionsObj}
          />
        </div>
      );
    }


    if (objPv.varType === "image") {
    
      let hasExpectedValueForComparison = objPv?._expectedPvvData?._miniatureValueSpaceDriveFileData?.url ? true : false;

      let expectedMiniatureUrl = objPv?._expectedPvvData?._miniatureValueSpaceDriveFileData?.url;
      let originalImgUrl = objPv?._expectedPvvData?._valueSpaceDriveFileData?.url;

      return (<>
        
        <div className={`${styles.customChecklistComboSelectOptions} ${styles.imageSelectInput}`}>
          {hasExpectedValueForComparison && (
              <HxfImageInput previewModeOnly={true} error={false}  value={originalImgUrl} miniatureImgValue={expectedMiniatureUrl} />
          )}
          <HxfImageInput error={doesFieldHaveError(objPv, idChecklist)} isCompressionModeEnabled={true} value={getChecklistPvValue(indexChecklist, indexPv, objPv.varType)} miniatureImgValue={getChecklistPvImgMiniature(indexChecklist, indexPv)} onChange={(result:any) => {
                
                 let newRes = JSON.parse(JSON.stringify(result));
                  setChecklistPvValue(indexChecklist, indexPv, newRes.base64File, objPv.varType,{
                    _miniatureImgValue:newRes?.miniature_base64File
                  });
          }} onResetValue={() => {
            setChecklistPvValue(indexChecklist, indexPv, null, objPv.varType,{_miniatureImgValue: null});
          }} generateMiniature={true}/>
        </div>
        </>
      );
    }
    return <>not implemented</>;
  };

  const loadChecklistIndex = (checklistData: any, index: number) => {
    if (currentVisibleChecklistIndex !== index) {
      return <></>;
    }

    let idChecklist = checklistData.Id_Checklist;

    return (
      <div style={{width:'100%'}}>
        <div className={styles.checklistTitle}>{checklistData.name}</div>

        <div style={{width:'100%'}}>
          <div style={{width:'100%'}}>
          <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <CustomTableHead>
                            <TableRow>
                                <CustomTableCell><b>{t('variable')}</b></CustomTableCell>
                           
                                  <CustomTableCell align="left" className={styles.biggerScreensShow}><b>{t('information')}</b></CustomTableCell>
                                
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
           
                          {checklistData?.parameterizedVariables?.map(
                                      (objPv: any, indexPv: number) => (
                                        <TableRow key={"clpv_" + indexPv}>
                                      
                                        
                                          <CustomTableCellVar align="left"> 
                                            <div className={styles.matchFixVarContainer} key={"mfv_fill_" + index}>
                                              {objPv.name}:
                                            </div>
                                  
                                               
                                          </CustomTableCellVar>
        
                                     
                                              <CustomTableCellVar>
                                                <div>
                                                 {getFieldVarType(objPv, index, indexPv, idChecklist)}
                                                </div>
                                              </CustomTableCellVar>
                                      
        
                                        
                                      </TableRow>
                                       
                                      )
                                    )}
                                                    
                              
                       
                        </TableBody>
                    </Table>
                </TableContainer>
          
          </div>
        </div>
      </div>
    );
  };

  function confirmChecklists() {
    let checklistsList =
      currentActionScreenManager?.currentActionConfig?.checklistsList;

    let currentChecklistsData = { ...screenState.checklistsData };
    let newErrorsMap: any = {};
    let hasErrors = false;
    let mapCLPVData:any = {};
 
    //add not filled checklist fields
    for (let i = 0; i < checklistsList.length; i++) {
      let idChecklist:any = checklistsList[i].Id_Checklist;
      let parameterizedVariables = checklistsList[i]?.parameterizedVariables;
      if (parameterizedVariables) {
        for (let j = 0; j < parameterizedVariables.length; j++) {
          let idParameterizedVariable:any =
            parameterizedVariables[j].Id_ParameterizedVariable;
          let paramVarType = parameterizedVariables[j].varType;
          let paramVarConfig = parameterizedVariables[j]?.config ? parameterizedVariables[j].config : {};

          if(!mapCLPVData[
            "idchk_" + idChecklist + "_idpv_" + idParameterizedVariable
          ]){
            mapCLPVData["idchk_" + idChecklist + "_idpv_" + idParameterizedVariable] = parameterizedVariables[j];
          }
         
          if (
            !currentChecklistsData[
              "idchk_" + idChecklist + "_idpv_" + idParameterizedVariable
            ]
          ) {
            
            let emptyVal = "";
            if (paramVarType === "checkbox") {
              emptyVal = "false";
            } else if (paramVarType === "numeric") {
              emptyVal = "0";
            }

            currentChecklistsData[
              "idchk_" + idChecklist + "_idpv_" + idParameterizedVariable
            ] = {
              Id_Checklist: idChecklist,
              Id_ParameterizedVariable: idParameterizedVariable,
              value: emptyVal,
              config:paramVarConfig
            };
          }
        }
      }
    }

    let currentChecklists = [];

    let checklistsDataKeys = Object.keys(currentChecklistsData);

    let mapByChecklist: any = {};
    
 
    for (let i = 0; i < checklistsDataKeys.length; i++) {
      let checklistData = currentChecklistsData[checklistsDataKeys[i]];

      let newCheckListParameterValueObj:any = {
        Id_Checklist: checklistData.Id_Checklist,
        Id_ParameterizedVariable: checklistData.Id_ParameterizedVariable,
        value: checklistData.value,
        varType: checklistData?.varType
      };

      if(checklistData?._miniatureImgValue){
        newCheckListParameterValueObj._miniatureImgValue = checklistData._miniatureImgValue;
      }
      let currentMappedChecklist: any = {};
      if (mapByChecklist["Id_Checklist_" + checklistData.Id_Checklist]) {
        currentMappedChecklist =
          mapByChecklist["Id_Checklist_" + checklistData.Id_Checklist];
      }
      let currentChecklistDefinedPvs = [];
      if (currentMappedChecklist["parameterizedVariablesValues"]) {
        currentChecklistDefinedPvs =
          mapByChecklist["Id_Checklist_" + checklistData.Id_Checklist][
            "parameterizedVariablesValues"
          ];
      }
      currentChecklistDefinedPvs.push(newCheckListParameterValueObj);
      currentMappedChecklist["parameterizedVariablesValues"] =
        currentChecklistDefinedPvs;
      mapByChecklist["Id_Checklist_" + checklistData.Id_Checklist] = {
        ...currentMappedChecklist,
      };

      let pvDataInfo = mapCLPVData[checklistsDataKeys[i]];
    
      if(pvDataInfo.varType === "checkbox" && pvDataInfo?.config?.isRequiredCheck === 1){
        if(!checklistData?.value || checklistData?.value === "false"){
          newErrorsMap[
            checklistData.Id_Checklist +
              "_" +
              checklistData.Id_ParameterizedVariable
          ] = true;
          hasErrors = true;
        }
      }
 
      if (!checklistData?.value) {
        newErrorsMap[
          checklistData.Id_Checklist +
            "_" +
            checklistData.Id_ParameterizedVariable
        ] = true;
        hasErrors = true;
      }
      
    }

    setCurrentErrorsMap(newErrorsMap);
    if (hasErrors) {
      return false;
    }

    let result: any = {};
    result["checklistsMap"] = mapByChecklist;

    return result;
  }

  function getConfirmationButtons() {
    //

    let checklistsList =
      currentActionScreenManager?.currentActionConfig?.checklistsList;

    let confirmBtnVisible = false;
    if (checklistsList.length === 1) {
      confirmBtnVisible = true;
    }

    let previousBtnVisible = false;
    if (currentVisibleChecklistIndex > 0) {
      previousBtnVisible = true;
    }

    let nextBtnVisible = false;

    if (
      checklistsList.length - 1 !== currentVisibleChecklistIndex &&
      checklistsList.length > 0
    ) {
      nextBtnVisible = true;
    }

    if (checklistsList.length - 1 === currentVisibleChecklistIndex) {
      confirmBtnVisible = true;
    }

    return (
      <div className={`${styles.checklistsConfirmationContainer}`}>
        <div className={styles.lineBreaker}></div>
        <div>
          {previousBtnVisible && (
            <ActionScreenFillChecklistsConfirmationButtons
              typeShow={"PREVIOUS"}
              onClickConfirm={() => {
                setCurrentVisibleChecklistIndex(
                  currentVisibleChecklistIndex - 1
                );
              }}
            />
          )}

          {confirmBtnVisible && (
            <ActionScreenFillChecklistsConfirmationButtons
              typeShow={"CONFIRM"}
              onClickConfirm={() => {
                if(!preConfirm.check()){
                  return;
                }
                let storeResult = confirmChecklists();
                if (storeResult !== false) {
                
                  props.callbackSuccessConfirm(storeResult);
                }
              }}
            />
          )}

          {nextBtnVisible && (
            <ActionScreenFillChecklistsConfirmationButtons
              typeShow={"NEXT"}
              onClickConfirm={() => {
                setCurrentVisibleChecklistIndex(
                  currentVisibleChecklistIndex + 1
                );
              }}
            />
          )}
        </div>
      </div>
    );

    return <>not implemented</>;
  }

  useEffect(() => {
    if (!didMount) {
      //mounting

      setIsLoading(false);
      setDidMount(true);
    }
  }, [didMount]);

  useEffect(() => {
    return () => {
      //unmounting
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('checklists')}
        />

        <div className={`${styles.contentWidthSizes} ${styles.content}`}>
          {currentActionScreenManager?.currentActionConfig?.checklistsList.map(
            (obj: any, index: number) => (
              <div key={"checklist_nr_" + index} style={{width:'100%'}}>
                {loadChecklistIndex(obj, index)}
              </div>
            )
          )}
        </div>
      </div>

      {getConfirmationButtons()}
    </>
  );
}
export default ActionScreenFillChecklists;
