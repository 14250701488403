import { IonButton, IonCardHeader, IonIcon, useIonToast } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { albumsOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import stockManagementController from "../../../../../barrel/controllers/stockManagementController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import ParameterizedVariablesMapperService from "../../../../../barrel/services/parameterizedVariablesMapperService";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import DefineProductQuantityPopup from "../../../../CustomElements/statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineStockLocationPopup from "../../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import DefineUomPopup from "../../../../CustomElements/statePopups/defineUomPopup/DefineUomPopup";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import styles from "./MoveStockDrop.module.scss";
import TokenIcon from '@mui/icons-material/Token';
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import ProductService from "../../../../../barrel/services/productService";
import HxfIonLoading from "../../../../CustomElements/HxfIonLoading/HxfIonLoading";

function MoveStockDrop(props) {
  const ALLOW_DROP_SHOPFLOOR_LOCATION = false; //flag to become dynamic in the future
  const [presentToast] = useIonToast();
  const { cancelToken, isCancel } = useCancelToken({ alias: "MoveStockDrop" });

  const [isDropping,setIsDropping] = useState(false);
  const [qrScannerEnabled, setQrScannerEnabled] = useState(false);
  const [barcodeScannerEnabled, setBarcodeScannerEnabled] = useState(false);
  const [deviceScanAutoConfirmLocation,setDeviceScanAutoConfirmLocation] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [loadedObjs, setLoadedObjs] = useState([]);
  const [dropoffLocation, setDropoffLocation] = useState(null);
  const [indexReRender, setIndexReRender] = useState(0);
  const {t} = useHxfTranslation();
  const feedbackService = useFeedbackService();
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const [isPopupDropoffLocationOpen, setIsPopupDropoffLocationOpen] =
    useState(false);

  const [
    isPopupDefineProductQuantityOpen,
    setIsPopupDefineProductQuantityOpen,
  ] = useState(false);

  const [popupQuantityProductData, setPopupQuantityProductData] =
    useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [errorLoading, setErrorLoading] = useState(false);

  const [defaultLocationSettings, setDefaultLocationSettings] = useState({
    defaultId_Warehouse_Location:null,
    defaultWarehouseLocationData:null,
    defaultId_Warehouse:null,
    defaultWarehouseData:null,
    defaultId_Lot:null,
    defaultLotData:null
  });

  const loadData = useCallback(() => {
    stockManagementController()
      .getWorkerProductsInTransit({includeMoveStockSettings:true},cancelToken)
      .then((res) => {
        let preparedObjsToRender = [];
        let transitProducts = res?.data?.response?.data;
        if(!transitProducts){
          throw "unexpected err";
        }
        if (transitProducts) {
          let isFirstLoad = loadedObjs.length === 0;
          if(isFirstLoad){
       
            let moveStockSettings = res?.data?.response?.moveStockSettings;
            if(moveStockSettings){

              if(moveStockSettings?.dropDefault?.moveStockQrcodeLocationFiller === 1){
                setQrScannerEnabled(true);
              }
              
              if(moveStockSettings?.dropDefault?.moveStockBarcode39LocationFiller === 1){
                setBarcodeScannerEnabled(true);
              }
             
              if(moveStockSettings?.dropDefault?.moveStockDeviceScanAutoConfirmLocation){
                setDeviceScanAutoConfirmLocation(true);
              }

              let dropDefault = moveStockSettings?.dropDefault;
              if(dropDefault){

                let idWhLoc = dropDefault?.Id_WarehouseLocation;
                let whLocCode = dropDefault?._moveStockDrop_warehouseLocationData?.code;
                let whLocName = dropDefault?._moveStockDrop_warehouseLocationData?.name;
                let idWh = dropDefault?.Id_Warehouse;
       
                let whCode = dropDefault?._moveStockDrop_warehouseData?.code;
                let whName = dropDefault?._moveStockDrop_warehouseData?.name;

                let idLot = dropDefault?.Id_Lot;
                let lotCode = dropDefault?._moveStockDrop_lotData?.code;
                let lotName = dropDefault?._moveStockDrop_lotData?.name;

                let defaultNoLot = dropDefault?.defaultNoLot;
                let lotNoLot_Id_Lot = null;
                let lotNoLot_code = null;
                let lotNoLot_name = null;
           
                if(defaultNoLot){
                  lotNoLot_Id_Lot = defaultNoLot.Id_Lot;
                  lotNoLot_code = defaultNoLot.code;
                  lotNoLot_name = defaultNoLot.name;
                }

                setDefaultLocationSettings({
                  defaultId_Lot:idLot,
                  defaultId_Warehouse:idWh,
                  defaultId_Warehouse_Location:idWhLoc,
                  defaultWarehouseLocationData:{
                    name:whLocName,
                    code:whLocCode
                  },
                  defaultWarehouseData:{
                    name:whName,
                    code:whCode
                  },
                  defaultLotData:{
                    code:lotCode,
                    name:lotName
                  },
                  lotNoLot_Id_Lot:lotNoLot_Id_Lot,
                  lotNoLot_name:lotNoLot_name,
                  lotNoLot_code:lotNoLot_code
                });
                
                let lotLabel = null;
                let whLabel = null;
                let whLocLabel = null;
                if(idLot){
                  lotLabel = "(" + lotCode + ") "+ lotName;
                }
                if(idWh){
                  whLabel = "(" + whCode + ") " + whName;
                }
                if(idWhLoc){
                  whLocLabel = "(" + whLocCode + ") " + whLocName;
                }
                setDropoffLocation({
                  Id_Lot:idLot,
                  Id_Warehouse:idWh,
                  Id_WarehouseLocation:idWhLoc,
                  temp_lotLabel:lotLabel,
                  temp_warehouseLabel:whLabel,
                  temp_warehouseLocationLabel:whLocLabel
                });
           
              }
            }
          }
          for (let i = 0; i < transitProducts.length; i++) {
            let product = transitProducts[i];

            product.Quantity = product.Quantity * -1;
            if (product?.productParameterizedVariablesData) {
              product.productParameterizedVariablesData =
                product.productParameterizedVariablesData;

              let parameterizedVariables = product.parameterizedVariablesData;

              //prepare Ids format to the service mapper
              for (
                let j = 0;
                j < product.productParameterizedVariablesData.length;
                j++
              ) {
                let idProductParameterizedVariable =
                  product.productParameterizedVariablesData[j]["Id"];
                delete product.productParameterizedVariablesData[j]["Id"];
                product.productParameterizedVariablesData[j][
                  "Id_Product_ParameterizedVariable"
                ] = idProductParameterizedVariable;
              }
              for (let j = 0; j < parameterizedVariables.length; j++) {
                let idParameterizedVariable = parameterizedVariables[j]["Id"];
                delete parameterizedVariables[j]["Id"];
                parameterizedVariables[j]["Id_ParameterizedVariable"] =
                  idParameterizedVariable;
              }
              product.productParameterizedVariablesData =
                ParameterizedVariablesMapperService().arrFillProductParameterizedVariables(
                  product.productParameterizedVariablesData,
                  parameterizedVariables
                );
            }

            preparedObjsToRender.push(product);

          }
        } else {
          //error
        }

        setLoadedObjs(preparedObjsToRender);
        setIsLoading(false);
      })
      .catch((res) => {
   
        if (isCancel(res)) {
          return;
        }
        feedbackService.notifyToast(t('generic.critical.error'), "danger");
        //todo handle
        setErrorLoading(true);
        setIsLoading(false);
      });
  }, [cancelToken, isCancel]);

  const getDropoffLocationElement = () => {
    if (!dropoffLocation?.Id_WarehouseLocation) {

      if(!ALLOW_DROP_SHOPFLOOR_LOCATION){
        return (
          <>{t('please.define.a.drop.off.location')}</>
        )
      }
      return (
        <>
          <b>{t('location')}:</b> {t('shopfloor')}
        </>
      );
    }

    return (
      <div>
        <div className={styles.locDiv}>
          <div>
            <b>{t('warehouselocation')}:</b>
          </div>
          <div>{dropoffLocation?.temp_warehouseLocationLabel}</div>
        </div>

        {!dropoffLocation?.temp_warehouseLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t('no.warehouse.defined')}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t('warehouse')}:</b>
              </div>
              <div>{dropoffLocation?.temp_warehouseLabel}</div>
            </div>
          </>
        )}

        {!dropoffLocation?.temp_lotLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t('no.lot.defined')}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t('lot')}:</b>
              </div>
              <div>{dropoffLocation?.temp_lotLabel}</div>
            </div>
          </>
        )}
      </div>
    );
  };

  const getProperQuantityScreen = () => {
    if (
      !popupQuantityProductData?.Id_Selected_UoM &&
      popupQuantityProductData?.flag_uomsEnabled_pickAndDropStock === 1 &&
      popupQuantityProductData?.uomConversionsData?.length > 0
    ) {
      return (
        <DefineUomPopup
          title={"Unit Of Measurement"}
          productData={popupQuantityProductData}
          onClosePopup={() => {
            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
          }}
          onSuccessConfirm={(idSelectedUom) => {
            let selectedUomData = {};

            if (idSelectedUom !== -1) {
              let uomConversionsData =
                popupQuantityProductData.uomConversionsData;
              for (let i = 0; i < uomConversionsData.length; i++) {
                if (uomConversionsData[i].Id_UoM_Target === idSelectedUom) {
                  selectedUomData = { ...uomConversionsData[i] };
                }
              }
            }

            let selUomLabel = "";
            if (idSelectedUom !== -1 && idSelectedUom) {
              selUomLabel = selectedUomData.UoM_Target_Code;
            }

            setPopupQuantityProductData({
              ...popupQuantityProductData,
              uomLabel: selUomLabel,
              Id_Selected_UoM: idSelectedUom,
              temp_selectedUom_Data: selectedUomData,
            });
          }}
        />
      );
    }

    return (
      <DefineProductQuantityPopup
        qtyInputPlaceholder={t('insert.quantity')}
        productData={popupQuantityProductData}
        title={t("shipping.quantity")}
        onClosePopup={() => {
          setIsPopupDefineProductQuantityOpen(false);
          setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
        }}
        onSuccessConfirm={(res) => {

          let quantityResult = res.quantityResult;

          if (quantityResult === 0) {
            return;
          }

          let perUnitValue = res?.perUnitValue;
          if(!perUnitValue){
            perUnitValue = 0;
          }

          setIsPopupDefineProductQuantityOpen(false);
          setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data

          let idLot = dropoffLocation?.Id_Lot;

          if (idLot && idLot !== "new") {
            idLot = parseInt(idLot);
          }

          //sends single object in the array
          let dropoffArr = [
            {
              Id_Product: res.newProductData?.Id_Product,
              Quantity: quantityResult,
              Id_WarehouseLocation: dropoffLocation?.Id_WarehouseLocation
                ? dropoffLocation.Id_WarehouseLocation
                : null,
              Id_Warehouse: dropoffLocation.Id_Warehouse
                ? dropoffLocation.Id_Warehouse
                : null,
              Id_Lot: idLot,
              productParameterizedVariablesData:
                res.newProductData?.productParameterizedVariablesData,
                perUnitValue:perUnitValue
            },
          ];

          if (idLot === "new") {
            dropoffArr[0]["newLotNameCode"] = dropoffLocation.temp_lotLabel;
          }


          //todo request with dropoffArr
          //send request, keep current location reload products list
          setIsDropping(true);
          stockManagementController().moveStockDrop(dropoffArr).then((res) => {
              setIsDropping(false);
              if(!res?.data?.response?.done){
                throw "unexpected drop";
              }
              
              presentToast({
                color: "success",
                message: t("dropped.successfully"),
                duration: 2000,
              });
              setLoadedObjs([]);
              loadData();
            }).catch((res) => {
              setIsDropping(false);
              presentToast({
                color: "danger",
                message: t("error.while.dropping"),
                duration: 2000,
              });
            });
        }}
      />
    );
  };

  const isGhostModeEnabled = () => {
    return barcodeScannerEnabled;
  }

  const openProduct = (index) => {

    let obj = loadedObjs[index];
    let productData = obj;

    let passData = {
      Id_Product: productData.Id_Product,
      perUnitValue:obj?.perUnitValue ? obj?.perUnitValue : 0,
      maxQuantityBaseUom: obj.Quantity,
      productParameterizedVariablesData:
        productData.productParameterizedVariablesData,
      name: productData.productName,
      code: productData.productCode,
      qtyValue: 0,
      uomLabel: null,
      Id_UoM_Selected: null,
      uomConversionsData: productData?.uomConversionsData,
      flag_uomsEnabled_pickAndDropStock:
        obj?.flag_uomsEnabled_pickAndDropStock
          ? obj.flag_uomsEnabled_pickAndDropStock
          : 0,
    };

    let qtyValue = obj.Quantity;
    passData.qtyValue = qtyValue;

    setPopupQuantityProductData(passData);
    if (!dropoffLocation || (!dropoffLocation?.Id_Lot && dropoffLocation?.Id_Warehouse)) {
      setIsPopupDropoffLocationOpen(true);
    } else {
      setIsPopupDefineProductQuantityOpen(true);
    }
  }

  useEffect(() => {
    if (!didMount) {
      loadData();
      setDidMount(true);
    }
  }, [didMount, loadData]);

  if (!didMount) {
    return <></>;
  }

  if(isDropping){
    return (
      <InAppTemplate>
        <HxfIonLoading isOpen={true}/>
      </InAppTemplate>
    )
  }

  return (
    <InAppTemplate>

      {isGhostModeEnabled() && (
        <DefineStockLocationPopup
          infoData={{
            productData: {
              code: popupQuantityProductData?.code,
              name: popupQuantityProductData?.name,
            },
          }}
          invisibleStockLocation={true}
          qrScannerEnabled={qrScannerEnabled}
          barcodeScannerEnabled={barcodeScannerEnabled}
          deviceScanAutoConfirmLocation={deviceScanAutoConfirmLocation}
          allowLotCreation={true}
          defaultId_Lot={defaultLocationSettings?.defaultId_Lot}
          defaultId_Warehouse={defaultLocationSettings?.defaultId_Warehouse}
          defaultId_Warehouse_Location={defaultLocationSettings?.defaultId_Warehouse_Location}
          defaultWarehouseLocationData={defaultLocationSettings?.defaultWarehouseLocationData}
          defaultWarehouseData={defaultLocationSettings?.defaultWarehouseData}
          defaultLotData={defaultLocationSettings?.defaultLotData}
          lotNoLot_Id_Lot={defaultLocationSettings?.lotNoLot_Id_Lot}
          lotNoLot_name={defaultLocationSettings?.lotNoLot_name}
          lotNoLot_code={defaultLocationSettings?.lotNoLot_code}
          subtitle={t('define.stock.drop.to')}
          currentData={dropoffLocation}
          title={t('drop.off.location')}
          onClosePopup={() => {
            setIsPopupDropoffLocationOpen(false);
          }}
          onSuccessConfirm={(result) => {
            if (!result?.Id_Lot && result?.defaultNoLotObj) {
              result.Id_Lot = result.defaultNoLotObj.Id_Lot;
              result.temp_lotLabel =
                "(" +
                result.defaultNoLotObj.code +
                ") " +
                result.defaultNoLotObj.name;
            }else if(!result?.Id_Lot && !result.Id_Warehouse){
              result.Id_Lot = null;
              result.Id_Warehouse = null;
              result.Id_WarehouseLocation = null;
            }
          
            setDropoffLocation(result);
            setDefaultLocationSettings(null);
            setIsPopupDropoffLocationOpen(false);

            if (popupQuantityProductData) {
              setIsPopupDefineProductQuantityOpen(true);
            }

            let SHOULD_AUTO_SELECT_PRODUCT_IF_IDENTIFIED = true;

            if(SHOULD_AUTO_SELECT_PRODUCT_IF_IDENTIFIED && result?.extra?.scannedData?.Id_Product){
              //todo support ppvvs

              let productId = result?.extra?.scannedData?.Id_Product;
              let productPPVVS = [];
              let productKey = ProductService().buildProductKey(productId,productPPVVS);

              if(loadedObjs){
                let foundMatches = 0;
                let foundMatchIndex = -1;
                for(let i = 0; i<loadedObjs.length; i++){
                  let idProduct = loadedObjs[i]?.Id_Product ? loadedObjs[i].Id_Product : null;
                  let loadedPPVVS = loadedObjs[i]?.arrayProductParameterizedVariables ? loadedObjs[i]?.arrayProductParameterizedVariables : [];
                  
                  let checkProductKey = ProductService().buildProductKey(idProduct,loadedPPVVS);
                  if(checkProductKey === productKey){
                    foundMatches++;
                    foundMatchIndex = i;
                  }
                  
                }
                if(foundMatches > 1){
                  feedbackService.notifyToast(t('found.multiple.product.matches'),"error");
                }else if(foundMatchIndex !== -1){
                  //todo select
                  openProduct(foundMatchIndex);
                }else{
                  feedbackService.notifyToast(t('product.not.found'),"error");
                }
              }
     

            }
   
            console.log("success", result);
          }}
        />
      )}
      {isPopupDropoffLocationOpen && (
        <DefineStockLocationPopup
          infoData={{
            productData: {
              code: popupQuantityProductData?.code,
              name: popupQuantityProductData?.name,
            },
          }}
          qrScannerEnabled={qrScannerEnabled}
          barcodeScannerEnabled={barcodeScannerEnabled}
          deviceScanAutoConfirmLocation={deviceScanAutoConfirmLocation}
          allowLotCreation={true}
          defaultId_Lot={defaultLocationSettings?.defaultId_Lot}
          defaultId_Warehouse={defaultLocationSettings?.defaultId_Warehouse}
          defaultId_Warehouse_Location={defaultLocationSettings?.defaultId_Warehouse_Location}
          defaultWarehouseLocationData={defaultLocationSettings?.defaultWarehouseLocationData}
          defaultWarehouseData={defaultLocationSettings?.defaultWarehouseData}
          defaultLotData={defaultLocationSettings?.defaultLotData}
          lotNoLot_Id_Lot={defaultLocationSettings?.lotNoLot_Id_Lot}
          lotNoLot_name={defaultLocationSettings?.lotNoLot_name}
          lotNoLot_code={defaultLocationSettings?.lotNoLot_code}
          subtitle={t('define.stock.drop.to')}
          currentData={dropoffLocation}
          title={t('drop.off.location')}
          onClosePopup={() => {
            setIsPopupDropoffLocationOpen(false);
          }}
          onSuccessConfirm={(result) => {
            if (!result?.Id_Lot && result?.defaultNoLotObj) {
              result.Id_Lot = result.defaultNoLotObj.Id_Lot;
              result.temp_lotLabel =
                "(" +
                result.defaultNoLotObj.code +
                ") " +
                result.defaultNoLotObj.name;
            }else if(!result?.Id_Lot && !result.Id_Warehouse){
              result.Id_Lot = null;
              result.Id_Warehouse = null;
              result.Id_WarehouseLocation = null;
            }
          
            setDropoffLocation(result);
            setDefaultLocationSettings(null);
            setIsPopupDropoffLocationOpen(false);

            if (popupQuantityProductData) {
              setIsPopupDefineProductQuantityOpen(true);
            }
            console.log("success", result);
          }}
        />
      )}
      {isPopupDefineProductQuantityOpen && <>{getProperQuantityScreen()}</>}

      <CustomArrowBack pushUrl="/feature/move-stock" />
      <div className={styles.pageTitle}>{t('move.stock')} - {t('drop.off')}</div>
      {errorLoading && (
        <CustomErrorNotice
          title={t("an.error.occurred")}
          description={t('failed.load.data')}
          secondDescription={t('try.again.persists')}
        />
      )}
      {!errorLoading &&
        !isLoading &&
        loadedObjs !== null &&
        loadedObjs.length === 0 && (
          <div className={styles.noItemsAvailable} data-div={"no-items-to-drop"}>
            {t("no.items.to.drop")}.
          </div>
        )}
      {loadedObjs !== null && loadedObjs.length > 0 && (
        <div className={styles.infoAndSelection}>
          <div className={styles.objLocationContainer}>
            <div className={styles.objLocation}>
              <div className={styles.objLocationContent}>
                <div className={styles.rlocTitle}>{t("drop.off.location")}</div>
                {dropoffLocation !== null ? (
                  <>
                    <div>{getDropoffLocationElement()}</div>
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              <IonButton
                data-btn={"modify-location"}
                disabled={false}
                className={styles.modifyLocationBtn}
                onClick={() => {
                  setIsPopupDropoffLocationOpen(true);
                }}
              >
                <IonIcon slot="start" size="large" icon={albumsOutline} />
                {t("location.modify")}
              </IonButton>
            </div>
          </div>
        </div>
      )}

      <div className={styles.receivingOrdersContainer}>
        {isLoading && <LoadingSpinnerImg />}
        <div
          id="objSelectionDiv"
          key={"rerender_ref_selection_" + indexReRender}
          className={styles.objsSelection}
        >
          {loadedObjs !== null ? (
            <>
              {loadedObjs.map((obj, index) => (
                <div key={"drpoff_prd_" + index} className={styles.objElement}>
                  <IonCardHeader
                    className={styles.elemElement}
                    onClick={() => {

                      if(!ALLOW_DROP_SHOPFLOOR_LOCATION && !dropoffLocation?.Id_WarehouseLocation){
                        feedbackService.notifyToast(t('please.define.a.drop.off.location'), "warning");
                        return;
                      }
                      openProduct(index);
                    }}
                    data-btn-drop-product-code={obj.productCode}
                    data-btn-drop-product-remaining-qty={obj.Quantity}
                  >
                    <div className={styles.elemBackgroundContainer}>
                      <TokenIcon />
                    </div>

                    <div className={styles.productNameCodeContainer}>

                      <div className={styles.productNameCode}>
                        <div
                          className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                        >
                          ({obj.productCode})
                        </div>
                        <div
                          className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                        >
                          {obj.productName}
                        </div>
                      </div>
                    </div>

                    {obj?.productParameterizedVariablesData?.length > 0 && (
                      <div className={styles.productNameCodeContainer}>
                        <div className={styles.orderTopLabel}>
                          <b>{t('parameterizedvariables')}</b>
                        </div>
                        <div className={styles.parameterizedVariablesContainer}>
                          {obj.productParameterizedVariablesData.map(
                            (paramObj, idxParamObj) => (
                              <span
                                key={"rop_" + index + "_ppv_" + idxParamObj}
                              >
                                <b> {paramObj.name}: </b>
                                {paramObj.Value}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    )}
                    {/*<div className={styles.dateMovementContainer}>{DatesConversionService().formattedDate(obj.dateMovement)}</div>*/}
                    
                    {obj?.perUnitValue && obj?.perUnitValue > 0 ? (
                      <div className={styles.positionValueInfo}>
                        <div className={styles.remainingQtyLabel}>
                          {t('unit.value')}
                        </div>
                        <div className={styles.unitValueContainer}>
                          <div className={styles.qtyRemValue}>{obj.perUnitValue}</div>
                          <div className={styles.qtyRemUoM}></div>
                        </div>
                      </div>
                    ): (<></>)}


                    <div className={styles.positionBottom}>
                      <div className={styles.remainingQtyLabel}>
                        {t('remaining.qty')}:
                      </div>
                      <div className={styles.qtyRemContainer}>
                        <div className={styles.qtyRemValue}>{obj.Quantity}</div>
                        <div className={styles.qtyRemUoM}></div>
                      </div>
                    </div>
                  </IonCardHeader>
                </div>
              ))}{" "}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </InAppTemplate>
  );
}

export default MoveStockDrop;
