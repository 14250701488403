import styles from "./ActionScreenAskProducedProductIrregularityConfirmationButtons.module.scss";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
  typeShow: any;
}

function ActionScreenAskProducedProductIrregularityConfirmationButtons(
  props: IProps
) {

  const {t} = useHxfTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div>
          <IonButton
            className={styles.customConfirmationButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {props.typeShow === "CONFIRM" ? t('confirm.caps') : t('no.irregularities.caps')}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default ActionScreenAskProducedProductIrregularityConfirmationButtons;
