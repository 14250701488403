import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
  useIonAlert, useIonToast
} from "@ionic/react";
import { Backdrop, Checkbox, CircularProgress, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AddRoadIcon from "@mui/icons-material/AddRoad";

import HighlightAlt from "@mui/icons-material/HighlightAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LockIcon from '@mui/icons-material/Lock';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import {
  addCircleOutline,
  addOutline,
  albumsOutline,
  bagCheckOutline,
  barcodeOutline, checkmarkCircleOutline, closeCircle, duplicate, listOutline, pencilOutline
} from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import lotsController from "../../../../../../barrel/controllers/lotsController";
import scannerBridgeController from "../../../../../../barrel/controllers/scannerBridgeController";
import shippingOrderProductsController from "../../../../../../barrel/controllers/shippingOrderProductsController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import HxfArrayUtils from "../../../../../../barrel/services/hxfArrayUtils";
import MobileWrapperCommunicationService from "../../../../../../barrel/services/mobileWrapperCommunicationService";
import parameterizedVariablesService from "../../../../../../barrel/services/parameterizedVariablesService";
import ProductService from "../../../../../../barrel/services/productService";
import ScannablesService from "../../../../../../barrel/services/ScannablesService";
import UomService from "../../../../../../barrel/services/uomService";
import { IHxfBarcodeSettings } from "../../../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import HxfSafeHtmlContentRender from "../../../../../../components/HxfSafeHtmlContentRender/HxfSafeHtmlContentRender";
import LoadingSpinnerImg from "../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import DefineInputsDataPopup from "../../../../../CustomElements/statePopups/defineInputsDataPopup/DefineInputsDataPopup";
import DefineProductQuantityPopup from "../../../../../CustomElements/statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineStockLocationPopup from "../../../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import TaskProgressBar from "../../../../../CustomElements/TaskProgressBar/TaskProgressBar";
import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
import ScanBarcodePopup from "../../../../ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import CornerButton from "../../../../Productions/Standard/ProductionProcess/CornerButton/CornerButton";
import styles from "./ShippingOrderProductsShow.module.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import shippingOrdersController from "../../../../../../barrel/controllers/shippingOrdersController";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
import HxfOverlayLoading from "../../../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import TaskProgressBarV2 from "../../../../../CustomElements/TaskProgressBarV2/TaskProgressBarV2";
import serializedProductsController from "../../../../../../barrel/controllers/serializedProductsController";

const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
 const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:5
 }));
 const CustomTableCellVar = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:7
 }));


function ShippingOrderProductsShow(props: any) {
  const DISABLE_LOAD_MORE_SHIPPING_PRODUCTS = true; //if enabling it back YOU MUST MAKE A NEW ShippingOrderProductsShow, and declare this one as "legacy" and keep using for older clients, due to the new behaviour otherwise if the product has more than the limit and needs to load more it will be bugged and things will not sync proprely
  //we must keep this behaviour for papillon
  const ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES = true; //later turn into a setting, locate ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES accross whole project
  const ALLOW_DROP_SHOPFLOOR_LOCATION = false; //flag to become dynamic in the future
  
  const ON_SCAN_EQUAL_PRODUCTS_SELECT_FIRST_AND_IF_OVERFILLED_FILL_NEXT_MATCH = true; // flag to become dynamic in the future
  const ON_SCAN_ON_QUANTITY_CONFIRM_IF_OVERSHIP_FILL_NEXT_EQUAL_PRODUCTS = true; //flag to become dynamic in the future

  const {t} = useHxfTranslation();
  const scannedCodesArr = useRef<any>([]);
  const feedbackService = useFeedbackService();
  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);
  const showProgressOnTheDefaultUom = true; //in the future we might want to make this a setting instead of a constant
  const setDefaultNoLotAsLotOnLoad = false; //in the future we might want to make this dynamic, its on false by default to force the worker to confirm it wants to use default
  const [present, dismiss] = useIonAlert();
  const shippingOrderId = props?.match?.params?.soId;
  const { sessionState } = useGlobalState();

  const SHOW_BTN_CLOSE_SHIPPINGORDER = sessionState?.userSessionData?.workerPermissions?.wsAllowCloseShippingOrder === 1; 
  const [didMount, setDidMount] = useState(false);
  const previousDidMount = usePrevious(didMount);
  const [deviceScanEnabled, setDeviceScanEnabled] = useState(false);
  const autoFillSettings = useRef<any>(null);
  const [triggerOnFinishedScanData, setTriggerOnFinishedScanData] = useState(null);
  const prevTriggerOnFinishedScanData = usePrevious(triggerOnFinishedScanData);
 
  const scannerSettings = useRef<IHxfBarcodeSettings>({
    useCamera: true,
    useExternalDevice: true,
    allowedScanFormats: [{ type: "barcode39", pattern: ""},{type:"qrcode", pattern:""}],
  });
  const sizePage = 25;
  const ALLOW_SWAP_VIEWS = true;

  const [defaultViewMode, setDefaultViewMode] = useState<"TABLE"|"MOBILE">("MOBILE");
  const [viewMode, setViewMode] = useState<"TABLE"|"MOBILE">("MOBILE");
  const loadedObjsRef = useRef<any>(null);
  const [completenessData, setCompletenessData] = useState<any>(null);
  const [enableSendAll,setEnableSendAll] = useState(false);
  const [shipRedirectHome,setOnShipRedirectHome] = useState(false);
  const [enableSetDescription, setEnableSetDescription] = useState(false);
  const [enableAutoConfirm,setEnableAutoConfirm]= useState(false);
  const [clearStockLocationOnCancel,setClearStockLocationOnCancel] = useState(false);
  const [disableBtnConfirmIfCompleted,setDisableBtnConfirmIfCompleted] = useState(false);
  const [isOrderOpen,setIsOrderOpen] = useState(false);
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);

  const [onSfs03ScanShipLimitSingleUnitBom,setOnSfs03ScanShipLimitSingleUnitBom] = useState(false);

  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [infoOrderData, setInfoOrderData] = useState<any>({});

  const [modifiedDescription, setModifiedDescription] = useState(false);

  const [
    shippingOrderProductsReceivedData,
    setShippingOrderProductsReceivedData,
  ] = useState<any>(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef<any>(0);
  const showProductStockLocations = useRef<any>(0);
  const myLocalStorage = useLocalStorageHandler();
  const shippingOrdersSearchFilters = useRef({});

  const [filtersApplied, setFiltersApplied] = useState([]);
  const previousFiltersApplied = usePrevious(filtersApplied);
  const posContainerRef = useRef(null);
  const [shippingLocation, setShippingLocation] = useState<any>(null);

  
  const [shippingProducts, setShippingProducts] = useState<any>([]); //array of {Id_ShippingOrder_Product, quantity};
  const previousShippingProducts = usePrevious(shippingProducts);
  const [isPopupShippingLocationOpen, setIsPopupShippingLocationOpen] = useState(false);

  const [isPopupDefineDescriptionOpen, setIsPopupDefineDescriptionOpen] = useState(false);
  const [allowInstantPhysicalDevices,setAllowInstantPhysicalDevices] = useState(false);
  const [presentToast] = useIonToast();

  const [loadingShipping, setLoadingShipping] = useState(false);


  const getViewModes = () => {
    let defViewMode = defaultViewMode;
    let viewModes = ["MOBILE","TABLE"];
      viewModes.sort((a, b) => {
        if (a === defViewMode) return -1;
        if (b === defViewMode) return 1;
        return 0;
    });
    return viewModes;
  }
  const resetStateShipping = () => {
    //after submitting
    setShippingProducts([]);
    setShippingLocation(null);
  }
  const loadedPosExtraData = useRef(null);
  const [
    isPopupDefineProductQuantityOpen,
    setIsPopupDefineProductQuantityOpen,
  ] = useState(false);
  const [popupQuantityProductData, setPopupQuantityProductData] =
    useState<any>(null);
  function isHidden(el: any) {
    return el.offsetParent === null;
  }

  const removeQtyShippingProduct = (idShippingProduct: any) => {
    let newShippingProducts: any = [];
    for (let i = 0; i < shippingProducts.length; i++) {
      if (shippingProducts[i].Id_ShippingOrder_Product !== idShippingProduct) {
        newShippingProducts.push(shippingProducts[i]);
      }
    }

    setShippingProducts(newShippingProducts);

    return newShippingProducts;
  };

  const getPreparedProductDataObj = (obj:any) => {
    return JSON.parse(obj?.productData);
  }

  const distributeQuantityByEqualProducts = (idProduct:any, arrayPPVVS:any, quantityToDistribute:any) => {
    if(!arrayPPVVS){
      arrayPPVVS = [];
    }

    let remainingQtyToDistribute = quantityToDistribute;

    
    let currentProductKey = ProductService().buildProductKey(idProduct, arrayPPVVS);
    let newShippingProducts: any = [...shippingProducts];
    let toAddShippingProducts = [];
    let loadedProductObjs = loadedObjsRef.current;
    let idShippingOrderProductFirstMatch = null;
    if(loadedProductObjs){
      for(let i = 0; i<loadedProductObjs.length; i++){
       
        let obj = loadedProductObjs[i];
        let idShippingOrderProduct = obj.Id;
        let loadedIdShippingOrderProduct = obj.Id;
        let productData = JSON.parse(obj.productData);
        
        let shippingOrderProductParameterizedVariablesValueData = obj?.shippingOrderProductParameterizedVariablesValueData ? JSON.parse(obj.shippingOrderProductParameterizedVariablesValueData) : [];
        let loadedProductKey = ProductService().buildProductKey(productData.Id_Product, shippingOrderProductParameterizedVariablesValueData);
        let totalQtyToShip = obj.Quantity;
        let currentSentQty =  getFinishedShippingOrderProductQuantity(idShippingOrderProduct);

        if(loadedProductKey === currentProductKey){
      
          if(!idShippingOrderProductFirstMatch){
            idShippingOrderProductFirstMatch = idShippingOrderProduct;
          }
          let missingQtyToShip = totalQtyToShip - currentSentQty;
          if(missingQtyToShip > 0){
            
            let qtyToAdd = 0;
            if(missingQtyToShip < remainingQtyToDistribute){
              qtyToAdd = missingQtyToShip;
            }else if(missingQtyToShip >= remainingQtyToDistribute){
              qtyToAdd = remainingQtyToDistribute;
   
             
            }
            if(qtyToAdd <= 0){
              break;
            }
            
            let foundAndModified = false;
            for(let j = 0; j<newShippingProducts.length; j++){
              if(newShippingProducts[j].Id_ShippingOrder_Product === loadedIdShippingOrderProduct){
                newShippingProducts[j].quantity = qtyToAdd;
                
                foundAndModified = true;
                break;
              }
            }
            if(!foundAndModified){
              toAddShippingProducts.push({
                Id_ShippingOrder_Product: loadedIdShippingOrderProduct,
                quantity: qtyToAdd,
              });
            }
            
            remainingQtyToDistribute = remainingQtyToDistribute - qtyToAdd;
            
            
          }
        }

      }

    }

    for(let i = 0; i<toAddShippingProducts.length; i++){
      newShippingProducts.push(toAddShippingProducts[i]);
    }

    if(remainingQtyToDistribute > 0){
       //add remaining qty to the first match
      if(idShippingOrderProductFirstMatch){
        let found = false;
        for(let j = 0; j<newShippingProducts.length; j++){
          if(newShippingProducts[j].Id_ShippingOrder_Product === idShippingOrderProductFirstMatch){
            newShippingProducts[j].quantity = newShippingProducts[j].quantity + remainingQtyToDistribute;
            found = true;
            break;
          }
        }
        if(!found){
          newShippingProducts.push({
            Id_ShippingOrder_Product: idShippingOrderProductFirstMatch,
            quantity: remainingQtyToDistribute
          });
        }
      }
     
    }
    setShippingProducts(newShippingProducts);

    return newShippingProducts;
  }
  const updateQtyShippingProduct = (idShippingProduct: any, newQty: any) => {
    let newShippingProducts: any = [...shippingProducts];
    for (let i = 0; i < newShippingProducts.length; i++) {
      if (
        newShippingProducts[i].Id_ShippingOrder_Product === idShippingProduct
      ) {
        newShippingProducts[i].quantity = newQty;
        setShippingProducts(newShippingProducts);
        return true;
      }
    }

    newShippingProducts.push({
      Id_ShippingOrder_Product: idShippingProduct,
      quantity: newQty,
    });
    setShippingProducts(newShippingProducts);
    return newShippingProducts;
  };


  const getShippingProductByIdShippingProduct = (idShippingProduct: any) => {
    let loadedProductObjs = loadedObjsRef.current;
    for(let i = 0; i<loadedProductObjs.length; i++){
      let obj = loadedProductObjs[i];
      let idShippingOrderProduct = obj.Id;
      if(parseInt(idShippingProduct) === parseInt(idShippingOrderProduct)){
        return obj;
      }
    }

    return null;
  };
  const getQtyShippingProductByIdShippingProduct = (idShippingProduct: any) => {
    for (let i = 0; i < shippingProducts.length; i++) {
      if (parseInt(shippingProducts[i].Id_ShippingOrder_Product) === parseInt(idShippingProduct)) {
        return shippingProducts[i].quantity;
      }
    }

    return 0;
  };

  const hasDefinedAddingQuantity = (idShippingProduct: any) => {
    for (let i = 0; i < shippingProducts.length; i++) {
      if (parseInt(shippingProducts[i].Id_ShippingOrder_Product) === parseInt(idShippingProduct)) {
        return shippingProducts[i].quantity !== null && shippingProducts[i].Id_ShippingOrder_Product !== undefined;
      }
    }

    return false;
  };

  const isGhostScannerEnabled = () => {
    return !showBarcodeScannerPopup && deviceScanEnabled && !isPopupDefineProductQuantityOpen && !isPopupDefineDescriptionOpen;
  }

  const getCurrentWorkerCode = () => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    return currentWorkerCode;
  }

  const getShippingOrderProductTotalQuantity = (quantity: any, obj: any) => {
    if (showProgressOnTheDefaultUom && obj?.Id_UoM_Selected && quantity > 0) {
      quantity = UomService().convertQuantity(
        quantity,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
    }

    return quantity;
  };
  const isEnabledCloseOrder = () => {
    
    if(!isOrderOpen || isOrderCompleted()){
      return false;
    }
    let completedPctReal:any = completenessData?.shippingOrder_completePctReal ? completenessData?.shippingOrder_completePctReal : 0;
    if(completedPctReal !== null && completedPctReal !== undefined){
      let minimumToAllow = sessionState?.userSessionData?.globalSettings?.featureExpander?.wsShippingOrderCloseBtnMinCompletenessPct;
      if(minimumToAllow !== null && minimumToAllow !== undefined){
        return parseFloat(completedPctReal) >= parseFloat(minimumToAllow);
      }
    }
    return false;
  }

  const isProductCompleted = (obj:any) => {
    let tasksDone = getFinishedShippingOrderProductQuantity(obj.Id, obj);
    let totalTasks = getShippingOrderProductTotalQuantity(obj.Quantity, obj);
    if(parseFloat(tasksDone) >= parseFloat(totalTasks)){
      return true;
    }

    return false;

  }
  const getFinishedShippingOrderProductQuantity = (
    idShippingProduct: any,
    obj: any = null
  ) => {
    if (!shippingOrderProductsReceivedData) {
      return 0;
    }

    for (let i = 0; i < shippingOrderProductsReceivedData.length; i++) {
      if (
        shippingOrderProductsReceivedData[i].Id_ShippingOrder_Product ===
        idShippingProduct
      ) {
        let shippedQuantity =
          shippingOrderProductsReceivedData[i].shippedQuantity;
        if (
          showProgressOnTheDefaultUom &&
          obj?.Id_UoM_Selected &&
          shippedQuantity > 0
        ) {
          shippedQuantity = UomService().convertQuantity(
            shippedQuantity,
            -1,
            obj.Id_UoM_Selected,
            obj.uomConversionsData
          );
        }

        return shippedQuantity;
      }
    }
    return 0;
  };
  const isShippingLocationSet = () => {
    return shippingLocation?.Id_WarehouseLocation ? true : false;
  };

  const hasAddedShippingProducts = () => {
    return shippingProducts.length > 0;
  };

  const initiateFilterCode = (val: any) => {
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    timerStartSearching.current = setTimeout(
      () => {
        shippingOrdersSearchFilters.current = {
          ...shippingOrdersSearchFilters.current,
          searchByCode: val,
        };
        restartSearch();
      },
      500,
      val
    );
  };

  const onFilterChipRemove = (index: any) => {
    let newFiltersApplied: any = [];
    for (let i = 0; i < filtersApplied.length; i++) {
      if (i !== index) {
        newFiltersApplied.push(filtersApplied[i]);
      }
    }
    setFiltersApplied(newFiltersApplied); //will trigger useeffect
  };

  const getProperFilterChip = (obj: any, index: any) => {
    //custom styling of chip
    if (obj.filterKey === "Id_BuyOrder") {
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{t("buy.order")}:</b> {obj.orderCode}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    }

    let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
    let chipVal = obj[obj.filterKey];
    //default
    return (
      <CustomAppliedFilterChip
        filterTextElement={
          <>
            <b>{labelDefault}:</b> {chipVal}
          </>
        }
        onRemoveFilter={() => {
          onFilterChipRemove(index);
        }}
      />
    );
  };

  const getTaskUomLabel = (obj: any) => {
    if (obj?.temp_UoM_SelectedLabel) {
      return obj.temp_UoM_SelectedLabel;
    } else if (obj?.temp_UoM_BaseLabel) {
      return obj.temp_UoM_BaseLabel;
    }
    return "";
  };
  const getFormattedFiltersForRequest = (filtersArray: any) => {
    let filtersArr = [...filtersArray];
    let formattedFilters: any = {};
    for (let i = 0; i < filtersArr.length; i++) {
      let filterKey = filtersArr[i].filterKey;
      let filterValue = filtersArr[i][filterKey];
      formattedFilters[filterKey] = filterValue;
    }

    return formattedFilters;
  };

  const getAddingQtyElem = (idShippingProduct: any, obj: any = null) => {
    let qty = getQtyShippingProductByIdShippingProduct(idShippingProduct);
   
    if (qty <= 0) {
      return <></>;
    }

    let uomLabel = "";

    if (showProgressOnTheDefaultUom && obj?.Id_UoM_Selected && qty > 0) {
      qty = UomService().convertQuantity(
        qty,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
      uomLabel = obj?.temp_UoM_SelectedLabel;
    }

    return (
      <div className={`${viewMode === "TABLE" ? styles.addingQtyTable : ""} ${viewMode === "MOBILE" ? styles.addingQtyMobile : ""}`}>
        <div>
          +{qty} {uomLabel}
        </div>
      </div>
    );
  };

  const getLabelLocationStock = (stockData:any) => {
    let warehousesLabel = "";
    let countAddedWhs = 0;
    if(stockData && stockData.length > 0){
      
      
      for(let i = 0; i<stockData.length; i++){
        let warehouseCode = stockData[i]?.warehouseCode;
        if(warehouseCode && !stockData[i]?._hideWarehouseFromInfo){
          if(countAddedWhs > 0){
            warehousesLabel += ",";
          }
          warehousesLabel += "" + warehouseCode;
          countAddedWhs++;
        }
      }
    }

    return{warehousesLabel:warehousesLabel,countAddedWhs:countAddedWhs};
  }
  const getElemStockAvailable = (stockData:any) => {
    if(!showProductStockLocations.current){
      return;
    }

    if(stockData && stockData.length > 0){
     
      let labelLocStock = getLabelLocationStock(stockData);

      if(labelLocStock.countAddedWhs === 0){
        return;
      }
      return (
        <div className={styles.orderNameCodeContainer}>
          <div className={styles.orderTopLabel}>
            <b>{t('stock.location')}</b>
          </div>
          <div
            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis} ${styles.descContainer}`}
          >
            {labelLocStock.warehousesLabel}
          </div>
      </div>
      )
    }

    return (<></>)
  }

  const attemptOpenProduct = (obj:any) => {
    if(!ALLOW_DROP_SHOPFLOOR_LOCATION && !shippingLocation?.Id_WarehouseLocation){
      feedbackService.notifyToast(t('define.stock.taking.location'), "warning");
      return;
    }

    selectProduct(obj);
  }
  const selectProduct = (obj:any, options:any = {}) => {
    let productData = JSON.parse(obj.productData);
    let passData: any = {
      Id_ShippingOrder_Product: obj.Id,
      Id_Product: productData.Id_Product,
      name: productData.name,
      code: productData.code,
      qtyValue: 0,
      uomLabel: null,
      Id_UoM_Selected: null,
      uomConversionsData: obj?.uomConversionsData,
    };

    let qtyValue = 0;

    if(options?.onQuantityConfirmOverShipFillNextEqualProducts){
      passData.onQuantityConfirmOverShipFillNextEqualProducts = true;
    }

  
    if(options?.onQuantityConfirmFillDefaultByEqualProductsTotalMissingQty){
      //qty value to fill is total sum of adding from all matches same product
      let sumTotalAddingQty = 0;
      let sumTotalQtyMissing = 0;
      let shippingOrderProductObj = getShippingProductByIdShippingProduct(obj.Id);
      if(!shippingOrderProductObj){
        throw "unexpected shopobj not found";
      }
      let shippingProductArrayPPVVS = shippingOrderProductObj?.shippingOrderProductParameterizedVariablesValueData ? shippingOrderProductObj.shippingOrderProductParameterizedVariablesValueData : [];
      let shippingOrderProductKey = ProductService().buildProductKey(shippingOrderProductObj.Id_Product, shippingProductArrayPPVVS);

      let loadedProductObjs = loadedObjsRef.current;
      if(loadedProductObjs){
        for(let i = 0; i<loadedProductObjs.length; i++){
         
          let obj = loadedProductObjs[i];
          let totalQtyToShip = obj.Quantity;
          let loadedIdShippingOrderProduct = obj.Id;
          let productData = JSON.parse(obj.productData);
          let shippingOrderProductParameterizedVariablesValueData = obj?.shippingOrderProductParameterizedVariablesValueData ? obj.shippingOrderProductParameterizedVariablesValueData : [];
          let loadedProductKey = ProductService().buildProductKey(productData.Id_Product, shippingOrderProductParameterizedVariablesValueData);

          let currentSentQty =  getFinishedShippingOrderProductQuantity(loadedIdShippingOrderProduct);
          
          let missingQtyToShip = totalQtyToShip - currentSentQty;
          if(loadedProductKey === shippingOrderProductKey){
         
            if(missingQtyToShip > 0){
              sumTotalQtyMissing += missingQtyToShip;
            }
            //get total adding qty
            let currentAddingQty = getQtyShippingProductByIdShippingProduct(loadedIdShippingOrderProduct);
            sumTotalAddingQty += currentAddingQty;
          }
        }

      }
     
      //qtyValue = sumTotalAddingQty;
      qtyValue = sumTotalQtyMissing;
    }else{
    
      if(obj.fillDefaultQuantityWithMissingTotal && !hasDefinedAddingQuantity(obj.Id)){
        let currentShippingOrderObj = getShippingProductByIdShippingProduct(obj.Id);
        if(currentShippingOrderObj){
          let totalQtyToShip = currentShippingOrderObj.Quantity;
          let currentSentQty =  getFinishedShippingOrderProductQuantity(obj.Id);
          let missingQtyToShip = totalQtyToShip - currentSentQty;
          if(missingQtyToShip > 0){
            qtyValue = missingQtyToShip;
          }
          
        }
      }else{
        //default behaviour
        qtyValue = getQtyShippingProductByIdShippingProduct(obj.Id);
      }

    }

    
    passData.qtyValue = qtyValue;
    if (obj?.Id_UoM_Selected) {
      //has default uom selected
      passData.uomLabel = obj?.temp_UoM_SelectedLabel;
      passData.Id_UoM_Selected = obj?.Id_UoM_Selected;
      //convert back to base
      passData.qtyValue = UomService().convertQuantity(
        qtyValue,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
    } else {
      //no uom was selected on the shipping, so we use the label of the base uom
      passData.uomLabel = obj.temp_UoM_BaseLabel;
    }

    setPopupQuantityProductData(passData);
    setIsPopupDefineProductQuantityOpen(true);
  }

  const getObjPPVVS = (obj:any) =>  {
    obj = JSON.parse(JSON.stringify(obj)); //Avoid inmemory direct mod issues
    let productParams = [];
 
    if (obj.shippingOrderProductParameterizedVariablesValueData) {
      obj.shippingOrderProductParameterizedVariablesValueData = JSON.parse(
        obj.shippingOrderProductParameterizedVariablesValueData
      );
      obj.parameterizedVariablesData = JSON.parse(
        obj.parameterizedVariablesData
      );
      obj.productParameterizedVariablesData = JSON.parse(
        obj.productParameterizedVariablesData
      );
      for (
        let i = 0;
        i < obj.shippingOrderProductParameterizedVariablesValueData.length;
        i++
      ) {
        let varValue =
          obj.shippingOrderProductParameterizedVariablesValueData[i].Value;
        if (!varValue) {
          varValue = "";
        }
        let idProductParameterizedVariable =
          obj.shippingOrderProductParameterizedVariablesValueData[i]
            .Id_Product_ParameterizedVariable;

        for (let j = 0; j < obj.productParameterizedVariablesData.length; j++) {
          if (
            obj.productParameterizedVariablesData[j]
              .Id_Product_ParameterizedVariable ===
            idProductParameterizedVariable
          ) {
            let idParameterizedVariable =
              obj.productParameterizedVariablesData[j].Id_ParameterizedVariable;

            for (let k = 0; k < obj.parameterizedVariablesData.length; k++) {
              if (
                obj.parameterizedVariablesData[k].Id_ParameterizedVariable ==
                idParameterizedVariable
              ) {
                productParams.push({
                  paramCode: obj.parameterizedVariablesData[k].code,
                  paramName: obj.parameterizedVariablesData[k].name,
                  paramVal: varValue,
                });
                break;
              }
            }
            break;
          }
        }
      }
    }

    return productParams;
  }

  const getObjProductCode = (obj:any) => {
    let productData = JSON.parse(obj.productData);
    return productData?.code;
  }
  const getShippingOrderProductElement = (obj: any, index: any) => {
    let productData = JSON.parse(obj.productData);

    obj = { ...obj };
    let productParams = getObjPPVVS(obj);

    return (
      <IonCardHeader
        className={styles.elemElement}
        onClick={() => {
          attemptOpenProduct(obj);
        }}
      >
        <div className={styles.elemBackgroundContainer}>
          <HighlightAlt />
        </div>
        <div className={styles.orderNameCodeContainer}>
          <div className={styles.orderTopLabel}>
            <b>{t('product')}</b>
          </div>
          <div className={styles.orderNameCode}>
            <div
              className={` ${styles.orderLabel} ${styles.doubleLineOverFlowElipsis}`}
            >
              ({productData.code}) {productData.name}
            </div>
          </div>

        </div>

        {getElemStockAvailable(obj?._stockAvailableLocations)}

        {productParams.length > 0 && (
          <div className={styles.orderNameCodeContainer}>
            <div className={styles.orderTopLabel}>
              <b>{t('parameterizedvariables')}</b>
            </div>
            <div>
              {productParams.map((paramObj, idxParamObj) => (
                <span key={"rop_" + index + "_ppv_" + idxParamObj}>
                  <b> {paramObj.paramName}: </b>
                  {paramObj.paramVal}
                </span>
              ))}
            </div>
          </div>
        )}

        {obj?.description && (
          <div className={styles.orderNameCodeContainer}>
            <div className={styles.orderTopLabel}>
              <b>{t('shippingorder.product.desc.short')}</b>
            </div>
            <div className={styles.orderNameCode}>
              <div
                className={` ${styles.orderLabel} ${styles.descContainer} ${styles.singleLineOverFlowElipsis} ${styles.removeParagraphsMargins}`}
              >
                
                <HxfSafeHtmlContentRender>
                  {obj.description}
                </HxfSafeHtmlContentRender>
              </div>
            </div>

          </div>
        )}


        <div className={styles.progressBarContainer}>
          <TaskProgressBar
            width={"325px"}
            tasksDone={getFinishedShippingOrderProductQuantity(obj.Id, obj)}
            totalTasks={getShippingOrderProductTotalQuantity(obj.Quantity, obj)}
            totalTasksNumberLabel={getTaskUomLabel(obj)}
          />
        </div>
        {getAddingQtyElem(obj.Id, obj)}
      </IonCardHeader>
    );
  };

  const isMatchingAll = () => {
    if (loadedObjsRef?.current === null) {
      return false;
    }

    for (let i = 0; i < loadedObjsRef.current.length; i++) {
      let matched = false;
      for (let j = 0; j < shippingProducts.length; j++) {
        if (
          loadedObjsRef.current[i].Id ===
          shippingProducts[j].Id_ShippingOrder_Product
        ) {
          let totalQty = loadedObjsRef.current[i].Quantity;
          if (shippingProducts[j].quantity >= totalQty) {
            matched = true;
            break;
          }
        }
      }

      if (!matched) {
        return false;
      }
    }

    return true;
  };
  const getShippingLocationElement = () => {


    if (!shippingLocation?.Id_WarehouseLocation) {
      if(!ALLOW_DROP_SHOPFLOOR_LOCATION){
        return (
          <>{t('please.define.a.drop.off.location')}</>
        )
      }
      return (
        <>
          <b>{t('location')}:</b> Shopfloor
        </>
      );
    }

    return (
      <div>
        <div className={styles.locDiv}>
          <div>
            <b>{t('warehouselocation')}:</b>
          </div>
          <div>{shippingLocation?.temp_warehouseLocationLabel}</div>
        </div>

        {!shippingLocation?.temp_warehouseLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t('no.warehouse.defined')}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t('warehouse')}:</b>
              </div>
              <div>{shippingLocation?.temp_warehouseLabel}</div>
            </div>
          </>
        )}

        {!shippingLocation?.temp_lotLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t('no.lot.defined')}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t('lot')}:</b>
              </div>
              <div>{shippingLocation?.temp_lotLabel}</div>
            </div>
          </>
        )}
      </div>
    );
  };
  const getInfoOrderSalesOrder = () => {
    let salesOrderData: any = infoOrderData?.salesOrderData;
    salesOrderData = JSON.parse(salesOrderData);

    if (!salesOrderData?.Id_BuyOrder) {
      return <></>;
    }

    return (
      <div>
        <div>
          <b>{t("buy.order")}:</b>
        </div>
        <div>
          ({salesOrderData.code}) {salesOrderData.name}
        </div>
      </div>
    );
  };

  const callGoBack = () => {
    let salesOrderData = infoOrderData?.salesOrderData;
    if(salesOrderData){
      salesOrderData = JSON.parse(salesOrderData);
      if (salesOrderData?.Id_SalesOrder) {
        props.history.push({
          pathname: "/feature/shippings/shipping-orders",
          state: {
            applyFilters: [
              {
                filterKey: "Id_SalesOrder",
                Id_SalesOrder: salesOrderData?.Id_SalesOrder,
                orderCode: salesOrderData.code,
                orderName: salesOrderData.name,
              },
            ],
          },
        });

        return;
      }
    }
  
    props.history.push("/feature/shippings/shipping-orders");
    
  };

  const refetchData = () => {
    resetStateShipping();
    loadMore(filtersApplied);
  }

  const loadMore = useCallback(
    (passedFilters = null) => {
      let currentPage = DISABLE_LOAD_MORE_SHIPPING_PRODUCTS ? 0 : allowedInfiniteScrollLoadPageRef.current;

      let extraFiltersApply = {};
      if (passedFilters) {
        extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
      } else {
        extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
      }


      let opts:any = {
          page: currentPage,
          //size: sizePage,
          size:1000,
          getDefaultShippingLocation: true,
          getAutoFillSettings: true,
          getShippingOrderSettings:true,
          //openOnly: true,
          Id_ShippingOrder: shippingOrderId,
          ...shippingOrdersSearchFilters.current,
          ...extraFiltersApply,
        };

      if(SHOW_BTN_CLOSE_SHIPPINGORDER){
        opts.includeCompleteness = true;
      }
      setLoadingShipping(true);
      shippingOrderProductsController()
        .getShippingOrderProducts(opts)
        .then((resp) => {
          setLoadingShipping(false);
          let posData = resp.data.response.data.return;

          let wsShippingProductsOrderByStockLoc = resp?.data?.response?.data?.extra?.wsShippingProductsOrderByStockLoc ? resp?.data?.response?.data?.extra?.wsShippingProductsOrderByStockLoc : null;
        
          if(wsShippingProductsOrderByStockLoc){
              //order shipping order products data by their first stock location warehouse code
         
              //first create a key with the first stock location warehouse code
              for(let i = 0; i<posData.length; i++){
                  let stockLocs = posData[i]._stockAvailableLocations;

                  let foundValidKey = false;
                  let validWarehouseCode = null;
                  let currentIdx = 0;
                  while(!foundValidKey && currentIdx < stockLocs.length){
                    let stockLoc = stockLocs?.[currentIdx]?.warehouseCode;
                    if(!stockLocs?.[currentIdx]?._hideWarehouseFromInfo){
                 
                      let newKey = stockLoc ? stockLoc : null;
                      validWarehouseCode = newKey;
                      foundValidKey = true;
                    }

                    currentIdx++;
                  }
                  //if it doesnt find any, it will set it as null
                  posData[i]._firstStockLocationWarehouseCode = validWarehouseCode;
              }

              posData = HxfArrayUtils().sortByKey(posData, "_firstStockLocationWarehouseCode", "ASC");
          }  
          
          let posTotalSize = resp.data.response.data.totalSize;
          let posExtraData = resp.data.response.data.extra;
          loadedPosExtraData.current = posExtraData;
          let includeProductStockLocations = resp.data.response.data.extra?.includeProductStockLocations;
          showProductStockLocations.current = includeProductStockLocations;

          if (currentPage === 0) {
            //first load only
            //loads the extraData that loads in the backend only when the page = 0
            //this is done to avoid double requests just to gather extra unrelated data

            if(posExtraData?.completeness){
              setCompletenessData(posExtraData?.completeness);
            }
            if(posExtraData?.shippingOrders_sendAllButton){
              setEnableSendAll(true);
            }
            if(posExtraData?.shippingOrders_onShipRedirectHome){
              setOnShipRedirectHome(true);
            }

            if(posExtraData?.shippingOrders_SFS03ConsLimitSingleUnitBom === 1){
              setOnSfs03ScanShipLimitSingleUnitBom(true);
            }

            if(posExtraData?.shippingOrders_setDescButton){
              setEnableSetDescription(true);
            }

            if(posExtraData?.wsShippingOrderProduct_autoConfirm){
              setEnableAutoConfirm(true);
            }

            if(posExtraData?.wsShippingOrderProduct_clearStockLocOnCancel){
              setClearStockLocationOnCancel(true);
            }
            
            if(posExtraData?.wsShippingOrderProduct_defaultView){
              let defaultView = posExtraData?.wsShippingOrderProduct_defaultView;
              if(defaultView === "MOBILE" || defaultView === "TABLE"){
                setViewMode(defaultView);
                setDefaultViewMode(defaultView);
              }

            }
            if(posExtraData?.shippingOrder?.open === 1){
              setIsOrderOpen(true);
            }

            if(posExtraData?.shippingOrder?.autoCloseOnCompletion){
              setDisableBtnConfirmIfCompleted(true);
            }
        
            if(posExtraData?.autoFillSettings?.allowPhysScannersScannerStart){
              setAllowInstantPhysicalDevices(true);
            }
            autoFillSettings.current = posExtraData?.autoFillSettings;
            let shippingOrdersBarcode39 = posExtraData?.autoFillSettings?.shippingOrders_barcode39AutoFill;
            let shippingOrdersQRCode = posExtraData?.autoFillSettings?.shippingOrders_QRAutoFill;

            let shippingOrders_scannerSFS03 = posExtraData?.autoFillSettings?.shippingOrders_scannerSFS03;
            
            let arrayAllowedFormats:any = [];
          

            if(shippingOrders_scannerSFS03){
              arrayAllowedFormats.push(
                { type: "barcode39", pattern: "SFS03" + ScannablesService().hxfBarcode39Delimiter + "[serializedproduct.id]" },
                { type: "qrcode", pattern: "SFS03" + ScannablesService().hxfQrcodeDelimiter + "[serializedproduct.id]" }
              );
            }

            let formatScanBarcode = posExtraData?.autoFillSettings?.shippingOrders_barcode39FormatAutoFill;
            let formatScanQr = posExtraData?.autoFillSettings?.shippingOrders_QRFormatAutoFill;
            if (
              shippingOrdersBarcode39
            ) {
            
              arrayAllowedFormats.push(
                { type: "barcode39", pattern: formatScanBarcode }//{ type: "qrcode", pattern: formatScan }
              );
              if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
                //we need to allow it to scan the same format as QR

                //_skipMatchFindDelimiterProcessing to ignore it when checking for the right delimiter
                arrayAllowedFormats.push(
                  { type: "barcode39", pattern: formatScanQr, _skipMatchFindDelimiterProcessing:true }//{ type: "qrcode", pattern: formatScan }
                );
              }
            }

            if(shippingOrdersQRCode){
              arrayAllowedFormats.push(
                { type: "qrcode", pattern: formatScanQr }//{ type: "qrcode", pattern: formatScan }
              );
            }
          
            scannerSettings.current.allowedScanFormats = arrayAllowedFormats;
            let isAtLeastOneScannerEnabled = shippingOrdersBarcode39 || shippingOrdersQRCode || shippingOrders_scannerSFS03;
            if(isAtLeastOneScannerEnabled){
              setDeviceScanEnabled(true);
            }
            if (posExtraData?.shippingOrderData) {
              setInfoOrderData(posExtraData.shippingOrderData);
            }

            if (posExtraData?.shippingOrderProducts_sent) {
            
              setShippingOrderProductsReceivedData(
                posExtraData?.shippingOrderProducts_sent
              );
            }

            if (posExtraData?.defaultShippingLocation) {
              let useShopfloor =
                posExtraData.defaultShippingLocation.useShopfloor === 1
                  ? true
                  : false;
              if (useShopfloor) {
                setShippingLocation({});
              } else if (
                !posExtraData?.defaultShippingLocation?.Id_WarehouseLocation ||
                posExtraData.defaultShippingLocation.Id_WarehouseLocation ===
                  null
              ) {
                setShippingLocation(null);
              } else {
                let newShippingLocation: any = {};

                let idWarehouseLocation =
                  posExtraData?.defaultShippingLocation?.Id_WarehouseLocation;
                let warehouseLocationLabel =
                  posExtraData?.defaultShippingLocation?.warehouseLocationLabel;
                let idWarehouse =
                  posExtraData?.defaultShippingLocation?.Id_Warehouse;
                let warehouseLabel =
                  posExtraData?.defaultShippingLocation?.warehouseLabel;

                let idLot = posExtraData?.defaultShippingLocation?.Id_Lot;
                let lotLabel = posExtraData?.defaultShippingLocation?.lotLabel;

                let defaultNoLotObj =
                  posExtraData?.defaultShippingLocation?.defaultNoLotObj;

                newShippingLocation["Id_WarehouseLocation"] =
                  idWarehouseLocation;
                newShippingLocation["temp_warehouseLocationLabel"] =
                  warehouseLocationLabel;

                if (idWarehouse) {
                  newShippingLocation["Id_Warehouse"] = idWarehouse;
                  newShippingLocation["temp_warehouseLabel"] = warehouseLabel;
                }

                if (idLot) {
                  newShippingLocation["Id_Lot"] = idLot;
                  newShippingLocation["temp_lotLabel"] = lotLabel;
                }

                if (defaultNoLotObj) {
                  newShippingLocation["defaultNoLotObj"] = defaultNoLotObj;

                  if (!idLot && setDefaultNoLotAsLotOnLoad) {
                    newShippingLocation["Id_Lot"] = defaultNoLotObj.Id_Lot;
                    //newReceivinnewShippingLocationgLocation["temp_lotLabel"] = "(" + defaultNoLotObj.code + ") " + defaultNoLotObj.name;
                  }
                }

                setShippingLocation(newShippingLocation);
              }
            }
          }

          let newArrayLoadedObjs = [];

          /*
          if (loadedObjsRef.current !== null) {
            newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
          } else {
            newArrayLoadedObjs = posData;
          }*/
          newArrayLoadedObjs = posData;
          loadedObjsRef.current = newArrayLoadedObjs;

          let hasMoreElements =
            newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
          if (hasMoreElements && !DISABLE_LOAD_MORE_SHIPPING_PRODUCTS) {
            allowedInfiniteScrollLoadPageRef.current =
              allowedInfiniteScrollLoadPageRef.current + 1;
            infiniteScrollEnabledRef.current = true;
          } else {
            infiniteScrollEnabledRef.current = false;
          }
          if (currentPage === 0) {
            setLoadingPage(false);
          }
          //setLoadedObjs(loadedObjsRef.current);
          setIsLoadingMore(false);
          setIndexReRender(indexReRender + 1);
        })
        .catch((resp) => {
          setLoadingShipping(false);
          console.log("THE ERROR: ", resp);
          if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
            if (failedToLoadPosError) {
              setFailedToLoadPosError(false);
            }
            setRequiresNetworkLoadError(true);
          } else {
            if (requiresNetworkLoadError) {
              setRequiresNetworkLoadError(false);
            }
            setFailedToLoadPosError(true);
          }
          console.log("FAILED::", resp);

          setLoadingPage(false);
        });
    },
    [
      setDefaultNoLotAsLotOnLoad,
      shippingOrderId,
      failedToLoadPosError,
      indexReRender,
      requiresNetworkLoadError,
      filtersApplied,
    ]
  );
  const restartSearch = useCallback(() => {
    allowedInfiniteScrollLoadPageRef.current = 0;
    loadedObjsRef.current = null;

    loadMore();
  }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);

  const alreadyScannedData = (data: any) => {
    let stringData = JSON.stringify(data);

    if (scannedCodesArr.current.includes(stringData)) {
      return true;
    }

    return false;
  };

  const submitIt = (passedShippingProducts:any = null) => {
    let preparedShippingProducts = getPreparedShippingProducts(passedShippingProducts);
    if(!preparedShippingProducts){
      return;
    }
    confirmSubmit(preparedShippingProducts);
  }
  useEffect(() => {
    if (!didMount) {
      let loadingFilters = props?.location?.state?.applyFilters;

      if (loadingFilters) {
        setFiltersApplied(loadingFilters);
      } else {
        loadingFilters = null;
      }

      window.history.replaceState({}, document.title); //clear history state

      loadMore(loadingFilters);
      setDidMount(true);
    }
  }, [
    props?.location?.state?.applyFilters,
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  useEffect(() => {
    if(prevTriggerOnFinishedScanData !== triggerOnFinishedScanData && triggerOnFinishedScanData !== null){
      let result:any = triggerOnFinishedScanData;
      setTriggerOnFinishedScanData(null); //to prevent retriggering
      console.log("RESULT: ", result);
     
      let format = result.resultFormat;
      let resultScan = result.resultScan;

      if (alreadyScannedData(result)) {
        feedbackService.notifyToast(
          t("scan.rejected.already.scanned"),
          "danger"
        );
        return;
      }
      let pattern = format.pattern;

      let scannedWithQrcode = false;
      let scannedWithBarcode = false;
      let delimiter = null;

      let currentAllowedScanFormats = scannerSettings.current.allowedScanFormats;

      if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
        if(format.type === "barcode39" || format.type === "barcode128"){
        
            for(let i = 0; i<currentAllowedScanFormats.length; i++){
              if(!currentAllowedScanFormats[i]?._skipMatchFindDelimiterProcessing && currentAllowedScanFormats[i].pattern === pattern){
                  //match found
                  let realType = currentAllowedScanFormats[i]?.type;
                
                  //SWAP type and process as if it was scanned with qrcode (in case it was qrcode pattern the one scanned)
                  format.type = realType;
                
              }
            }
          
        }
      }
      if (format.type === "barcode39") {
        delimiter = ScannablesService().hxfBarcode39Delimiter;
        scannedWithBarcode= true;
      }

      if (format.type === "barcode128") {
        delimiter = ScannablesService().hxfBarcode128Delimiter;
        scannedWithBarcode= true;
      }

      if (format.type === "qrcode") {
        delimiter = ScannablesService().hxfQrcodeDelimiter;
        scannedWithQrcode= true;
      }

    
    
      let splittedPattern = pattern.split(delimiter);
      debugger;
      let splittedResult = resultScan.split(delimiter);
      if(splittedPattern.length > 0){
        if(splittedPattern[0] === "SFS03"){
          //check if 
          if(autoFillSettings.current.shippingOrders_scannerSFS03){
            //by default when this is enabled we want to fill the current products with the Serialized Product associated consumptions (all products must exist)
            let idSerializedProduct = splittedResult?.[1];
            if(idSerializedProduct){

              let objGetInfo:any = {
                Id_SerializedProduct:idSerializedProduct,
                includeAssociatedProductionConsumptionStockMovements:true
              };

              if(onSfs03ScanShipLimitSingleUnitBom){
                objGetInfo.limitConsumptionSMsSingleUnitBom = true;
              }
              //fetch idserializedproduct associated consumptions data
              serializedProductsController().getInfo(objGetInfo).then((res) => {
                //check if all products from the serialized product exists
                debugger;
                if(!res?.data?.serializedProduct){
                  feedbackService.notifyToast(t('generic.critical.error'), "error");
                  return;
                }

                let bomConsumptionStockMovements = res?.data?.extraData?.consumptionStockMovements;
                if(!bomConsumptionStockMovements || bomConsumptionStockMovements.length === 0){
                  //error no consumption stock movements found
                  feedbackService.notifyToast(t('no.consumption.stock.movements.found'), "error");
                  return;
                }

                let preparedShippingOrderProducts:any = [];
                //check if all the bom consumption sms products exist in the current listed products
                //some stock movements might be related to the same product but it is checked twice (can be optimized)
                for(let i = 0; i<bomConsumptionStockMovements.length; i++){
                  let idProduct = bomConsumptionStockMovements[i].Id_Product;
                  let arrayPPVVS = bomConsumptionStockMovements[i].arrayProductParameterizedVariables;
                  let productKey = ProductService().buildProductKey(idProduct, arrayPPVVS);

                  let foundMatch = false;
                  let idShippingOrderProduct = null;
                  let loadedProductObjs = loadedObjsRef.current;
                  for(let i = 0; i<loadedProductObjs.length; i++){
                    let obj = loadedProductObjs[i];
                    idShippingOrderProduct = obj.Id;
                    let productData = JSON.parse(obj.productData);
                    let shippingOrderProductParameterizedVariablesValueData = obj?.shippingOrderProductParameterizedVariablesValueData ? JSON.parse(obj.shippingOrderProductParameterizedVariablesValueData) : [];
          
                    let loadedProductKey = ProductService().buildProductKey(productData.Id_Product, shippingOrderProductParameterizedVariablesValueData);
                    if(loadedProductKey === productKey){
                      foundMatch = true;
                      break;
                    }
                  }

                  if(!foundMatch){
                    feedbackService.notifyToast(t('couldnt.find.all.products.list'), "error");
                    return;
                  }

                  let idLot = bomConsumptionStockMovements[i].Id_Lot;
                  let idWh = bomConsumptionStockMovements[i].Id_Warehouse;
                  let idWhLoc = bomConsumptionStockMovements[i].Id_WarehouseLocation;

                  let quantity = bomConsumptionStockMovements[i].Quantity*-1;
                  if(onSfs03ScanShipLimitSingleUnitBom && bomConsumptionStockMovements[i]?.spSingleUnitBom_Quantity){
                    quantity = bomConsumptionStockMovements[i].spSingleUnitBom_Quantity*-1;
                  }
                  //found all now ship them
                  preparedShippingOrderProducts.push({
                    Id_ShippingOrder_Product:idShippingOrderProduct,
                    Id_Lot:idLot,
                    Id_Warehouse:idWh,
                    Id_WarehouseLocation:idWhLoc,
                    quantity:quantity
                  })
                }

                let opts:any = {};
                let duplicateCorrection = autoFillSettings.current.shippingOrders_SFS03SMDupeFix === true;
                debugger;
                //check if it will need to readd the consumptions
                if(duplicateCorrection){
                  opts["duplicateCorrection"] = duplicateCorrection;

                  //also need to remove one unit of the Serialized Product (because what we care about registering is each product itself)
                  opts["dcRemove_Id_SerializedProduct"] = idSerializedProduct;
                }
                present({
                  cssClass: "my-css",
                  header: t("serialized.product"),
                  message: t("confirm.ship.associated.consumptions"),
                  buttons: [
                    t("cancel"),
                    {
                      text: t("yes"),
                      handler: (d:any) => {

                        confirmSubmit(preparedShippingOrderProducts,opts);

                      },
                    },
                  ],
                  onDidDismiss: (e:any) => {},
                });

               
              }).catch((res) => {
               

                let errors = res?.response?.data?.issues?.errors;
                if(errors && errors.includes("not.enought.consumptions.for.single.unit.bom")){
                  feedbackService.notifyToast(t('not.enought.consumptions.for.single.unit.bom'), "error");
                  return;
                }
                feedbackService.notifyToast(t('generic.critical.error'), "error");
              });

              //
              //todo new checkbox, consumption conpensation (?? check github)
              return;
            }
          }
        }
      }


      //generic multi pattern that identifies product id and lot
      let indexThatIdentifiesTheProductId:any = -1;
      let indexThatIdentifiesTheLotId = -1;

      let indexThatIdentifiesTheProductionRecord = -1;
      for (let i = 0; i < splittedPattern.length; i++) {
        if (splittedPattern[i] === "[productionrecord.id]") {
          indexThatIdentifiesTheProductionRecord = i;
        
        }

     
        if (splittedPattern[i] === "[product.id]") {
        
          indexThatIdentifiesTheProductId = i;
        
        }


        if (splittedPattern[i] === "[lot.id]") {
          indexThatIdentifiesTheLotId = i;
         
        }
      }
      
      let isIdentifyingByProductAndLot = indexThatIdentifiesTheLotId !== -1 && indexThatIdentifiesTheProductId !== -1;

      if(isIdentifyingByProductAndLot){
        
        //identified with product id and lot id
        let splittedResult = resultScan.split(delimiter);
        let idLot = splittedResult[indexThatIdentifiesTheLotId];
        let idProduct = splittedResult[indexThatIdentifiesTheProductId];

        let arrayProductParameterizedVariables:any = []; //this type of scan does not support PPVVS so set as empty
        
        let productKey = ProductService().buildProductKey(idProduct, arrayProductParameterizedVariables);

        //get lot data
        //todo set loading
        lotsController().getLot({
          includeWhLocation:true,
          includeDefaultNoLot:true
        },idLot,null).then((res) => {
        
          if(!res?.data?.Id){
            throw "unexpected scan res";
          }
          let responseObject = res?.data;
          
          let lotCode = responseObject.code;
          let lotName = responseObject.name;
          let idWh = responseObject?.Id_Warehouse;
          let whName = responseObject?.warehouse?.name;
          let whCode = responseObject?.warehouse?.code;
          let idWhLoc = responseObject?.warehouse?.warehouseLocation?.Id;
          let whLocName = responseObject?.warehouse?.warehouseLocation?.name;
          let whLocCode = responseObject?.warehouse?.warehouseLocation?.code;
          let defaultNoLotObj = responseObject?.warehouse?.defaultNoLot;

          if(!defaultNoLotObj){
            defaultNoLotObj = null;
          }

        //check if there is only ONE product with the selected id
        let foundDoubleProductEqual = false;
     
        let errorFoundDoubleProductsEqual = false;
        let errorAlreadyAddingQtyToEqualProduct = false;
        let objProductTarget = null;

        let lastSelectedProductIsAlreadyAddingQty = false;
  
        let loadedProductObjs = loadedObjsRef.current;
        for(let i = 0; i<loadedProductObjs.length; i++){
          let obj = loadedProductObjs[i];
          let idShippingOrderProduct = obj.Id;
          let totalQtyToShip = obj.Quantity;
          let productData = JSON.parse(obj.productData);
          let shippingOrderProductParameterizedVariablesValueData = obj?.shippingOrderProductParameterizedVariablesValueData ? JSON.parse(obj.shippingOrderProductParameterizedVariablesValueData) : [];

          let loadedProductKey = ProductService().buildProductKey(productData.Id_Product, shippingOrderProductParameterizedVariablesValueData);

          //if(parseInt(productData.Id_Product) === parseInt(idProduct)){
          if(productKey === loadedProductKey){
            let currentAddingQty = getQtyShippingProductByIdShippingProduct(idShippingOrderProduct);
            let currentSentQty =  getFinishedShippingOrderProductQuantity(idShippingOrderProduct);
            let totalAssumedFilledQty = currentAddingQty + currentSentQty;
            let isFilled = totalAssumedFilledQty >= totalQtyToShip;

            if(currentAddingQty > 0){
              lastSelectedProductIsAlreadyAddingQty = true;
            }
            
            if(objProductTarget !== null){
              foundDoubleProductEqual = true;

              if(ON_SCAN_EQUAL_PRODUCTS_SELECT_FIRST_AND_IF_OVERFILLED_FILL_NEXT_MATCH){
                //we only allow 'simultaneous fill' if not already adding qty to products
                if(lastSelectedProductIsAlreadyAddingQty){
                  errorAlreadyAddingQtyToEqualProduct = true;
                }
              }else{
                errorFoundDoubleProductsEqual = true;
              }

              
            }else{
              objProductTarget = obj;
            }

          }
        }

        if(!objProductTarget ){

          feedbackService.notifyToast(t('scanned.product.not.found.in.expedition'), "error");
          
          setShowBarcodeScannerPopup(false);
          return;
        }

        if(errorFoundDoubleProductsEqual){
            feedbackService.notifyToast(t('multiple.products.with.same.id.found'), "warning");
            setShowBarcodeScannerPopup(false);
            return;
        }

        if(errorAlreadyAddingQtyToEqualProduct){
          feedbackService.notifyToast(t('two.equal.already.filled.products.edit.manually'), "error");
          setShowBarcodeScannerPopup(false);
          return;
        }


        let allowFillQuantity = false;
        if(scannedWithBarcode){
          allowFillQuantity = autoFillSettings.current?.shippingOrders_barcode39FillQuantity;
        }
        if(scannedWithQrcode){
          allowFillQuantity = autoFillSettings.current?.shippingOrders_QRFillQuantity;
        }
        
        selectProduct(objProductTarget,{
          onQuantityConfirmOverShipFillNextEqualProducts:foundDoubleProductEqual && ON_SCAN_ON_QUANTITY_CONFIRM_IF_OVERSHIP_FILL_NEXT_EQUAL_PRODUCTS,
          onQuantityConfirmFillDefaultByEqualProductsTotalMissingQty:ON_SCAN_ON_QUANTITY_CONFIRM_IF_OVERSHIP_FILL_NEXT_EQUAL_PRODUCTS && allowFillQuantity,
          fillDefaultQuantityWithMissingTotal:allowFillQuantity
        });
          setShippingLocation({
            Id_Lot:idLot,
            Id_Warehouse:idWh,
            Id_WarehouseLocation:idWhLoc,
            defaultNoLotObj:defaultNoLotObj,
            temp_lotLabel:lotCode,
            temp_warehouseLabel:whCode,
            temp_warehouseLocationLabel:whLocCode
          });
          //Todo select open product 
          setShowBarcodeScannerPopup(false);
        }).catch((res) => {
         
        
          if(res?.response?.status === 404){
            feedbackService.notifyToast(t('lot.not.found'),"danger");
            return;
          }
          feedbackService.notifyToast(t('generic.critical.error'),"danger");

          //force closing it to avoid issues
          setShowBarcodeScannerPopup(false);
        });
      }else{
        
        if (indexThatIdentifiesTheProductionRecord !== -1) {
          let splittedResult = resultScan.split(delimiter);
          if (splittedResult.length !== splittedPattern.length) {
            console.log("Scan does not match the pattern");
          } else {
            let productionRecordId =
              splittedResult[indexThatIdentifiesTheProductionRecord];
            scannerBridgeController()
              .getScannedProductionRecord({
                Id_ProductionRecord: productionRecordId,
              })
              .then((res: any) => {
                let scanResult = res?.data?.response?.data;
                if (scanResult && scanResult?.stockMovement) {
                  let fillProduct =  autoFillSettings.current?.shippingOrders_barcode39FillProduct;
                  let fillQuantity =  autoFillSettings.current?.shippingOrders_barcode39FillQuantity;
                  let fillStockLocation = autoFillSettings.current?.shippingOrders_barcode39FillStockLocation;

                  let associatedStockMovement = scanResult?.stockMovement;
                  let idProduct = associatedStockMovement.Id_Product;
                  let stockMovementPPVVs =
                    associatedStockMovement.stockmovementProductParameterizedVariablesData;

                  //find shipping product match for this product
                  //consider Id, IdPv, IdPPVV
                  for (let i = 0; i < loadedObjsRef.current.length; i++) {
                    let idShippingProduct = loadedObjsRef.current[i].Id;
                    let shp_Id_Product =
                      loadedObjsRef.current[i].Id_Product;
                    let shp_shippingPPVVs = JSON.parse(
                      loadedObjsRef.current[i]
                        .shippingOrderProductParameterizedVariablesValueData
                    );

                    if (shp_Id_Product === idProduct) {
                      //product matches
                      let doParameterizedVariableValuesMatch =
                        parameterizedVariablesService().compareStockMovementProductWithShippingProductPVVS(
                          stockMovementPPVVs,
                          shp_shippingPPVVs
                        ); //todo

                      if (doParameterizedVariableValuesMatch) {
                        //ppvvs matches

                        let currentAddingQty =
                          getQtyShippingProductByIdShippingProduct(
                            idShippingProduct
                          );
                        if (fillProduct) {
                          if (fillQuantity) {
                            updateQtyShippingProduct(
                              idShippingProduct,
                              parseFloat(currentAddingQty) +
                                parseFloat(associatedStockMovement.Quantity)
                            );
                          }
                        }

                        if (fillStockLocation) {
                          //fills with the stock movement stock location
                          let idWarehouseLocation =
                            associatedStockMovement.Id_WarehouseLocation;
                          let idWarehouse =
                            associatedStockMovement.Id_Warehouse;
                          let idLot = associatedStockMovement.Id_Lot;

                          let warehouseLocationLabel = "";
                          let warehouseLabel = "";
                          let lotLabel = "";

                          if (
                            associatedStockMovement?.warehouseLocationData
                          ) {
                            warehouseLocationLabel =
                              "(" +
                              associatedStockMovement?.warehouseLocationData
                                .code +
                              ") " +
                              associatedStockMovement?.warehouseLocationData
                                .name;
                          }

                          if (associatedStockMovement?.warehouseData) {
                            warehouseLabel =
                              "(" +
                              associatedStockMovement?.warehouseData.code +
                              ") " +
                              associatedStockMovement?.warehouseData.name;
                          }

                          if (associatedStockMovement?.lotData) {
                            lotLabel =
                              "(" +
                              associatedStockMovement?.lotData.code +
                              ") " +
                              associatedStockMovement?.lotData.name;
                          }

                          let newStockLocation = {
                            Id_WarehouseLocation: idWarehouseLocation,
                            Id_Warehouse: idWarehouse,
                            Id_Lot: idLot,
                            temp_lotLabel: lotLabel,
                            temp_warehouseLabel: warehouseLabel,
                            temp_warehouseLocationLabel:
                              warehouseLocationLabel,
                          };

                          setShippingLocation(newStockLocation);
                          
                        }

                        break;
                      }
                    }
                  }

                  setShowBarcodeScannerPopup(false);

                  feedbackService.notifyToast(t("scanned") + "!", "success", {
                    duration: 3000,
                  });

                  scannedCodesArr.current.push(JSON.stringify(result));
                } else {
                  throw "unexpected error result";
                }
                //todo
              })
              .catch((res: any) => {
                if (res?.response?.status === 404) {
                  feedbackService.notifyToast(t("invalid.scan"), "danger");
                  return;
                }
                feedbackService.notifyToast(
                  t("failed.error.generic"),
                  "danger"
                );
              });
          }
        }
      }

    }
  },[triggerOnFinishedScanData]);
  const getBarcodeScannerElement = () => {
    return (
      <>
        <IonCardHeader
          className={`${styles.elemElement} ${styles.scannerElem} ${
            isMatchingAll() ? styles.sendAllMatched : ""
          }`}
          onClick={() => {
            setShowBarcodeScannerPopup(true);
          }}
        >


          <div className={styles.sendAllTitle}>
            <div>
                <div>{t('scanner')}</div>
                <div className={styles.barcodeIconBg}>
                  <IonIcon icon={barcodeOutline} style={{fontSize:60}} />
                </div>
            </div>
          </div>

        </IonCardHeader>
      </>
    );
  };

  useEffect(() => {
    if (previousDidMount === didMount && didMount) {
      if (previousFiltersApplied !== filtersApplied) {
        console.log("filters were changed");
        restartSearch();
      }
    }
  }, [
    filtersApplied,
    previousDidMount,
    didMount,
    previousFiltersApplied,
    restartSearch,
  ]);

  const getPreparedShippingProducts = (passedShippingProducts = null) => {

    let preparedShippingProducts = passedShippingProducts ? passedShippingProducts : [...shippingProducts];

    if (preparedShippingProducts.length === 0 && !modifiedDescription) {
      present({
        buttons: [
          {
            text: "hide",
            handler: () => {
              console.log("called dismiss");
            },
          },
        ],
        message: t('not.added.any.products'),
        onDidDismiss: () => console.log("dismissed"),
        onWillDismiss: () => console.log("will dismiss"),
      });
      return false;
    }

    let properLocationDefined = false;
    if (
      (shippingLocation !== null &&
        shippingLocation?.Id_WarehouseLocation &&
        shippingLocation?.Id_Warehouse &&
        shippingLocation?.Id_Lot) ||
      (shippingLocation !== null &&
        !shippingLocation?.Id_WarehouseLocation &&
        !shippingLocation?.Id_Warehouse &&
        !shippingLocation?.Id_Lot)
    ) {
      properLocationDefined = true;
    }

    if (!properLocationDefined) {
      /*present(
          {
              buttons: [{ text: 'hide', handler: () => { console.log("called dismiss"); } }],
              message: 'Please define a location to take stock from',
              onDidDismiss: () => console.log('dismissed'),
              onWillDismiss: () => console.log('will dismiss')
          }
        );    */
      setIsPopupShippingLocationOpen(true);
      return false;
    }


    for (let i = 0; i < preparedShippingProducts.length; i++) {
      let idWarehouseLocation =
        shippingLocation?.Id_WarehouseLocation
          ? shippingLocation.Id_WarehouseLocation
          : null;
      let idWarehouse = shippingLocation?.Id_Warehouse
        ? shippingLocation.Id_Warehouse
        : null;
      let idLot = shippingLocation?.Id_Lot
        ? shippingLocation.Id_Lot
        : null;

      preparedShippingProducts[i]["Id_WarehouseLocation"] =
        idWarehouseLocation;
      preparedShippingProducts[i]["Id_Warehouse"] = idWarehouse;
      preparedShippingProducts[i]["Id_Lot"] = idLot;

      if (idLot === "new") {
        preparedShippingProducts[i]["newLotNameCode"] =
          shippingLocation.temp_lotLabel;
      }
    }

    return preparedShippingProducts;
  }

  const triggerOnCancelFeatures = () => {
  
    if(clearStockLocationOnCancel){
      //check if not adding qty in any product and only if its not, clear the stock location
      let isAddingQty = false;
      for (let i = 0; i < shippingProducts.length; i++) {
        if(shippingProducts[i].quantity > 0){
          isAddingQty = true;
          break;
        }
      }
      if(!isAddingQty){
        setShippingLocation(null);
      }
    }
  }
  const getPopupScannerObj = (options:any = {}) => {
    return (
      <ScanBarcodePopup
      freeScannerMode={false}
      title="Scanner"
      ghostMode={options.ghostMode}
      visible={true}
      barcodeScannerSettings={scannerSettings.current}
      onClosedCamera={() => {
        if (
          MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
          scannerSettings.current.useCamera &&
          !scannerSettings.current.useExternalDevice
        ) {
          setShowBarcodeScannerPopup(false);
        }
      }}
      onFinishedScan={(result: any) => {
        setTriggerOnFinishedScanData(result); //Locate scanbarcodepopup, as scanbarcodepopup works on timer, eventually make this functionality inside hxfbcarcodescanner, create hxfbarcodescanner v2 or something
        //the onFinishedScan finished scan must not be called from a timer, to fix later, or it would be accessing unsynced states
        //this temporarily fixes it

      }}
      onClosePopup={() => {
        setShowBarcodeScannerPopup(false);
      }}
    />
    )
  }

  const confirmSubmit = (preparedShippingProducts:any, options:any = {}) => {
   let extraData:any = {};

   if(modifiedDescription){
    extraData.Id_ShippingOrder = shippingOrderId;
    extraData.updateDescription = infoOrderData?.description ? infoOrderData.description : null;
   }

   if(options?.duplicateCorrection){
    extraData.duplicateCorrection = options.duplicateCorrection;
   }

   if(options?.dcRemove_Id_SerializedProduct){
    extraData.dcRemove_Id_SerializedProduct = options.dcRemove_Id_SerializedProduct;
   }
   setLoadingShipping(true);
    shippingOrderProductsController()
      .sendShippingOrderProducts(preparedShippingProducts, extraData)
      .then((res) => {
        setLoadingShipping(false);
        if(!res?.data?.done){
          throw "unexpected error";
        }
        /*
        if(res?.data?.code !== 200){
          throw "unexpected error";
        }*/

        if(shippingProducts && shippingProducts.length !== 0){
          
        }else if(modifiedDescription){
          feedbackService.notifyToast(t("description.updated.successfully"), "success");
        }
        
        if(shipRedirectHome){
          props.history.push("/");
        }else{

          /*
          props.history.push({
            pathname: "/page-redirector",
            state: { redirectTo: window.location.pathname },
          });*/
          feedbackService.notifyToast(t("shipped.successfully"), "success");
          refetchData();

        }
        
      })
      .catch((res) => {
        setLoadingShipping(false);
        let errors = res?.response?.data?.response?.issues?.errors;
        if(errors && errors.includes("error.serialIsRequired")){
         
          feedbackService.notifyToast(t("serials.require.identificaiton"), "error");
          props.history.push({
            pathname: "/page-redirector",
            state: { redirectTo: window.location.pathname },
          });
          return;
        }
        
        feedbackService.notifyToast(t("generic.critical.error"), "error");
      });
  }

  const isOrderCompleted = () => {
  
    let shopsSent = shippingOrderProductsReceivedData ? shippingOrderProductsReceivedData : [];
    let sops = loadedObjsRef?.current ? loadedObjsRef?.current : [];
    
    for(let i = 0; i<sops.length; i++){
      let idSop = sops[i].Id;
      let qtyRequired = sops[i].Quantity;
      let matchedQtyRequired = false;

      for(let j = 0; j<shopsSent.length; j++){
        if(shopsSent[j].Id_ShippingOrder_Product === idSop){
          let shippedQty = shopsSent[j].shippedQuantity;
          if(parseFloat(shippedQty) >= parseFloat(qtyRequired)){
            matchedQtyRequired = true;
          }
          
        }
      }

      if(!matchedQtyRequired){
        return false;
      }

    }
    return true;
  }
  if (!didMount) {
    return <></>;
  }

  if (!shippingOrderId) {
    return (
      <InAppTemplate>
        <CustomArrowBack
          onClick={() => {
            callGoBack();
          }}
        />
        <div className={styles.pageTitle}>{t("shippingorder.products")}</div>
      </InAppTemplate>
    );
  }

  return (
    <InAppTemplate>

      {loadingShipping && (
        <HxfOverlayLoading/>
      )}
      {isPopupDefineDescriptionOpen && (
        <DefineInputsDataPopup
          onClose={() => {
            setIsPopupDefineDescriptionOpen(false);
          }}
     
          title={t('define.description')}
          mapValuesErrors={[]}
          arrayInputs={[
            {
              label:t('description'),
              inputType:"TEXTFIELD",
              defaultValue:infoOrderData?.description,
              typeInputTextMaxLength:255,
              typeInputTextAutoFocus:true
            }
          ]}
          arrayConfirmationButtons={[
            {
              label:t('confirm'),
              labelStyles:{
                fontSize:25,
                margin:20
              },
              btnStyles:{
                minHeight:80,
                display:'flex',
                justifyContent:'center'
              },
              onClick: (data:any) => {

                let newDescription = data?.mapInputsValues?.[0];
                setInfoOrderData({...infoOrderData, description: newDescription})
                setModifiedDescription(true);
                setIsPopupDefineDescriptionOpen(false);
              }
            }
          ]}
        />
      )}

      {isPopupShippingLocationOpen && (
        <DefineStockLocationPopup
          allowLotCreation={false}
          subtitle={t("define.stock.taking.from")}
          currentData={shippingLocation}
          title={t("shipping.location")}
          onClosePopup={() => {
            setIsPopupShippingLocationOpen(false);
          }}
          onSuccessConfirm={(result: any) => {
            if (!result?.Id_Lot && result?.defaultNoLotObj) {
              result.Id_Lot = result.defaultNoLotObj.Id_Lot;
              result.temp_lotLabel =
                "(" +
                result.defaultNoLotObj.code +
                ") " +
                result.defaultNoLotObj.name;
            }

            setShippingLocation(result);
            setIsPopupShippingLocationOpen(false);
            console.log("success", result);
          }}
        />
      )}

      {isPopupDefineProductQuantityOpen && (
        <DefineProductQuantityPopup
          productData={popupQuantityProductData}
          title={t("shipping.quantity")}
          onClosePopup={() => {
            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
            
            triggerOnCancelFeatures();
          }}
          onSuccessConfirm={(res: any) => {
            let onQuantityConfirmOverShipFillNextEqualProducts = popupQuantityProductData?.onQuantityConfirmOverShipFillNextEqualProducts;


            let idShippingProduct =
              popupQuantityProductData.Id_ShippingOrder_Product;

            let quantityResult = res.quantityResult;
           
            if(quantityResult === ""){
              quantityResult = 0;
            }


            let newShippingProducts = null;

            if(onQuantityConfirmOverShipFillNextEqualProducts){
              if(quantityResult > 0){
                let shippingOrderProductObj = getShippingProductByIdShippingProduct(idShippingProduct);
                if(!shippingOrderProductObj){
                  throw "unexpected shopobj not found";
                }
                let shippingProductArrayPPVVS = shippingOrderProductObj?.shippingOrderProductParameterizedVariablesValueData ? shippingOrderProductObj.shippingOrderProductParameterizedVariablesValueData : [];
                let shippingOrderProductKey = ProductService().buildProductKey(shippingOrderProductObj.Id_Product, shippingProductArrayPPVVS);
                quantityResult = UomService().convertQuantity(
                  quantityResult,
                  res.newProductData.Id_UoM_Selected,
                  -1,
                  res.newProductData.uomConversionsData
                );
                newShippingProducts = distributeQuantityByEqualProducts(shippingOrderProductObj.Id_Product, shippingProductArrayPPVVS, quantityResult);
              }
            }else{
              //default behaviour
              if (quantityResult === 0) {
                newShippingProducts = removeQtyShippingProduct(idShippingProduct);
              } else {
                if (res?.newProductData?.Id_UoM_Selected !== -1) {
                  if(res?.newProductData?.uomConversionsData.length > 0){
                    //convert qty received back to base uom
                    quantityResult = UomService().convertQuantity(
                      quantityResult,
                      res.newProductData.Id_UoM_Selected,
                      -1,
                      res.newProductData.uomConversionsData
                    );
                  }
   
                }
                newShippingProducts = updateQtyShippingProduct(idShippingProduct, quantityResult);
              }
            }

            //get index product and scroll to it
       
            let indexProduct = null;
   
            let curProducts = loadedObjsRef.current;
            for (let i = 0; i < curProducts.length; i++) {
              if (
                curProducts[i].Id === idShippingProduct
              ) {
                indexProduct = i;
                break;
              }
            }
            if(indexProduct !== null){
              //scroll into view
           

              let elementToScroll = document.querySelector('[data-shipping-order-product-idx="' + indexProduct+ '"]');
              if(elementToScroll){
                elementToScroll.scrollIntoView({
                  behavior: 'smooth', // or 'auto'
                  block: 'start', // 'start', 'center', 'end', 'nearest'
                  inline: 'nearest' // 'start', 'center', 'end', 'nearest'
                });
              }

            }
            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
            
            
            //IF AUTOCONFIRM enabled
            if(enableAutoConfirm){
              submitIt(newShippingProducts);
            }
            
          }}
        />
      )}

      <div className={styles.topPageTitleBtnsContainer}>
        <div className={styles.arrowSpaceFiller}>
          <CustomArrowBack
            onClick={() => {
              callGoBack();
            }}
          />
        </div>
        <div className={styles.pageTitle}>{t("shippingorder.products")}</div>
        <div className={styles.cornerBtnsContainer}>
          {SHOW_BTN_CLOSE_SHIPPINGORDER && (
            <CornerButton
              disabled={!isEnabledCloseOrder()}
              onClick={() => {
                present({
                  cssClass: "my-css",
                  header: t("shippingorder.closing"),
                  message: t("shippingorder.close.confirm"),
                  buttons: [
                    "Cancel",
                    {
                      text: t("yes"),
                      handler: (d) => {
                        shippingOrdersController().close(shippingOrderId,getCurrentWorkerCode()).then((res) => {
                          if(!res?.data?.done){
                            throw "unexpected";
                          }
                        
                          feedbackService.notifyToast(t("shippingorder.closed.successfully"), "success");
                          props.history.push({
                            pathname: "/page-redirector",
                            state: { redirectTo: window.location.pathname },
                          });
                        }).catch((res) => {
                          feedbackService.notifyToast(t('generic.critical.error'),"danger");
                        });
                      },
                    },
                  ],
                  onDidDismiss: (e) => {},
                });
              }}
              btnType={"lock"}
              label={t("close.order")}
              iconPassed={bagCheckOutline}
            />
          )}


          { /* due to flex-wrap reverse, exit should be always the last item as we want to keep it in the corner to avoid misclicking */}
          <CornerButton
            onClick={() => {
              present({
                cssClass: "my-css",
                header: t("shippingorder.leaving"),
                message: t("shippingorder.exit.confirm"),
                buttons: [
                  "Cancel",
                  {
                    text: t("yes"),
                    handler: (d) => {
                      callGoBack();
                    },
                  },
                ],
                onDidDismiss: (e) => {},
              });
            }}
            btnType={"danger"}
            label={t("cancel")}
            iconPassed={closeCircle}
          />

        </div>
      </div>

      {loadingPage ? (
        <>
          <LoadingSpinnerImg />
        </>
      ) : (
        <>
          {isOrderCompleted() ? (
            <div className={styles.orderCompletedContainer}>
              <div>
                <div>{t('order.has.been.completed')}</div>
                <div>
                  <CheckCircleIcon/>
                </div>
              </div>

            </div>
          ) : (<>

              {!isOrderOpen && (
                <>
                 <div className={styles.orderCompletedContainer}>
                  <div>
                    <div>{t('order.has.been.closed')}</div>
                    <div>
                      <LockIcon/>
                    </div>
                  </div>

                </div>
                </>
              )}
          </>)}
        
          <div className={styles.infoAndSelection}>
            {infoOrderData?.code && (
              <div className={styles.orderInfoContainer}>
                <div className={styles.orderInfo}>
                  <div className={styles.orderInfoContent}>
                    <div>
                      <div>
                        <b>{t("shippingorder")}:</b>
                      </div>
                      <div>
                        ({infoOrderData.code}) {infoOrderData.name}
                      </div>
                    </div>
                    {getInfoOrderSalesOrder()}
                    <div>
                      <div>
                        <b>{t("date.due")}:</b>
                      </div>
                      <div>
                        {infoOrderData?.dueDate
                          ? DatesConversionService().formattedDate(
                              infoOrderData.dueDate
                            )
                          : t("date.any")}
                      </div>
                    </div>
                    {infoOrderData?.shippingAddress && (
                      <div>
                        <div>
                          <b>{t("shipping.address")}:</b>
                        </div>
                        <div>
                          {infoOrderData?.shippingAddress
                            ? infoOrderData.shippingAddress
                            : ""}
                        </div>
                      </div>
                    )}

                    {infoOrderData?.sendingTo_Id_Supplier && (
                      <div>
                        <div>
                          <b>{t("supplier")}:</b>
                        </div>
                        <div>
                          {infoOrderData?.sendingTo_supplierName}
                        </div>
                      </div>
                    )}

                    {infoOrderData?.description && (
                      <div>
                        <div>
                          <b>{t("description")}:</b>
                        </div>
                        <div>
                          {infoOrderData?.description}
                        </div>
                      </div>
                    )}

                    {enableSetDescription && (
                      <>
                        <IonButton
                          disabled={false}
                          className={styles.modifyLocationBtn}
                          onClick={() => {
                            setIsPopupDefineDescriptionOpen(true);
                          }}
                        >
                          <IonIcon slot="start" size="large" icon={pencilOutline} />
                          {infoOrderData?.description ? t("description.modify") : t("define.description")}
                        </IonButton>
                      </>
                    )}

                  </div>
                </div>
              </div>
            )}

            <div className={styles.shippingLocationContainer}>
              <div className={styles.shippingLocation}>
                <div className={styles.shippingLocationContent}>
                  <div className={styles.rlocTitle}>{t("taking.stock.from")}</div>
                  {shippingLocation !== null ? (
                    <>
                      <div>{getShippingLocationElement()}</div>
                    </>
                  ) : (
                    <div>{t("define.stock.taking.location")}</div>
                  )}
                </div>

                <IonButton
                  disabled={false}
                  className={styles.modifyLocationBtn}
                  data-btn={"modify-location"}
                  onClick={() => {
                    setIsPopupShippingLocationOpen(true);
                  }}
                >
                  <IonIcon slot="start" size="large" icon={albumsOutline} />
                  {t("location.modify")}
                </IonButton>
              </div>
            </div>
          </div>

          {/*<div className={styles.settingsContainer}>
               <div className={styles.customSearchBarContainer}>
                 <CustomSearchBar 
                   value={searchbarInput}
                   onResetTextClick={() => {
                     initiateFilterCode("");
                   }}
                   onChange={(evt) => {
                     initiateFilterCode(evt.target.value);
                     
       
                   }}
                  iconElement={<div className={styles.searchIconStyles}><IonIcon icon={searchCircleOutline}/></div>} placeholder={"Search Product"}/>
               </div>
               
             </div>*/}
          {filtersApplied.length > 0 && (
            <div className={styles.appliedFiltersContainer}>
              {filtersApplied.map((obj, index) => (
                <div key={"cafc_" + index}>
                  {getProperFilterChip(obj, index)}
                </div>
              ))}
            </div>
          )}
          {ALLOW_SWAP_VIEWS && (
              <div className={styles.displayViews}>
              {getViewModes().map((objMode:any, indexMode:any) => (
                <>
                    <div key={"viewMode_" + indexMode}>
                      {objMode === "MOBILE" && (
                          <IonButton
                          style={{width:64}}
                          color={viewMode === "MOBILE" ? "tertiary" : "medium"}
                          className={styles.closeWindowButton}
                          onClick={() => {
                            setViewMode("MOBILE");
                          }}
                        >
                          
                          <SettingsCellIcon/>
                        </IonButton>
                      )}
                      {objMode === "TABLE" && (
                          <IonButton
                          style={{width:64}}
                          color={viewMode === "TABLE" ? "tertiary" : "medium"}
                          className={styles.closeWindowButton}
                          onClick={() => {
                            setViewMode("TABLE");
                          }}
                        >
                          <IonIcon slot="icon-only" size="small" icon={listOutline} />
                    
                        </IonButton>
                      )}
                  </div>
                </>
              ))}

               
              </div>
          )}

          <div className={styles.shippingOrdersContainer}>
            {viewMode === "MOBILE" && (
              <div
              id="shippingOrdersSelectionDiv"
          
              className={styles.shippingOrdersSelection}
              ref={posContainerRef}
            >
              {deviceScanEnabled && <>{getBarcodeScannerElement()}</>}

              {enableSendAll && (
              <IonCardHeader
              className={`${styles.elemElement} ${styles.sendAllElem} ${
                isMatchingAll() ? styles.sendAllMatched : ""
              }`}
              onClick={() => {
            
                if(!ALLOW_DROP_SHOPFLOOR_LOCATION && !shippingLocation?.Id_WarehouseLocation){
                  feedbackService.notifyToast(t('please.define.a.drop.off.location'), "warning");
                  return;
                }
                let newShippingProducts = [];
                for (let i = 0; i < loadedObjsRef.current.length; i++) {
                  let totalQty = loadedObjsRef.current[i].Quantity;
                  let idShippingProduct = loadedObjsRef.current[i].Id;

                  let currentAddedQty =
                    getQtyShippingProductByIdShippingProduct(
                      idShippingProduct
                    );
                  let currentSentQty =
                    getFinishedShippingOrderProductQuantity(
                      idShippingProduct,
                      null
                    );
                  let totalAddedAndSentQty = currentAddedQty + currentSentQty;
                  let newAddingQtyVal = 0;
                  if (totalAddedAndSentQty < totalQty) {
                    newAddingQtyVal = totalQty - currentSentQty;
                  } else {
                    newAddingQtyVal = currentAddedQty;
                  }

                  newShippingProducts.push({
                    Id_ShippingOrder_Product: idShippingProduct,
                    quantity: newAddingQtyVal,
                  });
                }

                setShippingProducts(newShippingProducts);
              }}
            >
              <div className={styles.elemBackgroundContainer}>
                <AddRoadIcon />
              </div>

              <div className={styles.sendAllTitle}>{t("send.all")}</div>
            </IonCardHeader>
              )}

              {!failedToLoadPosError && loadedObjsRef.current !== null ? (
                <>
                  {loadedObjsRef.current.map((obj: any, index: any) => (
                    <div
                      key={"shopproduct_" + index}
                      className={styles.shippingOrderElement}
                      data-shipping-order-product-idx={index}
                      data-shipping-order-product-code={getObjProductCode(obj)}
                    >
                      {getShippingOrderProductElement(obj, index)}
                    </div>
                  ))}{" "}
                </>
              ) : (
                <></>
              )}

              {isLoadingMore && (
                <IonCardHeader
                  className={styles.elemElement}
                  onClick={() => {}}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <LocalShippingIcon />
                  </div>
                  <IonCardSubtitle>
                    <div className={styles.elemElementTitle}>Loading...</div>
                  </IonCardSubtitle>
                  <IonCardTitle className={styles.elemElementDescription}>
                    <IonSpinner />
                  </IonCardTitle>
                </IonCardHeader>
              )}

              {!DISABLE_LOAD_MORE_SHIPPING_PRODUCTS && (
                <HxfInfiniteScroll
                containerElementRef={posContainerRef}
                requiresContainerHavingScrollbar={true}
                onLoadMore={() => {
                  if (infiniteScrollEnabledRef.current) {
                    setIsLoadingMore(true);
                    loadMore();
                  }
                }}
                allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
              />
              )}

            </div>
            )}

            {viewMode === "TABLE" && (
            <div className={styles.tableViewMode}>
                <div className={styles.tableFormatContainer}>
  
                <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                              <CustomTableHead>
                                  <TableRow>
                                      <CustomTableCell><b>{t('product')}</b></CustomTableCell>
                                      {showProductStockLocations.current && (
                                        <CustomTableCell align="center">
                                                                              
                                            <b>{t('warehouse')}</b>
                                          
                                        </CustomTableCell>
                                      )}
                                      <CustomTableCell align="center"><b>{t('progress')}</b></CustomTableCell>
                                      <CustomTableCell align="center">
                                        
                                          <b>{t('action')}</b>
                                       
                                      </CustomTableCell>
                                  </TableRow>
                              </CustomTableHead>
                              <TableBody>
                                
                                
                              {!failedToLoadPosError && loadedObjsRef.current !== null && (
                                  <>
                                    {loadedObjsRef.current.map((obj: any, index: any) => (
                                      <TableRow
                                        key={"sop_ship_" + index}
                                        data-shipping-order-row-product-code={getPreparedProductDataObj(obj).code}
                                        data-shipping-order-row-shipping-order-product-id={obj.Id}
                                      >
                                        <CustomTableCell>
                                          <>
                                           <div>({getPreparedProductDataObj(obj).code})</div>
                                           <div>{getPreparedProductDataObj(obj).name}</div>
                                           {getObjPPVVS(obj).length > 0 && (
                                            <>
                                                {getObjPPVVS(obj).map((paramObj, idxParamObj) => (
                                                  <div className={styles.pvvs} key={"shptablep_" + index + "_ppv_" + idxParamObj}>
                                                    <div><b> {paramObj.paramName} </b></div>
                                                    <div>{paramObj.paramVal}</div>
                                                  </div>
                                                ))}
                                            </>
                                           )}

                                          {obj?.description && obj?.description !== "" && (
                                            <div>
                                              <div>
                                                <b>{t('shippingorder.product.desc.short')}</b>
                                              </div>
                                              <div>
                                                {obj.description}
                                              </div>
                                            </div>
                                          )}
                                          </>
                                        </CustomTableCell>

                                        {showProductStockLocations.current && (
                                          <CustomTableCell  align="center">
                                            <div style={{maxWidth:100,overflowWrap:'anywhere'}}>
                                             {getLabelLocationStock(obj?._stockAvailableLocations)?.warehousesLabel}
                                            </div>
                                            
                                          </CustomTableCell>
                                        )}
                                        <CustomTableCell align="center">

                                          <div style={{display:'flex',justifyContent:'center'}}>
                                              <div className={`${styles.tableProgressContainer} `}>
                                                <TaskProgressBarV2
                                                fillerColor={isProductCompleted(obj) ? '#a0dca070' : null}
                                                  width={"125px"}
                                                  tasksDone={getFinishedShippingOrderProductQuantity(obj.Id, obj)}
                                                  totalTasks={getShippingOrderProductTotalQuantity(obj.Quantity, obj)}
                                                  totalTasksNumberLabel={getTaskUomLabel(obj)}
                                                />
                                              </div>
                                          </div>
                                           
                                          </CustomTableCell>
                                          <CustomTableCell align="center">
                                            <div>
                                              <div>
                                                <IonButton
                                                  color="primary"
                                                  className={styles.closeWindowButton}
                                                  onClick={() => {
                                                    attemptOpenProduct(obj);
                                                  }}
                                                >
                                                  <IonIcon slot="icon-only" size="large" icon={addOutline} />
                                                  {t('quantity.short')}
                                                </IonButton>
                                              </div>
                                              <div>{getAddingQtyElem(obj.Id, obj)}</div>
                                            </div>
                                          </CustomTableCell>
                                      </TableRow>
                                    ))}{" "}
                                  </>
                              )}

                              </TableBody>
                          </Table>
                      </TableContainer>

                </div>
            </div>
            )}    

          </div>
        </>
      )}

      {failedToLoadPosError ? (
        <div className={styles.errorArea}>
          <CustomUnavailableErrorNotice />
        </div>
      ) : (
        <></>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}

      {showBarcodeScannerPopup && (
        <>{getPopupScannerObj()}</>
      )}

      {isGhostScannerEnabled() && (
        <>{getPopupScannerObj({ghostMode:true})}</>
      )}


      <div className={styles.confirmationBottomContainer}>
        <div className={styles.confirmationBottomContent}>
          <div className={styles.lineBreaker}></div>

          <div className={styles.centeredElem}>
            <IonButton
              disabled={!isOrderOpen || failedToLoadPosError || (isOrderCompleted() && disableBtnConfirmIfCompleted)}
              className={styles.confirmButton}
              onClick={() => {
                submitIt();
              }}
              data-btn={"shipping-confirm"}
            >
              {t("confirm")}
            </IonButton>
          </div>
        </div>
      </div>
    </InAppTemplate>
  );
}

export default ShippingOrderProductsShow;
