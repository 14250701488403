import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  useIonAlert,
} from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useState } from "react";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import styles from "../../Home.module.scss";
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import { t } from "i18next";
import useWorkerMode from "../../../../barrel/hooks/useWorkerMode";
function SingleMultiWorkerModeToggler() {
  const [present] = useIonAlert();
  const myLocalStorage = useLocalStorageHandler();
  const {changeToSingle,changeToMulti} = useWorkerMode();

  const [reRenderIndex, setReRenderIndex] = useState(0);
  const swapMode = (mode: "SMW_MODE_MULTI" | "SMW_MODE_SINGLE") => {
    // myLocalStorage.setSMWMode(mode);
    if(mode === "SMW_MODE_SINGLE"){
      changeToSingle();
    }else{
      changeToMulti();
    }
    setReRenderIndex(reRenderIndex + 1);
    if (mode === "SMW_MODE_MULTI") {
      popUpInfoMulti();
    } else {
      popUpInfoSingle();
    }
  };

  const popUpInfoMulti = () => {
    present({
      cssClass: "my-css",
      header: t("multiworker.mode"),
      message:
        t("youwillbe.asked.for.id"),
      buttons: [{ text: "OK", handler: (d) => {} }],
      onDidDismiss: (e) => {},
    });
  };
  const popUpInfoSingle = () => {
    present({
      cssClass: "my-css",
      header: t("singleworker.mode"),
      message:
        t("remain.auth.until"),
      buttons: [{ text: "OK", handler: (d) => {} }],
      onDidDismiss: (e) => {},
    });
  };

  const isSingleMode = () => {
    let curMode = myLocalStorage.getSMWMode();

    let isSingle = curMode === "SMW_MODE_SINGLE";
    return isSingle;
  };

  return (
    <>
      {isSingleMode() ? (
        <IonCardHeader
          className={styles.featureElement}
          onClick={() => {
            swapMode("SMW_MODE_MULTI");
          }}
        >
          <div className={styles.cornerIconBtn}>
            <div className={styles.cornerIconContainer}>
            <PeopleIcon />
            </div>
            
          </div>
          <div className={styles.featureBackgroundContainer}>
            <PeopleIcon />
          </div>
          <IonCardTitle className={styles.featureElementTitle}>
            {t("change.tomultiworker.mode")}
          </IonCardTitle>
          <IonCardSubtitle>
            <div className={styles.featureElementDescription}>
            {t("multiworker.desc")}
            </div>
          </IonCardSubtitle>
        </IonCardHeader>
      ) : (
        <IonCardHeader
          className={styles.featureElement}
          onClick={() => {
            swapMode("SMW_MODE_SINGLE");
          }}
        >
          <div className={styles.cornerIconBtn}>
            <div className={styles.cornerIconContainer}>
            <PersonIcon />
            </div>
            
          </div>
          <div className={styles.featureBackgroundContainer}>
            <PersonIcon />
          </div>
          <IonCardTitle className={styles.featureElementTitle}>
            {t("change.tosingleworker.mode")}
          </IonCardTitle>
          <IonCardSubtitle>
            <div className={styles.featureElementDescription}>
              {t("keepsworker.connected")}
            </div>
          </IonCardSubtitle>
        </IonCardHeader>
      )}
    </>
  );
}

export default SingleMultiWorkerModeToggler;
