import styles from "./ActionScreenAskMachineStopConfirmationButtons.module.scss";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton } from "@ionic/react";
import { t } from "i18next";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
  typeShow: any;
  enabled: any;
}

function ActionScreenAskMachineStopConfirmationButtons(props: IProps) {
  const {t} = useHxfTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={confirmationStyles.confirmationContainer}>
          <IonButton
            disabled={props?.enabled ? !props.enabled : true}
            className={confirmationStyles.confirmButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {props.typeShow === "CONFIRM" ? t("confirm") :  t("confirm")}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default ActionScreenAskMachineStopConfirmationButtons;
