import { IonButton, IonIcon, IonLoading } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useState } from "react";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import SimpleContentPopup from "../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";

import styles from "./MachineLongPressPopup.module.scss";

interface IProps{
    onClose:any;
    machineData:any;
}
function MachineLongPressPopup(props:IProps){

    const {t} = useHxfTranslation();
    const [didMount, setDidMount] = useState(false);

    console.log("MACHIN: ", props.machineData);
    return (
        <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}><div style={{position:'relative',right:-30}}>{t("machine.info")}</div></div>

              <IonButton
                    color="danger"
                    className={styles.closeWindowButton}
                    onClick={() => {
                    if (props?.onClose) {
                        props.onClose();
                    }
                    }}
                >
                    <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
            </div>
            <div className={styles.machTopInfo}>
                {props.machineData.name}</div>
            <div className={styles.content}>
              <div>

              </div>
              <div>
                {t('machine.adjustements')}
              </div>
              {props?.machineData?.adjustedToProductsData && props?.machineData?.adjustedToProductsData.length > 0 && (
                <>
                {props?.machineData?.adjustedToProductsData.map((objAdjustingToProduct:any, index:any) => (
                       <div key={"adjToProduct_" + index} className={styles.adjToProductContainer}>
                     
                                <div>
                                    <b>{objAdjustingToProduct.name}</b>
                                </div>
                                      
                                {objAdjustingToProduct?.arrayProductParameterizedVariables && objAdjustingToProduct.arrayProductParameterizedVariables.length > 0 && (
                                   <>
                                       <div className={styles.pvsContainer}>
                                           {objAdjustingToProduct?.arrayProductParameterizedVariables.map((objPv:any, pvIndex:any) => (
                                               <>
                                               <b>{objPv.name}:</b>{objPv.Value}{pvIndex < objAdjustingToProduct?.arrayProductParameterizedVariables.length -1 && (<>, </>)}
                                               </>
                                           ))}
                                       </div>
                                   </>
                               )}
                        
                             
                                {objAdjustingToProduct?.transformations && objAdjustingToProduct.transformations.length > 0 && (
                                    <>
                                        <div className={styles.transformationsContainer}>
                                        <div style={{margin:5}}>
                                                <div style={{textDecoration:'underline',marginBottom:5}}><b>{t('transformations')}:</b></div>
                                                        {objAdjustingToProduct?.transformations.map((objTransformation:any, transformationIdx:any) => (
                                                            <>
                                                                <div style={{borderWidth:1, borderRadius:10, borderStyle:"solid"}}>
                                                                    <div style={{margin:5}}>
                                                                    <b>{t('quantity')}:</b> {objTransformation.Quantity} <b>{t('product')}:</b> {objTransformation.name} 
                                                                        {objTransformation?.arrayProductParameterizedVariables && objTransformation?.arrayProductParameterizedVariables.length > 0 && (
                                                                            <>
                                                                                {objTransformation.arrayProductParameterizedVariables.map((objTransPPVV:any, idxTransPPVV:any) => (
                                                                                    <span key={"product_" + index + "_transf_" + transformationIdx + "_pv" + idxTransPPVV} style={{marginLeft:5}}>
                                                                                        <b>{objTransPPVV.name}:</b> {objTransPPVV.Value}
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </div>
                    
                                                                </div>
                    
                                                            </>
                                                        ))}
                                                  
        
                                                    </div>
                                            </div>
                                    </>
                                    )}
                         
                          
                
                       </div>
                ))}
                </>
              )}
             
                </div>
            </div>
        
        </div>
      </SimpleContentPopup>
    )

}

export default MachineLongPressPopup;