import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import styles from "./AppUnderMaintenance.module.scss";
import { Button } from "@mui/material";

function AppUnderMaintenance() {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.messageContainer}>
        <div className={styles.messageTitle}>Maintenance</div>
        <div className={styles.messageText}>
          We currently going under maintenance. <br></br>
          The service will be available as soon as possible.
        </div>
        <div className={styles.buttonRetry}>
          <Button
            onClick={() => {
              location.reload();
            }}
          >
            Reload Page
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AppUnderMaintenance;
