import { useEffect } from "react";
import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IAction {
  checklistsData: any;
}

interface IActionScreen {
  type: any;
  payload?: any;
}

const initialState = {
  checklistsData: {},
};

const reducer = (store: IAction, action: IActionScreen) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState, checklistsData: {} };
      return store;
    case "SET_CHECKLISTS_DATA":
      let newStore = { ...store, checklistsData: { ...action.payload } };
      store = { ...newStore };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    default:
      return store;
  }
};

export const [
  useActionScreenFillChecklistsStore,
  dispatchActionScreenFillChecklistsStore,
] = createStore(initialState, reducer);

export const manageActionScreenFillchecklistsStore = () => {
  const screenState = useActionScreenFillChecklistsStore();
  const getTest = () => {
    let aXk = screenState;

    return "test";
  };


  const getChecklistFieldObj = (
    idChecklist: number,
    idParameterizedVariable: number
  ) => {
    if (
      screenState?.checklistsData[
        getFormattedChecklistsDataID(idChecklist, idParameterizedVariable)
      ]
    ) {
      return  screenState?.checklistsData[
        getFormattedChecklistsDataID(idChecklist, idParameterizedVariable)
      ];
    }

    return null;
  };

  const getChecklistFieldValue = (
    idChecklist: number,
    idParameterizedVariable: number
  ) => {
    if (
      screenState?.checklistsData[
        getFormattedChecklistsDataID(idChecklist, idParameterizedVariable)
      ]
    ) {
      return screenState.checklistsData[
        getFormattedChecklistsDataID(idChecklist, idParameterizedVariable)
      ]?.value;
    }

    return null;
  };

  const setChecklistField = (
    idChecklist: number,
    idParameterizedVariable: number,
    value: any,
    varType:any,
    extraOpts?:any
  ) => {
    let newChecklistsData = { ...screenState.checklistsData };

    let newObj:any ={
      Id_Checklist: idChecklist,
      Id_ParameterizedVariable: idParameterizedVariable,
      value: value,
      varType:varType
    };

    if(extraOpts?._miniatureImgValue){
      newObj._miniatureImgValue = extraOpts._miniatureImgValue;
    }
    newChecklistsData[
      getFormattedChecklistsDataID(idChecklist, idParameterizedVariable)
    ] = newObj;
   
    dispatchActionScreenFillChecklistsStore({
      type: "SET_CHECKLISTS_DATA",
      payload: newChecklistsData,
    });
  };

  const getFormattedChecklistsDataID = (
    idChecklist: number,
    idParameterizedVariable: number
  ) => {
    return "idchk_" + idChecklist + "_idpv_" + idParameterizedVariable;
  };

  useEffect(() => {
    console.log("SCREENSTATE MODFD: ", screenState);
  }, [screenState]);
  return { getTest, setChecklistField, getChecklistFieldValue, getChecklistFieldObj };
};
