/**
 *
 * @returns the timestamp in seconds based on the browser time
 */
export function getCurrentTimestampSeconds() {
  var timeStamp = Math.floor(Date.now() / 1000);
  return timeStamp;
}

/**
 *
 * @param {seconds string or number, must be numeric int} sec_num
 * @returns hh:mm:ss
 */
export function numberSecondsToHHMMSS(sec_num) {
  sec_num = parseInt(sec_num, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}
