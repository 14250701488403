import { dispatchActionScreenManagerStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreensManager/ActionScreenManagerStore";
import { dispatchProductionOrderSelectionStore } from "../../pages/FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
function OperationActionService() {
  const closeOperationActionWindows = () => {
    dispatchActionScreenManagerStore({ type: "RESET_STORE" }); //closes all windows
    dispatchProductionOrderSelectionStore({ type: "RESET_ACTION_INDEX" }); //next time he presses finish again, opens first action window
  };

  return { closeOperationActionWindows };
}

export default OperationActionService;
