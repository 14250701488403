import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import ActionScreenTopBar from "../../../Components/ActionScreenTopBar";
import useActionScreenTopBar from "../../../Components/hooks/useActionScreenTopBar";
import styles from "./ActionRegisterProductSerializationSkip.module.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface IResult {
  irregularitysList: any;
}

interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}

function ActionRegisterProductSerializationSkip(props: IProps) {
  const actTopBar = useActionScreenTopBar();

  const {t} = useHxfTranslation();




  return (
    <>
      <div className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('register.product.serialization')}
        />

        <div className={styles.content}>
          <div className={styles.popupInfoQuestion}>

            {t('already.registered.product.serialization')}
          </div>

          <div style={{display:'flex',justifyContent:'center', color:'green',marginTop:10}}>
            <CheckCircleIcon fontSize="large"/>
          </div>

          <div className={`${styles.selectionOuter}`}></div>

        </div>
      </div>


      <div className={styles.confirmationButtons}>

        <div className={styles.confirmBtnContainer}>
            <IonButton
              className={styles.confBtn}
              onClick={() => {

                props.callbackSuccessConfirm();
              }}>
              <div className={styles.btnIcon}>
              
              </div>
              {t('continue')}
            </IonButton>

        </div>

      </div>

    </>
  );
}
export default ActionRegisterProductSerializationSkip;
