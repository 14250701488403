import CustomErrorNotice from "../CustomErrorNotice/CustomErrorNotice";
import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
function CustomUnavailableErrorNotice() {

  const {t} = useHxfTranslation();

  return (
    <CustomErrorNotice
      title={t("an.error.occurred")}
      description={t("failed.load")}
      secondDescription={t("reload.app.contact")}
      extraContent={
        <>
          <IonButton
            onClick={() => {
              location.reload();
            }}
          >
            {t("reload")}
          </IonButton>
        </>
      }
    />
  );
}

export default CustomUnavailableErrorNotice;
