import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import CustomElementGoBack from "../../../../../CustomElementGoback/CustomElementGoBack";
import TaskProgressBar from "../../../../../TaskProgressBar/TaskProgressBar";
import styles from "./MultiPopPopupProductionOrderProductSelectionSubscreen.module.scss";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import AvailableWorkTodoService from "../../../../../../../barrel/services/availableWorkTodoService";
import { useGlobalState } from "../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import { ASK_SELECT_MACHINE_PRODUCT_BY_PRODUCT } from "../../MultiPopProductionsStarterPopup";
import PreActionSelectMachine from "../../../../../../FeaturePages/Productions/Standard/ProductionInitiation/ProductionStage/PreActionViews/SelectMachine/PreActionSelectMachine";
import { useState } from "react";
import HighlightAlt from "@mui/icons-material/HighlightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomArrowBack from "../../../../../CustomArrowBack/CustomArrowBack";
interface IPropsSubSel {
  selectionData: any;
  onGoBack: any;
  selectedProductionOrderId: any;
  onSelectPops: any;
  onUnselectPops: any;
  onUpdatePops:any;
  isPopSelected: any;
  isArrayPopsSelected: any;
}
function MultiPopPopupProductionOrderProductSelectionSubscreen(
  props: IPropsSubSel
) {
  const {t} = useHxfTranslation();
  const {sessionState}  = useGlobalState();

  const DISABLE_ADD_ALL = true; //do not disable it or make it so if the products have to "select a machine" per product, then do not shwo this button.

  const [showPopupSelectMachine, setShowPopupSelectMachine] = useState<any>(null);
  const getProductionOrderLabel = () => {
    
    if (props.selectedProductionOrderId == -1) {
      return "";
    }

    for (let i = 0; i < props.selectionData.productionOrdersData.length; i++) {
      if (
        parseInt(
          props.selectionData.productionOrdersData[i].Id_ProductionOrder
        ) === props.selectedProductionOrderId
      ) {
        return props.selectionData.productionOrdersData[i].code;
      }
    }

    return "";
  };

  const getAdjustedMachineStatus2 = (obj:any) => {
    const ODD_COL = "#8b8bc7";
    const EVEN_COL = "#8bb3bf";
    if(sessionState.hasMachineAdjustedStatusEnabled()){
      let adjustedMachinesData = obj?.adjustedMachinesData;

      //split machines into categories
      let mapCategories_Machines:any = {};
      let mapCategories:any = {};
      for(let i = 0; i<adjustedMachinesData.length; i++){

        let categories = adjustedMachinesData[i]?.categories;
        if(categories && categories.length > 0){
          for(let j = 0; j<categories.length; j++){

            if(!mapCategories_Machines?.[categories[j].Id_MachineCategory]){
              mapCategories_Machines[categories[j].Id_MachineCategory] = {};
            }
            mapCategories_Machines[categories[j].Id_MachineCategory][adjustedMachinesData[i].Id] = adjustedMachinesData[i];
          
            mapCategories[categories[j].Id_MachineCategory] = categories[j];
          }
        }
      }


      let arrayMachineCategoriesWithAdjMachines = [];
      let categoryIds = Object.keys(mapCategories_Machines);
      for(let i = 0; i<categoryIds.length; i++){
        let categoryId = categoryIds[i];
        let machineCategoryData = mapCategories[categoryId];

        let catAdjMachinesIds = Object.keys(mapCategories_Machines[categoryId]);
        let arrayAdjMachines = [];
        for(let j = 0; j<catAdjMachinesIds.length; j++){
          let adjMachineId = catAdjMachinesIds[j];
          let adjMachineData = mapCategories_Machines[categoryId][adjMachineId];
          arrayAdjMachines.push(adjMachineData);
        }

        arrayMachineCategoriesWithAdjMachines.push({
          Id_MachineCategory:categoryId,
          adjustedMachinesArray:arrayAdjMachines
        });
      }

      if(adjustedMachinesData && adjustedMachinesData.length > 0){
        return (
                <div className={styles.machinfoContainer}>
                      <span><b>{t('adjusted.machines')}:</b></span>
                      <div className={styles.machboxesContainer}>
                        <div>
                          <div className={styles.machCategoriesContainer}>
                            {arrayMachineCategoriesWithAdjMachines.map((objCat, index) => (
                              <div className={styles.machCategoryContainer} key={"machCat_" + index}>
                                {objCat.adjustedMachinesArray.map((objMachine,indexMach) => (
                                  <div className={styles.machbox} data-adjusted-machine-code-status={objMachine.code} style={{backgroundColor:index % 2 == 0 ? EVEN_COL : ODD_COL}} key={index + "_adjMachine_"+indexMach}>
                                    <div style={{margin:3,whiteSpace:'nowrap'}}>{objMachine.code}</div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                </div>
              )
      }

    }
    return (<></>)
  }

  const getProductionOrderProductsProperArray = () => {
    if (props.selectedProductionOrderId == -1) {
      return [];
    }

    let pops = [];

    for (
      let i = 0;
      i < props.selectionData.productionOrderProductsData.length;
      i++
    ) {
      if (
        parseInt(
          props.selectionData.productionOrderProductsData[i].Id_ProductionOrder
        ) === props.selectedProductionOrderId
      ) {
        let productId = parseInt(
          props.selectionData.productionOrderProductsData[i].Id_Product
        );
        let productData =
          props.selectionData.productionOrderProductsData[i].productData;
        let productName = "";
        let productCode = "";

        let poppvvs =
          props.selectionData.productionOrderProductsData[i]
            .productionOrderProductsParameterizedVariableValues;
        if (poppvvs) {
          poppvvs = JSON.parse(poppvvs);
        }

        let ppvs =
          props.selectionData.productionOrderProductsData[i]
            .productParameterizedVariables;
        if (ppvs) {
          ppvs = JSON.parse(ppvs);
        }

        let pvs =
          props.selectionData.productionOrderProductsData[i]
            .parameterizedVariables;

        if (pvs) {
          pvs = JSON.parse(pvs);
        }

        let popId = parseInt(
          props.selectionData.productionOrderProductsData[i]
            .Id_ProductionOrder_Product
        );

        let popData = props.selectionData.productionOrderProductsData[i];

        pops.push({
          Id_Product: productId,
          name: productData.name,
          code: productData.code,
          Id_ProductionOrder_Product: popId,
          productionOrderProductsParameterizedVariableValues: poppvvs,
          productParameterizedVariables: ppvs,
          parameterizedVariables: pvs,
          adjustedMachinesData: popData?.adjustedMachinesData ? popData.adjustedMachinesData : null,
          Quantity:popData.Quantity,
          bomProducts:popData?.bomProducts ? popData.bomProducts : [],
          operationsData:popData?.operationsData ? popData.operationsData : []
        });
      }
    }

    return pops;
  };

  const getConsumptionsUI = (obj:any) => {
    //
    let showProductConsumptions = sessionState.userSessionData?.globalSettings?.featureExpander?.wsProductSelShowConsumptions === 1;

    if(showProductConsumptions && obj?.bomProducts &&  obj?.bomProducts?.length > 0){
      const showConsumptionsPVVS = false; //DO NOT enable, make a new UI flag if the client wants to see the consumption PVVS (to prevent affecting other clients UI)
   
      return (<div style={{color:'black',display:'flex',fontSize:13}}>
        <div style={{marginRight:3}}><b>{t('bom.short')}:</b></div>
        <div className={styles.bomProductsContainer}>
        {obj?.bomProducts?.map((bomProduct:any, indexBP:any) => (
          <div key={"bomproduct_" + indexBP} className={styles.bomSelProductContainer}>
            <span>{bomProduct.childProduct_name}</span>
             {(showConsumptionsPVVS && bomProduct?.arrayProductParameterizedVariables &&  bomProduct?.arrayProductParameterizedVariables.length > 0) && (
                <span className={styles.bomPVVS}>
                  [
                    {bomProduct?.arrayProductParameterizedVariables.map((objBOMPVV:any, indexBOMPVV:any) => (
                       <span key={"bomp_" + indexBP + "_pvv_" + indexBOMPVV}>
                          {objBOMPVV.name}:{objBOMPVV.Value}
                       </span>
                    ))}
                   
                  ]
                </span>
             )}
             {indexBP !== obj?.bomProducts?.length - 1 && (
              <>,</>
             )}
          </div>
        ))}
        </div>

      </div>)
    }
    return (<></>)
  }

  const getProductionOrderElement = (obj: any, index: number) => {
    let arrayProductPvs = [];
    if (
      obj?.productionOrderProductsParameterizedVariableValues &&
      obj?.productParameterizedVariables &&
      obj?.parameterizedVariables
    ) {
      for (
        let i = 0;
        i < obj.productionOrderProductsParameterizedVariableValues.length;
        i++
      ) {
        let found = false;
        let idProductParameterizedVariable =
          obj.productionOrderProductsParameterizedVariableValues[i]
            .Id_Product_ParameterizedVariable;
        let varValue =
          obj.productionOrderProductsParameterizedVariableValues[i].Value;
        if (!varValue) {
          varValue = "unspecified";
        }

        for (let j = 0; j < obj.productParameterizedVariables.length; j++) {
          if (
            obj.productParameterizedVariables[j]["Id"] ===
            idProductParameterizedVariable
          ) {
            let idParameterizedVariable =
              obj.productParameterizedVariables[j]["Id_ParameterizedVariable"];
            for (let k = 0; k < obj.parameterizedVariables.length; k++) {
              if (
                obj.parameterizedVariables[k].Id === idParameterizedVariable
              ) {
                arrayProductPvs.push({
                  varName: obj.parameterizedVariables[k].name,
                  varValue: varValue,
                });
                found = true;
                break;
              }
            }
            break;
          }
        }

        if (!found) {
          throw new Error("unexpected error, ppvv check, not found");
        }
      }
    }

    let totalOperationTimesDone = 0;
    let totalOperationQuantity = 0;
    let popOperationsData =
      props.selectionData.productionOrderProductsData[index].operationsData;

    let operationsAccessData = props.selectionData.operationsAccessData;
    let operationsWorkDoneInfo = AvailableWorkTodoService().getWorkAvailableAndWorkDoneInfo(popOperationsData,operationsAccessData);    
    
    let totalTimesDoneIgnoredOverflow = operationsWorkDoneInfo.totalTimesDoneIgnoredOverflow;
    let totalTimesMustBeDone = operationsWorkDoneInfo.totalTimesMustBeDone;
   

    return (
      <IonCardHeader
        className={`${styles.elemElement} ${
          props.isPopSelected(obj.Id_ProductionOrder_Product)
            ? styles.selectedActiveProductionElement
            : ""
        }`}
        style={{overflow:'hidden'}}
        onClick={() => {
          if (props.isPopSelected(obj.Id_ProductionOrder_Product)) {
            if (props?.onSelectPops) {
              let selected = [];
              selected.push(obj);

              props.onUnselectPops(selected);
            }
          } else {
            if (props?.onSelectPops) {
      
              let selectedOperationCode = props?.selectionData?.operationCode;
              let operationsData = obj?.operationsData;
              if(operationsData && ASK_SELECT_MACHINE_PRODUCT_BY_PRODUCT){
                for(let i = 0; i<operationsData.length; i++){
                  //check if the current selected operation code exists
                  if(operationsData[i].code.toLowerCase() === selectedOperationCode.toLowerCase()){
                    //if current operation with same code has "select machine"
                    if(operationsData[i].shopfloorSetting_selectMachine === 1){

                      setShowPopupSelectMachine({
                        selectedObj:obj,
                        Id_OperationProduct_Action:operationsData[i].Id,
                        Id_ProductionOrder_Product:obj.Id_ProductionOrder_Product
                      });
                    }
                  }
                }
              }
              
              //shopfloorSetting_selectMachine
              let selected = [];
              selected.push(obj);

              props.onSelectPops(selected);
            }
          }
        }}
      >
        <div style={{position:'absolute',top:27,left:-8,width:213}}>
                    
          <TaskProgressBar showMinimumFill={true} minimumFillPct={10} borderColor={"#dedcd7"} fillerColor={"#258d4f4d"} tasksDone={0} totalTasks={obj.Quantity} width={'100%'} progressBarContainerWidth={'125%'} height={20} fontSize={17} fillerRadius={'0px'}/>
        </div>
        <div className={styles.elemBackgroundContainer}>
          <HighlightAlt />
        </div>

        <IonCardTitle className={styles.elemElementDescription} style={{marginTop:0}}>
          <div>
            <div className={styles.labelsProductCap}>({obj.code})</div>
            <div className={styles.labelsProductCap}>{obj.name}</div>
          </div>
        </IonCardTitle>

        <IonCardSubtitle style={{marginTop:0}}>
          <div style={{height:'20px'}}>
            {arrayProductPvs.length > 0 && (
              <div>
                <div>{t('parameterizedvariables')}:</div>
                <div className={styles.ppvvsContainer}>
                  {arrayProductPvs.map((row, index) => (
                    <span key={"poppvs_" + index}>
                      <b>{row.varName}:</b>
                      {row.varValue}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

        </IonCardSubtitle>
          {getConsumptionsUI(obj)}
          {getAdjustedMachineStatus2(obj)}
          <div style={{position:'absolute', top:180,left:-8,width:264}}>
            <div className={styles.tskPrgBar}>
              {/*<div className={styles.tskPgrInfo}>
                {t('remaining.operation.executions')}
              </div>*/}
              <div className={styles.tskBarReposition}>
                <TaskProgressBar
                  tasksDone={totalTimesDoneIgnoredOverflow}
                  totalTasks={totalTimesMustBeDone}
                  width={'100%'}
                  height={20}
                  fontSize={17}
                  borderColor={"#dedcd7"}
                />
              </div>

            </div>
          </div>
      
      </IonCardHeader>
    );
  };

  const getAddAllUnselectAllButton = () => {
    let allPopsData = getProductionOrderProductsProperArray();

    let isAllAdded = props.isArrayPopsSelected(allPopsData);

    if(!isAllAdded && DISABLE_ADD_ALL){
      return (<></>)
    }

    return (
      <div className={`${styles.activeProductionElement}  `}>
        <IonCardHeader
          className={`${styles.elemElement}`}
          style={{overflow:'hidden'}}
          onClick={() => {
            if (isAllAdded) {
              //unselect all
              props.onUnselectPops(allPopsData);
            } else {
              //select all
              if (props?.onSelectPops) {
                let selected: any = [];

                for (let i = 0; i < allPopsData.length; i++) {
                  selected.push(allPopsData[i]);
                }

                props.onSelectPops(selected);
              }

              props.onGoBack();
            }
          }}
        >
          <div className={styles.addAllLabel}>
            {isAllAdded ? t('unselect.all') : t('add.all')}
          </div>
        </IonCardHeader>
      </div>
    );
  };

  if (
    !props?.selectionData?.productionOrdersData ||
    props.selectedProductionOrderId == -1
  ) {
    return <></>;
  }

  return (
    <>
    {showPopupSelectMachine && (
        <PreActionSelectMachine 
        Id_OperationProduct_Action={showPopupSelectMachine.Id_OperationProduct_Action}
        Id_ProductionOrder_Product={showPopupSelectMachine.Id_ProductionOrder_Product}
        onClose={() => {
          let selected = [];
          selected.push(showPopupSelectMachine.selectedObj);

          props.onUnselectPops(selected);
          
          setShowPopupSelectMachine(null);
        }}
        
        onFinish={(selectedMachine:any) => {
          let selected = [];

          let newSelectedObj = showPopupSelectMachine.selectedObj;
          newSelectedObj.selectedMachine = selectedMachine;
          selected.push(newSelectedObj);
          props.onUpdatePops(selected);
          setShowPopupSelectMachine(null);
          /*
          preActionsData.current = {
            ...preActionsData.current,
            selectedMachine:selectedMachine
          }
          dispatchPreActionsStore({
            type: "SET_PREACTIONS_DATA",
            payload: {
              ...preActionsStore.preActionsData, ...preActionsData.current
            },
          });
          //operationSelect(preActionsStore.pendingPreActionsOperation.obj, preActionsStore.pendingPreActionsOperation.index);
          startMultiProduction();*/
        }}

      />
    )}
      <div className={styles.selectedOperationInfo}>
        <div>{t('operation')}: {props.selectionData.operationCode}</div>
      </div>
      <div className={styles.selectedProductionOrderInfo}>
        <div>{t('order')}: {getProductionOrderLabel()}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          {t('add.all.products.to.work.on')}:
        </div>
        <div className={styles.operationsContainer}>
          
          <CustomArrowBack positioning="none" onClick={() => {
            props.onGoBack();
          }}/>

          <div className={styles.spaceArrow}></div>
          {getAddAllUnselectAllButton()}
          {getProductionOrderProductsProperArray().map(
            (row: any, index: number) => (
              <div
                key={"multiOperation_po_pop_" + index}
                className={`${styles.activeProductionElement}  `}
              >
                {getProductionOrderElement(row, index)}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default MultiPopPopupProductionOrderProductSelectionSubscreen;
