function ZebraService(){

    const generateZplFullText = (zplLinesArray:[]) => {
        let fullText = "";
        for(let i = 0; i<zplLinesArray.length; i++){
          if(i !== 0){
            fullText += "\n";
          }
          fullText += zplLinesArray[i];
        }
  
        return fullText;
        
    }

    const generateZplData = (fullText:any) => {

        return 'data:attachment/text,' + encodeURI(fullText);
    }

    const generateZplFile = (zplLinesArray:[]) => {

        let fullText = generateZplFullText(zplLinesArray);

        let hiddenElement = document.createElement('a');
  
        hiddenElement.href = generateZplData(fullText);
        hiddenElement.target = '_blank';
  
        let date:any = new Date();
        let ts:any = date.getTime()/1000;
        ts = parseInt(ts);
        let nameFile:any = "label_" + ts;
        hiddenElement.download = nameFile+ '.zpl';
        hiddenElement.click();

    }

    const getZebraFillingCharacter = () => {
      //ZEBRA_FILLING_CHAR
      return " ";
    }

    const clearFillingCharacter = (textScanResult:any) => {
      //removes the last characters = the getZebraFillingcharacter, example: if " " and text scan result is "ala la la   " it becomes "ala la la"
      let index = textScanResult.length - 1;
      while (textScanResult[index] === getZebraFillingCharacter()) {
        index--;
      }
      return textScanResult.substring(0, index + 1).trim();
    }

    return {clearFillingCharacter, generateZplFile,generateZplFullText,generateZplData};
}

export default ZebraService;