interface IPVServicePvsArrayComparable {
  Value: any;
  Id_Product_ParameterizedVariable: any;
}

function parameterizedVariablesService() {
  const compareStockMovementProductWithShippingProductPVVS = (
    stockMovementPPVVs: IPVServicePvsArrayComparable[],
    shippingOrderProductPvvs: IPVServicePvsArrayComparable[]
  ) => {
    if (stockMovementPPVVs.length !== shippingOrderProductPvvs.length) {
      return false;
    }

    for (let i = 0; i < stockMovementPPVVs.length; i++) {
      let smIdProductPV =
        stockMovementPPVVs[i].Id_Product_ParameterizedVariable;
      let smPVValue = stockMovementPPVVs[i].Value;

      let matchedParam = false;
      for (let j = 0; j < shippingOrderProductPvvs.length; j++) {
        let shpIdProductPv =
          shippingOrderProductPvvs[i].Id_Product_ParameterizedVariable;
        let shpPVValue = shippingOrderProductPvvs[i].Value;
        if (
          parseInt(smIdProductPV) === parseInt(shpIdProductPv) &&
          shpPVValue === smPVValue
        ) {
          matchedParam = true;
          break;
        }
      }

      if (!matchedParam) {
        return false;
      }
    }

    return true;
  };

  return { compareStockMovementProductWithShippingProductPVVS };
}

export default parameterizedVariablesService;
