import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function productComponentsController() {


    const getProductionOrderBoM = (idProductionOrder:any, options: any = {}) => {
    let requestUrl = "product-component/get-bom-by-production-order/" + idProductionOrder;
    return BarrelWrapper().appPostRequest(
        HxfAppServersService().getBackend() + requestUrl,
        { ...options }
    );
    };

    return { getProductionOrderBoM };
}

export default productComponentsController;
