import productionOrdersController from "../../../controllers/productionOrdersController";
import DaoData from "../daoData";
function ProductionOrdersDao() {
  const daoData = DaoData();

  const getProductionOrderById = (
    id: any,
    extraOptions: any = null,
    cancelToken: any
  ) => {
    let storageKey = "ProductionOrders_feature/production-order/" + id;

    if (extraOptions !== null) {
      if (extraOptions?.includePopDataByPopId) {
        storageKey = storageKey + "_incPopDataByPopId_" + id;
      }
    }

    return new Promise((resolve, reject) => {
      //"returnFirstOnly": true because there is only 1 object with key accessibleEntities
      daoData
        .accessData(
          () => {
            return productionOrdersController().getProductionOrderById(
              id,
              extraOptions,
              cancelToken
            );
          },
          {
            onlineCondition: {
              objectStorageStore: true,
              objectStorageKey: storageKey,
            },
            offlineCondition: {
              objectStorageFetch: true,
              objectStorageKey: storageKey,
              throwExceptionIfNotFound: true,
              returnFirstOnly: true,
            },
            offlineValidDuration: 3600 * 24 * 2,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  };

  const getActiveProductionOrders = (
    page: any,
    size: any,
    openOnly: any = true,
    getAllWorkerOnGoingProductions: any = false,
    getProductionOrdersSimultaneousOperations: any = false,
    getAllWorkerOnGoingMultiProductions: any = false,
    includePopsOperationsProgressData:any = false,
    cancelToken: any
  ) => {
    let openOnlyStr = openOnly ? "true" : "false";
    let getAllWorkerOnGoingProductionsStr = getAllWorkerOnGoingProductions
      ? "true"
      : "false";
    let getProductionOrdersSimultaneousOperationsStr =
      getProductionOrdersSimultaneousOperations ? "true" : "false";
    let getAllWorkerOnGoingMultiProductionsStr =
      getAllWorkerOnGoingMultiProductions ? "true" : "false";

    return new Promise((resolve, reject) => {
      //"returnFirstOnly": true because there is only 1 object with key accessibleEntities
      daoData
        .accessData(
          () => {
            return productionOrdersController().getActiveProductionOrders(
              {
                page: page,
                size: size,
                openOnly: openOnly,
                getAllWorkerOnGoingProductions: getAllWorkerOnGoingProductions,
                getProductionOrdersSimultaneousOperations:
                  getProductionOrdersSimultaneousOperationsStr,
                getAllWorkerOnGoingMultiProductions:
                  getAllWorkerOnGoingMultiProductionsStr,
                  includePopsOperationsProgressData:includePopsOperationsProgressData
              },
              cancelToken
            );
          },
          {
            onlineCondition: {
              objectStorageStore: true,
              objectStorageKey:
                "ProductionOrders_feature/production-orders/page_" +
                page +
                "_size_" +
                size +
                "_openOnly_" +
                openOnlyStr +
                "_getAllWorkerOnGoingProductions_" +
                getAllWorkerOnGoingProductionsStr,
            },
            offlineCondition: {
              objectStorageFetch: true,
              objectStorageKey:
                "ProductionOrders_feature/production-orders/page_" +
                page +
                "_size_" +
                size +
                "_openOnly_" +
                openOnlyStr +
                "_getAllWorkerOnGoingProductions_" +
                getAllWorkerOnGoingProductionsStr,
              throwExceptionIfNotFound: true,
              returnFirstOnly: true,
            },
            offlineValidDuration: 3600 * 24 * 2,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  };

  return { getActiveProductionOrders, getProductionOrderById };
}

export default ProductionOrdersDao;
