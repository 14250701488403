import { useAppOfflineContext } from "../../../contexts/AppOfflineProvider";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import useLocalStorageHandler from "../../hooks/useLocalStorageHandler";
import { getCurrentTimestampSeconds } from "../../utils/TimeUtils";
/**
 *
 *
 * Serves as middleware when getting data from the database, it checks if it has data in the app cache if the app is in offline mode
 *
 * To implement this into an endpoint call first we need to create the controller and then the Dao implementation
 *
 * e.g: productionOrdersController and productionOrdersDao
 *
 */
function DaoData() {
  const { appOffline } = useAppOfflineContext();
  const { sessionState } = useGlobalState();
  const { updateLatestOnlineActivityTs } = useLocalStorageHandler();

  /**
     * 
     * @param {*} onlinePromiseFunction 
     * @param {*} options 
     * 
     * options: 
       { 
            "onlineCondition":{
                "objectStorageStore":true or false,
                "objectStorageKey":"key"
            },
            "offlineCondition": {
                "objectStorageFetch": true or false,
                "objectStorageKey":"key",
                "appendPostDataPairsAsKey": ["workerIdentification","abcId"], 
                "returnFirstOnly":false,
                "responseTypeArray":false  if false returns in object form if true returns in array form
            },
            "offlineValidDuration": 0 (permanent, todo, not implemented), number integer ex: 3600 ( 3600 seconds )
       }
     */
  const accessData = (onlinePromiseFunction, options = {}) => {
    let offlineModeIsEnabled = false;

    return genericAccessData(
      () => {
        //online promise
        return new Promise((resolve, reject) => {
          let onlinePromise = onlinePromiseFunction();
          onlinePromise
            .then((result) => {
              if (options["onlineCondition"]) {
                if (options["onlineCondition"]["objectStorageStore"]) {
                  let key = options["onlineCondition"]["objectStorageKey"];

                  let tsExpiration = 0;
                  if (options["offlineValidDuration"]) {
                    let durationValid = 0;
                    durationValid = options["offlineValidDuration"];
                    tsExpiration = getCurrentTimestampSeconds() + durationValid;
                  }

                  let returnTypeArray = false;
                  if (Array.isArray(result.data.response)) {
                    returnTypeArray = true;
                  }

                  if (
                    "appendPostDataPairsAsKey" in options["offlineCondition"]
                  ) {
                    let postingData = JSON.parse(result.config.data);
                    let formedKeyExtra = appendPostDataPairsAsKey_keyForm(
                      postingData,
                      options
                    );
                    key = key + formedKeyExtra;
                  }
                  appOffline()
                    .genericOfflineObjectStorage.setVal(
                      key,
                      result.data.response,
                      {
                        returnTypeArray: returnTypeArray,
                        tsExpiration: tsExpiration,
                      }
                    )
                    .then(() => {
                      console.log("Finished storing data");
                      updateLatestOnlineActivityTs();
                    })
                    .catch((ex) => {
                      console.log("Failed to store data");
                      reject(
                        catchFailure(
                          "offline_fail",
                          "x2 - failed to store from offline db",
                          ex
                        )
                      );
                    });
                }
              }

              resolve(result);
            })
            .catch((ex) => {
              //JSON.stringify(ex) se quiser ver o objecto, o console log por algum motivo apenas mostra a mensagem de stack
              console.log("xA fail", JSON.stringify(ex));
              reject(catchFailure("online_response_error", "#x1 - failed", ex));
            });
        });
      },
      //offline function returning offline promise
      (err) => {
        return new Promise((resolve, reject) => {
          if (options["offlineCondition"] && offlineModeIsEnabled) {
            if (options["offlineCondition"]["objectStorageFetch"]) {
              let key = options["offlineCondition"]["objectStorageKey"];
              console.log(
                "THE GENERIC OFF STORAGE: ",
                appOffline().genericOfflineObjectStorage
              );

              let atest = appOffline().genericOfflineObjectStorage.getVal(key);
              console.log("THE atest: ", atest);
              console.log("THE GETTING ERR: ", err);

              if ("appendPostDataPairsAsKey" in options["offlineCondition"]) {
                let postingData = JSON.parse(
                  err?.error?.exceptionObj?.config?.data
                );
                let formedKeyExtra = appendPostDataPairsAsKey_keyForm(
                  postingData,
                  options
                );
                key = key + formedKeyExtra;
              }

              appOffline()
                .genericOfflineObjectStorage.getVal(key)
                .then((result) => {
                  console.log("RES OFF:", result);
                  //successfully accessed index db

                  let returnTypeArray = false;

                  if (result.length > 0) {
                    if (result[0]["returnTypeArray"] == true) {
                      returnTypeArray = true;
                    }
                  }

                  if (result.length == 0) {
                    //not found

                    if (
                      options["offlineCondition"]["throwExceptionIfNotFound"]
                    ) {
                      throw new Error("throwExceptionIfNotFound ");
                    }
                    let fictitiousResponse = {
                      metaDataInfo: "cached data from indexedDb",
                      data: { response: {} },
                    };

                    resolve(fictitiousResponse);
                  } else {
                    let resultData = [];
                    //handleMergeData
                    if (!options["offlineCondition"]["returnFirstOnly"]) {
                      for (var i = 0; i < result.length; i++) {
                        resultData.push({ ...result[i]["obj"] });
                      }
                    }
                    //
                    let fictitiousResponse = {};
                    if (
                      options["offlineCondition"]["returnFirstOnly"] &&
                      result.length > 0
                    ) {
                      let responseObject = {};
                      if (returnTypeArray) {
                        console.log("TYPE 1");
                        responseObject = [...result[0]["obj"]];
                      } else {
                        responseObject = { ...result[0]["obj"] };
                        console.log("TYPE 2");
                      }

                      fictitiousResponse = {
                        metaDataInfo: "cached data from indexedDb",
                        data: {
                          response: responseObject,
                          _indexdb_key: key,
                          _indexdb_tsExpiration: result[0]["tsExpiration"],
                        },
                      };
                    } else if (
                      !options["offlineCondition"]["returnFirstOnly"] &&
                      result.length > 0
                    ) {
                      let responseObject = {};
                      if (returnTypeArray) {
                        responseObject = [...resultData];
                      } else {
                        responseObject = { ...resultData };
                      }
                      fictitiousResponse = {
                        metaDataInfo: "cached data from indexedDb",
                        data: {
                          response: responseObject,
                          _indexdb_key: key,
                          _indexdb_tsExpiration: result["tsExpiration"],
                        },
                      };
                    }

                    resolve(fictitiousResponse);
                  }
                })
                .catch((ex) => {
                  //todo, what to do if error occurs when retrieving.
                  //fictitious error failed connection ??
                  console.log("FAIL:: ", ex);
                  //failed to access index db
                  reject(
                    catchFailure(
                      "offline_fail",
                      "#3 - failed to get from offline db",
                      ex
                    )
                  );
                });
            }
          } else {
            reject(catchFailure("load_fail", "failed to load #0"));
          }
        });
      }
    );
  };

  const appendPostDataPairsAsKey_keyForm = (postingData, options) => {
    let formingKey = "";

    if (options["offlineCondition"]["appendPostDataPairsAsKey"].length === 0) {
      //throw error
    }
    for (
      var i = 0;
      i < options["offlineCondition"]["appendPostDataPairsAsKey"].length;
      i++
    ) {
      let keySearch =
        options["offlineCondition"]["appendPostDataPairsAsKey"][i];
      if (postingData[keySearch]) {
        formingKey =
          formingKey + "_" + keySearch + "_" + postingData[keySearch];
      } else {
        //throw error
      }
    }

    return formingKey;
  };

  const catchFailure = (error_type, reason, exceptionObj = null) => {
    if (exceptionObj == null) {
      exceptionObj = {};
    } else if (typeof exceptionObj !== "object") {
      console.log("Exception obj received is not an object ", exceptionObj);
      exceptionObj = {};
    }
    return {
      error: {
        error_type: error_type,
        reason: reason,
        exceptionObj: exceptionObj,
      },
    };
  };

  /**
   * Generic method with implementable offlinePromiseRetriever
   *
   * We do not pass the promises directly to avoid "asynchronously execute it" without it beeing necessary
   * so a method is passed which will be executed when needed
   *
   * @param {*function which returns the desired promise} onlinePromiseRetriever
   * @param {*function which returns the desired promise} offlinePromiseRetriever
   * @returns
   */
  const genericAccessData = (
    onlinePromiseRetriever,
    offlinePromiseRetriever
  ) => {
    return new Promise((resolve, reject) => {
      let offlineModeIsEnabled = false;
      //if online attempt to get content
      if (appOffline().isConnectionAvailable()) {
        console.log("YES connection is available");
        let onlinePromise = onlinePromiseRetriever();
        return onlinePromise
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            //occurrs when online but an error occured
            console.log("promise catch->", err);

            //check the error
            //if error is because network is offline then allow offline storage load
            //if error is because server is unavailable same.
            //else, do not allow
            let isOfflineAcceptableError = false;
            //integrate here accepted errors considered offline (while online)

            //no server response
            if (
              err?.error?.exceptionObj?.request?.status === 0 &&
              err?.error?.exceptionObj?.response === undefined
            ) {
              isOfflineAcceptableError = true;
            }

            //maintenance detected / temporarily offline
            if (err?.error?.exceptionObj?.response?.status === 503) {
              isOfflineAcceptableError = true;
            }

            if (isOfflineAcceptableError && offlineModeIsEnabled) {
              //if fails to get online content, try to get it from offline db
              let offlinePromise = offlinePromiseRetriever(err);
              return offlinePromise
                .then((result) => {
                  resolve(result);
                })
                .catch((ex) => {
                  //if it fails to get it from offline db
                  reject(
                    catchFailure(
                      "offline_fail",
                      "#1 - failed to get from offline db",
                      ex
                    )
                  );
                });
            } else {
              console.log("HTPTERRx:", err);
              
              if (
                err?.error?.error_type &&
                err?.error?.reason &&
                err?.error?.exceptionObj
              ) {
                //copy error of the previous catch
                console.log("catch transformed into other catch");
                reject(
                  catchFailure(
                    err.error.error_type,
                    "failed to load #1",
                    err.error.exceptionObj
                  )
                );
              } else {
                reject(catchFailure("load_fail", "failed to load #1", err));
              }
            }
          });
      } else {
        if (offlineModeIsEnabled) {
          console.log("No, connection is not available");
          //if not online try to ge it from offline db
          let offlinePromise = offlinePromiseRetriever();
          return offlinePromise
            .then((result) => {
              resolve(result);
            })
            .catch((ex) => {
              //if it fails to get it from offline db
              reject(
                catchFailure(
                  "offline_fail",
                  "#2 - failed to get from offline db",
                  ex
                )
              );
            });
        } else {
          reject(catchFailure("load_fail", "failed to load #2"));
        }
      }
    });
  };

  return { accessData };
}

export default DaoData;
