import { Route } from "react-router";
import { Redirect } from "react-router";

import { useGlobalState } from "../GlobalCustomStateManagement/GlobalStateProvider";

function GuestOnlyRouteContext({ component: Component, authed, ...rest }: any) {
  const { sessionState } = useGlobalState();

  return (
    <Route
      {...rest}
      render={(props) =>
        sessionState.isUserLoggedIn() !== true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/home" }} />
        )
      }
    />
  );
}

export default GuestOnlyRouteContext;
