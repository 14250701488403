import styles from "./ActionScreenFillChecklistsConfirmationButtons.module.scss";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";
import actionScreenStyles from "./ActionScreenFillChecklists.module.scss";
import { t } from "i18next";
interface IProps {
  onClickConfirm: any;
  typeShow: any;
}

function ActionScreenFillChecklistsConfirmationButtons(props: IProps) {
  const getButtonLabel = () => {
    if (props.typeShow === "CONFIRM") {
      return t("confirm");
    }
    if (props.typeShow === "NEXT") {
      return t("next");
    }
    if (props.typeShow === "PREVIOUS") {
      return t("previous");
    }

    return t("none");
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <div className={`${styles.customConfirmationContainer} `}>
          <IonButton
            className={confirmationStyles.confirmButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {props.typeShow === "PREVIOUS" && (
              <IonIcon slot="start" icon={caretBackOutline} />
            )}{" "}
            {getButtonLabel()}{" "}
            {props.typeShow === "NEXT" && (
              <IonIcon slot="end" icon={caretForwardOutline} />
            )}{" "}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default ActionScreenFillChecklistsConfirmationButtons;
