import {
    IonButton, IonIcon, IonLoading
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import operationProductsController from "../../../../../../../../barrel/controllers/operationProductsController";
import useFeedbackService from "../../../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import ActionScreenMachineElement from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachine/ActionScreenMachineElement";
import ActionScreenAskUseMachineCurrentConfirmationButtons from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/ActionScreenAskUseMachineCurrentConfirmationButtons";
import ActionScreenMachineCurrentElement from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/ActionScreenMachineCurrentElement";
import MachineIdentifierScanner from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/MachineIdentifierScanner/MachineIdentifierScanner";
import SegmentGoBack from "../../../../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskUseMachineCurrent/SegmentGoBack/SegmentGoBack";

  
  import SimpleContentPopup from "../../../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import { dispatchPreActionsStore, usePreActionsStore } from "../../../preActionsStore";
import PreActionNewOrResumeConfirmationButtons from "./PreActionNewOrResumeConfirmationButtons";
import styles from "./PreActionNewOrResumeOperation.module.scss";

  interface IPopup {
    onFinish:any;
    onClose:any;
  }
  function PreActionNewOrResumeOperation(props: IPopup) {
  
    const { sessionState } = useGlobalState();
    
    const [didMount, setDidMount] = useState(false);


    const feedbackService = useFeedbackService();

    const {t} = useHxfTranslation();
    const [disableConfirmationButtons, setDisableConfirmationButtons] = useState(false);


    return (
      <div className={styles.intPopup}>
        
        <SimpleContentPopup mode="INNER">
          <div>
            <div className={styles.container}>
              <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
              >
                <div></div>
  
                <div className={styles.title}>{t('resume.or.start.new')}</div>
  
                <IonButton
                  color="danger"
                  className={styles.closeWindowButton}
                  onClick={() => {
                    if (props?.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
              </div>
              <div
                data-content="true"
                className={`popup-content-border-sides-only-styles ${styles.content}`}
              >
                <div>
                 
                    <div className={styles.scantip}>
                      {t('wish.to.select.new.or.continue')}
                    </div>
                    <div className={styles.objsContainer} data-content="true">
                    
                           
                    </div>
                </div>
              </div>

   
            </div>

            <PreActionNewOrResumeConfirmationButtons
                  onClickNew={() => {
                    props.onFinish("NEW");
                  }}
                  onClickContinuePrev={() => {
                    props.onFinish("CONTINUE_PREV");
                  }}
                 
               
                />
          </div>
        </SimpleContentPopup>
      </div>
    );
  }
  
  export default PreActionNewOrResumeOperation;
  