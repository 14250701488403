import { IonSpinner, useIonAlert } from "@ionic/react";
import { styled } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useOngoingUnavailabilityOccurrenceHook from "../../../../FeaturePages/OnGoingUnavailabilityOccurrence/store/onGoingUnavailabilityOccurrenceStore";
import HxfIonLoading from "../../../HxfIonLoading/HxfIonLoading";
import UnavailabilityActionFillChecklistConfirmationButtons from "./ConfirmationButtons/UnavailabilityActionFillChecklistConfirmationButtons";
import styles from "./UnavailabilityActionFillChecklist.module.scss";
import { Checkbox, TextField } from "@mui/material";
import HxfSelectorField from "../../../HxfSelectorField/HxfSelectorField";
interface IProps{
    onConfirm?:any;
    onCancel?:any;
}

export const getUnavailabilityActionFillChecklist = () => {
    return "UNAVACT_FILL_CHECKLIST";
}

const StyledRadio = styled(Radio)(({ theme }) => ({

    "& .MuiSvgIcon-root": {
      width:45,
      height:45
    },
  
  }));

function UnavailabilityActionFillChecklist(props:IProps){

    const [present] = useIonAlert();
    const [adjustingToProducts, setAdjustingToProducts] = useState<any>([null]);
    const [didMount, setDidMount] = useState(false);
    const prevDidMount = usePrevious(didMount);
    const {t} = useHxfTranslation();
    const occurrenceState = useOngoingUnavailabilityOccurrenceHook();
    const {sessionState} = useGlobalState();
    const [returningObj, setReturningObj] = useState<any>({comment:null});
    
    const [isLoading, setIsLoading] = useState(true);

    const [checklistFields, setChecklistFields] = useState<any>([]);
    const [mapErrors, setMapErrors] = useState<any>({});
 

    const getChecklistNaming = () => {

        let occurrence = occurrenceState.getCurrentUnavailabilityAction();
        let name = occurrence?._actionData?.checklistName;

        if(name){
            return (<div className={styles.nameCl}>{name}</div>)
        }

        return (<></>)
    }
    const getChecklistPvValue = (
        indexPv: number,
        varType: string
      ) => {
     
    
  
    
        let idParameterizedVariable = checklistFields[indexPv]["Id_ParameterizedVariable"];
    
        let val = checklistFields[indexPv]?.Value;

        if (val === null || val === undefined) {
          if (varType === "text") {
            return "";
          }
          if (varType === "numeric") {
            return "";
          }
          if (varType === "checkbox") {
            return false;
          }
          if (varType === "optionselect") {
            return null;
          }
          return null;
        } else {
          if (varType === "optionselect") {
            return { label: val, value: val };
          }
        }
    
        return val;
      };
    
      const getFieldVarType = (
        objPv: any,
        indexPv: number
      ) => {
        let varName = objPv.name;
        let isRequiredCheck = objPv?._config?.isRequiredCheck;
        if (objPv.varType === "text") {
          return (
            <div>
              <TextField
                disabled={false}
                error={mapErrors?.[indexPv]}
                onChange={(evt) => {
                    let newChecklists:any = [...checklistFields];
                    newChecklists[indexPv].Value = evt?.target?.value;

                    setChecklistFields(newChecklists);
                }}
                type={"text"}
                value={getChecklistPvValue(indexPv, objPv.varType)}
                fullWidth={true}
                label={varName}
                variant="outlined"
              />
            </div>
          );
        }
    
        if (objPv.varType === "numeric") {
          return (
            <div>
              <TextField
                disabled={false}
                error={mapErrors?.[indexPv]}
                onChange={(evt) => {


                  let newChecklists:any = [...checklistFields];
                  newChecklists[indexPv].Value = evt?.target?.value;

                  setChecklistFields(newChecklists);
                }}
                type={"number"}
                value={getChecklistPvValue(indexPv, objPv.varType)}
                fullWidth={true}
                label={varName}
                variant="outlined"
              />
            </div>
          );
        }
    
        if (objPv.varType === "checkbox") {
          return (
            <div className={styles.checkboxCustom}>
              <div className={styles.checkboxCustomInner}>
              <FormControlLabel control={ <Checkbox
                
                required={isRequiredCheck}
                  color={"primary"}
                  style={{
                    color: mapErrors[indexPv] ? "#e82301" : "#bdbdbd", //doesFieldHaveError(objPv, idChecklist) ? "#cd1b1b26" : "#ffffff00",
                    transform: "scale(2)",
               
                  }}
                  onChange={(evt) => {
                    let newVal = evt.target.checked;
                    //clear error
                    let newCurMap = { ...mapErrors };
                    if (
                      newCurMap[indexPv]
                    ) {
                      newCurMap[indexPv] =
                        false;
                      setMapErrors(newCurMap);
                    }
    
                    console.log("ONCHG: ", evt.target.checked);
        
                    let newChecklists:any = [...checklistFields];
                    newChecklists[indexPv].Value = evt.target.checked;

                    setChecklistFields(newChecklists);
                  }}
                  checked={getChecklistPvValue(
                    indexPv,
                    objPv.varType
                  )}
                />} label={(<div className={styles.pvCheckLabel}>{objPv.name}</div>)} />


              </div>
            </div>
          );
        }
    
        if (objPv.varType === "optionselect") {
          let optionsSelectableArr = objPv.varTypeOptionSelect_Opts.split(",");
    
          let defaultOptionsObj = [];
          for (let i = 0; i < optionsSelectableArr.length; i++) {
            defaultOptionsObj.push({
              label: optionsSelectableArr[i],
              value: optionsSelectableArr[i],
            });
          }
    
          return (
            <div className={styles.customChecklistComboSelectOptions}>
              <HxfSelectorField
                error={mapErrors?.[indexPv]}
                uniqueId={"hxfsel_" + indexPv}
                disabled={false}
                labelPlaceholder={varName}
                onChange={(evt:any, val:any) => {
                  console.log("ONCHG: ", val);
                  if (!val) {
                    val = null;
                  }
                  //setChecklistPvValue(indexChecklist, indexPv, val, objPv.varType);
    
                  let newChecklists:any = [...checklistFields];
                  newChecklists[indexPv].Value = val?.value;

                  setChecklistFields(newChecklists);
                  //clear error
                  let newCurMap = { ...mapErrors };
                  if (
                    newCurMap[indexPv]
                  ) {
                    newCurMap[indexPv] =
                      false;
                    setMapErrors(newCurMap);
                  }
                }}
                value={getChecklistPvValue(indexPv, objPv.varType)}
                defaultItems={defaultOptionsObj}
              />
            </div>
          );
        }
    
        return <>not implemented</>;
      };
   


    useEffect(() => {
        if(!didMount){
            let currentActionData = occurrenceState.getCurrentUnavailabilityAction();
      
            let checklistFieldsData = currentActionData?._actionData?.checklistFields;
            setChecklistFields(checklistFieldsData);
              
            setDidMount(true);
        }
    },[didMount,occurrenceState,props,returningObj,sessionState]);

    useEffect(() => {
        //after mounting
        if(!prevDidMount && didMount){

 

            setIsLoading(false);
        }
    }, [didMount,occurrenceState,props,returningObj,sessionState, prevDidMount]);


    if(isLoading){
        return (
            <>
             
               <div className={styles.content}>
                <div style={{marginBottom:50, marginTop:50}}>
                   
                    <IonSpinner/>
                    
                </div>
            </div>
            </>
        )
    }


    return (
        <>
            <div className={styles.content}>
                <div style={{margin:40}}>
                    <div>
                        {getChecklistNaming()}
                    </div>

                    {checklistFields.map((objField:any, index:any) => (
                        <div key={"chk_" + index} className={styles.inputContainer}>
                            {getFieldVarType(objField,index)}
                        </div>
                    ))}
                </div>
            </div>
            <div>
               <UnavailabilityActionFillChecklistConfirmationButtons
                disabled={false}
                onConfirm={() => {
                    //validate 
                    let hasErrors = false;
                    let newMapErrors:any = {};
                
                    for(let i = 0; i<checklistFields.length; i++){
                        let isRequired = checklistFields[i]?._config?.isRequiredCheck
                        if(isRequired && !checklistFields[i]?.Value){

                            hasErrors = true;
                            newMapErrors[i] = true;
                        }
                    }

                    if(hasErrors){
                        setMapErrors(newMapErrors);
                        return;
                    }

                    present({
                        cssClass: "my-css",
                        header: t("checklist.confirmation"),
                        message: t("checklist.confirmation.msg"),
                        buttons: [
                          t("cancel"),
                          {
                            text: t("yes"),
                            handler: (d:any) => {

                                let clearedChecklistData = [];
                                for(let i = 0; i<checklistFields.length; i++){
                                    let clValue = checklistFields[i]?.Value;

                                    if(checklistFields[i]?.varType === "checkbox"){
                                      if(clValue === true){
                                        clValue = "true";
                                      }else if(!clValue){
                                          clValue = "false";
                                      }
                                    }else if(!checklistFields[i]?.Value){
                                      clValue = null;
                                    }
     
                                    clearedChecklistData.push({
                                        Value:clValue,
                                        Id_ParameterizedVariable: checklistFields[i].Id_ParameterizedVariable
                                    });
                                }

                                let occurrence = occurrenceState.getCurrentUnavailabilityAction();
                                
                                let idChecklist = occurrence?._actionData?.Id_Checklist;
                                props.onConfirm({
                                    Id_Checklist:idChecklist,
                                    checklistFieldsData:{...clearedChecklistData}
                                });
                         
      
                            },
                          },
                        ],
                        onDidDismiss: (e:any) => {},
                      });
                   //yes or no
           
                }}
                />
            </div>
        </>
    )
}

export default UnavailabilityActionFillChecklist;