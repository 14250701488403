import { useIonAlert, useIonToast } from "@ionic/react";
import { closeCircle, homeOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import productionController from "../../../barrel/controllers/productionController";
import unavailabilityOccurrencesController from "../../../barrel/controllers/unavailabilityOccurrencesController";
import { useCancelToken } from "../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../../barrel/hooks/usePrevious";
import usePunchclock from "../../../barrel/hooks/usePunchclock";
import useSyncSettings from "../../../barrel/hooks/useSyncSettings";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import ProductionTopInfo from "../../CustomElements/ProductionUI/ProductionTopInfo/ProductionTopInfo";
import TimeElapsedLabel from "../../CustomElements/TimeElapsedLabel/TimeElapsedLabel";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import { dispatchProductionUIStore, useProductionUIStore } from "../ProductionOrders/Standard/store/productionConfirmationUIStore";
import { dispatchProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../ProductionOrders/Standard/store/productionOrderSelectionStore";
import CriticalErrorPage from "../../ErrorPages/CriticalErrorPage/CriticalErrorPage";

import CornerButton from "../Productions/Standard/ProductionProcess/CornerButton/CornerButton";
import styles from "./OnGoingUnavailabilityOccurrence.module.scss";
import UnavailabilityTopInfo from "./UnavailabilityTopInfo/UnavailabilityTopInfo";
import { dispatchDowntimesFooterUIStore } from "../../CustomElements/DowntimesFooterUI/downtimesFooterUIStore";
import useOngoingUnavailabilityOccurrenceHook from "./store/onGoingUnavailabilityOccurrenceStore";
import TimeElapsedLabelV2 from "../../CustomElements/TimeElapsedLabelV2/TimeElapsedLabelV2";

function OnGoingUnavailabilityOccurrence(props: any) {
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const localStorage = useLocalStorageHandler();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "ProductionStartStage",
  });

  const {t} = useHxfTranslation();
  const syncSettings = useSyncSettings();
  const punchclock = usePunchclock();
  const timerDispatch = useRef(setTimeout(() => {}, 0));

  const {setupOccurrence,resetOccurrence} = useOngoingUnavailabilityOccurrenceHook();
  const myLocalStorage = useLocalStorageHandler();
  const unavOccurrenceId = parseInt(props.match.params.unavOccurrenceId);

  const [didMount, setDidMount] = useState(false);

  const currentProductionUIStoreData = useProductionUIStore();
  const currentProductionOrderSelectionData =
    useProductionOrderSelectionStore();
  
  const { appState } = useGlobalState();
  const [showElapsedTimer, setShowElapsedTimer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedUnavailabilityOccurrence, setLoadedUnavailabilityOccurrence] = useState<any>(null);
  const feedbackService = useFeedbackService();
  const [showErrorLoading, setShowErrorLoading] = useState(false);
  const [downtimeObject, setDowntimeObject] = useState<any>(null);

  const [isWorkerDowntime,setIsWorkerDowntime] = useState(false);


  const loadCurrentUnavOccurrence = useCallback(() => {
    clearTimeout(timerDispatch.current);

    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    if(appState.isConnectionAvailable()){
        unavailabilityOccurrencesController().getByIdUnavailabilityOccurrence({includeMachineAdjustments:true},unavOccurrenceId).then((res:any) => {
       
            let unavOccurrence = res?.data?.response?.data;
            if(!unavOccurrence.Id){
                throw "unexpected unav occurrence data";
            }
          
 

            let isMachineDowntime = unavOccurrence?.machinesData.length > 0;
            let isWorkersDowntime = unavOccurrence?.workers.length > 0;
            if(isMachineDowntime){
              setDowntimeObject({Id_Machine:unavOccurrence?.machinesData[0].Id, name:unavOccurrence?.machinesData[0].name, code:unavOccurrence?.machinesData[0].code});

            }else if(isWorkersDowntime){
              setDowntimeObject({Id_Worker:unavOccurrence?.workers[0].Id, name:unavOccurrence?.workers[0].name, code:unavOccurrence?.workers[0].code});
              setIsWorkerDowntime(true);
            }else{
              throw "unexpected unav  type";
            }
          
            setLoadedUnavailabilityOccurrence(unavOccurrence);
           
            setupOccurrence(unavOccurrence);
            dispatchDowntimesFooterUIStore({
              type: "SET_FINALIZER_DATA",
              payload: {showFooter:true}, //,Id_UnavailabilityOccurrence:unavOccurrence.Id
            });
            setIsLoading(false);
        }).catch((res:any) => {
       
            feedbackService.notifyToast(t('generic.critical.error'), "error");
            setShowErrorLoading(true);
        });
    }
    

  }, [
    localStorage,
    appState,
    unavOccurrenceId,
    t,
    feedbackService,
    setupOccurrence
  ]);

  useEffect(() => {
    return () => {
      //cleanup, close finalizer
      resetOccurrence();
      dispatchDowntimesFooterUIStore({type:'SET_SHOW_STATE', payload:false});
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!didMount) {
      //triggers on load
      loadCurrentUnavOccurrence();
      setDidMount(true);
    }

    () => {
      console.log("umounting process");
      clearTimeout(timerDispatch.current);
    };
  }, [didMount, loadCurrentUnavOccurrence]);

  const getLabelOne = () => {
    if(isWorkerDowntime){
      return t('worker.downtime');
    }
    if(downtimeObject){

      return downtimeObject.code;
    }
    return "";
  }

  const getLabelTwo = () => {
    return loadedUnavailabilityOccurrence?.unavailabilityData?.code;
  }

  if(showErrorLoading){
    return (<CriticalErrorPage/>)
  }
  if (isLoading || !loadedUnavailabilityOccurrence) {
    return (
      <InAppTemplate>
        
        <UnavailabilityTopInfo/>
        <CustomArrowBack pushUrl="/feature/production-orders" />
        <LoadingSpinnerImg />
        
      </InAppTemplate>
    );
  }

  console.log(
    "current production order selection data:",
    currentProductionOrderSelectionData
  );

  return (
    <InAppTemplate>
      <>
        <UnavailabilityTopInfo labelOne={getLabelOne()} labelTwo={getLabelTwo()} />

        {currentProductionUIStoreData.showOperationOverlay && (
          <div className={styles.overlayContainer}></div>
        )}

        <div className={styles.topPageContainer}>
          <div></div>
          <div className={styles.pageTitle}>{t('downtime')}</div>
          <div className={styles.cornerButtons}>
            <CornerButton
              label={t("home")}
              onClick={() => {
                present({
                  cssClass: "my-css",
                  header: t("minimize"),
                  message: t("minimize.downtime.continue.later"),
                  buttons: [
                    t("cancel"),
                    {
                      text: t('ok'),
                      handler: (d) => {
                        setShowElapsedTimer(false);

                        props.history.push("/");
                      },
                    },
                  ],
                  onDidDismiss: (e) => {},
                });
              }}
              iconPassed={homeOutline}
            />
            <CornerButton
              onClick={() => {
                present({
                  cssClass: "my-css",
                  header: t("downtime.cancellation"),
                  message: t("downtime.cancel.confirm"),
                  buttons: [
                    t("cancel"),
                    {
                      text: t("yes"),
                      handler: (d) => {
                        let currentWorkerCode =
                          localStorage.getWorkerIdentificationCode();

                        
                          let idUnavailabilityOccurrence = unavOccurrenceId;//downtimesFooterUiStore?.Id_UnavailabilityOccurrence;
                          let workerCode = myLocalStorage.getWorkerIdentificationCode();
                              unavailabilityOccurrencesController().finishUnavailabilityOccurrence({workerCode:workerCode},idUnavailabilityOccurrence).then((res) => {
                        
                                  if(res?.data?.code === 200){
                                      feedbackService.notifyToast(t('canceled.downtime.successfully'),"success");
                                      props.history.push("/");
                                  }
                              }).catch((res) => {
                                  feedbackService.notifyToast(t('failed.error.generic'),"error",{duration:2500});
                                  if(isWorkerDowntime)
                                  {
                                    props.history.push("/");
                                  }else{
                                    props.history.push("/feature/machines");
                                  }
                                 
                              });

                      },
                    },
                  ],
                  onDidDismiss: (e) => {},
                });
              }}
              btnType={"danger"}
              label={t("cancel")}
              iconPassed={closeCircle}
            />
          </div>
        </div>

        <div className={styles.productionStartPreviewContainer}>
          <div className={styles.elemElement}>
            <div className={styles.elemTitle}>
  
              <div style={{width:'100%', height:'100%'}}>
              
                
                  <div className={styles.operationTakingTime}>
                    {!isLoading && (
                      <>
                      <TimeElapsedLabelV2 timerEnabled={true} startFromSeconds={loadedUnavailabilityOccurrence._elapsedSeconds}/>
                      {/*<TimeElapsedLabel
                      timerEnabled={
                        true
                      }
                      startDateTime={
                        loadedUnavailabilityOccurrence.dateStart
                      }
                    />*/}
                      </>

                    )}

                  </div>
               
              </div>
            </div>

          </div>
        </div>
      </>
    </InAppTemplate>
  );
}
export default withRouter(OnGoingUnavailabilityOccurrence);
