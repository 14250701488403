import { IonCardHeader, IonCardTitle } from "@ionic/react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import LoopIcon from '@mui/icons-material/Loop';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLongPress } from 'use-long-press';
import machinesController from "../../../barrel/controllers/machinesController";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import useMachineManagementShortcutReceiver from "../../../barrel/hooks/useMachineManagementShortcutReceiver/useMachineManagementShortcutReceiver";
import usePunchclock from "../../../barrel/hooks/usePunchclock";
import useSyncSettings from "../../../barrel/hooks/useSyncSettings";
import LoadingSpinnerImg from "../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import MachineLongPressPopup from "./MachineLongPressPopup/MachineLongPressPopup";
import styles from "./MachinesManagement.module.scss";

function MachinesManagement(props:any) {
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const feedbackService = useFeedbackService();
  const timerRecalc = useRef<any>(null);
  const [loadedMachines, setLoadedMachines] = useState([]);
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const {t} = useHxfTranslation();
  const punchclock = usePunchclock();
  const myLocalStorage = useLocalStorageHandler();
  const syncSettings = useSyncSettings();
  const machineManagementShortcutReceiver = useMachineManagementShortcutReceiver();
  const [showPopupMachineLongPress, setShowMachineLongPress] = useState<any>(null);
  const history:any = useHistory();
  const bind = useLongPress((evt,data:any) => {
    let selectedMachine = data.context;

    setShowMachineLongPress(selectedMachine);
  },{threshold:1000});

  const loadPageChecks = useCallback(() => {
  

    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    machinesController().getMachineStatus({workerCode:currentWorkerCode}).then((res:any) => {
      syncSettings.check(res);
      if(!res?.data?.response?.machinesData && res?.data?.response?.machinesData !== null){
        throw "unexpected response";
      }
      let machinesData = res?.data?.response?.machinesData ? res?.data?.response?.machinesData : [];
      let requiresPunchclockIn = res?.data?.response?.requirePunchclockIn;
      if(requiresPunchclockIn) {
        punchclock.redirRequirePunchclockIn();
        return;
      }
      setLoadedMachines(machinesData);
      setLoadingPage(false);
    }).catch((res) => {
  
      setLoadingPage(false);
      feedbackService.notifyToast(t('generic.critical.error'), "error");
    });

  }, [setLoadingPage, feedbackService, t, punchclock,myLocalStorage,syncSettings]);

  useEffect(() => {
    if (!didMount) {
      loadPageChecks();
      timerRecalc.current = setInterval( () => {
        loadPageChecks();
      },1000*10);

      if(!history?.location?.state?.preserveShortcutData){
        machineManagementShortcutReceiver.resetShortcutData();
      }
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  useEffect(() => {
    return () => {
        if(timerRecalc.current){
            console.log("clearing interval");
            clearInterval(timerRecalc.current);
        }
    }
  /* eslint-disable-line */
  },[]);

  const getAdjustedToProductsElement = (machineObj:any) => {
 
    if(!sessionState.hasMachineAdjustedStatusEnabled()){
      return (<></>)
    }
    let machineStatus = getMachineStatus(machineObj);
    if(machineStatus === "UNAVAILABLE"){
      return (<></>)
    }
    let adjustedToProductsData = machineObj?.adjustedToProductsData;
    if(adjustedToProductsData && adjustedToProductsData.length > 0){
      let hasMoreThanOneProducts = adjustedToProductsData.length > 1;
      let remainingOtherAdjustedProductsCount = adjustedToProductsData.length - 1;
      return (<div className={styles.infoContainer}>
         <span><b>{t('adjusted.to')}:</b></span>
         {adjustedToProductsData.slice(0,1).map((objProduct:any,index:any) => (

          <div className={styles.mmadjProductContainer} key={"adjp_" + index}>
            <div style={{margin:5}} className={styles.productCode}>{objProduct.code}</div>
            <div>
              {objProduct?.arrayProductParameterizedVariables && objProduct?.arrayProductParameterizedVariables.length > 0 && (
                <div className={styles.mmPVVContainer}>
                  <div className={styles.pvvLabelsContainer}>
                  {((objProduct?.arrayProductParameterizedVariables).slice(0,2)).map((objPVV:any, indexPVV:any) => (
                    
                      <div key={"adjPVV_" + index + "_" + indexPVV}>
                        <b>{objPVV.name}:</b> {objPVV.Value}
                      </div>
                    
                  ))}
                  {objProduct?.arrayProductParameterizedVariables.length > 1 && (
                    <div style={{position:'relative'}}>
                      <div></div>
                    </div>
                  )}
                  </div>
                </div>
              )}
            </div>

          </div>
         ))}
         <div><b> {hasMoreThanOneProducts ? "[+" + remainingOtherAdjustedProductsCount + "]" : ""}</b></div>
        
        </div>)
    }
 
    return (<></>)
  }
  const getMachineStatus = (machineObj:any) => {

  
    let workersCount = machineObj?.workersData ? machineObj.workersData.length : 0;

    let unavailabilityOccurrencesRunning = machineObj?.onGoingUnavailabilityOccurrencesData;
    let status = "AVAILABLE";
    let onGoingUnavailabilitiesData = machineObj?.onGoingUnavailabilitiesData;
    if(unavailabilityOccurrencesRunning && unavailabilityOccurrencesRunning.length > 0){
      status = "UNAVAILABLE";
    }else if(workersCount > 0){
      status = "ACTIVE"; //workers are using the machine
  }

    return status;

  }

  
  const getMachineStatusElement = (machineObj:any) => {
    let workersCount = machineObj?.workersData ? machineObj.workersData.length : 0;
  
    let firstWorker = workersCount > 0 ?  machineObj.workersData[0] : null;

 
    let unavailabilityOccurrencesRunning = machineObj?.onGoingUnavailabilityOccurrencesData;
    let status = getMachineStatus(machineObj);
    let onGoingUnavailabilitiesData = machineObj?.onGoingUnavailabilitiesData;
    let firstUnavCode = onGoingUnavailabilitiesData?.length > 0 ? onGoingUnavailabilitiesData[0].code : null;


    let hasSegment = machineObj?.machineSegmentData?.Id_MachineSegment ? true : false;
    let machineSegment = machineObj?.machineSegmentData;
    

    return (
      <IonCardHeader
      className={`${styles.elemElement} ${showPopupMachineLongPress?.Id_Machine === machineObj.Id_Machine ? styles.holdingMach : ""} ${status === "UNAVAILABLE" ? styles.unavailableElem : ""} ${status === "ACTIVE" ? styles.activeElem : ""}  ${status === "AVAILABLE" ? styles.availableElem : ""}`}
      {...bind(machineObj)}
      data-btn-machine-code={machineObj.code}
      onClick={() => {
        let statePreload:any = {};
        props.history.push({
          pathname: "/feature/machine-management/" + machineObj.Id_Machine,
          state: statePreload,
        });
      }}
    >
      <div className={styles.elemBackgroundContainer}>
        <MicrowaveIcon />
      </div>
      <div>
        <div className={`${styles.elemElementTitle}`}>
          {machineObj.code}
        </div>
        {firstUnavCode && (
          <div className={`${styles.infoContainer} ${styles.unavCodeContainer} `}>
            <div>
              <div><b>{t('downtime.code')}</b></div>
              <div>{firstUnavCode}</div>
            </div>
          </div>
        )}
      </div>
      <IonCardTitle className={styles.elemElementDescription}>
      
      </IonCardTitle>
      {getAdjustedToProductsElement(machineObj)}
      <div className={styles.statusContainer}>
         {status === "ACTIVE" && (<><div className={styles.statusRunning}><LoopIcon sx={{  animation: "spin 2s linear infinite",   "@keyframes spin": {  "0%": {   transform: "rotate(360deg)",  },  "100%": { transform: "rotate(0deg)",   }, }, }}/>Active</div></>)} {status === "AVAILABLE" && (<><div className={styles.statusAvailable}><CheckCircleIcon/>{t('available')}</div></>)}
         {status === "UNAVAILABLE" && (<><div className={styles.statusUnavailable}><EventBusyIcon/>{t('unavailable')}</div></>)}
      </div>
    </IonCardHeader>
    )
  }

  return (
    
    <InAppTemplate>
      {showPopupMachineLongPress && (
        <>
        <MachineLongPressPopup machineData={showPopupMachineLongPress} onClose={() => {
          setShowMachineLongPress(null);
        }}/>
        </>
      )}
      <CustomArrowBack pushUrl="/home" onBeforeRedirect={() => {
          machineManagementShortcutReceiver.resetShortcutData();
      }} />


      <div className={styles.pageTitle}>{t('machines')}</div>
      <div className={styles.topInfoContainer}>
        {machineManagementShortcutReceiver.shortcutReceivedData?.operationProductSelected && (
          <>{machineManagementShortcutReceiver.elementPreloadedShortcutData}</>
        )}
      </div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>


          {loadedMachines.map((obj:any,index:any) => (
            <div key={"lmach_" + index}>
              {getMachineStatusElement(obj)}
            </div>
          ))}
        </div>
      </div>
    </InAppTemplate>
  );
}

export default MachinesManagement;
