import { onServiceWorkerUpdate } from "@3m1/service-worker-updater";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import entityUserController from "./barrel/controllers/entityUserController";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log("update detected #1");


    if (registration?.waiting) {
      console.log("triggered skip waiting");
      registration.waiting.postMessage({ type: "SKIP_WAITING" }); //makes sure next refresh is updated
      
      const event = new Event("hxfServiceWorkerOnUpdate");
      window.dispatchEvent(event);

      //in case a new update is available before update checker is mounted
      let ts:any = Date.now()/1000;
      localStorage.setItem("lasttimeUpdateDetected", ts);
    }


  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
