import { useCallback, useEffect, useRef, useState } from "react";
import { dispatchProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenAskMachineStop.module.scss";
import ActionScreenAskMachineStopConfirmationButtons from "./ActionScreenAskMachineStopConfirmationButtons";

import { IonIcon, IonLoading } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import machineUsageActivityRecordsController from "../../../../../../barrel/controllers/machineUsageActivityRecordsController";
import { dispatchActionScreenProduceQuantityProductStore } from "../ActionScreenProduceQuantityProduct/ActionScreenProduceQuantityProductStore";
import CustomLoadingAction from "../../../../../../components/CustomLoadingAction";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import { Trans } from "react-i18next";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

interface IResultStoppingDevice {
    Id_MachineUsageActivityRecord?:any
    Id_Machine?:any;
    effectiveCycles?:any;
}
interface IResult {
  stopping:IResultStoppingDevice;

}

function ActionScreenAskMachineStop(props: any) {
  const {t} = useHxfTranslation();
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const preConfirm = useActionPreConfirm();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();

  const currentActionScreenManager = useActionScreenManagerStore();
  const feedbackService = useFeedbackService();
  const [selectedMachine, setSelectedMachine] = useState({
    temp_name: "",
    temp_code: "",
    Id_Machine: 0,
  });
 
  
  const result = useRef<IResult>({stopping:{}})
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  // states to allow clearing when loading a default stock location config

  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);

  function forceRerender() {
    setIndexkey(indexkey + 1);
  }


  const getMachineNaming = () => {

    let data = currentActionScreenManager?.currentActionConfig;
    if(data?.machineData){
        return "(" + data.machineData.code + ") " + data.machineData.name;
    }
    return "";
  }

  const endAction = useCallback(() => {
    let storeResult = result.current;

    props.callbackSuccessConfirm(storeResult);
  },[props,result]);
  const confirmClick = useCallback(() => {
    if(!preConfirm.check()){
      return;
    }
    let data = currentActionScreenManager?.currentActionConfig;

    let wasMachineStillRunning = false;
          
    let idMachineRecordToFetch = null;

    if(data?.machineRunningRecord){
        wasMachineStillRunning = true;
        let idMachineRecordRunning = data.machineRunningRecord.Id;
        idMachineRecordToFetch = idMachineRecordRunning;
        //get current activity record cycleCounter cycles
    }else{
      idMachineRecordToFetch = data?.machineLatestFinishedRecord?.Id;
    }
    

    if(!idMachineRecordToFetch){
      console.log("Issue occurred, unexpectedly didnt find a machine record"); //might have been deleted from the db for some reason
      //throw "no id machine record to fetch passed";
      endAction();
    }else{
      setIsLoading(true);
      machineUsageActivityRecordsController().getById(idMachineRecordToFetch,{},null).then((res) => {
      
          let returnObj = res?.data?.response?.data;
          if(returnObj?.effectiveCycles === null){
              throw "unexpected error, failed to fetch effective cycles";
          }
          
          if(wasMachineStillRunning){
            result.current.stopping.Id_MachineUsageActivityRecord = returnObj.Id;
            result.current.stopping.Id_Machine = returnObj.Id_Machine;
            result.current.stopping.effectiveCycles = returnObj.cycleCounter;
          }
  
  
          let shouldSetProducedQtyFromCycleCounter = data?.setProducedQtyDefaultByMachineCycleCounter === 1;
          if(shouldSetProducedQtyFromCycleCounter){
            dispatchActionScreenProduceQuantityProductStore({
              type: "SET_QUANTITY",
              payload: returnObj.cycleCounter,
            });
            
          }

          endAction();
      }).catch((res) => {
        feedbackService.notifyToast("An error occurred, try again and contact us if the error persists", "error");
      });
    }


    /*

    */
  },[ preConfirm,result,currentActionScreenManager?.currentActionConfig,endAction, feedbackService]);

  const clickedMachineIndex = useCallback(
    (index: number) => {
      let obj =
        currentActionScreenManager?.currentActionConfig?.machinesList[index];
      setSelectedMachine({
        Id_Machine: obj.Id_Machine,
        temp_name: obj.name,
        temp_code: obj.code,
      });
      setRemountKeypadIndex(remountKeypadIndex + 1);
    },
    [
      remountKeypadIndex,
      currentActionScreenManager?.currentActionConfig?.machinesList,
    ]
  );

  const closeCallback = () => {
    props.callbackOnClose();
  };


  useEffect(() => {
    if (!didMount) {
      //mounting
       
      let data = currentActionScreenManager?.currentActionConfig;

      //check if has machineRecord, if not, then skip action, no machine to stop
      //todo, do the same on backend on receiving do nothing regard machine
      if(!data?.machineRunningRecord){
          //todo check if has a latest machine record, to get qty from
        confirmClick();
      }else{
        setIsLoading(false);
      }
      
      
 
     
      setDidMount(true);
    }
  }, [
    didMount,
    clickedMachineIndex,
    currentActionScreenManager?.currentActionConfig,
    confirmClick
  ]);

  useEffect(() => {
    return () => {
      //unmounting
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  
  if (isLoading) {
    return <><CustomLoadingAction/></>;
  }

  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar callbackOnClose={closeCallback} title={t("machine.stopping.confirmation")} />

        <div className={styles.content}>
            <div className={styles.confirmationNotice}>
         
                  <div style={{margin:'20px'}}>
                     
                      <Trans i18nKey="machine.about.stop" values={{productname:productionOrderSelectionStore.product.name}}>
                            The machine <b>{getMachineNaming()}</b> is about to be stopped.
                      </Trans>
                  </div>
                

            </div>
    
        </div>
      </div>

      <ActionScreenAskMachineStopConfirmationButtons
        enabled={!isLoading}
        typeShow={"CONFIRM"}
        onClickConfirm={() => {
          
            confirmClick();
       
        }}
      />
    </>
  );
}
export default ActionScreenAskMachineStop;
