import { useEffect, useRef, useState } from "react";
import usePrevious from "../../../barrel/hooks/usePrevious";
import { howCloseIsElementToViewport } from "../../../barrel/utils/ViewPortUtils";
import { getViewportHeight } from "../../../barrel/utils/ViewPortUtils";
interface IHxfScroll {
  containerElementRef?:any;
  requiresContainerHavingScrollbar?:boolean;
  onLoadMore: any;
  minDistanceToViewport?: number; // ex: 0 - as soon as it is in view port it triggers, 100 - if its at 100px from viewport, it triggers, -100 as soon as it is further than 100 pixeis visible in the viewport
  allowedLoadPageRef: any; //to allow loading more pages, the onLoad must increase the value of the allowedLoadPage otherwise it will not attempt to load more elements
}

function HxfInfiniteScroll(props: IHxfScroll) {
  const [didMount, setDidMount] = useState(false);

  const loadedCurrentPageRef = useRef(-1);
  const allowedLoadPageRef = props.allowedLoadPageRef;
  const [pendingTriggerLoadMore, setPendingTriggerLoadMore] = useState(false);
  function hasScrollBar(element:any) {
    // Check for horizontal scrollbar
    //const hasHorizontalScrollBar = element.scrollWidth > element.clientWidth;
  
    // Check for vertical scrollbar
    const hasVerticalScrollBar = element.scrollHeight >= element.clientHeight;

    // Return true if either horizontal or vertical scrollbar exists, otherwise false
    return  hasVerticalScrollBar; //hasHorizontalScrollBar ||
  }
  
  
  const generateRandomString = () => {
    let ts = new Date();

    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 255; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    result = "hxfInfScrl_" + result;
    result = result.substring(0, 128);
    return result;
  };

  const [randomElementId, setRandomElementId] = useState("");

  useEffect(() => {
    let myInterval: NodeJS.Timeout | null = null;
    if (!didMount) {
      console.log("starting");

      let randomId = generateRandomString();
      setRandomElementId(randomId);

      myInterval = setInterval(() => {
        console.log(
          "loaded current allowed page is: ",
          props.allowedLoadPageRef.current
        );

        if (allowedLoadPageRef.current < loadedCurrentPageRef.current) {
          console.log("nothing to load " + allowedLoadPageRef.current);

          return;
        }
        console.log("timer check");

        let targetElement = document.getElementById(randomId);
        if (targetElement === null) {
          console.log("element not found " + randomId);
          return;
        }
        let elemDist = howCloseIsElementToViewport(targetElement);

        let distanceToTrigger = 0;
        if (props.minDistanceToViewport) {
          distanceToTrigger = props.minDistanceToViewport;
        }

        let winHeight = window?.innerHeight;
        if(targetElement && winHeight){
          let rect = targetElement?.getBoundingClientRect();
          let targetelemY = rect.y;
          //console.log("the target element Y", rect);
        }

        if(props?.requiresContainerHavingScrollbar && props?.containerElementRef?.current){

          if(!hasScrollBar(props.containerElementRef.current)){
            
            return;
          }

        }

        //element must be somewhere 

        if (distanceToTrigger > elemDist) {
          //console.log("loading more");

          loadedCurrentPageRef.current = loadedCurrentPageRef.current + 1;
          //console.log("DIST TO VIS: ", elemDist);
          if (props?.onLoadMore) {
            setPendingTriggerLoadMore(true);
          }
        } else {
          console.log("Not in condi");
        }
      }, 1000);

      setDidMount(true);
    }
    return () => {
      if (myInterval !== null) {
        clearInterval(myInterval);
      }

      console.log("umount hxfScroll");
    };

    //disabling the eslint on the line because if i add didMount it cals the cleanup function on every render
    //making the interval useless
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(pendingTriggerLoadMore){
      props.onLoadMore();
      setPendingTriggerLoadMore(false);
    }
  },[pendingTriggerLoadMore]);
  if (!didMount || randomElementId === "") {
    return <></>;
  }
  return <div id={randomElementId}></div>;
}

export default HxfInfiniteScroll;
