interface IUomsConversionDataElement {
  Id_Product: number;
  Id_UoM_Target: number;
  UoM_Target_Code: string;
  UoM_Target_Name: string;
  uomBaseValue: number;
  uomTargetValue: number;
}

interface IOrderProduct {
  Quantity: number;
  Id_UoM_Selected: number;
  uomConversionsData: Array<IUomsConversionDataElement>;
}

function UomService() {
  /**
   * This method is used to orders containing order products from the server base uom to be the uom visually correct
   *
   * this method must be used on load data only not on submission as it must be converted back to base (-1) before submitting
   */
  const convertLoadOrdersQuantityToTheirSelectedUom = (
    orders: any,
    orderProductsKey: string
  ) => {
    if (!orders) {
      return orders;
    }
    if (orders && orders.length > 0) {
      for (let i = 0; i < orders.length; i++) {
        let orderProducts = orders[i][orderProductsKey];
        if (orderProducts) {
          orderProducts =
            UomService().convertLoadOrderProductsQuantityToTheirSelectedUom(
              orderProducts
            );
        }
      }
    }

    return orders;
  };

  /**
   * This method is used to order products (comming from their orders) from the server base uom to be the uom visually correct
   *
   * this method must be used on load data only not on submission as it must be converted back to base (-1) before submitting
   */
  const convertLoadOrderProductsQuantityToTheirSelectedUom = (
    orderProducts: Array<IOrderProduct>
  ) => {
    if (!orderProducts) {
      return orderProducts;
    }
    for (let j = 0; j < orderProducts.length; j++) {
      let op = orderProducts[j];

      if (!op.Id_UoM_Selected) {
        //no conversion
      } else {
        op.Quantity = UomService().convertQuantity(
          op.Quantity,
          -1,
          op.Id_UoM_Selected,
          op.uomConversionsData
        );
      }
    }

    return orderProducts;
  };

  const convertQuantity = (
    quantity: number,
    idUomFrom: number,
    idUomTo: number,
    uomConversionsData: Array<IUomsConversionDataElement>
  ) => {
    let newQty = quantity;
    if(uomConversionsData.length === 0){
      return newQty;
    }
    if (newQty === 0) {
      return 0;
    }
    let targetIdUom = idUomTo;
    let uomTargetValue = 0;
    //let uomPreviousValue = 0;
    let uomBaseValue = 1;

    let previousSelectedIdUom = idUomFrom ? idUomFrom : -1; //-1 pcs default

    let searchPreviousUom = false;
    let searchTargetUom = false;
    let runLastConversion = true;
    if (previousSelectedIdUom === -1) {
      //pcs default
      searchPreviousUom = false;
      uomBaseValue = 1;
    } else {
      searchPreviousUom = true;
    }
    if (targetIdUom === -1) {
      searchTargetUom = false;

      uomTargetValue = 1;
    } else {
      searchTargetUom = true;
    }

    if (targetIdUom === -1 && previousSelectedIdUom !== -1) {
      runLastConversion = false;
    }

    if (searchPreviousUom) {
      for (let i = 0; i < uomConversionsData.length; i++) {
        if (uomConversionsData[i].Id_UoM_Target === previousSelectedIdUom) {
          let pcsUomBaseValue = uomConversionsData[i].uomBaseValue;
          let prevUomValue = uomConversionsData[i].uomTargetValue;
          //convert back to pcs first os that on the next search we convert it back to the target uom

          let ratio = prevUomValue / pcsUomBaseValue;

          newQty = newQty / ratio;
          break;
        }
      }
    }

    let foundTgt = false;
    if (searchTargetUom) {
      for (let i = 0; i < uomConversionsData.length; i++) {
        if (uomConversionsData[i].Id_UoM_Target === targetIdUom) {
          uomBaseValue = uomConversionsData[i].uomBaseValue;
          uomTargetValue = uomConversionsData[i].uomTargetValue;

          break;
        }
      }
    }

    if(!foundTgt){
      console.error("WARNING: unexpectedly didnt find target uom conversion data");
      return newQty;
  }

    let convertRatio = uomTargetValue / uomBaseValue;
    if (runLastConversion) {
      newQty = newQty * convertRatio;
    }

    return newQty;
  };

  return {
    convertLoadOrderProductsQuantityToTheirSelectedUom,
    convertQuantity,
    convertLoadOrdersQuantityToTheirSelectedUom,
  };
}
export default UomService;
