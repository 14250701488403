import { IonButton, IonToolbar } from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import entityController from "../../barrel/controllers/entityController";
import entityUserController from "../../barrel/controllers/entityUserController";
import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import styles from "./AppOutdatedCacheButton.module.scss";
import RequiredUpdatePopup from "./requiredUpdatePopup/RequiredUpdatePopup";

const AppOutdatedCacheButton = () => {
  const [didMount, setDidMount] = useState(false);
  const [timerCheckSw, setTimerCheckSw] = useState<any>(null);
  const myStorageHandler = useLocalStorageHandler();
  const [alreadyDetected, setAlreadyDetected] = useState(false);
  const [showForceUpdatePopup, setShowForceUpdatePopup] = useState(false);
  const {  appState } = useGlobalState();

  console.log("THE APP STATE: ", appState);
  const updateDetected = useCallback(() => {
    if (!appState.isAppOutdated()) {
     
      let appInitiatedTs:any = localStorage?.getItem("appInitiatedTs") ? localStorage.getItem("appInitiatedTs") : 0;
      let tsNow = Math.floor(Date.now() / 1000);
      let timeDiff: any = tsNow - parseInt(appInitiatedTs);
      if (!appInitiatedTs || timeDiff < 3) {
        console.log(
          "Update detected on first seconds of app initiation, even if not major, it should force reload now!"
        );
        //window.location.href = document.location.href;
        //window.location.reload();
        setShowForceUpdatePopup(true);
        return;
      }

      
      //get app/public/WS_UPDATE_MODE.txt

     
      entityUserController()
      .checkApiMajorVersion()
      .then((resp: any) => {
        let majorVersion = resp?.data?.response?.majorVersion;
        let workstationFeUpdateMode = resp?.data?.response?.WS_FRONT_UPDATE_MODE;
        console.log("Major api version check: ", majorVersion);
        if (
          majorVersion &&
          process.env.REACT_APP_EXPECTED_API_MAJOR_VERSION_WORKSTATION &&
          majorVersion >
            process.env.REACT_APP_EXPECTED_API_MAJOR_VERSION_WORKSTATION
        ) {
          console.log("Major version detected, force reload popup");
          //location.reload();
          setShowForceUpdatePopup(true);
        }else{
          if(workstationFeUpdateMode === "NONE"){
            //does nothing, service worker was already activated, next manual refresh will be updated
          }else if(workstationFeUpdateMode === "SILENT_RELOAD"){
            myStorageHandler.setReloadWhenPossible(); //doesnt notify of an update available, the app will reload when the user finishes a production or loads a page but he is not doing anything of relevance
          }else if(workstationFeUpdateMode === "NOTIFY_ALL"){
            appState.setAppOutdated(true);
          }else{
            appState.setAppOutdated(true); //default NOTIFY_ALL
          }

        }
      })
      .catch(() => {
        appState.setAppOutdated(true); //default NOTIFY_ALL
        console.log("failed to check api major version");
      });

    }
  }, [appState, myStorageHandler]);



  useEffect(() => {
    if (!didMount) {
      //forceServiceWorkerUpdateCheck();

      let ts:any = Date.now()/1000;
      let lasttimeUpdateDetected = localStorage.getItem("lasttimeUpdateDetected");
      if(lasttimeUpdateDetected && lasttimeUpdateDetected !== ""){
        if(parseInt(ts)-parseInt(lasttimeUpdateDetected) < 10){
          setShowForceUpdatePopup(true);
        }
      }
      window.addEventListener("hxfServiceWorkerOnUpdate", updateDetected);
      setDidMount(true);
    }

    return () => {
      console.log("Cleanup timercheck.");
      window.removeEventListener("hxfServiceWorkerOnUpdate", updateDetected);
      if (timerCheckSw !== null) {
        clearInterval(timerCheckSw);
      }
    };
    // eslint-disable-next-line
  }, []);

  return appState.isAppOutdated() || showForceUpdatePopup  ? (
    <>
    {showForceUpdatePopup ? (
      <RequiredUpdatePopup/>
    ) : (
      <IonToolbar>
        <div className={styles.outDatedAppToolbar}>

    
          <div
          className={`${styles.labelsContainer} ${styles.outDatedAppToolbarUpdateTextContainer}`}
        >
          <div>
            A new update is available! Reload the app or click on the update
            button to update it.
          </div>
          <div>Finish what you are doing first to avoid any work loss</div>
        </div>
        <div className={`${styles.outDatedUpdateButtonContainer}`}>
          <IonButton
            onClick={(data) => {
              //location.reload();
             
              let item = localStorage.getItem("lasttimeUpdateDetected");
              if(item){
                localStorage.removeItem("lasttimeUpdateDetected");
              }
            
              window.location.reload();
            }}
            color="primary"
          >
            Update
          </IonButton>
        </div>
        

        </div>
      </IonToolbar>
    )}

    </>
  ) : (null); // If no update is available, render nothing
};

//  message: { myCustomType: 'SKIP_WAITING' },
export default AppOutdatedCacheButton;
