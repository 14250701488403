import createStore from "../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IProductionUIStore {
  timerElapsedEnabled?: boolean;
  showOperationOverlay?: boolean;
}

interface IProductionUIStoreAction {
  type: any;
  payload?: any;
}

const initialState = {
  showOperationOverlay: false,
  timerElapsedEnabled: false,
};

const reducer = (
  store: IProductionUIStore,
  action: IProductionUIStoreAction
) => {
  switch (action.type) {
    case "SET_TIMER_ELAPSED_ENABLED":
      store.timerElapsedEnabled = action.payload;
      return store;
    case "SET_OPERATION_OVERLAY":
      store.showOperationOverlay = action.payload;
      return store;
    case "RESET_STORE":
      store = initialState;
      return store;
    default:
      return store;
  }
};

export const [useProductionUIStore, dispatchProductionUIStore] = createStore(
  initialState,
  reducer
);
