import styles from "./AutoCalculateWholesConfirmationButtons.module.scss";
import confirmationStyles from "../../../../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";

import { t } from "i18next";
interface IProps {
  onClickConfirm: any;

}

function AutoCalculateWholesConfirmationButtons(props: IProps) {
  const getButtonLabel = () => {
    return t("confirm");
  };

  return (
    <div className={styles.container}>
        <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={`${styles.customConfirmationContainer} `}>
          <IonButton
            data-btn="autoCalculateConfirm"
            className={confirmationStyles.confirmButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {/*{props.typeShow === "PREVIOUS" && (
              <IonIcon slot="start" icon={caretBackOutline} />
            )}{" "}*/}
            {getButtonLabel()}{" "}
     
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default AutoCalculateWholesConfirmationButtons;
