import { IonButton, IonIcon } from "@ionic/react";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { CircularProgress } from "@mui/material";
import { closeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import lotsController from "../../../../barrel/controllers/lotsController";
import { useCancelToken } from "../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import ScannablesService from "../../../../barrel/services/ScannablesService";
import { IHxfBarcodeScanFormat, IHxfBarcodeSettings } from "../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import ScanBarcodePopup from "../../../FeaturePages/ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import HxfStockLocationForm from "../../HxfStockLocationForm/HxfStockLocationForm";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import TraceStockPopup, { ITraceStockProductData } from "../../TraceStockPopup/TraceStockPopup";
import PrintLabelPopup from "../printLabelPopup/printLabelPopup";
import DefineStockLocationConfirmationButtonsPopup from "./DefineStockLocationConfirmationButtonsPopup/DefineStockLocationConfirmationButtonsPopup";
import styles from "./PopupReceivingLocation.module.scss";
import PrintStockLocationLabelButton from "./PrintStockLocationLabelButton/PrintStockLocationLabelButton";
import WidgetsIcon from '@mui/icons-material/Widgets';
import usePrevious from "../../../../barrel/hooks/usePrevious";
/*
interface IProductParameterizedVariable{
    Id_Product_ParameterizedVariable:number;
    value:any;
}

interface IProductData{
    Id_Product:number;
    productParameterizedVariables:IProductParameterizedVariable[];
}

interface IPopup{
    onClosePopup:any;
    onSuccessConfirm:any;
    title:string;
    currentData:any;
    subtitle:string | null;
    fetchProductData:IProductData | null;
    fetchProductStockQuantityOnLocationChange: boolean | null;
}
*/
interface IPopup {

  invisibleStockLocation?:boolean;
  onClosePopup: any;
  onSuccessConfirm: any;
  title: string;
  currentData: any;
  subtitle: string | null;
  allowLotCreation: boolean | null;
  infoData?: any;
  defaultId_Warehouse_Location?:any;
  defaultWarehouseLocationData?:any;
  defaultId_Warehouse?:any;
  defaultWarehouseData?:any;
  defaultId_Lot?:any;
  defaultLotData?:any;
  lotNoLot_Id_Lot?:any;
  lotNoLot_name?:any;
  lotNoLot_code?:any;

  lotLabelBuilder?:any;

  deviceScanAutoConfirmLocation?:boolean;

  qrScannerEnabled?:boolean;
  barcodeScannerEnabled?:boolean;
  traceStockEnabled?:any;
  traceStockFillDefaultLot?:boolean;
  traceProductData?:ITraceStockProductData;
  lotTracerEnabled?:any;
  disableSetLocation?:boolean;
  notDefinedAllParameterizedVariableValues?:boolean;
}

interface IFinishResult {
  Id_WarehouseLocation?:any;
  temp_warehouseLocationLabel?:any;
  Id_Warehouse?:any;
  temp_warehouseLabel?:any;
  Id_Lot?:any;
  temp_lotLabel?:any;
  defaultNoLotObj?:any;
  extra?:any;
}
function DefineStockLocationPopup(props: IPopup | any) {
  const ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES = true; //later turn into a setting, locate ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES accross whole project
  const useDefaultNoLotIfNoLotSelected = true; //flag to become dynamic in the future
  const {t} = useHxfTranslation();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "defineStockLocationPopup_lotScan",
  });

  const [triggerFinishScan, setTriggerFinishScan] = useState<any>(null);
  const prevFinishScan = usePrevious(triggerFinishScan);

  const getLocateStockLabels = (printableLabels:any) => {

    let productLabelsData = printableLabels ? printableLabels : [];
    
    let locateStockLabels:any = [];
    for(let i = 0; i<productLabelsData.length; i++){
      if(parseInt(productLabelsData[i]?.isLocationLabel) === 1){
        locateStockLabels.push(productLabelsData[i]);
      }
      
    }
    
    return locateStockLabels;
  }
  const getDefaultLotNoLot = () => {
   
    if(!props?.lotNoLot_Id_Lot){
      return null;
    }

    return {Id_Lot: props.lotNoLot_Id_Lot, name: props.lotNoLot_name, code: props.lotNoLot_code};
  }

  const getAllowedScanFormats = () => {
    let allowedScanFormats:IHxfBarcodeScanFormat[] = [];
    if(props.qrScannerEnabled || props.barcodeScannerEnabled){
      //some barcode scanners also scan qrs so we add both patterns
      allowedScanFormats.push(
        {"type":"qrcode","pattern":"SFS01" + ScannablesService().hxfQrcodeDelimiter +  "[lot.id]"},
        {"type":"qrcode","pattern":"SFS02" + ScannablesService().hxfQrcodeDelimiter +  "[lot.id]" + ScannablesService().hxfQrcodeDelimiter + "[product.id]"}
        );
    }

    if(props.barcodeScannerEnabled){
      allowedScanFormats.push({"type":"qrcode","pattern":"SFS01" + ScannablesService().hxfBarcode39Delimiter + "[lot.id]"});
      allowedScanFormats.push({"type":"qrcode","pattern":"SFS02" + ScannablesService().hxfBarcode39Delimiter + "[lot.id]" + ScannablesService().hxfBarcode39Delimiter + "[product.id]"});
    }

    return allowedScanFormats;
  }
  const scannerSettings = useRef<IHxfBarcodeSettings>({
    useCamera: true,
    useExternalDevice: props.barcodeScannerEnabled,
    initCameraOnStartUp:props.qrScannerEnabled && !props.barcodeScannerEnabled,
    allowedScanFormats: getAllowedScanFormats(),
  });
  const [isLoadingScanResultData, setIsLoadingScanResultData] = useState(false);
  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState<any>(null);
  const [showTraceStockPopup, setShowTraceStockPopup] = useState<any>(null);

  const [showLotTracePopup, setShowLotTracePopup] = useState<any>(null);

  const [defaultNoLotObj, setDefaultNoLotObj] = useState<any>(getDefaultLotNoLot());
  const [renderingIndex, setRenderingIndex] = useState(0);

  const [warehouseError, setWarehouseError] = useState(false);
  const [warehouseLocationError, setWarehouseLocationError] = useState(false);
  const [lotError, setLotError] = useState(false);

  const [warehouseLocation, setWarehouseLocation] = useState<any>(props?.defaultId_Warehouse_Location ? {label:props.defaultWarehouseLocationData.name,value:props.defaultId_Warehouse_Location} : null);
  const [warehouse, setWarehouse] = useState<any>(props?.defaultId_Warehouse ? {label:props.defaultWarehouseData.name,value:props.defaultId_Warehouse} : null);
  const [lot, setLot] = useState<any>(props?.defaultId_Lot ? {label:props.defaultLotData.name,value:props.defaultId_Lot} : null);

  const [warehouseSearchFilters, setWarehouseSearchFilters] = useState({});
  const [lotSearchFilters, setLotSearchFilters] = useState({});
  const [showPrintLabelPopup, setShowPrintLabelPopup] = useState<any>(null);

  const [insufficientQtyWarning, setInsufficientQtyWarning] = useState(false);

  const feedbackService = useFeedbackService();

  const stateCheckQtyStock = (inputQty:any, availableQty:any) => {
    if(inputQty === null || availableQty === null){
        setInsufficientQtyWarning(false);
        return;
    }
    if(inputQty > availableQty){
        setInsufficientQtyWarning(true);
    }else{
        setInsufficientQtyWarning(false);
    }
}




  const warehouseLoadedListJsonCache = useRef<any>([]);

  const [lotLoadedListJsonCache, setLotLoadedListJsonCache] = useState<any>([]);

  const [didMount, setDidMount] = useState(false);
  const callbackAddWarehouseLocationCacheItems = (items: any) => {

  };


  const callbackAddWarehouseCacheItems = (items: any) => {
    warehouseLoadedListJsonCache.current = items;
  };
  const callbackAddLotCacheItems = (items: any) => {
    //setLotLoadedListJsonCache([...lotLoadedListJsonCache, ...items]);
  };
  
  const getDefaultNoLotForWarehouse = (warehouseCacheObj: any) => {
    let defaultNoLot = warehouseCacheObj.defaultNoLot;
    defaultNoLot = JSON.parse(defaultNoLot);
    if (defaultNoLot.Id_Lot !== null) {
      return {
        Id_Lot: defaultNoLot.Id_Lot,
        name: defaultNoLot.name,
        code: defaultNoLot.code,
      };
    }

    return null;
  };
  /*const getDefaultNoLotForWarehouse = (idWarehouse:any) => {

       

        if(warehouseLoadedListJsonCache && warehouseLoadedListJsonCache.length > 0){
            for(let i = 0; i<warehouseLoadedListJsonCache.length; i++){
                if(warehouseLoadedListJsonCache[i].Id === idWarehouse){

                    let defaultNoLot = warehouseLoadedListJsonCache[i].defaultNoLot;
                    defaultNoLot = JSON.parse(defaultNoLot);
                    if(defaultNoLot.Id_Lot !== null){
                        return {Id_Lot:defaultNoLot.Id_Lot, name:defaultNoLot.name,code:defaultNoLot.code };
                    }
                }
            }
        }
        
        return null;
    }*/

  const getWarehouseCacheItem = (id: any) => {
    for (let i = 0; i < warehouseLoadedListJsonCache.current.length; i++) {
      if (warehouseLoadedListJsonCache.current[i].Id === id) {
        return warehouseLoadedListJsonCache.current[i];
      }
    }

    return null;
  };

  const getIsScannerEnabled = () => {
    let enabledProps = props.qrScannerEnabled || props.barcodeScannerEnabled;;
    let rightFormatSettings =  scannerSettings?.current?.allowedScanFormats && scannerSettings?.current?.allowedScanFormats?.length > 0;
    return props?.notDefinedAllParameterizedVariableValues !== true && enabledProps && rightFormatSettings;
  }

  const isBtnLotTracerEnabled = () => {
    return warehouse;
  }
  const getBtnLotTracer = () => {
    return (
      <div className={`${styles.btnOptionContainer}  `}>
        <div className={ `${styles.btnOptionInner}`}  onClick={() => {
            if(!isBtnLotTracerEnabled()){
              return;
            }
            let traceProductData = props.traceProductData;
            setShowLotTracePopup({traceProductData:traceProductData});
           
          }}>
          <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
            <div className={ `${styles.elementBtnContainer} ${!isBtnLotTracerEnabled() ? styles.btnOptionDisabled : ""} `}>
                <WidgetsIcon fontSize="large" />
              
            </div>
          </div>
          <div className={styles.btnOptionLabel}>
            <b>{t('smart.lot')}</b>
          </div>
        </div>
      </div>
    )
  }

  const getBtnTraceStock = () => {
    return (
      <div className={styles.btnOptionContainer}>
        <div className={styles.btnOptionInner}  onClick={() => {
            let traceProductData = props.traceProductData;
            
            setShowTraceStockPopup({traceProductData:traceProductData});
          }}>
          <div className={styles.elementBtnContainer}>
              <ViewInArIcon fontSize="large" />
            
          </div>
          <div className={styles.btnOptionLabel}><b>{t('trace')}</b></div>
        </div>
      </div>
    )
  }
  

  const resetErrors = () => {
    setLotError(false);
    setWarehouseError(false);
    setWarehouseLocationError(false);
  };


  const isGhostScannerEnabled = () => {

    
    return !showBarcodeScannerPopup && props.barcodeScannerEnabled;
  }
  const getPopupScannerObj = (options:any = {}) => {

    return (
      <ScanBarcodePopup
      title="Scanner"
      freeScannerMode={true}
      visible={true}
      ghostMode={options?.ghostMode}
      barcodeScannerSettings={scannerSettings.current}
      onClosedCamera={() => {
        if (
          (MobileWrapperCommunicationService().isDeviceMobileWrapper()) &&
          scannerSettings.current.useCamera &&
          !scannerSettings.current.useExternalDevice
        ) {
          setShowBarcodeScannerPopup(false);
        }

      }}
      onInvalidScan={() => {
        feedbackService.notifyToast("Invalid code scanned","warning");
      }}
      onFinishedScan={(result: any) => {
        setTriggerFinishScan(result);  //Locate scanbarcodepopup, as scanbarcodepopup works on timer, eventually make this functionality inside hxfbcarcodescanner, create hxfbarcodescanner v2 or something
        //the onFinishedScan finished scan must not be called from a timer, to fix later, or it would be accessing unsynced states
        //this temporarily fixes it 
      }}
      onClosePopup={() => {
        setShowBarcodeScannerPopup(false);
      }}
    />
    )
  }

  const hasLabelsData = () => {
    let productLabelsData = props?.infoData?.productData?.productLabelsData ? props?.infoData?.productData?.productLabelsData : [];

    return productLabelsData.length > 0;
  }

  const finishConfirm = (resultSuccess:IFinishResult) => {
    props.onSuccessConfirm(resultSuccess);

  }


  const getLotLabel = (lotData:any) => {
    if(props?.lotLabelBuilder){
      return props.lotLabelBuilder(lotData);
    }

    return "(" + lotData.code + ") " + lotData.name;

  }

useEffect(() => {

  if(triggerFinishScan && triggerFinishScan !== prevFinishScan){
    let result:any = triggerFinishScan;
    setTriggerFinishScan(null);
    console.log("RESULT: ", result);
             
    let format = result.resultFormat;
    let resultScan = result.resultScan;
    let delimiter = null;
    let pattern = format.pattern;
    let currentAllowedScanFormats = scannerSettings.current.allowedScanFormats;
    setShowBarcodeScannerPopup(false);
    if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
      if(format.type === "barcode39" || format.type === "barcode128"){
      
          for(let i = 0; i<currentAllowedScanFormats.length; i++){
            if(!currentAllowedScanFormats[i]?._skipMatchFindDelimiterProcessing && currentAllowedScanFormats[i].pattern === pattern){
                //match found
                let realType = currentAllowedScanFormats[i]?.type;
              
                //SWAP type and process as if it was scanned with qrcode (in case it was qrcode pattern the one scanned)
                format.type = realType;
              
            }
          }
        
      }
    }
    if (format.type === "barcode39") {
      delimiter = ScannablesService().hxfBarcode39Delimiter;
    }
    if (format.type === "barcode128") {
      delimiter = ScannablesService().hxfBarcode128Delimiter;
    }

    if (format.type === "qrcode") {
      delimiter = ScannablesService().hxfQrcodeDelimiter;
    }

 
    let splittedPattern = pattern.split(delimiter);

    let indexThatIdentifiesLotId = -1;
    let indexThatIdentifiesTheProductId = -1;

    for (let i = 0; i < splittedPattern.length; i++) {
      if (splittedPattern[i] === "[lot.id]") {
        indexThatIdentifiesLotId = i;
        
      }
      if(splittedPattern[i] === "[product.id]"){
        indexThatIdentifiesTheProductId = i;
    
      }
    }
    let splittedResult = resultScan.split(delimiter);
    let idProduct:any = null;
    if(indexThatIdentifiesTheProductId !== -1){
      idProduct = splittedResult[indexThatIdentifiesTheProductId];
    }
    if (indexThatIdentifiesLotId !== -1) {
    
     
      if (splittedResult.length !== splittedPattern.length) {
    
        console.log("Scan does not match the pattern");
        feedbackService.notifyToast("Invalid code scanned","warning");
      } else {
    
       
        let idLot = splittedResult[indexThatIdentifiesLotId];
        setIsLoadingScanResultData(true);
        //todo request to fetch the Id_Lot, Id_Warehouse, Id_WarehouseLocation AND
        // defaultLotNoLot to auto fill just in case
        lotsController().getLot({
          includeWhLocation:true,
          includeDefaultNoLot:true
        },idLot,cancelToken).then((res) => {
          if (isCancel(res)) {
            return;
          }
          setIsLoadingScanResultData(false);
         
          if(!res?.data?.Id){
            throw "unexpected scan res";
          }
          let responseObject = res?.data;
          
          let lotCode = responseObject.code;
          let lotName = responseObject.name;
          let idWh = responseObject?.Id_Warehouse;
          let whName = responseObject?.warehouse?.name;
          let whCode = responseObject?.warehouse?.code;
          let idWhLoc = responseObject?.warehouse?.warehouseLocation?.Id;
          let whLocName = responseObject?.warehouse?.warehouseLocation?.name;
          let whLocCode = responseObject?.warehouse?.warehouseLocation?.code;
          let defaultNoLotObj = responseObject?.warehouse?.defaultNoLot;
          setWarehouseLocation({
            label: "(" + whLocCode + ") " + whLocName,
            value: idWhLoc,
          });
          setWarehouseSearchFilters({
            Id_WarehouseLocation: idWhLoc,
          });

          setWarehouse({
            label: "(" + whCode + ") " + whName,
            value: idWh,
            warehouseData:{
              name:whName,
              code:whCode
            }
          });
          setLotSearchFilters({
            Id_Warehouse: idWh,
          });

          setLot({
            label: "(" + lotCode + ") " + lotName,
            value: idLot,
            lotData:{
              name:lotName,
              code:lotCode
            }
          });

          if(!defaultNoLotObj){
            setDefaultNoLotObj(null);
          }else{
            
            setDefaultNoLotObj({
              Id_Lot:defaultNoLotObj.Id_Lot,
              name:defaultNoLotObj.name,
              code:defaultNoLotObj.code
            });
          }
       
          if(props?.deviceScanAutoConfirmLocation){
            
            let lotLabel = getLotLabel({
              code:lotCode,
              name:lotName
            });
            finishConfirm({
              Id_WarehouseLocation:idWhLoc, temp_warehouseLocationLabel:whLocName,Id_Warehouse:idWh, temp_warehouseLabel:whName, Id_Lot:idLot, temp_lotLabel:lotLabel, defaultNoLotObj:defaultNoLotObj ? defaultNoLotObj : null
              ,extra:{
                scannedData:{
                  Id_Product:idProduct
                },
                warehouseData:{
                  name:whName,
                  code:whCode
                },
                lotData:{
                  code:lotCode,
                  name:lotName
                }
              }
            })
          }

        }).catch((res) => {
          if(res?.response?.status === 404){
            feedbackService.notifyToast(t("lot.not.found"),"error");
            return;
          }
          setIsLoadingScanResultData(false);
          feedbackService.notifyToast(t('generic.critical.error'),"danger");
        });
      }
    }else{
      feedbackService.notifyToast("Invalid code scanned","warning");
    }
  }
},[triggerFinishScan])

  useEffect(() => {
    if (!didMount) {
      if (props?.currentData) {
        if (props?.currentData?.Id_WarehouseLocation) {
          //checking if not shopfloor
          setWarehouseLocation({
            label: props.currentData.temp_warehouseLocationLabel,
            value: props.currentData.Id_WarehouseLocation,
          });
          setWarehouseSearchFilters({
            Id_WarehouseLocation: props?.currentData?.Id_WarehouseLocation,
          });
          if (props?.currentData?.Id_Warehouse) {

            let objWh:any = {
              label: props.currentData.temp_warehouseLabel,
              value: props.currentData.Id_Warehouse,
            };

            if(props?.currentData?.warehouseData?.code){
              objWh.code = props?.currentData?.warehouseData?.code;
            }
            if(props?.currentData?.warehouseData?.name){
              objWh.name = props?.currentData?.warehouseData?.name;
            }
            setWarehouse(objWh);
            setLotSearchFilters({
              Id_Warehouse: props.currentData.Id_Warehouse,
            });
          }

          if (props.currentData?.Id_Lot) {

            let objLot:any = {
              label: props.currentData.temp_lotLabel,
              value: props.currentData.Id_Lot,
            };
            if(props?.currentData?.lotData?.code){
              objLot.code = props?.currentData?.lotData?.code;
            }
            if(props?.currentData?.lotData?.name){
              objLot.name = props?.currentData?.lotData?.name;
            }
            setLot(objLot);
          }

          if (props.currentData?.defaultNoLotObj) {
            setDefaultNoLotObj(props.currentData.defaultNoLotObj);
          }
        }
      }
      setDidMount(true);
    }
  }, [didMount, props.currentData]);


  return (
    <>
    {props?.invisibleStockLocation ? (
      <>
          {isGhostScannerEnabled() && (
            <>{getPopupScannerObj({ghostMode:true})}</>
          )}
      </>
    ) : (
      <>
    <div className={styles.intPopup}>

      <SimpleContentPopup>
        <div>
          {showLotTracePopup && (
                        <TraceStockPopup
                          notDefinedAllParameterizedVariableValues={props.notDefinedAllParameterizedVariableValues}
                          onSelectStockLocation={(objSelLocation:any) => {
                      
                              if(objSelLocation?.Id_WarehouseLocation){
            
                                //todo default no lot 
                                setWarehouseLocation({
                                  label: "(" + objSelLocation.warehouseLocationCode + ") " + objSelLocation.warehouseLocationName,
                                  value: objSelLocation.Id_WarehouseLocation,
                                });
                                setWarehouseSearchFilters({
                                  Id_WarehouseLocation:objSelLocation.Id_WarehouseLocation,
                                });
            
                                setWarehouse({
                                  label:  "(" + objSelLocation.warehouseCode + ") " + objSelLocation.warehouseName,
                                  value: objSelLocation.Id_Warehouse,
                                  warehouseData:{
                                    name:objSelLocation?.warehouseName,
                                    code:objSelLocation?.warehouseCode
                                  }
                                });
                                setLotSearchFilters({
                                  Id_Warehouse: objSelLocation.Id_Warehouse,
                                });
                            
                                if(props?.traceStockFillDefaultLot && objSelLocation?.Id_Warehouse && objSelLocation?.defaultNoLot){
                                  
                                  setLot({ value: objSelLocation.defaultNoLot.Id, label: "(" + objSelLocation.defaultNoLot.code + ") " + objSelLocation.defaultNoLot.name, lotData:{
                                    code:objSelLocation.defaultNoLot.code,
                                    name:objSelLocation.defaultNoLot.name
                                  }});
                                  setDefaultNoLotObj({
                                    Id_Lot:objSelLocation.defaultNoLot.Id,
                                    name:objSelLocation.defaultNoLot.name,
                                    code:objSelLocation.defaultNoLot.code
                                  });
                                }
            
                                
              
            
                              }else{
                                setWarehouseLocation(null);
                                setWarehouseSearchFilters({});
                                setWarehouse(null);
                                setLotSearchFilters({});
            
                              }
            
                              setShowTraceStockPopup(null);
                          }}
                          Id_Product={showLotTracePopup.traceProductData.Id_Product}
                          productCode={showLotTracePopup.traceProductData.code}
                          productName={showLotTracePopup.traceProductData.name}
                          arrayProductParameterizedVariables={showLotTracePopup.traceProductData.arrayProductParameterizedVariables}
                          onClose={() => {
                            setShowTraceStockPopup(null);
                          }}
                        />
          )}
          {showTraceStockPopup && (
            <TraceStockPopup
            notDefinedAllParameterizedVariableValues={props.notDefinedAllParameterizedVariableValues}
              onSelectStockLocation={(objSelLocation:any) => {
          
                  if(objSelLocation?.Id_WarehouseLocation){
                    //todo default no lot 
                    setWarehouseLocation({
                      label: "(" + objSelLocation.warehouseLocationCode + ") " + objSelLocation.warehouseLocationName,
                      value: objSelLocation.Id_WarehouseLocation,
                    });
                    setWarehouseSearchFilters({
                      Id_WarehouseLocation:objSelLocation.Id_WarehouseLocation,
                    });

                    setWarehouse({
                      label:  "(" + objSelLocation.warehouseCode + ") " + objSelLocation.warehouseName,
                      value: objSelLocation.Id_Warehouse,
                      warehouseData:{
                        name:objSelLocation.warehouseName,
                        code:objSelLocation.warehouseCode
                      }
                    });
                    setLotSearchFilters({
                      Id_Warehouse: objSelLocation.Id_Warehouse,
                    });
                
                    if(props?.traceStockFillDefaultLot && objSelLocation?.Id_Warehouse && objSelLocation?.defaultNoLot){
                      
                      setLot({ value: objSelLocation.defaultNoLot.Id, label: "(" + objSelLocation.defaultNoLot.code + ") " + objSelLocation.defaultNoLot.name, lotData:{
                        name:objSelLocation.defaultNoLot.name,
                        code:objSelLocation.defaultNoLot.code
                      } });
                      setDefaultNoLotObj({
                        Id_Lot:objSelLocation.defaultNoLot.Id,
                        name:objSelLocation.defaultNoLot.name,
                        code:objSelLocation.defaultNoLot.code
                      });
                    }

                  }else{
                    setWarehouseLocation(null);
                    setWarehouseSearchFilters({});
                    setWarehouse(null);
                    setLotSearchFilters({});
                  }

                  setShowTraceStockPopup(null);
              }}
              Id_Product={showTraceStockPopup.traceProductData.Id_Product}
              productCode={showTraceStockPopup.traceProductData.code}
              productName={showTraceStockPopup.traceProductData.name}
              arrayProductParameterizedVariables={showTraceStockPopup.traceProductData.arrayProductParameterizedVariables}
            onClose={() => {
              setShowTraceStockPopup(null);
            }}/>
          )}
          {showBarcodeScannerPopup && (
            <>{getPopupScannerObj()}</>
          )}
          {isGhostScannerEnabled() && (
            <>{getPopupScannerObj({ghostMode:true})}</>
          )}

          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>
              <div className={styles.title}>{props.title}</div>
              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            {props?.infoData?.productData?.code && (
              <div className={styles.selectedProductInfo}>
                <div className={styles.selcetedProductInfoLabels}>
                  ({props?.infoData?.productData?.code}){" "}
                  {props?.infoData?.productData?.name}
                </div>
              </div>
            )}

            <div
              key={"render_pop_rl_" + renderingIndex}
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >

                  <div className={styles.locationSelectorArea}>
                    <div>
                      {props?.subtitle ? props.subtitle : ""}
                      <div style={{marginBottom:20, display:'flex', justifyContent:'center'}}>
                        {props?.traceStockEnabled && (
                          <>{getBtnTraceStock()}</>
                        )}

                        {props?.lotTracerEnabled && (
                          <>{getBtnLotTracer()}</>
                        )}
                      

                        {getIsScannerEnabled() && (
                          <>
                          {
                            ( MobileWrapperCommunicationService().isDeviceMobileWrapper() ||
                              (!MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
                              scannerSettings.current.useExternalDevice)) && (
                              <>
                                <div className={styles.btnOptionContainer}>
                                  <div className={styles.btnOptionInner}  onClick={() => {
                                      setShowBarcodeScannerPopup(true);
                                    }}>
                                    <div className={styles.elementBtnContainer}>
                                      <QrCodeScannerIcon fontSize="large" />
                                    </div>
                                    <div className={styles.btnOptionLabel}><b>QRcode</b></div>
                                  </div>

                                </div>
                                
                              </>
                            )}
                          </>
                        )}

                                
                      </div>

                      {(((!props?.disableSetLocation) && props?.notDefinedAllParameterizedVariableValues !== true) || props.enableLocateStockLabels) ? (
                      <>
                        {isLoadingScanResultData ? (
                          <>
                          <div style={{width:'100%',display:'flex',justifyContent:'center'}}><CircularProgress /></div>
                          </>) : (<>
                                      <HxfStockLocationForm
                                      callbackAddWarehouseLocationCacheItems={
                                        callbackAddWarehouseLocationCacheItems
                                      }
                                      callbackAddWarehouseCacheItems={
                                        callbackAddWarehouseCacheItems
                                      }
                                      /*callbackAddLotCacheItems={callbackAddLotCacheItems}*/
                                      allowLotCreation={props?.allowLotCreation}
                                      warehouseSearchFilters={warehouseSearchFilters}
                                      lotSearchFilters={lotSearchFilters}
                                      warehouseLocationValue={warehouseLocation}
                                      warehouseValue={warehouse}
                                      defaultNoLot={defaultNoLotObj}
                                      lotValue={lot}
                                      warehouseError={warehouseError}
                                      lotError={lotError}
                                      warehouseLocationError={warehouseLocationError}
                                      onWarehouseLocationChange={(val: any) => {
                                        resetErrors();
                                        if (
                                          val === null ||
                                          warehouseLocation?.value !== val.value
                                        ) {
                                          if (val === null) {
                                            //reset warehouse search filters
                                            setWarehouseSearchFilters({});
                                            //reset lot search filters
                                            setLotSearchFilters({});

                                            //clear warehouse location
                                            setWarehouseLocation(null);
                                          } else {
                                            let label = val.label;
                                            let value = val.value;
                                            setWarehouseLocation({ label: label, value: value });
                                            setWarehouseSearchFilters({
                                              Id_WarehouseLocation: val.value,
                                            });
                                            setLotSearchFilters({
                                              Id_WarehouseLocation: val.value,
                                            });
                                          }

                                          //reset warehouse
                                          setWarehouse(null);

                                          if (useDefaultNoLotIfNoLotSelected) {
                                            setDefaultNoLotObj(null);
                                          }
                                          //reset lot
                                          setLot(null);
                                          //re render
                                          //forceRerender();
                                        }
                                      }}
                                      onWarehouseChange={(val: any) => {
                                        resetErrors();
                                        if (val === null || warehouse?.value !== val.value) {
                                          if (val === null) {
                                            if (useDefaultNoLotIfNoLotSelected) {
                                              setDefaultNoLotObj(null);
                                            }

                                            //reset warehouse
                                            setWarehouse(null);
                                            if (useDefaultNoLotIfNoLotSelected) {
                                              setDefaultNoLotObj(null);
                                            }

                                            //reset lot filters
                                            setLotSearchFilters({});
                                          } else {
                                            let label = val.label;
                                            let value = val.value;
                                            let fetchedData = val?.fetchedData;
                                            setWarehouse({
                                              label: label,
                                              value: value,
                                              warehouseData:{
                                                name:fetchedData?.name,
                                                code:fetchedData?.code
                                              }
                                            });
                                            setLotSearchFilters({ Id_Warehouse: val.value });
                                            let warehouseCachedObj = getWarehouseCacheItem(
                                              val.value
                                            );

                                            if (warehouseCachedObj === null) {
                                              //throw error
                                            } else {
                                              if (
                                                warehouseCachedObj.warehouseLocationData !== null
                                              ) {
                                                let warehouseLocationJsonObj = JSON.parse(
                                                  warehouseCachedObj.warehouseLocationData
                                                );

                                                setWarehouseLocation({
                                                  label:
                                                    "(" +
                                                    warehouseLocationJsonObj.code +
                                                    ") " +
                                                    warehouseLocationJsonObj.name,
                                                  value: parseInt(
                                                    warehouseLocationJsonObj.Id_WarehouseLocation
                                                  ),
                                                });
                                                setWarehouseSearchFilters({
                                                  Id_WarehouseLocation:
                                                    warehouseLocationJsonObj.Id_WarehouseLocation,
                                                });
                                              }

                                              if (useDefaultNoLotIfNoLotSelected) {
                                                setDefaultNoLotObj(
                                                  getDefaultNoLotForWarehouse(warehouseCachedObj)
                                                );
                                              }
                                            }
                                          }

                                          setLot(null);

                                          //forceRerender();
                                        }
                                      }}
                                      onLotChange={(val: any) => {
                                        resetErrors();
                                        
                                        if (
                                          (val && typeof val === "string") ||
                                          (val && !val?.value && val?.inputValue !== null)
                                        ) {
                                          let newLotName = "";
                                          if (typeof val === "string") {
                                            newLotName = val;
                                          }
                                          if (val?.inputValue !== undefined) {
                                            newLotName = val.inputValue;
                                          }

                                          setLot({ value: "new", label: newLotName, lotData:{name:newLotName,code:newLotName} });
                                        } else {
                                          if (val === null || lot?.value !== val.value) {
                                            if (val === null) {
                                              setLot(null);
                                            } else {
                                              let label = val.label;
                                              let value = val.value;
                                   
                                              let lotLabel = label;
                                              let fetchedData = val?.fetchedData;
                                              debugger;
                                              if(fetchedData){
                                                lotLabel = getLotLabel({name:fetchedData.name, code:fetchedData.code});
                                              }
                                              setLot({ value: value, label: lotLabel, lotData:{name:fetchedData?.name, code:fetchedData?.code} });
                                            }

                                            //forceRerender();
                                          }
                                        }
                                      }}
                                    />
                            </>)}

                      </>
                      ) : (<></>)}
                    </div>
                  </div>


            </div>
          </div>
          <div
            className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
          >
          {(!isLoadingScanResultData) && props.enableLocateStockLabels && (
            <>
              {showPrintLabelPopup && (
                <PrintLabelPopup
                title={t('label.printing')}
                onClosePopup={() => {
                  setShowPrintLabelPopup(null);
                }}
                onSuccessConfirm={() => {

                }}
                printableLabels={showPrintLabelPopup.printableLabels}
                genericLabelData={showPrintLabelPopup?.genericLabelData}
                  />
              )}
              <PrintStockLocationLabelButton disabled={!lot?.value || !hasLabelsData()} onClickConfirm={() => {
                let productLabelsData = props?.infoData?.productData.productLabelsData ? props?.infoData?.productData.productLabelsData : [];
                let genericLabelData:any = {};
                let newLotName = null;
                if(lot?.value === "new"){
                  //is creating lot
                  newLotName = lot?.label;
                }

                genericLabelData = {
                  ...genericLabelData,
                  Id_Product:props?.infoData?.productData?.Id,
                  Id_Lot:lot?.value,
                  Id_Warehouse:warehouse?.value
                };

                debugger;
                if(newLotName){
                  genericLabelData["newLotName"] = newLotName;
                }

              
                setShowPrintLabelPopup({
                  genericLabelData:genericLabelData,
                  printableLabels:getLocateStockLabels(productLabelsData)
                });
              }}/>
            </>
          )}
          {(!isLoadingScanResultData && !props?.disableSetLocation && props?.notDefinedAllParameterizedVariableValues !== true) && (
          <DefineStockLocationConfirmationButtonsPopup
            onClickConfirm={() => {
              let acceptLotEmpty =
                useDefaultNoLotIfNoLotSelected && defaultNoLotObj !== null;
              if (
                (warehouseLocation?.value && warehouse?.value && lot?.value) ||
                (warehouseLocation?.value &&
                  warehouse?.value &&
                  !lot?.value &&
                  acceptLotEmpty) ||
                (!warehouseLocation && !warehouse && !lot)
              ) {
                //allgood

                if (props?.onSuccessConfirm) {
                  let resultSuccess: any = {};
                  if (warehouseLocation) {
                    resultSuccess["Id_WarehouseLocation"] =
                      warehouseLocation.value;
                    resultSuccess["temp_warehouseLocationLabel"] =
                      warehouseLocation.label;
                  }
                  if (warehouse) {
                    resultSuccess["Id_Warehouse"] = warehouse.value;
                    resultSuccess["temp_warehouseLabel"] = warehouse.label;
                  }

                  if(warehouse?.warehouseData){
                    resultSuccess["warehouseData"] = warehouse.warehouseData;
                  }

   
                  if (lot) {
                    resultSuccess["Id_Lot"] = lot.value;
                    resultSuccess["temp_lotLabel"] = lot.label;
                  }
                  if(lot?.lotData){
                    resultSuccess["lotData"] = lot.lotData;
                  }

                  if (defaultNoLotObj) {
                    resultSuccess["defaultNoLotObj"] = defaultNoLotObj;
                  }
                  finishConfirm(resultSuccess);
                }
              } else {
                if (!warehouse?.value) {
                  setWarehouseError(true);

                  setLotError(true);
                }
                if (!lot?.value) {
                  setLotError(true);
                }
              }
            }}
          />
          )}
          </div>
        </div>
      </SimpleContentPopup>
      </div>
      </>
    )}

    </>
  );
}

export default DefineStockLocationPopup;
