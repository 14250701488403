import styles from "./DefineStockLocationConfirmationButtonsPopup.module.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { caretForwardOutline, locateOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
interface IProps {
  onClickConfirm: any;
}

function DefineStockLocationConfirmationButtonsPopup(props: IProps) {

  const {t} = useHxfTranslation();
  
  return (

      <div>
        <div className={styles.container}>
          <div className={styles.buttonsContainer}>
            <div className={`${styles.customConfirmationContainer} `}>
              <IonButton
                className={styles.confirmButton}
                data-btn={"set-location"}
                onClick={() => {
                  if (props.onClickConfirm) {
                    props.onClickConfirm();
                  }
                }}
              >
                 <IonIcon slot="start" icon={locateOutline}></IonIcon>
                {t('set.location').toUpperCase()}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
   
  );
}

export default DefineStockLocationConfirmationButtonsPopup;
