function SecurityService(){

    //since this project removes bullet points we want to replace <li> with <li>&#8226; etc..
    const customPostSanitizeReplacers = (contentString:any) => {
        // Replace all occurrences of "<li>" with "<li>&#8226;"

        //onl needed in backoffice
        //contentString = contentString.replace(/<li>/g, '<li><span style="margin-right:10px">&#8226;</span>');
        return contentString;
    }

    /**
     * 
     * sanitizes text to html, add all supported tags from wysiwig
     * @param content string
     */
    function hxfLabelRendererContentHtmlSanitize(content:string){
        
        // const clean = DOMPurify.sanitize(content,{ALLOWED_TAGS: ['br','p','b', 'i', 'em', 'strong','s','span','del','ins','ul','li'], ALLOWED_ATTR: ['style']});
        let clean = customSanitize(content,['br','p','b', 'i', 'em', 'strong','s','span','del','ins','ul','li'] , ['style']);
        clean = customPostSanitizeReplacers(clean);
        console.log("clean sanitized: ", clean);
        return clean;
    }


    function customSanitize(content:any, allowedTags:any, allowedAttributes:any) {
        const doc = new DOMParser().parseFromString(content, 'text/html');
        
        function recursivelyClean(node:any) {
            for (let i = node.children.length - 1; i >= 0; i--) {
                const child = node.children[i];

                if (allowedTags.indexOf(child.tagName.toLowerCase()) === -1) {
                    node.removeChild(child);
                } else {
                    // Remove disallowed attributes
                    for (let j = child.attributes.length - 1; j >= 0; j--) {
                        const attr = child.attributes[j];
                        if (allowedAttributes.indexOf(attr.name.toLowerCase()) === -1) {
                            child.removeAttribute(attr.name);
                        }
                    }

                    // Recursively clean child nodes
                    recursivelyClean(child);
                }
            }
        }

        recursivelyClean(doc.body);

        // Serialize the cleaned HTML
        const cleanedContent = new XMLSerializer().serializeToString(doc.body);
        
        return cleanedContent;
    }


    return {hxfLabelRendererContentHtmlSanitize};
}

export default SecurityService;