import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import HxfKeyRippleEffectCustom from "../../../../HxfDigitKeyboard/HxfKeyRippleEffectCustom/HxfKeyRippleEffectCustom";
import styles from "./ActionScreenMachineElement.module.scss";
import Ripples from "react-ripples";
function ActionScreenMachineElement(props: any) {
  const getElement = () => {
    let innerClickableElement = (
      <div
        className={` ${styles.descIconContainer} ${
          !props?.enabled ? styles.disabledBtn : ""
        }`}
      >
        <div className={styles.addIconContainer}>
          <IonIcon icon={addOutline} size="large" />
        </div>
        <div className={styles.codeNameContainer}>
          <div className={styles.code}>({props.code})</div>
          <div>{props.name}</div>
        </div>
      </div>
    );

    if (!props?.enabled) {
      return <> {innerClickableElement}</>;
    }
    return (
      <Ripples
        onClick={() => {
          if (props?.onClick) {
            props.onClick();
          }
        }}
      >
        {innerClickableElement}
      </Ripples>
    );
  };

  return (
    <div
      className={`${styles.outerContainer} ${
        props.selected === true
          ? styles.outerContainerSelected
          : styles.outerContainerUnselected
      }`}
    >
      {getElement()}
    </div>
  );
}

export default ActionScreenMachineElement;
