import { useIonAlert, useIonToast } from "@ionic/react";
import { alarmOutline, closeCircle, documentOutline, homeOutline, readerOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import productionController from "../../../../../barrel/controllers/productionController";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../../../../barrel/hooks/usePrevious";
import useProductionProcess from "../../../../../barrel/hooks/useProductionProcess";
import usePunchclock from "../../../../../barrel/hooks/usePunchclock";
import useSyncSettings from "../../../../../barrel/hooks/useSyncSettings";
import ActionManagerService from "../../../../../barrel/services/actionManagerService";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
import SystemSoundService from "../../../../../barrel/services/systemSoundService";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import { dispatchCustomPopupsStore } from "../../../../CustomElements/ProductionUI/CustomPopups/CustomPopupsStore";
import { useActionScreenManagerStore } from "../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreensManager/ActionScreenManagerStore";
import ProductionTopInfo from "../../../../CustomElements/ProductionUI/ProductionTopInfo/ProductionTopInfo";
import TimeElapsedLabelV2 from "../../../../CustomElements/TimeElapsedLabelV2/TimeElapsedLabelV2";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import { IonButton, IonIcon } from "@ionic/react";
import {
  dispatchProductionUIStore,
  useProductionUIStore
} from "../../../ProductionOrders/Standard/store/productionConfirmationUIStore";
import {
  dispatchProductionOrderSelectionStore,
  useProductionOrderSelectionStore
} from "../../../ProductionOrders/Standard/store/productionOrderSelectionStore";
import CornerButton from "./CornerButton/CornerButton";
import styles from "./ProductionProcess.module.scss";
import ViewFilesPopup from "../../../../CustomElements/statePopups/viewFilesPopup/viewFilesPopup";
import HxfSafeHtmlContentRender from "../../../../../components/HxfSafeHtmlContentRender/HxfSafeHtmlContentRender";
/*
export interface ILoadedData {
  isLoaded: boolean;
  isLastOperation: boolean;
  currentOperation: any;
  nextOperation: any;
  productionProduct: any;
  productionOrder: any;
  production:any;
}
*/
function ProductionProcess(props: any) {
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const DISABLE_REMINDER = true; //TODO before enabling, separate into a different setting for the "alert", also fix alert acumulation issue
  const productionProcess = useProductionProcess();
  const localStorage = useLocalStorageHandler();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "ProductionStartStage",
  });
  const {t} = useHxfTranslation();
  const syncSettings = useSyncSettings();
  const punchclock = usePunchclock();
  const timerDispatch = useRef(setTimeout(() => {}, 0));


  const timerReminderPendingExecution = useRef<any>(null);
  const lastTimeReminded = useRef(0);

  const productionId = parseInt(props.match.params.productionId);
  const previousProductionId = usePrevious(productionId);

  const [didMount, setDidMount] = useState(false);
  const [showViewFiles, setShowViewFiles] = useState<any>(null);

  const [productionData, setProductionData] = useState<any>({}); //holds the loaded production data
  const currentProductionUIStoreData = useProductionUIStore();
  const currentProductionOrderSelectionData =
    useProductionOrderSelectionStore();
  
  const { appState } = useGlobalState();
  const [showElapsedTimer, setShowElapsedTimer] = useState(false);

  const firstTimeAlert = useRef<any>(null);
  const [triggerRemindAlert, setTriggerRemindAlert] = useState(1);
  const [restartReminder, setRestartReminder] = useState(1);


  const prevRestartReminder = usePrevious(restartReminder);
  const prevCountRemindAlert = usePrevious(triggerRemindAlert);
  //deprecated
  /*const [productionStepData, setProductionStepData] = useState({
    isLastOperation: false,
    currentOperation: {},
    nextOperation: {},
    productionProduct: {},
    productionOrder: {},
    production:{}
  } as ILoadedData);*/

  const isProductionDataLoaded = () => {
    return (
      "production" in currentProductionOrderSelectionData &&
      "Id" in currentProductionOrderSelectionData.production
    );
  };

  useEffect(() => {
  
    if(prevCountRemindAlert !== triggerRemindAlert && prevCountRemindAlert){
      if(!DISABLE_REMINDER){
        console.log("Triggering remind alert");
        callTriggerRemindAlert();
        initiateTimerReminderPendingExecution();
      }
    }

  }, [triggerRemindAlert]);// eslint-disable-line

  useEffect(() => {
  
    if(prevRestartReminder !== restartReminder && prevRestartReminder){
      if(!DISABLE_REMINDER){
        console.log("Triggering restarting reminder");
        initiateTimerReminderPendingExecution();
      }

    }

  }, [restartReminder]);// eslint-disable-line
  //
  
  const callTriggerRemindAlert = useCallback((argPendingDistributionExecutionReminderData:any = null) => {

    let _pendingDistributionExecutionReminderData = argPendingDistributionExecutionReminderData ? argPendingDistributionExecutionReminderData : currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData;
   
    if(_pendingDistributionExecutionReminderData){
      console.log("Remind alert triggered!");
      SystemSoundService().alertSound();
      presentToast({
        message: t('reminder') + '! - ' + _pendingDistributionExecutionReminderData.operationProduct_name,
        position:'middle',
        icon:alarmOutline,
        cssClass:'hxfAlertToast',
        buttons:[{
          role:'cancel',
          text:t('understood')
        }],
        color:'warning',
        duration: 3600000});


      //todo, sound alert, show side popup
    }
  },[presentToast,currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData]); // eslint-disable-line

  const initiateTimerReminderPendingExecution = useCallback((curOperationData = null,argOperationDuration = null, productionRecordDateStart = null,argpendingDistributionExecutionReminderData = null) => {
    let reminderSkipFirstExecution = curOperationData?.reminderSkipFirstExecution === 1;

    clearTimeout(timerReminderPendingExecution.current);
    let operationExpectedDuration = argOperationDuration ? argOperationDuration : currentProductionOrderSelectionData?.currentOperation?.expectedDuration;

    let _pendingDistributionExecutionReminderData = argpendingDistributionExecutionReminderData ? argpendingDistributionExecutionReminderData : currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData;
    


    let prDateStart = productionRecordDateStart ? productionRecordDateStart : currentProductionOrderSelectionData.productionRecord.dateStart;
    let dateStartObj = DatesConversionService().convertedDateObject(prDateStart);
    let currentDate = new Date();

    let timeDiffMs: any = currentDate.getTime() - dateStartObj.getTime();
    let currentTimeElapsed = timeDiffMs / 1000;
   
    let currentTs = Date.now()/1000;
    let extraDelay = 0;

    let pendingOneEveryExecutionsQty = _pendingDistributionExecutionReminderData?.pendingOneEveryExecutionsQty ? _pendingDistributionExecutionReminderData?.pendingOneEveryExecutionsQty : 0;

 
    let remindEveryXSeconds = operationExpectedDuration*pendingOneEveryExecutionsQty;
    if(!reminderSkipFirstExecution && currentTimeElapsed > operationExpectedDuration && !firstTimeAlert.current){
      console.log("Remind triggered on start");
      lastTimeReminded.current = currentTs;

      callTriggerRemindAlert(_pendingDistributionExecutionReminderData);
      extraDelay = 5;
      firstTimeAlert.current = true;
    }

    let nextRemindAt = (remindEveryXSeconds+extraDelay)*1000;
    console.log("next remind in: ", nextRemindAt);
    timerReminderPendingExecution.current = setTimeout(() => {
 
      let currentTs = Date.now()/1000;

      let disableSpamDelayControl = false;

      let timeDiff = currentTs - lastTimeReminded.current;
 
      if(disableSpamDelayControl || timeDiff > 60){
        lastTimeReminded.current = currentTs;
      
        firstTimeAlert.current = true;

        console.log("Remind triggered interval");
        setTriggerRemindAlert(triggerRemindAlert +1);
      }else{
        setTimeout(() => {
          setRestartReminder(restartReminder + 1);
        },1000);
        
      }


    }, nextRemindAt);

    //currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData
  },[restartReminder,callTriggerRemindAlert,triggerRemindAlert,currentProductionOrderSelectionData.productionRecord?.dateStart,currentProductionOrderSelectionData?.currentOperation?.expectedDuration,currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData]);

  const loadCurrentProduction = useCallback(() => {
    clearTimeout(timerDispatch.current);

    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    
    if(appState.isConnectionAvailable()){
      ActionManagerService().resetAllScreensState();
      productionController()
      .getWorkerProduction(currentWorkerCode, productionId, true, cancelToken)
      .then((res) => {
        setShowElapsedTimer(true);

        syncSettings.check(res);
        console.log("Production loaded successfully.");

        let productionResp = res?.data?.response?.data;

        if (!productionResp) {
          throw "unexpected production resp";
        }
        let requiresPunchclockIn =
          res?.data?.response?.data?.extra?.requirePunchclockIn;
        if (requiresPunchclockIn) {
          punchclock.redirRequirePunchclockIn();
          return;
        }
        setProductionData(productionResp);

        let isActive = false;
        let isLastOperation = true;
        let currentOperation: any = null;
        let nextOperation: any = null;
        let productionProduct: any = null;
        let productionOrder = productionResp.productionOrder;

        let productionOrderProduct = null;
        let pops = productionOrder?.productionOrder_productsData;
        
        if(pops){
          for(let i = 0; i<pops.length; i++){
            if(parseInt(pops[i].Id_ProductionOrder_Product ) === productionResp.Id_ProductionOrder_Product){
              productionOrderProduct = pops[i];
              break;
            }
          }
        }
       
        let productionRecord = {
          Id: productionResp.Id,
          ref:productionResp.ref,
          _parentRef:productionResp?._parentRef,
          scaleProductivity: productionResp.scaleProductivity,
          Id_ProductionOrder_Product: productionResp.Id_ProductionOrder_Product,
          dateStart: productionResp.dateStart,
          dateEnd: productionResp.dateEnd,
          isWaitingResume: productionResp.isWaitingResume,
          _elapsedSeconds:productionResp._elapsedSeconds,
          selectedMachines:productionResp?.selectedMachines
        };
        let production = {
          Id: productionResp.Id_Production,
          persistedStateResult: productionResp.production.persistedStateResult,
        };
        let productionOrderProduct_ParameterizedVariableValues = {};
        if (productionResp.productionOrderProduct_ParameterizedVariableValues) {
          productionOrderProduct_ParameterizedVariableValues =
            productionResp.productionOrderProduct_ParameterizedVariableValues;
        }
        if (productionRecord.isWaitingResume) {
          dispatchProductionUIStore({
            type: "SET_TIMER_ELAPSED_ENABLED",
            payload: false,
          });
          dispatchProductionUIStore({
            type: "SET_OPERATION_OVERLAY",
            payload: true,
          });
        } else {
          dispatchProductionUIStore({
            type: "SET_TIMER_ELAPSED_ENABLED",
            payload: true,
          });
          dispatchProductionUIStore({
            type: "SET_OPERATION_OVERLAY",
            payload: false,
          });
        }
        //check if production is still active
        if (productionResp.dateStart == productionResp.dateEnd) {
          isActive = true;
        }

        if (!isActive) {
          console.log("this production record has already been closed");
          props.history.push("/feature/production-orders");
        } else {
          //is active

          //product object
          if (!productionResp.product) {
            //an error occurred
            console.log("Failed to find the production product");
            throw new Error("Failed to find the production product");
          }
          productionProduct = productionResp.product;

          //find the operation we are working on
          for (let i = 0; i < productionResp.product.operations.length; i++) {
            let iterOperation = productionResp.product.operations[i];
            if (iterOperation.Id === productionResp.Id_OperationProduct) {
              currentOperation = productionResp.product.operations[i];
              break;
            }
          }

          if (currentOperation === null) {
            //an error occurred
            console.log(
              "Failed to find the production operation in the product operations"
            );
            throw new Error(
              "Failed to find the production operation in the product operations"
            );
          }

          let idNextOperation = null;

          for (
            let i = 0;
            i < productionResp.product.operationsRelations.length;
            i++
          ) {
            let iterRelation = productionResp.product.operationsRelations[i];
            //check if it is the last operation
            if (iterRelation["Id_OperationProduct"] === currentOperation.Id) {
              isLastOperation = false;
              //get next Operation Id

              idNextOperation = iterRelation["Id_Next_OperationProduct"];
            }
          }

          //find next operation obj
          if (idNextOperation != null) {
            for (let i = 0; i < productionResp.product.operations.length; i++) {
              let iterOperation = productionResp.product.operations[i];
              if (iterOperation.Id === idNextOperation) {
                nextOperation = productionResp.product.operations[i];
                break;
              }
            }
          }

     
          if(currentOperation?._pendingDistributionExecutionReminderData){
           
            initiateTimerReminderPendingExecution(currentOperation,currentOperation.expectedDuration, productionRecord.dateStart, currentOperation?._pendingDistributionExecutionReminderData);
          }
          

          let prTempStore = productionResp?.prTempStore ? productionResp.prTempStore : [];
          //prtempstore proper load

          let cleanArrStoreData = [];
          for(let i = 0; i<prTempStore.length; i++){
           let element = prTempStore[i];
           let preparedData = null;
            try {
              preparedData = JSON.parse(prTempStore[i].data);
            } catch (error) {
              console.error("error in pr temp store parsing..");
            }

            if(preparedData){
              element.data = preparedData;
              cleanArrStoreData.push(element)
            }

          }
          prTempStore = cleanArrStoreData;
          //
          //end of checks
         
          dispatchProductionOrderSelectionStore({
            type: "SET_STORE",
            payload: {
              isCurrentOperationTheLast: isLastOperation,
              productionOrder: productionOrder,
              productionOrderProduct_ParameterizedVariableValues:productionOrderProduct_ParameterizedVariableValues,
              product: productionProduct,
              currentOperation: currentOperation,
              currentOperationActionIndex: 0,
              nextOperation: nextOperation,
              productionRecord: productionRecord,
              production: production,
              prTempStore:prTempStore,
              productionOrderProduct:productionOrderProduct
            },
          });
        }
      })
      .catch((res) => {
        if (isCancel(res)) {
          return;
        }
        console.log(" failed to load production: ", res);
        //error failed
        
        props.history.push("/feature/production-orders");

        try {
          //other error dealing
          let errorRes = res.error.exceptionObj.response;
          if (errorRes.status == 400) {
            if (errorRes.data.response) {
              let errors = errorRes.data.response;
              if (errors.issues) {
                console.log("has errors, handle here");
              }
            }
          }
        } catch (ex) {
          console.log("failed to handle error");
        }
      });
    }
    

  }, [
    localStorage,
    punchclock,
    productionId,
    props,
    cancelToken,
    isCancel,
    syncSettings,
    appState,
    initiateTimerReminderPendingExecution
  ]);

  const getInformationContainer = () => {

    let informationObject:any = {};

    //we only show the information container if it has "relevant info to show"
    if(currentProductionOrderSelectionData?.productionRecord?.selectedMachines && currentProductionOrderSelectionData?.productionRecord?.selectedMachines.length > 0){
      informationObject = {...informationObject, machineName:currentProductionOrderSelectionData?.productionRecord?.selectedMachines[0]?.machineName};
    }

    if(currentProductionOrderSelectionData?.productionRecord?.ref && currentProductionOrderSelectionData?.productionRecord?.ref !== ""){
      informationObject = {...informationObject, ref:currentProductionOrderSelectionData?.productionRecord?.ref };
    }
    
    if(currentProductionOrderSelectionData?.productionRecord?._parentRef && currentProductionOrderSelectionData?.productionRecord?._parentRef !== ""){
      informationObject = {...informationObject, _parentRef:currentProductionOrderSelectionData?.productionRecord?._parentRef };
    }

    if(currentProductionOrderSelectionData?.productionOrderProduct?.description && currentProductionOrderSelectionData?.productionOrderProduct?.description !== ""){
      informationObject = {...informationObject, popDescription:currentProductionOrderSelectionData?.productionOrderProduct?.description };
    }

    let spaceDriveFiles = currentProductionOrderSelectionData?.currentOperation?._spaceDriveFiles;
    if(spaceDriveFiles){
      informationObject = {...informationObject, spaceDriveFiles:spaceDriveFiles };
    }

  
    //check if informationObject has relevant info
    let infoObjKeys = Object.keys(informationObject);
    if(infoObjKeys.length === 0){
      return (<></>)
    }

    return (
      <div className={styles.productionStartPreviewContainer}>
      <div className={styles.elemElement} style={{backgroundColor:'#cae7ff70'}}>
        <div style={{textAlign:'center',margin:9}}>
          <div>
            <b>{t('information')}</b>
          </div>{" "}
        </div>
        <div className={styles.separator}></div>
        
        {informationObject?.popDescription && (
                  <div className={styles.operationDescription}>
                  <div>
            
                    
                        <div className={styles.popDescriptionTitle}>
                            <b>{t('description')}</b>
                        </div>
                        <div>
                          <HxfSafeHtmlContentRender>
                            {informationObject?.popDescription}
                          </HxfSafeHtmlContentRender>
                        </div>
            
                
                  </div>
        
                </div>
        )}
        <div className={styles.operationDescription}>
          <div>
          {informationObject?._parentRef && (
            <>
            
                <div className={styles.infoSection}>
                    <b>{t('parent.ref')}:</b> {informationObject._parentRef}
                </div>
            
            </>
          )}
          {(informationObject?.ref && !informationObject?._parentRef) && (
          <div className={styles.infoSection}>
              <b>{t('ref')}:</b> {informationObject.ref}
          </div>
          )}

          {informationObject.machineName && (
            <div className={styles.infoSection}>
                  <b>{t('machine')}:</b> {informationObject.machineName}
            </div>
          )}
          </div>

        </div>

        {informationObject?.spaceDriveFiles && informationObject?.spaceDriveFiles.length > 0 && (
          <>
            <div className={styles.viewFilesContainer}>
                <IonButton
                    className={styles.viewFilesBtn}
                    size={"large"}
                    onClick={() => {
                        setShowViewFiles(spaceDriveFiles);
                    }}
                >
                    <IonIcon style={{marginRight:5}} slot="icon-only" icon={readerOutline} size={"small"}></IonIcon>
                    {t('view.files')}
                </IonButton>
            </div>
          </>
        )}
      </div>
    </div>
    )
  }


  const isZeroDotSomething = () => {

    let distributionEvery = parseInt(currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData?.pendingOneEveryExecutionsQty);

    if(distributionEvery === 0){
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (!didMount) {
      //triggers on load
      loadCurrentProduction();
      setDidMount(true);
    }

    () => {
      console.log("umounting process");
      clearTimeout(timerDispatch.current);
      if(timerReminderPendingExecution?.current){
        clearTimeout(timerReminderPendingExecution.current);
      }
     
    };
  }, [didMount, loadCurrentProduction]);

  useEffect(() => {
    if (
      previousProductionId !== undefined &&
      previousProductionId !== productionId
    ) {
      //triggers on "NEXT"
      //console.log("CHANGED FROM: " + previousProductionId + " to " + productionId);
      loadCurrentProduction();
    }
  }, [productionId, previousProductionId, loadCurrentProduction]);

  if (!isProductionDataLoaded()) {
    return (
      <InAppTemplate>
        <ProductionTopInfo
          productionOrder={currentProductionOrderSelectionData.productionOrder}
          product={currentProductionOrderSelectionData.productionProduct}
          currentOperation={
            currentProductionOrderSelectionData.currentOperation
          }
          nextOperation={currentProductionOrderSelectionData.nextOperation}
        />
        <CustomArrowBack pushUrl="/feature/production-orders" />
        <LoadingSpinnerImg />
      </InAppTemplate>
    );
  }

  console.log(
    "current production order selection data:",
    currentProductionOrderSelectionData
  );
  return (
    <InAppTemplate>
      <>
     
        {showViewFiles && (
          <ViewFilesPopup Id_OperationProduct={currentProductionOrderSelectionData.currentOperation.Id} files={showViewFiles} onClosePopup={() => {
            setShowViewFiles(null);
          }}/>
        )}
       
        <ProductionTopInfo
          productionOrder={currentProductionOrderSelectionData.productionOrder}
          product={currentProductionOrderSelectionData.productionProduct}
          currentOperation={
            currentProductionOrderSelectionData.currentOperation
          }
          nextOperation={currentProductionOrderSelectionData.nextOperation}
        />

        {currentProductionUIStoreData.showOperationOverlay && (
          <div className={styles.overlayContainer}></div>
        )}

        <div className={styles.topPageContainer}>
          <div></div>
          <div className={styles.pageTitle}>{t("operation")}</div>
          <div style={{position:'absolute',right:0}}>
            <div className={styles.cornerButtons}>
              <CornerButton
                label={t("home")}
                onClick={() => {
                  present({
                    cssClass: "my-css",
                    header: t("minimize"),
                    message: t("minimize.production.confirm"),
                    buttons: [
                      t("cancel"),
                      {
                        text: t('yes'),
                        handler: (d) => {
                          setShowElapsedTimer(false);
                          dispatchProductionUIStore({
                            type: "SET_ELAPSED_ENABLED",
                            payload: false,
                          });
                   
                          let idPo = currentProductionOrderSelectionData.productionOrder.Id;
                          let idPop = productionData.Id_ProductionOrder_Product;
                          
                          props.history.push("/feature/production-order/" + idPo + "/po-product/" + idPop);
                        },
                      },
                    ],
                    onDidDismiss: (e) => {},
                  });
                }}
                iconPassed={homeOutline}
              />
              <CornerButton
                onClick={() => {
                  present({
                    cssClass: "my-css",
                    header: t("production.cancellation"),
                    message: t("production.cancel.confirm"),
                    buttons: [
                      t("cancel"),
                      {
                        text: t("yes"),
                        handler: (d) => {
                  
                          let allowOperationPrCollab = currentProductionOrderSelectionData.currentOperation.allowOperationPrCollab === 1 ? true : false;
                          if(allowOperationPrCollab){

                            dispatchCustomPopupsStore({
                              type: "SHOW_COLLAB_CANCEL_POPUP",
                            });
                            
                          }else{
                            setShowElapsedTimer(false);
                            dispatchProductionUIStore({
                              type: "SET_ELAPSED_ENABLED",
                              payload: false,
                            });
                    
                            productionProcess.cancelRecord();
                          }
 
                        },
                      },
                    ],
                    onDidDismiss: (e) => {},
                  });
                }}
                btnType={"danger"}
                label={t("null.record")}
                iconPassed={closeCircle}
              />
            </div>
          </div>

        </div>
        <div style={{display:'flex',flexWrap:'wrap',width:'100%',justifyContent:'center',marginTop:25}}>
          {getInformationContainer()}
          
          <div className={styles.productionStartPreviewContainer}>
            <div className={styles.elemElement}>
              <div className={styles.elemTitle}>
                <div style={{marginRight:15}}>
                  ({currentProductionOrderSelectionData.currentOperation.code}){" "}
                  {currentProductionOrderSelectionData.currentOperation.name}
                </div>{" "}
                <div style={{marginLeft:15}}>
                  {" "}
                  {showElapsedTimer && (
                    <div className={styles.operationTakingTime}>
                      <TimeElapsedLabelV2 timerEnabled={currentProductionUIStoreData.timerElapsedEnabled} startFromSeconds={currentProductionOrderSelectionData.productionRecord._elapsedSeconds}/>

                    </div>
                  )}
                </div>
              </div>
              <div className={styles.separator}></div>

              <div className={styles.operationDescription}>
                <div>
                  <div>
                  {currentProductionOrderSelectionData.currentOperation.description}
                  </div>
                  
                  {currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData &&(
                    <div className={styles.pdeReminderDataInfo}>
                      <div>{t('execute')} <b>1</b> {t('operation.lower')} <b>{currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData?.operationProduct_name}</b> {isZeroDotSomething() ? (<></>) : (<>{t('at.every')} <b>{parseInt(currentProductionOrderSelectionData?.currentOperation?._pendingDistributionExecutionReminderData?.pendingOneEveryExecutionsQty)}</b> {t('operations.of.type')} <b>{currentProductionOrderSelectionData.currentOperation.name}</b> </>)}  </div>
                    </div>
                  )}
                </div>
            

              </div>
            </div>
          </div>

        </div>
      </>
    </InAppTemplate>
  );
}
export default withRouter(ProductionProcess);
