import { useState, useEffect, useLayoutEffect } from "react";
//https://github.com/SteveKanger/react-global-store-hook
//haxify custom version. v0

interface IStore {
  type: any;
  payload: any;
}

const effect = typeof window === "undefined" ? useEffect : useLayoutEffect;
const isFn = (fn: any) => typeof fn === "function";
const isObj = (obj: any) =>
  typeof obj === "object" &&
  typeof obj !== "function" &&
  !Array.isArray(obj) &&
  obj !== null;

const shouldUpdate = (state: any, newState: any) => {
  if (state === newState) return false;

  if (isObj(state) && isObj(newState)) {
    for (let key in newState) {
      if (state[key] !== newState[key]) return true;
    }
    return false;
  }

  return true;
};

const createStore = (initialStore: any, reducer: any) => {
  let store = initialStore;
  const listeners = new Set<any>();

  const getStore = () => store;

  const dispatch = (action: any) => {
    if (reducer) {
      store = reducer(store, action);
    } else {
      store = isFn(action) ? action(store) : action;
    }

    listeners.forEach(({ state, mapState, updater }) => {
      const newState = mapState(store);
      if (shouldUpdate(state, newState)) updater(() => newState);
    });
  };

  const useStore = (mapState: any = (store: IStore) => store) => {
    const [, updater] = useState();
    const state = mapState(store);

    effect(() => {
      const listener = {
        updater,
        state,
        mapState,
      };

      listeners.add(listener);
      return () => {
        listeners.delete(listener);
      };
    }, [state, mapState]);

    return state;
  };

  return [useStore, dispatch, getStore];
};

export default createStore;
