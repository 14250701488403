import CustomErrorNotice from "../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import ErrorNoticeCriticalError from "../../CustomElements/ErrorNoticeCriticalError/ErrorNoticeCriticalError";
function CriticalErrorPage() {
  return (
    <InAppTemplate>
      <ErrorNoticeCriticalError />
    </InAppTemplate>
  );
}

export default CriticalErrorPage;
