import axios from "axios";
import base64url from "base64url";
import sha256 from "crypto-js/sha256";

function useBarrelOauth() {
  const haxifyOauthLocalstorageName = "haxifyOauthDb";
  const oauthServerEndpoint = "http://localhost:8001/";
  const barrelConsentUrl = oauthServerEndpoint + "oauth2/consent";
  const barrelExchangeUrl =
    oauthServerEndpoint + "oauth2/exchange-authorization-code";

  const initiateResponseWait = (windowReference) => {
    setTimeout(() => {
      console.log("Checking oauth resp...");
      let oauthHaxifyDb = getCurrentHaxifyLocalStorageDb();
      if (oauthHaxifyDb["oauth_finished_authenticating_response"] !== "") {
        console.log(
          "FINISHED, response: ",
          oauthHaxifyDb["oauth_finished_authenticating_response"]
        );
        console.log("closing: ", windowReference);
        //comment lines bellow to check connection result design
        windowReference.close();
        //window.location.replace("/entity-switch");
        window.location.replace("/");
      } else {
        initiateResponseWait(windowReference);
      }
    }, 1000);
  };

  /**
   * todo implement this, todo check for this on initiateresponsewait
   * @param {*} response_code can be: SUCCESS, EXPIRED, UNEXPECTED_ERROR
   */
  const giveOauthResponseFeedback = (
    response_code,
    hasMultipleEntities = false
  ) => {
    let oauthHaxifyDb = getCurrentHaxifyLocalStorageDb();
    oauthHaxifyDb["oauth_finished_authenticating_response"] = response_code;
    haxifySaveOauthDb(oauthHaxifyDb);
  };

  const haxifyOauthLocalStorageObject = () => {
    return {
      oauth_authenticating: {},
      oauth_finished_authenticating_response: "",
    };
  };

  const localAppExchangeAuthorizationCode = (
    authorization_code,
    code_verifier
  ) => {
    return axios.post(barrelExchangeUrl, {
      authorization_code: authorization_code,
      code_verifier: code_verifier,
    });
  };

  const webAppExchangeAuthorizationCode = (authorization_code) => {
    return axios.post(barrelExchangeUrl, {
      authorization_code: authorization_code,
    });
  };

  const getCurrentHaxifyLocalStorageDb = () => {
    let currentLocalStorageObject = localStorage.getItem(
      haxifyOauthLocalstorageName
    );
    if (
      currentLocalStorageObject === undefined ||
      currentLocalStorageObject == null
    ) {
      currentLocalStorageObject = haxifyOauthLocalStorageObject();
    } else {
      currentLocalStorageObject = JSON.parse(currentLocalStorageObject);
    }
    return currentLocalStorageObject;
  };

  const haxifySaveOauthDb = (updatedObj) => {
    localStorage.setItem(
      haxifyOauthLocalstorageName,
      JSON.stringify(updatedObj)
    );
  };
  const storeCodeVerifier = (code_verifier) => {
    let oauthHaxifyDb = getCurrentHaxifyLocalStorageDb();
    oauthHaxifyDb["oauth_authenticating"]["code_verifier"] = code_verifier;
    haxifySaveOauthDb(oauthHaxifyDb);
  };
  const storeState = (state) => {
    let oauthHaxifyDb = getCurrentHaxifyLocalStorageDb();
    oauthHaxifyDb["oauth_authenticating"]["state"] = state;
    haxifySaveOauthDb(oauthHaxifyDb);
  };
  const getCodeVerifier = () => {
    let oauthHaxifyDb = getCurrentHaxifyLocalStorageDb();
    if (
      oauthHaxifyDb["oauth_authenticating"] !== undefined &&
      oauthHaxifyDb["oauth_authenticating"]["code_verifier"] !== undefined
    ) {
      return oauthHaxifyDb["oauth_authenticating"]["code_verifier"];
    }
    return "";
  };

  const getState = () => {
    let oauthHaxifyDb = getCurrentHaxifyLocalStorageDb();
    if (
      oauthHaxifyDb["oauth_authenticating"] !== undefined &&
      oauthHaxifyDb["oauth_authenticating"]["state"] !== undefined
    ) {
      return oauthHaxifyDb["oauth_authenticating"]["state"];
    }
    return "";
  };

  const generateRandomString = () => {
    let ts = new Date();
    let tsString = parseInt(ts.getTime() / 1000);

    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 255; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    result = tsString + "" + result;
    result = result.substring(0, 128);
    return result;
  };

  const generateCodeChallenge = (codeVerifier) => {
    // get ascii(codeverifier)
    //let asciiCodeVerifier = getCharCodes(codeVerifier);
    let sha256codeVerifier = sha256(codeVerifier);
    let base64urlSha256CodeVerifier = base64url(sha256codeVerifier.toString()); //base64url WITHOUT pading important due to beeing passed on urls
    return base64urlSha256CodeVerifier;
  };

  /*const getCharCodes = (s) => {
        //let charCodeArr = [];
        let charCodeFullStr = "";
        for(let i = 0; i < s.length; i++){
            let code = s.charCodeAt(i);
            //charCodeArr.push(code);
            charCodeFullStr = charCodeFullStr + "" + code;
        }
        
        //return charCodeArr;
        return charCodeFullStr;
    }*/

  const generateCodeVerifierAndChallenger = () => {
    let codeVerifier = generateRandomString();
    let codeChallenger = generateCodeChallenge(codeVerifier);

    return { code_verifier: codeVerifier, code_challenge: codeChallenger };
  };

  const generateState = () => {
    return generateRandomString();
  };
  const openConsentOauthUrl = (app_type, client_app_id, redirect_uri) => {
    if (app_type === "localapp") {
      let codeVerifierAndChallenge = generateCodeVerifierAndChallenger();
      storeCodeVerifier(codeVerifierAndChallenge.code_verifier);
      giveOauthResponseFeedback("");
      let myOpeningWindow = window.open(
        barrelConsentUrl +
          "/?client_app_id=" +
          client_app_id +
          "&redirect_uri=" +
          redirect_uri +
          "&scopes=haxify.profile&code_challenge=" +
          codeVerifierAndChallenge.code_challenge,
        "targetWindow",
        `toolbar=no,
             location=no,
             status=no,
             menubar=no,
             scrollbars=yes,
             resizable=yes,
             width=500,
             height=600`
      );
      console.log("Saving window ", myOpeningWindow);

      initiateResponseWait(myOpeningWindow);
    } else if (app_type === "webapp") {
      let stateGenerated = generateState();
      storeState(stateGenerated);
      giveOauthResponseFeedback("");
      let myOpeningWindow = window.open(
        barrelConsentUrl +
          "/?client_app_id=" +
          client_app_id +
          "&redirect_uri=" +
          redirect_uri +
          "&scopes=haxify.profile&state=" +
          stateGenerated,
        "targetWindow",
        `toolbar=no,
             location=no,
             status=no,
             menubar=no,
             scrollbars=yes,
             resizable=yes,
             width=500,
             height=600`
      );
      console.log("Saving window ", myOpeningWindow);

      initiateResponseWait(myOpeningWindow);
    } else {
      console.log("error unexpected app type");
      return;
    }

    console.log("CUR OBJ ", getCurrentHaxifyLocalStorageDb());
  };

  //Todo, on opening, create check for when localstorage "haxify_oauth_last_ts" changes, if > latest set then, continue oauth flow

  return {
    initiateResponseWait,
    giveOauthResponseFeedback,
    getState,
    openConsentOauthUrl,
    getCodeVerifier,
    webAppExchangeAuthorizationCode,
    localAppExchangeAuthorizationCode,
  };
}

export default useBarrelOauth;
