import { IonButton, IonIcon } from "@ionic/react";
import { TextField } from "@mui/material";
import { closeOutline } from "ionicons/icons";
import React, { Suspense, useEffect, useState } from "react";
import { withRouter } from "react-router";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import {
  dispatchCustomPopupsStore,
  useCustomPopupsStore
} from "../CustomPopupsStore";
import styles from "./CustomIntegrationsPopup.module.scss";

const HxfTcpExampleIntegrationView = React.lazy(() => import("./IntegrationsViews/hxfTcpExampleIntegration/hxfTcpExampleIntegration"));

function CustomIntegrationsPopup(props: any) {
  const popupsStore = useCustomPopupsStore();

  //enable popup with:  dispatchCustomPopupsStore({type:"SET_STORE",payload:{...popupsStore, onGoingProductionsPopup:true}});
  //on close, clear state and clear popup data
  const [integrationIdentifierCode, setIntegrationIdentifierCode] = useState("");
  const [selectedIdentifierCode, setSelectedIdentifierCode] = useState("");
  const [didMount, setDidMount] = useState(false);
  const feedbackService = useFeedbackService();
  const allIntegrationIndentifierCodes = [
    "hxfTcpExampleIntegration"
  ];
  console.log("popupstore", popupsStore);

  const closeCurrentIntegration = () => {
    setSelectedIdentifierCode("");
  }
  useEffect(() => {
    if (!didMount) {
      console.log("MONTOU AJANELA");

      setDidMount(true);
    }
  }, [didMount]);

  if (!popupsStore.customIntegrationsPopup) {
    return <></>;
  }

  if(selectedIdentifierCode === "hxfTcpExampleIntegration"){
    return (
        
        <Suspense fallback={<div>Loading...</div>}>
               <HxfTcpExampleIntegrationView onClose={() => {
                    closeCurrentIntegration();
               }}/>
        </Suspense>
 
    );
  }

  console.log("Prods data: ", popupsStore);
  return (
    <SimpleContentPopup>
      <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>Custom Integrations</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {
                dispatchCustomPopupsStore({
                  type: "CLOSE_CUSTOM_INTEGRATIONS_POPUP",
                });
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          <div className={styles.content}>
            <div style={{marginTop:25}}>
                <div style={{margin:20}}>
                <TextField
                        fullWidth
                        error={false}
                        onChange={(evt:any) => {
                       
                            let value = evt?.target?.value;
                            if(!value){
                                setIntegrationIdentifierCode("");
                                return;
                            }
                            
                            setIntegrationIdentifierCode(value);
                        }}
                        value={
                            integrationIdentifierCode ? integrationIdentifierCode : ""
                        }
                        label={"Integration Identifier"}
                        variant="outlined"
                      
                        
                    />
                    <IonButton
                        size="large"
                        color="primary"
                        style={{width:'100%'}}
                        onClick={() => {
                           
                            if(!allIntegrationIndentifierCodes.includes(integrationIdentifierCode)){
                                feedbackService.notifyToast("This integration is invalid", "error");
                            }else{
                                setSelectedIdentifierCode(integrationIdentifierCode);
                            }
                        }}
                    >
                        Select
                    </IonButton>
                </div>

            </div>

          </div>
        </div>
 
      </div>
    </SimpleContentPopup>
  );
}

export default withRouter(CustomIntegrationsPopup);
