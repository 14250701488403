import styles from "./ActionScreenDefineProducedProductParameterizedVariableValues.module.scss";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import { useEffect, useState } from "react";
import HxfSelectorField from "../../../../HxfSelectorField/HxfSelectorField";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import {
  dispatchActionScreenDefineProducedProductParameterizedVariableValuesStore,
  useActionScreenDefineProducedProductParameterizedVariableValuesStore,
} from "./ActionScreenDefineProducedProductParameterizedVariableValuesStore";
import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import { IonButton } from "@ionic/react";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { t } from "i18next";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
interface IResult {
  parameterizedVariablesData: Array<any>;
}
function ActionScreenDefineProducedProductParameterizedVariableValues(
  props: any
) {
  const [indexkey, setIndexkey] = useState(0);
  const currentActionScreenManager = useActionScreenManagerStore();
  const [didMount, setDidMount] = useState(false);
  const actionScreenDefineProducedProductParameterizedVariableValuesStore =
    useActionScreenDefineProducedProductParameterizedVariableValuesStore();
  const {t} = useHxfTranslation();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();

  const hasErrorCheck = (fieldName: any) => {
    let currentState =
      actionScreenDefineProducedProductParameterizedVariableValuesStore.variablesData;
    if (currentState[fieldName]) {
      if (currentState[fieldName]["hasErrors"] === true) {
        return true;
      }
    }

    return false;
  };

  const confirmProductParameterizedVariables = () => {
    let currentState =
      actionScreenDefineProducedProductParameterizedVariableValuesStore.variablesData;
    let parameterizedVarsData =
      currentActionScreenManager?.currentActionConfig
        ?.temp_parameterizedVariables;
    let foundErrors = false;
    let resultObj = {};

    for (let i = 0; i < parameterizedVarsData.length; i++) {
      let fieldName =
        getParameterVariableStatePrefix() + parameterizedVarsData[i].Id;
      let fieldValue = getValueFromProductionOrderProductVariablesIfDefined(
        fieldName,
        "valOnly"
      );
      let actualValue = null;

      if (!fieldValue) {
        //not defined in the production order product
        fieldValue = getFieldValue(fieldName);

        actualValue = fieldValue;
        if (actualValue?.value) {
          actualValue = actualValue.value;
        }

        if (actualValue === null) {
          actualValue = "";
        }
        console.log("FV:", actualValue);
      } else {
        actualValue = fieldValue;
      }

      if (!currentState[fieldName]) {
        currentState[fieldName] = {};
      }
      //Validations
      if (actualValue === "") {
        foundErrors = true;
        currentState[fieldName]["hasErrors"] = true;
      } else {
        currentState[fieldName]["hasErrors"] = false;
        currentState[fieldName]["valueObj"] = actualValue;
      }
    }

    dispatchActionScreenDefineProducedProductParameterizedVariableValuesStore({
      type: "SET_STATE",
      payload: { ...currentState },
    });

    if (!foundErrors) {
      let result: any = {};
      //todo confirm return result obj
      let arrayParameterizedVariablesAndValues = [];
      let stateKeys = Object.keys(currentState);

      for (let i = 0; i < stateKeys.length; i++) {
        let key = stateKeys[i];
        let idParameterizedVariable = key.replace(
          getParameterVariableStatePrefix(),
          ""
        );

        let parameterizedVariableValue = "";

        let stateValue = currentState[key].valueObj;
        if (stateValue?.value) {
          parameterizedVariableValue = stateValue.value;
        } else {
          parameterizedVariableValue = stateValue;
        }

        let varAndValueObj = {
          Id: parseInt(idParameterizedVariable),
          value: parameterizedVariableValue,
        };
        arrayParameterizedVariablesAndValues.push(varAndValueObj);
      }

      result["parameterizedVariablesAndValues"] =
        arrayParameterizedVariablesAndValues;

      return result;
    }
    return false;
  };

  const getParameterVariableStatePrefix = () => {
    return "PPV_Id_";
  };

  const setFieldValue = (fieldName: string, value: any) => {
    let currentState =
      actionScreenDefineProducedProductParameterizedVariableValuesStore.variablesData;
    currentState = { ...currentState };
    currentState[fieldName] = {};
    currentState[fieldName]["valueObj"] = value;

    dispatchActionScreenDefineProducedProductParameterizedVariableValuesStore({
      type: "SET_STATE",
      payload: currentState,
    });
  };

  const getFieldValue = (fieldName: string, allowNull = false) => {
    let currentState =
      actionScreenDefineProducedProductParameterizedVariableValuesStore.variablesData;
    let objectFields = Object.keys(currentState);

    if (!objectFields.includes(fieldName)) {
      if (allowNull) {
        console.log("RETURNING NULL");
        return null;
      }
      console.log("returning nothing");
      return "";
    }

    let returnObj = currentState[fieldName]["valueObj"];
    if (returnObj === undefined) {
      if (allowNull) {
        console.log("RETURNING NULL 2");
        return null;
      }
      console.log("returning nothing 2 ");
      return "";
    }

    console.log("RETURNING ", returnObj);
    return returnObj;
  };

  const getValueFromProductionOrderProductVariablesIfDefined = (
    fieldName: any,
    returnType: string
  ) => {
    let productionOrderSelection = productionOrderSelectionStore;
    let productionOrderProduct_ParameterizedVariableValues =
      productionOrderSelection?.productionOrderProduct_ParameterizedVariableValues;

    if (productionOrderProduct_ParameterizedVariableValues) {
      let idParameterizedVariable = fieldName.replace(
        getParameterVariableStatePrefix(),
        ""
      );
      for (
        let i = 0;
        i < productionOrderProduct_ParameterizedVariableValues.length;
        i++
      ) {
        if (
          parseInt(idParameterizedVariable) ===
          productionOrderProduct_ParameterizedVariableValues[i]
            .Id_ParameterizedVariable
        ) {
          if (returnType === "labelValue") {
            let label = "";
            let value = "";
            if (
              productionOrderProduct_ParameterizedVariableValues[i].Value !==
              null
            ) {
              label =
                productionOrderProduct_ParameterizedVariableValues[i].Value;
              value =
                productionOrderProduct_ParameterizedVariableValues[i].Value;
            }
            return { label: label, value: value };
          }

          if (returnType === "valOnly") {
            return productionOrderProduct_ParameterizedVariableValues[i].Value;
          }
        }
      }
    }

    return false;
  };

  const getHxfSelFieldValue = (fieldName: any) => {
    //check if production order product already has defined PPvs
    let definedVal = getValueFromProductionOrderProductVariablesIfDefined(
      fieldName,
      "labelValue"
    );

    if (!definedVal || definedVal === "" || definedVal?.value === "") {
      return getFieldValue(fieldName, true);
    }

    return definedVal;
  };

  const isFieldEnabled = (fieldName: any) => {
    let definedVal = getValueFromProductionOrderProductVariablesIfDefined(
      fieldName,
      "valOnly"
    );

    if (definedVal === null) {
      return true;
    }
    if (definedVal !== false && definedVal !== "") {
      return false;
    }

    return true;
  };
  const getNormalInputValue = (fieldName: any) => {
    let definedVal = getValueFromProductionOrderProductVariablesIfDefined(
      fieldName,
      "valOnly"
    );
    if (!definedVal || definedVal === "") {
      return getFieldValue(fieldName);
    }

    return definedVal;
  };

  const getVariableInputField = (obj: any, index: number) => {
    if (obj.varType === "optionselect") {
      let optionsSelectableStr = obj.varTypeOptionSelect_Opts;
      let optionsSelectableArr = optionsSelectableStr.split(",");

      let defaultOptionsObj = [];
      for (let i = 0; i < optionsSelectableArr.length; i++) {
        defaultOptionsObj.push({
          label: optionsSelectableArr[i],
          value: optionsSelectableArr[i],
        });
      }
      let inputLabel = "";
      inputLabel = "(" + obj.code + ") " + obj.name;

      return (
        <FormControl variant={"outlined"} fullWidth>
          <InputLabel>{inputLabel}</InputLabel>
          <Select
            native
            error={hasErrorCheck(getParameterVariableStatePrefix() + obj.Id)}
            value={getNormalInputValue(
              getParameterVariableStatePrefix() + obj.Id
            )}
            label={inputLabel}
            onChange={(evt) => {
              setFieldValue(
                getParameterVariableStatePrefix() + obj.Id,
                evt.target.value
              );
            }}
          >
            <option key={"ppv_x"} value={""}></option>
            {defaultOptionsObj.map((obj, index) => (
              <option key={"ppv_" + index} value={obj.value}>
                {obj.label}
              </option>
            ))}
          </Select>
        </FormControl>
      );
      /*
            return (<HxfSelectorField disabled={!isFieldEnabled(getParameterVariableStatePrefix() + obj.Id)}  disableInternalFiltering={true} labelPlaceholder={inputLabel} uniqueId={"productPV_sel_" + index} error={hasErrorCheck(getParameterVariableStatePrefix() + obj.Id)} onChange={(evt,val) => {
                    console.log("ONCHG: ", val);
                    setFieldValue(getParameterVariableStatePrefix() + obj.Id,val );
            }} value={getHxfSelFieldValue(getParameterVariableStatePrefix() + obj.Id)} defaultItems={defaultOptionsObj}/>)
            */
    }

    if (obj.varType === "text") {
      let inputLabel = "";
      inputLabel = "(" + obj.code + ") " + obj.name;
      return (
        <TextField
          disabled={!isFieldEnabled(getParameterVariableStatePrefix() + obj.Id)}
          error={hasErrorCheck(getParameterVariableStatePrefix() + obj.Id)}
          onChange={(evt) => {
            console.log("ONCHG: ", evt.target.value);
            setFieldValue(
              getParameterVariableStatePrefix() + obj.Id,
              evt.target.value
            );
          }}
          value={getNormalInputValue(
            getParameterVariableStatePrefix() + obj.Id
          )}
          fullWidth={true}
          label={inputLabel}
          variant="outlined"
        />
      );
    }

    if (obj.varType === "numeric") {
      let inputLabel = "";
      inputLabel = "(" + obj.code + ") " + obj.name;
      return (
        <TextField
          disabled={!isFieldEnabled(getParameterVariableStatePrefix() + obj.Id)}
          error={hasErrorCheck(getParameterVariableStatePrefix() + obj.Id)}
          onChange={(evt) => {
            console.log("ONCHG: ", evt.target.value);
            setFieldValue(
              getParameterVariableStatePrefix() + obj.Id,
              evt.target.value
            );
          }}
          type={"number"}
          value={getNormalInputValue(
            getParameterVariableStatePrefix() + obj.Id
          )}
          fullWidth={true}
          label={inputLabel}
          variant="outlined"
        />
      );
    }
  };

  useEffect(() => {
    console.log(
      "CUR ACT CFG: ",
      currentActionScreenManager.currentActionConfig
    );
    console.log(
      "CUR Local comp state: ",
      actionScreenDefineProducedProductParameterizedVariableValuesStore
    );
    if (!didMount) {
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager.currentActionConfig,
    actionScreenDefineProducedProductParameterizedVariableValuesStore,
  ]);

  useEffect(() => {
    console.log(
      "CHANGED: ",
      actionScreenDefineProducedProductParameterizedVariableValuesStore
    );
  }, [actionScreenDefineProducedProductParameterizedVariableValuesStore]);

  console.log("Cur act s m", currentActionScreenManager.currentActionConfig);

  if (
    !currentActionScreenManager?.currentActionConfig
      ?.temp_parameterizedVariables
  ) {
    return <></>;
  }

  return (
    <>
      <div className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('define.product.variables')}
        />

        <div className={styles.content}>
          <div className={styles.label}>{t('define.the.variables')}</div>
          <div className={styles.productVariablesSection}>
            <div className={styles.productVariables}>
              {currentActionScreenManager?.currentActionConfig?.temp_parameterizedVariables.map(
                (obj: any, index: number) => (
                  <div
                    className={styles.pvContainer}
                    key={"productPV_" + index}
                  >
                    {getVariableInputField(obj, index)}
                  </div>
                )
              )}
            </div>
          </div>

          <div className={confirmationStyles.confirmationContainer}>
            <IonButton
              className={confirmationStyles.confirmButton}
              onClick={() => {
                let storeResult = confirmProductParameterizedVariables();

                if (storeResult !== false) {
                  props.callbackSuccessConfirm(storeResult);
                }
              }}
            >
              {t("confirm.caps")}
            </IonButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActionScreenDefineProducedProductParameterizedVariableValues;
