import styles from "./SubScreenDefineWastesConfirmationButtons.module.scss";
import confirmationStyles from "../../../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
}

function SubScreenDefineWastesConfirmationButtons(props: IProps) {
  const {t} = useHxfTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={styles.customConfirmationContainer}>
          <IonButton
            className={styles.customConfirmationButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {t("confirm.caps")}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default SubScreenDefineWastesConfirmationButtons;
