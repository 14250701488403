import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IActionScreenScaleProductivityProduct {
  quantity: any;
  layoutHours:any;
  layoutMinutes:any;
}

interface IActionScreenScaleProductivityAction {
  type: any;
  payload?: any;
}

const initialState = {
  quantity: "",
  layoutHours:"",
  layoutMinutes:"",
};

const reducer = (
  store: IActionScreenScaleProductivityProduct,
  action: IActionScreenScaleProductivityAction
) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    case "SET_QUANTITY":
      store = { ...store, quantity: action.payload };
      return store;
    case "SET_HOURS":
        store = { ...store, layoutHours: action.payload };
        return store;
      case "SET_MINUTES":
        store = { ...store, layoutMinutes: action.payload };
        return store;
    default:
      return store;
  }
};

export const [
  useActionScreenScaleProductivityStore,
  dispatchActionScreenScaleProductivityStore,
] = createStore(initialState, reducer);
