import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useHxfTranslation from "../useHxfTranslation";
import styles from "./useMachineManagementShortcutReceiver.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import { dispatchMachineManagementShortcutReceiverStore, useMachineManagementShortcutReceiverStore } from "./machineManagementShortcutReceiverStore";
function useMachineManagementShortcutReceiver(){

    const [didMount, setDidMount] = useState(false);

    const history = useHistory();
    const {t} = useHxfTranslation();

    const machineManagementShortcutReceiverStore = useMachineManagementShortcutReceiverStore();

    const resetShortcutData = () => {
      let alreadyLoadedBefore = machineManagementShortcutReceiverStore?.routeState !== null;
      if(alreadyLoadedBefore){
        dispatchMachineManagementShortcutReceiverStore({type:"RESET_STORE"});
      }
    }

    const getElementShortcutData = () => {

      let routeState = machineManagementShortcutReceiverStore.routeState;
        return (
            <div className={styles.preloadedFromContainer}>
            <div>
              <div><b>{t('preloaded.from')}:</b></div>
              <div><b>{t('operation')}:</b> {routeState?.operationProductSelected?.name}</div>
              <div><b>{t('product')}:</b> {routeState?.productSelected?.name}</div>
            </div>
            <div className={styles.cancelPreload} onClick={() => {
         
                resetShortcutData();
            }}>
              <CancelIcon fontSize={"large"}/>
            </div>
          </div>
        )
    }

    useEffect(() => {
        if(!didMount){
            let alreadyLoadedBefore = machineManagementShortcutReceiverStore?.routeState !== null;

            if(!alreadyLoadedBefore){
              //prevent overwritting the state store
              let loadedRouteState = history?.location?.state;
              dispatchMachineManagementShortcutReceiverStore({type:"SET_STORE",payload:{routeState:loadedRouteState}});
              window.history.replaceState({}, '') //clear state
            }

            setDidMount(true);
        }
    },[didMount]);



    return {shortcutReceivedData:machineManagementShortcutReceiverStore.routeState,resetShortcutData,elementPreloadedShortcutData:getElementShortcutData()};
}

export default useMachineManagementShortcutReceiver;