import styles from "./UnavailabilityTopInfo.module.scss";
import { useProductionOrderSelectionStore } from "../../ProductionOrders/Standard/store/productionOrderSelectionStore";
import { withRouter } from "react-router";
import ProductionService from "../../../../barrel/services/productionService";
import DangerousIcon from '@mui/icons-material/Dangerous';
import PanToolIcon from '@mui/icons-material/PanTool';
function UnavailabilityTopInfo(props:any) {

  //path validation to show

  return (
    <>
        <div className={styles.unavInfo}>
        <div className={styles.unavIcon}><PanToolIcon/> </div>
        <div>{props.labelOne}</div>
        </div>
        <div className={styles.productInfo}>{props.labelTwo}</div>
    </>
  );
}
export default withRouter(UnavailabilityTopInfo);
