function HxfAppServersService() {
  const getServerEndpoint = (serverAlias) => {
    if (serverAlias === "eu1") {
      return "https://eu1.shopfloor.studio/serve/";
    }
    if (serverAlias === "demo") {
      return "https://demo.shopfloor.studio/serve/";
    }

    if (serverAlias === "staging-server") {
      return "https://staging-server.shopfloor.studio/serve/";
    }


    if(serverAlias === "dev_simulation" && process.env.REACT_APP_MODE === "dev"){
      return "SIMULATION";
  }
  
    throw "Unexpected server alias...";
    return null;
  };

  const setCurrentServer = (server) => {

    let definedCentral = process.env.REACT_APP_CENTRAL_API_SERVE;
    if(definedCentral.includes("staging.haxify.com")){
        //if running on the staging server force sets staging-server always
        return localStorage.setItem("srv", "staging-server");
    }
    
    if (getServerEndpoint(server)) {
      return localStorage.setItem("srv", server);
    } else {
      throw "Invalid server passed to set current";
    }
  };

  const hasServerBeenSet = () => {
    let curServ = localStorage.getItem("srv");
    let hasServer = curServ !== null;
    return hasServer;
  };
  const getBackend = () => {
    let backend = null;

    let server = localStorage.getItem("srv");
    if (!server) {
      throw "Unexpected err, server has not been set yet";
    }

    if (process.env.REACT_APP_MODE === "dev") {
      backend = process.env.REACT_APP_API_SERVE_DEV;
    } else {
      backend = HxfAppServersService().getServerEndpoint(server);
    }
    return backend;
  };

  return { setCurrentServer, getServerEndpoint, getBackend, hasServerBeenSet };
}
export default HxfAppServersService;
