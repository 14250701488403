
import SimpleContentPopup from "../../../../SimpleContentPopup/SimpleContentPopup";
import AutoCalculateWholesMethods from "./AutoCalculateWholesMethods/AutoCalculateWholesMethods";


interface IProps{
    onClose:any;
    onConfirm:any;
    defaultValue?:any;
    autoCalculationMode:any;
}
function PopupAutoCalculateMFVFill(props:IProps){

    if(props.autoCalculationMode === "DIST_DIFF_WHOLES_CENTER" || props.autoCalculationMode === "DIST_EQUAL_WHOLES_CENTER" || props.autoCalculationMode === "DIST_UNTIL_WHOLE_CENTER" || props.autoCalculationMode === "DIST_DIFF_SPACES_CENTER" || props.autoCalculationMode === "DIST_EQUAL_SPACES_CENTER" || props.autoCalculationMode === "DIST_UNTIL_SPACE_CENTER" ){

      return (<AutoCalculateWholesMethods
         onClose={() => { props?.onClose()}}
         onConfirm={(val:any) => {
             props.onConfirm(val);
        }}
         defaultValue={props?.defaultValue}
         autoCalculationMode={props.autoCalculationMode}
        />)
    }


    return (
    <>
        <SimpleContentPopup>
            n/a
        </SimpleContentPopup>
    </>)
}

export default PopupAutoCalculateMFVFill;