import { useHistory, withRouter } from "react-router";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import useLocalStorageHandler from "./useLocalStorageHandler";

function usePunchclock() {
  const history = useHistory();
  const { sessionState } = useGlobalState();
  let myLocalStorage = useLocalStorageHandler();

  const redirPunchclockMode = (options: any = {}) => {
    let punchclockMode = sessionState.userSessionData.punchclockMode;
    let passState: any = {};
    if (options?.forceManualConfirmation) {
      punchclockMode = "MANUAL_SELECT";
      if (options?.hidePunchout) {
        passState["hidePunchout"] = true;
      }

      if (options?.pageToAccessAfterPunchclockIn) {
        passState["pageToAccessAfterPunchclockIn"] =
          options["pageToAccessAfterPunchclockIn"];
      }
    }
    if (
      punchclockMode === "DEDICATED_DETECT" ||
      punchclockMode === "DEDICATED_SELECT"
    ) {
      myLocalStorage.clearWorkerIdentificationCode();
      history.push({
        pathname: "/worker-identify",
        state: {
          pageToAccessAfterIdentification: "/feature/punch-clock",
          options: { hideToolbar: true },
        },
      });
    } else if (
      punchclockMode === "MANUAL_DETECT" ||
      punchclockMode === "MANUAL_SELECT"
    ) {
      history.push({
        pathname: "/feature/punch-clock",
        state: { ...passState },
      });
    }
  };

  const redirRequirePunchclockIn = () => {
    let currentUrl = history.location;

    redirPunchclockMode({
      forceManualConfirmation: true,
      hidePunchout: true,
      usePunchclockMode: "MANUAL_SELECT",
      pageToAccessAfterPunchclockIn: currentUrl,
    });
  };

  return { redirPunchclockMode, redirRequirePunchclockIn };
}

export default usePunchclock;
