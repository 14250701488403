import {
  IonButton, IonIcon
} from "@ionic/react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { closeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import MobileWrapperCommunicationService from "../../../../../barrel/services/mobileWrapperCommunicationService";
import HxfBarcodeScanner, {
  IHxfBarcodeSettings
} from "../../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import SimpleContentPopup from "../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./ScanBarcodePopup.module.scss";

interface IPopup {
  onClosePopup?: any;
  onFinishedScan?: any;
  onClosedCamera?: any;
  onInvalidScan?:any;
  title: string;
  visible: boolean;
  barcodeScannerSettings: IHxfBarcodeSettings;
  ghostMode?:boolean; // if true, the popup is invisible, but it still listens for a physical reader (ex: barcode scanner)
  freeScannerMode?:boolean;
}
function ScanBarcodePopup(props: IPopup) {

  const {t} = useHxfTranslation();
  const isUsingCamera = () => {
    return props.barcodeScannerSettings.useCamera;
  };

  const isUsingExternalDevices = () => {
    return props.barcodeScannerSettings.useExternalDevice;
  };


  if (!props.visible) {
    return <></>;
  }

  if(props.ghostMode){
    return (<>
                <HxfBarcodeScanner
                freeScannerMode={props.freeScannerMode}
              onInvalidScan={() => {
                if(props.onInvalidScan){
                  props.onInvalidScan();
                }
              }}
              onClosedCamera={() => {
                props.onClosedCamera();
              }}
              settings={props.barcodeScannerSettings}
              onFinishedScan={(result) => {
                props.onFinishedScan(result);
              }}
            />
    </>)
  }
  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup mode="INNER">
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
              <div>
                {(isUsingExternalDevices() && !isUsingCamera()) ||
                (isUsingExternalDevices() &&
                  isUsingCamera() &&
                  (!MobileWrapperCommunicationService().isDeviceMobileWrapper())) ? (
                  <div className={styles.scantip}>
                    {t('use.scanner.device')}
                  </div>
                ) : (
                  <></>
                )}

                {!isUsingExternalDevices() && isUsingCamera() ? (
                  <div className={styles.scantip}>
                    {t('starting.camera')}
                  </div>
                ) : (
                  <></>
                )}

             

                {isUsingExternalDevices() &&
                isUsingCamera() &&
                (MobileWrapperCommunicationService().isDeviceMobileWrapper()) ? (
                  <div>
                    <div className={styles.scantip}>
                      {t('use.scanner.device')}
                    </div>
                    <div className={styles.scantip}>OR</div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className={styles.iconCamera}
                        onClick={() => {
                          let scanForFormats = props.barcodeScannerSettings.allowedScanFormats;

                            

                              //dispatch event to camera etc
                              MobileWrapperCommunicationService().notifyWaitingForCameraScan(
                                scanForFormats
                              );
                          


                        }}
                      >
                        <PhotoCameraIcon />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <HxfBarcodeScanner
              freeScannerMode={props.freeScannerMode}
              onInvalidScan={() => {
                if(props.onInvalidScan){
                  props.onInvalidScan();
                }
              }}
              onClosedCamera={() => {
                props.onClosedCamera();
              }}
              settings={props.barcodeScannerSettings}
              onFinishedScan={(result) => {
                props.onFinishedScan(result);
              }}
            />
            <div
              style={{ height: "10px", backgroundColor: "white" }}
              className={"popup-bottom-confirmation-border-styles"}
            ></div>
          </div>
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default ScanBarcodePopup;
