import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import BarrelWrapper from "./barrelWrapper";
import HxfAppServersService from "../services/hxfAppServersService";

interface IStockMovementProductPvsProps {
  Id: string | number;
  Id_Product_ParameterizedVariable: number;
  Value: string | number;
}

interface IStockMovementProps {
  Id_Product: number;
  Quantity: number;
  Id_Lot: number | null;
  Id_Warehouse: number | null;
  Id_WarehouseLocation: number | null;
  stockMovementProductParameterizedVariables:
    | IStockMovementProductPvsProps[]
    | null;
  newLotNameCode?: string;
}

function stockMovementsController() {
  const myLocalStorage = useLocalStorageHandler();

  const saveStockMovement = (movementObj: IStockMovementProps | null) => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
    let url = HxfAppServersService().getBackend() + "stock-movement/save";

    let smObj: any = movementObj ? movementObj : {};
    smObj["workerCode"] = currentWorkerCode;

    let postingObj = { object: smObj };
    return BarrelWrapper().appPostRequest(url, postingObj);
  };

  return { saveStockMovement };
}

export default stockMovementsController;
