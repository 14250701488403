import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IActionScreen {
  variablesData: any;
}

interface IActionScreenAction {
  type: any;
  payload?: any;
}

const initialState = {
  variablesData: {},
};

const reducer = (store: IActionScreen, action: IActionScreenAction) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState };
      return store;
    case "SET_STATE":
      store = { ...store, variablesData: action.payload };
      return store;
    default:
      return store;
  }
};

export const [
  useActionScreenDefineProducedProductParameterizedVariableValuesStore,
  dispatchActionScreenDefineProducedProductParameterizedVariableValuesStore,
] = createStore(initialState, reducer);
