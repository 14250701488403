import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
//solution for auto scrolling up
//https://github.com/mui/material-ui/issues/30249

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}

const HxfListboxFix = forwardRef(function ListBoxBase(
  props: ListBoxProps,
  ref: ForwardedRef<HTMLUListElement>
) {
  const { children, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(
    ref,
    () => innerRef.current
  );

  return (
    <ul {...rest} ref={innerRef} role="list-box">
      {children}
    </ul>
  );
});

export default HxfListboxFix;

type NullableUlElement = HTMLUListElement | null;
