
interface IOperationProductAccess{
    Id_OperationProduct:any;
}

export interface IOperationOperationsAccessData{
    operationsAccess:string;
    operationsAccessArray:IOperationProductAccess[];
}
function OperationsAccessService(){

    const checkIfOperationsAccessArrayIncludesOperation = (operationsAccessData:IOperationOperationsAccessData, idOperation:any) => {
   
        if(operationsAccessData.operationsAccess === "ALL"){
            return true;
        }
        let operationsAccessArray = operationsAccessData.operationsAccessArray;
      
        if(operationsAccessArray){
          for(let i = 0; i<operationsAccessArray.length; i++){
            if(operationsAccessArray[i].Id_OperationProduct === idOperation){
              return true;
            }
          }
        }
        return false;
      
      }


      return {checkIfOperationsAccessArrayIncludesOperation};
}

export default OperationsAccessService;