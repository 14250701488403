import styles from "./ActionScreenAskBomConsumptionsConfimrationButtons.module.scss";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
  showVerifyingStockLabel?: boolean;
}
function ActionScreenAskBomConsumptionsConfirmationButtons(props: IProps) {
  const {t} = useHxfTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      {props?.showVerifyingStockLabel == true && (
        <div className={styles.verifyingStockLabel}>Verifying stock...</div>
      )}
      <div className={styles.buttonsContainer}>
        <div className={confirmationStyles.confirmationContainer}>
          <IonButton
            className={confirmationStyles.confirmButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {t("confirm.caps")}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default ActionScreenAskBomConsumptionsConfirmationButtons;
