import { Redirect, Route } from "react-router";
import { useGlobalState } from "../GlobalCustomStateManagement/GlobalStateProvider";
import InternetRequiredPage from "../pages/ErrorPages/InternetRequiredPage/InternetRequiredPage";

function ConfigurableRoute({ component: Component, authed, ...props }: any) {
  const { sessionState, appState } = useGlobalState();

  if (props.requiresNetworkAccess) {
    if (!appState.isConnectionAvailable()) {
      return (
        <Route
          {...props}
          render={(props) => {
            return <InternetRequiredPage />;
          }}
        />
      );
    }
  }

  if (props.requiresLogin && sessionState.isUserLoggedIn() !== true) {
    return (
      <Route
        {...props}
        render={(props) => <Redirect to={{ pathname: "/login" }} />}
      />
    );
    /*return (
      <Route
        {...props}
        render={(props) =>
          sessionState.isUserLoggedIn() === true ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )
        }
      />
    );*/
  }

  console.log("O SESS STATE: ", sessionState);
  if (
    props.requiresWorkerIdentification &&
    sessionState.isUserWorkerIdentified() !== true
  ) {
    return (
      <Route
        {...props}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/worker-identify",
              state: {
                pageToAccessAfterIdentification:
                  location?.pathname !== null ? location.pathname : "/home",
              },
            }}
          />
        )}
      />
    );
  }
  return <Route {...props} render={(props) => <Component {...props} />} />;
}

export default ConfigurableRoute;
