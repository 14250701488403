
//extremely specific behaviours that would not be worth abstracting into new features
function HardcodedBehaviourService(){

    const checkIfHideButStillWaste_actionScreenAskProducedProductBuildWastesProduct = (fullIrregularityObject:any,productComponent:any, sessionState:any) => {

        let hcidentifier = sessionState?.userSessionData?.entityHCIdentifier;
        //pormecalu
        if(hcidentifier === "86da8995ec51c30828f55b51e8b33e084abb2e231ca2af90ad6347251bd5692f"){
            if(fullIrregularityObject?.Id_Irregularity === 5 || fullIrregularityObject?.Id_Irregularity === 4){
                //hide products with variable Tamanho Perfil code, from wastes consumption irregularidade: Qtd Rejeitada Embalagem
                let arrayProductParameterizedVariables = productComponent?.arrayProductParameterizedVariables;
                if(arrayProductParameterizedVariables){
                    for(let i = 0; i<arrayProductParameterizedVariables.length; i++){
                        if(arrayProductParameterizedVariables[i].code === "Tamanho Perfil"){
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    return {checkIfHideButStillWaste_actionScreenAskProducedProductBuildWastesProduct};

}

export default HardcodedBehaviourService;