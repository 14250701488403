import styles from "./MultiPopPopupOperationSelectionSubscreen.module.scss";
import { IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import PrecisionManufacturing from "@mui/icons-material/PrecisionManufacturing";
import { constructOutline, hammerOutline } from "ionicons/icons";

interface IPropsSubSel {
  multiPopProductionsStarterPopupData: any;
  onOperationSelect: any;
}
function MultiPopPopupOperationSelectionSubscreen(props: IPropsSubSel) {
  const {t} = useHxfTranslation();
  
  const getOperationElement = (obj: any, index: number) => {
    console.log("Obj Loaded; ", obj);

    let productsLabel = "";
    let productsData = obj?.productsData;
    if (productsData) {
      productsData = JSON.parse(productsData);
      for (let i = 0; i < productsData.length; i++) {
        if (i !== 0) {
          productsLabel = productsLabel + ", ";
        }

        let productName = productsData[i].name;
        productsLabel += productName;
      }
    }
    return (
      <IonCardHeader
        className={`${styles.elemElement}`}
        onClick={() => {
          props.onOperationSelect(obj, index);
        }}
      >
        <div className={styles.elemBackgroundContainer}>
            <IonIcon className={styles.iconElement} slot="icon-only" size="large" icon={hammerOutline} />
        </div>
        <IonCardTitle className={styles.elemElementDescription}>
          {obj.operationCode}
        </IonCardTitle>
        <IonCardSubtitle>
          <div className={styles.elemElementTitle}>
            {t("products")}:{productsLabel}
          </div>
        </IonCardSubtitle>
      </IonCardHeader>
    );
  };
  return (
    <div className={styles.content}>
      <div className={styles.contentTitle}>{t("select.an.operation")}:</div>
      <div className={styles.operationsContainer}>
        {props.multiPopProductionsStarterPopupData.map(
          (row: any, index: number) => (
            <div
              key={"multiOperation_" + index}
              className={`${styles.activeProductionElement}  `}
            >
              {getOperationElement(row, index)}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default MultiPopPopupOperationSelectionSubscreen;
