import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";
import GenericListJsonService from "../services/genericListJsonService";
function productionRecordOperationActionTempStoresController() {

    const save = (options:any = {}) => {
        let requestUrl =
        "pr-operation-action-store/save";
        return BarrelWrapper().appPostRequest(
          HxfAppServersService().getBackend() + requestUrl,
          {
  
            ...options
          }
        );
      }

  return { save };
}

export default productionRecordOperationActionTempStoresController;
