import SimpleContentPopup from "../../../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./AddFinishedProductPopup.module.scss";
import { IonButton, IonIcon, IonLoading } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import AddRemoveStockComponent from "../../../../../../StockInOut/SubViews/AddRemoveStock/Component/AddRemoveStockComponent";

interface IProps{
    onCancel:any;
    onConfirmAddProduct:any;
    openFrom:'receivings';
}
function AddFinishedProductPopup(props:IProps){

    const {t} = useHxfTranslation();

    return (
   
        <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}>{t('add.product')}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  props.onCancel();
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.content}>
            <AddRemoveStockComponent openFrom={props.openFrom} skipAskStockLocation={true} fixedSearchbar={true} mode="add" hideTitle={true} customOnConfirm={(preparedStockMovement:any) => {
           
                delete preparedStockMovement.Id_WarehouseLocation;
                delete preparedStockMovement.Id_Warehouse;
                delete preparedStockMovement.Id_Lot;
                props.onConfirmAddProduct(preparedStockMovement);
    

            }}/>
            </div>
          
           
          </div>
        </div>
      </SimpleContentPopup>
     

    )
}
export default AddFinishedProductPopup;