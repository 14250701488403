import { IonButton, IonIcon, IonLoading } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import SimpleContentPopup from "../../../pages/CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import ReloadButton from "./ReloadButton/ReloadButton";
import styles from "./RequiredUpdatePopup.module.scss";



function RequiredUpdatePopup() {

  const feedbackService = useFeedbackService();
  const {t} = useHxfTranslation();

  const [currentPrintableLabelIndex, setCurrentPrintableLabelIndex] = useState(0);

  const [renderPrintUrlKey, setRenderPrintUrlKey] = useState(0);

  const renderPrintUrl = useRef("");



  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup left={"0xp"}>
        <div key={"printLblPop_" + renderPrintUrlKey}>
              <iframe
              style={{display:'none'}}
              name="mylabel_print_output"
              src={
                renderPrintUrl.current
              }
              width="0"
              height="0"
              onLoad={() => {
                console.log("finished loading");

              }}
            ></iframe>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{t('update.required')}!</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                 
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>

            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
              <div>
                <Trans i18nKey="app.hasbeen.updated">
                    The app has been <b>updated</b> and a reload is required!
                </Trans>
              </div>
                
            </div>
          </div>

     
          <ReloadButton/>
        
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default RequiredUpdatePopup;
