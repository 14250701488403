import styles from "./PreActionNewOrResumeConfirmationButtons.module.scss";

import { IonButton, IonIcon } from "@ionic/react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import { createOutline, refreshOutline } from "ionicons/icons";

interface IProps {
  onClickNew?:any;
  onClickContinuePrev?:any;
}

function PreActionNewOrResumeConfirmationButtons(props: IProps) {
  const {t} = useHxfTranslation();
  
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
      <div className={styles.confirmationContainer}>
        <div style={{width:'100%',marginBottom:10}}>
          <div style={{display:'flex',justifyContent:'center'}}>
            <IonButton
            data-btn="collabContinue"
            disabled={false}
            className={`${styles.confirmButton} ${styles.confirmButtonContinuePrev}`}
            onClick={() => {
              props.onClickContinuePrev();
            }}
          >
            <IonIcon icon={refreshOutline} size="large" slot="start"></IonIcon>
            {t("continue.previous")}
          </IonButton>
          </div>

          <div style={{display:'flex',justifyContent:'center'}}>
            <IonButton
            data-btn="collabStartNew"
           disabled={false}
           className={styles.confirmButton}
           onClick={() => {
             props.onClickNew();
           }}
            >
            <IonIcon icon={createOutline} size="large" slot="start"></IonIcon>
              {t("start.new")}
            </IonButton>
          </div>

        </div>

        </div>

      </div>
    </div>
  );
}

export default PreActionNewOrResumeConfirmationButtons;
