import { useEffect, useState } from "react";
import { withRouter } from "react-router";
/**
 * Purpose of this page is when we need to redirect to the same route and trigger mount again
 */
function PageRedirector(props: any) {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
    } else {
      if (props.history.location.state !== undefined) {
        let state = {};
        if (props?.history?.location?.state?.withHistoryState) {
          state = props.history.location.state.withHistoryState;
        }

        props.history.push({
          pathname: props.history.location.state.redirectTo,
          state: state,
        });
      }
    }
  }, [didMount, props.history]);
  if (props.history.location.state === undefined) {
    return <>no redir data</>;
  }
  return <></>;
}

export default withRouter(PageRedirector);
