import { IonItem, IonList, IonListHeader, useIonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
type Props = {
  onHide: any;
  onLogOff: any;
  onSwitchLang: any;
  getSessionState: any;
};


const languages = [
	{
		id: 'en',
		title: 'English',
		flag: 'en'
	},
	{
		id: 'pt',
		title: 'Português',
		flag: 'pt'
	}
];

const ToolbarLangSwitcher: React.FC<{
  onHide: () => void;
  onLogOff: () => any;
  onSwitchLang: () => any;
  getSessionState: () => any;
}> = ({ onHide, onLogOff, onSwitchLang, getSessionState }: Props) => {

  const {t} = useHxfTranslation();
  const {i18n} = useTranslation();

  function handleLanguageChange(lng:any) {
        i18n.changeLanguage(lng.id);
        document.cookie = "lang=" + lng.id + "; max-age=31536000";
        onHide();  
  }



  return (
    <IonList>
   
    <IonListHeader>
        {t('switch.language')}
    </IonListHeader>
      {languages.map((obj,i) => (
            <IonItem
            key={i}
            lines="none"
            detail={false}
            button
            onClick={() => {
              handleLanguageChange(obj);
            }}
          >
            <img style={{margin:'12px'}} src={"/assets/images/flags/" + obj.flag + ".png"} alt="lang flag" />  {obj.title}
          </IonItem>
      ))}

      
    </IonList>
  );
};

export default ToolbarLangSwitcher;
