import entityController from "../../../controllers/entityController";
import DaoData from "../daoData";

function EntityDao() {
  const daoData = DaoData();

  const getAccessibleEntities = () => {
    return new Promise((resolve, reject) => {
      //"returnFirstOnly": true because there is only 1 object with key accessibleEntities
      daoData
        .accessData(
          () => {
            return entityController().getAccessibleUserEntities();
          },
          {
            onlineCondition: {
              objectStorageStore: true,
              objectStorageKey: "accessibleEntities",
            },
            offlineCondition: {
              objectStorageFetch: true,
              objectStorageKey: "accessibleEntities",
              throwExceptionIfNotFound: true,
              returnFirstOnly: true,
            },
            offlineValidDuration: 3600 * 24 * 2,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  };

  return { getAccessibleEntities };
}

export default EntityDao;
