import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import HxfTextfieldNumber from "../../../../../../HxfTextfieldNumber/HxfTextfieldNumber";
import { IonButton, IonIcon, IonLoading, useIonAlert } from "@ionic/react";
import { addOutline, chevronBack, closeOutline, layersOutline, removeOutline, tabletLandscapeOutline, trashOutline, tvOutline } from "ionicons/icons";
import styles from "./asspLayoutAskWorkedTime.module.scss";
import confirmationStyles from "../../../Components/ActionScreenConfirmationButton.module.scss";
interface IProps{
    contentClass:any;
    onUpdatedHours:any;
    onUpdatedMinutes:any;
    currentHours:any;
    currentMinutes:any;
    onConfirm:any;
    allowConfirmation?:any;
    hasError?:any;
    onClearError?:any;
}

function AsspLayoutAskWorkedTime(props:IProps){
    const {t} = useHxfTranslation();

    const isConfirmEnabled = () => {

        if(!props.allowConfirmation){
            return false;
        }
        if(!props.currentHours && !props.currentMinutes){
            return false;
        }

        let hasHours = false;
        if(props.currentHours && parseInt(props.currentHours) > 0){
            hasHours = true;
        }
        let hasMinutes = false;
        if(props.currentMinutes && parseInt(props.currentMinutes) > 0){
            hasMinutes = true;
        }

        return hasHours || hasMinutes;

    }


    return (
        <>
            <div className={props.contentClass}>
                <div style={{marginTop:10,marginBottom:20}}>
                    <HxfTextfieldNumber
                
                    fullWidth={true}
            
                    centerInputText={true} error={props.hasError} label={t('hours')} placeholder={t('hours')}
                    onChange={(evt:any) => {
                        let val = evt;
                        val = parseInt(val);
                        props.onUpdatedHours(val);
                        props.onClearError();

                    }} value={props.currentHours}/> 
                    <div>
                        <IonButton
                        
                        disabled={false}
                            className={styles.addrembtn}
                            onClick={() => {
                                let curVal = props?.currentHours ? props.currentHours : 0;
                                let newHours = parseInt(curVal) - 1;
                                if(newHours < 0){
                                    return;
                                }
                                props.onUpdatedHours(newHours);
                            }}
                        >
                            <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                        </IonButton>
                        <IonButton
                            disabled={false}
                            className={styles.addrembtn}
                            onClick={() => {
                                let curVal = props?.currentHours ? props.currentHours : 0;
                                let newHours = parseInt(curVal) + 1;
                                props.onUpdatedHours(newHours);
                            }}
                        >
                            <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                        </IonButton>
                    </div>
                    <div style={{marginTop:10}}>
                        <HxfTextfieldNumber
                            fullWidth={true}
                            centerInputText={true}
                            error={props.hasError}
                            label={t('minutes')}
                            placeholder={t('minutes')}
                            onChange={(evt:any) => {
                                let val = evt;
                                val = parseInt(val);
                                props.onUpdatedMinutes(val);
                                props.onClearError();
                            }}
                            value={props.currentMinutes}
                        /> 
                    </div>
                    <div>
                        <IonButton
                        
                            disabled={false}
                            className={styles.addrembtn}
                            onClick={() => {
                                let curVal = props?.currentMinutes ? props.currentMinutes : 0;
                                let newMinutes = parseInt(curVal) - 10;
                                if(newMinutes < 0){
                                    return;
                                }
                                props.onUpdatedMinutes(newMinutes);
                            }}
                        >
                            <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                        </IonButton>
                        <IonButton
                            disabled={false}
                            className={styles.addrembtn}
                            onClick={() => {
                                let curVal = props?.currentMinutes ? props.currentMinutes : 0;
                                let newMinutes = parseInt(curVal) + 10;
                                if(newMinutes > 60){
                                    return;
                                }
                                props.onUpdatedMinutes(newMinutes);
                            }}
                        >
                            <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                        </IonButton>
                    </div>
                </div>

                <div className={styles.lineBreaker}></div>
                <div className={confirmationStyles.confirmationContainer}>
                    <IonButton
                        
                        data-btn={"confirm-worked-time"}
                        disabled={!isConfirmEnabled()}
                        className={confirmationStyles.confirmButton}
                        onClick={() => {
                            props.onConfirm();
                        }}
                    >
                        { t("confirm")}
                    </IonButton>
                </div>
            </div>

        </>
    )
}

export default AsspLayoutAskWorkedTime;