import styles from "./ActionScreenAskPrintLabelsConfirmationButtons.module.scss";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import { IonButton } from "@ionic/react";
import PrintIcon from "@mui/icons-material/Print";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import { useEffect, useRef, useState } from "react";
import SystemService from "../../../../../../barrel/services/systemService";
import MobileWrapperCommunicationService from "../../../../../../barrel/services/mobileWrapperCommunicationService";
import ActionScreenAskPrintLabelsPrintOnMobile, { IActionScreenPrintLabelsPrintableLabel } from "./Subviews/ActionScreenAskPrintLabelsPrintOnMobile/ActionScreenAskPrintLabelsPrintOnMobile";
import labelsController from "../../../../../../barrel/controllers/labelsController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useWindowSize from "../../../../../../barrel/hooks/useWindowSize";
import HxfAppServersService from "../../../../../../barrel/services/hxfAppServersService";
import ZebraService from "../../../../../../barrel/services/zebraService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
interface IProps {
  currentActionConfig: any;
  finishedActionCallback: any;
  countToPrint:any;
}


function ActionScreenAskPrintLabelsConfirmationButtons(props: IProps) {
  const timerCheckScanMobile = useRef<any>(null);
  const [width, height] = useWindowSize();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const currentActionScreenManager:any = useActionScreenManagerStore();
  const feedbackService = useFeedbackService();
  const [renderPrintableFrames, setRenderPrintableFrames] = useState(false);
  const [renderFramesKey, setRenderFramesKey] = useState(0);
  const {t} = useHxfTranslation();
  const [hideSkipOnFinishAction, setHideSkipOnFinishAction] = useState(false);
  const [hideSkip, setHideSkip] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const printShownCount = useRef(0);
  const [printButtonEnabled, setPrintButtonEnabled] = useState(true);
  const [printableLabels, setPrintableLabels] = useState<IActionScreenPrintLabelsPrintableLabel[]>([]);
  const genericLabelDataToUse = useRef<any>(null);
  const [showAskPrintLabelsOneByOne, setShowAskPrintLabelsOneByOne] = useState(false);
  const getLabelContentData = (obj: any, options:any = null) => {
    let idLabel = obj?.Id_Label ? obj.Id_Label : null;
    let idProductionOrder = productionOrderSelectionStore.productionOrder.Id;
    let idProductionOrderProduct =
      productionOrderSelectionStore.productionRecord.Id_ProductionOrder_Product;
    let idProductionRecord = productionOrderSelectionStore.productionRecord.Id;


    let objParams = {
      Id_Label: idLabel,
      Id_ProductionOrder: idProductionOrder,
      Id_ProductionOrder_Product: idProductionOrderProduct,
      Id_ProductionRecord: idProductionRecord,
    };

    if(options?.returnObjParams){
      return objParams;
    }
    const queryBuilder = new URLSearchParams(objParams);

    return queryBuilder;
  };

  /**
   * checks if finished popups with the printable labels
   */
  const checkIfFinishedPrints = () => {
    if (
      currentActionScreenManager?.currentActionConfig?.labelsList.length <=
      printShownCount.current
    ) {
      if (hideSkipOnFinishAction) {
      
        setHideSkip(true);
      }

      return true;
    }
    return false;
  };

  const getCurrentServerServe = () => {

    if (process.env.REACT_APP_MODE === "dev") {
      return process.env.REACT_APP_API_SERVE_DEV;
    } 

    let server = localStorage.getItem("srv");
    if (!server) {
      throw "xUnexpected err, server has not been set yet";
    }

    return HxfAppServersService().getServerEndpoint(server);

  }

  const renderFrame = (obj: any) => {
    let queryBuilder = getLabelContentData(obj);

    let queryString = queryBuilder.toString();
    const isMobile = SystemService().isMobile();
    if (isMobile) {
      console.log("mobile mode - getDownloadable = true");
      queryString = queryString + "&getDownloadable=true";
    }

  
    return (
      <iframe
        style={{display:'none'}}
        name="output_frame_pdf"
        src={
          getCurrentServerServe() +
          "label/print-pdf/?" +
          queryBuilder.toString()
        }
        width="100"
        height="100"
        onLoad={() => {
          console.log("finished loading");
          printShownCount.current = printShownCount.current + 1;
          checkIfFinishedPrints();
        }}
      ></iframe>
    );
  };

  const requestGenerateZebra = (labelData:any) => {

    labelsController().generateZpl({...labelData}).then((res:any) => {
 
      let zplLines = res?.data?.response?.zplLabelConverted;
      if(!zplLines){
        throw "unexpected zpl label";
      }
 
      ZebraService().generateZplFile(zplLines);

    }).catch((res:any) => {
      feedbackService.notifyToast("Failed to fetch label. ", "error");
    });
  }


  const initiateCustomListenerForMobile = () => {
    timerCheckScanMobile.current = setTimeout(() => {
      let valFound = localStorage.getItem("webcom_rn");

      if (!valFound || valFound === "") {
        initiateCustomListenerForMobile();
      } else {
        try {
          let receivedMessage = JSON.parse(valFound);
          let action = receivedMessage.action;
          let payload = receivedMessage.payload;
          if (action === "wrapcom_network_print_zpl_return") {
            clearTimeout(timerCheckScanMobile.current);
         
           
            if(payload.printStatus === "success"){
            
              feedbackService.notifyToast(t('print.label.success'), "success");
            }else if(payload.printStatus === "failed"){
              feedbackService.notifyToast(t('print.label.fail'), "error");
            }
        
            setPrintButtonEnabled(true);
          
          }else{
            initiateCustomListenerForMobile();
          }

 
        } catch (exception) {
         
          alert("critical error occured when trying to read rn message");
          initiateCustomListenerForMobile();
        }

        localStorage.setItem("webcom_rn", "");
      }
    }, 250);
  };

  const callPrintZebraLabelsNetwork = (genericLabelData:any,numberOfLabels:number) => {
    labelsController().generateZpl(genericLabelData).then((res:any) => {
      let zplData = res?.data?.response?.zplLabelConverted;

      let zplLabelFull = "";
      for(let i = 0; i<zplData.length; i++){
        zplLabelFull += zplLabelFull + "" + zplData[i];
      }
    
      initiateCustomListenerForMobile();
      setPrintButtonEnabled(false);
      MobileWrapperCommunicationService().notifyPrintLabel(zplLabelFull,numberOfLabels);
   }).catch((res) => {
    feedbackService.notifyToast(t("generic.critical.error"), "error");
   });
  }

  const callPrint = () => {
    const isMobile = SystemService().isMobile();
    const enableNonSilentModeForMobile = false;
    const silentPrint = enableNonSilentModeForMobile && isMobile ? false : true;

    const isZebraLabel = currentActionScreenManager?.currentActionConfig?.zebraLabel === 1;
    const zebraPrintMode = currentActionScreenManager?.currentActionConfig?.zebraLabelMode;
    

    let genericLabelData:any = getLabelContentData(null,{returnObjParams:true});
    //if just one, and mobile replicate behaviour as pressing print inside the multi labels

   
    if(MobileWrapperCommunicationService().isDeviceMobileWrapper()){
      
      let obj:any = currentActionScreenManager?.currentActionConfig?.labelsList;
      setPrintableLabels(obj);
      if(obj.length === 1){
        genericLabelData["Id_Label"] = obj[0].Id_Label;
       
        if(isZebraLabel && zebraPrintMode === "DIRECT_NETWORK_PRINT_ZPL"){
         
          callPrintZebraLabelsNetwork(genericLabelData,props.countToPrint);
          setShowContinue(true);
        }else{
          let objParamsRenderPdf = genericLabelData;

          labelsController().getDirectViewToken(objParamsRenderPdf).then((res:any) => {
            //1 get file visual token
            let tokenDvt = res?.data?.response?.data?.token;
            if(!tokenDvt){
              throw "failed to fetch token";
            }
            objParamsRenderPdf["dvt"] = tokenDvt;
            let queryBuilder = new URLSearchParams(objParamsRenderPdf);
  
            if(isZebraLabel){
              let renderUrl:any = getCurrentServerServe() + "label/render-zpl/label.zpl?" + queryBuilder.toString() + "&x=label.zpl";
              window.location.href = renderUrl;
            }else{
              let renderUrl = getCurrentServerServe() + "label/render-pdf/label.pdf?" + queryBuilder.toString() + "&x=outputfile.pdf";
              console.log("GEN URL: ", renderUrl);
              MobileWrapperCommunicationService().notifyOpenPdf(renderUrl);
            }
            setShowContinue(true);
          }).catch((res:any) => {
            feedbackService.notifyToast("Failed to fetch token", "error");
          });
        }

      }else{
        genericLabelDataToUse.current = genericLabelData;
        setShowAskPrintLabelsOneByOne(true);
      }

    }else if (silentPrint) {
     
      if(isZebraLabel){

      
        for (
          let i = 0;
          i < currentActionScreenManager?.currentActionConfig?.labelsList.length;
          i++
        ) {
          let obj =
            currentActionScreenManager?.currentActionConfig?.labelsList[i];
          let queryBuilder = getLabelContentData(obj);
      
          requestGenerateZebra({...genericLabelData,Id_Label:obj.Id_Label});
        }
        setShowContinue(true);
        return;
      }

      //pdf behaviour
      
      console.log("Printing silent mode mode");
      if (renderPrintableFrames) {
        //forces rerender, reprint
        setRenderFramesKey(renderFramesKey + 1);
      }
      setRenderPrintableFrames(true);
    } else {



      //pdf behaviour
      
      console.log("Printing non silent mode mode");
      for (
        let i = 0;
        i < currentActionScreenManager?.currentActionConfig?.labelsList.length;
        i++
      ) {
        let obj =
          currentActionScreenManager?.currentActionConfig?.labelsList[i];
        let queryBuilder = getLabelContentData(obj);
        
        if(currentActionScreenManager?.currentActionConfig?.zebraLabel === 1){
          //zpl behaviour
        
          requestGenerateZebra(queryBuilder);
         
       
        }else{
          window.open(
            process.env.REACT_APP_API_SERVE_DEV +
              "label/print-pdf/?" +
              queryBuilder.toString() +
              "&getDownloadable=true",
            "",
            "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
          );
        }

      }

      setHideSkip(true);
    }
  };
  return (
    <>
        <div style={{position:'absolute'}}>
        <div style={{width:width + 'px'}}>
                {showAskPrintLabelsOneByOne && (
              <ActionScreenAskPrintLabelsPrintOnMobile
              countToPrint={props.countToPrint}
              genericLabelData={genericLabelDataToUse.current}
              printableLabels={printableLabels}
              currentActionConfig={currentActionScreenManager?.currentActionConfig}
              callbackOnClose={() => {
                setShowAskPrintLabelsOneByOne(false);
              }}
              callbackSuccessConfirm={() => {

              }}/>
            )}

          <div className={styles.container}>

            <div className={styles.lineBreaker}></div>
            <div className={styles.buttonsContainer}>
              <div
                className={confirmationStyles.confirmationContainer}
                style={{ paddingBottom: "10px" }}
              >
                <div style={{position: 'relative', right: '9px'}}>
                  <div key={renderFramesKey}>
                    {renderPrintableFrames && (
                      <div style={{ display: "none" }}>
                        {currentActionScreenManager?.currentActionConfig?.labelsList.map(
                          (obj: any, index: number) => (
                            <div key={"fileFrame_" + index}>{renderFrame(obj)}</div>
                          )
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <IonButton
                      disabled={!printButtonEnabled}
                      className={confirmationStyles.confirmButton}
                      onClick={() => {
                        
                        if(currentActionScreenManager?.currentActionConfig?.zebraLabel === 1){
                          printShownCount.current = 0;

                          setHideSkipOnFinishAction(true);
                          callPrint();

                        }else  if (
                          currentActionScreenManager?.currentActionConfig?.zplSupport
                        ) {
                          
                          //on popup scan zpl machine etc return setHideSkip(true);
                        } else {
                          printShownCount.current = 0;

                          setHideSkipOnFinishAction(true);
                          callPrint();
                        }
                      }}
                    >
                      <div className={styles.btnIcon}>
                        <PrintIcon />
                      </div>
                      {t('print')}
                    </IonButton>
                  </div>

                  {!hideSkip && props?.currentActionConfig?.uiButtonSkip === 1 ? (
                    <div>
                      <IonButton
                        className={confirmationStyles.confirmButton}
                        onClick={() => {
                          props.finishedActionCallback();
                        }}
                      >
                        Skip / Ignore Step
                      </IonButton>
                    </div>
                  ) : (
                    <></>
                  )}
                  {hideSkip || showContinue ? (
                    <div>
                      <IonButton
                        className={confirmationStyles.confirmButton}
                        onClick={() => {
                          printShownCount.current = 0;
                          callPrint();
                          props.finishedActionCallback();
                        }}
                      >
                        <div className={styles.btnIcon}></div>Continue
                      </IonButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>
  </>
  );
}

export default ActionScreenAskPrintLabelsConfirmationButtons;
